import { CHANGE_PASSWORD } from "../../graphql/users";
import { DialogBase } from "../../components/dialog";
import { DialogContent, Grid, TextField } from "@material-ui/core";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { flowRight as compose } from "lodash";
import { graphql, withApollo } from "@apollo/client/react/hoc";
import { withAppContext } from "../../utils/with-app-context";
import { withFormik } from "formik";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import bcrypt from "bcryptjs";

const messages = defineMessages({
  password: {
    defaultMessage: "Password",
    id: "change-password-dialog.password"
  },
  password2: {
    defaultMessage: "Password, igen",
    id: "change-password-dialog.password2"
  },
  title: {
    defaultMessage: "Ændre Password",
    id: "change-password-dialog.title"
  }
});

class ChangePasswordDialog extends PureComponent {
  static propTypes = {
    context: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    mail: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired
  };

  render() {
    const {
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      intl: { formatMessage },
      onCancel,
      open,
      values
    } = this.props;
    return (
      <DialogBase
        okDisabled={
          !values.password ||
          !values.password2 ||
          values.password !== values.password2 ||
          values.password.length < 6 ||
          !(/\d/.test(values.password) && /[a-zA-Z]/.test(values.password))
        }
        open={open}
        title={formatMessage(messages.title)}
        onCancel={onCancel}
        onOk={handleSubmit}
      >
        <DialogContent>
          <div style={{ height: 400 }}>
            Skal indeholde mindst et bogstav og et tal og skal minimum være 6
            tegn langt.
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    error={errors.password}
                    label={formatMessage(messages.password)}
                    name="password"
                    type="password"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    error={errors.password2}
                    label={formatMessage(messages.password2)}
                    name="password2"
                    type="password"
                    value={values.password2}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {values.password &&
                  values.password2 &&
                  values.password !== values.password2 ? (
                    <div style={{ color: "red" }}>
                      <FormattedMessage
                        defaultMessage="De indtastede passwords er ikke ens"
                        id="change-password-dialog.password-mismatch"
                      />
                    </div>
                  ) : null}
                </Grid>
              </Grid>
            </form>
          </div>
        </DialogContent>
      </DialogBase>
    );
  }
}

const handleSubmit = async (payload, { props }) => {
  const { password } = payload;
  const salt = bcrypt.genSaltSync();
  const hashed = await bcrypt.hash(password, salt);

  await props.change_password({
    variables: { mail: props.mail, password: hashed }
  });
  props.onOk();
};

const enhanced = compose(
  graphql(CHANGE_PASSWORD, { name: "change_password" }),
  withFormik({
    displayName: "ChangePasswordDialog",
    enableReinitialize: true,
    handleSubmit
  }),
  injectIntl,
  withApollo,
  withAppContext
)(ChangePasswordDialog);

export { enhanced as ChangePasswordDialog };
