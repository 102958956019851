/* eslint-disable react/jsx-no-bind */
import * as yup from "yup";
import { APP_BAR_HEIGHT, Page } from "../../../../components/layout/page";
import { AppBar, Link, Tab, Tabs, withStyles } from "@material-ui/core";
import {
  CREATE_ORDER_CONFIRMATIONS,
  CREATE_ORDER_CONFIRMATIONS_TASK,
  DELETE_ORDER_CONFIRMATIONS_TASK,
  GET_ORDER_CONFIRMATION,
  UPDATE_ORDER_CONFIRMATIONS,
  UPDATE_ORDER_CONFIRMATIONS_TASK
} from "../../../../graphql/order-confirmations";
import { GET_CASE } from "../../../../graphql/cases";
import { GET_ORDER_CONFIRMATION_TEMPLATE_WITH_CASE } from "../../../../graphql/order-confermation-templates";
import { OrderConfirmationForm } from "./order-confirmations-form";
import { Query } from "@apollo/client/react/components";
import { ReminderList } from "../../../../components/reminders/reminder-list";
import { Route, Switch, withRouter } from "react-router-dom";
import { flowRight as compose } from "lodash";
import { defineMessages, injectIntl } from "react-intl";
import { graphql } from "@apollo/client/react/hoc";
import { replaceWithData } from "../../../../utils/app";
import { withAppContext } from "../../../../utils/with-app-context";
import { withFormik } from "formik";
import AreYouSure from "../../../../components/are-you-sure";
import Header from "../../../../components/layout/header";
import PropTypes from "prop-types";
import React, { PureComponent, useState } from "react";
import uuid from "uuid/v4";

const messages = defineMessages({
  add: {
    defaultMessage: "Tilføj",
    id: "orderConfirmation-entry.add"
  },
  createNew: {
    defaultMessage: "Opret ny ordrebekræftelse",
    id: "orderConfirmation-entry.create-new-orderConfirmation"
  },
  details: {
    defaultMessage: "Detaljer",
    id: "orderConfirmation-entry.details"
  },
  reminders: {
    defaultMessage: "Påmindelser",
    id: "orderConfirmation-entry.reminders"
  },
  update: {
    defaultMessage: "Redigér ordrebekræftelse",
    id: "orderConfirmation-entry.update-orderConfirmation"
  }
});

const schema = yup.object().shape({
  //name: yup.string().required(),
  description: yup.string().required(),
  work_starts_date: yup
    .string()
    .nullable()
    .required()
  //icon: yup.string().required()
});
const styles = () => ({
  appBar: {
    "@media (min-width:1025px)": {
      width: "calc(100% - 240px)"
    },
    marginTop: APP_BAR_HEIGHT
  }
});

class OrderConfirmationEntry extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    dirty: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    id: PropTypes.string,
    intl: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    resetForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired
  };
  handleResetForm = () => {
    this.props.handleReset();
  };

  showNavigationWarning = () => {
    return !this.props.isSubmitting && (!this.props.id || this.props.dirty);
  };

  render() {
    const {
      dirty,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      id,
      intl: { formatMessage },
      isSubmitting,
      match: {
        params: { caseId }
      },
      setFieldValue,
      touched,
      values
    } = this.props;

    let heading;
    if (id) {
      heading = formatMessage(messages.update);
    } else {
      heading = formatMessage(messages.createNew);
    }

    return (
      <Page
        withTabs
        appBar={
          <Header
            backLocation={`/cases/${caseId}/documents`}
            dirty={dirty || !this.props.id}
            doc={values}
            title={heading}
            onResetButton={this.handleResetForm}
            onSaveButton={handleSubmit}
          />
        }
      >
        <Switch>
          <Route
            path="/cases/:caseId/order-confirmation/:orderConfirmationId/reminders"
            render={props => (
              <ReminderList
                {...props}
                baseURL={`/cases/${caseId}/order-confirmation/${id}/reminders`}
                parentId={id}
                parentName="document_order_confirmation_id"
              />
            )}
          />
          <Route
            path="/cases/:caseId/order-confirmation/:orderConfirmationId"
            render={props => (
              <div
                style={{
                  backgroundColor: "#FFF",
                  minHeight: "calc(100% - 64px)",
                  padding: 20
                }}
              >
                <OrderConfirmationForm
                  {...props}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  values={values}
                />
              </div>
            )}
          />
        </Switch>
        <AreYouSure when={this.showNavigationWarning} />
      </Page>
    );
  }
}

const relatedDiff = (oldList, newList) => {
  const toDelete = oldList.reduce((removedInstances, oldInstance) => {
    if (!newList.find(instance => instance.id === oldInstance.id)) {
      removedInstances.push(oldInstance);
    }
    return removedInstances;
  }, []);

  const toAdd = newList.reduce((addedInstances, instance) => {
    if (!oldList.find(oldInstance => instance.id === oldInstance.id)) {
      addedInstances.push(instance);
    }
    return addedInstances;
  }, []);

  const toUpdate = newList.reduce((updatedInstances, instance) => {
    if (oldList.find(oldInstance => instance.id === oldInstance.id)) {
      delete instance.__typename;
      updatedInstances.push(instance);
    }
    return updatedInstances;
  }, []);

  return { toAdd, toDelete, toUpdate };
};

const handleSubmit = async (
  payload,
  { props, resetForm, setErrors, setSubmitting }
) => {
  console.log("ass");

  const nowTimestamp = new Date().toISOString();
  const { caseId, orderConfirmationId } = props.match.params;
  const {
    description,
    document_date,
    drawings_date,
    other_terms,
    revision_date,
    tasks,
    title,
    work_ends_date,
    work_starts_date
  } = payload;
  const variables = {
    id: undefined,
    orderConfirmation: {
      case_id: caseId,
      changed: nowTimestamp,
      description,
      document_date,
      drawings_date,
      other_terms,
      revision_date,
      title,
      work_ends_date,
      work_starts_date
    }
  };
  try {
    if (orderConfirmationId) {
      const oldTasks = props.variables.tasks;
      const newTasks = tasks || [];
      const { toAdd, toDelete, toUpdate } = relatedDiff(oldTasks, newTasks);

      if (toDelete.length) {
        await props.deleteOrderConfirmationTask({
          variables: {
            where: {
              _or: toDelete.map(instance => {
                return { id: { _eq: instance.id } };
              })
            }
          }
        });
      }
      if (toAdd.length) {
        await props.createOrderConfirmationTask({
          variables: {
            orderConfirmationTasks: toAdd.map(instance => {
              instance.order_confirmation_id = orderConfirmationId;
              return instance;
            })
          }
        });
      }
      if (toUpdate.length) {
        Promise.all(
          toUpdate.map(async instance => {
            return await props.updateOrderConfirmationTask({
              variables: {
                id: instance.id,
                orderConfirmationTask: instance
              }
            });
          })
        );
      }

      variables.id = orderConfirmationId;
      const response = await props.updateOrderConfirmation({
        variables
      });
      props.setVariables(
        response.data.update_case_doc_order_confirmations.returning[0]
      );
      resetForm();
    } else {
      if (tasks && tasks.length) {
        variables.orderConfirmation.tasks = { data: tasks };
      }
      variables.orderConfirmation.created = nowTimestamp;
      variables.orderConfirmation.user_id = props.context.state.currentUser.id;
      const response = await props.createOrderConfirmation({
        variables
      });
      const createdId =
        response.data.insert_case_doc_order_confirmations.returning[0].id;
      props.history.replace(`/cases/${caseId}/order-confirmation/${createdId}`);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    const errors = e.graphQLErrors.map(error => error.message);
    // eslint-disable-next-line no-console
    console.log(errors);
    setSubmitting(false);
    setErrors({ form: errors });
  }
};

const OrderConfirmationFormWithGraphQL = compose(
  withRouter,
  withAppContext,
  graphql(CREATE_ORDER_CONFIRMATIONS, {
    name: "createOrderConfirmation"
  }),
  graphql(UPDATE_ORDER_CONFIRMATIONS, {
    name: "updateOrderConfirmation"
  }),

  graphql(CREATE_ORDER_CONFIRMATIONS_TASK, {
    name: "createOrderConfirmationTask"
  }),
  graphql(UPDATE_ORDER_CONFIRMATIONS_TASK, {
    name: "updateOrderConfirmationTask"
  }),
  graphql(DELETE_ORDER_CONFIRMATIONS_TASK, {
    name: "deleteOrderConfirmationTask"
  }),
  withFormik({
    displayName: "OrderConfirmationForm",
    handleSubmit,
    mapPropsToValues: ({ dateOfInspection, variables }) => {
      let { document_date, work_ends_date, work_starts_date } = variables;
      document_date = document_date ? document_date : new Date().toISOString();
      work_starts_date = work_starts_date ? work_starts_date : dateOfInspection;
      work_ends_date = work_ends_date ? work_ends_date : dateOfInspection;
      return {
        ...variables,
        document_date,
        work_ends_date,
        work_starts_date
      };
    },
    validationSchema: schema
  }),
  injectIntl,
  withStyles(styles)
)(OrderConfirmationEntry);

const OrderConfirmationEntryWrapper = ({
  match: {
    params: { caseId, orderConfirmationId, templateId }
  }
}) => {
  const id = orderConfirmationId;
  const [variables, setVariables] = useState(null);
  if (variables) {
    return (
      <OrderConfirmationFormWithGraphQL
        id={id}
        setVariables={setVariables}
        variables={variables}
      />
    );
  }
  if (id) {
    return (
      <Query query={GET_ORDER_CONFIRMATION} variables={{ id }}>
        {({ data, error, loading }) => {
          if (loading) {
            return <p>Loading...</p>;
          }
          if (error) {
            return <p>Error :(</p>;
          }
          return (
            <OrderConfirmationFormWithGraphQL
              id={id}
              setVariables={setVariables}
              variables={data.case_doc_order_confirmations_by_pk}
            />
          );
        }}
      </Query>
    );
  } else {
    if (!templateId) {
      return (
        <Query query={GET_CASE} variables={{ caseId }}>
          {({ data, error, loading }) => {
            if (loading) {
              return <p>Loading...</p>;
            }
            if (error) {
              return <p>Error :(</p>;
            }
            return (
              <OrderConfirmationFormWithGraphQL
                dateOfInspection={data.cases[0].date_of_inspection}
                id={id}
                setVariables={setVariables}
                variables={{}}
              />
            );
          }}
        </Query>
      );
    }
    return (
      <Query
        query={GET_ORDER_CONFIRMATION_TEMPLATE_WITH_CASE}
        variables={{ caseId, id: templateId }}
      >
        {({ data, error, loading }) => {
          if (loading) {
            return <p>Loading...</p>;
          }
          if (error) {
            return <p>Error :(</p>;
          }
          const parsedData = data.order_confirmation_templates_by_pk;
          parsedData.description = replaceWithData(
            parsedData.description,
            data.cases_by_pk
          );
          parsedData.title = replaceWithData(
            parsedData.title,
            data.cases_by_pk
          );
          parsedData.other_terms = replaceWithData(
            parsedData.other_terms,
            data.cases_by_pk
          );
          parsedData.tasks = parsedData.tasks.map(task => {
            return {
              description: replaceWithData(task.description, data.cases_by_pk),
              id: uuid(),
              order: task.order,
              price: task.price,
              title: replaceWithData(task.title, data.cases_by_pk)
            };
          });
          delete parsedData.__typename;

          return (
            <OrderConfirmationFormWithGraphQL
              dateOfInspection={data.cases_by_pk.date_of_inspection}
              id={id}
              setVariables={setVariables}
              variables={parsedData}
            />
          );
        }}
      </Query>
    );
  }
};

const OrderConfirmationEntryWrapperWithRouter = withRouter(
  OrderConfirmationEntryWrapper
);
export { OrderConfirmationEntryWrapperWithRouter as OrderConfirmationsEntry };
