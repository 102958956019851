import { MUTable } from "../../components/mu-table";
import {ORDER_CONFIRMATION_TEMPLATES} from "../../graphql/order-confermation-templates";
import {defineMessages, injectIntl, } from "react-intl";
import {withRouter} from "react-router";
import MuFab from "../../components/fab";
import PropTypes from "prop-types";
import React, {PureComponent} from "react";

const messages = defineMessages({
  template: {
    defaultMessage: "Skabelon",
    id: "order-confirmation-templates-list.template"
  }
});

class OrderConfirmationTemplatesList extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
  };

  handleRowClick = (template) => {
    this.props.history.push(`/settings/order-confirmations/${template.id}`);
  };
  handleFabClick = () => {
    this.props.history.push("/settings/order-confirmations/create");
  };

  render() {
    const {
      intl: {formatMessage}
    } = this.props;
    return (
      <>
        <MUTable
          columns={[
            {
              field: "template_title",
              title: formatMessage(messages.template)
            }
          ]}
          dataSourceName="order_confirmation_templates"
          gql={ORDER_CONFIRMATION_TEMPLATES}
          orderBy="template_title"
          onRowClick={this.handleRowClick}
        />
        <MuFab onClick={this.handleFabClick} />
      </>
    );
  }
}

export default withRouter(injectIntl(OrderConfirmationTemplatesList));
