/* eslint-disable react/jsx-no-bind */
import * as yup from "yup";
import { APP_BAR_HEIGHT, Page } from "../../../../components/layout/page";
import { AppBar, Link, Tab, Tabs, withStyles } from "@material-ui/core";
import {
  CREATE_CASE_DOC_NOTE,
  GET_CASE_DOC_NOTE,
  UPDATE_CASE_DOC_NOTE
} from "../../../../graphql/case-doc-notes";
import { CaseNoteForm } from "./form";
import { Query } from "@apollo/client/react/components";
import { ReminderList } from "../../../../components/reminders/reminder-list";
import { Route, Switch, withRouter } from "react-router-dom";
import { flowRight as compose } from "lodash";
import { defineMessages, injectIntl } from "react-intl";
import { graphql } from "@apollo/client/react/hoc";
import { withAppContext } from "../../../../utils/with-app-context";
import { withFormik } from "formik";
import AreYouSure from "../../../../components/are-you-sure";
import Header from "../../../../components/layout/header";
import PropTypes from "prop-types";
import React, { PureComponent, useState } from "react";

const messages = defineMessages({
  add: {
    defaultMessage: "Tilføj",
    id: "caseNote-entry.add"
  },
  caseNotes: {
    defaultMessage: "Note",
    id: "caseNote-entry.caseNotes"
  },
  createNew: {
    defaultMessage: "Opret ny note",
    id: "caseNote-entry.create-new-caseNote"
  },
  details: {
    defaultMessage: "Detaljer",
    id: "caseNote-entry.details"
  },
  reminders: {
    defaultMessage: "Påmindelser",
    id: "caseNote-entry.reminders"
  },
  update: {
    defaultMessage: "Redigér note",
    id: "caseNote-entry.update-caseNote"
  }
});

const schema = yup.object().shape({
  title: yup.string().required()
});

const styles = () => ({
  appBar: {
    "@media (min-width:1025px)": {
      width: "calc(100% - 240px)"
    },
    marginTop: APP_BAR_HEIGHT
  }
});

class CaseNoteEntry extends PureComponent {
  static propTypes = {
    caseNoteId: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    dirty: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    resetForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired
  };

  handleResetForm = () => this.props.handleReset();

  showNavigationWarning = () =>
    !this.props.isSubmitting && (!this.props.caseNoteId || this.props.dirty);

  render() {
    const {
      caseNoteId,
      classes,
      dirty,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      intl: { formatMessage },
      isSubmitting,
      match: {
        params: { caseId }
      },
      touched,
      values
    } = this.props;

    let heading;
    if (caseNoteId) {
      heading = formatMessage(messages.update);
    } else {
      heading = formatMessage(messages.createNew);
    }
    let currentTab = "details";
    if (window.location.hash.indexOf("/reminders") > -1) {
      currentTab = "reminders";
    }

    return (
      <Page
        withTabs
        appBar={
          <Header
            backLocation={`/cases/${caseId}/documents`}
            dirty={dirty || !this.props.caseNoteId}
            doc={values}
            title={heading}
            onResetButton={this.handleResetForm}
            onSaveButton={handleSubmit}
          />
        }
      >
        <Switch>
          <Route
            path="/cases/:caseId/note/:caseNoteId/reminders"
            render={props => (
              <ReminderList
                {...props}
                baseURL={`/cases/${caseId}/note/${caseNoteId}/reminders`}
                parentId={caseNoteId}
                parentName="document_note_id"
              />
            )}
          />
          <Route
            path="/cases/:caseId/note/:caseNoteId"
            render={props => (
              <div
                style={{
                  backgroundColor: "#FFF",
                  minHeight: "calc(100% - 64px)",
                  padding: 20
                }}
              >
                <CaseNoteForm
                  {...props}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  touched={touched}
                  values={values}
                />
              </div>
            )}
          />
        </Switch>
        <AreYouSure when={this.showNavigationWarning} />
      </Page>
    );
  }
}

const handleSubmit = async (
  payload,
  { props, resetForm, setErrors, setSubmitting }
) => {
  const { document_date, revision_date, text, title } = payload;
  let action;
  const variables = {
    case_doc_note: {
      document_date,
      revision_date,
      text,
      title
    },
    id: undefined
  };

  const { caseId, caseNoteId } = props.match.params;
  const timestamp = new Date().toISOString();
  if (caseNoteId) {
    action = props.updateCaseNote;
    variables.id = caseNoteId;
    variables.case_doc_note.changed = timestamp;
  } else {
    action = props.insertCaseNote;
    variables.case_doc_note.created = timestamp;
    variables.case_doc_note.changed = timestamp;
    variables.case_doc_note.case_id = caseId;
    variables.case_doc_note.user_id = props.context.state.currentUser.id;
  }
  try {
    const response = await action({
      variables
    });
    if (caseNoteId) {
      //props.setVariables(response.data.update_case_doc_notes.returning[0]);
      resetForm();
    } else {
      const createdCaseNoteId =
        response.data.insert_case_doc_notes.returning[0].id;
      props.history.replace(`/cases/${caseId}/note/${createdCaseNoteId}`);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    const errors = e.graphQLErrors.map(error => error.message);
    // eslint-disable-next-line no-console
    console.log(errors);
    setSubmitting(false);
    setErrors({ form: errors });
  }
};

const CaseNoteFormWithGraphQL = compose(
  withRouter,
  withAppContext,
  graphql(CREATE_CASE_DOC_NOTE, {
    name: "insertCaseNote"
  }),
  graphql(UPDATE_CASE_DOC_NOTE, { name: "updateCaseNote" }),
  withFormik({
    displayName: "CaseNoteForm",
    handleSubmit,
    mapPropsToValues: ({ variables }) => {
      let { document_date } = variables;
      document_date = document_date ? document_date : new Date().toISOString();
      return {
        ...variables,
        document_date
      };
    },
    validationSchema: schema
  }),
  injectIntl,
  withStyles(styles)
)(CaseNoteEntry);

const CaseNoteEntryWrapper = ({
  match: {
    params: { caseNoteId }
  }
}) => {
  const [variables, setVariables] = useState(null);
  if (variables) {
    return (
      <CaseNoteFormWithGraphQL
        caseNoteId={caseNoteId}
        setVariables={setVariables}
        variables={variables}
      />
    );
  }
  if (caseNoteId) {
    return (
      <Query query={GET_CASE_DOC_NOTE} variables={{ caseNoteId }}>
        {({ data, error, loading }) => {
          if (loading) {
            return <p>Loading...</p>;
          }
          if (error) {
            return <p>Error :(</p>;
          }

          return (
            <CaseNoteFormWithGraphQL
              caseNoteId={caseNoteId}
              setVariables={setVariables}
              variables={data.case_doc_notes[0]}
            />
          );
        }}
      </Query>
    );
  } else {
    return <CaseNoteFormWithGraphQL variables={{}} />;
  }
};

const CaseNoteEntryWrapperWithRouter = withRouter(CaseNoteEntryWrapper);
export { CaseNoteEntryWrapperWithRouter as CaseNoteEntry };
