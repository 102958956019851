import gql from "graphql-tag";

export const REMINDERS = gql`
  query list_reminders(
    $orderBy: [reminders_order_by!]
    $limit: Int
    $where: reminders_bool_exp
  ) {
    reminders(order_by: $orderBy, where: $where, limit: $limit) {
      id
      date
      message
      contacted
      creator {
        id
        acronym
      }
      recipients {
        user {
          id
          acronym
          mail
        }
      }
      request_id
      request {
        id
        builder
        address
        zip_code
        city
        mail
        mobile
      }
      case_id
      case {
        id
        builder {
          id
          first_name
          last_name
          mail
          mobile
          address
          zip_code
          city
        }
        company_id
        company {
          id
          first_name
          last_name
          company
          address
          zip_code
          city
          phone
          mobile
          mail
        }
        other_address
        address
        zip_code
        city
        suppliers(where: { contact: { type: { _eq: "manager" } } }, limit: 1) {
          contact {
            company
            first_name
            last_name
            mobile
            mail
          }
        }
      }
    }
  }
`;

export const CREATE_REMINDER = gql`
  mutation insert_reminder($reminder: reminders_insert_input!) {
    insert_reminders(objects: [$reminder]) {
      returning {
        id
        date
        message
        contacted

        creator {
          id
          acronym
        }
        recipients {
          user {
            id
            first_name
            last_name
            mail
            mobile
            phone
            acronym
          }
        }
      }
    }
  }
`;

export const GET_REMINDER = gql`
  query get_reminders($reminderId: uuid) {
    reminders(where: { id: { _eq: $reminderId } }) {
      id
      date
      message
      contacted
      creator {
        id
        acronym
      }
      recipients {
        user {
          id
          first_name
          last_name
          mail
          mobile
          phone
          acronym
        }
      }
    }
  }
`;

export const UPDATE_REMINDER = gql`
  mutation updateReminder($id: uuid, $reminder: reminders_set_input!) {
    update_reminders(where: { id: { _eq: $id } }, _set: $reminder) {
      returning {
        id
        date
        message
        contacted
        creator {
          id
          acronym
        }
        recipients {
          user {
            id
            first_name
            last_name
            mail
            mobile
            phone
            acronym
          }
        }
      }
    }
  }
`;

export const CREATE_REMINDER_RECIPIENT = gql`
  mutation insert_reminder_recipient(
    $reminder_recipients: [reminder_recipients_insert_input!]!
  ) {
    insert_reminder_recipients(objects: $reminder_recipients) {
      returning {
        id
        user_id
        reminder_id
      }
    }
  }
`;

export const DELETE_REMINDER_RECIPIENT = gql`
  mutation delete_reminder_recipients($where: reminder_recipients_bool_exp!) {
    delete_reminder_recipients(where: $where) {
      affected_rows
    }
  }
`;

export const REMINDER_CHANGE_CONTACTED = gql`
  mutation updateRemindersContacted($id: uuid, $contacted: Boolean!) {
    update_reminders(
      where: { id: { _eq: $id } }
      _set: { contacted: $contacted }
    ) {
      returning {
        id
        date
        message
        contacted
        creator {
          id
          acronym
        }
        recipients {
          user {
            id
            first_name
            last_name
            mail
            mobile
            phone
            acronym
          }
        }
      }
    }
  }
`;
