/* eslint-disable react/jsx-no-bind */
import * as yup from "yup";
import {
  CREATE_REMINDER,
  CREATE_REMINDER_RECIPIENT,
  DELETE_REMINDER_RECIPIENT,
  GET_REMINDER,
  UPDATE_REMINDER
} from "../../graphql/reminders";
import { Page } from "../layout/page";
import { Query } from "@apollo/client/react/components";
import { RemindersForm } from "./reminders-form";
import { flowRight as compose } from "lodash";
import { defineMessages, injectIntl } from "react-intl";
import { formatUserLabel } from "../user-select";
import { generateSelectValue } from "../../utils/form-helpers";
import { graphql, withApollo } from "@apollo/client/react/hoc";
import { withAppContext } from "../../utils/with-app-context";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import AreYouSure from "../are-you-sure";
import Header from "../layout/header";
import PropTypes from "prop-types";
import React, { PureComponent, useState } from "react";

const messages = defineMessages({
  add: {
    defaultMessage: "Tilføj",
    id: "entry.add"
  },
  createNew: {
    defaultMessage: "Opret ny påmindelse",
    id: "entry.createNew"
  },
  update: {
    defaultMessage: "Redigér påmindelse",
    id: "entry.update-reminder"
  }
});

const schema = yup.object().shape({
  date: yup
    .string()
    .nullable()
    .required(),
  message: yup.string().required(),
  recipients: yup.array().required()
});

class ReminderEntry extends PureComponent {
  static propTypes = {
    dirty: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    reminderId: PropTypes.string,
    requestId: PropTypes.string.isRequired,
    resetForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired
  };

  handleResetForm = () => {
    this.props.handleReset();
  };

  showNavigationWarning = () => {
    return !this.props.isSubmitting && this.props.dirty;
  };

  render() {
    const {
      dirty,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      intl: { formatMessage },
      isSubmitting,
      reminderId,
      submitForm,
      touched,
      values
    } = this.props;
    let heading;
    if (reminderId) {
      heading = formatMessage(messages.update);
    } else {
      heading = formatMessage(messages.createNew);
    }
    return (
      <Page
        appBar={
          <Header
            dirty={dirty}
            title={heading}
            onResetButton={this.handleResetForm}
            onSaveButton={submitForm}
          />
        }
      >
        <div style={{ backgroundColor: "#FFF", padding: 10 }}>
          <RemindersForm
            caseInfo={{}}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            touched={touched}
            values={values}
          />
        </div>
        <AreYouSure when={this.showNavigationWarning} />
      </Page>
    );
  }
}

const handleSubmit = async (payload, { props, setErrors, setSubmitting }) => {
  const { date, message, recipients } = payload;
  const variables = {
    reminder: {
      case_id: props.caseId,
      creator_id: props.context.state.currentUser.id,
      date,
      document_note_id: props.caseNoteId,
      document_order_confirmation_id: props.orderConfirmationId,
      document_quality_control_id: props.qualityControlId,
      message,
      request_id: props.requestId
    }
  };
  const { createReminder, createReminderRecipient, updateReminder } = props;
  try {
    const response = await createReminder({
      variables
    });
    const createdReminderId = response.data.insert_reminders.returning[0].id;
    const reminderRecipients = recipients.map(recipient => ({
      reminder_id: createdReminderId,
      user_id: recipient.user ? recipient.user.id : recipient.id
    }));

    await createReminderRecipient({
      variables: { reminder_recipients: reminderRecipients }
    });
    if (payload.id) {
      const updateVariables = {
        id: payload.id,
        reminder: {
          next_reminder_id: createdReminderId
        }
      };
      await updateReminder({ variables: updateVariables });
    }
    window.history.back();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    const errors = e.graphQLErrors.map(error => error.message);
    // eslint-disable-next-line no-console
    console.log(errors);
    setSubmitting(false);
    setErrors({ form: errors });
  }
};

const EnhancedReminderEntry = compose(
  withApollo,
  graphql(CREATE_REMINDER, { name: "createReminder" }),
  graphql(UPDATE_REMINDER, { name: "updateReminder" }),
  graphql(CREATE_REMINDER_RECIPIENT, { name: "createReminderRecipient" }),
  graphql(DELETE_REMINDER_RECIPIENT, { name: "deleteReminderRecipients" }),
  withAppContext,
  withFormik({
    displayName: "ReminderEntry",
    handleSubmit,
    mapPropsToValues: ({ context, variables }) => {
      const date = variables.date || null;
      let { recipients } = variables;
      if (!recipients) {
        recipients = [
          {
            ...context.state.currentUser,
            label: formatUserLabel(context.state.currentUser),
            value: context.state.currentUser.id
          }
        ];
      } else {
        recipients = recipients.map(recipient =>
          generateSelectValue(recipient.user, formatUserLabel)
        );
      }
      return {
        ...variables,
        date,
        recipients
      };
    },
    validationSchema: schema
  }),
  withRouter,
  injectIntl
)(ReminderEntry);

const ReminderEntryWrapper = ({ match: { params } }) => {
  const [variables, setVariables] = useState(null);
  const { reminderId } = params;
  if (variables) {
    return (
      <EnhancedReminderEntry
        {...params}
        reminderId={reminderId}
        setVariables={setVariables}
        variables={variables}
      />
    );
  }
  if (reminderId) {
    return (
      <Query query={GET_REMINDER} variables={{ reminderId }}>
        {({ data, error, loading }) => {
          if (loading) {
            return <p>Loading...</p>;
          }
          if (error) {
            return <p>Error :(</p>;
          }

          return (
            <EnhancedReminderEntry
              {...params}
              setVariables={setVariables}
              variables={data.reminders[0]}
            />
          );
        }}
      </Query>
    );
  } else {
    return (
      <EnhancedReminderEntry
        {...params}
        setVariables={setVariables}
        variables={{}}
      />
    );
  }
};

const ReminderEntryWrapperWithRouter = withRouter(ReminderEntryWrapper);

export { ReminderEntryWrapperWithRouter as ReminderEntry };
