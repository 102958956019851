import { Page } from "../components/layout/page";
import Header from "../components/layout/header";
import React, { Component } from "react";

class NotImplemented extends Component {
  render() {
    return (
      <Page appBar={<Header title={this.props.title} />}>
        <h1 style={{ textAlign: "center" }}>
          Siden er endnu ikke implementeret
        </h1>
      </Page>
    );
  }
}

export default NotImplemented;
