import { AjaxDownloadButton } from "../../components/download-button";
import { BASE_FILE_URL } from "../../utils/app";
import { ContactSelect } from "../../components/contact-select";
import { DatePicker } from "@material-ui/pickers";
import { FormattedMessage } from "react-intl";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { isMobile } from "../../utils";
import { useLazyQuery } from "@apollo/client";
import { useRequestExternalCaseStatsLazyQuery } from "../../generated/graphql";
import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import gql from "graphql-tag";
import moment from "moment";

export const GET_EXTERNAL_CASE_STATS_FOR_USER = gql`
  query requestExternalCaseStatsForUser(
    $startDate: timestamptz
    $contactId: uuid = ""
    $endDate: timestamptz = "2099-12-21"
  ) {
    requests(
      where: {
        date: { _gt: $startDate, _lte: $endDate }
        state: { _eq: "external" }
        external_id: { _eq: $contactId }
      }
      order_by: { date: asc }
    ) {
      task
      id
      case_type {
        name
        price
      }
    }
  }
`;

export const GET_EXTERNAL_CASE_STATS = gql`
  query requestExternalCaseStats(
    $startDate: timestamptz
    $endDate: timestamptz = "2099-12-21"
  ) {
    requests(
      where: {
        date: { _gt: $startDate, _lte: $endDate }
        state: { _eq: "external" }
      }
      order_by: { date: asc }
    ) {
      task
      id
      case_type {
        name
        price
      }
    }
  }
`;

export const ExternalCaseCard = React.memo(
  function ExternalCaseCard(): JSX.Element {
    const [getData, { data, loading }] = useLazyQuery(
      GET_EXTERNAL_CASE_STATS_FOR_USER
    );

    const [
      getDataWithoutUser,
      { data: dataWithoutUser, loading: loadingWihtoutUsers }
    ] = useRequestExternalCaseStatsLazyQuery();

    const [startDate, setStartDate] = useState<moment.Moment | null>(null);
    const [endDate, setEndDate] = useState<moment.Moment | null>(null);
    const [external, setExternal] = useState<{
      company: string;
      first_name: string;
      id: string;
      last_name: string;
    } | null>(null);
    //const [requestSum, setRequestSum] = useState<Map<string, {count, price}> | null>(null);

    const onContactSelected = useCallback((option): void => {
      setExternal(option);
    }, []);

    const startIsoDate = startDate?.toISOString();
    const endIsoDate = endDate?.toISOString();

    useEffect(() => {
      if (endIsoDate && startIsoDate) {
        if (external) {
          getData({
            variables: {
              contactId: external.id,
              endDate: endIsoDate,
              startDate: startIsoDate
            }
          });
        } else {
          getDataWithoutUser({
            variables: {
              endDate: endIsoDate,
              startDate: startIsoDate
            }
          });
        }
      } else if (!endIsoDate && !startIsoDate) {
        const initialEndDate = moment();
        const initialStartDate = moment(initialEndDate).subtract(1, "months");
        setStartDate(initialStartDate);
        setEndDate(initialEndDate);
      }
    }, [external, getData, endIsoDate, startIsoDate, getDataWithoutUser]);

    const requestSum = new Map<string, { count; price }>();
    const currentData = external ? data : dataWithoutUser;
    if (!loading && currentData) {
      if (currentData.requests?.length) {
        currentData.requests.forEach(request => {
          const task = request.case_type?.name;
          const price = request.case_type?.price;
          //const entry = requestSum.get(task) || {count: 0, price}
          requestSum.set(task, {
            count: 1 + (requestSum.get(task)?.count || 0),
            price
          });
        });
      }
    }
    let totalCount = 0;
    let totalPrice = 0;

    return (
      <Paper>
        <div style={{ padding: 10 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ContactSelect
                field={{ name: "external", value: external }}
                form={{ errors: {} }}
                type="external"
                onSelect={onContactSelected}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                clearable
                fullWidth
                DialogProps={{ fullScreen: isMobile }}
                format="Do MMMM YYYY"
                label={
                  <FormattedMessage
                    defaultMessage="Fra dato"
                    id="checklist-items.start-date"
                  />
                }
                value={startDate}
                onChange={setStartDate}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                clearable
                fullWidth
                DialogProps={{ fullScreen: isMobile }}
                format="Do MMMM YYYY"
                label={
                  <FormattedMessage
                    defaultMessage="Til dato"
                    id="checklist-items.end-date"
                  />
                }
                value={endDate}
                onChange={setEndDate}
              />
            </Grid>
          </Grid>
        </div>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Formulartype"
                  id="requests.case-type"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Antal" id="requests.count" />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Enhedspris"
                  id="requests.price"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Vurderet salgspris"
                  id="requests.salesPrice"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading || loadingWihtoutUsers ? (
              <TableRow>
                <TableCell colSpan={4}>Loader</TableCell>
              </TableRow>
            ) : (
              <>
                {_.sortBy(Array.from(requestSum), ([key]) => key).map(
                  ([name, { count, price }]) => {
                    totalCount += count;
                    const salesPrice =
                      price != null && count != null ? count * price : 0;
                    totalPrice += salesPrice;
                    return (
                      <TableRow key={name || "blank"}>
                        <TableCell>{name}</TableCell>
                        <TableCell>{count}</TableCell>
                        <TableCell>{price}</TableCell>
                        <TableCell>{salesPrice}</TableCell>
                      </TableRow>
                    );
                  }
                )}
                <TableRow>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Total"
                      id="requests.total"
                    />
                  </TableCell>
                  <TableCell>{totalCount}</TableCell>
                  <TableCell />
                  <TableCell>{totalPrice}</TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
        <div style={{ float: "right" }}>
          <AjaxDownloadButton
            data={{
              data: _.sortBy(Array.from(requestSum), ([key]) => key),
              endDate: endDate?.format("DD-MM-YYYY"),
              name: `${external?.first_name} ${external?.last_name}, ${external?.company}`,
              startDate: startDate?.format("DD-MM-YYYY")
            }}
            disabled={!requestSum.size}
            downloadURL={`${BASE_FILE_URL}/pdf/external`}
            filename={`ekstern.pdf`}
            label="Download PDF"
          />
        </div>
      </Paper>
    );
  }
);
