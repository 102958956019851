import {
  AccountEdit,
  AccountMultipleCheck,
  ChartAreaspline,
  ContactMail,
  Domain,
  EmailSearch,
  FileCloud,
  FormatListChecks,
  HomeGroup,
  HomeLock,
  HomeLockOpen,
  HomeMapMarker,
  HomePlus,
  ImageSearch,
  Reminder,
  Settings,
  Timetable
} from "mdi-material-ui";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import React, { Component } from "react";

import { Divider, IconButton, ListItem, withStyles } from "@material-ui/core";

const styles = theme => ({
  toolbar: {
    ...theme.mixins.toolbar,
    fontSize: 15,
    textAlign: "center"
  }
});

class ListSideBar extends Component {
  linksAdmin = [
    { icon: <Settings />, path: "/settings", title: "Indstillinger" },
    { icon: <ChartAreaspline />, path: "/statistics", title: "Statistik" },
    {
      icon: <AccountMultipleCheck />,
      path: "/users",
      title: "Bruger administration"
    }
  ];

  handeLogout = () => {
    localStorage.removeItem("token");
    window.location = "/";
  };

  render() {
    const { classes } = this.props;
    const {
      state: { currentUser }
    } = this.props.context;
    const currentRole = currentUser ? currentUser.role : null;
    const currentNumber = currentUser ? currentUser.employee_number : null;
    const isAdmin = currentRole === "admin";
    const isSuperUser = currentRole === "superuser";
    const isUser = currentRole === "user";

    const links = [
      {
        icon: <HomeLockOpen />,
        path: "/cases/list/current",
        title: "Igangværende"
      },
      { icon: <HomeLock />, path: "/cases/list/archive", title: "Lukkede" },
      !isUser
        ? {
            icon: <HomeGroup />,
            path: "/cases/requests",
            title: "Forespørgsler"
          }
        : null,
      { icon: <HomePlus />, path: "/cases/create", title: "Opret ny sag" },
      {
        icon: <ImageSearch />,
        path: "/cases/list/search",
        title: "Søg i alle sager"
      },
      "divider",
      currentNumber <= 5
        ? { icon: <FormatListChecks />, path: "/my-cases", title: "Mine sager" }
        : null,
      { icon: <Reminder />, path: "/reminders", title: "Mine påmindelser" },
      { icon: <EmailSearch />, path: "/builderpedia", title: "Byggepedia" },
      { icon: <HomeMapMarker />, path: "/overview", title: "Sagsoverblik" },
      {
        icon: <Timetable />,
        path: "/timeregistration",
        title: "Tidsregistering"
      },
      { icon: <ContactMail />, path: "/contacts", title: "Kontakter" },
      { icon: <FileCloud />, path: "/files", title: "Filarkiv" },
      "divider",
      { icon: <AccountEdit />, path: "/profile", title: "Brugerprofil" },
      "divider",
      isAdmin || isSuperUser
        ? { icon: <Settings />, path: "/settings", title: "Indstillinger" }
        : null,
      isAdmin || isSuperUser
        ? { icon: <ChartAreaspline />, path: "/statistics", title: "Statistik" }
        : null,
      isAdmin
        ? {
            icon: <AccountMultipleCheck />,
            path: "/users",
            title: "Bruger administration"
          }
        : null,
      isAdmin || isSuperUser
        ? {
            icon: <FormatListChecks />,
            path: "/absence",
            title: "Fravær"
          }
        : null
    ];

    return (
      <div>
        <div
          className={classes.toolbar}
          style={!this.props.isMobile ? { height: 64 } : undefined}
        >
          <img
            src="logo.png"
            style={{
              height: "auto",
              maxHeight: 63,
              maxWidth: "100%",
              width: "auto"
            }}
          />
          {this.props.isMobile && (
            <IconButton>
              <Icon style={{ display: "inline", width: 24 }}>arrow_back</Icon>
            </IconButton>
          )}
        </div>
        <Divider />
        <List>
          <div>
            {links
              .filter(link => !!link)
              .map((link, index) => {
                return link === "divider" ? (
                  <Divider key={index} />
                ) : (
                  <NavLink
                    key={index}
                    exact
                    activeClassName={classes.current}
                    className="link"
                    style={{ color: "#000" }}
                    to={link.path}
                  >
                    <ListItem>
                      <ListItemIcon>{link.icon}</ListItemIcon>
                      <ListItemText primary={link.title} />
                    </ListItem>
                  </NavLink>
                );
              })}

            <Divider />
            {!currentUser ? (
              <NavLink className="link" to="/login">
                <ListItem>
                  <ListItemIcon>
                    <Icon>account_circle</Icon>
                  </ListItemIcon>
                  <ListItemText primary="Login" />
                </ListItem>
              </NavLink>
            ) : (
              <MenuItem onClick={this.handeLogout}>
                <ListItemIcon>
                  <Icon>account_circle</Icon>
                </ListItemIcon>
                <ListItemText primary="Logud" />
              </MenuItem>
            )}
          </div>
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(ListSideBar));
