import Bowser from "bowser";
import queryString from "query-string";

export const replaceQueryString = (key: string, value: string): void => {
  const [routePart, queryPart] = window.location.hash.split("?");
  const oldValues = queryString.parse(queryPart);
  const newValues = { ...oldValues };
  newValues[key] = value;
  const stringified = queryString.stringify(newValues);
  window.location.hash = `${routePart}?${stringified}`;
};

export function getQueryString(): {
  [name: string]: string | undefined;
};
export function getQueryString(name?: string): string;
export function getQueryString(name?: any): any {
  const queryStrings = queryString.parse(window.location.hash.split("?")[1]);
  if (name) {
    return queryStrings[name];
  }
  return queryStrings;
}

const browser = Bowser.getParser(window.navigator.userAgent);
export const isMobile = browser.getPlatformType(true) !== "desktop";
