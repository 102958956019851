/* eslint-disable react/jsx-no-bind */
import * as yup from "yup";
import { APP_BAR_HEIGHT, Page } from "../../../../components/layout/page";
import {
  CREATE_CASE_DOC_ECONOMY,
  GET_CASE_DOC_ECONOMY,
  UPDATE_CASE_DOC_ECONOMY
} from "../../../../graphql/case-doc-economy";
import { CaseEconomyForm } from "./form";
import { Query } from "@apollo/client/react/components";
import { flowRight as compose } from "lodash";
import { defineMessages, injectIntl } from "react-intl";
import { graphql } from "@apollo/client/react/hoc";
import { withAppContext } from "../../../../utils/with-app-context";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import AreYouSure from "../../../../components/are-you-sure";
import Header from "../../../../components/layout/header";
import PropTypes from "prop-types";
import React, { PureComponent, useState } from "react";

const messages = defineMessages({
  add: {
    defaultMessage: "Tilføj",
    id: "caseDocEconomy-entry.add"
  },
  caseDocEconomys: {
    defaultMessage: "Economy",
    id: "caseDocEconomy-entry.caseDocEconomys"
  },
  createNew: {
    defaultMessage: "Opret ny økonomi note",
    id: "caseDocEconomy-entry.create-new-caseDocEconomy"
  },
  details: {
    defaultMessage: "Detaljer",
    id: "caseDocEconomy-entry.details"
  },
  reminders: {
    defaultMessage: "Påmindelser",
    id: "caseDocEconomy-entry.reminders"
  },
  update: {
    defaultMessage: "Redigér økonomi note",
    id: "caseDocEconomy-entry.update-caseDocEconomy"
  }
});

const schema = yup.object().shape({
  title: yup.string().required()
});

const styles = () => ({
  appBar: {
    "@media (min-width:1025px)": {
      width: "calc(100% - 240px)"
    },
    marginTop: APP_BAR_HEIGHT
  }
});

class CaseEconomyEntry extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    dirty: PropTypes.bool.isRequired,
    economyId: PropTypes.string.isRequired,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    resetForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired
  };

  handleResetForm = () => this.props.handleReset();

  showNavigationWarning = () =>
    !this.props.isSubmitting && (!this.props.economyId || this.props.dirty);

  render() {
    const {
      dirty,
      economyId,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      intl: { formatMessage },
      isSubmitting,
      match: {
        params: { caseId }
      },
      touched,
      values
    } = this.props;

    let heading;
    if (economyId) {
      heading = formatMessage(messages.update);
    } else {
      heading = formatMessage(messages.createNew);
    }

    return (
      <Page
        appBar={
          <Header
            backLocation={`/cases/${caseId}/documents`}
            dirty={dirty || !this.props.economyId}
            doc={values}
            title={heading}
            onResetButton={this.handleResetForm}
            onSaveButton={handleSubmit}
          />
        }
      >
        <div
          style={{
            backgroundColor: "#FFF",
            minHeight: "calc(100% - 64px)",
            padding: 20
          }}
        >
          <CaseEconomyForm
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            touched={touched}
            values={values}
          />
        </div>

        <AreYouSure when={this.showNavigationWarning} />
      </Page>
    );
  }
}

const handleSubmit = async (
  payload,
  { props, resetForm, setErrors, setSubmitting }
) => {
  const { document_date, revision_date, text, title } = payload;
  let action;
  const variables = {
    case_doc_economy: {
      document_date,
      revision_date,
      text,
      title
    },
    id: undefined
  };

  const { caseId, economyId } = props.match.params;
  const timestamp = new Date().toISOString();
  if (economyId) {
    action = props.updateCaseEconomy;
    variables.id = economyId;
    variables.case_doc_economy.changed = timestamp;
  } else {
    action = props.insertCaseEconomy;
    variables.case_doc_economy.created = timestamp;
    variables.case_doc_economy.changed = timestamp;
    variables.case_doc_economy.case_id = caseId;
    variables.case_doc_economy.user_id = props.context.state.currentUser.id;
  }
  try {
    const response = await action({
      variables
    });
    if (economyId) {
      //props.setVariables(response.data.update_case_doc_economy.returning[0]);
      resetForm();
    } else {
      const createdCaseEconomyId =
        response.data.insert_case_doc_economy.returning[0].id;
      props.history.replace(`/cases/${caseId}/economy/${createdCaseEconomyId}`);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    const errors = e.graphQLErrors.map(error => error.message);
    // eslint-disable-next-line no-console
    console.log(errors);
    setSubmitting(false);
    setErrors({ form: errors });
  }
};

const CaseEconomyFormWithGraphQL = compose(
  withRouter,
  withAppContext,
  graphql(CREATE_CASE_DOC_ECONOMY, {
    name: "insertCaseEconomy"
  }),
  graphql(UPDATE_CASE_DOC_ECONOMY, { name: "updateCaseEconomy" }),
  withFormik({
    displayName: "CaseEconomyForm",
    handleSubmit,
    mapPropsToValues: ({ variables }) => {
      let { document_date } = variables;
      document_date = document_date ? document_date : new Date().toISOString();
      return {
        ...variables,
        document_date
      };
    },
    validationSchema: schema
  }),
  injectIntl,
  withStyles(styles)
)(CaseEconomyEntry);

const CaseEconomyEntryWrapper = ({
  match: {
    params: { economyId }
  }
}) => {
  const [variables, setVariables] = useState(null);
  if (variables) {
    return (
      <CaseEconomyFormWithGraphQL
        economyId={economyId}
        setVariables={setVariables}
        variables={variables}
      />
    );
  }
  if (economyId) {
    return (
      <Query query={GET_CASE_DOC_ECONOMY} variables={{ economyId }}>
        {({ data, error, loading }) => {
          if (loading) {
            return <p>Loading...</p>;
          }
          if (error) {
            return <p>Error :(</p>;
          }
          return (
            <CaseEconomyFormWithGraphQL
              economyId={economyId}
              setVariables={setVariables}
              variables={data.case_doc_economy[0]}
            />
          );
        }}
      </Query>
    );
  } else {
    return <CaseEconomyFormWithGraphQL variables={{}} />;
  }
};

const CaseEconomyEntryWrapperWithRouter = withRouter(CaseEconomyEntryWrapper);
export { CaseEconomyEntryWrapperWithRouter as CaseEconomyEntry };
