import { DatePickerField } from "../../../../components/date-time-fields";
import { Field } from "formik";
import { FormattedMessage } from "react-intl";
import { Grid } from "@material-ui/core";
import { RichTextEditor } from "../../../../components/draftjs-formik";
import { Tasks } from "../../../../components/order-confirmation-tasks";
import { formikPropTypes } from "../../../../utils/form-helpers";
import React from "react";
import TextField from "@material-ui/core/TextField";

const OrderConfirmationForm =
  // eslint-disable-next-line react/prop-types
  ({ errors, handleBlur, handleChange, handleSubmit, values }) => (
    <>
      <form onSubmit={handleSubmit}>
        <div>{errors.form}</div>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Field
              fullWidth
              component={DatePickerField}
              label={
                <FormattedMessage
                  defaultMessage="Dokument dato"
                  id="order-confirmations-form.document-date"
                />
              }
              name="document_date"
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Field
              fullWidth
              component={DatePickerField}
              label={
                <FormattedMessage
                  defaultMessage="Rev. dato"
                  id="order-confirmations-form.revision_date"
                />
              }
              name="revision_date"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={errors.title}
              label={
                <FormattedMessage
                  defaultMessage="Dokumenttitel"
                  id="order-confirmations-form.document-title"
                />
              }
              name="title"
              type="text"
              value={values.title}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RichTextEditor
              label={
                <FormattedMessage
                  defaultMessage="Beskrivelse"
                  id="order-confirmations-form.description"
                />
              }
              name="description"
              value={values.description}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item sm={4} xs={12}>
            <Field
              fullWidth
              component={DatePickerField}
              label={
                <FormattedMessage
                  defaultMessage="Underbyggende tegningsmateriale dateret d."
                  id="order-confirmations-form.drawings-date"
                />
              }
              name="drawings_date"
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Field
              fullWidth
              component={DatePickerField}
              label={
                <FormattedMessage
                  defaultMessage="Arbejdet starter d."
                  id="order-confirmations-form.work-starts-date"
                />
              }
              name="work_starts_date"
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Field
              fullWidth
              component={DatePickerField}
              label={
                <FormattedMessage
                  defaultMessage="Arbejdet afsluttes d."
                  id="order-confirmations-form.work-ends-date"
                />
              }
              name="work_ends_date"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RichTextEditor
              label={
                <FormattedMessage
                  defaultMessage="Forbehold"
                  id="order-confirmations-form.other-terms"
                />
              }
              name="other_terms"
              value={values.other_terms}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <h4>
          <FormattedMessage
            defaultMessage="Opgaver"
            id="order-confirmations-form.tasks"
          />
        </h4>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field component={Tasks} name="tasks" />
          </Grid>
        </Grid>
      </form>
    </>
  );
OrderConfirmationForm.propTypes = {
  ...formikPropTypes
};

const OrderConfirmationFormWithIntl = React.memo(OrderConfirmationForm);

export { OrderConfirmationFormWithIntl as OrderConfirmationForm };
