import { Button, CircularProgress } from "@material-ui/core";
import { defineMessages, useIntl } from "react-intl";
import { red } from "@material-ui/core/colors";
import FileSaver from "file-saver";
import React, { useCallback, useState } from "react";

const messages = defineMessages({
  errorMessage: {
    defaultMessage: "Der skete en fejl",
    id: "download-button.errormessage"
  }
});

interface AjaxDownloadButtonProps {
  data:
    | { [field: string]: any }
    | any[]
    | (() => { [field: string]: any } | any[]);
  disabled?: boolean;
  downloadURL: string;
  filename: string;
  Icon?: React.ComponentType;
  label: string;
}

export const AjaxDownloadButton = React.memo(function AjaxDownloadButton(
  props: AjaxDownloadButtonProps
): JSX.Element {
  const [error, setError] = useState("");
  const [fetching, setFetching] = useState(false);
  const { formatMessage } = useIntl();

  const handleDownloadButtonClick = useCallback((): Promise<void> => {
    let { data } = props;
    if (typeof data === "function") {
      data = data();
    }
    setFetching(true);
    return fetch(props.downloadURL, {
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json"
      },
      method: "post"
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.blob();
      })
      .then(blob => {
        setFetching(false);
        setError("");

        return FileSaver.saveAs(blob, props.filename);
      })
      .catch(_error => {
        setFetching(false);
        setError(formatMessage(messages.errorMessage));
      });
  }, [formatMessage, props]);

  const { disabled, Icon, label } = props;

  return (
    <div>
      <Button
        disabled={disabled || fetching}
        startIcon={
          fetching ? (
            <CircularProgress size={24} />
          ) : Icon ? (
            <Icon />
          ) : (
            undefined
          )
        }
        variant="text"
        onClick={handleDownloadButtonClick}
      >
        {label}
      </Button>
      {error && !fetching ? (
        <div style={{ color: red["A700"], marginLeft: 15 }}>{error}</div>
      ) : null}
    </div>
  );
});
