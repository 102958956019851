import { TableCell } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import React, { useCallback } from "react";

interface ClickableTableCellProps<T extends string> {
  id: T;
  label: JSX.Element | string;
  onClick: (column: T) => void;
}

export function ClickableTableCell<T extends string>({
  id,
  label,
  onClick
}: ClickableTableCellProps<T>): JSX.Element {
  const handleHeaderClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);
  return (
    <TableCell style={{ cursor: "pointer" }} onClick={handleHeaderClick}>
      {label}{" "}
      <div style={{ float: "right", height: 20 }}>
        <HelpIcon color="primary" fontSize="small" />
      </div>
    </TableCell>
  );
}
