import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  defineMessages,
  injectIntl
} from "react-intl";

import "react-image-lightbox/style.css";
import { Button, Card, Typography } from "@material-ui/core";
import {
  CREATE_STANDARD_PHOTO,
  STANDARD_PHOTOS
} from "../../../graphql/standard-photos";
import {
  LazyLoadImage,
  trackWindowScroll
} from "react-lazy-load-image-component";
import { Upload } from "../../../components/upload";
import { withApollo } from "@apollo/client/react/hoc";
import { withAppContext } from "../../../utils/with-app-context";
import { withRouter } from "react-router";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Lightbox from "react-image-lightbox";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import _ from "lodash";

export const fileInstanceToURL = (photo, width) => {
  return `/files/get/${photo.file.id}/${photo.file.filename}?sharekey=${
    photo.file.share_key
  }${width ? `&width=${width}` : ""}`;
};

class PhotoCard extends PureComponent {
  static propTypes = {
    onFileSelected: PropTypes.func,
    onPhotoClick: PropTypes.func.isRequired,
    photo: PropTypes.object.isRequired,
    photoIndex: PropTypes.number.isRequired,
    scrollPosition: PropTypes.object.isRequired
  };

  handleClick = () => this.props.onPhotoClick(this.props.photoIndex);

  handleFileSelected = () => this.props.onFileSelected([this.props.photo.file]);

  render() {
    const { onFileSelected, photo, scrollPosition } = this.props;
    return (
      <Card
        square
        style={{
          display: "flex",
          flexDirection: "column",
          margin: 5,
          maxHeight: "220px",
          maxWidth: "160px",
          minHeight: "220px",
          minWidth: "160px",
          overflow: "hidden",
          textAlign: "center"
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <LazyLoadImage
            alt={photo.file.filename}
            effect="opacity"
            scrollPosition={scrollPosition}
            src={fileInstanceToURL(photo, 160)}
            style={{ maxHeight: 125 }}
            onClick={this.handleClick}
          />
          <div style={{ textAlign: "center" }}>{photo.title}</div>
          <div style={{ fontSize: 10, textAlign: "center" }}>
            <FormattedDate value={photo.file.created} />{" "}
            <FormattedTime value={photo.file.created} />
          </div>
        </div>
        {!onFileSelected ? (
          <Button component="a" href={`#/settings/photos/${photo.id}`}>
            <FormattedMessage defaultMessage="Redigér" id="photo-card.edit" />
          </Button>
        ) : (
          <Button onClick={this.handleFileSelected}>
            <FormattedMessage defaultMessage="Vælg" id="photo-card.select" />
          </Button>
        )}
      </Card>
    );
  }
}

const appRoot = document.getElementById("root");

const categoryToTitle = defineMessages({
  "building-inspection": {
    defaultMessage: "Byggetilsyn",
    id: "standard-photos-form.building-inspection"
  },
  insurance: {
    defaultMessage: "Forsikring",
    id: "standard-photos-form.insurance"
  },
  "moisture-technology": {
    defaultMessage: "Fugtteknik",
    id: "standard-photos-form.moisture-technology"
  },
  mold: {
    defaultMessage: "Skimmel",
    id: "standard-photos-form.mold"
  },
  other: {
    defaultMessage: "Andet",
    id: "standard-photos-form.other"
  }
});

class StandardPhotoList extends PureComponent {
  static propTypes = {
    client: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    onFileSelected: PropTypes.func,
    scrollPosition: PropTypes.object.isRequired,
    searchString: PropTypes.string
  };

  state = {
    photoIndex: null,
    photos: [],
    uploadError: false
  };
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
  }
  componentDidMount() {
    this.fetchData(this.props.searchString);
    appRoot.appendChild(this.el);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.searchString !== prevProps.searchString) {
      this.fetchData(this.props.searchString);
    }
  }

  componentWillUnmount() {
    appRoot.removeChild(this.el);
  }

  addPhoto = async files => {
    const file = files[0];
    const returning = await this.props.client.mutate({
      mutation: CREATE_STANDARD_PHOTO,
      variables: {
        standard_photos: [
          {
            file_id: file.id,
            title: file.filename
          }
        ]
      }
    });
    const photo = returning.data.insert_standard_photos.returning[0];
    const currentPhotos = [...this.state.photos];
    currentPhotos.push(photo);

    this.setState({ photos: currentPhotos });
  };

  handleFileUploaded = file => {
    this.addPhoto(file);
  };

  fetchData = async searchString => {
    this.props.context.setLoading(true);
    this.setState({ error: false });
    const variables = {
      orderBy: { file: { created: "desc" } }
    };
    if (searchString) {
      variables.where = { title: { _ilike: `%${searchString}%` } };
    }
    try {
      this.props.client.stop();
      const response = await this.props.client.query({
        fetchPolicy: "network-only",
        query: STANDARD_PHOTOS,
        variables
      });
      this.setState({ photos: response.data.standard_photos });
      this.props.context.setLoading(false);
    } catch (e) {
      this.props.context.setLoading(false);
      this.setState({ error: true });
      // eslint-disable-next-line no-console
      console.log(e);
      return [];
    }
  };

  handlePhotoClick = photoIndex => {
    this.setState({ photoIndex });
  };

  handleUploadError = () => {
    this.setState({ uploadError: true });
  };

  handleUploadStart = () => {
    this.setState({ uploadError: false });
  };

  render() {
    const groupedPhotos = {
      "": [],
      "building-inspection": [],
      insurance: [],
      "moisture-technology": [],
      mold: [],
      other: []
    };
    const { intl, onFileSelected, scrollPosition } = this.props;
    this.state.photos.forEach(photo => {
      const { category } = photo;
      const group = category || ""; // wtf is this needed?

      groupedPhotos[group].push(photo);
    }, {});

    let photoIndexCounter = -1;
    return (
      <div style={{ margin: 10 }}>
        {this.state.uploadError ? (
          <Typography color="error" variant="h5">
            <FormattedMessage
              defaultMessage="Der skete en fejl. Ikke alle filer er blevet uploadet"
              id="standard-photo-list.error"
            />
          </Typography>
        ) : null}
        {Object.keys(groupedPhotos).map((category, index) => {
          return !groupedPhotos[category].length ? null : (
            <div key={index}>
              <Typography variant="h5">
                {!category ? (
                  <FormattedMessage
                    defaultMessage="Ingen kategori"
                    id="standard-photo-list.no-category"
                  />
                ) : (
                  intl.formatMessage(categoryToTitle[category])
                )}
                <hr />
              </Typography>
              <div
                style={{
                  alignItems: "flex-start",
                  display: "flex",
                  flexWrap: "wrap"
                }}
              >
                {groupedPhotos[category].map(photo => {
                  photoIndexCounter += 1;
                  return (
                    <PhotoCard
                      key={photo.id}
                      photo={photo}
                      photoIndex={photoIndexCounter}
                      scrollPosition={scrollPosition}
                      onFileSelected={onFileSelected}
                      onPhotoClick={this.handlePhotoClick}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
        {this.state.photoIndex !== null &&
          ReactDOM.createPortal(
            <Lightbox
              mainSrc={fileInstanceToURL(
                this.state.photos[this.state.photoIndex],
                800
              )}
              nextSrc={fileInstanceToURL(
                this.state.photos[
                  (this.state.photoIndex + 1) % this.state.photos.length
                ],
                800
              )}
              prevSrc={fileInstanceToURL(
                this.state.photos[
                  (this.state.photoIndex + this.state.photos.length - 1) %
                    this.state.photos.length
                ],
                800
              )}
              reactModalStyle={{ overlay: { zIndex: "1300" } }}
              toolbarButtons={[
                <Button
                  key={1}
                  component="a"
                  href={fileInstanceToURL(
                    this.state.photos[this.state.photoIndex]
                  )}
                  style={{ color: "#FFF" }}
                >
                  <CloudDownloadIcon />
                </Button>
              ]}
              onCloseRequest={() => this.setState({ photoIndex: null })}
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex:
                    (this.state.photoIndex + 1) % this.state.photos.length
                })
              }
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex:
                    (this.state.photoIndex + this.state.photos.length - 1) %
                    this.state.photos.length
                })
              }
            />,
            this.el
          )}
        {!onFileSelected ? (
          <>
            <Upload
              accept="Image/*"
              onError={this.handleUploadError}
              onFileUploaded={this.handleFileUploaded}
              onUploadStart={this.handleUploadStart}
            />
            <Upload
              capture
              accept="Image/*"
              row={2}
              onError={this.handleUploadError}
              onFileUploaded={this.handleFileUploaded}
              onUploadStart={this.handleUploadStart}
            />
          </>
        ) : null}
      </div>
    );
  }
}

const EnhancedStandardPhotoList = withAppContext(
  withRouter(injectIntl(withApollo(trackWindowScroll(StandardPhotoList))))
);
export { EnhancedStandardPhotoList as StandardPhotoList };
