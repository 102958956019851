import {DialogBase} from "./dialog";
import {DialogContent, TextField} from "@material-ui/core";
import {FormattedMessage, defineMessages, injectIntl} from "react-intl";
import { StandardPhotoList } from "../pages/settings/photos/list";
import PropTypes from "prop-types";
import React, {useCallback, useEffect, useState} from "react";

const messages = defineMessages({
  title: {
    defaultMessage: "Søg arkivfoto",
    id: "select-standard-photo-dialog.title"
  }
});

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export const SelectStandardPhotoDialog = React.memo(
  injectIntl(({intl: {formatMessage}, onCancel, onFileSelected, open}) => {
    const [searchString, setSearchString] = useState("");

    const handleSearchFieldChange = useCallback(value => {
      setSearchString(value);
    }, []);
    const debouncedSearchString = useDebounce(searchString, 500)
    return (
      <DialogBase
        fullscreen
        open={open}
        title={formatMessage(messages.title)}

        onCancel={onCancel}
        onSearchChanged={handleSearchFieldChange}
      >
        <DialogContent>

        <StandardPhotoList searchString={debouncedSearchString} onFileSelected={onFileSelected}/>
        </DialogContent>
      </DialogBase>
    );
  })
);

SelectStandardPhotoDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func,
  open: PropTypes.bool.isRequired,
};
