import {
  AppBar,
  IconButton,
  InputBase,
  Toolbar,
  Typography
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { fade, makeStyles } from "@material-ui/core/styles";
import Bowser from "bowser";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  inputInput: {
    "&::placeholder": {
      color: "#fff",
      opacity: 0.87
    },
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  search: {
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    backgroundColor: fade(theme.palette.common.white, 0.15),
    borderRadius: theme.shape.borderRadius,
    marginLeft: 0,
    marginRight: 0,
    position: "relative",
    width: "100%"
  },
  searchIcon: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    pointerEvents: "none",
    position: "absolute",
    width: theme.spacing(7)
  },
  title: {
    flex: 1,
    marginLeft: theme.spacing(2)
  }
}));

export const DialogBase = React.memo(
  ({
    cancelLabel,
    children,
    fullscreen,
    okDisabled,
    okLabel,
    onCancel,
    onOk,
    onSearchChanged,
    open,
    title
  }) => {
    const classes = useStyles();
    const browser = Bowser.getParser(window.navigator.userAgent);
    let appBar;
    let actions;
    const isMobile = browser.getPlatformType(true) !== "desktop";
    const [filterString, setFilterString] = useState("");

    const handleFilterFieldChange = useCallback(event => {
      setFilterString(event.target.value);
    }, []);

    const handleFilterFieldKeyDown = useCallback(
      event => {
        if (onSearchChanged) {
          onSearchChanged(event.target.value);
        }
      },
      [onSearchChanged]
    );
    if (fullscreen || isMobile) {
      appBar = (
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              aria-label="Close"
              color="inherit"
              edge="start"
              onClick={onCancel}
            >
              <CloseIcon />
            </IconButton>

            {onSearchChanged ? (
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  classes={{
                    input: classes.inputInput,
                    root: classes.inputRoot
                  }}
                  placeholder={title}
                  value={filterString}
                  onChange={handleFilterFieldChange}
                  onKeyDown={handleFilterFieldKeyDown}
                />
              </div>
            ) : (
              <Typography className={classes.title} variant="h6">
                {title}
              </Typography>
            )}
            {onOk ? (
              <Button color="inherit" disabled={okDisabled} onClick={onOk}>
                {okLabel ? (
                  okLabel
                ) : (
                  <FormattedMessage defaultMessage="Ok" id="dialog.ok" />
                )}
              </Button>
            ) : null}
          </Toolbar>
        </AppBar>
      );
    } else {
      actions = (
        <DialogActions>
          <Button color="primary" onClick={onCancel}>
            {cancelLabel ? (
              cancelLabel
            ) : (
              <FormattedMessage defaultMessage="Annulér" id="dialog.cancel" />
            )}
          </Button>
          {onOk ? (
            <Button color="primary" disabled={okDisabled} onClick={onOk}>
              {okLabel ? (
                okLabel
              ) : (
                <FormattedMessage defaultMessage="Ok" id="dialog.ok" />
              )}
            </Button>
          ) : null}
        </DialogActions>
      );
    }
    return (
      <Dialog
        fullWidth
        aria-labelledby="form-dialog-title"
        fullScreen={fullscreen || isMobile}
        open={open}
        onClose={onCancel}
      >
        {appBar ? (
          appBar
        ) : (
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        )}
        {children}
        {actions}
      </Dialog>
    );
  }
);

DialogBase.propTypes = {
  children: PropTypes.element,
  fullscreen: PropTypes.bool,
  okDisabled: PropTypes.bool,
  okLabel: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element.isRequired
  ]).isRequired
};

DialogBase.displayName = "DialogBase";
