/* eslint-disable react/display-name */
import { ArrowDialog } from "./arrow-dialog";
import { CREATE_CASE_PHOTO } from "../graphql/cases";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Grid, IconButton, Paper } from "@material-ui/core";
import { RichTextEditor } from "./draftjs-formik";
import { SelectCasePhotoDialog } from "./select-case-photo-dialog";
import { SelectStandardPhotoDialog } from "./select-standard-photo-dialog";
import { Upload } from "./upload";
import { defineMessages, useIntl } from "react-intl";
import { fileInstanceToURL } from "../pages/case/photos/list";
import { useApolloClient } from "@apollo/client";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import FolderIcon from "@material-ui/icons/Folder";
import ImageIcon from "@material-ui/icons/Image";
import PropTypes from "prop-types";
import React, { Component, useCallback, useState } from "react";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import uuid from "uuid/v4";

const messages = defineMessages({
  description: {
    defaultMessage: "Beskrivelse",
    id: "request-list.description"
  },
  price: {
    defaultMessage: "Pris",
    id: "request-list.price"
  },
  title: {
    defaultMessage: "Titel",
    id: "request-list.title"
  }
});

const EMPTY_TASK = { description: "", file_id: null, title: "" };

const Divider = React.memo(({ index, onClick }) => {
  const handleAddTask = useCallback(() => {
    onClick({ ...EMPTY_TASK, order: index });
  }, [onClick, index]);
  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div style={{ flex: 1, paddingTop: 14 }}>
        <hr />
      </div>
      <div style={{ width: 48 }}>
        <IconButton aria-label="Save" onClick={handleAddTask}>
          <AddIcon />
        </IconButton>
      </div>
      <div style={{ flex: 1, paddingTop: 14 }}>
        <hr />
      </div>
    </div>
  );
});
Divider.propTypes = {
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

const taskComperator = (a, b) => a.order - b.order;
const TaskCards = React.memo(
  ({ caseId, onDelete, onSave, tasks, useArrows, useCasePhotos }) => {
    if (!tasks || tasks.length === 0) {
      return null;
    }
    return tasks.sort(taskComperator).map((row, index) => (
      <Draggable key={row.id} draggableId={row.id} index={index}>
        {provided => (
          <div
            ref={provided.innerRef}
            style={{ paddingBottom: 10 }}
            {...provided.draggableProps}
          >
            <TaskForm
              caseId={caseId}
              dragHandleProps={provided.dragHandleProps}
              initialValues={row}
              useArrows={useArrows}
              useCasePhotos={useCasePhotos}
              onDelete={onDelete}
              onSave={onSave}
            />
            <Divider index={index} onClick={onSave} />
          </div>
        )}
      </Draggable>
    ));
  }
);
TaskCards.propTypes = {
  caseId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  tasks: PropTypes.array,
  useArrows: PropTypes.bool.isRequired,
  useCasePhotos: PropTypes.bool.isRequired
};

const TaskForm = React.memo(
  ({
    caseId,
    dragHandleProps,
    initialValues,
    onDelete,
    onSave,
    useArrows,
    useCasePhotos
  }) => {
    const [values, setValues] = useState({ ...initialValues });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [caseDialogOpen, setCaseDialogOpen] = useState(false);
    const [arrowDialogOpen, setArrowDialogOpen] = useState(false);
    const intl = useIntl();
    const client = useApolloClient();
    const handleAddTask = useCallback(() => {
      onSave(values);
      setValues(initialValues);
    }, [values, onSave, setValues, initialValues]);

    React.useEffect(() => {
      if (!values.id) {
        return;
      }
      setValues(initialValues);
    }, [initialValues, values.id]);

    const handleRemoveTask = useCallback(() => {
      onDelete(values.id);
    }, [values, onDelete]);

    const handleChange = useCallback(
      event => {
        const copy = { ...values, [event.target.name]: event.target.value };
        setValues(copy);
        if (copy.id) {
          onSave(copy);
        }
      },
      [values, onSave, setValues]
    );

    const handleOpenDialog = useCallback(() => {
      setDialogOpen(true);
    }, [setDialogOpen]);

    const handleCloseDialog = useCallback(() => {
      setDialogOpen(false);
    }, [setDialogOpen]);

    const handleOpenCaseDialog = useCallback(() => {
      setCaseDialogOpen(true);
    }, [setCaseDialogOpen]);

    const handleCloseCaseDialog = useCallback(() => {
      setCaseDialogOpen(false);
    }, [setCaseDialogOpen]);

    const handleOpenArrowDialog = useCallback(() => {
      setArrowDialogOpen(true);
    }, [setArrowDialogOpen]);

    const handleCloseArrowDialog = useCallback(() => {
      setArrowDialogOpen(false);
    }, [setArrowDialogOpen]);

    const handleUpload = useCallback(
      files => {
        const file = files[0];

        client.mutate({
          mutation: CREATE_CASE_PHOTO,
          variables: {
            case_photos: [
              {
                case_id: caseId,
                file_id: file.id,
                title: file.filename
              }
            ]
          }
        });

        const copy = {
          ...values,
          file: {
            filename: file.filename,
            id: file.id,
            share_key: file.share_key
          },
          file_id: file.id
        };
        setValues(copy);
        if (copy.id) {
          onSave(copy);
        }
      },
      [client, caseId, values, onSave]
    );
    const handleCasePhotoSelected = useCallback(
      files => {
        handleCloseDialog();
        handleCloseCaseDialog();
        let copy;
        if (!files) {
          copy = {
            ...values,
            file: null,
            file_id: null
          };
        } else {
          const file = files[0];
          copy = {
            ...values,
            file: {
              filename: file.filename,
              id: file.id,
              share_key: file.share_key
            },
            file_id: file.id
          };
        }
        setValues(copy);
        if (copy.id) {
          onSave(copy);
        }
      },
      [handleCloseDialog, handleCloseCaseDialog, values, onSave]
    );

    const handleArrowsDialogOk = useCallback(
      value => {
        handleCloseArrowDialog();
        handleChange({
          target: {
            name: "arrows",
            value
          }
        });
      },
      [handleChange, handleCloseArrowDialog]
    );
    return (
      <Paper style={{ padding: 10 }}>
        <div style={{ display: "flex", width: "100%" }}>
          {values.id ? (
            <div
              style={{
                alignItems: "center",

                display: "flex",
                justifyContent: "center",
                width: 48
              }}
            >
              <IconButton
                aria-label="Save"
                style={{ alignSelf: "flex-end" }}
                onClick={handleRemoveTask}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </div>
          ) : null}
          <div style={{ flex: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={intl.formatMessage(messages.title)}
                  name="title"
                  type="text"
                  value={values.title}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RichTextEditor
                  label={intl.formatMessage(messages.description)}
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </div>
          {values.file && values.file.id ? (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                width: 200
              }}
            >
              <div style={{ position: "relative" }}>
                {values.arrows && values.arrows.length ? (
                  <ArrowBackIcon
                    style={{ color: "red", position: "absolute" }}
                  />
                ) : null}
                <img src={fileInstanceToURL(values, 552)} width="200" />
              </div>
            </div>
          ) : null}
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              height: 250,
              justifyContent: "space-evenly",
              width: 48
            }}
          >
            {!values.id ? (
              <IconButton aria-label="Save" onClick={handleAddTask}>
                <DoneIcon />
              </IconButton>
            ) : null}
            {useCasePhotos ? (
              <>
                <IconButton
                  aria-label="Open Case Dialog"
                  onClick={handleOpenCaseDialog}
                >
                  <ImageIcon />
                </IconButton>
                <Upload
                  capture
                  accept="Image/*"
                  floating={false}
                  onFileUploaded={handleUpload}
                />
              </>
            ) : null}
            <IconButton aria-label="Open Dialog" onClick={handleOpenDialog}>
              <FolderIcon />
            </IconButton>
            {useArrows ? (
              <IconButton
                aria-label="Open Dialog"
                disabled={!values.file}
                onClick={handleOpenArrowDialog}
              >
                <ArrowBackIcon />
              </IconButton>
            ) : null}
            {values.id ? (
              <div {...dragHandleProps}>
                <DragHandleIcon />
              </div>
            ) : null}
          </div>
        </div>
        <SelectStandardPhotoDialog
          open={dialogOpen}
          onCancel={handleCloseDialog}
          onFileSelected={handleCasePhotoSelected}
        />
        {useCasePhotos ? (
          <SelectCasePhotoDialog
            open={caseDialogOpen}
            onCancel={handleCloseCaseDialog}
            onFileSelected={handleCasePhotoSelected}
          />
        ) : null}
        {useArrows && values.file && values.file.id ? (
          <ArrowDialog
            arrows={values.arrows}
            file={values.file}
            open={arrowDialogOpen}
            taskId={values.id}
            onCancel={handleCloseArrowDialog}
            onOk={handleArrowsDialogOk}
          />
        ) : null}
      </Paper>
    );
  },
  (prevProps, nextProps) => {
    return _.isEqual(prevProps.initialValues, nextProps.initialValues);
  }
);

TaskForm.propTypes = {
  caseId: PropTypes.string.isRequired,
  dragHandleProps: PropTypes.object,
  initialValues: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  useArrows: PropTypes.bool,
  useCasePhotos: PropTypes.bool
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export class Tasks extends Component {
  static propTypes = {
    caseId: PropTypes.string.isRequired,
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    useArrows: PropTypes.bool,
    useCasePhotos: PropTypes.bool
  };

  static defaultProps = {
    useArrows: false,
    useCasePhotos: false
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState)) {
      return false;
    }
    return true;
  }

  handleSave = task => {
    const {
      field: { name, value },
      form: { setFieldValue }
    } = this.props;
    const oldTasks = value || [];

    let newTasks = [];
    if (task.id) {
      const index = oldTasks.findIndex(t => t.id === task.id);
      newTasks = [...oldTasks];
      newTasks[index] = task;
    } else {
      task.id = uuid();
      if (task.order === undefined) {
        task.order = oldTasks.length;
        newTasks = [...oldTasks, task];
      } else {
        newTasks = [...oldTasks];
        newTasks.splice(task.order + 1, 0, task);
        newTasks = newTasks.map((t, index) => {
          t.order = index;
          return t;
        });
      }
    }
    setFieldValue(name, newTasks);
  };

  handleDelete = taskId => {
    const {
      field: { name, value },
      form: { setFieldValue }
    } = this.props;
    const tasksCopy = [...value];

    setFieldValue(
      name,
      tasksCopy.filter(t => t.id !== taskId)
    );
  };

  handleDragEnd = result => {
    const {
      field: { name, value },
      form: { setFieldValue }
    } = this.props;
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const tasks = reorder(value, result.source.index, result.destination.index);

    const newTasks = tasks.map((t, index) => {
      t.order = index;
      return t;
    });
    setFieldValue(name, newTasks);
  };
  render() {
    const {
      caseId,
      field: { value },
      useArrows,
      useCasePhotos
    } = this.props;
    return (
      <>
        <TaskForm
          caseId={caseId}
          initialValues={{ ...EMPTY_TASK }}
          useArrows={useArrows}
          useCasePhotos={useCasePhotos}
          onSave={this.handleSave}
        />
        <br />
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable droppableId="list">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <TaskCards
                  caseId={caseId}
                  tasks={value}
                  useArrows={useArrows}
                  useCasePhotos={useCasePhotos}
                  onDelete={this.handleDelete}
                  onSave={this.handleSave}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </>
    );
  }
}
