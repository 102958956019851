import gql from "graphql-tag";

export const CONTACTS_AGGREGATED_QUERY = gql`
  query list_contacts(
    $orderBy: contacts_order_by!
    $where: contacts_bool_exp
    $offset: Int
    $limit: Int
  ) {
    contacts_aggregate(
      offset: $offset
      order_by: [$orderBy]
      where: $where
      limit: $limit
    ) {
      aggregate {
        count
      }
      nodes {
        id
        first_name
        last_name
        company
        address
        zip_code
        city
        phone
        mobile
        mail
        type
      }
    }
  }
`;

export const GET_CONTACT_WITH_NAME_MOBILE = gql`
  query get_contact($mobile: String!, $first_name: String!, $last_name: String!) {
    contacts(where: {mobile: {_eq: $mobile}, _and: {first_name: {_eq: $first_name}, _and: {last_name: {_eq: $last_name}}}}) {
      id
      address
      city
      company
      mail
      first_name
      last_name
      mobile
      phone
      zip_code
    }
  }
`;

export const GET_CONTACT = gql`
  query get_contacts($contactId: uuid) {
    contacts(where: { id: { _eq: $contactId } }) {
      id
      address
      city
      company
      mail
      first_name
      last_name
      mobile
      phone
      zip_code
      type
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation insert_contact($contact: contacts_insert_input!) {
    insert_contacts(objects: [$contact]) {
      returning {
        id
        address
        city
        company
        mail
        first_name
        last_name
        mobile
        phone
        zip_code
      }
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation update_contact($id: uuid, $contact: contacts_set_input!) {
    update_contacts(where: { id: { _eq: $id } }, _set: $contact) {
      returning {
        id
        address
        city
        company
        mail
        first_name
        last_name
        mobile
        phone
        zip_code
        type
      }
    }
  }
`;
