import * as yup from "yup";
import {
  CREATE_REMINDER,
  CREATE_REMINDER_RECIPIENT,
  UPDATE_REMINDER
} from "../graphql/reminders";
import { DialogBase } from "./dialog";
import { DialogContent } from "@material-ui/core";
import { RemindersForm } from "./reminders/reminders-form";
import { flowRight as compose } from "lodash";
import { defineMessages, injectIntl } from "react-intl";
import { formatUserLabel } from "./user-select";
import { graphql, withApollo } from "@apollo/client/react/hoc";
import { withAppContext } from "../utils/with-app-context";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

const messages = defineMessages({
  add: {
    defaultMessage: "Tilføj",
    id: "reminder-entry.add"
  },
  createNew: {
    defaultMessage: "Opret ny påmindelse",
    id: "contact-entry.create-new-contact"
  },
  reminders: {
    defaultMessage: "Påmindelse",
    id: "reminder-entry.reminder"
  },
  update: {
    defaultMessage: "Redigér påmindelse",
    id: "contact-entry.update"
  }
});

const schema = yup.object().shape({
  case_id: yup.object().required(),
  date: yup
    .string()
    .nullable()
    .required(),
  message: yup.string().required(),
  recipients: yup.array().required()
});

class ReminderCreateDialog extends PureComponent {
  static propTypes = {
    caseInfo: PropTypes.object,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    open: PropTypes.bool,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired
  };

  render() {
    const {
      caseInfo,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      intl: { formatMessage },
      isSubmitting,
      onCancel,
      open,
      touched,
      values
    } = this.props;

    return (
      <DialogBase
        open={open}
        title={
          values.id
            ? formatMessage(messages.update)
            : formatMessage(messages.createNew)
        }
        onCancel={onCancel}
        onOk={handleSubmit}
      >
        <DialogContent>
          <div style={{ height: 400 }}>
            <RemindersForm
              caseInfo={caseInfo}
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              touched={touched}
              values={values}
            />
          </div>
        </DialogContent>
      </DialogBase>
    );
  }
}

const getId = caseId => {
  if (caseId) {
    return caseId.id || caseId;
  }
  return null;
};

const handleSubmit = async (payload, { props, setErrors, setSubmitting }) => {
  const { case_id, date, id, message, recipients, request_id } = payload;
  const variables = {
    reminder: {
      case_id: getId(case_id),
      creator_id: props.context.state.currentUser.id,
      date,
      message,
      request_id
    }
  };

  if (props.caseInfo) {
    const { key, value } = props.caseInfo;
    variables.reminder[key] = value;
  }

  const { createReminder, createReminderRecipient, updateReminder } = props;
  try {
    const response = await createReminder({
      variables
    });
    const createdReminderId = response.data.insert_reminders.returning[0].id;
    const reminderRecipients = recipients.map(recipient => ({
      reminder_id: createdReminderId,
      user_id: recipient.user ? recipient.user.id : recipient.id
    }));

    await createReminderRecipient({
      variables: { reminder_recipients: reminderRecipients }
    });

    if (id) {
      const updateVariables = {
        id,
        reminder: {
          next_reminder_id: createdReminderId
        }
      };
      await updateReminder({ variables: updateVariables });
    }
    props.onReminderCreated(response.data.insert_reminders.returning[0]);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    const errors = e.graphQLErrors.map(error => error.message);
    // eslint-disable-next-line no-console
    console.log(errors);
    setSubmitting(false);
    setErrors({ form: errors });
  }
};

const EnhancedReminderCreateDialog = compose(
  withApollo,
  withAppContext,
  graphql(CREATE_REMINDER, { name: "createReminder" }),
  graphql(UPDATE_REMINDER, { name: "updateReminder" }),
  graphql(CREATE_REMINDER_RECIPIENT, { name: "createReminderRecipient" }),
  withFormik({
    displayName: "ReminderCreateDialog",
    handleSubmit,
    mapPropsToValues: ({ context, variables }) => {
      let recipients;
      let date;
      if (variables) {
        date = variables.date || null;
        ({ recipients } = variables);
      }
      if (!variables || !recipients) {
        recipients = [
          {
            ...context.state.currentUser,
            label: formatUserLabel(context.state.currentUser),
            value: context.state.currentUser.id
          }
        ];
      }
      return {
        ...variables,
        date,
        recipients
      };
    },
    validationSchema: props => {
      if (!props.variables) {
        return schema;
      }
      return yup.object().shape({
        date: yup
          .string()
          .nullable()
          .required()
      });
    }
  }),
  withRouter,
  injectIntl
)(ReminderCreateDialog);

//

export { EnhancedReminderCreateDialog as ReminderCreateDialog };
