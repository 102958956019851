import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { FormattedMessage } from "react-intl";
import { useQuery } from "@apollo/client";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import gql from "graphql-tag";
import moment from "moment";
import tinycolor2 from "tinycolor2";

const GET_CASE_STATS = gql`
  query case_stats(
    $startMonth: String
    $endMonth: String
    $timestamp: timestamptz
  ) {
    cases(
      where: { created: { _lte: $timestamp }, closed: { _gte: $timestamp } }
    ) {
      case_type {
        name
      }
    }

    cases_case_type_count(order_by: { count: desc }) {
      case_type {
        name
      }
      count
      case_type_id
    }
    cases_case_count_per_employee(
      order_by: { responsible: { employee_number: asc } }
    ) {
      count
      responsible {
        first_name
        last_name
        acronym
        employee_number
      }
      responsible_id
    }

    cases_building_inspection_per_employee(
      order_by: { responsible: { employee_number: asc } }
    ) {
      case_type_id
      count
      responsible_id
      case_type {
        name
      }
      responsible {
        first_name
        last_name
        acronym
        employee_number
      }
    }

    cases_per_case_type_per_month(
      where: {
        _and: { year_month: { _lte: $endMonth } }
        year_month: { _gte: $startMonth }
      }
      order_by: { year_month: asc }
    ) {
      case_type_id
      count
      year_month
      case_type {
        color
        name
      }
    }
  }
`;

export function CaseOverview(): JSX.Element {
  const startMonth = moment()
    .subtract(24, "months")
    .toDate();
  const endMonth = new Date();

  const lastYearTimestamp = useMemo(
    () =>
      moment()
        .subtract(12, "months")
        .toISOString(),
    []
  );

  const { data, error, loading } = useQuery(GET_CASE_STATS, {
    variables: {
      endMonth: `${endMonth.getFullYear()}-${
        endMonth.getMonth() < 10
          ? `0${endMonth.getMonth()}`
          : endMonth.getMonth()
      }`,
      startMonth: `${startMonth.getFullYear()}-${
        startMonth.getMonth() < 10
          ? `0${startMonth.getMonth()}`
          : startMonth.getMonth()
      }`,
      timestamp: lastYearTimestamp
    }
  });

  let totalCaseTypeCount = 0;
  let totalEmployeeCaseCount = 0;
  let totalCaseTypeCountLastYear = 0;

  const [graphLines, setGraphLines] = useState<Map<string, string> | null>();
  const [graphData, setGraphData] = useState<
    { [key: string]: string | number }[] | null
  >();

  const [activeLines, setActiveLines] = useState<Set<string> | null>();

  const buildingInspectionData = useMemo(() => {
    const buildingInspectionDataTemp = new Map<
      string,
      { id: string; responsible: any; started: number; waiting: number }
    >();
    if (loading || error || !data) {
      return buildingInspectionDataTemp;
    }

    data.cases_building_inspection_per_employee.forEach(instance => {
      // Byggetilsynspakke
      const currentEmployee = buildingInspectionDataTemp.get(
        instance.responsible_id
      );
      if (!currentEmployee) {
        if (instance.case_type_id === "13b75bf9-57c9-42ad-8c6d-f056e8f394e1") {
          buildingInspectionDataTemp.set(instance.responsible_id, {
            id: instance.respsible_id,
            responsible: instance.responsible,
            started: instance.count,
            waiting: 0
          });
        } else {
          buildingInspectionDataTemp.set(instance.responsible_id, {
            id: instance.respsible_id,
            responsible: instance.responsible,
            started: 0,
            waiting: instance.count
          });
        }
      } else {
        if (instance.case_type_id === "13b75bf9-57c9-42ad-8c6d-f056e8f394e1") {
          currentEmployee.started = instance.count;
        } else {
          currentEmployee.waiting = instance.count;
        }
      }
    });
    return buildingInspectionDataTemp;
  }, [data, error, loading]);

  useEffect(() => {
    if (!loading && !error && data) {
      const graphLinesTemp: Map<string, string> = new Map();
      const graphDataTemp: { [key: string]: string | number }[] = [];

      const cut = moment()
        .subtract(12, "months")
        .format("YYYY-MM");

      data.cases_per_case_type_per_month.forEach(instance => {
        const pastYear = instance.year_month <= cut;
        let yearMonth = instance.year_month;
        const caseType = instance.case_type;
        let color = caseType?.color ?? "#000";
        if (pastYear) {
          const monthSplit = yearMonth.split("-");
          yearMonth = `${parseInt(monthSplit[0]) + 1}-${monthSplit[1]}`;
          color = tinycolor2(color)
            .spin(180)
            .toHexString();
        }
        const existing = graphDataTemp.find(row => row.name === yearMonth);
        const caseTypeName = pastYear
          ? `${caseType?.name ?? "Ingen sagstype"} - Forrige år`
          : caseType?.name ?? "Ingen sagstype";
        graphLinesTemp.set(caseTypeName, color);
        if (existing) {
          existing[caseTypeName] = instance.count;
        } else {
          graphDataTemp.push({
            name: yearMonth,
            [instance.case_type?.name ?? "Ingen sagstype"]: instance.count
          });
        }
      });
      setGraphLines(graphLinesTemp);
      setGraphData(graphDataTemp);
      setActiveLines(new Set(graphLinesTemp.keys()));
    }
  }, [data, error, loading]);

  const activeCasesLastYear = useMemo(() => {
    const activeCases = new Map<string, number>();
    if (!loading && !error && data) {
      data.cases.forEach(caseInstance => {
        activeCases.set(
          caseInstance.case_type?.name,
          (activeCases.get(caseInstance.case_type?.name) || 0) + 1
        );
      });
    }
    return activeCases;
  }, [data, error, loading]);

  const handleCheckboxChanged = useCallback(
    (event: any, value: boolean) => {
      const key = event.target.name;
      const newActiveLines = new Set(activeLines);

      if (value) {
        newActiveLines.add(key);
      } else {
        newActiveLines.delete(key);
      }
      setActiveLines(newActiveLines);
    },
    [activeLines]
  );

  const handleSelectAll = useCallback(() => {
    if (activeLines && activeLines.size) {
      setActiveLines(new Set());
    } else {
      setActiveLines(new Set(graphLines.keys()));
    }
  }, [activeLines, graphLines]);

  const caseTypeBlock = [];
  let checkboxes = [];
  if (graphLines) {
    const sortedLines = [...graphLines.keys()];
    sortedLines.sort();
    sortedLines.forEach(key => {
      checkboxes.push(
        <div key={key} style={{ position: "relative" }}>
          <span
            style={{
              backgroundColor: graphLines.get(key),
              display: "inline-block",
              height: 18,
              position: "relative",
              top: 5,
              width: 18
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={activeLines.has(key)}
                name={key}
                onChange={handleCheckboxChanged}
              />
            }
            label={key}
            style={{ width: "90%" }}
          />
        </div>
      );
      if (checkboxes.length === 10) {
        caseTypeBlock.push(
          <Grid key={caseTypeBlock.length} item lg={3}>
            {checkboxes}
          </Grid>
        );
        checkboxes = [];
      }
    });
  }
  if (checkboxes.length) {
    caseTypeBlock.push(
      <Grid key={caseTypeBlock.length} item lg={3}>
        {checkboxes}
      </Grid>
    );
  }

  caseTypeBlock.push(
    <Grid key={caseTypeBlock.length} item lg={3}>
      <Button color="primary" variant="contained" onClick={handleSelectAll}>
        <FormattedMessage
          defaultMessage="Vælg/fravælg alle"
          id="case-overview.select-all"
        />
      </Button>
    </Grid>
  );

  return (
    <div
      style={{
        minHeight: "calc(100% - 64px)",
        padding: 20
      }}
    >
      <Grid container spacing={1}>
        <Grid item lg={4}>
          <Paper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Aktive sager fordelt på type"
                      id="case-overview.case-type"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Antal"
                      id="case-overview.count"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Sidste år"
                      id="case-overview.last-year"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading || error ? (
                  <span>Loader</span>
                ) : (
                  <>
                    {data.cases_case_type_count.map(caseTypeCount => {
                      totalCaseTypeCount += caseTypeCount.count;
                      const lastYear = activeCasesLastYear.get(
                        caseTypeCount.case_type?.name
                      );
                      totalCaseTypeCountLastYear += lastYear ?? 0;

                      return (
                        <TableRow key={caseTypeCount.case_type_id}>
                          <TableCell>
                            {caseTypeCount.case_type
                              ? caseTypeCount.case_type.name
                              : ""}
                          </TableCell>
                          <TableCell>{caseTypeCount.count}</TableCell>
                          <TableCell>{lastYear}</TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      <TableCell>
                        <FormattedMessage
                          defaultMessage="Total"
                          id="case-overview.total"
                        />
                      </TableCell>
                      <TableCell>{totalCaseTypeCount}</TableCell>
                      <TableCell>{totalCaseTypeCountLastYear}</TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Grid>

        <Grid item lg={8}>
          <Paper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Medarbejder"
                      id="case-overview.employee"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="INT"
                      id="case-overview.initials"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Tilsyn opstartet"
                      id="case-overview.started"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Tilsyn ikke opstartet"
                      id="case-overview.not-started"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Antal"
                      id="case-overview.count"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading || error ? (
                  <span>Loader</span>
                ) : (
                  <>
                    {[...buildingInspectionData.entries()].map(
                      ([responsibleId, buildingCases]) => {
                        totalEmployeeCaseCount +=
                          (buildingCases?.started || 0) +
                          (buildingCases?.waiting || 0);

                        return (
                          <TableRow key={responsibleId}>
                            <TableCell>
                              {buildingCases.responsible
                                ? buildingCases.responsible.employee_number
                                : ""}
                            </TableCell>
                            <TableCell>
                              {buildingCases.responsible
                                ? `${buildingCases.responsible.first_name} ${buildingCases.responsible.last_name}`
                                : ""}
                            </TableCell>

                            <TableCell>
                              {buildingCases.responsible
                                ? buildingCases.responsible.acronym
                                : ""}
                            </TableCell>
                            <TableCell>
                              {buildingCases?.started || ""}
                            </TableCell>
                            <TableCell>
                              {buildingCases?.waiting || ""}
                            </TableCell>
                            <TableCell>
                              {(buildingCases?.started || 0) +
                                (buildingCases?.waiting || 0)}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                    <TableRow>
                      <TableCell>
                        <FormattedMessage
                          defaultMessage="Total"
                          id="case-overview.total"
                        />
                      </TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell>{totalEmployeeCaseCount}</TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ marginTop: 10 }}>
        <Grid item lg={12}>
          <Paper style={{ padding: 10 }}>
            <div style={{ fontSize: 14, fontWeight: 500 }}>
              <FormattedMessage
                defaultMessage="Antal sager pr. sagstype pr. måned"
                id="case-overview.cases_per_case_type_per_month"
              />
            </div>
            <ResponsiveContainer height={400} width="100%">
              <LineChart data={graphData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip wrapperStyle={{ zIndex: 1000 }} />
                {graphLines
                  ? _.sortBy(
                      [...graphLines],
                      ([key]) => key
                    ).map(([key, color]) =>
                      activeLines.has(key) ? (
                        <Line key={key} dataKey={key} stroke={color} />
                      ) : null
                    )
                  : null}
              </LineChart>
            </ResponsiveContainer>
            <Grid container>{caseTypeBlock}</Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
