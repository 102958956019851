import gql from "graphql-tag";

export const ORDER_CONFIRMATION_TEMPLATES = gql`
  query list_order_confirmation_templates(
    $orderBy: order_confirmation_templates_order_by!
    $where: order_confirmation_templates_bool_exp
    $limit: Int
  ) {
    order_confirmation_templates(
      order_by: [$orderBy]
      where: $where
      limit: $limit
    ) {
      changed
      created
      description
      id
      other_terms
      template_title
      title
    }
  }
`;

export const GET_ORDER_CONFIRMATION_TEMPLATE = gql`
  query get_order_confirmation_templates($id: uuid!) {
    order_confirmation_templates_by_pk(id: $id) {
      changed
      created
      description
      id
      other_terms
      template_title
      title
      tasks(order_by: {order: asc}) {
        description
        id
        order
        price
        title
      }
    }
  }
`;

export const GET_ORDER_CONFIRMATION_TEMPLATE_WITH_CASE = gql`
  query get_order_confirmation_template_with_case($id: uuid!, $caseId: uuid!) {
    order_confirmation_templates_by_pk(id: $id) {
      changed
      created
      description
      id
      other_terms
      template_title
      title
      tasks(order_by: {order: asc}) {
        description
        id
        order
        price
        title
      }
    }
    cases_by_pk(id: $caseId) {
      address
      builder_id
      builder {
        address
        city
        company
        first_name
        id
        last_name
        mail
        mobile
        phone
        zip_code
      }
      city
      date_of_inspection
      other_address
      responsible_id
      responsible {
        acronym
        first_name
        last_name
        mail
        mobile
        phone
      }
      zip_code
    }
  }
`;

export const CREATE_ORDER_CONFIRMATION_TEMPLATE = gql`
  mutation insert_order_confirmation_template(
    $orderConfirmationTemplate: order_confirmation_templates_insert_input!
  ) {
    insert_order_confirmation_templates(objects: [$orderConfirmationTemplate]) {
      returning {
        changed
        created
        description
        id
        other_terms
        template_title
        title
        tasks(order_by: {order: asc}) {
          description
          id
          order
          price
          title
        }
      }
    }
  }
`;

export const UPDATE_ORDER_CONFIRMATION_TEMPLATE = gql`
  mutation update_order_confirmation_template(
    $id: uuid
    $orderConfirmationTemplate: order_confirmation_templates_set_input!
  ) {
    update_order_confirmation_templates(
      where: {id: {_eq: $id}}
      _set: $orderConfirmationTemplate
    ) {
      returning {
        changed
        created
        description
        id
        other_terms
        template_title
        title
        tasks(order_by: {order: asc}) {
          description
          id
          order
          price
          title
        }
      }
    }
  }
`;

export const CREATE_ORDER_CONFIRMATION_TEMPLATE_TASK = gql`
  mutation insert_order_confirmation_template_task(
    $orderConfirmationTemplateTasks: [order_confirmation_template_tasks_insert_input!]!
  ) {
    insert_order_confirmation_template_tasks(
      objects: $orderConfirmationTemplateTasks
    ) {
      returning {
        description
        id
        order
        price
        title
      }
    }
  }
`;

export const UPDATE_ORDER_CONFIRMATION_TEMPLATE_TASK = gql`
  mutation update_order_confirmation_template_task(
    $id: uuid
    $orderConfirmationTemplateTask: order_confirmation_template_tasks_set_input!
  ) {
    update_order_confirmation_template_tasks(
      where: {id: {_eq: $id}}
      _set: $orderConfirmationTemplateTask
    ) {
      returning {
        description
        id
        order
        price
        title
      }
    }
  }
`;

export const DELETE_ORDER_CONFIRMATION_TEMPLATE_TASK = gql`
  mutation delete_order_confirmation_template_tasks(
    $where: order_confirmation_template_tasks_bool_exp!
  ) {
    delete_order_confirmation_template_tasks(where: $where) {
      affected_rows
    }
  }
`;
