import {Grid} from "@material-ui/core";
import {defineMessages, injectIntl} from "react-intl";
import React, {FunctionComponent} from "react";
import TextField from "@material-ui/core/TextField";

const messages = defineMessages({
  address: {
    defaultMessage: "Addresse",
    id: "contact-form.address",
  },
  alreadyExists: {
    defaultMessage:
      "A contact with this name, mail and phone number already exists",
    id: "contact-form.alreadyExists",
  },
  city: {
    defaultMessage: "By",
    id: "contact-form.city",
  },
  company: {
    defaultMessage: "Firma",
    id: "contact-form.company",
  },
  firstName: {
    defaultMessage: "Fornavn",
    id: "contact-form.first_name",
  },
  lastName: {
    defaultMessage: "Efternavn",
    id: "contact-form.last_name",
  },
  mail: {
    defaultMessage: "Mail",
    id: "contact-form.mail",
  },
  mobile: {
    defaultMessage: "Mobil",
    id: "contact-form.mobile",
  },
  phone: {
    defaultMessage: "Telefon",
    id: "contact-form.phone",
  },
  postalCode: {
    defaultMessage: "Postnummer",
    id: "contact-form.zip_code",
  },
  save: {
    defaultMessage: "Gem",
    id: "contact-form.save",
  },
  select: {
    defaultMessage: "Vælg",
    id: "contact-form.select",
  },
  type: {
    defaultMessage: "Type",
    id: "contact-form.type",
  },
});

export const CONTACT_TYPE_OPTIONS = [
  {label: "", value: ""},
  {label: "Kunde", value: "customer"},
  {label: "Aftager", value: "external"},
  {label: "Byggefirma", value: "company"},
  {label: "Byggeleder", value: "manager"},
];

interface Props {
  errors: any;
  handleBlur: any;
  handleChange: any;
  handleSubmit: any;
  intl: any;
  isSubmitting: any;
  touched: any;
  values: any;
}

const ContactForm: FunctionComponent<Props> = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  intl,
  values,
}) => (
  <>
    <div>
      {errors.form === "already exists"
        ? intl.formatMessage(messages.alreadyExists)
        : errors.form}
    </div>
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            required
            error={!!errors.first_name}
            label={intl.formatMessage(messages.firstName)}
            name="first_name"
            type="text"
            value={values.first_name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            required
            error={!!errors.last_name}
            label={intl.formatMessage(messages.lastName)}
            name="last_name"
            type="text"
            value={values.last_name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            required
            error={!!errors.address}
            label={intl.formatMessage(messages.address)}
            name="address"
            type="text"
            value={values.address}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            required
            error={!!errors.zip_code}
            label={intl.formatMessage(messages.postalCode)}
            name="zip_code"
            type="text"
            value={values.zip_code}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            required
            error={!!errors.city}
            label={intl.formatMessage(messages.city)}
            name="city"
            type="text"
            value={values.city}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            error={!!errors.mail}
            label={intl.formatMessage(messages.mail)}
            name="mail"
            type="text"
            value={values.mail}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            error={!!errors.phone}
            inputProps={{maxLength: 12}}
            label={intl.formatMessage(messages.phone)}
            name="phone"
            type="text"
            value={values.phone}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            required
            error={!!errors.mobile}
            inputProps={{maxLength: 12}}
            label={intl.formatMessage(messages.mobile)}
            name="mobile"
            type="text"
            value={values.mobile}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            required
            select
            error={!!errors.type}
            label={intl.formatMessage(messages.type)}
            name="type"
            SelectProps={{
              native: true,
            }}
            value={values.type}
            onBlur={handleBlur}
            onChange={handleChange}
          >
            {CONTACT_TYPE_OPTIONS.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            error={!!errors.company}
            label={intl.formatMessage(messages.company)}
            name="company"
            type="text"
            value={values.company}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </form>
  </>
);

export default injectIntl(ContactForm);
