import gql from "graphql-tag";

export const QUALITY_CONTROL_TEMPLATES = gql`
  query list_quality_control_templates(
    $orderBy: quality_control_templates_order_by!
    $where: quality_control_templates_bool_exp
    $limit: Int
  ) {
    quality_control_templates(
      order_by: [$orderBy]
      where: $where
      limit: $limit
    ) {
      changed
      created
      id
      template_title
      title
    }
  }
`;

export const GET_QUALITY_CONTROL_TEMPLATE = gql`
  query get_quality_control_templates($id: uuid!) {
    quality_control_templates_by_pk(id: $id) {
      changed
      created
      id
      template_title
      title
      tasks(order_by: {order: asc}) {
        description
        id
        order
        file_id,
        file {
          id
          share_key
          filename
          created
        }
        title
      }
    }
  }
`;

export const GET_QUALITY_CONTROL_TEMPLATE_WITH_CASE = gql`
  query get_quality_control_template_with_case($id: uuid!, $caseId: uuid!) {
    quality_control_templates_by_pk(id: $id) {
      changed
      created
      id
      template_title
      title
      tasks(order_by: {order: asc}) {
        description
        id
        order
        file_id
        file {
          id
          share_key
          filename
          created
        }
        title
      }
    }
    cases_by_pk(id: $caseId) {
      address
      builder_id
      builder {
        address
        city
        company
        first_name
        id
        last_name
        mail
        mobile
        phone
        zip_code
      }
      city
      date_of_inspection
      other_address
      responsible_id
      responsible {
        acronym
        first_name
        last_name
        mail
        mobile
        phone
      }
      zip_code
    }
  }
`;

export const CREATE_QUALITY_CONTROL_TEMPLATE = gql`
  mutation insert_quality_control_template(
    $qualityControlTemplate: quality_control_templates_insert_input!
  ) {
    insert_quality_control_templates(objects: [$qualityControlTemplate]) {
      returning {
        changed
        created
        id
        template_title
        title
        tasks(order_by: {order: asc}) {
          description
          id
          order
          file_id
          title
        }
      }
    }
  }
`;

export const UPDATE_QUALITY_CONTROL_TEMPLATE = gql`
  mutation update_quality_control_template(
    $id: uuid
    $qualityControlTemplate: quality_control_templates_set_input!
  ) {
    update_quality_control_templates(
      where: {id: {_eq: $id}}
      _set: $qualityControlTemplate
    ) {
      returning {
        changed
        created
        id
        template_title
        title
        tasks(order_by: {order: asc}) {
          description
          id
          order
          template_id
          file_id
          file {
            id
            share_key
            filename
            created
          }
          title
        }
      }
    }
  }
`;

export const CREATE_QUALITY_CONTROL_TEMPLATE_TASK = gql`
  mutation insert_quality_control_template_task(
    $qualityControlTemplateTasks: [quality_control_template_tasks_insert_input!]!
  ) {
    insert_quality_control_template_tasks(
      objects: $qualityControlTemplateTasks
    ) {
      returning {
        description
        id
        order
        file_id
        title
      }
    }
  }
`;

export const UPDATE_QUALITY_CONTROL_TEMPLATE_TASK = gql`
  mutation update_quality_control_template_task(
    $id: uuid
    $qualityControlTemplateTask: quality_control_template_tasks_set_input!
  ) {
    update_quality_control_template_tasks(
      where: {id: {_eq: $id}}
      _set: $qualityControlTemplateTask
    ) {
      returning {
        description
        id
        order
        title
        file_id
      }
    }
  }
`;

export const DELETE_QUALITY_CONTROL_TEMPLATE_TASK = gql`
  mutation delete_quality_control_template_tasks(
    $where: quality_control_template_tasks_bool_exp!
  ) {
    delete_quality_control_template_tasks(where: $where) {
      affected_rows
    }
  }
`;
