import { AutocompleteSelect } from "../../../components/autocomplete-select";
import { BASE_URL } from "../../../utils/app";
import {
  Button,
  CircularProgress,
  DialogContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import {
  CaseControlBuildingInspectionQuery,
  Order_By,
  useCaseControlBuildingInspectionLazyQuery
} from "../../../generated/graphql";
import { ClickableTableCell } from "./clickable-table-cell";
import { DialogBase } from "../../../components/dialog";
import { FormattedMessage } from "react-intl";
import { User, UserSelect } from "../../../components/user-select";
import { getCaseAddressFormatted, getCaseWhere } from "../../case/case-list";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import gql from "graphql-tag";
import moment from "moment";

export const GET_CASE_STATS = gql`
  query caseControlBuildingInspection(
    $orderBy: cases_order_by!
    $where: cases_bool_exp
  ) {
    cases(order_by: [$orderBy], where: $where) {
      id
      task
      description
      responsible_id
      responsible {
        id
        acronym
      }
      company_id
      builder {
        id
        address
        zip_code
        city
      }
      address
      zip_code
      city
      case_type_id
      case_files {
        id
      }
      case_photos {
        file {
          created
        }
      }
      reminders {
        id
        date
      }
      date_of_inspection
      estimated_time
      other_address
      changed
      flag
      checklists_aggregate {
        aggregate {
          count
        }
      }
      quality_controls_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

type CaseInstance = CaseControlBuildingInspectionQuery["cases"][number];

const BUILDING_INSPECTION_NOT_STARTED_ID =
  "c01495b0-869a-41fc-a552-5664460e9bf2";
const BUILDING_INSPECTION_STARTED_ID = "13b75bf9-57c9-42ad-8c6d-f056e8f394e1";

const Row = React.memo(function Row({
  caseInstance
}: {
  caseInstance: CaseInstance;
}): JSX.Element {
  const yes = (
    <FormattedMessage defaultMessage="Ja" id="building-inspection-table.yes" />
  );
  const no = (
    <FormattedMessage defaultMessage="Nej" id="building-inspection-table.no" />
  );
  const { case_photos, reminders } = caseInstance;

  const companySet = !!caseInstance.company_id;
  const photosExists = !!caseInstance.case_photos.length;
  const filesExists = !!caseInstance.case_files.length;
  const filesError = photosExists && !filesExists;
  const remindersExists = !!reminders.length;
  const flagSet = caseInstance.flag;
  const flagWarning =
    !flagSet && caseInstance.case_type_id === BUILDING_INSPECTION_STARTED_ID;

  const reminderState: "error" | "warning" | "missing" | "ok" = useMemo(() => {
    if (!remindersExists && !photosExists) {
      return "error";
    }
    const lastPhotoAdded = _.last(
      _.orderBy(case_photos, photo => photo.file.created)
    )?.file.created;
    const photoOk = lastPhotoAdded
      ? moment(lastPhotoAdded) >= moment().subtract(7, "days")
      : false;

    const lastReminderAdded = _.last(
      _.orderBy(reminders, reminder => reminder.date)
    )?.date;
    const reminderOk = lastReminderAdded
      ? moment(lastReminderAdded) >= moment()
      : false;

    if (!photoOk && !reminderOk) {
      return "error";
    }

    if (photoOk && !reminderOk) {
      return "warning";
    }

    if (!remindersExists) {
      return "missing";
    }

    return "ok";
  }, [case_photos, photosExists, reminders, remindersExists]);

  const error = (
    <FormattedMessage
      defaultMessage="Fejl"
      id="building-inspection-table.error"
    />
  );
  const passive = (
    <FormattedMessage
      defaultMessage="Passiv"
      id="building-inspection-table.passive"
    />
  );
  const [inspectionColor, inspectionValue, inpectionOk] = useMemo(() => {
    let color: string | undefined;
    let value: JSX.Element | null;
    let ok = false;
    const dateOfInspection = caseInstance.date_of_inspection;
    const firstPhotoAdded = _.first(
      _.orderBy(caseInstance.case_photos, photo => photo.file.created)
    )?.file.created;
    if (dateOfInspection) {
      const date = moment(dateOfInspection);
      if (firstPhotoAdded && date > moment(firstPhotoAdded)) {
        value = error;
        color = "red";
        ok = false;
      } else {
        value = yes;
        ok = true;
      }
    } else {
      color = "red";
      value = no;
      ok = false;
    }

    if (
      caseInstance.case_type_id === BUILDING_INSPECTION_NOT_STARTED_ID &&
      firstPhotoAdded
    ) {
      ok = false;
      color = "red";
      value = passive;
    }
    return [color, value, ok];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    caseInstance.case_photos,
    caseInstance.case_type_id,
    caseInstance.date_of_inspection
  ]);

  const allOk =
    companySet &&
    photosExists &&
    !filesError &&
    reminderState === "ok" &&
    !flagWarning &&
    reminderState &&
    inpectionOk;

  const handleCaseClik = useCallback(() => {
    window.open(`${BASE_URL}/cases/${caseInstance.id}`, "_BLANK");
  }, [caseInstance.id]);
  const address = getCaseAddressFormatted(caseInstance);
  return (
    <TableRow key={caseInstance.id}>
      <TableCell style={{ whiteSpace: "pre-line" }}>
        {address?.replace(",", "\n")}
      </TableCell>
      <TableCell
        style={
          !companySet &&
          caseInstance.case_type_id === BUILDING_INSPECTION_STARTED_ID
            ? { backgroundColor: "red" }
            : undefined
        }
      >
        {companySet ? yes : no}
      </TableCell>
      <TableCell>{photosExists ? yes : no}</TableCell>
      <TableCell style={filesError ? { backgroundColor: "red" } : undefined}>
        {filesExists ? yes : no}
      </TableCell>
      <TableCell
        style={
          reminderState === "error" ? { backgroundColor: "red" } : undefined
        }
      >
        {reminderState === "error" || reminderState === "warning" ? (
          <FormattedMessage
            defaultMessage="Nej"
            id="building-inspection-table.no"
          />
        ) : (
          <FormattedMessage
            defaultMessage="Ja"
            id="building-inspection-table.yes"
          />
        )}
      </TableCell>
      <TableCell
        style={flagWarning ? { backgroundColor: "yellow" } : undefined}
      >
        {flagSet ? yes : no}
      </TableCell>
      <TableCell>
        {
          new Set(
            caseInstance.case_photos.map(photo =>
              new Date(photo.file.created).toDateString()
            )
          ).size
        }
      </TableCell>
      <TableCell>
        {caseInstance.checklists_aggregate.aggregate.count +
          caseInstance.quality_controls_aggregate.aggregate.count}
      </TableCell>
      <TableCell
        style={
          inspectionColor ? { backgroundColor: inspectionColor } : undefined
        }
      >
        {inspectionValue}
      </TableCell>
      <TableCell
        style={
          !caseInstance.estimated_time &&
          caseInstance.case_type_id === BUILDING_INSPECTION_STARTED_ID
            ? { backgroundColor: "red" }
            : undefined
        }
      >
        {caseInstance.estimated_time ? yes : no}
      </TableCell>
      <TableCell style={allOk ? { backgroundColor: "lightgreen" } : undefined}>
        {caseInstance.case_type_id === BUILDING_INSPECTION_NOT_STARTED_ID ? (
          <FormattedMessage
            defaultMessage="Passiv"
            id="building-inspection-table.passive"
          />
        ) : (
          <FormattedMessage
            defaultMessage="Aktiv"
            id="building-inspection-table.active"
          />
        )}
      </TableCell>
      <TableCell>
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={handleCaseClik}
        >
          Åbn sag
        </Button>
      </TableCell>
    </TableRow>
  );
});

type columnName =
  | "company"
  | "photo"
  | "files"
  | "reminder"
  | "flag"
  | "inspection-count"
  | "notes"
  | "start-date"
  | "period"
  | "state";

const DialogBaseWithoutType: any = DialogBase;

export function BuildingInspectionCard({
  search = "",
  userId
}: {
  search?: string;
  userId?: string;
}): JSX.Element {
  const [responsible, setResponsible] = useState<User | null>(null);
  const onUserSelected = useCallback((option): void => {
    setResponsible(option);
  }, []);

  const [
    selectedColumnHeader,
    setSelectedColumnHeader
  ] = useState<columnName | null>(null);

  const [caseType, setCaseType] = useState<{ label: string; value: string }>({
    label: "Byggetilsynspakke",
    value: BUILDING_INSPECTION_STARTED_ID
  });
  const onCaseTypeSelected = useCallback((option): void => {
    setCaseType(option);
  }, []);

  const [
    getData,
    { data, loading }
  ] = useCaseControlBuildingInspectionLazyQuery();

  useEffect(() => {
    const caseTypeId = caseType?.value;
    const responsibleId = userId || responsible?.id;
    if (!responsibleId) {
      return;
    }

    const where = getCaseWhere(search, {
      case_type_id: { _eq: caseTypeId },
      closed: { _is_null: true },
      responsible: { id: { _eq: responsibleId } }
    });

    getData({
      variables: {
        orderBy: {
          changed: Order_By.Asc
        },
        where
      }
    });
  }, [caseType?.value, getData, responsible?.id, search, userId]);

  const AutocompleteSelectWithoutType: any = AutocompleteSelect;

  const onExplainerDialogOk = useCallback(
    () => setSelectedColumnHeader(null),
    []
  );

  const no = (
    <FormattedMessage
      defaultMessage="Nej"
      id="building-inspection-table.explainer.red-no"
    />
  );
  const yes = (
    <FormattedMessage
      defaultMessage="Ja"
      id="building-inspection-table.explainer.yes"
    />
  );
  return (
    <Paper style={{ minHeight: 300, overflowX: "scroll" }}>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Grid container>
          {!userId ? (
            <Grid item lg={6} xs={12}>
              <UserSelect
                field={{ name: "responsible", value: responsible }}
                form={{ errors: {} }}
                onlyGroups={[1, 2, 3]}
                placeholder={
                  <FormattedMessage
                    defaultMessage="Sagsansvarlig"
                    id="building-inspectio.responsible"
                  />
                }
                onSelect={onUserSelected}
              />
            </Grid>
          ) : null}
          <Grid item lg={6} xs={12}>
            <AutocompleteSelectWithoutType
              field={{ name: "caseType", value: caseType }}
              form={{ errors: {} }}
              options={[
                {
                  label: "Byggetilsynspakke",
                  value: BUILDING_INSPECTION_STARTED_ID
                },
                {
                  label: "Byggetilsynspakke - IKKE OPSTARTET",
                  value: BUILDING_INSPECTION_NOT_STARTED_ID
                }
              ]}
              placeholder={
                <FormattedMessage
                  defaultMessage="Sagstype"
                  id="building-inspection.case_type"
                />
              }
              onSelect={onCaseTypeSelected}
            />
          </Grid>
        </Grid>
      </div>
      {loading && !data?.cases ? (
        <div style={{ padding: 10, textAlign: "center" }}>
          <CircularProgress size={24} />
        </div>
      ) : (
        <Table size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: "#F0F0F0" }}>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Sagsadresse"
                  id="building-inspection-table.header.case-address"
                />
              </TableCell>
              <ClickableTableCell<columnName>
                id="company"
                label={
                  <FormattedMessage
                    defaultMessage="Byggeleder"
                    id="building-inspection-table.header.company"
                  />
                }
                onClick={setSelectedColumnHeader}
              />
              <TableCell>
                <FormattedMessage
                  defaultMessage="Foto på sagen"
                  id="building-inspection-table.header.photo"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Filer i filarkiv"
                  id="building-inspection-table.header.files"
                />
              </TableCell>
              <ClickableTableCell<columnName>
                id="reminder"
                label={
                  <FormattedMessage
                    defaultMessage="Påmindelse"
                    id="building-inspection-table.header.reminder"
                  />
                }
                onClick={setSelectedColumnHeader}
              />
              <ClickableTableCell<columnName>
                id="flag"
                label={
                  <FormattedMessage
                    defaultMessage="Flag på sagen"
                    id="building-inspection-table.header.flag"
                  />
                }
                onClick={setSelectedColumnHeader}
              />
              <TableCell>
                <FormattedMessage
                  defaultMessage="Antal tilsyn"
                  id="building-inspection-table.header.inspection-count"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Notater"
                  id="building-inspection-table.header.notes"
                />
              </TableCell>
              <ClickableTableCell<columnName>
                id="start-date"
                label={
                  <FormattedMessage
                    defaultMessage="Opstarts dato"
                    id="building-inspection-table.header.start-date"
                  />
                }
                onClick={setSelectedColumnHeader}
              />
              <ClickableTableCell<columnName>
                id="period"
                label={
                  <FormattedMessage
                    defaultMessage="Byggeperiode"
                    id="building-inspection-table.header.period"
                  />
                }
                onClick={setSelectedColumnHeader}
              />
              <TableCell>
                <FormattedMessage
                  defaultMessage="Status"
                  id="building-inspection-table.header.state"
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.cases.map(caseInstance => {
              return <Row key={caseInstance.id} caseInstance={caseInstance} />;
            })}
          </TableBody>
        </Table>
      )}
      <DialogBaseWithoutType
        cancelLabel={
          <FormattedMessage
            defaultMessage="Ok"
            id="building-inspection-table.explainer.ok"
          />
        }
        open={!!selectedColumnHeader}
        title={
          selectedColumnHeader === "company" ? (
            <FormattedMessage
              defaultMessage="Byggeleder"
              id="building-inspection-table.explainer.title.company"
            />
          ) : selectedColumnHeader === "reminder" ? (
            <FormattedMessage
              defaultMessage="Påmindelse"
              id="building-inspection-table.explainer.title.reminder"
            />
          ) : selectedColumnHeader === "flag" ? (
            <FormattedMessage
              defaultMessage="Flag på sagen"
              id="building-inspection-table.explainer.title.flag"
            />
          ) : selectedColumnHeader === "start-date" ? (
            <FormattedMessage
              defaultMessage="Opstartsdato"
              id="building-inspection-table.explainer.title.start-date"
            />
          ) : selectedColumnHeader === "period" ? (
            <FormattedMessage
              defaultMessage="Byggeperiode"
              id="building-inspection-table.explainer.title.period"
            />
          ) : null
        }
        onCancel={onExplainerDialogOk}
      >
        <DialogContent>
          {selectedColumnHeader === "company" ? (
            <ul>
              <li>
                <span>{yes}</span>:{" "}
                <FormattedMessage
                  defaultMessage="Byggelederen er valgt"
                  id="building-inspection-table.explainer.company-yes"
                />
              </li>
              <li>
                <span>{no}</span>:{" "}
                <FormattedMessage
                  defaultMessage="Byggelederen er ikke valgt, sagen er ikke startet"
                  id="building-inspection-table.explainer.company-no"
                />
              </li>
              <li>
                <span style={{ backgroundColor: "red" }}>{no}</span>:{" "}
                <FormattedMessage
                  defaultMessage="Byggelederen er ikke valgt, sagen er startet"
                  id="building-inspection-table.explainer.company-red-no"
                />
              </li>
            </ul>
          ) : selectedColumnHeader === "reminder" ? (
            <ul>
              <li>
                <span>{yes}</span>:{" "}
                <FormattedMessage
                  defaultMessage="Påmindelse er oprettet"
                  id="building-inspection-table.explainer.reminder-yes"
                />
              </li>
              <li>
                <span>{no}</span>:{" "}
                <FormattedMessage
                  defaultMessage="Der er ikke oprettet nogen påmindelse"
                  id="building-inspection-table.explainer.reminder-no"
                />
              </li>
              <li>
                <span style={{ backgroundColor: "red" }}>{no}</span>:{" "}
                <FormattedMessage
                  defaultMessage="Der er ikke oprettet nogen påmindelse og det er over 7 dage siden sidste foto er lagt på sagen"
                  id="building-inspection-table.explainer.reminder-red-no"
                />
              </li>
            </ul>
          ) : selectedColumnHeader === "flag" ? (
            <ul>
              <li>
                <span>{yes}</span>:{" "}
                <FormattedMessage
                  defaultMessage="Flag er opsat"
                  id="building-inspection-table.explainer.flag-yes"
                />
              </li>
              <li>
                <span>{no}</span>:{" "}
                <FormattedMessage
                  defaultMessage="Flag er ikke opsat"
                  id="building-inspection-table.explainer.flag-no"
                />
              </li>
              <li>
                <span style={{ backgroundColor: "yellow" }}>{no}</span>:{" "}
                <FormattedMessage
                  defaultMessage="Flag er ikke opsat og sagen er startet"
                  id="building-inspection-table.explainer.flag-red-no"
                />
              </li>
            </ul>
          ) : selectedColumnHeader === "start-date" ? (
            <ul>
              <li>
                <span>{yes}</span>:{" "}
                <FormattedMessage
                  defaultMessage="Opstartsdato sat"
                  id="building-inspection-table.explainer.start-date-yes"
                />
              </li>
              <li>
                <span style={{ backgroundColor: "red" }}>{no}</span>:{" "}
                <FormattedMessage
                  defaultMessage="Opstartsdato er ikke sat"
                  id="building-inspection-table.explainer.start-date-red-no"
                />
              </li>
              <li>
                <span style={{ backgroundColor: "red" }}>
                  <FormattedMessage
                    defaultMessage="Fejl"
                    id="building-inspection-table.explainer.start-date-red-error"
                  />
                </span>
                :{" "}
                <FormattedMessage
                  defaultMessage="Opstartsdato ligger senere end datoen for det første billede"
                  id="building-inspection-table.explainer.start-date-red-error-text"
                />
              </li>
              <li>
                <span style={{ backgroundColor: "red" }}>
                  <FormattedMessage
                    defaultMessage="Passiv"
                    id="building-inspection-table.explainer.start-date-red-passiv"
                  />
                </span>
                :{" "}
                <FormattedMessage
                  defaultMessage="Der ligger billeder på sagen, men sagen er ikke startet."
                  id="building-inspection-table.explainer.start-date-red-passive-text"
                />
              </li>
            </ul>
          ) : selectedColumnHeader === "period" ? (
            <ul>
              <li>
                <span>{yes}</span>:{" "}
                <FormattedMessage
                  defaultMessage="Byggeperioden er indtastet"
                  id="building-inspection-table.explainer.period-yes"
                />
              </li>
              <li>
                <span>{no}</span>:{" "}
                <FormattedMessage
                  defaultMessage="Byggeperioden er mangler"
                  id="building-inspection-table.explainer.period-no"
                />
              </li>
              <li>
                <span style={{ backgroundColor: "red" }}>{no}</span>:{" "}
                <FormattedMessage
                  defaultMessage="Byggeperioden er mangler, sagen er startet"
                  id="building-inspection-table.explainer.period-red-no"
                />
              </li>
            </ul>
          ) : null}
        </DialogContent>
      </DialogBaseWithoutType>
    </Paper>
  );
}
