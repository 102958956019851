/* eslint-disable react/jsx-no-bind */
import * as yup from "yup";
import { APP_BAR_HEIGHT, Page } from "../../../../components/layout/page";
import { AppBar, Link, Tab, Tabs, withStyles } from "@material-ui/core";
import {
  CREATE_QUALITY_CONTROLS,
  CREATE_QUALITY_CONTROLS_TASK,
  CREATE_QUALITY_CONTROL_TASK_ARROWS,
  DELETE_QUALITY_CONTROLS_TASK,
  DELETE_QUALITY_CONTROL_TASK_ARROWS,
  GET_QUALITY_CONTROL,
  UPDATE_QUALITY_CONTROLS,
  UPDATE_QUALITY_CONTROLS_TASK,
  UPDATE_QUALITY_CONTROL_TASK_ARROWS
} from "../../../../graphql/quality-controls";
import { GET_CASE } from "../../../../graphql/cases";
import { GET_QUALITY_CONTROL_TEMPLATE_WITH_CASE } from "../../../../graphql/quality-control-templates";
import { QualityControlForm } from "./form";
import { Query } from "@apollo/client/react/components";
import { ReminderList } from "../../../../components/reminders/reminder-list";
import { Route, Switch, withRouter } from "react-router-dom";
import { flowRight as compose } from "lodash";
import { defineMessages, injectIntl } from "react-intl";
import { graphql, withApollo } from "@apollo/client/react/hoc";
import { replaceWithData } from "../../../../utils/app";
import { withAppContext } from "../../../../utils/with-app-context";
import { withFormik } from "formik";
import AreYouSure from "../../../../components/are-you-sure";
import Header from "../../../../components/layout/header";
import PropTypes from "prop-types";
import React, { PureComponent, useState } from "react";
import uuid from "uuid/v4";

const messages = defineMessages({
  add: {
    defaultMessage: "Tilføj",
    id: "qualityControl-entry.add"
  },
  createNew: {
    defaultMessage: "Opret ny tilsynsnotat",
    id: "qualityControl-entry.create-new-qualityControl"
  },
  details: {
    defaultMessage: "Detaljer",
    id: "qualityControl-entry.details"
  },
  reminders: {
    defaultMessage: "Påmindelser",
    id: "qualityControl-entry.reminders"
  },
  update: {
    defaultMessage: "Redigér tilsynsnotat",
    id: "qualityControl-entry.update-qualityControl"
  }
});

const schema = yup.object().shape({
  title: yup.string().required()
  //color: yup.string().required(),
  //icon: yup.string().required()
});

const styles = () => ({
  appBar: {
    "@media (min-width:1025px)": {
      width: "calc(100% - 240px)"
    },
    marginTop: APP_BAR_HEIGHT
  }
});

class QualityControlEntry extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    dirty: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    id: PropTypes.string,
    intl: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    resetForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired
  };
  handleResetForm = () => {
    this.props.handleReset();
  };

  showNavigationWarning = () => {
    return !this.props.isSubmitting && (!this.props.id || this.props.dirty);
  };

  render() {
    const {
      dirty,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      id,
      intl: { formatMessage },
      isSubmitting,
      match: {
        params: { caseId }
      },
      setFieldValue,
      touched,
      values
    } = this.props;

    let heading;
    if (id) {
      heading = formatMessage(messages.update);
    } else {
      heading = formatMessage(messages.createNew);
    }

    return (
      <Page
        withTabs
        appBar={
          <Header
            backLocation={`/cases/${caseId}/documents`}
            dirty={dirty || !this.props.id}
            doc={values}
            title={heading}
            onResetButton={this.handleResetForm}
            onSaveButton={handleSubmit}
          />
        }
      >
        <Switch>
          <Route
            path="/cases/:caseId/quality-control/:qualityControlId/reminders"
            render={props => (
              <ReminderList
                {...props}
                baseURL={`/cases/${caseId}/quality-control/${id}/reminders`}
                parentId={id}
                parentName="document_quality_control_id"
              />
            )}
          />
          <Route
            path="/cases/:caseId/quality-control/:qualityControlId"
            render={props => (
              <div
                style={{
                  backgroundColor: "#FFF",
                  minHeight: "calc(100% - 64px)",
                  padding: 20
                }}
              >
                <QualityControlForm
                  {...props}
                  caseId={caseId}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  values={values}
                />
              </div>
            )}
          />
        </Switch>
        <AreYouSure when={this.showNavigationWarning} />
      </Page>
    );
  }
}

const QualityControlEntryWithApollo = withApollo(QualityControlEntry);

const relatedDiff = (oldList, newList) => {
  const toDelete = oldList.reduce((removedInstances, oldInstance) => {
    if (!newList.find(instance => instance.id === oldInstance.id)) {
      removedInstances.push(oldInstance);
    }
    return removedInstances;
  }, []);

  const toAdd = newList.reduce((addedInstances, instance) => {
    if (!oldList.find(oldInstance => instance.id === oldInstance.id)) {
      addedInstances.push(instance);
    }
    return addedInstances;
  }, []);

  const toUpdate = newList.reduce((updatedInstances, instance) => {
    if (oldList.find(oldInstance => instance.id === oldInstance.id)) {
      delete instance.__typename;
      updatedInstances.push(instance);
    }
    return updatedInstances;
  }, []);

  return { toAdd, toDelete, toUpdate };
};

const handleSubmit = async (
  payload,
  { props, resetForm, setErrors, setSubmitting }
) => {
  const nowTimestamp = new Date().toISOString();
  const { caseId, qualityControlId } = props.match.params;
  const {
    date_of_inspection,
    document_date,
    revision_date,
    tasks,
    title
  } = payload;
  const variables = {
    id: undefined,
    qualityControl: {
      case_id: caseId,
      changed: nowTimestamp,
      date_of_inspection,
      document_date,
      revision_date,
      title
    }
  };
  try {
    if (qualityControlId) {
      const oldTasks = props.variables.tasks;
      const newTasks = tasks || [];
      const { toAdd, toDelete, toUpdate } = relatedDiff(oldTasks, newTasks);

      if (toDelete.length) {
        await props.deleteQualityControlTask({
          variables: {
            where: {
              _or: toDelete.map(instance => {
                return { id: { _eq: instance.id } };
              })
            }
          }
        });
      }
      if (toAdd.length) {
        await props.createQualityControlTask({
          variables: {
            qualityControlTasks: toAdd.map(instance => {
              const copy = { ...instance };
              delete copy.file;
              if (copy.arrows && copy.arrows.length) {
                copy.arrows = {
                  data: copy.arrows.map(arrow => {
                    delete arrow.task_id;
                    return arrow;
                  })
                };
              } else {
                delete copy.arrows;
              }
              copy.quality_control_id = qualityControlId;
              return copy;
            })
          }
        });
      }
      let [totalArrowsToDelete, totalArrowsToAdd, totalArrowsToUpdate] = [
        [],
        [],
        []
      ];

      if (toUpdate.length) {
        Promise.all(
          toUpdate.map(instance => {
            const copy = { ...instance };
            delete copy.file;
            const oldTask = oldTasks.find(t => instance.id === t.id);
            const arrowDiff = relatedDiff(oldTask.arrows, copy.arrows || []);
            totalArrowsToDelete = totalArrowsToDelete.concat(
              arrowDiff.toDelete
            );
            totalArrowsToAdd = totalArrowsToAdd.concat(arrowDiff.toAdd);
            totalArrowsToUpdate = totalArrowsToUpdate.concat(
              arrowDiff.toUpdate
            );
            delete copy.arrows;
            return props.updateQualityControlTask({
              variables: {
                id: instance.id,
                qualityControlTask: copy
              }
            });
          })
        );
      }

      if (totalArrowsToDelete.length) {
        await props.deleteQualityControlTaskArrow({
          variables: {
            where: {
              _or: totalArrowsToDelete.map(instance => {
                return { id: { _eq: instance.id } };
              })
            }
          }
        });
      }
      if (totalArrowsToAdd.length) {
        await props.createQualityControlTaskArrow({
          variables: {
            qualityControlTaskArrows: totalArrowsToAdd
          }
        });
      }

      if (totalArrowsToUpdate.length) {
        Promise.all(
          totalArrowsToUpdate.map(instance => {
            return props.updateQualityControlTaskArrow({
              variables: {
                id: instance.id,
                qualityControlTaskArrow: instance
              }
            });
          })
        );
      }

      variables.id = qualityControlId;
      const response = await props.updateQualityControl({
        variables
      });
      props.setVariables(
        response.data.update_case_doc_quality_controls.returning[0]
      );
      resetForm();
    } else {
      if (tasks && tasks.length) {
        variables.qualityControl.tasks = {
          data: tasks.map(t => {
            delete t.file;
            if (t.arrows) {
              if (t.arrows.length) {
                t.arrows = {
                  data: t.arrows.map(arrow => {
                    delete arrow.task_id;
                    return arrow;
                  })
                };
              } else {
                delete t.arrows;
              }
            }
            return t;
          })
        };
      }
      variables.qualityControl.created = nowTimestamp;
      variables.qualityControl.user_id = props.context.state.currentUser.id;
      const response = await props.createQualityControl({
        variables
      });
      const createdId =
        response.data.insert_case_doc_quality_controls.returning[0].id;
      props.history.replace(`/cases/${caseId}/quality-control/${createdId}`);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    const errors = e.graphQLErrors.map(error => error.message);
    // eslint-disable-next-line no-console
    console.log(errors);
    setSubmitting(false);
    setErrors({ form: errors });
  }
};

const QualityControlFormWithGraphQL = compose(
  withRouter,
  withAppContext,
  graphql(CREATE_QUALITY_CONTROLS, {
    name: "createQualityControl"
  }),
  graphql(UPDATE_QUALITY_CONTROLS, {
    name: "updateQualityControl"
  }),

  graphql(CREATE_QUALITY_CONTROLS_TASK, {
    name: "createQualityControlTask"
  }),
  graphql(UPDATE_QUALITY_CONTROLS_TASK, {
    name: "updateQualityControlTask"
  }),
  graphql(DELETE_QUALITY_CONTROLS_TASK, {
    name: "deleteQualityControlTask"
  }),
  graphql(CREATE_QUALITY_CONTROL_TASK_ARROWS, {
    name: "createQualityControlTaskArrow"
  }),
  graphql(UPDATE_QUALITY_CONTROL_TASK_ARROWS, {
    name: "updateQualityControlTaskArrow"
  }),
  graphql(DELETE_QUALITY_CONTROL_TASK_ARROWS, {
    name: "deleteQualityControlTaskArrow"
  }),
  withFormik({
    displayName: "QualityControlForm",
    handleSubmit,
    mapPropsToValues: ({ dateOfInspection, variables }) => {
      let { date_of_inspection, document_date } = variables;
      document_date = document_date ? document_date : new Date().toISOString();
      date_of_inspection = date_of_inspection
        ? date_of_inspection
        : document_date;
      return {
        ...variables,
        date_of_inspection,
        document_date
      };
    },
    validationSchema: schema
  }),
  injectIntl,
  withStyles(styles)
)(QualityControlEntryWithApollo);

const QualityControlEntryWrapper = ({
  match: {
    params: { caseId, qualityControlId, templateId }
  }
}) => {
  const id = qualityControlId;
  const [variables, setVariables] = useState(null);
  if (variables) {
    return (
      <QualityControlFormWithGraphQL
        id={id}
        setVariables={setVariables}
        variables={variables}
      />
    );
  }
  if (id) {
    return (
      <Query query={GET_QUALITY_CONTROL} variables={{ id }}>
        {({ data, error, loading }) => {
          if (loading) {
            return <p>Loading...</p>;
          }
          if (error) {
            return <p>Error :(</p>;
          }
          return (
            <QualityControlFormWithGraphQL
              id={id}
              setVariables={setVariables}
              variables={data.case_doc_quality_controls_by_pk}
            />
          );
        }}
      </Query>
    );
  } else {
    if (!templateId) {
      return (
        <Query query={GET_CASE} variables={{ caseId }}>
          {({ data, error, loading }) => {
            if (loading) {
              return <p>Loading...</p>;
            }
            if (error) {
              return <p>Error :(</p>;
            }
            return (
              <QualityControlFormWithGraphQL
                dateOfInspection={data.cases[0].date_of_inspection}
                id={id}
                setVariables={setVariables}
                variables={{}}
              />
            );
          }}
        </Query>
      );
    }
    return (
      <Query
        query={GET_QUALITY_CONTROL_TEMPLATE_WITH_CASE}
        variables={{ caseId, id: templateId }}
      >
        {({ data, error, loading }) => {
          if (loading) {
            return <p>Loading...</p>;
          }
          if (error) {
            return <p>Error :(</p>;
          }
          const parsedData = data.quality_control_templates_by_pk;

          parsedData.title = replaceWithData(
            parsedData.title,
            data.cases_by_pk
          );

          parsedData.tasks = parsedData.tasks.map(task => {
            return {
              description: replaceWithData(task.description, data.cases_by_pk),
              file: task.file,
              file_id: task.file_id,
              id: uuid(),
              order: task.order,
              title: replaceWithData(task.title, data.cases_by_pk)
            };
          });
          delete parsedData.__typename;

          return (
            <QualityControlFormWithGraphQL
              dateOfInspection={data.cases_by_pk.date_of_inspection}
              id={id}
              setVariables={setVariables}
              variables={parsedData}
            />
          );
        }}
      </Query>
    );
  }
};

const QualityControlEntryWrapperWithRouter = withRouter(
  QualityControlEntryWrapper
);
export { QualityControlEntryWrapperWithRouter as QualityControlsEntry };
