import { CasesCreatedCard } from "./cases-created-card";
import { CasesCreatedPerEmployeePerMonthCard } from "./cases-created-per-employee-per-month-card";
import { CasesCreatedResponsibleCard } from "./cases-created-responsible-card";
import { Grid } from "@material-ui/core";
import { RequestsDonePerEmployeePerMonth } from "./requests-done-per-employee-per-month";
import { useQuery } from "@apollo/client";
import React, { useMemo } from "react";
import gql from "graphql-tag";

const GET_CASE_STATS = gql`
  query casesCreatedEmployeeNumbers(
    $startMonth: String
    $startDate: timestamptz
    $endDate: timestamptz
  ) {
    users {
      employee_number
      acronym
    }
  }
`;

export function CasesCreated(): JSX.Element {
  const startMonth = new Date();
  startMonth.setMonth(startMonth.getMonth() - 12);

  const {
    data
  }: {
    data: {
      users:
        | {
            acronym: string;
            employee_number: number;
          }[]
        | null;
    };
    error?: any;
    loading: boolean;
  } = useQuery(GET_CASE_STATS, {
    variables: {
      startMonth: `${startMonth.getFullYear()}-${
        startMonth.getMonth() < 10
          ? `0${startMonth.getMonth()}`
          : startMonth.getMonth()
      }`
    }
  });
  const users = data?.users;
  const userSortOrder: Map<string, number> = useMemo(() => {
    const tempSortOrder = new Map<string, number>();
    // eslint-disable-next-line no-unused-expressions
    users?.forEach(u => tempSortOrder.set(u.acronym, u.employee_number));
    return tempSortOrder;
  }, [users?.forEach]);

  return (
    <div
      style={{
        minHeight: "calc(100% - 64px)",
        padding: 20
      }}
    >
      <Grid container spacing={1}>
        <Grid item lg={12}>
          <CasesCreatedPerEmployeePerMonthCard userSortOrder={userSortOrder} />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item lg={12}>
          <RequestsDonePerEmployeePerMonth userSortOrder={userSortOrder} />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item>
          <h3>Oversigt over sagstype oprettet på hver medarbejder</h3>
          <CasesCreatedResponsibleCard />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item lg={8}>
          <h3>Antal sager oprettet fordelt pr. medarbejder</h3>
          <CasesCreatedCard />
        </Grid>
      </Grid>
    </div>
  );
}
