import * as yup from "yup";
import { Button, Typography } from "@material-ui/core";
import {
  CREATE_USER,
  GET_USER,
  GET_USER_LIST,
  GET_USER_WITH_MAIL,
  UPDATE_USER
} from "../../graphql/users";
import { ChangePasswordDialog } from "./change-password-dialog";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { Page } from "../../components/layout/page";
import { Query } from "@apollo/client/react/components";
import { UserForm } from "./user-form";
import { flowRight as compose } from "lodash";
import { formatUserLabel } from "../../components/user-select";
import { generateSelectValue } from "../../utils/form-helpers";
import { graphql, withApollo } from "@apollo/client/react/hoc";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import AreYouSure from "../../components/are-you-sure";
import Header from "../../components/layout/header";
import PropTypes from "prop-types";
import React, { PureComponent, useState } from "react";
import bcrypt from "bcryptjs";

const messages = defineMessages({
  add: {
    defaultMessage: "Tilføj",
    id: "user-entry.add"
  },
  createNew: {
    defaultMessage: "Opret ny bruger",
    id: "user-entry.create-new-user"
  },
  update: {
    defaultMessage: "Redigér bruger",
    id: "user-entry.update-user"
  },
  users: {
    defaultMessage: "Brugere",
    id: "user-entry.users"
  }
});

const userTypes = ["user", "admin", "superuser", "sale"];
const schema = yup.object().shape({
  acronym: yup.string().required(),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  mail: yup
    .string()
    .email()
    .required(), // TODO: Decide if it should be unique in db
  mobile: yup
    .number()
    .positive()
    .required(),
  password: yup.string(),
  phone: yup
    .number()
    .positive()
    .required(),
  user_type: yup.string().oneOf(userTypes)
});

class UserEntry extends PureComponent {
  static propTypes = {
    client: PropTypes.object.isRequired,
    dirty: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    resetForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired
  };
  state = {
    showChangePasswordDialog: false,
    userExists: false
  };
  handleSubmitForm = async () => {
    const {
      client,
      submitForm,
      userId,
      values: { mail }
    } = this.props;
    if (!!userId || !mail) {
      submitForm();
      return;
    }
    const existing = await client.query({
      fetchPolicy: "network-only",
      query: GET_USER_WITH_MAIL,
      variables: {
        mail
      }
    });
    if (!existing.data.users.length) {
      submitForm();
      this.setState({ userExists: false });
    } else {
      this.setState({ userExists: true });
    }
  };

  handleResetForm = () => this.props.handleReset();

  showNavigationWarning = () => !this.props.isSubmitting && this.props.dirty;

  handleChangePasswordClick = () =>
    this.setState({ showChangePasswordDialog: true });

  handleChangeClose = () => this.setState({ showChangePasswordDialog: false });

  render() {
    const {
      dirty,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      intl: { formatMessage },
      isSubmitting,
      touched,
      userId,
      values
    } = this.props;

    let heading;
    if (userId) {
      heading = formatMessage(messages.update);
    } else {
      heading = formatMessage(messages.createNew);
    }

    return (
      <Page
        appBar={
          <Header
            dirty={dirty}
            title={heading}
            onResetButton={this.handleResetForm}
            onSaveButton={this.handleSubmitForm}
          />
        }
      >
        <div
          style={{
            backgroundColor: "#FFF",
            minHeight: "calc(100% - 64px)",
            padding: 20
          }}
        >
          {this.state.userExists ? (
            <Typography color="error" variant="subtitle1">
              <FormattedMessage
                defaultMessage="Kontakten eksisterer i forvejen"
                id="user-entry.create-new-user"
              />
            </Typography>
          ) : null}
          <UserForm
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            touched={touched}
            values={values}
          />
          {values.id ? (
            <>
              {" "}
              <hr />
              <Button onClick={this.handleChangePasswordClick}>
                Skift password
              </Button>
            </>
          ) : null}
        </div>
        <AreYouSure when={this.showNavigationWarning} />

        <ChangePasswordDialog
          mail={this.props.values.mail}
          open={this.state.showChangePasswordDialog}
          onCancel={this.handleChangeClose}
          onOk={this.handleChangeClose}
        />
      </Page>
    );
  }
}

const UserEntryWithApollo = withApollo(UserEntry);

const makeToken = () => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < 32; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const handleSubmit = async (
  payload,
  { props, resetForm, setErrors, setSubmitting }
) => {
  const {
    acronym,
    active,
    employee_number,
    first_name,
    id,
    last_name,
    mail,
    mobile,
    phone,
    substitute,
    user_type
  } = payload;
  let action;
  const variables = {
    id: undefined,
    user: {
      acronym,
      active,
      employee_number,
      first_name,
      id,
      last_name,
      mail,
      mobile,
      phone,
      substitute_id: substitute ? substitute.id : null,
      user_type
    }
  };

  const { userId } = props.match.params;
  if (userId) {
    action = props.update_user;
    variables.id = userId;
  } else {
    action = props.insert_user;
    const salt = bcrypt.genSaltSync();
    variables.user.password = await bcrypt.hash("123Qwe123!", salt);
    variables.user.token = makeToken();
  }
  try {
    const response = await action({
      variables
    });
    if (userId) {
      //props.setVariables(response.data.update_users.returning[0]);
      resetForm();
    } else {
      const createdUserId = response.data.insert_users.returning[0].id;
      props.history.replace(`/users/${createdUserId}`);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    const errors = e.graphQLErrors.map(error => error.message);
    // eslint-disable-next-line no-console
    console.log(errors);
    setSubmitting(false);
    setErrors({ form: errors });
  }
};

const UserFormWithGraphQL = compose(
  graphql(CREATE_USER, {
    name: "insert_user",
    options: {
      refetchQueries: [
        { query: GET_USER_LIST, variables: { orderBy: { first_name: "asc" } } }
      ]
    }
  }),
  graphql(UPDATE_USER, { name: "update_user" }),
  withFormik({
    displayName: "UserForm",
    handleSubmit,
    mapPropsToValues: ({ variables }) => {
      const substitute = variables.id
        ? generateSelectValue(variables.substitute, formatUserLabel)
        : null;
      return {
        ...variables,
        substitute
      };
    },
    validationSchema: schema
  })
)(UserEntryWithApollo);

const UserEntryWithIntl = withRouter(injectIntl(UserFormWithGraphQL));

const UserEntryWrapper = ({
  match: {
    params: { userId }
  }
}) => {
  const [variables, setVariables] = useState(null);
  if (variables) {
    return (
      <UserEntryWithIntl
        setVariables={setVariables}
        userId={userId}
        variables={variables}
      />
    );
  }
  if (userId) {
    return (
      <Query query={GET_USER} variables={{ userId }}>
        {({ data, error, loading }) => {
          if (loading) {
            return <p>Loading...</p>;
          }
          if (error) {
            return <p>Error :(</p>;
          }

          return (
            <UserEntryWithIntl
              setVariables={setVariables}
              userId={userId}
              variables={data.users[0]}
            />
          );
        }}
      </Query>
    );
  } else {
    return <UserEntryWithIntl variables={{}} />;
  }
};

const UserEntryWrapperWithRouter = withRouter(UserEntryWrapper);
export { UserEntryWrapperWithRouter as UserEntry };
