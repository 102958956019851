/* eslint-disable react/display-name */
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {
  FormattedMessage,
  defineMessages,
  injectIntl
} from "react-intl";
import {
  Grid,
  IconButton,
  Paper,
} from "@material-ui/core";
import {RichTextEditor} from "./draftjs-formik";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import PropTypes from "prop-types";
import React, {PureComponent, useCallback, useState} from "react";
import TextField from "@material-ui/core/TextField";
import uuid from "uuid/v4";

const messages = defineMessages({
  description: {
    defaultMessage: "Beskrivelse",
    id: "request-list.description"
  },
  price: {
    defaultMessage: "Pris",
    id: "request-list.price"
  },
  title: {
    defaultMessage: "Titel",
    id: "request-list.title"
  }
});

const EMPTY_TASK = {description: "", price: null, title: ""};

const Divider = React.memo(({index, onClick}) => {
  const handleAddTask = useCallback(() => {
    onClick({...EMPTY_TASK, order: index});
  }, [onClick, index]);
  return (
    <div style={{display: "flex", width: "100%"}}>
      <div style={{flex: 1, paddingTop: 14}}>
        <hr />
      </div>
      <div style={{width: 48}}>
        <IconButton aria-label="Save" onClick={handleAddTask}>
          <AddIcon />
        </IconButton>
      </div>
      <div style={{flex: 1, paddingTop: 14}}>
        <hr />
      </div>
    </div>
  );
});
Divider.propTypes = {
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

const taskComperator = (a, b) => a.order - b.order;
const TaskCards = React.memo(({onDelete, onSave,tasks}) => {
  if (!tasks || tasks.length === 0) {
    return null;
  }
  return tasks.sort(taskComperator).map((row, index) => (
    <Draggable key={row.id} draggableId={row.id} index={index}>
      {provided => (
        <div
          ref={provided.innerRef}
          style={{paddingBottom: 10}}
          {...provided.draggableProps}
        >
          <TaskForm
            dragHandleProps={provided.dragHandleProps}
            initialValues={row}
            onDelete={onDelete}
            onSave={onSave}
          />
          <Divider index={index} onClick={onSave} />
        </div>
      )}
    </Draggable>
  ));
});
TaskCards.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  tasks: PropTypes.array
};

const TaskForm = React.memo(
  injectIntl(({dragHandleProps, initialValues, intl, onDelete, onSave}) => {
    const [values, setValues] = useState({...initialValues});
    const handleAddTask = useCallback(() => {
      onSave(values);
      setValues(initialValues);
    }, [values, onSave, setValues, initialValues]);

    const handleRemoveTask = useCallback(() => {
      onDelete(values.id);
    }, [values, onDelete]);

    const handleChange = useCallback(
      event => {
        const copy = {...values, [event.target.name]: event.target.value};
        setValues(copy);
        if (copy.id) {
          onSave(copy);
        }
      },
      [values, onSave, setValues]
    );
    return (
      <Paper style={{padding: 10}}>
        <div style={{display: "flex", width: "100%"}}>
          {values.id ?
        <div
            style={{
              alignItems: "center",

              display: "flex",
              justifyContent: "center",
              width: 48
            }}
          >

            <IconButton aria-label="delete" style={{alignSelf: "flex-end"}} onClick={handleRemoveTask}>
                <DeleteIcon color="error"/>
              </IconButton>
            </div>:null}
          <div style={{flex: 1}}>
            <Grid container spacing={2}>
              <Grid item sm={9} xs={12}>
                <TextField
                  fullWidth
                  label={intl.formatMessage(messages.title)}
                  name="title"
                  type="text"
                  value={values.title}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <TextField
                  fullWidth
                  label={intl.formatMessage(messages.price)}
                  name="price"
                  type="number"
                  value={values.price}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RichTextEditor
                  label={intl.formatMessage(messages.description)}
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              alignItems: "center",

              display: "flex",
              justifyContent: "center",
              width: 48
            }}
          >
            {!values.id ? (
              <IconButton aria-label="Save" onClick={handleAddTask}>
                <DoneIcon />
              </IconButton>
            ) : (
              <div {...dragHandleProps}>
                <DragHandleIcon />
              </div>
            )}
          </div>
        </div>
      </Paper>
    );
  })
);
TaskForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  onSave: PropTypes.func.isRequired
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export class Tasks extends PureComponent {
  static propTypes = {
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired
  };

  handleSave = task => {
    const {
      field: {name, value},
      form: {setFieldValue}
    } = this.props;
    let newTasks = [];
    if (task.id) {
      const index = value.findIndex(t => t.id === task.id);
      newTasks = [...value];
      newTasks[index] = task;
    } else {
      task.id = uuid();
      if (task.order === undefined) {
        task.order = value.length;
        newTasks = [...value, task];
      } else {
        newTasks = [...value];
        newTasks.splice(task.order + 1, 0, task);
        newTasks = newTasks.map((t, index) => {
          t.order = index;
          return t;
        });
      }
    }
    setFieldValue(name, newTasks);
  };

  handleDelete = taskId => {
    const {
      field: {name, value},
      form: {setFieldValue}
    } = this.props;
    const tasksCopy = [...value];

    setFieldValue(name, tasksCopy.filter(t=>t.id !== taskId));

  }

  handleDragEnd = result => {
    const {
      field: {name, value},
      form: {setFieldValue}
    } = this.props;
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const tasks = reorder(value, result.source.index, result.destination.index);

    const newTasks = tasks.map((t, index) => {
      t.order = index;
      return t;
    });
    setFieldValue(name, newTasks);
  };
  render() {
    const {
      field: {value}
    } = this.props;
    return (
      <>
        <TaskForm initialValues={{...EMPTY_TASK}} onSave={this.handleSave} />
        <br />
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable droppableId="list">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <TaskCards tasks={value} onDelete={this.handleDelete} onSave={this.handleSave} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {value && value.length ? (
          <Paper>
            <div style={{padding: 10, textAlign: "right"}}>
              <FormattedMessage
                defaultMessage="Total: {total}"
                id="total"
                values={{
                  total: value
                    .reduce((total, task) => {
                      if (task.price != null && task.price !== "") {
                        let {price} = task;
                        if (typeof task.price === "string") {
                          price = parseFloat(price.replace(",", "."));
                        }
                        return total + price;
                      }
                      return total;
                    }, 0.0)
                    .toFixed(2)
                }}
              />
            </div>
          </Paper>
        ) : null}
      </>
    );
  }
}
