import { Autocomplete, AutocompleteChangeReason } from "@material-ui/lab";
import { Grid, TextField, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import React, { useCallback, useMemo, useState } from "react";
import _ from "lodash";
import parse from "autosuggest-highlight/parse";

const LOOKUP_THROTTLE_TIMEOUT = 200;

const BOLD_WEIGHT = 700;
const NORMAL_WEIGHT = 400;

interface AddressAutocompleteFieldProps {
  autocompleteService?: google.maps.places.AutocompleteService;
  autoFocus?: boolean;
  countryRestrictions: string | readonly string[];
  onChange: (value: string) => void;
  onPlaceSelected: (placeId: string) => void;
  open: boolean;
  value: string;
}

export function AddressAutocompleteField(
  props: AddressAutocompleteFieldProps
): JSX.Element {
  const {
    autocompleteService,
    autoFocus,
    countryRestrictions,
    onChange,
    onPlaceSelected,
    open,
    value
  } = props;

  const [options, setOptions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  const intl = useIntl();

  const fetch = useMemo(
    () =>
      _.throttle(
        (
          request: google.maps.places.AutocompletionRequest,
          callback: (
            result: google.maps.places.AutocompletePrediction[],
            status: google.maps.places.PlacesServiceStatus
          ) => void
        ) => {
          if (autocompleteService) {
            autocompleteService.getPlacePredictions(request, callback);
          }
        },
        LOOKUP_THROTTLE_TIMEOUT
      ),
    [autocompleteService]
  );

  React.useEffect(() => {
    let active = true;
    if (!autocompleteService) {
      return undefined;
    }
    if (value === "") {
      setOptions([]);
      return undefined;
    }
    fetch(
      {
        componentRestrictions: {
          country: countryRestrictions as string | string[]
        },
        input: value,
        types: ["geocode"]
      },
      (results: google.maps.places.AutocompletePrediction[]) => {
        if (active) {
          setOptions(results || []);
        }
      }
    );
    return () => {
      active = false;
    };
  }, [value, fetch, autocompleteService, countryRestrictions]);

  const getOptionLabel = useCallback(
    (option: google.maps.places.AutocompletePrediction): string =>
      option.description || "",
    []
  );

  const handleTextFieldChange = useCallback(
    (event: any) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  const handleTextFieldKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === "Enter") {
        if (options.length) {
          event.preventDefault();
          onPlaceSelected(options[0].place_id);
        }
      }
    },
    [onPlaceSelected, options]
  );

  const renderInput = useCallback(
    (params: any): JSX.Element => (
      <TextField
        {...params}
        fullWidth
        autoComplete="off"
        autoFocus={autoFocus ?? true}
        label={intl.formatMessage({
          defaultMessage: "Adresse",
          id: "address-autocomplete.address"
        })}
        margin="dense"
        onChange={handleTextFieldChange}
        onKeyDown={handleTextFieldKeyDown}
      />
    ),
    [autoFocus, handleTextFieldChange, handleTextFieldKeyDown, intl]
  );

  const renderOption = useCallback(
    (option: google.maps.places.AutocompletePrediction): JSX.Element => {
      const matches =
        option.structured_formatting.main_text_matched_substrings || [];
      const parts = parse(
        option.structured_formatting.main_text,
        matches.map((match: any) => [match.offset, match.offset + match.length])
      );

      return (
        <Grid container alignItems="center">
          <Grid item>
            <LocationOnIcon />
          </Grid>
          <Grid item xs>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{
                  fontWeight: part.highlight ? BOLD_WEIGHT : NORMAL_WEIGHT
                }}
              >
                {part.text}
              </span>
            ))}
            <Typography color="textSecondary" variant="body2">
              {option.structured_formatting.secondary_text}
            </Typography>
          </Grid>
        </Grid>
      );
    },
    []
  );

  const handleChange = useCallback(
    (
      _event: React.ChangeEvent<unknown>,
      selectedValue: string | google.maps.places.AutocompletePrediction | null,
      reason: AutocompleteChangeReason
    ): void => {
      if (reason === "clear") {
        onChange("");
      } else if (reason === "select-option") {
        if (selectedValue && typeof selectedValue !== "string") {
          onPlaceSelected(selectedValue.place_id);
        }
      }
    },
    [onChange, onPlaceSelected]
  );

  return (
    <Autocomplete<google.maps.places.AutocompletePrediction>
      freeSolo
      includeInputInList
      filterOptions={_.identity}
      getOptionLabel={getOptionLabel}
      inputValue={value}
      multiple={false}
      openOnFocus={open}
      options={options}
      renderInput={renderInput}
      renderOption={renderOption}
      style={{ width: "100%" }}
      onChange={handleChange}
    />
  );
}
