import { LIBRARIES } from "./constants";
import { useIntl } from "react-intl";
import { useLoadScript } from "@react-google-maps/api";

export function useLoadGoogleMaps(): {
  isLoaded: boolean;
  loadError: Error | undefined;
  url: string;
} {
  const intl = useIntl();
  return useLoadScript({
    googleMapsApiKey: "AIzaSyAareESm47Joh6AkGMIkGBNGRyGVrBeOak",
    language: intl.locale,
    libraries: LIBRARIES
  });
}
