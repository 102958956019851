import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper
} from "@material-ui/core";
import { CalendarButton } from "../../components/calendar-button";
import { CaseTypeSelect } from "../../components/case-type-select";
import { ConstructionPlanSelect } from "../../components/construction-plan-select";
import { ContactCreateDialog } from "../../components/contact-create-dialog";
import { ContactSelect } from "../../components/contact-select";
import { DateTimePickerField } from "../../components/date-time-fields";
import { ExternalInfo, ResponsibleInfo } from "../request/request-form";
import { Field } from "formik";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { Pin } from "../../components/pin";
import { SupplierList } from "./supplier-list";
import { TextFieldAutosize } from "../../components/resizing-text-area";
import { UserSelect } from "../../components/user-select";
import { formikPropTypes } from "../../utils/form-helpers";
import Bowser from "bowser";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PeopleIcon from "@material-ui/icons/People";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PersonIcon from "@material-ui/icons/Person";
import React, { PureComponent } from "react";
import SimpleMap from "../../components/SimpleMap";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";

const browser = Bowser.getParser(window.navigator.userAgent);
const isMobile = browser.getPlatformType(true) !== "desktop";

const messages = defineMessages({
  address: {
    defaultMessage: "Adresse",
    id: "case-form.address"
  },
  alreadyExists: {
    defaultMessage:
      "Der eksisterer allerede en sag med denne navn, mail og telefon telefonkombination",
    id: "case-form.alreadyExists"
  },
  bringToilet: {
    defaultMessage: "Medbring skurvogn/wc",
    id: "case-form.bringToilet"
  },
  builder: {
    defaultMessage: "Bygherre",
    id: "case-form.builder"
  },
  caseClosed: {
    defaultMessage: "Sag lukket",
    id: "case-form.caseClosed"
  },

  caseOpen: {
    defaultMessage: "Sag åben",
    id: "case-form.caseOpen"
  },
  cases: {
    defaultMessage: "Sager",
    id: "case-form.cases"
  },
  caseType: {
    defaultMessage: "Sagstype",
    id: "case-form.caseType"
  },
  city: {
    defaultMessage: "By",
    id: "case-form.city"
  },
  company: {
    defaultMessage: "Firma",
    id: "case-form.company"
  },
  description: {
    defaultMessage: "Beskrivelse",
    id: "case-form.description"
  },
  details: {
    defaultMessage: "Detaljer",
    id: "case-form.details"
  },
  documents: {
    defaultMessage: "Dokumenter",
    id: "case-form.documents"
  },
  estimatedTimeInWeeks: {
    defaultMessage: "Estimeret tid i uger",
    id: "case-form.estimatedTimeInWeeks"
  },
  importantNote: {
    defaultMessage: "Vigtige forhold vedr. sagsadressen",
    id: "case-form.importantNote"
  },
  inspectionDateAndTime: {
    defaultMessage: "Indspektionsdato og tid",
    id: "case-form.inspectionDateAndTime"
  },
  inspectionTime: {
    defaultMessage: "Indspektionstidspunkt",
    id: "case-form.inspectionTime"
  },
  lastName: {
    defaultMessage: "Efternavn",
    id: "case-form.lastName"
  },
  mail: {
    defaultMessage: "Mail",
    id: "case-form.mail"
  },
  mobile: {
    defaultMessage: "Mobil",
    id: "case-form.mobile"
  },
  name: {
    defaultMessage: "Navn",
    id: "case-form.name"
  },
  otherAddress: {
    defaultMessage: "Sagsadresse: Anden adresse end bygherres",
    id: "case-form.otherAddress"
  },
  otherNote: {
    defaultMessage: "Andre noter",
    id: "case-form.otherNote"
  },
  phone: {
    defaultMessage: "Telefon",
    id: "case-form.phone"
  },
  photos: {
    defaultMessage: "Fotos",
    id: "case-form.photos"
  },
  responsible: {
    defaultMessage: "Ansvarlig",
    id: "case-form.responsible"
  },
  save: {
    defaultMessage: "Gem",
    id: "case-form.save"
  },
  searchAddress: {
    defaultMessage: "Adressesøgning",
    id: "case-form.searchAddress"
  },
  selectBuilder: {
    defaultMessage: "Vælg bygherre...",
    id: "case-form.selectBuilder"
  },
  selectResponsible: {
    defaultMessage: "Vælg ansvarlig...",
    id: "case-form.selectResponsible"
  },
  suppliers: {
    defaultMessage: "Kontaktpersoner",
    id: "case-form.suppliers"
  },
  task: {
    defaultMessage: "Opgave",
    id: "case-form.task"
  },
  usefullNote: {
    defaultMessage: "Nyttige interne informationer omkring sagen",
    id: "case-form.usefullNote"
  },
  zipCode: {
    defaultMessage: "Post nr.",
    id: "case-form.zipCode"
  }
});

class CaseForm extends PureComponent {
  static propTypes = {
    ...formikPropTypes
  };
  state = {
    builderSync: false,
    foundAdresses: []
  };

  handleOtherAddressBlur = event => {
    const { address, city, zip_code } = this.props.values;
    this.props.handleBlur(event);
    if (address && zip_code && city) {
      this.props.onContactSelected({ address, city, zip_code }, true);
    }
  };

  handleOtherAddressChanged = event => {
    if (!event.target.checked) {
      this.props.onContactSelected(this.props.values.builder, true);
    } else {
      const { address, city, zip_code } = this.props.values;
      if (address && zip_code && city) {
        this.props.onContactSelected({ address, city, zip_code }, true);
      }
    }
    this.props.handleChange(event);
  };

  handleMapClick = geo => {
    if (!isMobile) {
      this.props.setFieldValue("lat", geo.lat);
      this.props.setFieldValue("lng", geo.lng);
    }
  };

  handleSearchAddressChange = event => {
    this.searchForAdresses(event.target.value);
  };

  searchForAdresses = _.debounce(address => {
    fetch("/geo", {
      body: JSON.stringify({ address }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "POST"
    })
      .then(res => res.json())
      .then(
        geoCodes => {
          this.setState({ foundAdresses: geoCodes.json.results });

          return;
        },
        () => {
          this.setState({ foundAdresses: [] });
        }
      )
      .catch(error => {
        console.log(error);
      });
  }, 500);

  handleSyncToEcon = () => {
    this.setState({ builderSync: true });
    const token = localStorage.getItem("token");
    fetch("/econ", {
      body: JSON.stringify(this.props.values.builder),
      headers: {
        Accept: "application/json",
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      method: "POST"
    })
      .then(response => {
        if (response.statusText === "OK") {
          this.setState({ builderSync: false });
        }
        return;
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const {
      contactCreateDialogOpen,
      currentUser,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      intl,
      onContactCreated,
      onContactCreateDialogCancel,
      onContactSelected,
      onCreateCompanyClick,
      onCreateContactClick,
      onCreateSecondaryContactClick,
      setFieldValue,
      touched,
      values
    } = this.props;

    const currentRole = currentUser ? currentUser.role : null;
    const isAdmin = currentRole === "admin";
    const isSuperUser = currentRole === "superuser";

    return (
      <>
        <div>{errors.form}</div>
        <form onSubmit={handleSubmit}>
          <Grid container justify="flex-end" spacing={3}>
            <Grid item lg={5} sm={5} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.state == null || values.state}
                    color={"primary"}
                    name="state"
                    value="open"
                    onChange={handleChange}
                  />
                }
                label={
                  values.state == null || values.state
                    ? intl.formatMessage(messages.caseOpen)
                    : intl.formatMessage(messages.caseClosed)
                }
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={values.flag}
                    color={"primary"}
                    name="flag"
                    onChange={handleChange}
                  />
                }
                label={
                  <FormattedMessage defaultMessage="Flag" id="case-form.flag" />
                }
              />
              <CalendarButton caseInstance={values} />
              {isAdmin || isSuperUser ? (
                <Button
                  color="primary"
                  disabled={!values.builder || values.builder.synced}
                  variant="contained"
                  onClick={this.handleSyncToEcon}
                >
                  <FormattedMessage
                    defaultMessage="ØKONOMI"
                    id="case-form.sync"
                  />
                </Button>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item lg={3} sm={6} xs={12}>
              <Field
                component={CaseTypeSelect}
                label={intl.formatMessage(messages.caseType)}
                name="case_type"
              />
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <Field
                fullWidth
                component={DateTimePickerField}
                label={intl.formatMessage(messages.inspectionDateAndTime)}
                name="date_of_inspection"
              />
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <TextField
                fullWidth
                error={
                  errors.estimated_time &&
                  touched.estimated_time &&
                  errors.estimated_time
                }
                label={intl.formatMessage(messages.estimatedTimeInWeeks)}
                name="estimated_time"
                type="number"
                value={values.estimated_time}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <Field
                component={ConstructionPlanSelect}
                label={
                  <FormattedMessage
                    defaultMessage="Byggeplan"
                    id="calendar-entry-dialog.construction_plan"
                  />
                }
                name="construction_plan_id"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextFieldAutosize
                fullWidth
                multiline
                error={errors.task}
                label={intl.formatMessage(messages.task)}
                name="task"
                type="text"
                value={values.task}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldAutosize
                fullWidth
                multiline
                error={errors.description}
                label={intl.formatMessage(messages.description)}
                name="description"
                type="text"
                value={values.description}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <Paper square style={{ padding: 10 }}>
                <div style={{ display: "flex", width: "100%" }}>
                  <div style={{ flex: 1 }}>
                    <Field
                      component={ContactSelect}
                      label={intl.formatMessage(messages.builder)}
                      name="builder"
                      onSelect={onContactSelected}
                    />
                  </div>
                  <div style={{ width: 48 }}>
                    <IconButton onClick={onCreateContactClick}>
                      <PersonAddIcon />
                    </IconButton>
                  </div>
                </div>
                {values.builder ? (
                  <>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ flex: 1 }}>
                        <ExternalInfo
                          caseInstance={values}
                          contact={values.builder}
                          secondaryContact={values.secondary_builder}
                        />
                      </div>
                      <div style={{ width: 48 }}>
                        <div style={{ width: 48 }}>
                          <IconButton
                            component="a"
                            href={`#/contacts/${values.builder.id}`}
                          >
                            <PersonIcon />
                          </IconButton>
                        </div>
                        {values.secondary_builder ? (
                          <div style={{ width: 48 }}>
                            <IconButton
                              component="a"
                              href={`#/contacts/${values.secondary_builder.id}`}
                            >
                              <PeopleIcon />
                            </IconButton>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ flex: 1 }}>
                        <Field
                          component={ContactSelect}
                          label={
                            <FormattedMessage
                              defaultMessage="Extra kontakt"
                              id="case-form.secondary-builder"
                            />
                          }
                          name="secondary_builder"
                        />
                      </div>
                      <div style={{ width: 48 }}>
                        <IconButton onClick={onCreateSecondaryContactClick}>
                          <PersonAddIcon />
                        </IconButton>
                      </div>
                    </div>
                  </>
                ) : null}
              </Paper>
              <br />
              <Paper square style={{ padding: 10 }}>
                <Field
                  component={UserSelect}
                  label={intl.formatMessage(messages.responsible)}
                  name="responsible"
                />
                {values.responsible ? (
                  <ResponsibleInfo user={values.responsible} />
                ) : null}
              </Paper>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Paper
                square
                style={{ height: "100%", minHeight: 500, width: "100%" }}
              >
                <div style={{ height: 400, width: "100%" }}>
                  <SimpleMap
                    center={
                      values.lat && values.lng
                        ? [values.lat, values.lng]
                        : undefined
                    }
                    zoom={values.lat && values.lng ? 11 : undefined}
                    onClick={this.handleMapClick}
                  >
                    {values.lat && values.lng ? (
                      <Pin
                        mapPosition
                        color="grey"
                        icon="circle"
                        lat={values.lat}
                        lng={values.lng}
                      />
                    ) : null}
                  </SimpleMap>
                </div>
                <div style={{ padding: 10 }}>
                  <TextField
                    fullWidth
                    id="searchAddress"
                    label={intl.formatMessage(messages.searchAddress)}
                    name="searchAddress"
                    onChange={this.handleSearchAddressChange}
                  />

                  {this.state.foundAdresses.length ? (
                    <List
                      aria-label="Secondary mailbox folders"
                      component="nav"
                    >
                      {this.state.foundAdresses.map(address => (
                        <ListItem
                          key={address.place_id}
                          button
                          // eslint-disable-next-line react/jsx-no-bind
                          onClick={() => {
                            const { lat, lng } = address.geometry.location;
                            setFieldValue("lat", lat);
                            setFieldValue("lng", lng);
                          }}
                        >
                          <ListItemText primary={address.formatted_address} />
                        </ListItem>
                      ))}
                    </List>
                  ) : null}
                </div>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper square style={{ margin: "10px 0", padding: 10 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.other_address}
                      color={"primary"}
                      name="other_address"
                      value={values.other_address}
                      onChange={this.handleOtherAddressChanged}
                    />
                  }
                  label={intl.formatMessage(messages.otherAddress)}
                />
                {values.other_address ? (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        error={
                          errors.address && touched.address && errors.address
                        }
                        label={intl.formatMessage(messages.address)}
                        name="address"
                        type="text"
                        value={values.address}
                        onBlur={this.handleOtherAddressBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        error={
                          errors.zip_code && touched.zip_code && errors.zip_code
                        }
                        label={intl.formatMessage(messages.zipCode)}
                        name="zip_code"
                        type="text"
                        value={values.zip_code}
                        onBlur={this.handleOtherAddressBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        error={errors.city && touched.city && errors.city}
                        label={intl.formatMessage(messages.city)}
                        name="city"
                        type="text"
                        value={values.city}
                        onBlur={this.handleOtherAddressBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                ) : null}
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper square>
                <div style={{ display: "flex", padding: 10, width: "100%" }}>
                  <div
                    style={{
                      flex: 1
                    }}
                  >
                    <Field
                      component={ContactSelect}
                      inputStyle={{
                        backgroundColor: values.company ? "auto" : "#ff000052"
                      }}
                      label={
                        <FormattedMessage
                          defaultMessage="Byggeleder"
                          id="case-form.company"
                        />
                      }
                      name="company"
                      placeholder={
                        <FormattedMessage
                          defaultMessage="Indsæt byggeleder for at han vises i dine påmindelser"
                          id="case-form.company-placeholder"
                        />
                      }
                    />
                  </div>
                  <div style={{ width: 48 }}>
                    <IconButton onClick={onCreateCompanyClick}>
                      <PersonAddIcon />
                    </IconButton>
                  </div>
                </div>

                <div style={{ padding: 10 }}>
                  <Field
                    isMulti
                    noInitial
                    component={ContactSelect}
                    label={intl.formatMessage(messages.suppliers)}
                    name="suppliers"
                  />
                </div>
                {values.suppliers && values.suppliers.length ? (
                  <SupplierList suppliers={values.suppliers} />
                ) : null}
              </Paper>
            </Grid>
          </Grid>
          <div style={{ height: 200 }} />
        </form>
        {contactCreateDialogOpen ? (
          <ContactCreateDialog
            defaultType={contactCreateDialogOpen}
            open={contactCreateDialogOpen}
            onCancel={onContactCreateDialogCancel}
            onContactCreated={onContactCreated}
          />
        ) : null}
      </>
    );
  }
}

const CaseFormWithIntl = injectIntl(CaseForm);

export { CaseFormWithIntl as CaseForm };
