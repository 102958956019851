import Bowser from "bowser";
import React, {FunctionComponent} from "react";
const browser = Bowser.getParser(window.navigator.userAgent);
const platform = browser.getPlatformType(true);
const isMobile = platform === "mobile" || platform === "tablet";
export const APP_BAR_HEIGHT = isMobile ? 56 : 64;

interface Props {
  appBar: React.ReactNode;
  fab?: React.ReactNode;
  withTabs?: boolean;
}

export const Page: FunctionComponent<Props> = React.memo(
  ({appBar, children, fab, withTabs}) => {
    return (
      <div
        style={{
          height: "100%",
          minHeight: "100%",
          paddingTop: withTabs ? APP_BAR_HEIGHT + 48 : APP_BAR_HEIGHT,
        }}
      >
        {appBar}
        <div style={{height: "calc(100% - 64px)"}}>{children}</div>
        {fab}
      </div>
    );
  },
);
