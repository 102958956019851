import { AutocompleteSelect } from "../../components/autocomplete-select";
import {
  Button,
  Checkbox,
  DialogContent,
  FormControlLabel,
  Grid,
  TextField
} from "@material-ui/core";
import { DialogBase } from "../../components/dialog";
import { Field, withFormik } from "formik";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { REQUESTS_STATE_OPTIONS } from "./request-form";
import { flowRight as compose } from "lodash";
import { withApollo } from "@apollo/client/react/hoc";
import { withAppContext } from "../../utils/with-app-context";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export const STANDARD_REQUEST_FILTER = {
  state: REQUESTS_STATE_OPTIONS[0].value
};

export const getFilters = () => {
  const json = localStorage.getItem("request-list-filter");
  const savedFilter = JSON.parse(json);
  return savedFilter || STANDARD_REQUEST_FILTER;
};

export const setStandardFilter = () => {
  localStorage.setItem(
    "request-list-filter",
    JSON.stringify(STANDARD_REQUEST_FILTER)
  );
};

const messages = defineMessages({
  external: {
    defaultMessage: "Ekstern email",
    id: "request-filter-dialog.external"
  },
  filter: {
    defaultMessage: "Filtrér",
    id: "request-filter-dialog.filter"
  },
  myCases: {
    defaultMessage: "Vis kun mine sager",
    id: "request-filter-dialog.myCases"
  },
  responsible: {
    defaultMessage: "Initialer",
    id: "request-filter-dialog.responsible"
  },
  state: {
    defaultMessage: "Udført",
    id: "request-filter-dialog.state"
  }
});

class RequestFilterDialog extends PureComponent {
  static propTypes = {
    context: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onFilterChanged: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired
  };

  handleReset = () => {
    localStorage.setItem(
      "request-list-filter",
      JSON.stringify(STANDARD_REQUEST_FILTER)
    );
    this.props.onFilterChanged(STANDARD_REQUEST_FILTER);
  };

  handleMyRequestsChange = (_event, value) => {
    if (value) {
      this.props.setFieldValue(
        "responsible",
        this.props.context.state.currentUser.acronym
      );
    } else {
      this.props.setFieldValue("responsible", "");
    }
  };

  render() {
    const {
      context,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      intl: { formatMessage },
      onCancel,
      open,
      values
    } = this.props;
    return (
      <DialogBase
        open={open}
        title={formatMessage(messages.filter)}
        onCancel={onCancel}
        onOk={handleSubmit}
      >
        <DialogContent>
          <div style={{ height: 400 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    //error={errors.state}
                    component={AutocompleteSelect}
                    label={formatMessage(messages.state)}
                    name="state"
                    options={REQUESTS_STATE_OPTIONS}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button style={{ color: "red" }} onClick={this.handleReset}>
                    <FormattedMessage
                      defaultMessage="Nulstil"
                      id="request-filter-dialog.reset"
                    />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    error={errors.responsible}
                    label={formatMessage(messages.responsible)}
                    name="responsible"
                    type="text"
                    value={values.responsible}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          values.responsible ===
                          context.state.currentUser.acronym
                        }
                        color="primary"
                        value={context.state.currentUser.acronym}
                        onChange={this.handleMyRequestsChange}
                      />
                    }
                    label={formatMessage(messages.myCases)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    error={errors.external}
                    label={formatMessage(messages.external)}
                    name="external"
                    type="text"
                    value={values.external}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </form>
          </div>
        </DialogContent>
      </DialogBase>
    );
  }
}

const handleSubmit = (payload, { props }) => {
  const { external, responsible, state } = payload;
  const variables = {
    external: external ? ["mail", external.toLowerCase()] : null,
    responsible: responsible ? ["acronym", responsible.toUpperCase()] : null,
    state: state ? state.value : undefined
  };

  localStorage.setItem("request-list-filter", JSON.stringify(variables));
  props.onFilterChanged(variables);
};

const enhanced = compose(
  withFormik({
    displayName: "RequestFilterDialog",
    enableReinitialize: true,
    handleSubmit,
    mapPropsToValues: () => {
      const values = getFilters();
      const state = REQUESTS_STATE_OPTIONS.find(
        option => option.value === values.state
      );
      const responsible = values.responsible ? values.responsible[1] : null;
      const external = values.external ? values.external[1] : null;
      return {
        external,
        responsible,
        state
      };
    }
  }),
  injectIntl,
  withApollo,
  withAppContext
)(RequestFilterDialog);

export { enhanced as RequestFilterDialog };
