/* eslint-disable react/jsx-no-bind */
import { DatePicker, DateTimePicker, TimePicker } from "@material-ui/pickers";
import { startOf } from "../utils/form-helpers";
import Bowser from "bowser";
import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
const browser = Bowser.getParser(window.navigator.userAgent);
const isMobile = browser.getPlatformType(true) !== "desktop";

export const DatePickerField = React.memo(({ field, form, ...other }) => {
  const currentError = form.errors[field.name];
  return (
    <DatePicker
      clearable
      DialogProps={{ fullScreen: isMobile }}
      error={Boolean(currentError)}
      format="Do MMMM YYYY"
      helperText={currentError}
      name={field.name}
      value={field.value || null}
      onChange={date => form.setFieldValue(field.name, date, true)}
      onError={(_, error) => form.setFieldError(field.name, error)}
      {...other}
    />
  );
});
DatePickerField.displayName = "DatePickerField";
DatePickerField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object
};

export const DateTimePickerField = React.memo(({ field, form, ...other }) => {
  const currentError = form.errors[field.name];
  return (
    <DateTimePicker
      clearable
      ampm={false}
      DialogProps={{ fullScreen: isMobile }}
      error={Boolean(currentError)}
      format="Do MMMM YYYY HH:mm"
      helperText={currentError}
      initialFocusedDate={startOf(moment(), 15, "minute")}
      minutesStep={15}
      name={field.name}
      value={field.value}
      onChange={date => form.setFieldValue(field.name, date, true)}
      onError={(_, error) => form.setFieldError(field.name, error)}
      {...other}
    />
  );
});
DateTimePickerField.displayName = "DateTimePickerField";
DateTimePickerField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object
};

export const TimePickerField = React.memo(({ field, form, ...other }) => {
  const currentError = form.errors[field.name];
  return (
    <TimePicker
      clearable
      disablePast
      ampm={false}
      DialogProps={{ fullScreen: isMobile }}
      error={Boolean(currentError)}
      helperText={currentError}
      name={field.name}
      value={field.value}
      onChange={time => form.setFieldValue(field.name, time, true)}
      onError={(_, error) => form.setFieldError(field.name, error)}
      {...other}
    />
  );
});
TimePickerField.displayName = "TimePickerField";
TimePickerField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object
};
