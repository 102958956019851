import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import { BASE_URL } from "../utils/app";
import { gantt } from "dhtmlx-gantt";
import React, { useEffect, useRef } from "react";
gantt.config.readonly = true;
export const Gantt = React.memo(function Gantt({
  tasks
}: {
  tasks: any;
}): JSX.Element {
  const ganttContainerRef = useRef();
  useEffect(() => {
    if (ganttContainerRef.current) {
      gantt.i18n.setLocale("da");
      gantt.init(ganttContainerRef.current);
      gantt.parse(tasks);
      gantt.config.scales = [{ format: "%F, %Y", step: 1, unit: "month" }];
      gantt.config.columns = [
        { label: "Sag", name: "text", tree: true, width: "*" },
        { align: "center", label: "Start", name: "start_date" }
      ];
      gantt.attachEvent(
        "onTaskClick",
        id => {
          if (id.length === 36) {
            window.open(`${BASE_URL}/cases/${id}`, "blank");
          }
          return true;
        },
        null
      );
    }
  }, [tasks]);

  return (
    <div
      ref={ganttContainerRef}
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center"
      }}
    />
  );
});
