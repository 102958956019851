import {
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup
} from "@material-ui/core";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { PIN_SHAPES, Pin, PinInsideIcon } from "../../components/pin";
import { formikPropTypes } from "../../utils/form-helpers";
import React from "react";
import TextField from "@material-ui/core/TextField";

const messages = defineMessages({
  name: {
    defaultMessage: "Navn",
    id: "case-types-form.name"
  }
});

const PIN_COLORS = [
  "red",
  "darkred",
  "lightred",
  "orange",
  "beige",
  "green",
  "darkgreen",
  "lightgreen",
  "blue",
  "darkblue",
  "lightblue",
  "purple",
  "darkpurple",
  "pink",
  "cadetblue",
  "gray",
  "lightgray"
];

const CaseTypeForm = React.memo(
  // eslint-disable-next-line react/prop-types
  ({ errors, handleBlur, handleChange, handleSubmit, intl, values }) => (
    <>
      <form onSubmit={handleSubmit}>
        <div>{errors.form}</div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              error={errors.name}
              label={intl.formatMessage(messages.name)}
              name="name"
              type="text"
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              error={!!errors.price}
              label={
                <FormattedMessage
                  defaultMessage="Pris"
                  id="case-types-form.price"
                />
              }
              name="price"
              type="text"
              value={values.price}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormLabel component="legend">
              <FormattedMessage
                defaultMessage="Farve"
                id="case-types-form.color"
              />
            </FormLabel>
            <RadioGroup
              aria-label="Gender"
              error={errors.color}
              name="color"
              value={values.color}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              {PIN_COLORS.map(color => (
                <FormControlLabel
                  key={color}
                  control={<Radio />}
                  label={<Pin color={color} />}
                  value={color}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={4}>
            <FormLabel component="legend">
              <FormattedMessage
                defaultMessage="Ikon"
                id="case-types-form.color"
              />
            </FormLabel>
            <RadioGroup
              aria-label="icon"
              error={errors.icon}
              name="icon"
              value={values.icon}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              {Object.keys(PIN_SHAPES)
                .sort()
                .map(icon => (
                  <FormControlLabel
                    key={icon}
                    control={<Radio />}
                    label={
                      <div style={{ width: 38 }}>
                        <PinInsideIcon shape={icon} />
                      </div>
                    }
                    value={icon}
                  />
                ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={4}>
            <FormattedMessage
              defaultMessage="Preview"
              id="case-types-form.preview"
            />
            <Pin color={values.color} icon={values.icon} />
          </Grid>
        </Grid>
      </form>
    </>
  )
);
CaseTypeForm.displayName = "CaseTypeForm";

CaseTypeForm.propTypes = {
  ...formikPropTypes
};

const CaseTypeFormWithIntl = injectIntl(CaseTypeForm);

export { CaseTypeFormWithIntl as CaseTypeForm };
