import { CaseSelect } from "../../../components/case-select";
import { DatePickerField } from "../../../components/date-time-fields";
import { Field } from "formik";
import { FormattedMessage } from "react-intl";
import { Grid } from "@material-ui/core";
import { UserSelect } from "../../../components/user-select";
import React from "react";
import TextField from "@material-ui/core/TextField";

interface Props {
  errors: any;
  handleBlur: any;
  handleChange: any;
  handleSubmit: any;
  isSubmitting: any;
  showCaseSelect: boolean;
  touched: any;
  values: any;
}

function TimeForm({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  showCaseSelect,
  values
}: Props): JSX.Element {
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {showCaseSelect ? (
            <Grid item xs={12}>
              <Field
                autoFocus
                noInitial
                component={CaseSelect}
                label={
                  <FormattedMessage defaultMessage="Sag" id="time-form.case" />
                }
                name="case_id"
              />
            </Grid>
          ) : null}
          <Grid item sm={6} xs={12}>
            <Field
              fullWidth
              component={DatePickerField}
              label={
                <FormattedMessage
                  defaultMessage="Dokument dato"
                  id="time-form.date"
                />
              }
              name="date"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              component={UserSelect}
              label={
                <FormattedMessage
                  defaultMessage="Medarbejder"
                  id="time-form.user"
                />
              }
              name="user"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              autoFocus={!showCaseSelect}
              error={!!errors.description}
              label={
                <FormattedMessage
                  defaultMessage="Beskrivelse"
                  id="time-form.description"
                />
              }
              name="description"
              type="text"
              value={values.description}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              error={!!errors.time}
              label={
                <FormattedMessage defaultMessage="Timer" id="time-form.hours" />
              }
              name="time"
              type="text"
              value={values.time}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}
export default TimeForm;
