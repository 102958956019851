import { Grid } from "@material-ui/core";
import { defineMessages, injectIntl } from "react-intl";
import { formikPropTypes } from "../../utils/form-helpers";
import { withApollo } from "@apollo/client/react/hoc";
import React, { PureComponent } from "react";
import TextField from "@material-ui/core/TextField";

const messages = defineMessages({
  title: {
    defaultMessage: "Titel",
    id: "case-foto-form.title"
  }
});

class ArchiveFileForm extends PureComponent {
  static propTypes = {
    ...formikPropTypes
  };

  render() {
    const {
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      intl,
      values
    } = this.props;

    return (
      <>
        <form onSubmit={handleSubmit}>
          <div>{errors.form}</div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                error={errors.title}
                label={intl.formatMessage(messages.title)}
                name="title"
                type="text"
                value={values.title}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

const ArchiveFileFormWithIntl = injectIntl(withApollo(ArchiveFileForm));

export { ArchiveFileFormWithIntl as ArchiveFileForm };
