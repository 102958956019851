import { AppContext } from "../components/AppContext";
import React from "react";

export const withAppContext = Element => {
  return React.forwardRef((props, ref) => {
    return (
      <AppContext.Consumer>
        {context => <Element context={context} {...props} ref={ref} />}
      </AppContext.Consumer>
    );
  });
};