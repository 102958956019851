import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  injectIntl
} from "react-intl";

import "react-image-lightbox/style.css";
import { Button, Card, Typography } from "@material-ui/core";
import {
  CASE_PHOTOS_AND_CASE_TYPE,
  CREATE_CASE_PHOTO,
  UPDATE_CASE_INSPECTION_DATE
} from "../../../graphql/cases";
import {
  LazyLoadImage,
  trackWindowScroll
} from "react-lazy-load-image-component";
import { Upload } from "../../../components/upload";
import { withApollo } from "@apollo/client/react/hoc";
import { withAppContext } from "../../../utils/with-app-context";
import { withRouter } from "react-router";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Lightbox from "react-image-lightbox";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import ReactDOM from "react-dom";

const BUILDING_INSPECTION_NOT_STARTED_ID =
  "c01495b0-869a-41fc-a552-5664460e9bf2";
const BUILDING_INSPECTION_STARTED_ID = "13b75bf9-57c9-42ad-8c6d-f056e8f394e1";

export const fileInstanceToURL = (photo, width) => {
  return `/files/get/${photo.file.id}/${encodeURI(
    photo.file.filename
  )}?sharekey=${photo.file.share_key}${width ? `&width=${width}` : ""}`;
};

class PhotoCard extends PureComponent {
  static propTypes = {
    onFileSelected: PropTypes.func,
    onPhotoClick: PropTypes.func.isRequired,
    photo: PropTypes.object.isRequired,
    photoIndex: PropTypes.number.isRequired,
    scrollPosition: PropTypes.object.isRequired
  };

  handleClick = () => this.props.onPhotoClick(this.props.photoIndex);
  handleFileSelected = () => this.props.onFileSelected([this.props.photo.file]);

  render() {
    const { onFileSelected, photo, scrollPosition } = this.props;
    return (
      <Card
        square
        style={{
          display: "flex",
          flexDirection: "column",
          margin: 5,
          maxHeight: "220px",
          maxWidth: "160px",
          minHeight: "220px",
          minWidth: "160px",
          overflow: "hidden",
          textAlign: "center"
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <LazyLoadImage
            alt={photo.file.filename}
            effect="opacity"
            scrollPosition={scrollPosition}
            src={fileInstanceToURL(photo, 160)}
            style={{ maxHeight: 125 }}
            onClick={this.handleClick}
          />
          <div style={{ textAlign: "center" }}>
            <FormattedDate value={photo.file.created} />{" "}
            <FormattedTime value={photo.file.created} />
          </div>
          <div style={{ fontSize: 10, textAlign: "center" }}>{photo.title}</div>
        </div>

        {!onFileSelected ? (
          <Button
            component="a"
            href={`#/cases/${photo.case_id}/photos/${photo.id}`}
          >
            <FormattedMessage defaultMessage="Redigér" id="photo-card.edit" />
          </Button>
        ) : (
          <Button onClick={this.handleFileSelected}>
            <FormattedMessage defaultMessage="Vælg" id="photo-card.select" />
          </Button>
        )}
      </Card>
    );
  }
}

const appRoot = document.getElementById("root");

class CasePhotoList extends PureComponent {
  static propTypes = {
    client: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    onFileSelected: PropTypes.func,
    scrollPosition: PropTypes.object
  };

  state = {
    caseTypeId: null,
    photoIndex: null,
    photos: [],
    uploadError: false
  };
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
  }
  componentDidMount() {
    this.fetchData();
    appRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    appRoot.removeChild(this.el);
  }

  addPhoto = async files => {
    const file = files[0];
    const returning = await this.props.client.mutate({
      mutation: CREATE_CASE_PHOTO,
      variables: {
        case_photos: [
          {
            case_id: this.props.match.params.caseId,
            file_id: file.id,
            title: file.filename
          }
        ]
      }
    });
    const photo = returning.data.insert_case_photos.returning[0];
    const currentPhotos = [photo, ...this.state.photos];
    if (
      !this.state.photos.length &&
      (this.state.caseTypeId === BUILDING_INSPECTION_NOT_STARTED_ID ||
        this.state.caseTypeId === BUILDING_INSPECTION_STARTED_ID)
    ) {
      await this.props.client.mutate({
        mutation: UPDATE_CASE_INSPECTION_DATE,
        variables: {
          date: new Date().toISOString(),
          id: this.props.match.params.caseId
        }
      });
    }
    this.setState({ photos: currentPhotos });
  };

  handleFileUploaded = file => {
    this.addPhoto(file);
  };

  fetchData = async () => {
    this.props.context.setLoading(true);

    try {
      const response = await this.props.client.query({
        fetchPolicy: "network-only",
        query: CASE_PHOTOS_AND_CASE_TYPE,
        variables: {
          caseId: this.props.match.params.caseId,
          orderBy: { file: { created: "desc" } }
        }
      });
      this.setState({
        caseTypeId: response.data.cases_by_pk.case_type_id,
        photos: response.data.case_photos
      });
      this.props.context.setLoading(false);
    } catch (e) {
      this.props.context.setLoading(false);
      // eslint-disable-next-line no-console
      console.log(e);
      return [];
    }
  };

  handlePhotoClick = photoIndex => {
    this.setState({ photoIndex });
  };

  handleUploadError = () => {
    this.setState({ uploadError: true });
  };

  handleUploadStart = () => {
    this.setState({ uploadError: false });
  };

  handleDownloadClick = () => {
    window.open(
      fileInstanceToURL(this.state.photos[this.state.photoIndex]),
      "_blank"
    );
  };

  render() {
    const { scrollPosition } = this.props;
    const groupedPhotos = this.state.photos.reduce((groups, photo) => {
      const date = photo.file.created.split("T")[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(photo);
      return groups;
    }, {});

    let photoIndexCounter = -1;
    return (
      <div style={{ margin: 10 }}>
        {this.state.uploadError ? (
          <Typography color="error" variant="h5">
            <FormattedMessage
              defaultMessage="Der skete en fejl. Ikke alle filer er blevet uploadet"
              id="photo-upload-list.error"
            />
          </Typography>
        ) : null}
        {Object.keys(groupedPhotos).map((date, index) => {
          return (
            <div key={index}>
              <Typography variant="h5">
                <FormattedDate value={date} />
                <hr />
              </Typography>
              <div
                style={{
                  alignItems: "flex-start",
                  display: "flex",
                  flexWrap: "wrap"
                }}
              >
                {groupedPhotos[date].map(photo => {
                  photoIndexCounter += 1;
                  return (
                    <PhotoCard
                      key={`${photo.id}-${photoIndexCounter}`}
                      photo={photo}
                      photoIndex={photoIndexCounter}
                      scrollPosition={scrollPosition}
                      onFileSelected={this.props.onFileSelected}
                      onPhotoClick={this.handlePhotoClick}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
        {this.state.photoIndex !== null &&
          ReactDOM.createPortal(
            <Lightbox
              mainSrc={fileInstanceToURL(
                this.state.photos[this.state.photoIndex],
                800
              )}
              nextSrc={fileInstanceToURL(
                this.state.photos[
                  (this.state.photoIndex + 1) % this.state.photos.length
                ],
                800
              )}
              prevSrc={fileInstanceToURL(
                this.state.photos[
                  (this.state.photoIndex + this.state.photos.length - 1) %
                    this.state.photos.length
                ],
                800
              )}
              reactModalStyle={{ overlay: { zIndex: "1300" } }}
              toolbarButtons={[
                <Button
                  key={1}
                  style={{ color: "#FFF", cursor: "pointer" }}
                  onClick={this.handleDownloadClick}
                >
                  <CloudDownloadIcon />
                </Button>
              ]}
              onCloseRequest={() => this.setState({ photoIndex: null })}
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex:
                    (this.state.photoIndex + 1) % this.state.photos.length
                })
              }
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex:
                    (this.state.photoIndex + this.state.photos.length - 1) %
                    this.state.photos.length
                })
              }
            />,
            this.el
          )}
        <Upload
          accept="Image/*"
          onError={this.handleUploadError}
          onFileUploaded={this.handleFileUploaded}
          onUploadStart={this.handleUploadStart}
        />
        <Upload
          capture
          accept="Image/*"
          row={2}
          onError={this.handleUploadError}
          onFileUploaded={this.handleFileUploaded}
          onUploadStart={this.handleUploadStart}
        />
      </div>
    );
  }
}

const EnhancedCasePhotoList = withAppContext(
  withRouter(injectIntl(withApollo(trackWindowScroll(CasePhotoList))))
);
export { EnhancedCasePhotoList as CasePhotoList };
