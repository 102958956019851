import { AppContext } from "../../components/AppContext";
import { useContext } from "react";

export const useCurrentUser = ():
  | {
      employee_number: number;
      first_name: string;
      id: string;
      last_name: string;
      mail: string;
      mobile?: string;
      phone?: string;
      role: string;
    }
  | undefined => {
  const { state } = useContext(AppContext);
  return state.currentUser;
};
