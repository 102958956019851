import { CONTACTS_AGGREGATED_QUERY } from "../../graphql/contacts";
import { CONTACT_TYPE_OPTIONS } from "./contact-form";
import { MUTable } from "../../components/mu-table";
import { Page } from "../../components/layout/page";
import { createWhereObject } from "../../utils/form-helpers";
import { defineMessages, injectIntl } from "react-intl";
import { getQueryString } from "../../utils";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Header from "../../components/layout/header";
import React, { Component } from "react";

const messages = defineMessages({
  add: {
    defaultMessage: "Tilføj",
    id: "contact-list.add"
  },
  address: {
    defaultMessage: "Adresse",
    id: "contact-list.address"
  },
  company: {
    defaultMessage: "Firma",
    id: "contact-list.company"
  },
  contacts: {
    defaultMessage: "Kontakter",
    id: "contact-list.contacts"
  },
  mail: {
    defaultMessage: "Mail",
    id: "contact-list.mail"
  },

  mobile: {
    defaultMessage: "Mobil",
    id: "contact-list.mobile"
  },

  name: {
    defaultMessage: "Navn",
    id: "contact-list.name"
  },
  phone: {
    defaultMessage: "Telefon",
    id: "contact-list.phone"
  },
  type: {
    defaultMessage: "Type",
    id: "contact-list.type"
  }
});

class ContactList extends Component {
  state = {
    search: getQueryString("q") || ""
  };
  handleSearchChange = value => {
    this.setState({ search: value });
  };
  handleRowClick = contact => {
    this.props.history.push(`/contacts/${contact.id}`);
  };
  handleFabClick = () => {
    this.props.history.push("/contacts/create");
  };

  render() {
    const { formatMessage } = this.props.intl;

    const addContactFab = (
      <Fab
        aria-label={formatMessage(messages.add)}
        color="primary"
        style={{ bottom: 5 * 2, position: "absolute", right: 5 * 2 }}
        onClick={this.handleFabClick}
      >
        <AddIcon />
      </Fab>
    );

    const where = createWhereObject(
      [
        "first_name",
        "last_name",
        "company",
        "address",
        "phone",
        "mobile",
        "mail",
        "city"
      ],
      this.state.search
    );

    //const searchString=`%${q}%`;

    return (
      <Page
        appBar={
          <Header
            title={formatMessage(messages.contacts)}
            onSearchChange={this.handleSearchChange}
          />
        }
        fab={addContactFab}
      >
        <MUTable
          columns={[
            {
              field: "name",
              render: contact => `${contact.first_name} ${contact.last_name}`,
              title: formatMessage(messages.name)
            },
            {
              field: "company",
              title: formatMessage(messages.company)
            },
            {
              field: "address",
              render: contact =>
                `${contact.address || ""}${
                  contact.address ? "," : ""
                } ${contact.zip_code || ""} ${contact.city || ""}`,
              title: formatMessage(messages.address)
            },
            {
              field: "phone",
              title: formatMessage(messages.phone)
            },
            {
              field: "mobile",
              title: formatMessage(messages.mobile)
            },
            {
              field: "mail",
              title: formatMessage(messages.mail)
            },
            {
              field: "type",
              render: contact =>
                contact.type
                  ? CONTACT_TYPE_OPTIONS.find(t => t.value === contact.type)
                      .label
                  : "",
              title: formatMessage(messages.type)
            }
          ]}
          dataSourceName="contacts_aggregate"
          gql={CONTACTS_AGGREGATED_QUERY}
          limit={100}
          orderBy="first_name"
          where={where}
          onRowClick={this.handleRowClick}
        />
      </Page>
    );
  }
}

export default injectIntl(ContactList);
