import gql from "graphql-tag";

export const ORDER_CONFIRMATIONS = gql`
  query list_case_doc_order_confirmations(
    $orderBy: case_doc_order_confirmations_order_by!
    $where: case_doc_order_confirmations_bool_exp
    $limit: Int
  ) {
    case_doc_order_confirmations(
      order_by: [$orderBy]
      where: $where
      limit: $limit
    ) {
      changed
      created
      description
      id
      other_terms
      title
      type
    }
  }
`;

export const GET_ORDER_CONFIRMATION = gql`
  query get_order_confirmation($id: uuid!) {
    case_doc_order_confirmations_by_pk(id: $id) {
      changed
      created
      description
      document_date
      revision_date
      type
      drawings_date
      id
      other_terms
      share_key
      title
      work_ends_date
      work_starts_date
      case {
        address
        builder_id
        builder {
          address
          city
          company
          first_name
          id
          last_name
          mail
          mobile
          phone
          zip_code
        }
        city
        date_of_inspection
        other_address
        responsible_id
        responsible {
          acronym
          first_name
          last_name
          mail
          mobile
          phone
        }
        zip_code
      }
      tasks(order_by: {order: asc}) {
        description
        id
        order
        price
        title
      }
    }
  }
`;

export const CREATE_ORDER_CONFIRMATIONS = gql`
  mutation insert_case_doc_order_confirmations(
    $orderConfirmation: case_doc_order_confirmations_insert_input!
  ) {
    insert_case_doc_order_confirmations(objects: [$orderConfirmation]) {
      returning {
        changed
        created
        description
        document_date
        revision_date
        drawings_date
        id
        type
        other_terms
        share_key
        title
        work_ends_date
        work_starts_date
        case {
          address
          builder {
            address
            city
            company
            first_name
            id
            last_name
            mail
            mobile
            phone
            zip_code
          }
          city
          date_of_inspection
          other_address
          responsible {
            acronym
            first_name
            last_name
            mail
            mobile
            phone
          }
          zip_code
        }
        tasks(order_by: {order: asc}) {
          description
          id
          order
          price
          title
        }
      }
    }
  }
`;

export const UPDATE_ORDER_CONFIRMATIONS = gql`
  mutation update_case_doc_order_confirmations(
    $id: uuid
    $orderConfirmation: case_doc_order_confirmations_set_input!
  ) {
    update_case_doc_order_confirmations(
      where: {id: {_eq: $id}}
      _set: $orderConfirmation
    ) {
      returning {
        changed
        created
        description
        type
        document_date
        revision_date
        drawings_date
        id
        other_terms
        share_key
        title
        work_ends_date
        work_starts_date
        case {
          address
          builder {
            address
            city
            company
            first_name
            id
            last_name
            mail
            mobile
            phone
            zip_code
          }
          city
          date_of_inspection
          other_address
          responsible {
            acronym
            first_name
            last_name
            mail
            mobile
            phone
          }
          zip_code
        }
        tasks(order_by: {order: asc}) {
          description
          id
          order
          price
          title
        }
      }
    }
  }
`;

export const CREATE_ORDER_CONFIRMATIONS_TASK = gql`
  mutation insert_case_doc_order_confirmations_task(
    $orderConfirmationTasks: [order_confirmation_tasks_insert_input!]!
  ) {
    insert_order_confirmation_tasks(objects: $orderConfirmationTasks) {
      returning {
        description
        id
        order
        price
        title
      }
    }
  }
`;

export const UPDATE_ORDER_CONFIRMATIONS_TASK = gql`
  mutation update_case_doc_order_confirmations_task(
    $id: uuid
    $orderConfirmationTask: order_confirmation_tasks_set_input!
  ) {
    update_order_confirmation_tasks(
      where: {id: {_eq: $id}}
      _set: $orderConfirmationTask
    ) {
      returning {
        description
        id
        order
        price
        title
      }
    }
  }
`;

export const DELETE_ORDER_CONFIRMATIONS_TASK = gql`
  mutation delete_order_confirmation_tasks(
    $where: order_confirmation_tasks_bool_exp!
  ) {
    delete_order_confirmation_tasks(where: $where) {
      affected_rows
    }
  }
`;
