import gql from "graphql-tag";

export const GET_CONSTRUCTION_PLANS = gql`
  query getConstructionPlans(
    $orderBy: construction_plans_order_by!
    $where: construction_plans_bool_exp
    $limit: Int
  ) {
    construction_plans(order_by: [$orderBy], where: $where, limit: $limit) {
      id
      name
    }
  }
`;
