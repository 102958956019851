import { AutocompleteSelect } from "./autocomplete-select";
import { CASES } from "../graphql/cases";
import { createWhereObject } from "../utils/form-helpers";
import { getCaseAddressFormatted } from "../pages/case/case-list";
import { withApollo } from "@apollo/client/react/hoc";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export const formatCaseLabel = caseInstance => {
  const address = getCaseAddressFormatted(caseInstance);
  const builder = caseInstance.builder
    ? `${caseInstance.builder.first_name} ${caseInstance.builder.last_name}`
    : undefined;
  return [address, builder, caseInstance.task]
    .filter(string => !!string)
    .join(" - ");
};

class CaseSelect extends PureComponent {
  static propTypes = {
    autoFocus: PropTypes.bool,
    client: PropTypes.object.isRequired,
    noInitial: PropTypes.bool
  };
  state = {
    defaultOptions: []
  };
  async componentDidMount() {
    if (!this.props.noInitial) {
      const where = { state: { _eq: true } };
      const response = await this.caseQuery(where);
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        defaultOptions: response.data.cases_aggregate.nodes.map(
          caseInstance => ({
            ...caseInstance,
            label: formatCaseLabel(caseInstance),
            value: caseInstance.id
          })
        )
      });
    }
  }
  caseQuery = where => {
    return this.props.client.query({
      fetchPolicy: "network-only",
      query: CASES,
      variables: {
        limit: 10,
        orderBy: { address: "asc" },
        where
      }
    });
  };
  handleLoadCases = async (input, callback) => {
    const extraWhere = { closed: { _is_null: true } };
    const where = input
      ? createWhereObject(
          [
            "address",
            "zip_code",
            "city",
            "task",
            "builder.address",
            "builder.zip_code",
            "builder.city",
            "builder.first_name",
            "builder.last_name",
            "builder.phone",
            "builder.mail",
            "builder.mobile"
          ],
          input,
          extraWhere
        )
      : createWhereObject([], "", extraWhere);
    const response = await this.caseQuery(where);
    callback(
      response.data.cases_aggregate.nodes.map(caseInstance => ({
        ...caseInstance,
        label: formatCaseLabel(caseInstance),
        value: caseInstance.id
      }))
    );
  };
  render() {
    return (
      <AutocompleteSelect
        autoFocus={this.props.autoFocus}
        defaultOptions={
          this.props.noInitial ? undefined : this.state.defaultOptions
        }
        onLoadOptions={this.handleLoadCases}
        {...this.props}
      />
    );
  }
}

const withHOC = withApollo(CaseSelect);

export { withHOC as CaseSelect };
