import {} from "react-intl";
import PropTypes from "prop-types";

export const formikPropTypes = {
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired
};

export const filterToWhereClause = filter => {
  if (!filter) {
    return null;
  }

  const where = [];
  Object.entries(filter).forEach(([key, value]) => {
    if (value && value.length) {
      if (Array.isArray(value)) {
        if (value[1] && Array.isArray(value[1])) {
          where.push({ [key]: { [value[0]]: { _in: value[1] } } });
        } else if (value[1]) {
          where.push({ [key]: { [value[0]]: { _eq: value[1] } } });
        }
      } else {
        where.push({ [key]: { _eq: value } });
      }
    }
  });
  return where;
};

const getFieldsObject = (fieldString, search) => {
  const parts = fieldString.split(".").reverse();
  let fieldObject = { _ilike: `%${search}%` };
  parts.forEach(part => {
    fieldObject = { [part]: fieldObject };
  });
  return fieldObject;
};

const rewriteString = search => {
  const isDateWithoutYear = search.match(
    /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012]).*$/
  );
  if (isDateWithoutYear) {
    const year = search.match(/^.*[/-](\d{4})$/);
    if (year) {
      return `${year[1]}-${isDateWithoutYear[2]}-${isDateWithoutYear[1]}`;
    }
    return `${isDateWithoutYear[2]}-${isDateWithoutYear[1]}`;
  }
  return search;
};

export const createWhereObject = (
  fields,
  searchString,
  extraWhere,
  split = true
) => {
  let where = [];
  if (searchString) {
    if (split) {
      const parts = searchString.trim().split(" ");
      parts.forEach(part => {
        const iLikeParts = [];
        fields.forEach(field => {
          const finalPart = rewriteString(part);
          if (field.indexOf(".") > -1) {
            iLikeParts.push(getFieldsObject(field, finalPart));
          } else {
            iLikeParts.push({ [field]: { _ilike: `%${finalPart}%` } });
          }
        });
        where.push({ _or: iLikeParts });
      });
    } else {
      const iLikeParts = [];
      fields.forEach(field => {
        const finalPart = rewriteString(searchString);
        if (field.indexOf(".") > -1) {
          iLikeParts.push(getFieldsObject(field, finalPart));
        } else {
          iLikeParts.push({ [field]: { _ilike: `%${finalPart}%` } });
        }
      });
      where.push({ _or: iLikeParts });
    }
  }
  if (extraWhere) {
    if (Array.isArray(extraWhere)) {
      where = [...where, ...extraWhere];
    } else {
      where.push(extraWhere);
    }
  }
  return where.length ? { _and: where } : undefined;
};

export const emailLink = (recipients, subject, body, cc) => {
  const recipientString = Array.isArray(recipients)
    ? recipients.join(",")
    : recipients;

  const encodedSubject = encodeURIComponent(subject);
  const encodedBody = encodeURIComponent(body);
  let link = `mailto:${recipientString}?subject=${encodedSubject}&body=${encodedBody}`;
  if (cc && cc.length) {
    const ccString = Array.isArray(cc) ? cc.join(",") : cc;
    const encodedCc = encodeURIComponent(ccString);
    link = `${link}&cc=${encodedCc}`;
  }
  return link;
};

export const generateSelectValue = (value, labelFormatter) => {
  return value
    ? {
        ...value,
        label: labelFormatter(value),
        value: value.id
      }
    : null;
};

export const startOf = (m, n, unit) => {
  const units = ["year", "month", "hour", "minute", "second", "millisecond"];
  const pos = units.indexOf(unit);
  if (pos === -1) {
    throw new Error("Unsupported unit");
  }
  for (let i = pos + 1; i < units.length; i++) {
    m.set(units[i], 0);
  }
  m.set(unit, Math.floor(m.get(unit) / n) * n);

  return m;
};

export const endOf = (m, n, unit) => {
  const units = ["year", "month", "hour", "minute", "second", "millisecond"];
  const pos = units.indexOf(unit);
  if (pos === -1) {
    throw new Error("Unsupported unit");
  }
  for (let i = pos + 1; i < units.length; i++) {
    m.set(units[i], units[i] === "millisecond" ? 999 : 59);
  }
  m.set(unit, Math.floor(m.get(unit) / n) * n + n - 1);

  return m;
};
