import * as yup from "yup";
import { Button, DialogContent, Typography } from "@material-ui/core";
import {
  CONTACTS_AGGREGATED_QUERY,
  CREATE_CONTACT,
  GET_CONTACT_WITH_NAME_MOBILE
} from "../graphql/contacts";
import { DialogBase } from "./dialog";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { flowRight as compose } from "lodash";
import { graphql, withApollo } from "@apollo/client/react/hoc";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import ContactForm from "../pages/contact/contact-form";
import React, { PureComponent } from "react";

const messages = defineMessages({
  add: {
    defaultMessage: "Tilføj",
    id: "contact-entry.add"
  },
  contacts: {
    defaultMessage: "Kontakter",
    id: "contact-entry.contacts"
  },
  createNew: {
    defaultMessage: "Opret ny kotakt",
    id: "contact-entry.create-new-contact"
  },
  update: {
    defaultMessage: "Redigér Kontakt",
    id: "contact-entry.update-contact"
  }
});

const schema = yup.object().shape({
  address: yup.string().required(),
  city: yup.string().required(),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  mail: yup.string().required(),
  mobile: yup.string().required(),
  type: yup.string({ min: 1 }).required(),
  zip_code: yup.string().required()
});

class ContactCreateDialog extends PureComponent {
  state = {
    contactExists: false
  };
  handleSubmitForm = async () => {
    const {
      client,
      contactId,
      submitForm,
      values: { first_name, last_name, mobile }
    } = this.props;
    if (!!contactId || !(first_name && last_name && mobile)) {
      submitForm();
      return;
    }
    const existing = await client.query({
      fetchPolicy: "network-only",
      query: GET_CONTACT_WITH_NAME_MOBILE,
      variables: {
        first_name,
        last_name,
        mobile
      }
    });
    if (!existing.data.contacts.length) {
      submitForm();
      this.setState({ contactExists: null });
    } else {
      this.setState({ contactExists: existing.data.contacts[0] });
    }
  };
  handleUseExistingContactClick = () => {
    this.props.onContactCreated(this.state.contactExists);
  };

  render() {
    const {
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      intl: { formatMessage },
      isSubmitting,
      onCancel,
      open,
      touched,
      values
    } = this.props;
    return (
      <DialogBase
        open={open}
        title={formatMessage(messages.createNew)}
        onCancel={onCancel}
        onOk={this.handleSubmitForm}
      >
        <DialogContent>
          {this.state.contactExists ? (
            <>
              <Typography color="error" variant="subtitle1">
                <FormattedMessage
                  defaultMessage="Kontakten eksisterer i forvejen"
                  id="contact-entry.contact-exists"
                />
              </Typography>
              <Button
                color="primary"
                onClick={this.handleUseExistingContactClick}
              >
                <FormattedMessage
                  defaultMessage="Brug den eksisterende kontakt"
                  id="contact-entry.use-existing"
                />
              </Button>
            </>
          ) : null}
          <ContactForm
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            touched={touched}
            values={values}
          />
        </DialogContent>
      </DialogBase>
    );
  }
}

const ContactCreateDialogWithApollo = withApollo(ContactCreateDialog);

const handleSubmit = (payload, { props, setErrors, setSubmitting }) => {
  const {
    address,
    city,
    company,
    first_name,
    id,
    last_name,
    mail,
    mobile,
    phone,
    type,
    zip_code
  } = payload;
  const variables = {
    contact: {
      address,
      city,
      company,
      first_name,
      id,
      last_name,
      mail,
      mobile,
      phone,
      type,
      zip_code
    },
    id: undefined
  };

  props
    .insert_contact({
      variables
    })
    .then(
      response => {
        props.onContactCreated(response.data.insert_contacts.returning[0]);
        return;
      },
      e => {
        // eslint-disable-next-line no-console
        console.log(e);
        const errors = e.graphQLErrors.map(error => error.message);
        // eslint-disable-next-line no-console
        console.log(errors);
        setSubmitting(false);
        setErrors({ form: errors });
      }
    );
};

const withGraphQL = compose(
  graphql(CREATE_CONTACT, {
    name: "insert_contact",
    options: {
      refetchQueries: [
        {
          query: CONTACTS_AGGREGATED_QUERY,
          variables: { orderBy: { first_name: "asc" } }
        }
      ]
    }
  }),
  withFormik({
    displayName: "ContactCreateDialog",
    handleSubmit,
    mapPropsToValues: ({ defaultType }) => {
      let type = "";
      if (defaultType === "company") {
        type = "company";
      }
      return {
        type
      };
    },
    validationSchema: schema
  })
)(ContactCreateDialogWithApollo);

const ContactCreateDialogWithIntl = withRouter(injectIntl(withGraphQL));

const ContactCreateDialWithRouter = withRouter(ContactCreateDialogWithIntl);
export { ContactCreateDialWithRouter as ContactCreateDialog };
