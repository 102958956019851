import { FormattedMessage } from "react-intl";
import { Gantt } from "../../components/gantt";
import { Paper } from "@material-ui/core";
import { User, UserSelect } from "../../components/user-select";
import { getCaseAddressFormatted } from "../case/case-list";
import { useCasesWithConstructionPlansLazyQuery } from "../../generated/graphql";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import gql from "graphql-tag";

export const QUERY = gql`
  query casesWithConstructionPlans($where: cases_bool_exp) {
    cases(where: $where, order_by: { date_of_inspection: asc }) {
      id
      task
      description
      builder {
        id
        address
        zip_code
        city
      }
      address
      zip_code
      city
      date_of_inspection
      other_address
      construction_plan {
        entries {
          days_after
          name
          id
        }
      }
    }
  }
`;

export function GanttPage(): JSX.Element {
  const [responsible, setResponsible] = useState<User | null>(null);
  const onUserSelected = useCallback((option): void => {
    setResponsible(option);
  }, []);

  const [getData, { data }] = useCasesWithConstructionPlansLazyQuery();

  useEffect(() => {
    if (!responsible?.id) {
      return;
    }

    const where = {
      closed: { _is_null: true },
      construction_plan_id: { _is_null: false },
      date_of_inspection: { _is_null: false },
      responsible_id: { _eq: responsible?.id }
    };

    getData({
      variables: {
        where
      }
    });
  }, [getData, responsible?.id]);

  const ganttData = useMemo(() => {
    if (!data?.cases) {
      return [];
    }
    let casesWithPlans = [];

    const now = new Date();
    // eslint-disable-next-line no-unused-expressions
    data?.cases.forEach(instance => {
      const inspectionDate = new Date(instance.date_of_inspection);

      const planEntries = [];
      // eslint-disable-next-line no-unused-expressions
      instance.construction_plan?.entries.forEach(entry => {
        const entryDate = new Date(inspectionDate);
        entryDate.setDate(entryDate.getDate() + entry.days_after);
        if (now > entryDate) {
          return;
        }
        const entryEndDate = new Date(entryDate);
        entryEndDate.setDate(entryEndDate.getDate() + 1);

        planEntries.push({
          end_date: entryEndDate,
          id: `${instance.id}-${entry.id}`,
          parent: instance.id,
          progress: 1,
          single_date: true,
          start_date: entryDate,
          text: entry.name
        });
      });
      if (planEntries.length) {
        casesWithPlans = [
          ...casesWithPlans,
          {
            id: instance.id,
            open: true,
            progress: 1,
            text: getCaseAddressFormatted(instance)
          },
          ...planEntries
        ];
      }
    });
    return casesWithPlans;
  }, [data?.cases, data?.cases?.forEach]);
  console.log(ganttData);
  return (
    <Paper>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 112px)"
        }}
      >
        <UserSelect
          field={{ name: "responsible", value: responsible }}
          form={{ errors: {} }}
          placeholder={
            <FormattedMessage
              defaultMessage="Sagsansvarlig"
              id="building-inspectio.responsible"
            />
          }
          onSelect={onUserSelected}
        />

        <Gantt tasks={{ data: ganttData }} />
      </div>
    </Paper>
  );
}
