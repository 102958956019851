import { AbsenceTypeList } from "./absence-types-list";
import { AppBar, Link, Tab, Tabs } from "@material-ui/core";
import { ConstructionPlanList } from "./construction-plans";
import { Page } from "../../components/layout/page";
import { Route, Switch } from "react-router";
import { StandardPhotoList } from "./photos/list";
import { defineMessages, injectIntl } from "react-intl";
import CaseTypesList from "./case-types-list";
import Header from "../../components/layout/header";
import OrderConfirmationTemplatesList from "./order-confirmation-templates-list";
import PropTypes from "prop-types";
import QualityControlTemplatesList from "./quality-control-templates-list";
import React, { PureComponent } from "react";

const messages = defineMessages({
  absenceTypes: {
    defaultMessage: "Fraværstyper",
    id: "settings.tab.absence-types"
  },
  caseTypes: {
    defaultMessage: "Sagstyper",
    id: "settings.tab.case-types"
  },
  constrictionPlans: {
    defaultMessage: "Byggeplaner",
    id: "constriction-plans"
  },
  orderConfirmationTemplates: {
    defaultMessage: "Ordrebekræftelsesskabeloner",
    id: "settings.tab.order-confirmations-templates"
  },
  qualityControlTemplates: {
    defaultMessage: "Tilsynsnotatskabeloner",
    id: "settings.tab.quality-control"
  },
  settings: {
    defaultMessage: "Indstillinger",
    id: "settings"
  },
  standardPhotos: {
    defaultMessage: "Standardfotos",
    id: "settings.tab.standard-photos"
  }
});

class Settings extends PureComponent {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  render() {
    const {
      intl: { formatMessage },
      match: {
        params: { tab }
      }
    } = this.props;
    const currentTab = tab || "case-types";

    return (
      <div style={{ backgroundColor: "#FFF", flexGrow: 1 }}>
        <Page appBar={<Header title={formatMessage(messages.settings)} />}>
          <AppBar position="static">
            <Tabs scrollButtons="auto" value={currentTab} variant="scrollable">
              <Tab
                component={Link}
                href="#/settings/case-types"
                label={formatMessage(messages.caseTypes)}
                value="case-types"
              />
              <Tab
                component={Link}
                href="#/settings/order-confirmations"
                label={formatMessage(messages.orderConfirmationTemplates)}
                value="order-confirmations"
              />
              <Tab
                component={Link}
                href="#/settings/quality-control"
                label={formatMessage(messages.qualityControlTemplates)}
                value="quality-control"
              />
              <Tab
                component={Link}
                href="#/settings/photos"
                label={formatMessage(messages.standardPhotos)}
                value="photos"
              />
              <Tab
                component={Link}
                href="#/settings/absence-types"
                label={formatMessage(messages.absenceTypes)}
                value="absence-types"
              />
              <Tab
                component={Link}
                href="#/settings/construction-plans"
                label={formatMessage(messages.constrictionPlans)}
                value="construction-plans"
              />
            </Tabs>
          </AppBar>
          <Switch>
            <Route exact component={CaseTypesList} path="/settings" />
            <Route
              exact
              component={CaseTypesList}
              path="/settings/case-types"
            />
            <Route
              exact
              component={OrderConfirmationTemplatesList}
              path="/settings/order-confirmations"
            />
            <Route
              exact
              component={QualityControlTemplatesList}
              path="/settings/quality-control"
            />
            <Route
              exact
              component={StandardPhotoList}
              path="/settings/photos"
            />
            <Route
              exact
              component={AbsenceTypeList}
              path="/settings/absence-types"
            />
            <Route
              exact
              component={ConstructionPlanList}
              path="/settings/construction-plans"
            />
          </Switch>
        </Page>
      </div>
    );
  }
}

export default injectIntl(Settings);
