import { AbsenceList } from "./absence-list";
import { Absence as AbsenceReport } from "./report";
import { AppBar, Link, Tab, Tabs } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Page } from "../../components/layout/page";
import { Route, Switch, useParams } from "react-router";
import Header from "../../components/layout/header";
import React, { useCallback, useMemo, useState } from "react";
import _ from "lodash";

export const Absence = React.memo(function Absence(): JSX.Element {
  const test = useParams<{ tab: "list" }>();
  const { tab } = test;
  const currentTab = tab || "list";
  console.log(test);
  const [search, setSearch] = useState("");

  const debouncedSearchChange = useMemo(() => _.debounce(setSearch, 300), []);

  const handleSearchChange = useCallback(
    value => {
      debouncedSearchChange(value);
    },
    [debouncedSearchChange]
  );

  return (
    <div style={{ flexGrow: 1 }}>
      <Page
        appBar={
          <Header
            title={
              <FormattedMessage defaultMessage="Fravær" id="statistics.title" />
            }
            onSearchChange={handleSearchChange}
          />
        }
      >
        <AppBar position="static">
          <Tabs scrollButtons="auto" value={currentTab} variant="scrollable">
            <Tab
              component={Link}
              href="#/absence"
              label={
                <FormattedMessage defaultMessage="Liste" id="statistics.list" />
              }
              value="list"
            />
            <Tab
              component={Link}
              href="#/absence/report"
              label={
                <FormattedMessage
                  defaultMessage="Rapport"
                  id="statistics.report"
                />
              }
              value="report"
            />
          </Tabs>
        </AppBar>
        <Switch>
          <Route
            exact
            path="/absence"
            render={props => <AbsenceList search={search} {...props} />}
          />
          <Route exact component={AbsenceReport} path="/absence/report" />
        </Switch>
      </Page>
    </div>
  );
});
