/* eslint-disable react/no-did-mount-set-state */
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid
} from "@material-ui/core";
import { CASES_CORDS } from "../graphql/cases";
import { FormattedMessage, injectIntl } from "react-intl";
import { GET_CASE_TYPE_LIST } from "../graphql/case-types";
import { Page } from "../components/layout/page";
import { Pin } from "../components/pin";
import { createWhereObject } from "../utils/form-helpers";
import { getQueryString } from "../utils";
import { withApollo } from "@apollo/client/react/hoc";
import { withAppContext } from "../utils/with-app-context";
import Header from "../components/layout/header";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import SimpleMap from "../components/SimpleMap";
import _ from "lodash";

class Overview extends PureComponent {
  static propTypes = {
    intl: PropTypes.object.isRequired
  };

  state = {
    cases: [],
    caseTypes: [],
    checked: {},
    onlyMine: false,
    search: getQueryString("q") || ""
  };

  async componentDidMount() {
    const response = await this.props.client.query({
      fetchPolicy: "network-only",
      query: GET_CASE_TYPE_LIST,
      variables: {
        limit: 1000,
        orderBy: { name: "asc" }
      }
    });
    const checked = {};
    response.data.case_types_aggregate.nodes.forEach(element => {
      checked[element.id] = true;
    });
    this.setState({
      caseTypes: response.data.case_types_aggregate.nodes,
      checked
    });
    this.fetchCases(false, this.state.search);
  }

  fetchCases = async (onlyMine, search) => {
    const finalFilter = { state: { _eq: true } };
    if (onlyMine) {
      finalFilter.responsible_id = {
        _eq: this.props.context.state.currentUser.id
      };
    }
    const where = createWhereObject(
      ["address", "zip_code", "city"],
      search,
      finalFilter
    );

    const response = await this.props.client.query({
      fetchPolicy: "network-only",
      query: CASES_CORDS,
      variables: {
        where
      }
    });

    this.setState({
      cases: response.data.cases
    });
  };

  handleToggleAll = () => {
    const checked = { ...this.state.checked };
    let newChecked = {};
    if (_.every(this.state.checked, el => el)) {
      newChecked = _.mapValues(checked, () => false);
    } else {
      newChecked = _.mapValues(checked, () => true);
    }
    this.setState({ checked: newChecked });
  };

  handleChecked = (id, value) => {
    const newChecked = { ...this.state.checked };
    newChecked[id] = value;
    this.setState({ checked: newChecked });
  };

  hanleOnlyMineChanged = () => {
    const onlyMine = !this.state.onlyMine;
    this.setState({ onlyMine });
    this.fetchCases(onlyMine, this.state.search);
  };

  handlePinClick = id => {
    window.location = `#/cases/${id}`;
  };
  handleSearchChange = value => {
    this.setState({ search: value });

    this.fetchCases(this.state.onlyMine, value);
  };
  render() {
    const caseTypeBlock = [];
    let checkboxes = [];
    this.state.caseTypes.forEach(caseType => {
      checkboxes.push(
        <div key={caseType.id}>
          <span style={{ display: "inline-flex" }}>
            <Pin color={caseType.color} icon={caseType.icon} />
          </span>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.checked[caseType.id]}
                onChange={(_event, value) => {
                  this.handleChecked(caseType.id, value);
                }}
              />
            }
            label={caseType.name}
          />
        </div>
      );
      if (checkboxes.length === 5) {
        caseTypeBlock.push(
          <Grid item lg={4}>
            {checkboxes}
          </Grid>
        );
        checkboxes = [];
      }
    });
    if (checkboxes.length) {
      caseTypeBlock.push(
        <Grid item lg={4}>
          {checkboxes}
        </Grid>
      );
    }

    return (
      <div style={{ backgroundColor: "#FFF", flexGrow: 1 }}>
        <Page
          appBar={
            <Header
              title={
                <FormattedMessage defaultMessage="Sager" id="overview.title" />
              }
              onSearchChange={this.handleSearchChange}
            />
          }
        >
          <div style={{ height: 600, width: "100%" }}>
            <SimpleMap
              center={[56.0854226, 10.5296348]}
              zoom={7}
              onClick={this.handleMapClick}
            >
              {this.state.cases
                .filter(instance => this.state.checked[instance.case_type_id])
                .map(instance => {
                  const caseType = this.state.caseTypes.find(
                    el => el.id === instance.case_type_id
                  );
                  return (
                    <Pin
                      key={instance.id}
                      mapPosition
                      caseId={instance.id}
                      color={caseType ? caseType.color : "grey"}
                      icon={caseType ? caseType.icon : undefined}
                      lat={instance.lat}
                      lng={instance.lng}
                      onClick={this.handlePinClick}
                    />
                  );
                })}
            </SimpleMap>
          </div>
          <Grid container>
            <Grid item lg={3} xs={6}>
              <Button onClick={this.handleToggleAll}>
                <FormattedMessage
                  defaultMessage="Vælg / Fravælg all"
                  id="overview.select-all"
                />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.onlyMine}
                    onChange={this.hanleOnlyMineChanged}
                  />
                }
                label={
                  <FormattedMessage defaultMessage="Kun Mine" id="overview" />
                }
              />{" "}
            </Grid>
          </Grid>
          <FormGroup>
            <Grid container>{caseTypeBlock}</Grid>
          </FormGroup>
        </Page>
      </div>
    );
  }
}

const enhanced = withAppContext(withApollo(injectIntl(Overview)));

export { enhanced as Overview };
