import { DatePicker } from "@material-ui/pickers";
import { FormattedMessage } from "react-intl";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { isMobile } from "../../utils";
import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import gql from "graphql-tag";
import moment from "moment";

const GET_CASE_STATS = gql`
  query requestsDonePerEmployeePerMonth(
    $startMonth: String
    $endMonth: String
  ) {
    requests_done_per_employee_per_month(
      order_by: { year_month: desc }
      where: { year_month: { _gte: $startMonth, _lte: $endMonth } }
    ) {
      count
      employee {
        acronym
      }
      responsible_id
      year_month
    }
  }
`;

export function RequestsDonePerEmployeePerMonth({
  userSortOrder
}: {
  userSortOrder: Map<string, number>;
}): JSX.Element {
  const [getData, { data, loading }]: [
    any,
    {
      data: {
        requests_done_per_employee_per_month:
          | {
              count: number;
              employee: { acronym: string } | null;
              responsible_id: string | null;
              year_month: string;
            }[]
          | null;
      };
      loading: boolean;
    }
  ] = useLazyQuery(GET_CASE_STATS);

  const [startDate, setStartDate] = useState<moment.Moment | null>(null);
  const [endDate, setEndDate] = useState<moment.Moment | null>(null);

  useEffect(() => {
    if (startDate && endDate) {
      const startYearMonth = startDate.format("YYYY-MM");
      const endYearMonth = endDate.format("YYYY-MM");

      getData({
        variables: {
          endMonth: endYearMonth,
          startMonth: startYearMonth
        }
      });
    } else if (!startDate) {
      const initialEnd = moment();
      const initialStart = moment().subtract(12, "months");
      setStartDate(initialStart);
      setEndDate(initialEnd);
    }
  }, [endDate, getData, startDate]);

  const startMonth = new Date();
  startMonth.setMonth(startMonth.getMonth() - 12);

  const requestsPerMonth = data?.requests_done_per_employee_per_month ?? [];
  const [requestMonths, requestEmployeeData, requestTotals] = useMemo((): [
    Set<string>,
    Map<string, Map<string, number>>,
    Map<string, number>
  ] => {
    const ordered = new Map<string, Map<string, number>>();
    const totals = new Map<string, number>();
    const fetchedMonths = new Set<string>();
    if (loading || !requestsPerMonth) {
      return [fetchedMonths, ordered, totals];
    }
    const sortedCasesPerMonth = _.sortBy(requestsPerMonth, "year_month");
    sortedCasesPerMonth.forEach(entry => {
      fetchedMonths.add(entry.year_month);
      totals.set(entry.year_month, 0);
    });

    sortedCasesPerMonth.forEach(entry => {
      const existing = ordered.get(entry.employee?.acronym);
      totals.set(entry.year_month, totals.get(entry.year_month) + entry.count);
      if (existing) {
        existing.set(
          entry.year_month,
          (existing.get(entry.year_month) || 0) + entry.count
        );
      } else {
        const newEmployee = new Map<string, number>();
        [...fetchedMonths].forEach(m => newEmployee.set(m, 0));
        newEmployee.set(entry.year_month, entry.count);
        ordered.set(entry.employee?.acronym, newEmployee);
      }
    });
    return [fetchedMonths, ordered, totals];
  }, [requestsPerMonth, loading]);

  return (
    <div
      style={{
        minHeight: "calc(100% - 64px)",
        padding: 20
      }}
    >
      <h3>Statistik over ekspederede sager</h3>
      <Paper>
        <div style={{ padding: 10 }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <DatePicker
                clearable
                fullWidth
                DialogProps={{ fullScreen: isMobile }}
                format="MMMM YYYY"
                label={
                  <FormattedMessage
                    defaultMessage="Start"
                    id="checklist-items.start"
                  />
                }
                value={startDate}
                views={["year", "month"]}
                onChange={setStartDate}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                clearable
                fullWidth
                DialogProps={{ fullScreen: isMobile }}
                format="MMMM YYYY"
                label={
                  <FormattedMessage
                    defaultMessage="Slut"
                    id="checklist-items.end"
                  />
                }
                value={endDate}
                views={["year", "month"]}
                onChange={setEndDate}
              />
            </Grid>
          </Grid>
        </div>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell />
              {loading ? (
                <TableCell>Loader</TableCell>
              ) : (
                <>
                  {[...requestMonths].map(month => {
                    return <TableCell key={month}>{month}</TableCell>;
                  })}
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell>Loader</TableCell>
              </TableRow>
            ) : (
              <>
                {_.orderBy([...requestEmployeeData], ([initials]) =>
                  userSortOrder.get(initials)
                ).map(([initials, monthData], index) => {
                  return (
                    <TableRow key={`${initials}${index}`}>
                      <TableCell>{userSortOrder.get(initials)}</TableCell>
                      <TableCell>{initials || "Ukendt"}</TableCell>
                      {[...monthData].map(([yearMonth, value]) => (
                        <TableCell key={yearMonth}>{value}</TableCell>
                      ))}
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell />
                  <TableCell style={{ fontWeight: "bold" }}>
                    <FormattedMessage
                      defaultMessage="Total"
                      id="cases-created.total"
                    />
                  </TableCell>
                  {[...requestTotals].map(([yearMonth, value]) => (
                    <TableCell key={yearMonth} style={{ fontWeight: "bold" }}>
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}
