/* eslint-disable react/jsx-no-bind */
import { FormattedMessage } from "react-intl";
import { Formik } from "formik";
import { Grid, TextField, Typography } from "@material-ui/core";
import { Page } from "../../../components/layout/page";
import { useApolloClient } from "@apollo/client";
import { useGetConstructionPlanEntryLazyQuery } from "../../../generated/graphql";
import { useHistory, useParams } from "react-router";
import AreYouSure from "../../../components/are-you-sure";
import Header from "../../../components/layout/header";
import React, { useCallback, useEffect } from "react";
import gql from "graphql-tag";

export const GET_CONSTRUCTION_PLAN_ENTRY = gql`
  query getConstructionPlanEntry($id: uuid) {
    construction_plan_entries(where: { id: { _eq: $id } }) {
      id
      name
      days_after
      construction_plan {
        name
      }
    }
  }
`;

export const CREATE_CONSTRUCTION_PLAN_ENTRY = gql`
  mutation insertConstructionPlanEntry(
    $constructionPlanEntry: construction_plan_entries_insert_input!
  ) {
    insert_construction_plan_entries(objects: [$constructionPlanEntry]) {
      returning {
        id
        name
        days_after
      }
    }
  }
`;

export const UPDATE_CONSTRUCTION_PLAN_ENTRY = gql`
  mutation updateConstructionPlanEntry(
    $id: uuid
    $constructionPlanEntry: construction_plan_entries_set_input!
  ) {
    update_construction_plan_entries(
      where: { id: { _eq: $id } }
      _set: $constructionPlanEntry
    ) {
      returning {
        id
        name
        days_after
      }
    }
  }
`;

export const ConstructionPlanEntryEntry = function ConstructionPlanEntryEntry(): JSX.Element {
  const { id, planId } = useParams<{ id?: string; planId: string }>();
  const history = useHistory();

  const client = useApolloClient();

  const [getData, { data }] = useGetConstructionPlanEntryLazyQuery();

  useEffect(() => {
    getData({
      variables: {
        id
      }
    });
  }, [getData, id]);

  const handleSubmit = useCallback(
    async (
      values: { days_after: number | null; name: string },
      { resetForm, setErrors, setSubmitting }
    ) => {
      const { days_after, name } = values;
      const variables = {
        constructionPlanEntry: {
          construction_plan_id: planId,
          days_after,
          name
        },
        id
      };
      try {
        let response: { data?: any } | null;

        if (id) {
          response = await client.mutate({
            mutation: UPDATE_CONSTRUCTION_PLAN_ENTRY,
            variables
          });
        } else {
          response = await client.mutate({
            mutation: CREATE_CONSTRUCTION_PLAN_ENTRY,
            variables
          });
        }

        if (id) {
          resetForm();
        } else {
          const createdConstructionPlanEntryId =
            response.data?.insert_construction_plan_entries.returning[0].id;
          history.replace(
            `/settings/construction-plans/${planId}/${createdConstructionPlanEntryId}`
          );
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        const errors = e.graphQLErrors?.map(error => error.message);
        // eslint-disable-next-line no-console
        console.log(errors);
        setSubmitting(false);
        setErrors({ form: errors });
      }
    },
    [client, history, id, planId]
  );
  if (id && !data?.construction_plan_entries) {
    return <div>loading</div>;
  }

  return (
    <Formik
      initialValues={
        data?.construction_plan_entries
          ? data.construction_plan_entries[0]
          : {
              days_after: null,
              name: ""
            }
      }
      onSubmit={handleSubmit}
    >
      {({
        dirty,
        errors,
        handleBlur,
        handleChange,
        handleReset,
        isSubmitting,
        submitForm,
        values
      }) => {
        const showNavigationWarning = (): boolean => {
          return !isSubmitting && dirty;
        };
        return (
          <Page
            appBar={
              <Header
                dirty={dirty}
                title={
                  <FormattedMessage
                    defaultMessage="Byggeplanspunkt"
                    id="construction-plan-entry.title"
                  />
                }
                onResetButton={handleReset}
                onSaveButton={submitForm}
              />
            }
          >
            <div
              style={{
                backgroundColor: "#FFF",
                minHeight: "calc(100% - 64px)",
                padding: 20
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    error={!!errors.name}
                    label={
                      <FormattedMessage
                        defaultMessage="Navn"
                        id="construction-plans.name"
                      />
                    }
                    name="name"
                    type="text"
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    error={!!errors.days_after}
                    label={
                      <FormattedMessage
                        defaultMessage="Antal dage efter første indspektion"
                        id="construction-plans.days-after-inspection"
                      />
                    }
                    name="days_after"
                    type="number"
                    value={values.days_after}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </div>

            <AreYouSure when={showNavigationWarning} />
          </Page>
        );
      }}
    </Formik>
  );
};
