import { GET_ABSENCE_TYPES } from "../../graphql/absence";
import { MUTable } from "../../components/mu-table";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router";
import MuFab from "../../components/fab";
import React, { useCallback } from "react";

const messages = defineMessages({
  active: {
    defaultMessage: "Aktiv",
    id: "case-types-list.active"
  },
  label: {
    defaultMessage: "Label",
    id: "case-types-list.label"
  },
  pin: {
    defaultMessage: "Kortnål",
    id: "case-types-list.pin"
  }
});

export const AbsenceTypeList = (): JSX.Element => {
  const history = useHistory();
  const handleRowClick = useCallback(
    caseType => {
      history.push(`/settings/absence-types/${caseType.id}`);
    },
    [history]
  );
  const handleFabClick = useCallback(() => {
    history.push("/settings/absence-types/create");
  }, [history]);
  const { formatMessage } = useIntl();

  const MUTableWithoutTypes: any = MUTable;
  return (
    <>
      <MUTableWithoutTypes
        columns={[
          {
            field: "label",
            title: formatMessage(messages.label)
          },
          {
            field: "active",
            render: absenceType => (absenceType.active ? "Ja" : null),
            title: formatMessage(messages.active)
          }
        ]}
        dataSourceName="absence_types"
        gql={GET_ABSENCE_TYPES}
        orderBy="label"
        path="/settings/absence-types"
        onRowClick={handleRowClick}
      />
      <MuFab onClick={handleFabClick} />
    </>
  );
};
