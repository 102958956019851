import gql from "graphql-tag";

export const GET_CASE_TYPE_LIST = gql`
  query case_types_agregate(
    $orderBy: case_types_order_by!
    $where: case_types_bool_exp
    $offset: Int
    $limit: Int
  ) {
    case_types_aggregate(
      offset: $offset
      order_by: [$orderBy]
      where: $where
      limit: $limit
    ) {
      aggregate {
        count
      }
      nodes {
        id
        name
        icon
        color
        price
      }
    }
  }
`;

export const GET_CASE_TYPE = gql`
  query get_case_types($caseTypeId: uuid) {
    case_types(where: { id: { _eq: $caseTypeId } }) {
      id
      name
      icon
      color
      price
    }
  }
`;

export const GET_CASE_TYPE_CHECK = gql`
  query case_types_check($name: String!, $icon: String!, $color: String!) {
    case_types(
      where: {
        _or: [
          { name: { _eq: $name } }
          { color: { _eq: $color }, _and: { icon: { _eq: $icon } } }
        ]
      }
    ) {
      id
    }
  }
`;

export const CREATE_CASE_TYPE = gql`
  mutation insert_case_type($caseType: case_types_insert_input!) {
    insert_case_types(objects: [$caseType]) {
      returning {
        id
        name
        icon
        color
        price
      }
    }
  }
`;

export const UPDATE_CASE_TYPE = gql`
  mutation update_case_type($id: uuid, $caseType: case_types_set_input!) {
    update_case_types(where: { id: { _eq: $id } }, _set: $caseType) {
      returning {
        id
        name
        icon
        color
        price
      }
    }
  }
`;
