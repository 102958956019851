import {DialogBase} from "./dialog";
import {DialogContent, TextField} from "@material-ui/core";
import {FormattedMessage, defineMessages, injectIntl} from "react-intl";
import PropTypes from "prop-types";
import React, {useCallback, useState} from "react";

const messages = defineMessages({
  password: {
    defaultMessage: "Nyt password",
    id: "change-password-dialog.password"
  },
  password2: {
    defaultMessage: "Nyt password, igen",
    id: "change-password-dialog.password"
  },
  title: {
    defaultMessage: "Ændre password",
    id: "change-password-dialog.title"
  }
});

export const ChangePasswordDialog = React.memo(
  injectIntl(({intl: {formatMessage}, onCancel, onOk, open}) => {
    const [values, setValues] = useState({password: "", password2: ""});
    const handleOk = useCallback(() => {
      onOk(values.password);
    },[onOk, values.password]);

    const handleChange = event => {
      event.persist();
      setValues({...values, [event.target.name]: event.target.value});
    };
    const okDisabled =
      !values.password ||
      !values.password2 ||
      values.password !== values.password2 ||
      values.password.length < 6 || 
      !(/\d/.test(values.password) && /[a-zA-Z]/.test(values.password));
    return (
      <DialogBase
        okDisabled={okDisabled}
        open={open}
        title={formatMessage(messages.title)}
        onCancel={onCancel}
        onOk={handleOk}
      >
        <DialogContent>
          Skal indeholde mindst et bogstav og et tal og skal minimum være 6 tegn langt.
          <TextField
            autoFocus
            fullWidth
            id="password"
            label={formatMessage(messages.password)}
            margin="dense"
            name="password"
            type="password"
            value={values.password || ""}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={handleChange}
          />
          <TextField
            fullWidth
            id="password2"
            label={formatMessage(messages.password2)}
            margin="dense"
            name="password2"
            type="password"
            value={values.password2 || ""}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={handleChange}
          />
          {values.password &&
          values.password2 &&
          values.password !== values.password2 ? (
            <div style={{color: "red"}}>
              <FormattedMessage
                defaultMessage="De indtastede passwords er ikke ens"
                id="change-password-dialog.password-mismatch"
              />
            </div>
          ) : null}
        </DialogContent>
      </DialogBase>
    );
  })
);

ChangePasswordDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
ChangePasswordDialog.displayName = "ChangePasswordDialog";
