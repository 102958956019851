import { Page } from "../../components/layout/page";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";

import { AppContext } from "../../components/AppContext";
import { Fab } from "@material-ui/core";
import {
  FormattedDate,
  FormattedTime,
  defineMessages,
  useIntl
} from "react-intl";
import {
  List_RequestsQuery,
  useUpdateRequestResponsibleMutation
} from "../../generated/graphql";
import { MUTable } from "../../components/mu-table";
import { REQUESTS } from "../../graphql/requests";
import { REQUESTS_STATE_OPTIONS } from "./request-form";
import {
  RequestFilterDialog,
  STANDARD_REQUEST_FILTER,
  getFilters,
  setStandardFilter
} from "./request-filter-dialog";
import { User, UserSelect } from "../../components/user-select";
import {
  createWhereObject,
  filterToWhereClause
} from "../../utils/form-helpers";
import { getQueryString } from "../../utils";
import { useHistory } from "react-router";
import AddIcon from "@material-ui/icons/Add";
import Header from "../../components/layout/header";
import _ from "lodash";
import gql from "graphql-tag";

const messages = defineMessages({
  address: {
    defaultMessage: "Adresse",
    id: "request-list.address"
  },
  builder: {
    defaultMessage: "Bygherre",
    id: "request-list.builder"
  },
  city: {
    defaultMessage: "By",
    id: "request-list.city"
  },
  date: {
    defaultMessage: "Oprettet",
    id: "request-list.date"
  },
  description: {
    defaultMessage: "Beskrivelse",
    id: "request-list.description"
  },
  first_name: {
    defaultMessage: "Fornavn",
    id: "request-list.first_name"
  },
  last_name: {
    defaultMessage: "Efternavn",
    id: "request-list.last_name"
  },
  mobile: {
    defaultMessage: "Mobil",
    id: "request-list.mobile"
  },
  notChosen: {
    defaultMessage: "Ikke valgt",
    id: "request-list.not-chosen"
  },
  phone: {
    defaultMessage: "Telefon",
    id: "request-list.phone"
  },
  requests: {
    defaultMessage: "Forespørgsler",
    id: "request-list.requests"
  },
  responsible: {
    defaultMessage: "Ansvarlig",
    id: "request-list.responsible"
  },
  state: {
    defaultMessage: "Status",
    id: "request-list.state"
  },
  task: {
    defaultMessage: "Opgave",
    id: "request-list.task"
  },
  zip_code: {
    defaultMessage: "Postnummer",
    id: "request-list.zip_code"
  }
});

const MUTableWithoutType: any = MUTable;
type Request = List_RequestsQuery["requests_aggregate"]["nodes"][number];

export const UPDATE_ABSENCE = gql`
  mutation updateRequestResponsible($id: uuid!, $responsibleId: uuid) {
    update_requests_by_pk(
      pk_columns: { id: $id }
      _set: { responsible_id: $responsibleId }
    ) {
      id
    }
  }
`;

export const formatUserLabel = (user: User): string =>
  user.acronym || user.mail || "";

const SelectRequestResponsible = React.memo(function SelectRequestResponsible({
  request
}: {
  request: Request;
}): JSX.Element {
  const [responsible, setResponsible] = useState(request.responsible);
  const [
    updateRequestResponsibleMutation
  ] = useUpdateRequestResponsibleMutation();
  const onUserSelected = useCallback(
    (option: User): void => {
      setResponsible(option);
      updateRequestResponsibleMutation({
        variables: { id: request.id, responsibleId: option.id }
      });
    },
    [request.id, updateRequestResponsibleMutation]
  );
  useEffect(() => {
    setResponsible(request.responsible);
  }, [request.responsible]);

  return (
    <UserSelect
      field={{
        name: "responsible",
        value: responsible
          ? {
              ...responsible,
              label: responsible.acronym,
              value: responsible.id
            }
          : null
      }}
      form={{ errors: {} }}
      formatLabel={formatUserLabel}
      onlyGroups={[10, 15]}
      onSelect={onUserSelected}
    />
  );
});

export const RequestList = React.memo(function RequestList(): JSX.Element {
  const [filter, setFilter] = useState(filterToWhereClause(getFilters()));
  const [requestFilterDialogOpen, setRequestFilterDialogOpen] = useState(false);
  const [search, setSearch] = useState(getQueryString("q") || "");
  const history = useHistory();
  const {
    state: { currentUser }
  } = useContext<{ state: { currentUser: { id?: string; role?: string } } }>(
    AppContext
  );

  const handleSearchChange = useCallback(value => {
    setSearch(value);
  }, []);
  const handleRowClick = useCallback(
    request => {
      history.push(`/cases/requests/${request.id}`);
    },
    [history]
  );
  const handleRequestFilterDialogCancel = useCallback(() => {
    setRequestFilterDialogOpen(false);
  }, []);
  const handleFilterClick = useCallback(() => {
    setRequestFilterDialogOpen(true);
  }, []);
  const handleFilterChange = useCallback(newFilter => {
    setFilter(filterToWhereClause(newFilter));
    setRequestFilterDialogOpen(false);
  }, []);
  const handleFilterClearClick = useCallback(() => {
    setStandardFilter();
    handleFilterChange(STANDARD_REQUEST_FILTER);
  }, [handleFilterChange]);

  const finalFilter = useMemo(() => {
    if (currentUser.role === "admin") {
      return filter;
    }
    return [
      ...filter,
      {
        _or: [
          { responsible_id: { _eq: currentUser.id } },
          { responsible_id: { _is_null: true } }
        ]
      }
    ];
  }, [currentUser.id, currentUser.role, filter]);

  const intl = useIntl();
  const filtered = !_.isEqual(getFilters(), STANDARD_REQUEST_FILTER);
  const where = createWhereObject(
    [
      "builder",
      "case_type.name",
      "address",
      "state",
      "city",
      "zip_code",
      "mobile"
    ],
    search.replace(",", "").replace(".", ""),
    finalFilter
  );
  const handleFabClick = useCallback(() => {
    history.replace("/cases/requests/new");
  }, [history]);
  return (
    <Page
      appBar={
        <Header
          title={intl.formatMessage(messages.requests)}
          onFilterClearClick={filtered ? handleFilterClearClick : null}
          onFilterClick={handleFilterClick}
          onSearchChange={handleSearchChange}
        />
      }
      fab={
        <Fab
          color="primary"
          style={{ bottom: 5 * 2, position: "fixed", right: 5 * 2 }}
          onClick={handleFabClick}
        >
          <AddIcon />
        </Fab>
      }
    >
      <MUTableWithoutType
        noPadding
        columns={[
          {
            field: "builder",
            title: intl.formatMessage(messages.builder)
          },
          {
            field: "case_type",
            render: (request: Request) =>
              request.case_type?.name || intl.formatMessage(messages.notChosen),
            title: intl.formatMessage(messages.task)
          },
          {
            field: "address",
            render: (request: Request) =>
              `${request.address}, ${request.zip_code} ${request.city}`,
            title: intl.formatMessage(messages.address)
          },
          {
            field: "date",
            // eslint-disable-next-line react/display-name
            render: (request: Request) => (
              <>
                <FormattedDate value={request.date} />{" "}
                <FormattedTime value={request.date} />
              </>
            ),
            title: intl.formatMessage(messages.date)
          },
          {
            field: "state",

            render: (request: Request) => {
              const state = REQUESTS_STATE_OPTIONS.find(
                option => option.value === request.state
              );
              let initials = "";
              if (request.state === "external") {
                initials = request.external
                  ? ` (${request.external.mail})`
                  : "";
              } else {
                initials = request.responsible
                  ? ` (${request.responsible.acronym})`
                  : "";
              }
              return `${state ? state.label : ""}${initials}`;
            },
            title: intl.formatMessage(messages.state)
          },
          {
            field: "responsible",
            noClick: true,
            // eslint-disable-next-line react/display-name
            render: (request: Request) => (
              <SelectRequestResponsible request={request} />
            ),
            title: intl.formatMessage(messages.responsible)
          }
        ]}
        dataSourceName="requests_aggregate"
        direction="desc"
        gql={REQUESTS}
        limit={500}
        orderBy="date"
        where={where}
        onRowClick={handleRowClick}
      />
      <RequestFilterDialog
        filter={filter}
        open={requestFilterDialogOpen}
        onCancel={handleRequestFilterDialogCancel}
        onFilterChanged={handleFilterChange}
      />
    </Page>
  );
});
