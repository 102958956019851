import { AutocompleteSelect } from "./autocomplete-select";
import { CONTACTS_AGGREGATED_QUERY } from "../graphql/contacts";
import { createWhereObject } from "../utils/form-helpers";
import { withApollo } from "@apollo/client/react/hoc";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export const formatContactLabel = contact =>
  `${contact.first_name || ""} ${contact.last_name || ""} ${contact.company ||
    ""}`;

class ContactSelect extends PureComponent {
  static propTypes = {
    client: PropTypes.object.isRequired,
    noInitial: PropTypes.bool,
    type: PropTypes.string
  };
  state = {
    defaultOptions: []
  };
  async componentDidMount() {
    if (!this.props.noInitial) {
      const where = this.props.type
        ? { type: { _eq: this.props.type } }
        : undefined;
      const response = await this.contactQuery(where);
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        defaultOptions: response.data.contacts_aggregate.nodes.map(contact => ({
          ...contact,
          label: formatContactLabel(contact),
          value: contact.id
        }))
      });
    }
  }
  contactQuery = where => {
    return this.props.client.query({
      fetchPolicy: "network-only",
      query: CONTACTS_AGGREGATED_QUERY,
      variables: {
        limit: 25,
        orderBy: { first_name: "asc" },
        where
      }
    });
  };
  handleLoadContacts = async (input, callback) => {
    const extraWhere = this.props.type
      ? { type: { _eq: this.props.type } }
      : undefined;
    const where = input
      ? createWhereObject(
          ["first_name", "last_name", "company", "phone", "mobile"],
          input,
          extraWhere
        )
      : createWhereObject([], "", extraWhere);
    const response = await this.contactQuery(where);
    callback(
      response.data.contacts_aggregate.nodes.map(contact => ({
        ...contact,
        label: formatContactLabel(contact),
        value: contact.id
      }))
    );
  };

  render() {
    return (
      <AutocompleteSelect
        defaultOptions={
          this.props.noInitial ? undefined : this.state.defaultOptions
        }
        onLoadOptions={this.handleLoadContacts}
        {...this.props}
      />
    );
  }
}

const withHOC = withApollo(ContactSelect);

export { withHOC as ContactSelect };
