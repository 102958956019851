import { GET_USER_LIST } from "../../graphql/users";
import { Page } from "../../components/layout/page";
import { RouterProps } from "react-router";
import { User } from "../../types/types";
import { defineMessages, injectIntl } from "react-intl";
import AddIcon from "@material-ui/icons/Add";
import BSTable from "../../components/table";
import Fab from "@material-ui/core/Fab";
import Header from "../../components/layout/header";
import React, { Component } from "react";

const messages = defineMessages({
  add: {
    defaultMessage: "Opret",
    id: "user-list.add"
  },
  mail: {
    defaultMessage: "Mail",
    id: "user-list.mail"
  },
  mobile: {
    defaultMessage: "Mobil",
    id: "user-list.mobile"
  },
  name: {
    defaultMessage: "Navn",
    id: "user-list.name"
  },
  phone: {
    defaultMessage: "Telefon",
    id: "user-list.phone"
  },
  user_type: {
    defaultMessage: "Brugertype",
    id: "user-list.user_type"
  },
  users: {
    defaultMessage: "Brugere",
    id: "user-list.users"
  }
});

interface Props extends RouterProps {
  intl: any;
}

class UserList extends Component<Props> {
  handleRowClick = (
    _event: React.MouseEvent<Element, MouseEvent>,
    user: User
  ): void => {
    this.props.history.push(`/users/${user.id}`);
  };
  handleFabClick = (): void => {
    this.props.history.push("/users/create");
  };
  render(): JSX.Element {
    const { formatMessage } = this.props.intl;

    const fab = (
      <Fab
        aria-label={formatMessage(messages.add)}
        color="primary"
        style={{ bottom: 5 * 2, position: "absolute", right: 5 * 2 }}
        onClick={this.handleFabClick}
      >
        <AddIcon />
      </Fab>
    );
    const BSTableWithoutType = BSTable as any;
    return (
      <Page appBar={<Header title={formatMessage(messages.users)} />} fab={fab}>
        <BSTableWithoutType
          columns={[
            {
              field: "employee_number",
              title: "#"
            },
            {
              field: "name",
              render: (user: User) =>
                `${user.first_name || ""} ${user.last_name || ""}`,
              title: formatMessage(messages.name)
            },
            {
              field: "mail",
              title: formatMessage(messages.mail)
            },
            {
              field: "mobile",
              title: formatMessage(messages.mobile)
            },
            {
              field: "phone",
              title: formatMessage(messages.phone)
            },
            {
              field: "user_type",
              title: formatMessage(messages.user_type)
            }
          ]}
          dataSourceName="users_aggregate"
          defaultOrderBy="first_name"
          gql={GET_USER_LIST}
          limit={200}
          path="/users"
          onRowClick={this.handleRowClick}
        />
      </Page>
    );
  }
}

export default injectIntl(UserList);
