/* eslint-disable react/display-name */
import {ChecklistMarker} from "./checklist-items";
import {DialogBase} from "./dialog";
import {DialogContent} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import {fileInstanceToURL} from "../pages/case/photos/list";
import PropTypes from "prop-types";
import React, {useCallback, useState} from "react";
import _ from "lodash";

export const MarkerDialog = ({file, markers, number, onCancel, onOk, open}) => {
  const [markerList, setMarkerList] = useState(
    markers && markers.length ? markers : [{number: 1}]
  );
  React.useEffect(() => {
    if (
      _.isEqual(
        markers.filter(m => m.number !== number),
        markerList.filter(m => m.number !== number)
      )
    ) {
      return;
    }
    const newMarkerList = markers.map(marker => {
      if (marker.number === number) {
        return markerList.find(m => m.number === number);
      }
      return marker;
    });
    setMarkerList(newMarkerList);
  }, [markers, setMarkerList, markerList, number]);

  const [imageSize, setImageSize] = useState();

  const handleImageClick = useCallback(
    event => {
      const copy = [...markerList];
      const changedMarker = copy.find(marker => marker.number === number);
      if (!changedMarker) {
        const newMarker = {
          number: copy.length + 1,
          x: event.nativeEvent.offsetX / event.target.width,
          y: event.nativeEvent.offsetY / event.target.height
        }
        copy.push(newMarker);
      } else {
        changedMarker.x = event.nativeEvent.offsetX / event.target.width;
        changedMarker.y = event.nativeEvent.offsetY / event.target.height;
      }
      setMarkerList(copy);
    },
    [markerList, setMarkerList, number]
  );

  const onImgLoaded = useCallback(
    ({target: img}) => {
      setImageSize({
        height: img.height,
        width: img.width
      });
    },
    [setImageSize]
  );

  const handleOK = useCallback(() => {
    onOk(markerList.find(marker => marker.number === number));
  }, [markerList, onOk, number]);

  return (
    <DialogBase
      fullscreen
      open={open}
      title={
        <FormattedMessage
          defaultMessage="Placer markering"
          id="marker-dialog.title"
        />
      }
      onCancel={onCancel}
      onOk={handleOK}
    >
      <DialogContent>
        <div style={{position: "relative"}}>
          {imageSize &&
            markerList.map((marker, index) => {
              if (!marker.x || !marker.y) {
                return null;
              }

              const realX = marker.x * imageSize.width;
              const realY = marker.y * imageSize.height;
              return (
                <ChecklistMarker
                  key={`${index}-${marker.x}-${marker.y}`}
                  active={number === marker.number}
                  left={realX}
                  number={marker.number}
                  top={realY}
                />
              );
            })}
          <img
            src={fileInstanceToURL({file})}
            width="100%"
            onClick={handleImageClick}
            onLoad={onImgLoaded}
          />
        </div>
      </DialogContent>
    </DialogBase>
  );
};
MarkerDialog.propTypes = {
  file: PropTypes.object.isRequired,
  markers: PropTypes.array,
  number: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};
