import gql from "graphql-tag";

export const REQUESTS = gql`
  query list_requests(
    $orderBy: requests_order_by!
    $where: requests_bool_exp
    $offset: Int
    $limit: Int
  ) {
    requests_aggregate(
      offset: $offset
      order_by: [$orderBy]
      where: $where
      limit: $limit
    ) {
      aggregate {
        count
      }
      nodes {
        id
        builder
        task
        address
        date
        state
        zip_code
        city
        mail
        mobile
        description
        case_type {
          id
          name
        }
        inspection_date
        inspection_time
        responsible {
          id
          first_name
          last_name
          mail
          mobile
          phone
          acronym
        }
        external {
          id
          first_name
          last_name
          company
          address
          zip_code
          city
          phone
          mobile
          mail
        }
      }
    }
  }
`;

export const GET_REQUEST = gql`
  query get_requests($requestId: uuid) {
    requests(where: { id: { _eq: $requestId } }) {
      id
      builder
      task
      address
      date
      state
      zip_code
      city
      mail
      mobile
      description
      case_type {
        id
        name
      }
      inspection_date
      inspection_time
      responsible {
        id
        first_name
        last_name
        mail
        mobile
        phone
        acronym
      }
      external {
        id
        first_name
        last_name
        company
        address
        zip_code
        city
        phone
        mobile
        mail
      }
      request_notes(order_by: { added_date: desc }) {
        id
        user {
          id
          acronym
        }
        notes
        added_date
      }
      reminders(order_by: { date: asc }) {
        id
        date
        message
        creator {
          id
          acronym
        }
        recipients {
          user {
            id
            acronym
          }
        }
      }
    }
  }
`;

export const CREATE_REQUEST = gql`
  mutation createRequest($request: requests_insert_input!) {
    insert_requests(objects: [$request]) {
      returning {
        id
        builder
        task
        address
        date
        state
        zip_code
        city
        mail
        mobile
        description
        case_type {
          id
          name
        }
        inspection_date
        inspection_time
        responsible {
          id
          first_name
          last_name
          mail
          mobile
          phone
          acronym
        }
        external {
          id
          first_name
          last_name
          company
          address
          zip_code
          city
          phone
          mobile
          mail
        }
        request_notes(order_by: { added_date: desc }) {
          id
          user {
            id
            acronym
          }
          notes
          added_date
        }
        reminders(order_by: { date: asc }) {
          id
          date
          message
          creator {
            id
            acronym
          }
          recipients {
            user {
              id
              acronym
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_REQUEST = gql`
  mutation update_request($id: uuid, $request: requests_set_input!) {
    update_requests(where: { id: { _eq: $id } }, _set: $request) {
      returning {
        id
        builder
        task
        address
        date
        state
        zip_code
        city
        mail
        mobile
        description
        case_type {
          id
          name
        }
        inspection_date
        inspection_time
        responsible {
          id
          first_name
          last_name
          mail
          mobile
          phone
          acronym
        }
        external {
          id
          first_name
          last_name
          company
          address
          zip_code
          city
          phone
          mobile
          mail
        }
        request_notes(order_by: { added_date: desc }) {
          id
          user {
            id
            acronym
          }
          notes
          added_date
        }
        reminders(order_by: { date: asc }) {
          id
          date
          message
          creator {
            id
            acronym
          }
          recipients {
            user {
              id
              acronym
            }
          }
        }
      }
    }
  }
`;

export const CREATE_REQEST_NOTES = gql`
  mutation insert_request_notes(
    $request_notes: [request_notes_insert_input!]!
  ) {
    insert_request_notes(objects: $request_notes) {
      returning {
        id
        user {
          id
          acronym
        }
        notes
        added_date
      }
    }
  }
`;

export const REQUEST_REMINDERS = gql`
  query list_request_reminders(
    $orderBy: reminders_order_by!
    $offset: Int
    $limit: Int
    $where: reminders_bool_exp
  ) {
    reminders_aggregate(
      offset: $offset
      order_by: [$orderBy]
      where: $where
      limit: $limit
    ) {
      aggregate {
        count
      }
      nodes {
        id
        date
        message
        creator {
          id
          acronym
        }
        recipients {
          user {
            id
            acronym
          }
        }
      }
    }
  }
`;

export const CREATE_REMINDER = gql`
  mutation createReminder($reminder: reminders_insert_input!) {
    insert_reminders(objects: [$reminder]) {
      returning {
        id
        date
        message
        creator {
          id
          acronym
        }
        recipients {
          user {
            id
            first_name
            last_name
            mail
            mobile
            phone
            acronym
          }
        }
      }
    }
  }
`;

export const GET_REMINDER = gql`
  query get_request_reminders($reminderId: uuid) {
    reminders(where: { id: { _eq: $reminderId } }) {
      id
      date
      message
      creator {
        id
        acronym
      }
      recipients {
        user {
          id
          first_name
          last_name
          mail
          mobile
          phone
          acronym
        }
      }
    }
  }
`;

export const UPDATE_REMINDER = gql`
  mutation update_request_reminders(
    $id: uuid
    $reminder: reminders_set_input!
  ) {
    update_reminders(where: { id: { _eq: $id } }, _set: $reminder) {
      returning {
        id
        date
        message
        creator {
          id
          acronym
        }
        recipients {
          user {
            id
            first_name
            last_name
            mail
            mobile
            phone
            acronym
          }
        }
      }
    }
  }
`;

export const CREATE_REMINDER_RECIPIENT = gql`
  mutation createReminderRecipient(
    $reminder_recipients: [reminder_recipients_insert_input!]!
  ) {
    insert_reminder_recipients(objects: $reminder_recipients) {
      returning {
        id
        user_id
        reminder_id
      }
    }
  }
`;

export const DELETE_REMINDER_RECIPIENT = gql`
  mutation delete_reminder_recipients($where: reminder_recipients_bool_exp!) {
    delete_reminder_recipients(where: $where) {
      affected_rows
    }
  }
`;
