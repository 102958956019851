import { DialogBase } from "../../../components/dialog";
import {
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { QUALITY_CONTROL_TEMPLATES } from "../../../graphql/quality-control-templates";
import { Query } from "@apollo/client/react/components";
import React from "react";

const SelectQualityControlDialog = ({ caseId, onCancel, open }) => (
  <DialogBase
    open={open}
    title={
      <FormattedMessage
        defaultMessage="Vælg tilsynsnotat"
        id="selet-quality-control-dialog.title"
      />
    }
    onCancel={onCancel}
  >
    <DialogContent>
      <List>
        <ListItem
          button
          component="a"
          href={`#/cases/${caseId}/quality-control/create`}
        >
          <ListItemText
            primary={
              <FormattedMessage
                defaultMessage="Opret uden skabelon"
                id="selet-quality-control-dialog.add-without-template"
              />
            }
          />
        </ListItem>
        <Divider />
        <Query
          query={QUALITY_CONTROL_TEMPLATES}
          variables={{ orderBy: { template_title: "asc" } }}
        >
          {({ data, error, loading }) => {
            if (loading) {
              return <p>Loading...</p>;
            }
            if (error) {
              return <p>Error :(</p>;
            }

            return data.quality_control_templates.map(instance => (
              <>
                <ListItem
                  key={instance.id}
                  button
                  component="a"
                  href={`#/cases/${caseId}/quality-control/create/${instance.id}`}
                >
                  <ListItemText primary={instance.template_title} />
                </ListItem>
                <Divider />
              </>
            ));
          }}
        </Query>
      </List>
    </DialogContent>
  </DialogBase>
);

const memoed = React.memo(SelectQualityControlDialog);
export { memoed as SelectQualityControlDialog };
