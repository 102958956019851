import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Order_By, useGetConstructionPlansQuery } from "../generated/graphql";
import React from "react";

export const formatConstructionPlanLabel = (constructionPlan): string =>
  constructionPlan.name;

export const ConstructionPlanSelect = (props: any): JSX.Element => {
  const { data } = useGetConstructionPlansQuery({
    variables: {
      limit: 20,
      orderBy: { name: Order_By.Asc }
    }
  });
  return (
    <FormControl fullWidth className="constriction-plan-select">
      <InputLabel htmlFor="age-native-simple">{props.label}</InputLabel>
      <Select {...props.field}>
        <MenuItem value={null}>Ingen</MenuItem>
        {data?.construction_plans?.map(constructionPlan => (
          <MenuItem key={constructionPlan.id} value={constructionPlan.id}>
            {formatConstructionPlanLabel(constructionPlan)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
