import {ExternalInfo} from "../request/request-form";
import {FormattedMessage} from "react-intl";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import Bowser from "bowser";
import PropTypes from "prop-types";
import React from "react";

const browser = Bowser.getParser(window.navigator.userAgent);
const isMobile = browser.getPlatformType(true) !== "desktop";

export const SupplierList = React.memo(({suppliers}) => {
  if (isMobile) {
    return suppliers.map((supplier, index) => (
      <><ExternalInfo key={index} contact={supplier} /><hr /></>
    ));
  }
  return (
    <Table>
      <TableHead style={{backgroundColor: "#333", color: "#FFF"}}>
        <TableRow>
          <TableCell style={{color: "#FFF"}}>
            <FormattedMessage defaultMessage="Navn" id="supplier-list.name" />
          </TableCell>
          <TableCell style={{color: "#FFF"}}>
            <FormattedMessage
              defaultMessage="Firma"
              id="supplier-list.company"
            />
          </TableCell>
          <TableCell style={{color: "#FFF"}}>
            <FormattedMessage
              defaultMessage="Adresse"
              id="supplier-list.address"
            />
          </TableCell>
          <TableCell style={{color: "#FFF"}}>
            <FormattedMessage
              defaultMessage="Mobil"
              id="supplier-list.mobile"
            />
          </TableCell>
          <TableCell style={{color: "#FFF"}}>
            <FormattedMessage
              defaultMessage="Telefon"
              id="supplier-list.phone"
            />
          </TableCell>
          <TableCell style={{color: "#FFF"}}>
            <FormattedMessage defaultMessage="Mail" id="supplier-list.mail" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {suppliers.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{`${row.first_name} ${row.last_name}`}</TableCell>
            <TableCell>{row.company}</TableCell>
            <TableCell>{row.address}</TableCell>
            <TableCell>{row.mobile}</TableCell>
            <TableCell>{row.phone}</TableCell>
            <TableCell>{row.mail}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
});
SupplierList.displayName = "SupplierList";
SupplierList.propTypes = {
  suppliers: PropTypes.array
};
