import gql from "graphql-tag";

export const GET_ABSENCE_TYPES = gql`
  query getAbsenceTypes(
    $orderBy: absence_types_order_by!
    $where: absence_types_bool_exp
    $limit: Int
  ) {
    absence_types(order_by: [$orderBy], where: $where, limit: $limit) {
      id
      label
      active
    }
  }
`;
