/* eslint-disable react/display-name */
import React from 'react'

export const DocumentInstructions = React.memo(() => {
    return (
        <div>
            <h4>Instruktioner</h4>I alle felter herunder kan der indsættes
            special-felter som automatisk udfyldes fra den givne sag, som en
            ordrebekræftelse laves til. Alle special-felter skrives som{" "}
            {"{{ felt }}"} – mellemrummene er ikke vigtige, blot de krøllede
            parenteser laves korrekt og ordet i midten er det rigtige. Følgende
            felter kan indsættes:
            <ul>
              <li>
                <code>{"{{ firstName }}"}</code>: Bygherrens fornavn.
              </li>
              <li>
                <code>{"{{ lastName }}"}</code>: Bygherrens efternavn.
              </li>
              <li>
                <code>{"{{ phone }}"}</code>: Bygherrens telefonnummer.
              </li>
              <li>
                <code>{"{{ email }}"}</code>: Bygherrens email-adresse.
              </li>
              <li>
                <code>{"{{ builderAddress }}"}</code>: Bygherrens adresse,
                skrevet som {'"<adresse>, <postnr> <by>"'}.
              </li>
              <li>
                <code>{"{{ address }}"}</code>: Sagens adresse, skrevet som{" "}
                {'"<adresse>, <postnr> <by>"'}. Kan være det samme som eller
                forskellig fra bygherrens adresse
              </li>
              <li>
                <code>{"{{ responsible }}"}</code>: Den sagsansvarliges fulde
                navn.
              </li>
              <li>
                <code>{"{{ responsibleEmail }}"}</code>: Den sagsansvarliges
                email-adresse.
              </li>
              <li>
                <code>{"{{ responsibleMobile }}"}</code>: Den sagsansvarliges
                mobiltelefonnummer.
              </li>
              <li>
                <code>{"{{ responsiblePhone }}"}</code>: Den sagsansvarliges
                fastnettelefonnummer.
              </li>
              <li>
                <code>{"{{ responsibleInitials }}"}</code>: Den sagsansvarliges
                initialer.
              </li>
              <li>
                <code>{"{{ date }}"}</code>: Inspektionsdato, e.g.{" "}
                {'"25/2 2018"'}.
              </li>
              <li>
                <code>{"{{ time }}"}</code>: Inspektionstidspunktet på dagen,
                e.g. {'"13:00"'}.
              </li>
            </ul>
        </div>
    )
}
)
