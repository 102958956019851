/* eslint-disable react/jsx-no-bind */
import { APP_BAR_HEIGHT, Page } from "../../components/layout/page";
import { AppBar, Link, Tab, Tabs, makeStyles } from "@material-ui/core";
import { AppContext } from "../../components/AppContext";
import { CREATE_CASE } from "../../graphql/cases";
import { CREATE_CASE_DOC_NOTES } from "../../graphql/case-doc-notes";
import {
  CREATE_CONTACT,
  GET_CONTACT_WITH_NAME_MOBILE
} from "../../graphql/contacts";
import {
  CREATE_REQEST_NOTES,
  CREATE_REQUEST,
  UPDATE_REQUEST
} from "../../graphql/requests";
import { Formik, useFormikContext } from "formik";
import {
  Get_RequestsQuery,
  useGet_RequestsLazyQuery
} from "../../generated/graphql";
import { GoogleMapsClientWithPromise, createClient } from "@google/maps";
import { REQUESTS_STATE_OPTIONS, RequestForm } from "./request-form";
import { ReminderList } from "../../components/reminders/reminder-list";
import { Route, Switch } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";
import { formatCaseTypeLabel } from "../../components/case-type-select";
import { formatContactLabel } from "../../components/contact-select";
import { formatUserLabel } from "../../components/user-select";
import { generateSelectValue } from "../../utils/form-helpers";
import { useApolloClient } from "@apollo/client";
import { useCurrentUser } from "../../utils/hooks/use-current-user";
import { useHistory, useParams } from "react-router";
import AlarmAddIcon from "@material-ui/icons/AlarmAdd";
import AreYouSure from "../../components/are-you-sure";
import Header from "../../components/layout/header";
import MuFab from "../../components/fab";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import _ from "lodash";

const messages = defineMessages({
  add: {
    defaultMessage: "Tilføj",
    id: "request-entry.add"
  },
  details: {
    defaultMessage: "Detaljer",
    id: "request-entry.details"
  },
  reminders: {
    defaultMessage: "Påmindelser",
    id: "request-entry.reminders"
  },
  requests: {
    defaultMessage: "Forespørgsler",
    id: "request-entry.requests"
  },
  update: {
    defaultMessage: "Redigér forespørgsel",
    id: "request-entry.update-request"
  }
});

export type RequestStateOption = {
  label: string;
  value: string;
};

export type Request = Get_RequestsQuery["requests"][number] & {
  state: RequestStateOption;
};

const useStyles = makeStyles({
  appBar: {
    "@media (min-width:1025px)": {
      width: "calc(100% - 240px)"
    },
    marginTop: APP_BAR_HEIGHT
  }
});

const ReminderListWithoutTypes: any = ReminderList;

const RequestPage = React.memo(function RequestPage(): JSX.Element {
  const {
    dirty,
    handleReset,
    isSubmitting,
    submitForm,
    values
  } = useFormikContext<Request>();
  const {
    state: { currentUser }
  } = useContext(AppContext);
  const classes = useStyles();
  const { requestId } = useParams<{ requestId?: string }>();

  const history = useHistory();
  const googleMapsClient = useRef<GoogleMapsClientWithPromise>();
  const client = useApolloClient();

  useEffect(() => {
    googleMapsClient.current = createClient({
      key: "AIzaSyAareESm47Joh6AkGMIkGBNGRyGVrBeOak",
      Promise
    });
  }, []);

  const getGeoCodes = useCallback(async contact => {
    try {
      const geoCodes = await googleMapsClient.current
        .geocode({
          address: `${contact.address} ${contact.zip_code} ${contact.city}`
        })
        .asPromise();
      const { lat, lng } = geoCodes.json.results[0].geometry.location;
      return { lat, lng };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
    return { lat: undefined, lng: undefined };
  }, []);

  const convertToCase = useCallback(async () => {
    const {
      address,
      builder,
      city,
      description,
      id,
      inspection_date,
      mail,
      mobile,
      request_notes,
      task,
      zip_code
    } = values;

    const first_name = builder.substr(0, builder.indexOf(" "));
    const last_name = builder.substr(builder.indexOf(" ") + 1);
    let existingContactCheck;
    try {
      existingContactCheck = await client.query({
        fetchPolicy: "network-only",
        query: GET_CONTACT_WITH_NAME_MOBILE,
        variables: {
          first_name,
          last_name,
          mobile
        }
      });
    } catch (e) {
      console.log("Do something with this error", e);
    }
    let contact;
    if (existingContactCheck.data.contacts.length) {
      contact = existingContactCheck.data.contacts[0];
    } else {
      const contactVariables = {
        contact: {
          address,
          city,
          first_name,
          last_name,
          mail,
          mobile,
          phone: mobile,
          type: "customer",
          zip_code
        }
      };

      try {
        const response = await client.mutate({
          mutation: CREATE_CONTACT,
          variables: contactVariables
        });
        contact = response.data.insert_contacts.returning[0];
      } catch (e) {
        console.log("Do something with this error", e);
      }
    }

    const { lat, lng } = await getGeoCodes(contact);

    const variables = {
      case: {
        builder_id: contact.id,
        case_type_id: _.get(values, "case_type.id", null),
        created: new Date(),
        created_by_id: currentUser.id,
        date_of_inspection: inspection_date,
        description,
        lat,
        lng,
        responsible_id: _.get(values, "responsible.id", null),
        task
      }
    };

    try {
      const response = await client.mutate({
        mutation: CREATE_CASE,
        variables
      });
      const createdCaseId = response.data.insert_cases.returning[0].id;
      if (request_notes.length) {
        await client.mutate({
          mutation: CREATE_CASE_DOC_NOTES,
          variables: {
            case_doc_notes: request_notes.map(note => {
              let noteToSave = note.notes;
              let noteDescription;
              if (noteToSave.length > 300) {
                noteToSave = note.notes.substring(0, 299);
                noteDescription = note.notes.substring(300);
              }

              return {
                case_id: createdCaseId,
                changed: note.added_date,
                created: note.added_date,
                document_date: note.added_date,
                text: noteDescription,
                title: noteToSave,
                type: "note",
                user_id: note.user.id
              };
            })
          }
        });
      }

      const requestVariables = {
        id,
        request: {
          case_id: createdCaseId,
          state: "converted"
        }
      };
      await client.mutate({
        mutation: UPDATE_REQUEST,
        variables: requestVariables
      });
      this.props.context.setLoading(false);

      history.replace(`/cases/${createdCaseId}`);
    } catch (e) {
      console.log("Do something with this error", e);
    }
    this.props.context.setLoading(false);
  }, [client, currentUser.id, getGeoCodes, history, values]);

  const handleConvertToCaseClick = useCallback(async () => {
    if (dirty) {
      await submitForm();
      convertToCase();
    } else {
      convertToCase();
    }
  }, [convertToCase, dirty, submitForm]);

  const handleFabClick = useCallback(() => {
    history.push(`/cases/requests/${requestId}/reminders/create`);
  }, [history, requestId]);

  const showNavigationWarning = useCallback((): boolean => {
    return !isSubmitting && dirty;
  }, [dirty, isSubmitting]);
  const intl = useIntl();
  const currentTab =
    window.location.hash.indexOf("reminders") > -1 ? "reminders" : "details";
  return (
    <Page
      withTabs
      appBar={
        <>
          <AppBar className={classes.appBar} elevation={0} position="fixed">
            <Tabs scrollButtons="auto" value={currentTab} variant="scrollable">
              <Tab
                component={Link}
                href={`#/cases/requests/${requestId}`}
                label={intl.formatMessage(messages.details)}
                value="details"
              />
              <Tab
                component={Link}
                disabled={!requestId}
                href={`#/cases/requests/${requestId}/reminders`}
                label={intl.formatMessage(messages.reminders)}
                value="reminders"
              />
            </Tabs>
          </AppBar>
          <Header
            dirty={dirty}
            title={intl.formatMessage(messages.update)}
            onResetButton={handleReset}
            onSaveButton={submitForm}
          />
        </>
      }
    >
      <Switch>
        <Route
          path="/cases/requests/:requestId/reminders"
          render={props => (
            <ReminderListWithoutTypes
              {...props}
              baseURL={`/cases/requests/${requestId}/reminders`}
              parentId={requestId}
              parentName="request_id"
            />
          )}
        />
        <Route
          path="/cases/requests/:requestId"
          render={() => (
            <div
              style={{
                backgroundColor: "#FFF",
                minHeight: "calc(100% - 64px)",
                padding: 20
              }}
            >
              <RequestForm onConvertToCaseClick={handleConvertToCaseClick} />
              <MuFab
                disabled={!requestId}
                icon={<AlarmAddIcon />}
                onClick={handleFabClick}
              />
            </div>
          )}
        />
      </Switch>
      <AreYouSure when={showNavigationWarning} />
    </Page>
  );
});

export const RequestEntry = function RequestEntry(): JSX.Element {
  const { requestId } = useParams<{ requestId?: string }>();

  const client = useApolloClient();

  const [getData, { data }] = useGet_RequestsLazyQuery();

  useEffect(() => {
    if (requestId) {
      getData({
        variables: {
          requestId
        }
      });
    }
  }, [getData, requestId]);
  const history = useHistory();
  const handleSubmit = useCallback(
    async (payload, { resetForm, setErrors, setSubmitting }) => {
      const {
        address,
        builder,
        city,
        date,
        description,
        id,
        inspection_date,
        mail,
        mobile,
        request_notes,
        state,
        task,
        zip_code
      } = payload;
      const variables = {
        id,
        request: {
          address,
          builder,
          case_type_id: _.get(payload, "case_type.id", null),
          city,
          date,
          description,
          external_id: _.get(payload, "external.id", null),
          id,
          inspection_date,
          mail,
          mobile,
          responsible_id: _.get(payload, "responsible.id", null),
          state: state ? state.value : undefined,
          task,
          zip_code
        }
      };
      let responseId: string;
      try {
        if (requestId) {
          const response = await client.mutate({
            mutation: UPDATE_REQUEST,
            variables
          });
          responseId = response.data.update_requests.returning[0].id;
        } else {
          const response = await client.mutate({
            mutation: CREATE_REQUEST,
            variables
          });
          responseId = response.data.insert_requests.returning[0].id;
        }

        const newRequestNotes = request_notes.filter(notes => !notes.id);
        const requestNotesToBeAdded = newRequestNotes.map(requestNote => {
          const { added_date, notes, user } = requestNote;
          return {
            added_date,
            notes,
            request_id: responseId,
            user_id: user.id
          };
        });
        if (requestNotesToBeAdded.length) {
          await client.mutate({
            mutation: CREATE_REQEST_NOTES,
            variables: { request_notes: requestNotesToBeAdded }
          });
        }
        if (requestId) {
          resetForm({ values: payload });
        } else {
          history.replace(`/cases/requests/${responseId}`);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        const errors = e.graphQLErrors.map(error => error.message);
        // eslint-disable-next-line no-console
        console.log(errors);
        setSubmitting(false);
        setErrors({ form: errors });
      }
    },
    [client, history, requestId]
  );

  const currentUser = useCurrentUser();

  if (requestId && !data?.requests) {
    return <div>loading</div>;
  }

  const request = data?.requests[0];
  const responsible = generateSelectValue(
    request?.responsible || currentUser,
    formatUserLabel
  );
  const external = request?.responsible
    ? generateSelectValue(request.external, formatContactLabel)
    : null;
  const case_type = request?.responsible
    ? generateSelectValue(request.case_type, formatCaseTypeLabel)
    : null;
  const state = request?.state
    ? REQUESTS_STATE_OPTIONS.find(option => option.value === request?.state)
    : REQUESTS_STATE_OPTIONS[0];
  const inspection_date = request?.inspection_date || null;

  return (
    <Formik
      initialValues={{
        address: "",
        builder: "",
        city: "",
        description: "",
        inspection_time: null,
        mail: "",
        mobile: "",
        reminders: [],
        request_notes: [],
        task: "",
        zip_code: "",
        ...(request ? request : {}),
        case_type,
        external,
        inspection_date,
        responsible,
        state
      }}
      onSubmit={handleSubmit}
    >
      <RequestPage />
    </Formik>
  );
};
