import { CasePhotoList } from "../pages/case/photos/list";
import { DialogBase } from "./dialog";
import { DialogContent } from "@material-ui/core";
import { defineMessages, useIntl } from "react-intl";
import PropTypes from "prop-types";
import React, { useCallback } from "react";

const messages = defineMessages({
  title: {
    defaultMessage: "Vælg sagsfoto",
    id: "select-case-photo-dialog.title"
  }
});

export const SelectCasePhotoDialog = React.memo(function SelectCasePhotoDialog({
  onCancel,
  onFileSelected,
  open
}) {
  const { formatMessage } = useIntl();
  const handleRemove = useCallback(() => {
    onFileSelected();
  }, [onFileSelected]);
  return (
    <DialogBase
      fullscreen
      okLabel="Intet Billede"
      open={open}
      title={formatMessage(messages.title)}
      onCancel={onCancel}
      onOk={handleRemove}
    >
      <DialogContent>
        <CasePhotoList onFileSelected={onFileSelected} />
      </DialogContent>
    </DialogBase>
  );
});

SelectCasePhotoDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func,
  open: PropTypes.bool.isRequired
};
