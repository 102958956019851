import { GET_CONSTRUCTION_PLANS } from "../../../graphql/construction-plans";
import { MUTable } from "../../../components/mu-table";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router";
import MuFab from "../../../components/fab";
import React, { useCallback } from "react";

const messages = defineMessages({
  name: {
    defaultMessage: "Navn",
    id: "construction-plan-list.name"
  }
});

export const ConstructionPlanList = (): JSX.Element => {
  const history = useHistory();
  const handleRowClick = useCallback(
    constructionPlan => {
      history.push(`/settings/construction-plans/${constructionPlan.id}`);
    },
    [history]
  );
  const handleFabClick = useCallback(() => {
    history.push("/settings/construction-plans/create");
  }, [history]);
  const { formatMessage } = useIntl();

  const MUTableWithoutTypes: any = MUTable;
  return (
    <>
      <MUTableWithoutTypes
        columns={[
          {
            field: "name",
            title: formatMessage(messages.name)
          }
        ]}
        dataSourceName="construction_plans"
        gql={GET_CONSTRUCTION_PLANS}
        orderBy="name"
        path="/settings/construction-plans"
        onRowClick={handleRowClick}
      />
      <MuFab onClick={handleFabClick} />
    </>
  );
};
