import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select
} from "@material-ui/core";
import { Field } from "formik";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { UserSelect } from "../../components/user-select";
import React, { FunctionComponent } from "react";
import TextField from "@material-ui/core/TextField";

const messages = defineMessages({
  acronym: {
    defaultMessage: "Initialer",
    id: "user-form.acronym"
  },
  employeeNumber: {
    defaultMessage: "Medarbejder nummer",
    id: "user-form.employee_number"
  },
  first_name: {
    defaultMessage: "Fornavn",
    id: "user-form.first_name"
  },
  last_name: {
    defaultMessage: "Efternavn",
    id: "user-form.last_name"
  },
  mail: {
    defaultMessage: "Mail",
    id: "user-form.mail"
  },
  mobile: {
    defaultMessage: "Mobil",
    id: "user-form.mobile"
  },
  phone: {
    defaultMessage: "Telefon",
    id: "user-form.phone"
  },
  substitute: {
    defaultMessage: "Afløser",
    id: "user-form.substitute"
  },
  user_type: {
    defaultMessage: "Brugertype",
    id: "user-form.user_type"
  }
});

interface Props {
  errors: any;
  handleBlur: any;
  handleChange: any;
  handleSubmit: any;
  intl: any;
  isSubmitting: any;
  touched: any;
  values: any;
}

const UserForm: FunctionComponent<Props> = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  intl,
  values
}) => (
  <>
    <form onSubmit={handleSubmit}>
      <div>{errors.form}</div>

      <TextField
        fullWidth
        error={!!errors.first_name}
        label={intl.formatMessage(messages.first_name)}
        name="first_name"
        type="text"
        value={values.first_name}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        error={!!errors.last_name}
        label={intl.formatMessage(messages.last_name)}
        name="last_name"
        type="text"
        value={values.last_name}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        error={!!errors.mail}
        //defaultValue="@byggesagkyndig.nu" // TODO: discus how/if this should be implemented.
        label={intl.formatMessage(messages.mail)}
        name="mail"
        type="text"
        value={values.mail}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        error={!!errors.acronym}
        label={intl.formatMessage(messages.acronym)}
        name="acronym"
        type="text"
        value={values.acronym}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        error={!!errors.mobile}
        label={intl.formatMessage(messages.mobile)}
        name="mobile"
        type="text"
        value={values.mobile}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        error={!!errors.phone}
        label={intl.formatMessage(messages.phone)}
        name="phone"
        type="text"
        value={values.phone}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <Field
        component={UserSelect}
        label={intl.formatMessage(messages.substitute)}
        name="substitute"
      />
      <TextField
        fullWidth
        error={!!errors.employee_number}
        label={intl.formatMessage(messages.employeeNumber)}
        name="employee_number"
        type="text"
        value={values.employee_number}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <FormControl>
        <InputLabel htmlFor="age-native-simple">
          {intl.formatMessage(messages.user_type)}
        </InputLabel>
        <Select
          fullWidth
          native
          error={!!errors.user_type}
          name="user_type"
          value={values.user_type}
          onBlur={handleBlur}
          onChange={handleChange}
        >
          <option value={"user"}>Bruger</option>
          <option value={"superuser"}>Superbruger</option>
          <option value={"admin"}>Admin</option>
          <option value={"sale"}>Salg</option>
        </Select>
      </FormControl>
      <br />
      <FormControlLabel
        control={
          <Checkbox
            checked={values.active}
            name="active"
            onChange={handleChange}
          />
        }
        label={
          <FormattedMessage defaultMessage="Aktiv" id="user-form.active" />
        }
      />
    </form>
  </>
);

const UserFormWithIntl: any = injectIntl(UserForm);

export { UserFormWithIntl as UserForm };
