import { GET_CASE_TYPE_LIST } from "../../graphql/case-types";
import { Pin } from "../../components/pin";
import { defineMessages, injectIntl } from "react-intl";
import { withRouter } from "react-router";
import BSTable from "../../components/table";
import MuFab from "../../components/fab";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

const messages = defineMessages({
  name: {
    defaultMessage: "Navn",
    id: "case-types-list.name"
  },
  pin: {
    defaultMessage: "Kortnål",
    id: "case-types-list.pin"
  },
  price: {
    defaultMessage: "Pris",
    id: "case-types-list.price"
  }
});

class CaseTypeList extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired
  };

  handleRowClick = (_event, caseType) => {
    this.props.history.push(`/settings/case-types/${caseType.id}`);
  };
  handleFabClick = () => {
    this.props.history.push("/settings/case-types/create");
  };

  render() {
    const {
      intl: { formatMessage }
    } = this.props;
    return (
      <>
        <BSTable
          columns={[
            {
              field: "name",
              title: formatMessage(messages.name)
            },
            {
              field: "icon",
              render: caseType => (
                <Pin color={caseType.color} icon={caseType.icon} />
              ),
              title: formatMessage(messages.pin)
            },
            {
              field: "price",
              title: formatMessage(messages.price)
            }
          ]}
          dataSourceName="case_types_aggregate"
          defaultOrderBy="name"
          gql={GET_CASE_TYPE_LIST}
          path="/settings/case-types"
          onRowClick={this.handleRowClick}
        />
        <MuFab onClick={this.handleFabClick} />
      </>
    );
  }
}

export default withRouter(injectIntl(CaseTypeList));
