import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  numeric: any;
  timestamptz: any;
  uuid: any;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

/** columns and relationships of "absence" */
export type Absence = {
  __typename?: 'absence';
  /** An object relationship */
  absence_type: Absence_Types;
  absence_type_id: Scalars['uuid'];
  /** An object relationship */
  employee: Users;
  employee_id: Scalars['uuid'];
  end: Scalars['timestamptz'];
  half_day?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  notes?: Maybe<Scalars['String']>;
  start: Scalars['timestamptz'];
};

/** aggregated selection of "absence" */
export type Absence_Aggregate = {
  __typename?: 'absence_aggregate';
  aggregate?: Maybe<Absence_Aggregate_Fields>;
  nodes: Array<Absence>;
};

/** aggregate fields of "absence" */
export type Absence_Aggregate_Fields = {
  __typename?: 'absence_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Absence_Max_Fields>;
  min?: Maybe<Absence_Min_Fields>;
};


/** aggregate fields of "absence" */
export type Absence_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Absence_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "absence" */
export type Absence_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Absence_Max_Order_By>;
  min?: Maybe<Absence_Min_Order_By>;
};

/** input type for inserting array relation for remote table "absence" */
export type Absence_Arr_Rel_Insert_Input = {
  data: Array<Absence_Insert_Input>;
  on_conflict?: Maybe<Absence_On_Conflict>;
};

/** Boolean expression to filter rows from the table "absence". All fields are combined with a logical 'AND'. */
export type Absence_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Absence_Bool_Exp>>>;
  _not?: Maybe<Absence_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Absence_Bool_Exp>>>;
  absence_type?: Maybe<Absence_Types_Bool_Exp>;
  absence_type_id?: Maybe<Uuid_Comparison_Exp>;
  employee?: Maybe<Users_Bool_Exp>;
  employee_id?: Maybe<Uuid_Comparison_Exp>;
  end?: Maybe<Timestamptz_Comparison_Exp>;
  half_day?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  start?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "absence" */
export enum Absence_Constraint {
  /** unique or primary key constraint */
  AbsencePkey = 'absence_pkey'
}

/** input type for inserting data into table "absence" */
export type Absence_Insert_Input = {
  absence_type?: Maybe<Absence_Types_Obj_Rel_Insert_Input>;
  absence_type_id?: Maybe<Scalars['uuid']>;
  employee?: Maybe<Users_Obj_Rel_Insert_Input>;
  employee_id?: Maybe<Scalars['uuid']>;
  end?: Maybe<Scalars['timestamptz']>;
  half_day?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Absence_Max_Fields = {
  __typename?: 'absence_max_fields';
  absence_type_id?: Maybe<Scalars['uuid']>;
  employee_id?: Maybe<Scalars['uuid']>;
  end?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "absence" */
export type Absence_Max_Order_By = {
  absence_type_id?: Maybe<Order_By>;
  employee_id?: Maybe<Order_By>;
  end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Absence_Min_Fields = {
  __typename?: 'absence_min_fields';
  absence_type_id?: Maybe<Scalars['uuid']>;
  employee_id?: Maybe<Scalars['uuid']>;
  end?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "absence" */
export type Absence_Min_Order_By = {
  absence_type_id?: Maybe<Order_By>;
  employee_id?: Maybe<Order_By>;
  end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
};

/** response of any mutation on the table "absence" */
export type Absence_Mutation_Response = {
  __typename?: 'absence_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Absence>;
};

/** input type for inserting object relation for remote table "absence" */
export type Absence_Obj_Rel_Insert_Input = {
  data: Absence_Insert_Input;
  on_conflict?: Maybe<Absence_On_Conflict>;
};

/** on conflict condition type for table "absence" */
export type Absence_On_Conflict = {
  constraint: Absence_Constraint;
  update_columns: Array<Absence_Update_Column>;
  where?: Maybe<Absence_Bool_Exp>;
};

/** ordering options when selecting data from "absence" */
export type Absence_Order_By = {
  absence_type?: Maybe<Absence_Types_Order_By>;
  absence_type_id?: Maybe<Order_By>;
  employee?: Maybe<Users_Order_By>;
  employee_id?: Maybe<Order_By>;
  end?: Maybe<Order_By>;
  half_day?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
};

/** primary key columns input for table: "absence" */
export type Absence_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "absence_search_view" */
export type Absence_Search_View = {
  __typename?: 'absence_search_view';
  /** An object relationship */
  absence_type?: Maybe<Absence_Types>;
  absence_type_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  employee?: Maybe<Users>;
  employee_id?: Maybe<Scalars['uuid']>;
  end?: Maybe<Scalars['timestamptz']>;
  half_day?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
  text_end?: Maybe<Scalars['String']>;
  text_start?: Maybe<Scalars['String']>;
};

/** aggregated selection of "absence_search_view" */
export type Absence_Search_View_Aggregate = {
  __typename?: 'absence_search_view_aggregate';
  aggregate?: Maybe<Absence_Search_View_Aggregate_Fields>;
  nodes: Array<Absence_Search_View>;
};

/** aggregate fields of "absence_search_view" */
export type Absence_Search_View_Aggregate_Fields = {
  __typename?: 'absence_search_view_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Absence_Search_View_Max_Fields>;
  min?: Maybe<Absence_Search_View_Min_Fields>;
};


/** aggregate fields of "absence_search_view" */
export type Absence_Search_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Absence_Search_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "absence_search_view" */
export type Absence_Search_View_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Absence_Search_View_Max_Order_By>;
  min?: Maybe<Absence_Search_View_Min_Order_By>;
};

/** input type for inserting array relation for remote table "absence_search_view" */
export type Absence_Search_View_Arr_Rel_Insert_Input = {
  data: Array<Absence_Search_View_Insert_Input>;
};

/** Boolean expression to filter rows from the table "absence_search_view". All fields are combined with a logical 'AND'. */
export type Absence_Search_View_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Absence_Search_View_Bool_Exp>>>;
  _not?: Maybe<Absence_Search_View_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Absence_Search_View_Bool_Exp>>>;
  absence_type?: Maybe<Absence_Types_Bool_Exp>;
  absence_type_id?: Maybe<Uuid_Comparison_Exp>;
  employee?: Maybe<Users_Bool_Exp>;
  employee_id?: Maybe<Uuid_Comparison_Exp>;
  end?: Maybe<Timestamptz_Comparison_Exp>;
  half_day?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  start?: Maybe<Timestamptz_Comparison_Exp>;
  text_end?: Maybe<String_Comparison_Exp>;
  text_start?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "absence_search_view" */
export type Absence_Search_View_Insert_Input = {
  absence_type?: Maybe<Absence_Types_Obj_Rel_Insert_Input>;
  absence_type_id?: Maybe<Scalars['uuid']>;
  employee?: Maybe<Users_Obj_Rel_Insert_Input>;
  employee_id?: Maybe<Scalars['uuid']>;
  end?: Maybe<Scalars['timestamptz']>;
  half_day?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
  text_end?: Maybe<Scalars['String']>;
  text_start?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Absence_Search_View_Max_Fields = {
  __typename?: 'absence_search_view_max_fields';
  absence_type_id?: Maybe<Scalars['uuid']>;
  employee_id?: Maybe<Scalars['uuid']>;
  end?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
  text_end?: Maybe<Scalars['String']>;
  text_start?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "absence_search_view" */
export type Absence_Search_View_Max_Order_By = {
  absence_type_id?: Maybe<Order_By>;
  employee_id?: Maybe<Order_By>;
  end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
  text_end?: Maybe<Order_By>;
  text_start?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Absence_Search_View_Min_Fields = {
  __typename?: 'absence_search_view_min_fields';
  absence_type_id?: Maybe<Scalars['uuid']>;
  employee_id?: Maybe<Scalars['uuid']>;
  end?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
  text_end?: Maybe<Scalars['String']>;
  text_start?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "absence_search_view" */
export type Absence_Search_View_Min_Order_By = {
  absence_type_id?: Maybe<Order_By>;
  employee_id?: Maybe<Order_By>;
  end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
  text_end?: Maybe<Order_By>;
  text_start?: Maybe<Order_By>;
};

/** response of any mutation on the table "absence_search_view" */
export type Absence_Search_View_Mutation_Response = {
  __typename?: 'absence_search_view_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Absence_Search_View>;
};

/** input type for inserting object relation for remote table "absence_search_view" */
export type Absence_Search_View_Obj_Rel_Insert_Input = {
  data: Absence_Search_View_Insert_Input;
};

/** ordering options when selecting data from "absence_search_view" */
export type Absence_Search_View_Order_By = {
  absence_type?: Maybe<Absence_Types_Order_By>;
  absence_type_id?: Maybe<Order_By>;
  employee?: Maybe<Users_Order_By>;
  employee_id?: Maybe<Order_By>;
  end?: Maybe<Order_By>;
  half_day?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
  text_end?: Maybe<Order_By>;
  text_start?: Maybe<Order_By>;
};

/** select columns of table "absence_search_view" */
export enum Absence_Search_View_Select_Column {
  /** column name */
  AbsenceTypeId = 'absence_type_id',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  End = 'end',
  /** column name */
  HalfDay = 'half_day',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  Start = 'start',
  /** column name */
  TextEnd = 'text_end',
  /** column name */
  TextStart = 'text_start'
}

/** input type for updating data in table "absence_search_view" */
export type Absence_Search_View_Set_Input = {
  absence_type_id?: Maybe<Scalars['uuid']>;
  employee_id?: Maybe<Scalars['uuid']>;
  end?: Maybe<Scalars['timestamptz']>;
  half_day?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
  text_end?: Maybe<Scalars['String']>;
  text_start?: Maybe<Scalars['String']>;
};

/** select columns of table "absence" */
export enum Absence_Select_Column {
  /** column name */
  AbsenceTypeId = 'absence_type_id',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  End = 'end',
  /** column name */
  HalfDay = 'half_day',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  Start = 'start'
}

/** input type for updating data in table "absence" */
export type Absence_Set_Input = {
  absence_type_id?: Maybe<Scalars['uuid']>;
  employee_id?: Maybe<Scalars['uuid']>;
  end?: Maybe<Scalars['timestamptz']>;
  half_day?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "absence_types" */
export type Absence_Types = {
  __typename?: 'absence_types';
  active: Scalars['Boolean'];
  category: Scalars['String'];
  id: Scalars['uuid'];
  label: Scalars['String'];
  type: Scalars['String'];
};

/** aggregated selection of "absence_types" */
export type Absence_Types_Aggregate = {
  __typename?: 'absence_types_aggregate';
  aggregate?: Maybe<Absence_Types_Aggregate_Fields>;
  nodes: Array<Absence_Types>;
};

/** aggregate fields of "absence_types" */
export type Absence_Types_Aggregate_Fields = {
  __typename?: 'absence_types_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Absence_Types_Max_Fields>;
  min?: Maybe<Absence_Types_Min_Fields>;
};


/** aggregate fields of "absence_types" */
export type Absence_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Absence_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "absence_types" */
export type Absence_Types_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Absence_Types_Max_Order_By>;
  min?: Maybe<Absence_Types_Min_Order_By>;
};

/** input type for inserting array relation for remote table "absence_types" */
export type Absence_Types_Arr_Rel_Insert_Input = {
  data: Array<Absence_Types_Insert_Input>;
  on_conflict?: Maybe<Absence_Types_On_Conflict>;
};

/** Boolean expression to filter rows from the table "absence_types". All fields are combined with a logical 'AND'. */
export type Absence_Types_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Absence_Types_Bool_Exp>>>;
  _not?: Maybe<Absence_Types_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Absence_Types_Bool_Exp>>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  category?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "absence_types" */
export enum Absence_Types_Constraint {
  /** unique or primary key constraint */
  AbsenceTypesPkey = 'absence_types_pkey'
}

/** input type for inserting data into table "absence_types" */
export type Absence_Types_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Absence_Types_Max_Fields = {
  __typename?: 'absence_types_max_fields';
  category?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "absence_types" */
export type Absence_Types_Max_Order_By = {
  category?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Absence_Types_Min_Fields = {
  __typename?: 'absence_types_min_fields';
  category?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "absence_types" */
export type Absence_Types_Min_Order_By = {
  category?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** response of any mutation on the table "absence_types" */
export type Absence_Types_Mutation_Response = {
  __typename?: 'absence_types_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Absence_Types>;
};

/** input type for inserting object relation for remote table "absence_types" */
export type Absence_Types_Obj_Rel_Insert_Input = {
  data: Absence_Types_Insert_Input;
  on_conflict?: Maybe<Absence_Types_On_Conflict>;
};

/** on conflict condition type for table "absence_types" */
export type Absence_Types_On_Conflict = {
  constraint: Absence_Types_Constraint;
  update_columns: Array<Absence_Types_Update_Column>;
  where?: Maybe<Absence_Types_Bool_Exp>;
};

/** ordering options when selecting data from "absence_types" */
export type Absence_Types_Order_By = {
  active?: Maybe<Order_By>;
  category?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: "absence_types" */
export type Absence_Types_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "absence_types" */
export enum Absence_Types_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Category = 'category',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "absence_types" */
export type Absence_Types_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** update columns of table "absence_types" */
export enum Absence_Types_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Category = 'category',
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label',
  /** column name */
  Type = 'type'
}

/** update columns of table "absence" */
export enum Absence_Update_Column {
  /** column name */
  AbsenceTypeId = 'absence_type_id',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  End = 'end',
  /** column name */
  HalfDay = 'half_day',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  Start = 'start'
}

/** columns and relationships of "archive_files" */
export type Archive_Files = {
  __typename?: 'archive_files';
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid'];
  id: Scalars['uuid'];
  title: Scalars['String'];
};

/** aggregated selection of "archive_files" */
export type Archive_Files_Aggregate = {
  __typename?: 'archive_files_aggregate';
  aggregate?: Maybe<Archive_Files_Aggregate_Fields>;
  nodes: Array<Archive_Files>;
};

/** aggregate fields of "archive_files" */
export type Archive_Files_Aggregate_Fields = {
  __typename?: 'archive_files_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Archive_Files_Max_Fields>;
  min?: Maybe<Archive_Files_Min_Fields>;
};


/** aggregate fields of "archive_files" */
export type Archive_Files_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Archive_Files_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "archive_files" */
export type Archive_Files_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Archive_Files_Max_Order_By>;
  min?: Maybe<Archive_Files_Min_Order_By>;
};

/** input type for inserting array relation for remote table "archive_files" */
export type Archive_Files_Arr_Rel_Insert_Input = {
  data: Array<Archive_Files_Insert_Input>;
  on_conflict?: Maybe<Archive_Files_On_Conflict>;
};

/** Boolean expression to filter rows from the table "archive_files". All fields are combined with a logical 'AND'. */
export type Archive_Files_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Archive_Files_Bool_Exp>>>;
  _not?: Maybe<Archive_Files_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Archive_Files_Bool_Exp>>>;
  file?: Maybe<Files_Bool_Exp>;
  file_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "archive_files" */
export enum Archive_Files_Constraint {
  /** unique or primary key constraint */
  ArchiveFilesPkey = 'archive_files_pkey'
}

/** input type for inserting data into table "archive_files" */
export type Archive_Files_Insert_Input = {
  file?: Maybe<Files_Obj_Rel_Insert_Input>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Archive_Files_Max_Fields = {
  __typename?: 'archive_files_max_fields';
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "archive_files" */
export type Archive_Files_Max_Order_By = {
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Archive_Files_Min_Fields = {
  __typename?: 'archive_files_min_fields';
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "archive_files" */
export type Archive_Files_Min_Order_By = {
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "archive_files" */
export type Archive_Files_Mutation_Response = {
  __typename?: 'archive_files_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Archive_Files>;
};

/** input type for inserting object relation for remote table "archive_files" */
export type Archive_Files_Obj_Rel_Insert_Input = {
  data: Archive_Files_Insert_Input;
  on_conflict?: Maybe<Archive_Files_On_Conflict>;
};

/** on conflict condition type for table "archive_files" */
export type Archive_Files_On_Conflict = {
  constraint: Archive_Files_Constraint;
  update_columns: Array<Archive_Files_Update_Column>;
  where?: Maybe<Archive_Files_Bool_Exp>;
};

/** ordering options when selecting data from "archive_files" */
export type Archive_Files_Order_By = {
  file?: Maybe<Files_Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: "archive_files" */
export type Archive_Files_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "archive_files" */
export enum Archive_Files_Select_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "archive_files" */
export type Archive_Files_Set_Input = {
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** update columns of table "archive_files" */
export enum Archive_Files_Update_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}


/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "case_doc_checklists" */
export type Case_Doc_Checklists = {
  __typename?: 'case_doc_checklists';
  /** An object relationship */
  case: Cases;
  case_id: Scalars['uuid'];
  changed: Scalars['String'];
  closing?: Maybe<Scalars['String']>;
  created: Scalars['String'];
  document_date?: Maybe<Scalars['String']>;
  /** An object relationship */
  file?: Maybe<Files>;
  file_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  introduction?: Maybe<Scalars['String']>;
  /** An array relationship */
  items: Array<Checklist_Items>;
  /** An aggregated array relationship */
  items_aggregate: Checklist_Items_Aggregate;
  revision_date?: Maybe<Scalars['String']>;
  share_key: Scalars['uuid'];
  title: Scalars['String'];
  type: Scalars['String'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "case_doc_checklists" */
export type Case_Doc_ChecklistsItemsArgs = {
  distinct_on?: Maybe<Array<Checklist_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Checklist_Items_Order_By>>;
  where?: Maybe<Checklist_Items_Bool_Exp>;
};


/** columns and relationships of "case_doc_checklists" */
export type Case_Doc_ChecklistsItems_AggregateArgs = {
  distinct_on?: Maybe<Array<Checklist_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Checklist_Items_Order_By>>;
  where?: Maybe<Checklist_Items_Bool_Exp>;
};

/** aggregated selection of "case_doc_checklists" */
export type Case_Doc_Checklists_Aggregate = {
  __typename?: 'case_doc_checklists_aggregate';
  aggregate?: Maybe<Case_Doc_Checklists_Aggregate_Fields>;
  nodes: Array<Case_Doc_Checklists>;
};

/** aggregate fields of "case_doc_checklists" */
export type Case_Doc_Checklists_Aggregate_Fields = {
  __typename?: 'case_doc_checklists_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Case_Doc_Checklists_Max_Fields>;
  min?: Maybe<Case_Doc_Checklists_Min_Fields>;
};


/** aggregate fields of "case_doc_checklists" */
export type Case_Doc_Checklists_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Case_Doc_Checklists_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "case_doc_checklists" */
export type Case_Doc_Checklists_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Case_Doc_Checklists_Max_Order_By>;
  min?: Maybe<Case_Doc_Checklists_Min_Order_By>;
};

/** input type for inserting array relation for remote table "case_doc_checklists" */
export type Case_Doc_Checklists_Arr_Rel_Insert_Input = {
  data: Array<Case_Doc_Checklists_Insert_Input>;
  on_conflict?: Maybe<Case_Doc_Checklists_On_Conflict>;
};

/** Boolean expression to filter rows from the table "case_doc_checklists". All fields are combined with a logical 'AND'. */
export type Case_Doc_Checklists_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Case_Doc_Checklists_Bool_Exp>>>;
  _not?: Maybe<Case_Doc_Checklists_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Case_Doc_Checklists_Bool_Exp>>>;
  case?: Maybe<Cases_Bool_Exp>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  changed?: Maybe<String_Comparison_Exp>;
  closing?: Maybe<String_Comparison_Exp>;
  created?: Maybe<String_Comparison_Exp>;
  document_date?: Maybe<String_Comparison_Exp>;
  file?: Maybe<Files_Bool_Exp>;
  file_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  introduction?: Maybe<String_Comparison_Exp>;
  items?: Maybe<Checklist_Items_Bool_Exp>;
  revision_date?: Maybe<String_Comparison_Exp>;
  share_key?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_doc_checklists" */
export enum Case_Doc_Checklists_Constraint {
  /** unique or primary key constraint */
  CaseDocChecklistsPkey = 'case_doc_checklists_pkey'
}

/** input type for inserting data into table "case_doc_checklists" */
export type Case_Doc_Checklists_Insert_Input = {
  case?: Maybe<Cases_Obj_Rel_Insert_Input>;
  case_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['String']>;
  closing?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  document_date?: Maybe<Scalars['String']>;
  file?: Maybe<Files_Obj_Rel_Insert_Input>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  introduction?: Maybe<Scalars['String']>;
  items?: Maybe<Checklist_Items_Arr_Rel_Insert_Input>;
  revision_date?: Maybe<Scalars['String']>;
  share_key?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Case_Doc_Checklists_Max_Fields = {
  __typename?: 'case_doc_checklists_max_fields';
  case_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['String']>;
  closing?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  document_date?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  introduction?: Maybe<Scalars['String']>;
  revision_date?: Maybe<Scalars['String']>;
  share_key?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "case_doc_checklists" */
export type Case_Doc_Checklists_Max_Order_By = {
  case_id?: Maybe<Order_By>;
  changed?: Maybe<Order_By>;
  closing?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  document_date?: Maybe<Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  introduction?: Maybe<Order_By>;
  revision_date?: Maybe<Order_By>;
  share_key?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Case_Doc_Checklists_Min_Fields = {
  __typename?: 'case_doc_checklists_min_fields';
  case_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['String']>;
  closing?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  document_date?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  introduction?: Maybe<Scalars['String']>;
  revision_date?: Maybe<Scalars['String']>;
  share_key?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "case_doc_checklists" */
export type Case_Doc_Checklists_Min_Order_By = {
  case_id?: Maybe<Order_By>;
  changed?: Maybe<Order_By>;
  closing?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  document_date?: Maybe<Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  introduction?: Maybe<Order_By>;
  revision_date?: Maybe<Order_By>;
  share_key?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "case_doc_checklists" */
export type Case_Doc_Checklists_Mutation_Response = {
  __typename?: 'case_doc_checklists_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Case_Doc_Checklists>;
};

/** input type for inserting object relation for remote table "case_doc_checklists" */
export type Case_Doc_Checklists_Obj_Rel_Insert_Input = {
  data: Case_Doc_Checklists_Insert_Input;
  on_conflict?: Maybe<Case_Doc_Checklists_On_Conflict>;
};

/** on conflict condition type for table "case_doc_checklists" */
export type Case_Doc_Checklists_On_Conflict = {
  constraint: Case_Doc_Checklists_Constraint;
  update_columns: Array<Case_Doc_Checklists_Update_Column>;
  where?: Maybe<Case_Doc_Checklists_Bool_Exp>;
};

/** ordering options when selecting data from "case_doc_checklists" */
export type Case_Doc_Checklists_Order_By = {
  case?: Maybe<Cases_Order_By>;
  case_id?: Maybe<Order_By>;
  changed?: Maybe<Order_By>;
  closing?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  document_date?: Maybe<Order_By>;
  file?: Maybe<Files_Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  introduction?: Maybe<Order_By>;
  items_aggregate?: Maybe<Checklist_Items_Aggregate_Order_By>;
  revision_date?: Maybe<Order_By>;
  share_key?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "case_doc_checklists" */
export type Case_Doc_Checklists_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "case_doc_checklists" */
export enum Case_Doc_Checklists_Select_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  Changed = 'changed',
  /** column name */
  Closing = 'closing',
  /** column name */
  Created = 'created',
  /** column name */
  DocumentDate = 'document_date',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Introduction = 'introduction',
  /** column name */
  RevisionDate = 'revision_date',
  /** column name */
  ShareKey = 'share_key',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "case_doc_checklists" */
export type Case_Doc_Checklists_Set_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['String']>;
  closing?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  document_date?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  introduction?: Maybe<Scalars['String']>;
  revision_date?: Maybe<Scalars['String']>;
  share_key?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "case_doc_checklists" */
export enum Case_Doc_Checklists_Update_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  Changed = 'changed',
  /** column name */
  Closing = 'closing',
  /** column name */
  Created = 'created',
  /** column name */
  DocumentDate = 'document_date',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Introduction = 'introduction',
  /** column name */
  RevisionDate = 'revision_date',
  /** column name */
  ShareKey = 'share_key',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "case_doc_economy" */
export type Case_Doc_Economy = {
  __typename?: 'case_doc_economy';
  /** An object relationship */
  case: Cases;
  case_id: Scalars['uuid'];
  changed: Scalars['String'];
  created: Scalars['String'];
  document_date?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  revision_date?: Maybe<Scalars['String']>;
  share_key: Scalars['uuid'];
  text?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['String'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "case_doc_economy" */
export type Case_Doc_Economy_Aggregate = {
  __typename?: 'case_doc_economy_aggregate';
  aggregate?: Maybe<Case_Doc_Economy_Aggregate_Fields>;
  nodes: Array<Case_Doc_Economy>;
};

/** aggregate fields of "case_doc_economy" */
export type Case_Doc_Economy_Aggregate_Fields = {
  __typename?: 'case_doc_economy_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Case_Doc_Economy_Max_Fields>;
  min?: Maybe<Case_Doc_Economy_Min_Fields>;
};


/** aggregate fields of "case_doc_economy" */
export type Case_Doc_Economy_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Case_Doc_Economy_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "case_doc_economy" */
export type Case_Doc_Economy_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Case_Doc_Economy_Max_Order_By>;
  min?: Maybe<Case_Doc_Economy_Min_Order_By>;
};

/** input type for inserting array relation for remote table "case_doc_economy" */
export type Case_Doc_Economy_Arr_Rel_Insert_Input = {
  data: Array<Case_Doc_Economy_Insert_Input>;
  on_conflict?: Maybe<Case_Doc_Economy_On_Conflict>;
};

/** Boolean expression to filter rows from the table "case_doc_economy". All fields are combined with a logical 'AND'. */
export type Case_Doc_Economy_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Case_Doc_Economy_Bool_Exp>>>;
  _not?: Maybe<Case_Doc_Economy_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Case_Doc_Economy_Bool_Exp>>>;
  case?: Maybe<Cases_Bool_Exp>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  changed?: Maybe<String_Comparison_Exp>;
  created?: Maybe<String_Comparison_Exp>;
  document_date?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  revision_date?: Maybe<String_Comparison_Exp>;
  share_key?: Maybe<Uuid_Comparison_Exp>;
  text?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_doc_economy" */
export enum Case_Doc_Economy_Constraint {
  /** unique or primary key constraint */
  CaseDocEconomyPkey = 'case_doc_economy_pkey'
}

/** input type for inserting data into table "case_doc_economy" */
export type Case_Doc_Economy_Insert_Input = {
  case?: Maybe<Cases_Obj_Rel_Insert_Input>;
  case_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  document_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  revision_date?: Maybe<Scalars['String']>;
  share_key?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Case_Doc_Economy_Max_Fields = {
  __typename?: 'case_doc_economy_max_fields';
  case_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  document_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  revision_date?: Maybe<Scalars['String']>;
  share_key?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "case_doc_economy" */
export type Case_Doc_Economy_Max_Order_By = {
  case_id?: Maybe<Order_By>;
  changed?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  document_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  revision_date?: Maybe<Order_By>;
  share_key?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Case_Doc_Economy_Min_Fields = {
  __typename?: 'case_doc_economy_min_fields';
  case_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  document_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  revision_date?: Maybe<Scalars['String']>;
  share_key?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "case_doc_economy" */
export type Case_Doc_Economy_Min_Order_By = {
  case_id?: Maybe<Order_By>;
  changed?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  document_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  revision_date?: Maybe<Order_By>;
  share_key?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "case_doc_economy" */
export type Case_Doc_Economy_Mutation_Response = {
  __typename?: 'case_doc_economy_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Case_Doc_Economy>;
};

/** input type for inserting object relation for remote table "case_doc_economy" */
export type Case_Doc_Economy_Obj_Rel_Insert_Input = {
  data: Case_Doc_Economy_Insert_Input;
  on_conflict?: Maybe<Case_Doc_Economy_On_Conflict>;
};

/** on conflict condition type for table "case_doc_economy" */
export type Case_Doc_Economy_On_Conflict = {
  constraint: Case_Doc_Economy_Constraint;
  update_columns: Array<Case_Doc_Economy_Update_Column>;
  where?: Maybe<Case_Doc_Economy_Bool_Exp>;
};

/** ordering options when selecting data from "case_doc_economy" */
export type Case_Doc_Economy_Order_By = {
  case?: Maybe<Cases_Order_By>;
  case_id?: Maybe<Order_By>;
  changed?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  document_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  revision_date?: Maybe<Order_By>;
  share_key?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "case_doc_economy" */
export type Case_Doc_Economy_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "case_doc_economy" */
export enum Case_Doc_Economy_Select_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  Changed = 'changed',
  /** column name */
  Created = 'created',
  /** column name */
  DocumentDate = 'document_date',
  /** column name */
  Id = 'id',
  /** column name */
  RevisionDate = 'revision_date',
  /** column name */
  ShareKey = 'share_key',
  /** column name */
  Text = 'text',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "case_doc_economy" */
export type Case_Doc_Economy_Set_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  document_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  revision_date?: Maybe<Scalars['String']>;
  share_key?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "case_doc_economy" */
export enum Case_Doc_Economy_Update_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  Changed = 'changed',
  /** column name */
  Created = 'created',
  /** column name */
  DocumentDate = 'document_date',
  /** column name */
  Id = 'id',
  /** column name */
  RevisionDate = 'revision_date',
  /** column name */
  ShareKey = 'share_key',
  /** column name */
  Text = 'text',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "case_doc_notes" */
export type Case_Doc_Notes = {
  __typename?: 'case_doc_notes';
  /** An object relationship */
  case: Cases;
  case_id: Scalars['uuid'];
  changed: Scalars['String'];
  created: Scalars['String'];
  document_date?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  revision_date?: Maybe<Scalars['String']>;
  share_key: Scalars['uuid'];
  text?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['String'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "case_doc_notes" */
export type Case_Doc_Notes_Aggregate = {
  __typename?: 'case_doc_notes_aggregate';
  aggregate?: Maybe<Case_Doc_Notes_Aggregate_Fields>;
  nodes: Array<Case_Doc_Notes>;
};

/** aggregate fields of "case_doc_notes" */
export type Case_Doc_Notes_Aggregate_Fields = {
  __typename?: 'case_doc_notes_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Case_Doc_Notes_Max_Fields>;
  min?: Maybe<Case_Doc_Notes_Min_Fields>;
};


/** aggregate fields of "case_doc_notes" */
export type Case_Doc_Notes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Case_Doc_Notes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "case_doc_notes" */
export type Case_Doc_Notes_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Case_Doc_Notes_Max_Order_By>;
  min?: Maybe<Case_Doc_Notes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "case_doc_notes" */
export type Case_Doc_Notes_Arr_Rel_Insert_Input = {
  data: Array<Case_Doc_Notes_Insert_Input>;
  on_conflict?: Maybe<Case_Doc_Notes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "case_doc_notes". All fields are combined with a logical 'AND'. */
export type Case_Doc_Notes_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Case_Doc_Notes_Bool_Exp>>>;
  _not?: Maybe<Case_Doc_Notes_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Case_Doc_Notes_Bool_Exp>>>;
  case?: Maybe<Cases_Bool_Exp>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  changed?: Maybe<String_Comparison_Exp>;
  created?: Maybe<String_Comparison_Exp>;
  document_date?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  revision_date?: Maybe<String_Comparison_Exp>;
  share_key?: Maybe<Uuid_Comparison_Exp>;
  text?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_doc_notes" */
export enum Case_Doc_Notes_Constraint {
  /** unique or primary key constraint */
  CaseDocNotesPkey = 'case_doc_notes_pkey'
}

/** input type for inserting data into table "case_doc_notes" */
export type Case_Doc_Notes_Insert_Input = {
  case?: Maybe<Cases_Obj_Rel_Insert_Input>;
  case_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  document_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  revision_date?: Maybe<Scalars['String']>;
  share_key?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Case_Doc_Notes_Max_Fields = {
  __typename?: 'case_doc_notes_max_fields';
  case_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  document_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  revision_date?: Maybe<Scalars['String']>;
  share_key?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "case_doc_notes" */
export type Case_Doc_Notes_Max_Order_By = {
  case_id?: Maybe<Order_By>;
  changed?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  document_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  revision_date?: Maybe<Order_By>;
  share_key?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Case_Doc_Notes_Min_Fields = {
  __typename?: 'case_doc_notes_min_fields';
  case_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  document_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  revision_date?: Maybe<Scalars['String']>;
  share_key?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "case_doc_notes" */
export type Case_Doc_Notes_Min_Order_By = {
  case_id?: Maybe<Order_By>;
  changed?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  document_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  revision_date?: Maybe<Order_By>;
  share_key?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "case_doc_notes" */
export type Case_Doc_Notes_Mutation_Response = {
  __typename?: 'case_doc_notes_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Case_Doc_Notes>;
};

/** input type for inserting object relation for remote table "case_doc_notes" */
export type Case_Doc_Notes_Obj_Rel_Insert_Input = {
  data: Case_Doc_Notes_Insert_Input;
  on_conflict?: Maybe<Case_Doc_Notes_On_Conflict>;
};

/** on conflict condition type for table "case_doc_notes" */
export type Case_Doc_Notes_On_Conflict = {
  constraint: Case_Doc_Notes_Constraint;
  update_columns: Array<Case_Doc_Notes_Update_Column>;
  where?: Maybe<Case_Doc_Notes_Bool_Exp>;
};

/** ordering options when selecting data from "case_doc_notes" */
export type Case_Doc_Notes_Order_By = {
  case?: Maybe<Cases_Order_By>;
  case_id?: Maybe<Order_By>;
  changed?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  document_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  revision_date?: Maybe<Order_By>;
  share_key?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "case_doc_notes" */
export type Case_Doc_Notes_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "case_doc_notes" */
export enum Case_Doc_Notes_Select_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  Changed = 'changed',
  /** column name */
  Created = 'created',
  /** column name */
  DocumentDate = 'document_date',
  /** column name */
  Id = 'id',
  /** column name */
  RevisionDate = 'revision_date',
  /** column name */
  ShareKey = 'share_key',
  /** column name */
  Text = 'text',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "case_doc_notes" */
export type Case_Doc_Notes_Set_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  document_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  revision_date?: Maybe<Scalars['String']>;
  share_key?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "case_doc_notes" */
export enum Case_Doc_Notes_Update_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  Changed = 'changed',
  /** column name */
  Created = 'created',
  /** column name */
  DocumentDate = 'document_date',
  /** column name */
  Id = 'id',
  /** column name */
  RevisionDate = 'revision_date',
  /** column name */
  ShareKey = 'share_key',
  /** column name */
  Text = 'text',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "case_doc_order_confirmations" */
export type Case_Doc_Order_Confirmations = {
  __typename?: 'case_doc_order_confirmations';
  /** An object relationship */
  case: Cases;
  case_id: Scalars['uuid'];
  changed: Scalars['String'];
  created: Scalars['String'];
  description: Scalars['String'];
  document_date: Scalars['String'];
  drawings_date?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  other_terms?: Maybe<Scalars['String']>;
  revision_date?: Maybe<Scalars['String']>;
  share_key: Scalars['uuid'];
  /** An array relationship */
  tasks: Array<Order_Confirmation_Tasks>;
  /** An aggregated array relationship */
  tasks_aggregate: Order_Confirmation_Tasks_Aggregate;
  title: Scalars['String'];
  type: Scalars['String'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
  work_ends_date?: Maybe<Scalars['String']>;
  work_starts_date?: Maybe<Scalars['String']>;
};


/** columns and relationships of "case_doc_order_confirmations" */
export type Case_Doc_Order_ConfirmationsTasksArgs = {
  distinct_on?: Maybe<Array<Order_Confirmation_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Confirmation_Tasks_Order_By>>;
  where?: Maybe<Order_Confirmation_Tasks_Bool_Exp>;
};


/** columns and relationships of "case_doc_order_confirmations" */
export type Case_Doc_Order_ConfirmationsTasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Confirmation_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Confirmation_Tasks_Order_By>>;
  where?: Maybe<Order_Confirmation_Tasks_Bool_Exp>;
};

/** aggregated selection of "case_doc_order_confirmations" */
export type Case_Doc_Order_Confirmations_Aggregate = {
  __typename?: 'case_doc_order_confirmations_aggregate';
  aggregate?: Maybe<Case_Doc_Order_Confirmations_Aggregate_Fields>;
  nodes: Array<Case_Doc_Order_Confirmations>;
};

/** aggregate fields of "case_doc_order_confirmations" */
export type Case_Doc_Order_Confirmations_Aggregate_Fields = {
  __typename?: 'case_doc_order_confirmations_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Case_Doc_Order_Confirmations_Max_Fields>;
  min?: Maybe<Case_Doc_Order_Confirmations_Min_Fields>;
};


/** aggregate fields of "case_doc_order_confirmations" */
export type Case_Doc_Order_Confirmations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Case_Doc_Order_Confirmations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "case_doc_order_confirmations" */
export type Case_Doc_Order_Confirmations_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Case_Doc_Order_Confirmations_Max_Order_By>;
  min?: Maybe<Case_Doc_Order_Confirmations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "case_doc_order_confirmations" */
export type Case_Doc_Order_Confirmations_Arr_Rel_Insert_Input = {
  data: Array<Case_Doc_Order_Confirmations_Insert_Input>;
  on_conflict?: Maybe<Case_Doc_Order_Confirmations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "case_doc_order_confirmations". All fields are combined with a logical 'AND'. */
export type Case_Doc_Order_Confirmations_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Case_Doc_Order_Confirmations_Bool_Exp>>>;
  _not?: Maybe<Case_Doc_Order_Confirmations_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Case_Doc_Order_Confirmations_Bool_Exp>>>;
  case?: Maybe<Cases_Bool_Exp>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  changed?: Maybe<String_Comparison_Exp>;
  created?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  document_date?: Maybe<String_Comparison_Exp>;
  drawings_date?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  other_terms?: Maybe<String_Comparison_Exp>;
  revision_date?: Maybe<String_Comparison_Exp>;
  share_key?: Maybe<Uuid_Comparison_Exp>;
  tasks?: Maybe<Order_Confirmation_Tasks_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
  work_ends_date?: Maybe<String_Comparison_Exp>;
  work_starts_date?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_doc_order_confirmations" */
export enum Case_Doc_Order_Confirmations_Constraint {
  /** unique or primary key constraint */
  CaseDocOrderConfirmationsPkey = 'case_doc_order_confirmations_pkey'
}

/** input type for inserting data into table "case_doc_order_confirmations" */
export type Case_Doc_Order_Confirmations_Insert_Input = {
  case?: Maybe<Cases_Obj_Rel_Insert_Input>;
  case_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  document_date?: Maybe<Scalars['String']>;
  drawings_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  other_terms?: Maybe<Scalars['String']>;
  revision_date?: Maybe<Scalars['String']>;
  share_key?: Maybe<Scalars['uuid']>;
  tasks?: Maybe<Order_Confirmation_Tasks_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
  work_ends_date?: Maybe<Scalars['String']>;
  work_starts_date?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Case_Doc_Order_Confirmations_Max_Fields = {
  __typename?: 'case_doc_order_confirmations_max_fields';
  case_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  document_date?: Maybe<Scalars['String']>;
  drawings_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  other_terms?: Maybe<Scalars['String']>;
  revision_date?: Maybe<Scalars['String']>;
  share_key?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  work_ends_date?: Maybe<Scalars['String']>;
  work_starts_date?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "case_doc_order_confirmations" */
export type Case_Doc_Order_Confirmations_Max_Order_By = {
  case_id?: Maybe<Order_By>;
  changed?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  document_date?: Maybe<Order_By>;
  drawings_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  other_terms?: Maybe<Order_By>;
  revision_date?: Maybe<Order_By>;
  share_key?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  work_ends_date?: Maybe<Order_By>;
  work_starts_date?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Case_Doc_Order_Confirmations_Min_Fields = {
  __typename?: 'case_doc_order_confirmations_min_fields';
  case_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  document_date?: Maybe<Scalars['String']>;
  drawings_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  other_terms?: Maybe<Scalars['String']>;
  revision_date?: Maybe<Scalars['String']>;
  share_key?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  work_ends_date?: Maybe<Scalars['String']>;
  work_starts_date?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "case_doc_order_confirmations" */
export type Case_Doc_Order_Confirmations_Min_Order_By = {
  case_id?: Maybe<Order_By>;
  changed?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  document_date?: Maybe<Order_By>;
  drawings_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  other_terms?: Maybe<Order_By>;
  revision_date?: Maybe<Order_By>;
  share_key?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  work_ends_date?: Maybe<Order_By>;
  work_starts_date?: Maybe<Order_By>;
};

/** response of any mutation on the table "case_doc_order_confirmations" */
export type Case_Doc_Order_Confirmations_Mutation_Response = {
  __typename?: 'case_doc_order_confirmations_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Case_Doc_Order_Confirmations>;
};

/** input type for inserting object relation for remote table "case_doc_order_confirmations" */
export type Case_Doc_Order_Confirmations_Obj_Rel_Insert_Input = {
  data: Case_Doc_Order_Confirmations_Insert_Input;
  on_conflict?: Maybe<Case_Doc_Order_Confirmations_On_Conflict>;
};

/** on conflict condition type for table "case_doc_order_confirmations" */
export type Case_Doc_Order_Confirmations_On_Conflict = {
  constraint: Case_Doc_Order_Confirmations_Constraint;
  update_columns: Array<Case_Doc_Order_Confirmations_Update_Column>;
  where?: Maybe<Case_Doc_Order_Confirmations_Bool_Exp>;
};

/** ordering options when selecting data from "case_doc_order_confirmations" */
export type Case_Doc_Order_Confirmations_Order_By = {
  case?: Maybe<Cases_Order_By>;
  case_id?: Maybe<Order_By>;
  changed?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  document_date?: Maybe<Order_By>;
  drawings_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  other_terms?: Maybe<Order_By>;
  revision_date?: Maybe<Order_By>;
  share_key?: Maybe<Order_By>;
  tasks_aggregate?: Maybe<Order_Confirmation_Tasks_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
  work_ends_date?: Maybe<Order_By>;
  work_starts_date?: Maybe<Order_By>;
};

/** primary key columns input for table: "case_doc_order_confirmations" */
export type Case_Doc_Order_Confirmations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "case_doc_order_confirmations" */
export enum Case_Doc_Order_Confirmations_Select_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  Changed = 'changed',
  /** column name */
  Created = 'created',
  /** column name */
  Description = 'description',
  /** column name */
  DocumentDate = 'document_date',
  /** column name */
  DrawingsDate = 'drawings_date',
  /** column name */
  Id = 'id',
  /** column name */
  OtherTerms = 'other_terms',
  /** column name */
  RevisionDate = 'revision_date',
  /** column name */
  ShareKey = 'share_key',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WorkEndsDate = 'work_ends_date',
  /** column name */
  WorkStartsDate = 'work_starts_date'
}

/** input type for updating data in table "case_doc_order_confirmations" */
export type Case_Doc_Order_Confirmations_Set_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  document_date?: Maybe<Scalars['String']>;
  drawings_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  other_terms?: Maybe<Scalars['String']>;
  revision_date?: Maybe<Scalars['String']>;
  share_key?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
  work_ends_date?: Maybe<Scalars['String']>;
  work_starts_date?: Maybe<Scalars['String']>;
};

/** update columns of table "case_doc_order_confirmations" */
export enum Case_Doc_Order_Confirmations_Update_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  Changed = 'changed',
  /** column name */
  Created = 'created',
  /** column name */
  Description = 'description',
  /** column name */
  DocumentDate = 'document_date',
  /** column name */
  DrawingsDate = 'drawings_date',
  /** column name */
  Id = 'id',
  /** column name */
  OtherTerms = 'other_terms',
  /** column name */
  RevisionDate = 'revision_date',
  /** column name */
  ShareKey = 'share_key',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WorkEndsDate = 'work_ends_date',
  /** column name */
  WorkStartsDate = 'work_starts_date'
}

/** columns and relationships of "case_doc_quality_controls" */
export type Case_Doc_Quality_Controls = {
  __typename?: 'case_doc_quality_controls';
  /** An object relationship */
  case: Cases;
  case_id: Scalars['uuid'];
  changed: Scalars['String'];
  created: Scalars['String'];
  date_of_inspection: Scalars['String'];
  document_date: Scalars['String'];
  id: Scalars['uuid'];
  revision_date?: Maybe<Scalars['String']>;
  share_key: Scalars['uuid'];
  /** An array relationship */
  tasks: Array<Quality_Control_Tasks>;
  /** An aggregated array relationship */
  tasks_aggregate: Quality_Control_Tasks_Aggregate;
  title: Scalars['String'];
  type: Scalars['String'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "case_doc_quality_controls" */
export type Case_Doc_Quality_ControlsTasksArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Tasks_Order_By>>;
  where?: Maybe<Quality_Control_Tasks_Bool_Exp>;
};


/** columns and relationships of "case_doc_quality_controls" */
export type Case_Doc_Quality_ControlsTasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Tasks_Order_By>>;
  where?: Maybe<Quality_Control_Tasks_Bool_Exp>;
};

/** aggregated selection of "case_doc_quality_controls" */
export type Case_Doc_Quality_Controls_Aggregate = {
  __typename?: 'case_doc_quality_controls_aggregate';
  aggregate?: Maybe<Case_Doc_Quality_Controls_Aggregate_Fields>;
  nodes: Array<Case_Doc_Quality_Controls>;
};

/** aggregate fields of "case_doc_quality_controls" */
export type Case_Doc_Quality_Controls_Aggregate_Fields = {
  __typename?: 'case_doc_quality_controls_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Case_Doc_Quality_Controls_Max_Fields>;
  min?: Maybe<Case_Doc_Quality_Controls_Min_Fields>;
};


/** aggregate fields of "case_doc_quality_controls" */
export type Case_Doc_Quality_Controls_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Case_Doc_Quality_Controls_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "case_doc_quality_controls" */
export type Case_Doc_Quality_Controls_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Case_Doc_Quality_Controls_Max_Order_By>;
  min?: Maybe<Case_Doc_Quality_Controls_Min_Order_By>;
};

/** input type for inserting array relation for remote table "case_doc_quality_controls" */
export type Case_Doc_Quality_Controls_Arr_Rel_Insert_Input = {
  data: Array<Case_Doc_Quality_Controls_Insert_Input>;
  on_conflict?: Maybe<Case_Doc_Quality_Controls_On_Conflict>;
};

/** Boolean expression to filter rows from the table "case_doc_quality_controls". All fields are combined with a logical 'AND'. */
export type Case_Doc_Quality_Controls_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Case_Doc_Quality_Controls_Bool_Exp>>>;
  _not?: Maybe<Case_Doc_Quality_Controls_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Case_Doc_Quality_Controls_Bool_Exp>>>;
  case?: Maybe<Cases_Bool_Exp>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  changed?: Maybe<String_Comparison_Exp>;
  created?: Maybe<String_Comparison_Exp>;
  date_of_inspection?: Maybe<String_Comparison_Exp>;
  document_date?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  revision_date?: Maybe<String_Comparison_Exp>;
  share_key?: Maybe<Uuid_Comparison_Exp>;
  tasks?: Maybe<Quality_Control_Tasks_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_doc_quality_controls" */
export enum Case_Doc_Quality_Controls_Constraint {
  /** unique or primary key constraint */
  CaseDocQualityControlsPkey = 'case_doc_quality_controls_pkey'
}

/** input type for inserting data into table "case_doc_quality_controls" */
export type Case_Doc_Quality_Controls_Insert_Input = {
  case?: Maybe<Cases_Obj_Rel_Insert_Input>;
  case_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  date_of_inspection?: Maybe<Scalars['String']>;
  document_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  revision_date?: Maybe<Scalars['String']>;
  share_key?: Maybe<Scalars['uuid']>;
  tasks?: Maybe<Quality_Control_Tasks_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Case_Doc_Quality_Controls_Max_Fields = {
  __typename?: 'case_doc_quality_controls_max_fields';
  case_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  date_of_inspection?: Maybe<Scalars['String']>;
  document_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  revision_date?: Maybe<Scalars['String']>;
  share_key?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "case_doc_quality_controls" */
export type Case_Doc_Quality_Controls_Max_Order_By = {
  case_id?: Maybe<Order_By>;
  changed?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  date_of_inspection?: Maybe<Order_By>;
  document_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  revision_date?: Maybe<Order_By>;
  share_key?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Case_Doc_Quality_Controls_Min_Fields = {
  __typename?: 'case_doc_quality_controls_min_fields';
  case_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  date_of_inspection?: Maybe<Scalars['String']>;
  document_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  revision_date?: Maybe<Scalars['String']>;
  share_key?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "case_doc_quality_controls" */
export type Case_Doc_Quality_Controls_Min_Order_By = {
  case_id?: Maybe<Order_By>;
  changed?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  date_of_inspection?: Maybe<Order_By>;
  document_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  revision_date?: Maybe<Order_By>;
  share_key?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "case_doc_quality_controls" */
export type Case_Doc_Quality_Controls_Mutation_Response = {
  __typename?: 'case_doc_quality_controls_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Case_Doc_Quality_Controls>;
};

/** input type for inserting object relation for remote table "case_doc_quality_controls" */
export type Case_Doc_Quality_Controls_Obj_Rel_Insert_Input = {
  data: Case_Doc_Quality_Controls_Insert_Input;
  on_conflict?: Maybe<Case_Doc_Quality_Controls_On_Conflict>;
};

/** on conflict condition type for table "case_doc_quality_controls" */
export type Case_Doc_Quality_Controls_On_Conflict = {
  constraint: Case_Doc_Quality_Controls_Constraint;
  update_columns: Array<Case_Doc_Quality_Controls_Update_Column>;
  where?: Maybe<Case_Doc_Quality_Controls_Bool_Exp>;
};

/** ordering options when selecting data from "case_doc_quality_controls" */
export type Case_Doc_Quality_Controls_Order_By = {
  case?: Maybe<Cases_Order_By>;
  case_id?: Maybe<Order_By>;
  changed?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  date_of_inspection?: Maybe<Order_By>;
  document_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  revision_date?: Maybe<Order_By>;
  share_key?: Maybe<Order_By>;
  tasks_aggregate?: Maybe<Quality_Control_Tasks_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "case_doc_quality_controls" */
export type Case_Doc_Quality_Controls_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "case_doc_quality_controls" */
export enum Case_Doc_Quality_Controls_Select_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  Changed = 'changed',
  /** column name */
  Created = 'created',
  /** column name */
  DateOfInspection = 'date_of_inspection',
  /** column name */
  DocumentDate = 'document_date',
  /** column name */
  Id = 'id',
  /** column name */
  RevisionDate = 'revision_date',
  /** column name */
  ShareKey = 'share_key',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "case_doc_quality_controls" */
export type Case_Doc_Quality_Controls_Set_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  date_of_inspection?: Maybe<Scalars['String']>;
  document_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  revision_date?: Maybe<Scalars['String']>;
  share_key?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "case_doc_quality_controls" */
export enum Case_Doc_Quality_Controls_Update_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  Changed = 'changed',
  /** column name */
  Created = 'created',
  /** column name */
  DateOfInspection = 'date_of_inspection',
  /** column name */
  DocumentDate = 'document_date',
  /** column name */
  Id = 'id',
  /** column name */
  RevisionDate = 'revision_date',
  /** column name */
  ShareKey = 'share_key',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "case_files" */
export type Case_Files = {
  __typename?: 'case_files';
  case_id: Scalars['uuid'];
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid'];
  id: Scalars['uuid'];
  title: Scalars['String'];
};

/** aggregated selection of "case_files" */
export type Case_Files_Aggregate = {
  __typename?: 'case_files_aggregate';
  aggregate?: Maybe<Case_Files_Aggregate_Fields>;
  nodes: Array<Case_Files>;
};

/** aggregate fields of "case_files" */
export type Case_Files_Aggregate_Fields = {
  __typename?: 'case_files_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Case_Files_Max_Fields>;
  min?: Maybe<Case_Files_Min_Fields>;
};


/** aggregate fields of "case_files" */
export type Case_Files_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Case_Files_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "case_files" */
export type Case_Files_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Case_Files_Max_Order_By>;
  min?: Maybe<Case_Files_Min_Order_By>;
};

/** input type for inserting array relation for remote table "case_files" */
export type Case_Files_Arr_Rel_Insert_Input = {
  data: Array<Case_Files_Insert_Input>;
  on_conflict?: Maybe<Case_Files_On_Conflict>;
};

/** Boolean expression to filter rows from the table "case_files". All fields are combined with a logical 'AND'. */
export type Case_Files_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Case_Files_Bool_Exp>>>;
  _not?: Maybe<Case_Files_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Case_Files_Bool_Exp>>>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  file?: Maybe<Files_Bool_Exp>;
  file_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_files" */
export enum Case_Files_Constraint {
  /** unique or primary key constraint */
  CaseFilesPkey = 'case_files_pkey'
}

/** input type for inserting data into table "case_files" */
export type Case_Files_Insert_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  file?: Maybe<Files_Obj_Rel_Insert_Input>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Case_Files_Max_Fields = {
  __typename?: 'case_files_max_fields';
  case_id?: Maybe<Scalars['uuid']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "case_files" */
export type Case_Files_Max_Order_By = {
  case_id?: Maybe<Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Case_Files_Min_Fields = {
  __typename?: 'case_files_min_fields';
  case_id?: Maybe<Scalars['uuid']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "case_files" */
export type Case_Files_Min_Order_By = {
  case_id?: Maybe<Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "case_files" */
export type Case_Files_Mutation_Response = {
  __typename?: 'case_files_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Case_Files>;
};

/** input type for inserting object relation for remote table "case_files" */
export type Case_Files_Obj_Rel_Insert_Input = {
  data: Case_Files_Insert_Input;
  on_conflict?: Maybe<Case_Files_On_Conflict>;
};

/** on conflict condition type for table "case_files" */
export type Case_Files_On_Conflict = {
  constraint: Case_Files_Constraint;
  update_columns: Array<Case_Files_Update_Column>;
  where?: Maybe<Case_Files_Bool_Exp>;
};

/** ordering options when selecting data from "case_files" */
export type Case_Files_Order_By = {
  case_id?: Maybe<Order_By>;
  file?: Maybe<Files_Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: "case_files" */
export type Case_Files_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "case_files" */
export enum Case_Files_Select_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "case_files" */
export type Case_Files_Set_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** update columns of table "case_files" */
export enum Case_Files_Update_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

/** columns and relationships of "case_photos" */
export type Case_Photos = {
  __typename?: 'case_photos';
  case_id: Scalars['uuid'];
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid'];
  id: Scalars['uuid'];
  title: Scalars['String'];
};

/** aggregated selection of "case_photos" */
export type Case_Photos_Aggregate = {
  __typename?: 'case_photos_aggregate';
  aggregate?: Maybe<Case_Photos_Aggregate_Fields>;
  nodes: Array<Case_Photos>;
};

/** aggregate fields of "case_photos" */
export type Case_Photos_Aggregate_Fields = {
  __typename?: 'case_photos_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Case_Photos_Max_Fields>;
  min?: Maybe<Case_Photos_Min_Fields>;
};


/** aggregate fields of "case_photos" */
export type Case_Photos_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Case_Photos_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "case_photos" */
export type Case_Photos_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Case_Photos_Max_Order_By>;
  min?: Maybe<Case_Photos_Min_Order_By>;
};

/** input type for inserting array relation for remote table "case_photos" */
export type Case_Photos_Arr_Rel_Insert_Input = {
  data: Array<Case_Photos_Insert_Input>;
  on_conflict?: Maybe<Case_Photos_On_Conflict>;
};

/** Boolean expression to filter rows from the table "case_photos". All fields are combined with a logical 'AND'. */
export type Case_Photos_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Case_Photos_Bool_Exp>>>;
  _not?: Maybe<Case_Photos_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Case_Photos_Bool_Exp>>>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  file?: Maybe<Files_Bool_Exp>;
  file_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_photos" */
export enum Case_Photos_Constraint {
  /** unique or primary key constraint */
  CasePhotosPkey = 'case_photos_pkey'
}

/** input type for inserting data into table "case_photos" */
export type Case_Photos_Insert_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  file?: Maybe<Files_Obj_Rel_Insert_Input>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Case_Photos_Max_Fields = {
  __typename?: 'case_photos_max_fields';
  case_id?: Maybe<Scalars['uuid']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "case_photos" */
export type Case_Photos_Max_Order_By = {
  case_id?: Maybe<Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Case_Photos_Min_Fields = {
  __typename?: 'case_photos_min_fields';
  case_id?: Maybe<Scalars['uuid']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "case_photos" */
export type Case_Photos_Min_Order_By = {
  case_id?: Maybe<Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "case_photos" */
export type Case_Photos_Mutation_Response = {
  __typename?: 'case_photos_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Case_Photos>;
};

/** input type for inserting object relation for remote table "case_photos" */
export type Case_Photos_Obj_Rel_Insert_Input = {
  data: Case_Photos_Insert_Input;
  on_conflict?: Maybe<Case_Photos_On_Conflict>;
};

/** on conflict condition type for table "case_photos" */
export type Case_Photos_On_Conflict = {
  constraint: Case_Photos_Constraint;
  update_columns: Array<Case_Photos_Update_Column>;
  where?: Maybe<Case_Photos_Bool_Exp>;
};

/** ordering options when selecting data from "case_photos" */
export type Case_Photos_Order_By = {
  case_id?: Maybe<Order_By>;
  file?: Maybe<Files_Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: "case_photos" */
export type Case_Photos_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "case_photos" */
export enum Case_Photos_Select_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "case_photos" */
export type Case_Photos_Set_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** update columns of table "case_photos" */
export enum Case_Photos_Update_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

/** columns and relationships of "case_suppliers" */
export type Case_Suppliers = {
  __typename?: 'case_suppliers';
  /** An object relationship */
  case: Cases;
  case_id: Scalars['uuid'];
  /** An object relationship */
  contact: Contacts;
  contact_id: Scalars['uuid'];
  id: Scalars['uuid'];
};

/** aggregated selection of "case_suppliers" */
export type Case_Suppliers_Aggregate = {
  __typename?: 'case_suppliers_aggregate';
  aggregate?: Maybe<Case_Suppliers_Aggregate_Fields>;
  nodes: Array<Case_Suppliers>;
};

/** aggregate fields of "case_suppliers" */
export type Case_Suppliers_Aggregate_Fields = {
  __typename?: 'case_suppliers_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Case_Suppliers_Max_Fields>;
  min?: Maybe<Case_Suppliers_Min_Fields>;
};


/** aggregate fields of "case_suppliers" */
export type Case_Suppliers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Case_Suppliers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "case_suppliers" */
export type Case_Suppliers_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Case_Suppliers_Max_Order_By>;
  min?: Maybe<Case_Suppliers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "case_suppliers" */
export type Case_Suppliers_Arr_Rel_Insert_Input = {
  data: Array<Case_Suppliers_Insert_Input>;
  on_conflict?: Maybe<Case_Suppliers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "case_suppliers". All fields are combined with a logical 'AND'. */
export type Case_Suppliers_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Case_Suppliers_Bool_Exp>>>;
  _not?: Maybe<Case_Suppliers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Case_Suppliers_Bool_Exp>>>;
  case?: Maybe<Cases_Bool_Exp>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  contact?: Maybe<Contacts_Bool_Exp>;
  contact_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_suppliers" */
export enum Case_Suppliers_Constraint {
  /** unique or primary key constraint */
  CaseSuppliersPkey = 'case_suppliers_pkey'
}

/** input type for inserting data into table "case_suppliers" */
export type Case_Suppliers_Insert_Input = {
  case?: Maybe<Cases_Obj_Rel_Insert_Input>;
  case_id?: Maybe<Scalars['uuid']>;
  contact?: Maybe<Contacts_Obj_Rel_Insert_Input>;
  contact_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Case_Suppliers_Max_Fields = {
  __typename?: 'case_suppliers_max_fields';
  case_id?: Maybe<Scalars['uuid']>;
  contact_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "case_suppliers" */
export type Case_Suppliers_Max_Order_By = {
  case_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Case_Suppliers_Min_Fields = {
  __typename?: 'case_suppliers_min_fields';
  case_id?: Maybe<Scalars['uuid']>;
  contact_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "case_suppliers" */
export type Case_Suppliers_Min_Order_By = {
  case_id?: Maybe<Order_By>;
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "case_suppliers" */
export type Case_Suppliers_Mutation_Response = {
  __typename?: 'case_suppliers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Case_Suppliers>;
};

/** input type for inserting object relation for remote table "case_suppliers" */
export type Case_Suppliers_Obj_Rel_Insert_Input = {
  data: Case_Suppliers_Insert_Input;
  on_conflict?: Maybe<Case_Suppliers_On_Conflict>;
};

/** on conflict condition type for table "case_suppliers" */
export type Case_Suppliers_On_Conflict = {
  constraint: Case_Suppliers_Constraint;
  update_columns: Array<Case_Suppliers_Update_Column>;
  where?: Maybe<Case_Suppliers_Bool_Exp>;
};

/** ordering options when selecting data from "case_suppliers" */
export type Case_Suppliers_Order_By = {
  case?: Maybe<Cases_Order_By>;
  case_id?: Maybe<Order_By>;
  contact?: Maybe<Contacts_Order_By>;
  contact_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: "case_suppliers" */
export type Case_Suppliers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "case_suppliers" */
export enum Case_Suppliers_Select_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "case_suppliers" */
export type Case_Suppliers_Set_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  contact_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "case_suppliers" */
export enum Case_Suppliers_Update_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  Id = 'id'
}

/** columns and relationships of "case_time_entries" */
export type Case_Time_Entries = {
  __typename?: 'case_time_entries';
  /** An object relationship */
  case: Cases;
  case_id: Scalars['uuid'];
  date: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  time: Scalars['numeric'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "case_time_entries" */
export type Case_Time_Entries_Aggregate = {
  __typename?: 'case_time_entries_aggregate';
  aggregate?: Maybe<Case_Time_Entries_Aggregate_Fields>;
  nodes: Array<Case_Time_Entries>;
};

/** aggregate fields of "case_time_entries" */
export type Case_Time_Entries_Aggregate_Fields = {
  __typename?: 'case_time_entries_aggregate_fields';
  avg?: Maybe<Case_Time_Entries_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Case_Time_Entries_Max_Fields>;
  min?: Maybe<Case_Time_Entries_Min_Fields>;
  stddev?: Maybe<Case_Time_Entries_Stddev_Fields>;
  stddev_pop?: Maybe<Case_Time_Entries_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Case_Time_Entries_Stddev_Samp_Fields>;
  sum?: Maybe<Case_Time_Entries_Sum_Fields>;
  var_pop?: Maybe<Case_Time_Entries_Var_Pop_Fields>;
  var_samp?: Maybe<Case_Time_Entries_Var_Samp_Fields>;
  variance?: Maybe<Case_Time_Entries_Variance_Fields>;
};


/** aggregate fields of "case_time_entries" */
export type Case_Time_Entries_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Case_Time_Entries_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "case_time_entries" */
export type Case_Time_Entries_Aggregate_Order_By = {
  avg?: Maybe<Case_Time_Entries_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Case_Time_Entries_Max_Order_By>;
  min?: Maybe<Case_Time_Entries_Min_Order_By>;
  stddev?: Maybe<Case_Time_Entries_Stddev_Order_By>;
  stddev_pop?: Maybe<Case_Time_Entries_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Case_Time_Entries_Stddev_Samp_Order_By>;
  sum?: Maybe<Case_Time_Entries_Sum_Order_By>;
  var_pop?: Maybe<Case_Time_Entries_Var_Pop_Order_By>;
  var_samp?: Maybe<Case_Time_Entries_Var_Samp_Order_By>;
  variance?: Maybe<Case_Time_Entries_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "case_time_entries" */
export type Case_Time_Entries_Arr_Rel_Insert_Input = {
  data: Array<Case_Time_Entries_Insert_Input>;
  on_conflict?: Maybe<Case_Time_Entries_On_Conflict>;
};

/** aggregate avg on columns */
export type Case_Time_Entries_Avg_Fields = {
  __typename?: 'case_time_entries_avg_fields';
  time?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "case_time_entries" */
export type Case_Time_Entries_Avg_Order_By = {
  time?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "case_time_entries". All fields are combined with a logical 'AND'. */
export type Case_Time_Entries_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Case_Time_Entries_Bool_Exp>>>;
  _not?: Maybe<Case_Time_Entries_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Case_Time_Entries_Bool_Exp>>>;
  case?: Maybe<Cases_Bool_Exp>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  date?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  time?: Maybe<Numeric_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_time_entries" */
export enum Case_Time_Entries_Constraint {
  /** unique or primary key constraint */
  CaseTimeEntriesPkey = 'case_time_entries_pkey'
}

/** input type for incrementing integer column in table "case_time_entries" */
export type Case_Time_Entries_Inc_Input = {
  time?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "case_time_entries" */
export type Case_Time_Entries_Insert_Input = {
  case?: Maybe<Cases_Obj_Rel_Insert_Input>;
  case_id?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  time?: Maybe<Scalars['numeric']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Case_Time_Entries_Max_Fields = {
  __typename?: 'case_time_entries_max_fields';
  case_id?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  time?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "case_time_entries" */
export type Case_Time_Entries_Max_Order_By = {
  case_id?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Case_Time_Entries_Min_Fields = {
  __typename?: 'case_time_entries_min_fields';
  case_id?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  time?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "case_time_entries" */
export type Case_Time_Entries_Min_Order_By = {
  case_id?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "case_time_entries" */
export type Case_Time_Entries_Mutation_Response = {
  __typename?: 'case_time_entries_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Case_Time_Entries>;
};

/** input type for inserting object relation for remote table "case_time_entries" */
export type Case_Time_Entries_Obj_Rel_Insert_Input = {
  data: Case_Time_Entries_Insert_Input;
  on_conflict?: Maybe<Case_Time_Entries_On_Conflict>;
};

/** on conflict condition type for table "case_time_entries" */
export type Case_Time_Entries_On_Conflict = {
  constraint: Case_Time_Entries_Constraint;
  update_columns: Array<Case_Time_Entries_Update_Column>;
  where?: Maybe<Case_Time_Entries_Bool_Exp>;
};

/** ordering options when selecting data from "case_time_entries" */
export type Case_Time_Entries_Order_By = {
  case?: Maybe<Cases_Order_By>;
  case_id?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "case_time_entries" */
export type Case_Time_Entries_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "case_time_entries" */
export enum Case_Time_Entries_Select_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Time = 'time',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "case_time_entries" */
export type Case_Time_Entries_Set_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  time?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Case_Time_Entries_Stddev_Fields = {
  __typename?: 'case_time_entries_stddev_fields';
  time?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "case_time_entries" */
export type Case_Time_Entries_Stddev_Order_By = {
  time?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Case_Time_Entries_Stddev_Pop_Fields = {
  __typename?: 'case_time_entries_stddev_pop_fields';
  time?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "case_time_entries" */
export type Case_Time_Entries_Stddev_Pop_Order_By = {
  time?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Case_Time_Entries_Stddev_Samp_Fields = {
  __typename?: 'case_time_entries_stddev_samp_fields';
  time?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "case_time_entries" */
export type Case_Time_Entries_Stddev_Samp_Order_By = {
  time?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Case_Time_Entries_Sum_Fields = {
  __typename?: 'case_time_entries_sum_fields';
  time?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "case_time_entries" */
export type Case_Time_Entries_Sum_Order_By = {
  time?: Maybe<Order_By>;
};

/** update columns of table "case_time_entries" */
export enum Case_Time_Entries_Update_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Time = 'time',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Case_Time_Entries_Var_Pop_Fields = {
  __typename?: 'case_time_entries_var_pop_fields';
  time?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "case_time_entries" */
export type Case_Time_Entries_Var_Pop_Order_By = {
  time?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Case_Time_Entries_Var_Samp_Fields = {
  __typename?: 'case_time_entries_var_samp_fields';
  time?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "case_time_entries" */
export type Case_Time_Entries_Var_Samp_Order_By = {
  time?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Case_Time_Entries_Variance_Fields = {
  __typename?: 'case_time_entries_variance_fields';
  time?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "case_time_entries" */
export type Case_Time_Entries_Variance_Order_By = {
  time?: Maybe<Order_By>;
};

/** columns and relationships of "case_types" */
export type Case_Types = {
  __typename?: 'case_types';
  color?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  price?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "case_types" */
export type Case_Types_Aggregate = {
  __typename?: 'case_types_aggregate';
  aggregate?: Maybe<Case_Types_Aggregate_Fields>;
  nodes: Array<Case_Types>;
};

/** aggregate fields of "case_types" */
export type Case_Types_Aggregate_Fields = {
  __typename?: 'case_types_aggregate_fields';
  avg?: Maybe<Case_Types_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Case_Types_Max_Fields>;
  min?: Maybe<Case_Types_Min_Fields>;
  stddev?: Maybe<Case_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Case_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Case_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Case_Types_Sum_Fields>;
  var_pop?: Maybe<Case_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Case_Types_Var_Samp_Fields>;
  variance?: Maybe<Case_Types_Variance_Fields>;
};


/** aggregate fields of "case_types" */
export type Case_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Case_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "case_types" */
export type Case_Types_Aggregate_Order_By = {
  avg?: Maybe<Case_Types_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Case_Types_Max_Order_By>;
  min?: Maybe<Case_Types_Min_Order_By>;
  stddev?: Maybe<Case_Types_Stddev_Order_By>;
  stddev_pop?: Maybe<Case_Types_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Case_Types_Stddev_Samp_Order_By>;
  sum?: Maybe<Case_Types_Sum_Order_By>;
  var_pop?: Maybe<Case_Types_Var_Pop_Order_By>;
  var_samp?: Maybe<Case_Types_Var_Samp_Order_By>;
  variance?: Maybe<Case_Types_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "case_types" */
export type Case_Types_Arr_Rel_Insert_Input = {
  data: Array<Case_Types_Insert_Input>;
  on_conflict?: Maybe<Case_Types_On_Conflict>;
};

/** aggregate avg on columns */
export type Case_Types_Avg_Fields = {
  __typename?: 'case_types_avg_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "case_types" */
export type Case_Types_Avg_Order_By = {
  price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "case_types". All fields are combined with a logical 'AND'. */
export type Case_Types_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Case_Types_Bool_Exp>>>;
  _not?: Maybe<Case_Types_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Case_Types_Bool_Exp>>>;
  color?: Maybe<String_Comparison_Exp>;
  icon?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_types" */
export enum Case_Types_Constraint {
  /** unique or primary key constraint */
  CaseTypesCaseTypeKey = 'case_types_case_type_key',
  /** unique or primary key constraint */
  CaseTypesIdKey = 'case_types_id_key',
  /** unique or primary key constraint */
  CaseTypesPkey = 'case_types_pkey'
}

/** input type for incrementing integer column in table "case_types" */
export type Case_Types_Inc_Input = {
  price?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "case_types" */
export type Case_Types_Insert_Input = {
  color?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Case_Types_Max_Fields = {
  __typename?: 'case_types_max_fields';
  color?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "case_types" */
export type Case_Types_Max_Order_By = {
  color?: Maybe<Order_By>;
  icon?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Case_Types_Min_Fields = {
  __typename?: 'case_types_min_fields';
  color?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "case_types" */
export type Case_Types_Min_Order_By = {
  color?: Maybe<Order_By>;
  icon?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** response of any mutation on the table "case_types" */
export type Case_Types_Mutation_Response = {
  __typename?: 'case_types_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Case_Types>;
};

/** input type for inserting object relation for remote table "case_types" */
export type Case_Types_Obj_Rel_Insert_Input = {
  data: Case_Types_Insert_Input;
  on_conflict?: Maybe<Case_Types_On_Conflict>;
};

/** on conflict condition type for table "case_types" */
export type Case_Types_On_Conflict = {
  constraint: Case_Types_Constraint;
  update_columns: Array<Case_Types_Update_Column>;
  where?: Maybe<Case_Types_Bool_Exp>;
};

/** ordering options when selecting data from "case_types" */
export type Case_Types_Order_By = {
  color?: Maybe<Order_By>;
  icon?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** primary key columns input for table: "case_types" */
export type Case_Types_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "case_types" */
export enum Case_Types_Select_Column {
  /** column name */
  Color = 'color',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price'
}

/** input type for updating data in table "case_types" */
export type Case_Types_Set_Input = {
  color?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Case_Types_Stddev_Fields = {
  __typename?: 'case_types_stddev_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "case_types" */
export type Case_Types_Stddev_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Case_Types_Stddev_Pop_Fields = {
  __typename?: 'case_types_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "case_types" */
export type Case_Types_Stddev_Pop_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Case_Types_Stddev_Samp_Fields = {
  __typename?: 'case_types_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "case_types" */
export type Case_Types_Stddev_Samp_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Case_Types_Sum_Fields = {
  __typename?: 'case_types_sum_fields';
  price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "case_types" */
export type Case_Types_Sum_Order_By = {
  price?: Maybe<Order_By>;
};

/** update columns of table "case_types" */
export enum Case_Types_Update_Column {
  /** column name */
  Color = 'color',
  /** column name */
  Icon = 'icon',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price'
}

/** aggregate var_pop on columns */
export type Case_Types_Var_Pop_Fields = {
  __typename?: 'case_types_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "case_types" */
export type Case_Types_Var_Pop_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Case_Types_Var_Samp_Fields = {
  __typename?: 'case_types_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "case_types" */
export type Case_Types_Var_Samp_Order_By = {
  price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Case_Types_Variance_Fields = {
  __typename?: 'case_types_variance_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "case_types" */
export type Case_Types_Variance_Order_By = {
  price?: Maybe<Order_By>;
};

/** columns and relationships of "cases" */
export type Cases = {
  __typename?: 'cases';
  address?: Maybe<Scalars['String']>;
  /** An object relationship */
  builder?: Maybe<Contacts>;
  builder_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  case_doc_notes: Array<Case_Doc_Notes>;
  /** An aggregated array relationship */
  case_doc_notes_aggregate: Case_Doc_Notes_Aggregate;
  /** An array relationship */
  case_files: Array<Case_Files>;
  /** An aggregated array relationship */
  case_files_aggregate: Case_Files_Aggregate;
  /** An array relationship */
  case_photos: Array<Case_Photos>;
  /** An aggregated array relationship */
  case_photos_aggregate: Case_Photos_Aggregate;
  /** An object relationship */
  case_type?: Maybe<Case_Types>;
  case_type_id?: Maybe<Scalars['uuid']>;
  changed: Scalars['timestamptz'];
  /** An array relationship */
  checklists: Array<Case_Doc_Checklists>;
  /** An aggregated array relationship */
  checklists_aggregate: Case_Doc_Checklists_Aggregate;
  city?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  company?: Maybe<Contacts>;
  company_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  construction_plan?: Maybe<Construction_Plans>;
  construction_plan_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  created_by?: Maybe<Users>;
  created_by_id?: Maybe<Scalars['uuid']>;
  date_of_inspection?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  estimated_time?: Maybe<Scalars['Int']>;
  flag: Scalars['Boolean'];
  id: Scalars['uuid'];
  important_note?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  other_address?: Maybe<Scalars['Boolean']>;
  other_note?: Maybe<Scalars['String']>;
  /** An array relationship */
  quality_controls: Array<Case_Doc_Quality_Controls>;
  /** An aggregated array relationship */
  quality_controls_aggregate: Case_Doc_Quality_Controls_Aggregate;
  /** An array relationship */
  reminders: Array<Reminders>;
  /** An aggregated array relationship */
  reminders_aggregate: Reminders_Aggregate;
  /** An array relationship */
  requests: Array<Requests>;
  /** An aggregated array relationship */
  requests_aggregate: Requests_Aggregate;
  /** An object relationship */
  responsible?: Maybe<Users>;
  responsible_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  secondary_builder?: Maybe<Contacts>;
  secondary_builder_id?: Maybe<Scalars['uuid']>;
  /** if the case is open true if closed false */
  state: Scalars['Boolean'];
  /** An array relationship */
  suppliers: Array<Case_Suppliers>;
  /** An aggregated array relationship */
  suppliers_aggregate: Case_Suppliers_Aggregate;
  task: Scalars['String'];
  toilet?: Maybe<Scalars['Boolean']>;
  usefull_note?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};


/** columns and relationships of "cases" */
export type CasesCase_Doc_NotesArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Notes_Order_By>>;
  where?: Maybe<Case_Doc_Notes_Bool_Exp>;
};


/** columns and relationships of "cases" */
export type CasesCase_Doc_Notes_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Notes_Order_By>>;
  where?: Maybe<Case_Doc_Notes_Bool_Exp>;
};


/** columns and relationships of "cases" */
export type CasesCase_FilesArgs = {
  distinct_on?: Maybe<Array<Case_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Files_Order_By>>;
  where?: Maybe<Case_Files_Bool_Exp>;
};


/** columns and relationships of "cases" */
export type CasesCase_Files_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Files_Order_By>>;
  where?: Maybe<Case_Files_Bool_Exp>;
};


/** columns and relationships of "cases" */
export type CasesCase_PhotosArgs = {
  distinct_on?: Maybe<Array<Case_Photos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Photos_Order_By>>;
  where?: Maybe<Case_Photos_Bool_Exp>;
};


/** columns and relationships of "cases" */
export type CasesCase_Photos_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Photos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Photos_Order_By>>;
  where?: Maybe<Case_Photos_Bool_Exp>;
};


/** columns and relationships of "cases" */
export type CasesChecklistsArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Checklists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Checklists_Order_By>>;
  where?: Maybe<Case_Doc_Checklists_Bool_Exp>;
};


/** columns and relationships of "cases" */
export type CasesChecklists_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Checklists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Checklists_Order_By>>;
  where?: Maybe<Case_Doc_Checklists_Bool_Exp>;
};


/** columns and relationships of "cases" */
export type CasesQuality_ControlsArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Quality_Controls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Quality_Controls_Order_By>>;
  where?: Maybe<Case_Doc_Quality_Controls_Bool_Exp>;
};


/** columns and relationships of "cases" */
export type CasesQuality_Controls_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Quality_Controls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Quality_Controls_Order_By>>;
  where?: Maybe<Case_Doc_Quality_Controls_Bool_Exp>;
};


/** columns and relationships of "cases" */
export type CasesRemindersArgs = {
  distinct_on?: Maybe<Array<Reminders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reminders_Order_By>>;
  where?: Maybe<Reminders_Bool_Exp>;
};


/** columns and relationships of "cases" */
export type CasesReminders_AggregateArgs = {
  distinct_on?: Maybe<Array<Reminders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reminders_Order_By>>;
  where?: Maybe<Reminders_Bool_Exp>;
};


/** columns and relationships of "cases" */
export type CasesRequestsArgs = {
  distinct_on?: Maybe<Array<Requests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Order_By>>;
  where?: Maybe<Requests_Bool_Exp>;
};


/** columns and relationships of "cases" */
export type CasesRequests_AggregateArgs = {
  distinct_on?: Maybe<Array<Requests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Order_By>>;
  where?: Maybe<Requests_Bool_Exp>;
};


/** columns and relationships of "cases" */
export type CasesSuppliersArgs = {
  distinct_on?: Maybe<Array<Case_Suppliers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Suppliers_Order_By>>;
  where?: Maybe<Case_Suppliers_Bool_Exp>;
};


/** columns and relationships of "cases" */
export type CasesSuppliers_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Suppliers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Suppliers_Order_By>>;
  where?: Maybe<Case_Suppliers_Bool_Exp>;
};

/** aggregated selection of "cases" */
export type Cases_Aggregate = {
  __typename?: 'cases_aggregate';
  aggregate?: Maybe<Cases_Aggregate_Fields>;
  nodes: Array<Cases>;
};

/** aggregate fields of "cases" */
export type Cases_Aggregate_Fields = {
  __typename?: 'cases_aggregate_fields';
  avg?: Maybe<Cases_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Cases_Max_Fields>;
  min?: Maybe<Cases_Min_Fields>;
  stddev?: Maybe<Cases_Stddev_Fields>;
  stddev_pop?: Maybe<Cases_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cases_Stddev_Samp_Fields>;
  sum?: Maybe<Cases_Sum_Fields>;
  var_pop?: Maybe<Cases_Var_Pop_Fields>;
  var_samp?: Maybe<Cases_Var_Samp_Fields>;
  variance?: Maybe<Cases_Variance_Fields>;
};


/** aggregate fields of "cases" */
export type Cases_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cases_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cases" */
export type Cases_Aggregate_Order_By = {
  avg?: Maybe<Cases_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Cases_Max_Order_By>;
  min?: Maybe<Cases_Min_Order_By>;
  stddev?: Maybe<Cases_Stddev_Order_By>;
  stddev_pop?: Maybe<Cases_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Cases_Stddev_Samp_Order_By>;
  sum?: Maybe<Cases_Sum_Order_By>;
  var_pop?: Maybe<Cases_Var_Pop_Order_By>;
  var_samp?: Maybe<Cases_Var_Samp_Order_By>;
  variance?: Maybe<Cases_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "cases" */
export type Cases_Arr_Rel_Insert_Input = {
  data: Array<Cases_Insert_Input>;
  on_conflict?: Maybe<Cases_On_Conflict>;
};

/** aggregate avg on columns */
export type Cases_Avg_Fields = {
  __typename?: 'cases_avg_fields';
  estimated_time?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cases" */
export type Cases_Avg_Order_By = {
  estimated_time?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cases". All fields are combined with a logical 'AND'. */
export type Cases_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Cases_Bool_Exp>>>;
  _not?: Maybe<Cases_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Cases_Bool_Exp>>>;
  address?: Maybe<String_Comparison_Exp>;
  builder?: Maybe<Contacts_Bool_Exp>;
  builder_id?: Maybe<Uuid_Comparison_Exp>;
  case_doc_notes?: Maybe<Case_Doc_Notes_Bool_Exp>;
  case_files?: Maybe<Case_Files_Bool_Exp>;
  case_photos?: Maybe<Case_Photos_Bool_Exp>;
  case_type?: Maybe<Case_Types_Bool_Exp>;
  case_type_id?: Maybe<Uuid_Comparison_Exp>;
  changed?: Maybe<Timestamptz_Comparison_Exp>;
  checklists?: Maybe<Case_Doc_Checklists_Bool_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  closed?: Maybe<Timestamptz_Comparison_Exp>;
  company?: Maybe<Contacts_Bool_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  construction_plan?: Maybe<Construction_Plans_Bool_Exp>;
  construction_plan_id?: Maybe<Uuid_Comparison_Exp>;
  created?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Users_Bool_Exp>;
  created_by_id?: Maybe<Uuid_Comparison_Exp>;
  date_of_inspection?: Maybe<String_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  estimated_time?: Maybe<Int_Comparison_Exp>;
  flag?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  important_note?: Maybe<String_Comparison_Exp>;
  lat?: Maybe<Numeric_Comparison_Exp>;
  lng?: Maybe<Numeric_Comparison_Exp>;
  other_address?: Maybe<Boolean_Comparison_Exp>;
  other_note?: Maybe<String_Comparison_Exp>;
  quality_controls?: Maybe<Case_Doc_Quality_Controls_Bool_Exp>;
  reminders?: Maybe<Reminders_Bool_Exp>;
  requests?: Maybe<Requests_Bool_Exp>;
  responsible?: Maybe<Users_Bool_Exp>;
  responsible_id?: Maybe<Uuid_Comparison_Exp>;
  secondary_builder?: Maybe<Contacts_Bool_Exp>;
  secondary_builder_id?: Maybe<Uuid_Comparison_Exp>;
  state?: Maybe<Boolean_Comparison_Exp>;
  suppliers?: Maybe<Case_Suppliers_Bool_Exp>;
  task?: Maybe<String_Comparison_Exp>;
  toilet?: Maybe<Boolean_Comparison_Exp>;
  usefull_note?: Maybe<String_Comparison_Exp>;
  zip_code?: Maybe<String_Comparison_Exp>;
};

/** columns and relationships of "cases_building_inspection_per_employee" */
export type Cases_Building_Inspection_Per_Employee = {
  __typename?: 'cases_building_inspection_per_employee';
  /** An object relationship */
  case_type?: Maybe<Case_Types>;
  case_type_id?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  responsible?: Maybe<Users>;
  responsible_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "cases_building_inspection_per_employee" */
export type Cases_Building_Inspection_Per_Employee_Aggregate = {
  __typename?: 'cases_building_inspection_per_employee_aggregate';
  aggregate?: Maybe<Cases_Building_Inspection_Per_Employee_Aggregate_Fields>;
  nodes: Array<Cases_Building_Inspection_Per_Employee>;
};

/** aggregate fields of "cases_building_inspection_per_employee" */
export type Cases_Building_Inspection_Per_Employee_Aggregate_Fields = {
  __typename?: 'cases_building_inspection_per_employee_aggregate_fields';
  avg?: Maybe<Cases_Building_Inspection_Per_Employee_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Cases_Building_Inspection_Per_Employee_Max_Fields>;
  min?: Maybe<Cases_Building_Inspection_Per_Employee_Min_Fields>;
  stddev?: Maybe<Cases_Building_Inspection_Per_Employee_Stddev_Fields>;
  stddev_pop?: Maybe<Cases_Building_Inspection_Per_Employee_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cases_Building_Inspection_Per_Employee_Stddev_Samp_Fields>;
  sum?: Maybe<Cases_Building_Inspection_Per_Employee_Sum_Fields>;
  var_pop?: Maybe<Cases_Building_Inspection_Per_Employee_Var_Pop_Fields>;
  var_samp?: Maybe<Cases_Building_Inspection_Per_Employee_Var_Samp_Fields>;
  variance?: Maybe<Cases_Building_Inspection_Per_Employee_Variance_Fields>;
};


/** aggregate fields of "cases_building_inspection_per_employee" */
export type Cases_Building_Inspection_Per_Employee_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cases_Building_Inspection_Per_Employee_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cases_building_inspection_per_employee" */
export type Cases_Building_Inspection_Per_Employee_Aggregate_Order_By = {
  avg?: Maybe<Cases_Building_Inspection_Per_Employee_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Cases_Building_Inspection_Per_Employee_Max_Order_By>;
  min?: Maybe<Cases_Building_Inspection_Per_Employee_Min_Order_By>;
  stddev?: Maybe<Cases_Building_Inspection_Per_Employee_Stddev_Order_By>;
  stddev_pop?: Maybe<Cases_Building_Inspection_Per_Employee_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Cases_Building_Inspection_Per_Employee_Stddev_Samp_Order_By>;
  sum?: Maybe<Cases_Building_Inspection_Per_Employee_Sum_Order_By>;
  var_pop?: Maybe<Cases_Building_Inspection_Per_Employee_Var_Pop_Order_By>;
  var_samp?: Maybe<Cases_Building_Inspection_Per_Employee_Var_Samp_Order_By>;
  variance?: Maybe<Cases_Building_Inspection_Per_Employee_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Cases_Building_Inspection_Per_Employee_Avg_Fields = {
  __typename?: 'cases_building_inspection_per_employee_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cases_building_inspection_per_employee" */
export type Cases_Building_Inspection_Per_Employee_Avg_Order_By = {
  count?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cases_building_inspection_per_employee". All fields are combined with a logical 'AND'. */
export type Cases_Building_Inspection_Per_Employee_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Cases_Building_Inspection_Per_Employee_Bool_Exp>>>;
  _not?: Maybe<Cases_Building_Inspection_Per_Employee_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Cases_Building_Inspection_Per_Employee_Bool_Exp>>>;
  case_type?: Maybe<Case_Types_Bool_Exp>;
  case_type_id?: Maybe<Uuid_Comparison_Exp>;
  count?: Maybe<Bigint_Comparison_Exp>;
  responsible?: Maybe<Users_Bool_Exp>;
  responsible_id?: Maybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Cases_Building_Inspection_Per_Employee_Max_Fields = {
  __typename?: 'cases_building_inspection_per_employee_max_fields';
  case_type_id?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
  responsible_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "cases_building_inspection_per_employee" */
export type Cases_Building_Inspection_Per_Employee_Max_Order_By = {
  case_type_id?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
  responsible_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Cases_Building_Inspection_Per_Employee_Min_Fields = {
  __typename?: 'cases_building_inspection_per_employee_min_fields';
  case_type_id?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
  responsible_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "cases_building_inspection_per_employee" */
export type Cases_Building_Inspection_Per_Employee_Min_Order_By = {
  case_type_id?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
  responsible_id?: Maybe<Order_By>;
};

/** ordering options when selecting data from "cases_building_inspection_per_employee" */
export type Cases_Building_Inspection_Per_Employee_Order_By = {
  case_type?: Maybe<Case_Types_Order_By>;
  case_type_id?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
  responsible?: Maybe<Users_Order_By>;
  responsible_id?: Maybe<Order_By>;
};

/** select columns of table "cases_building_inspection_per_employee" */
export enum Cases_Building_Inspection_Per_Employee_Select_Column {
  /** column name */
  CaseTypeId = 'case_type_id',
  /** column name */
  Count = 'count',
  /** column name */
  ResponsibleId = 'responsible_id'
}

/** aggregate stddev on columns */
export type Cases_Building_Inspection_Per_Employee_Stddev_Fields = {
  __typename?: 'cases_building_inspection_per_employee_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cases_building_inspection_per_employee" */
export type Cases_Building_Inspection_Per_Employee_Stddev_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cases_Building_Inspection_Per_Employee_Stddev_Pop_Fields = {
  __typename?: 'cases_building_inspection_per_employee_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cases_building_inspection_per_employee" */
export type Cases_Building_Inspection_Per_Employee_Stddev_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cases_Building_Inspection_Per_Employee_Stddev_Samp_Fields = {
  __typename?: 'cases_building_inspection_per_employee_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cases_building_inspection_per_employee" */
export type Cases_Building_Inspection_Per_Employee_Stddev_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Cases_Building_Inspection_Per_Employee_Sum_Fields = {
  __typename?: 'cases_building_inspection_per_employee_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "cases_building_inspection_per_employee" */
export type Cases_Building_Inspection_Per_Employee_Sum_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Cases_Building_Inspection_Per_Employee_Var_Pop_Fields = {
  __typename?: 'cases_building_inspection_per_employee_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cases_building_inspection_per_employee" */
export type Cases_Building_Inspection_Per_Employee_Var_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cases_Building_Inspection_Per_Employee_Var_Samp_Fields = {
  __typename?: 'cases_building_inspection_per_employee_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cases_building_inspection_per_employee" */
export type Cases_Building_Inspection_Per_Employee_Var_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Cases_Building_Inspection_Per_Employee_Variance_Fields = {
  __typename?: 'cases_building_inspection_per_employee_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cases_building_inspection_per_employee" */
export type Cases_Building_Inspection_Per_Employee_Variance_Order_By = {
  count?: Maybe<Order_By>;
};

/** columns and relationships of "cases_case_count_per_employee" */
export type Cases_Case_Count_Per_Employee = {
  __typename?: 'cases_case_count_per_employee';
  count?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  responsible?: Maybe<Users>;
  responsible_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "cases_case_count_per_employee" */
export type Cases_Case_Count_Per_Employee_Aggregate = {
  __typename?: 'cases_case_count_per_employee_aggregate';
  aggregate?: Maybe<Cases_Case_Count_Per_Employee_Aggregate_Fields>;
  nodes: Array<Cases_Case_Count_Per_Employee>;
};

/** aggregate fields of "cases_case_count_per_employee" */
export type Cases_Case_Count_Per_Employee_Aggregate_Fields = {
  __typename?: 'cases_case_count_per_employee_aggregate_fields';
  avg?: Maybe<Cases_Case_Count_Per_Employee_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Cases_Case_Count_Per_Employee_Max_Fields>;
  min?: Maybe<Cases_Case_Count_Per_Employee_Min_Fields>;
  stddev?: Maybe<Cases_Case_Count_Per_Employee_Stddev_Fields>;
  stddev_pop?: Maybe<Cases_Case_Count_Per_Employee_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cases_Case_Count_Per_Employee_Stddev_Samp_Fields>;
  sum?: Maybe<Cases_Case_Count_Per_Employee_Sum_Fields>;
  var_pop?: Maybe<Cases_Case_Count_Per_Employee_Var_Pop_Fields>;
  var_samp?: Maybe<Cases_Case_Count_Per_Employee_Var_Samp_Fields>;
  variance?: Maybe<Cases_Case_Count_Per_Employee_Variance_Fields>;
};


/** aggregate fields of "cases_case_count_per_employee" */
export type Cases_Case_Count_Per_Employee_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cases_Case_Count_Per_Employee_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cases_case_count_per_employee" */
export type Cases_Case_Count_Per_Employee_Aggregate_Order_By = {
  avg?: Maybe<Cases_Case_Count_Per_Employee_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Cases_Case_Count_Per_Employee_Max_Order_By>;
  min?: Maybe<Cases_Case_Count_Per_Employee_Min_Order_By>;
  stddev?: Maybe<Cases_Case_Count_Per_Employee_Stddev_Order_By>;
  stddev_pop?: Maybe<Cases_Case_Count_Per_Employee_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Cases_Case_Count_Per_Employee_Stddev_Samp_Order_By>;
  sum?: Maybe<Cases_Case_Count_Per_Employee_Sum_Order_By>;
  var_pop?: Maybe<Cases_Case_Count_Per_Employee_Var_Pop_Order_By>;
  var_samp?: Maybe<Cases_Case_Count_Per_Employee_Var_Samp_Order_By>;
  variance?: Maybe<Cases_Case_Count_Per_Employee_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Cases_Case_Count_Per_Employee_Avg_Fields = {
  __typename?: 'cases_case_count_per_employee_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cases_case_count_per_employee" */
export type Cases_Case_Count_Per_Employee_Avg_Order_By = {
  count?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cases_case_count_per_employee". All fields are combined with a logical 'AND'. */
export type Cases_Case_Count_Per_Employee_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Cases_Case_Count_Per_Employee_Bool_Exp>>>;
  _not?: Maybe<Cases_Case_Count_Per_Employee_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Cases_Case_Count_Per_Employee_Bool_Exp>>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  responsible?: Maybe<Users_Bool_Exp>;
  responsible_id?: Maybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Cases_Case_Count_Per_Employee_Max_Fields = {
  __typename?: 'cases_case_count_per_employee_max_fields';
  count?: Maybe<Scalars['bigint']>;
  responsible_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "cases_case_count_per_employee" */
export type Cases_Case_Count_Per_Employee_Max_Order_By = {
  count?: Maybe<Order_By>;
  responsible_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Cases_Case_Count_Per_Employee_Min_Fields = {
  __typename?: 'cases_case_count_per_employee_min_fields';
  count?: Maybe<Scalars['bigint']>;
  responsible_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "cases_case_count_per_employee" */
export type Cases_Case_Count_Per_Employee_Min_Order_By = {
  count?: Maybe<Order_By>;
  responsible_id?: Maybe<Order_By>;
};

/** ordering options when selecting data from "cases_case_count_per_employee" */
export type Cases_Case_Count_Per_Employee_Order_By = {
  count?: Maybe<Order_By>;
  responsible?: Maybe<Users_Order_By>;
  responsible_id?: Maybe<Order_By>;
};

/** select columns of table "cases_case_count_per_employee" */
export enum Cases_Case_Count_Per_Employee_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  ResponsibleId = 'responsible_id'
}

/** aggregate stddev on columns */
export type Cases_Case_Count_Per_Employee_Stddev_Fields = {
  __typename?: 'cases_case_count_per_employee_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cases_case_count_per_employee" */
export type Cases_Case_Count_Per_Employee_Stddev_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cases_Case_Count_Per_Employee_Stddev_Pop_Fields = {
  __typename?: 'cases_case_count_per_employee_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cases_case_count_per_employee" */
export type Cases_Case_Count_Per_Employee_Stddev_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cases_Case_Count_Per_Employee_Stddev_Samp_Fields = {
  __typename?: 'cases_case_count_per_employee_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cases_case_count_per_employee" */
export type Cases_Case_Count_Per_Employee_Stddev_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Cases_Case_Count_Per_Employee_Sum_Fields = {
  __typename?: 'cases_case_count_per_employee_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "cases_case_count_per_employee" */
export type Cases_Case_Count_Per_Employee_Sum_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Cases_Case_Count_Per_Employee_Var_Pop_Fields = {
  __typename?: 'cases_case_count_per_employee_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cases_case_count_per_employee" */
export type Cases_Case_Count_Per_Employee_Var_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cases_Case_Count_Per_Employee_Var_Samp_Fields = {
  __typename?: 'cases_case_count_per_employee_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cases_case_count_per_employee" */
export type Cases_Case_Count_Per_Employee_Var_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Cases_Case_Count_Per_Employee_Variance_Fields = {
  __typename?: 'cases_case_count_per_employee_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cases_case_count_per_employee" */
export type Cases_Case_Count_Per_Employee_Variance_Order_By = {
  count?: Maybe<Order_By>;
};

/** columns and relationships of "cases_case_type_count" */
export type Cases_Case_Type_Count = {
  __typename?: 'cases_case_type_count';
  /** An object relationship */
  case_type?: Maybe<Case_Types>;
  case_type_id?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "cases_case_type_count" */
export type Cases_Case_Type_Count_Aggregate = {
  __typename?: 'cases_case_type_count_aggregate';
  aggregate?: Maybe<Cases_Case_Type_Count_Aggregate_Fields>;
  nodes: Array<Cases_Case_Type_Count>;
};

/** aggregate fields of "cases_case_type_count" */
export type Cases_Case_Type_Count_Aggregate_Fields = {
  __typename?: 'cases_case_type_count_aggregate_fields';
  avg?: Maybe<Cases_Case_Type_Count_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Cases_Case_Type_Count_Max_Fields>;
  min?: Maybe<Cases_Case_Type_Count_Min_Fields>;
  stddev?: Maybe<Cases_Case_Type_Count_Stddev_Fields>;
  stddev_pop?: Maybe<Cases_Case_Type_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cases_Case_Type_Count_Stddev_Samp_Fields>;
  sum?: Maybe<Cases_Case_Type_Count_Sum_Fields>;
  var_pop?: Maybe<Cases_Case_Type_Count_Var_Pop_Fields>;
  var_samp?: Maybe<Cases_Case_Type_Count_Var_Samp_Fields>;
  variance?: Maybe<Cases_Case_Type_Count_Variance_Fields>;
};


/** aggregate fields of "cases_case_type_count" */
export type Cases_Case_Type_Count_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cases_Case_Type_Count_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cases_case_type_count" */
export type Cases_Case_Type_Count_Aggregate_Order_By = {
  avg?: Maybe<Cases_Case_Type_Count_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Cases_Case_Type_Count_Max_Order_By>;
  min?: Maybe<Cases_Case_Type_Count_Min_Order_By>;
  stddev?: Maybe<Cases_Case_Type_Count_Stddev_Order_By>;
  stddev_pop?: Maybe<Cases_Case_Type_Count_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Cases_Case_Type_Count_Stddev_Samp_Order_By>;
  sum?: Maybe<Cases_Case_Type_Count_Sum_Order_By>;
  var_pop?: Maybe<Cases_Case_Type_Count_Var_Pop_Order_By>;
  var_samp?: Maybe<Cases_Case_Type_Count_Var_Samp_Order_By>;
  variance?: Maybe<Cases_Case_Type_Count_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Cases_Case_Type_Count_Avg_Fields = {
  __typename?: 'cases_case_type_count_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cases_case_type_count" */
export type Cases_Case_Type_Count_Avg_Order_By = {
  count?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cases_case_type_count". All fields are combined with a logical 'AND'. */
export type Cases_Case_Type_Count_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Cases_Case_Type_Count_Bool_Exp>>>;
  _not?: Maybe<Cases_Case_Type_Count_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Cases_Case_Type_Count_Bool_Exp>>>;
  case_type?: Maybe<Case_Types_Bool_Exp>;
  case_type_id?: Maybe<Uuid_Comparison_Exp>;
  count?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Cases_Case_Type_Count_Max_Fields = {
  __typename?: 'cases_case_type_count_max_fields';
  case_type_id?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "cases_case_type_count" */
export type Cases_Case_Type_Count_Max_Order_By = {
  case_type_id?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Cases_Case_Type_Count_Min_Fields = {
  __typename?: 'cases_case_type_count_min_fields';
  case_type_id?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "cases_case_type_count" */
export type Cases_Case_Type_Count_Min_Order_By = {
  case_type_id?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
};

/** ordering options when selecting data from "cases_case_type_count" */
export type Cases_Case_Type_Count_Order_By = {
  case_type?: Maybe<Case_Types_Order_By>;
  case_type_id?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
};

/** select columns of table "cases_case_type_count" */
export enum Cases_Case_Type_Count_Select_Column {
  /** column name */
  CaseTypeId = 'case_type_id',
  /** column name */
  Count = 'count'
}

/** aggregate stddev on columns */
export type Cases_Case_Type_Count_Stddev_Fields = {
  __typename?: 'cases_case_type_count_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cases_case_type_count" */
export type Cases_Case_Type_Count_Stddev_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cases_Case_Type_Count_Stddev_Pop_Fields = {
  __typename?: 'cases_case_type_count_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cases_case_type_count" */
export type Cases_Case_Type_Count_Stddev_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cases_Case_Type_Count_Stddev_Samp_Fields = {
  __typename?: 'cases_case_type_count_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cases_case_type_count" */
export type Cases_Case_Type_Count_Stddev_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Cases_Case_Type_Count_Sum_Fields = {
  __typename?: 'cases_case_type_count_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "cases_case_type_count" */
export type Cases_Case_Type_Count_Sum_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Cases_Case_Type_Count_Var_Pop_Fields = {
  __typename?: 'cases_case_type_count_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cases_case_type_count" */
export type Cases_Case_Type_Count_Var_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cases_Case_Type_Count_Var_Samp_Fields = {
  __typename?: 'cases_case_type_count_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cases_case_type_count" */
export type Cases_Case_Type_Count_Var_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Cases_Case_Type_Count_Variance_Fields = {
  __typename?: 'cases_case_type_count_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cases_case_type_count" */
export type Cases_Case_Type_Count_Variance_Order_By = {
  count?: Maybe<Order_By>;
};

/** unique or primary key constraints on table "cases" */
export enum Cases_Constraint {
  /** unique or primary key constraint */
  CasesPkey = 'cases_pkey'
}

/** columns and relationships of "cases_created_per_employee_per_month" */
export type Cases_Created_Per_Employee_Per_Month = {
  __typename?: 'cases_created_per_employee_per_month';
  count?: Maybe<Scalars['bigint']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  employee?: Maybe<Users>;
  year_month?: Maybe<Scalars['String']>;
};

/** aggregated selection of "cases_created_per_employee_per_month" */
export type Cases_Created_Per_Employee_Per_Month_Aggregate = {
  __typename?: 'cases_created_per_employee_per_month_aggregate';
  aggregate?: Maybe<Cases_Created_Per_Employee_Per_Month_Aggregate_Fields>;
  nodes: Array<Cases_Created_Per_Employee_Per_Month>;
};

/** aggregate fields of "cases_created_per_employee_per_month" */
export type Cases_Created_Per_Employee_Per_Month_Aggregate_Fields = {
  __typename?: 'cases_created_per_employee_per_month_aggregate_fields';
  avg?: Maybe<Cases_Created_Per_Employee_Per_Month_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Cases_Created_Per_Employee_Per_Month_Max_Fields>;
  min?: Maybe<Cases_Created_Per_Employee_Per_Month_Min_Fields>;
  stddev?: Maybe<Cases_Created_Per_Employee_Per_Month_Stddev_Fields>;
  stddev_pop?: Maybe<Cases_Created_Per_Employee_Per_Month_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cases_Created_Per_Employee_Per_Month_Stddev_Samp_Fields>;
  sum?: Maybe<Cases_Created_Per_Employee_Per_Month_Sum_Fields>;
  var_pop?: Maybe<Cases_Created_Per_Employee_Per_Month_Var_Pop_Fields>;
  var_samp?: Maybe<Cases_Created_Per_Employee_Per_Month_Var_Samp_Fields>;
  variance?: Maybe<Cases_Created_Per_Employee_Per_Month_Variance_Fields>;
};


/** aggregate fields of "cases_created_per_employee_per_month" */
export type Cases_Created_Per_Employee_Per_Month_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cases_Created_Per_Employee_Per_Month_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cases_created_per_employee_per_month" */
export type Cases_Created_Per_Employee_Per_Month_Aggregate_Order_By = {
  avg?: Maybe<Cases_Created_Per_Employee_Per_Month_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Cases_Created_Per_Employee_Per_Month_Max_Order_By>;
  min?: Maybe<Cases_Created_Per_Employee_Per_Month_Min_Order_By>;
  stddev?: Maybe<Cases_Created_Per_Employee_Per_Month_Stddev_Order_By>;
  stddev_pop?: Maybe<Cases_Created_Per_Employee_Per_Month_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Cases_Created_Per_Employee_Per_Month_Stddev_Samp_Order_By>;
  sum?: Maybe<Cases_Created_Per_Employee_Per_Month_Sum_Order_By>;
  var_pop?: Maybe<Cases_Created_Per_Employee_Per_Month_Var_Pop_Order_By>;
  var_samp?: Maybe<Cases_Created_Per_Employee_Per_Month_Var_Samp_Order_By>;
  variance?: Maybe<Cases_Created_Per_Employee_Per_Month_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Cases_Created_Per_Employee_Per_Month_Avg_Fields = {
  __typename?: 'cases_created_per_employee_per_month_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cases_created_per_employee_per_month" */
export type Cases_Created_Per_Employee_Per_Month_Avg_Order_By = {
  count?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cases_created_per_employee_per_month". All fields are combined with a logical 'AND'. */
export type Cases_Created_Per_Employee_Per_Month_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Cases_Created_Per_Employee_Per_Month_Bool_Exp>>>;
  _not?: Maybe<Cases_Created_Per_Employee_Per_Month_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Cases_Created_Per_Employee_Per_Month_Bool_Exp>>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  created_by_id?: Maybe<Uuid_Comparison_Exp>;
  employee?: Maybe<Users_Bool_Exp>;
  year_month?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Cases_Created_Per_Employee_Per_Month_Max_Fields = {
  __typename?: 'cases_created_per_employee_per_month_max_fields';
  count?: Maybe<Scalars['bigint']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  year_month?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "cases_created_per_employee_per_month" */
export type Cases_Created_Per_Employee_Per_Month_Max_Order_By = {
  count?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  year_month?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Cases_Created_Per_Employee_Per_Month_Min_Fields = {
  __typename?: 'cases_created_per_employee_per_month_min_fields';
  count?: Maybe<Scalars['bigint']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  year_month?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "cases_created_per_employee_per_month" */
export type Cases_Created_Per_Employee_Per_Month_Min_Order_By = {
  count?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  year_month?: Maybe<Order_By>;
};

/** ordering options when selecting data from "cases_created_per_employee_per_month" */
export type Cases_Created_Per_Employee_Per_Month_Order_By = {
  count?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  employee?: Maybe<Users_Order_By>;
  year_month?: Maybe<Order_By>;
};

/** select columns of table "cases_created_per_employee_per_month" */
export enum Cases_Created_Per_Employee_Per_Month_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  YearMonth = 'year_month'
}

/** aggregate stddev on columns */
export type Cases_Created_Per_Employee_Per_Month_Stddev_Fields = {
  __typename?: 'cases_created_per_employee_per_month_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cases_created_per_employee_per_month" */
export type Cases_Created_Per_Employee_Per_Month_Stddev_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cases_Created_Per_Employee_Per_Month_Stddev_Pop_Fields = {
  __typename?: 'cases_created_per_employee_per_month_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cases_created_per_employee_per_month" */
export type Cases_Created_Per_Employee_Per_Month_Stddev_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cases_Created_Per_Employee_Per_Month_Stddev_Samp_Fields = {
  __typename?: 'cases_created_per_employee_per_month_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cases_created_per_employee_per_month" */
export type Cases_Created_Per_Employee_Per_Month_Stddev_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Cases_Created_Per_Employee_Per_Month_Sum_Fields = {
  __typename?: 'cases_created_per_employee_per_month_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "cases_created_per_employee_per_month" */
export type Cases_Created_Per_Employee_Per_Month_Sum_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Cases_Created_Per_Employee_Per_Month_Var_Pop_Fields = {
  __typename?: 'cases_created_per_employee_per_month_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cases_created_per_employee_per_month" */
export type Cases_Created_Per_Employee_Per_Month_Var_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cases_Created_Per_Employee_Per_Month_Var_Samp_Fields = {
  __typename?: 'cases_created_per_employee_per_month_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cases_created_per_employee_per_month" */
export type Cases_Created_Per_Employee_Per_Month_Var_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Cases_Created_Per_Employee_Per_Month_Variance_Fields = {
  __typename?: 'cases_created_per_employee_per_month_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cases_created_per_employee_per_month" */
export type Cases_Created_Per_Employee_Per_Month_Variance_Order_By = {
  count?: Maybe<Order_By>;
};

/** input type for incrementing integer column in table "cases" */
export type Cases_Inc_Input = {
  estimated_time?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "cases" */
export type Cases_Insert_Input = {
  address?: Maybe<Scalars['String']>;
  builder?: Maybe<Contacts_Obj_Rel_Insert_Input>;
  builder_id?: Maybe<Scalars['uuid']>;
  case_doc_notes?: Maybe<Case_Doc_Notes_Arr_Rel_Insert_Input>;
  case_files?: Maybe<Case_Files_Arr_Rel_Insert_Input>;
  case_photos?: Maybe<Case_Photos_Arr_Rel_Insert_Input>;
  case_type?: Maybe<Case_Types_Obj_Rel_Insert_Input>;
  case_type_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['timestamptz']>;
  checklists?: Maybe<Case_Doc_Checklists_Arr_Rel_Insert_Input>;
  city?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['timestamptz']>;
  company?: Maybe<Contacts_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['uuid']>;
  construction_plan?: Maybe<Construction_Plans_Obj_Rel_Insert_Input>;
  construction_plan_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Users_Obj_Rel_Insert_Input>;
  created_by_id?: Maybe<Scalars['uuid']>;
  date_of_inspection?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  estimated_time?: Maybe<Scalars['Int']>;
  flag?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  important_note?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  other_address?: Maybe<Scalars['Boolean']>;
  other_note?: Maybe<Scalars['String']>;
  quality_controls?: Maybe<Case_Doc_Quality_Controls_Arr_Rel_Insert_Input>;
  reminders?: Maybe<Reminders_Arr_Rel_Insert_Input>;
  requests?: Maybe<Requests_Arr_Rel_Insert_Input>;
  responsible?: Maybe<Users_Obj_Rel_Insert_Input>;
  responsible_id?: Maybe<Scalars['uuid']>;
  secondary_builder?: Maybe<Contacts_Obj_Rel_Insert_Input>;
  secondary_builder_id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['Boolean']>;
  suppliers?: Maybe<Case_Suppliers_Arr_Rel_Insert_Input>;
  task?: Maybe<Scalars['String']>;
  toilet?: Maybe<Scalars['Boolean']>;
  usefull_note?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Cases_Max_Fields = {
  __typename?: 'cases_max_fields';
  address?: Maybe<Scalars['String']>;
  builder_id?: Maybe<Scalars['uuid']>;
  case_type_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['timestamptz']>;
  city?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['uuid']>;
  construction_plan_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  date_of_inspection?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  estimated_time?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  important_note?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  other_note?: Maybe<Scalars['String']>;
  responsible_id?: Maybe<Scalars['uuid']>;
  secondary_builder_id?: Maybe<Scalars['uuid']>;
  task?: Maybe<Scalars['String']>;
  usefull_note?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "cases" */
export type Cases_Max_Order_By = {
  address?: Maybe<Order_By>;
  builder_id?: Maybe<Order_By>;
  case_type_id?: Maybe<Order_By>;
  changed?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  closed?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  construction_plan_id?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  date_of_inspection?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  estimated_time?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  important_note?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
  other_note?: Maybe<Order_By>;
  responsible_id?: Maybe<Order_By>;
  secondary_builder_id?: Maybe<Order_By>;
  task?: Maybe<Order_By>;
  usefull_note?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Cases_Min_Fields = {
  __typename?: 'cases_min_fields';
  address?: Maybe<Scalars['String']>;
  builder_id?: Maybe<Scalars['uuid']>;
  case_type_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['timestamptz']>;
  city?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['uuid']>;
  construction_plan_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  date_of_inspection?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  estimated_time?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  important_note?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  other_note?: Maybe<Scalars['String']>;
  responsible_id?: Maybe<Scalars['uuid']>;
  secondary_builder_id?: Maybe<Scalars['uuid']>;
  task?: Maybe<Scalars['String']>;
  usefull_note?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "cases" */
export type Cases_Min_Order_By = {
  address?: Maybe<Order_By>;
  builder_id?: Maybe<Order_By>;
  case_type_id?: Maybe<Order_By>;
  changed?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  closed?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  construction_plan_id?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  date_of_inspection?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  estimated_time?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  important_note?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
  other_note?: Maybe<Order_By>;
  responsible_id?: Maybe<Order_By>;
  secondary_builder_id?: Maybe<Order_By>;
  task?: Maybe<Order_By>;
  usefull_note?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

/** response of any mutation on the table "cases" */
export type Cases_Mutation_Response = {
  __typename?: 'cases_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Cases>;
};

/** input type for inserting object relation for remote table "cases" */
export type Cases_Obj_Rel_Insert_Input = {
  data: Cases_Insert_Input;
  on_conflict?: Maybe<Cases_On_Conflict>;
};

/** on conflict condition type for table "cases" */
export type Cases_On_Conflict = {
  constraint: Cases_Constraint;
  update_columns: Array<Cases_Update_Column>;
  where?: Maybe<Cases_Bool_Exp>;
};

/** ordering options when selecting data from "cases" */
export type Cases_Order_By = {
  address?: Maybe<Order_By>;
  builder?: Maybe<Contacts_Order_By>;
  builder_id?: Maybe<Order_By>;
  case_doc_notes_aggregate?: Maybe<Case_Doc_Notes_Aggregate_Order_By>;
  case_files_aggregate?: Maybe<Case_Files_Aggregate_Order_By>;
  case_photos_aggregate?: Maybe<Case_Photos_Aggregate_Order_By>;
  case_type?: Maybe<Case_Types_Order_By>;
  case_type_id?: Maybe<Order_By>;
  changed?: Maybe<Order_By>;
  checklists_aggregate?: Maybe<Case_Doc_Checklists_Aggregate_Order_By>;
  city?: Maybe<Order_By>;
  closed?: Maybe<Order_By>;
  company?: Maybe<Contacts_Order_By>;
  company_id?: Maybe<Order_By>;
  construction_plan?: Maybe<Construction_Plans_Order_By>;
  construction_plan_id?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  created_by?: Maybe<Users_Order_By>;
  created_by_id?: Maybe<Order_By>;
  date_of_inspection?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  estimated_time?: Maybe<Order_By>;
  flag?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  important_note?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
  other_address?: Maybe<Order_By>;
  other_note?: Maybe<Order_By>;
  quality_controls_aggregate?: Maybe<Case_Doc_Quality_Controls_Aggregate_Order_By>;
  reminders_aggregate?: Maybe<Reminders_Aggregate_Order_By>;
  requests_aggregate?: Maybe<Requests_Aggregate_Order_By>;
  responsible?: Maybe<Users_Order_By>;
  responsible_id?: Maybe<Order_By>;
  secondary_builder?: Maybe<Contacts_Order_By>;
  secondary_builder_id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  suppliers_aggregate?: Maybe<Case_Suppliers_Aggregate_Order_By>;
  task?: Maybe<Order_By>;
  toilet?: Maybe<Order_By>;
  usefull_note?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

/** columns and relationships of "cases_per_case_type_per_month" */
export type Cases_Per_Case_Type_Per_Month = {
  __typename?: 'cases_per_case_type_per_month';
  /** An object relationship */
  case_type?: Maybe<Case_Types>;
  case_type_id?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
  year_month?: Maybe<Scalars['String']>;
};

/** aggregated selection of "cases_per_case_type_per_month" */
export type Cases_Per_Case_Type_Per_Month_Aggregate = {
  __typename?: 'cases_per_case_type_per_month_aggregate';
  aggregate?: Maybe<Cases_Per_Case_Type_Per_Month_Aggregate_Fields>;
  nodes: Array<Cases_Per_Case_Type_Per_Month>;
};

/** aggregate fields of "cases_per_case_type_per_month" */
export type Cases_Per_Case_Type_Per_Month_Aggregate_Fields = {
  __typename?: 'cases_per_case_type_per_month_aggregate_fields';
  avg?: Maybe<Cases_Per_Case_Type_Per_Month_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Cases_Per_Case_Type_Per_Month_Max_Fields>;
  min?: Maybe<Cases_Per_Case_Type_Per_Month_Min_Fields>;
  stddev?: Maybe<Cases_Per_Case_Type_Per_Month_Stddev_Fields>;
  stddev_pop?: Maybe<Cases_Per_Case_Type_Per_Month_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cases_Per_Case_Type_Per_Month_Stddev_Samp_Fields>;
  sum?: Maybe<Cases_Per_Case_Type_Per_Month_Sum_Fields>;
  var_pop?: Maybe<Cases_Per_Case_Type_Per_Month_Var_Pop_Fields>;
  var_samp?: Maybe<Cases_Per_Case_Type_Per_Month_Var_Samp_Fields>;
  variance?: Maybe<Cases_Per_Case_Type_Per_Month_Variance_Fields>;
};


/** aggregate fields of "cases_per_case_type_per_month" */
export type Cases_Per_Case_Type_Per_Month_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cases_Per_Case_Type_Per_Month_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cases_per_case_type_per_month" */
export type Cases_Per_Case_Type_Per_Month_Aggregate_Order_By = {
  avg?: Maybe<Cases_Per_Case_Type_Per_Month_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Cases_Per_Case_Type_Per_Month_Max_Order_By>;
  min?: Maybe<Cases_Per_Case_Type_Per_Month_Min_Order_By>;
  stddev?: Maybe<Cases_Per_Case_Type_Per_Month_Stddev_Order_By>;
  stddev_pop?: Maybe<Cases_Per_Case_Type_Per_Month_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Cases_Per_Case_Type_Per_Month_Stddev_Samp_Order_By>;
  sum?: Maybe<Cases_Per_Case_Type_Per_Month_Sum_Order_By>;
  var_pop?: Maybe<Cases_Per_Case_Type_Per_Month_Var_Pop_Order_By>;
  var_samp?: Maybe<Cases_Per_Case_Type_Per_Month_Var_Samp_Order_By>;
  variance?: Maybe<Cases_Per_Case_Type_Per_Month_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Cases_Per_Case_Type_Per_Month_Avg_Fields = {
  __typename?: 'cases_per_case_type_per_month_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cases_per_case_type_per_month" */
export type Cases_Per_Case_Type_Per_Month_Avg_Order_By = {
  count?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cases_per_case_type_per_month". All fields are combined with a logical 'AND'. */
export type Cases_Per_Case_Type_Per_Month_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Cases_Per_Case_Type_Per_Month_Bool_Exp>>>;
  _not?: Maybe<Cases_Per_Case_Type_Per_Month_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Cases_Per_Case_Type_Per_Month_Bool_Exp>>>;
  case_type?: Maybe<Case_Types_Bool_Exp>;
  case_type_id?: Maybe<Uuid_Comparison_Exp>;
  count?: Maybe<Bigint_Comparison_Exp>;
  year_month?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Cases_Per_Case_Type_Per_Month_Max_Fields = {
  __typename?: 'cases_per_case_type_per_month_max_fields';
  case_type_id?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
  year_month?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "cases_per_case_type_per_month" */
export type Cases_Per_Case_Type_Per_Month_Max_Order_By = {
  case_type_id?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
  year_month?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Cases_Per_Case_Type_Per_Month_Min_Fields = {
  __typename?: 'cases_per_case_type_per_month_min_fields';
  case_type_id?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
  year_month?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "cases_per_case_type_per_month" */
export type Cases_Per_Case_Type_Per_Month_Min_Order_By = {
  case_type_id?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
  year_month?: Maybe<Order_By>;
};

/** ordering options when selecting data from "cases_per_case_type_per_month" */
export type Cases_Per_Case_Type_Per_Month_Order_By = {
  case_type?: Maybe<Case_Types_Order_By>;
  case_type_id?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
  year_month?: Maybe<Order_By>;
};

/** select columns of table "cases_per_case_type_per_month" */
export enum Cases_Per_Case_Type_Per_Month_Select_Column {
  /** column name */
  CaseTypeId = 'case_type_id',
  /** column name */
  Count = 'count',
  /** column name */
  YearMonth = 'year_month'
}

/** aggregate stddev on columns */
export type Cases_Per_Case_Type_Per_Month_Stddev_Fields = {
  __typename?: 'cases_per_case_type_per_month_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cases_per_case_type_per_month" */
export type Cases_Per_Case_Type_Per_Month_Stddev_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cases_Per_Case_Type_Per_Month_Stddev_Pop_Fields = {
  __typename?: 'cases_per_case_type_per_month_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cases_per_case_type_per_month" */
export type Cases_Per_Case_Type_Per_Month_Stddev_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cases_Per_Case_Type_Per_Month_Stddev_Samp_Fields = {
  __typename?: 'cases_per_case_type_per_month_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cases_per_case_type_per_month" */
export type Cases_Per_Case_Type_Per_Month_Stddev_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Cases_Per_Case_Type_Per_Month_Sum_Fields = {
  __typename?: 'cases_per_case_type_per_month_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "cases_per_case_type_per_month" */
export type Cases_Per_Case_Type_Per_Month_Sum_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Cases_Per_Case_Type_Per_Month_Var_Pop_Fields = {
  __typename?: 'cases_per_case_type_per_month_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cases_per_case_type_per_month" */
export type Cases_Per_Case_Type_Per_Month_Var_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cases_Per_Case_Type_Per_Month_Var_Samp_Fields = {
  __typename?: 'cases_per_case_type_per_month_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cases_per_case_type_per_month" */
export type Cases_Per_Case_Type_Per_Month_Var_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Cases_Per_Case_Type_Per_Month_Variance_Fields = {
  __typename?: 'cases_per_case_type_per_month_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cases_per_case_type_per_month" */
export type Cases_Per_Case_Type_Per_Month_Variance_Order_By = {
  count?: Maybe<Order_By>;
};

/** primary key columns input for table: "cases" */
export type Cases_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "cases" */
export enum Cases_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  BuilderId = 'builder_id',
  /** column name */
  CaseTypeId = 'case_type_id',
  /** column name */
  Changed = 'changed',
  /** column name */
  City = 'city',
  /** column name */
  Closed = 'closed',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ConstructionPlanId = 'construction_plan_id',
  /** column name */
  Created = 'created',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DateOfInspection = 'date_of_inspection',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Description = 'description',
  /** column name */
  EstimatedTime = 'estimated_time',
  /** column name */
  Flag = 'flag',
  /** column name */
  Id = 'id',
  /** column name */
  ImportantNote = 'important_note',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  OtherAddress = 'other_address',
  /** column name */
  OtherNote = 'other_note',
  /** column name */
  ResponsibleId = 'responsible_id',
  /** column name */
  SecondaryBuilderId = 'secondary_builder_id',
  /** column name */
  State = 'state',
  /** column name */
  Task = 'task',
  /** column name */
  Toilet = 'toilet',
  /** column name */
  UsefullNote = 'usefull_note',
  /** column name */
  ZipCode = 'zip_code'
}

/** input type for updating data in table "cases" */
export type Cases_Set_Input = {
  address?: Maybe<Scalars['String']>;
  builder_id?: Maybe<Scalars['uuid']>;
  case_type_id?: Maybe<Scalars['uuid']>;
  changed?: Maybe<Scalars['timestamptz']>;
  city?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['uuid']>;
  construction_plan_id?: Maybe<Scalars['uuid']>;
  created?: Maybe<Scalars['timestamptz']>;
  created_by_id?: Maybe<Scalars['uuid']>;
  date_of_inspection?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  estimated_time?: Maybe<Scalars['Int']>;
  flag?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  important_note?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
  other_address?: Maybe<Scalars['Boolean']>;
  other_note?: Maybe<Scalars['String']>;
  responsible_id?: Maybe<Scalars['uuid']>;
  secondary_builder_id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['Boolean']>;
  task?: Maybe<Scalars['String']>;
  toilet?: Maybe<Scalars['Boolean']>;
  usefull_note?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Cases_Stddev_Fields = {
  __typename?: 'cases_stddev_fields';
  estimated_time?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cases" */
export type Cases_Stddev_Order_By = {
  estimated_time?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cases_Stddev_Pop_Fields = {
  __typename?: 'cases_stddev_pop_fields';
  estimated_time?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cases" */
export type Cases_Stddev_Pop_Order_By = {
  estimated_time?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cases_Stddev_Samp_Fields = {
  __typename?: 'cases_stddev_samp_fields';
  estimated_time?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cases" */
export type Cases_Stddev_Samp_Order_By = {
  estimated_time?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Cases_Sum_Fields = {
  __typename?: 'cases_sum_fields';
  estimated_time?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['numeric']>;
  lng?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "cases" */
export type Cases_Sum_Order_By = {
  estimated_time?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
};

/** update columns of table "cases" */
export enum Cases_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  BuilderId = 'builder_id',
  /** column name */
  CaseTypeId = 'case_type_id',
  /** column name */
  Changed = 'changed',
  /** column name */
  City = 'city',
  /** column name */
  Closed = 'closed',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ConstructionPlanId = 'construction_plan_id',
  /** column name */
  Created = 'created',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DateOfInspection = 'date_of_inspection',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Description = 'description',
  /** column name */
  EstimatedTime = 'estimated_time',
  /** column name */
  Flag = 'flag',
  /** column name */
  Id = 'id',
  /** column name */
  ImportantNote = 'important_note',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  OtherAddress = 'other_address',
  /** column name */
  OtherNote = 'other_note',
  /** column name */
  ResponsibleId = 'responsible_id',
  /** column name */
  SecondaryBuilderId = 'secondary_builder_id',
  /** column name */
  State = 'state',
  /** column name */
  Task = 'task',
  /** column name */
  Toilet = 'toilet',
  /** column name */
  UsefullNote = 'usefull_note',
  /** column name */
  ZipCode = 'zip_code'
}

/** aggregate var_pop on columns */
export type Cases_Var_Pop_Fields = {
  __typename?: 'cases_var_pop_fields';
  estimated_time?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cases" */
export type Cases_Var_Pop_Order_By = {
  estimated_time?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cases_Var_Samp_Fields = {
  __typename?: 'cases_var_samp_fields';
  estimated_time?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cases" */
export type Cases_Var_Samp_Order_By = {
  estimated_time?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Cases_Variance_Fields = {
  __typename?: 'cases_variance_fields';
  estimated_time?: Maybe<Scalars['Float']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cases" */
export type Cases_Variance_Order_By = {
  estimated_time?: Maybe<Order_By>;
  lat?: Maybe<Order_By>;
  lng?: Maybe<Order_By>;
};

/** columns and relationships of "checklist_item_arrows" */
export type Checklist_Item_Arrows = {
  __typename?: 'checklist_item_arrows';
  angle: Scalars['Int'];
  id: Scalars['uuid'];
  item_id: Scalars['uuid'];
  order: Scalars['Int'];
  x: Scalars['numeric'];
  y: Scalars['numeric'];
};

/** aggregated selection of "checklist_item_arrows" */
export type Checklist_Item_Arrows_Aggregate = {
  __typename?: 'checklist_item_arrows_aggregate';
  aggregate?: Maybe<Checklist_Item_Arrows_Aggregate_Fields>;
  nodes: Array<Checklist_Item_Arrows>;
};

/** aggregate fields of "checklist_item_arrows" */
export type Checklist_Item_Arrows_Aggregate_Fields = {
  __typename?: 'checklist_item_arrows_aggregate_fields';
  avg?: Maybe<Checklist_Item_Arrows_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Checklist_Item_Arrows_Max_Fields>;
  min?: Maybe<Checklist_Item_Arrows_Min_Fields>;
  stddev?: Maybe<Checklist_Item_Arrows_Stddev_Fields>;
  stddev_pop?: Maybe<Checklist_Item_Arrows_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Checklist_Item_Arrows_Stddev_Samp_Fields>;
  sum?: Maybe<Checklist_Item_Arrows_Sum_Fields>;
  var_pop?: Maybe<Checklist_Item_Arrows_Var_Pop_Fields>;
  var_samp?: Maybe<Checklist_Item_Arrows_Var_Samp_Fields>;
  variance?: Maybe<Checklist_Item_Arrows_Variance_Fields>;
};


/** aggregate fields of "checklist_item_arrows" */
export type Checklist_Item_Arrows_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Checklist_Item_Arrows_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "checklist_item_arrows" */
export type Checklist_Item_Arrows_Aggregate_Order_By = {
  avg?: Maybe<Checklist_Item_Arrows_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Checklist_Item_Arrows_Max_Order_By>;
  min?: Maybe<Checklist_Item_Arrows_Min_Order_By>;
  stddev?: Maybe<Checklist_Item_Arrows_Stddev_Order_By>;
  stddev_pop?: Maybe<Checklist_Item_Arrows_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Checklist_Item_Arrows_Stddev_Samp_Order_By>;
  sum?: Maybe<Checklist_Item_Arrows_Sum_Order_By>;
  var_pop?: Maybe<Checklist_Item_Arrows_Var_Pop_Order_By>;
  var_samp?: Maybe<Checklist_Item_Arrows_Var_Samp_Order_By>;
  variance?: Maybe<Checklist_Item_Arrows_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "checklist_item_arrows" */
export type Checklist_Item_Arrows_Arr_Rel_Insert_Input = {
  data: Array<Checklist_Item_Arrows_Insert_Input>;
  on_conflict?: Maybe<Checklist_Item_Arrows_On_Conflict>;
};

/** aggregate avg on columns */
export type Checklist_Item_Arrows_Avg_Fields = {
  __typename?: 'checklist_item_arrows_avg_fields';
  angle?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "checklist_item_arrows" */
export type Checklist_Item_Arrows_Avg_Order_By = {
  angle?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "checklist_item_arrows". All fields are combined with a logical 'AND'. */
export type Checklist_Item_Arrows_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Checklist_Item_Arrows_Bool_Exp>>>;
  _not?: Maybe<Checklist_Item_Arrows_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Checklist_Item_Arrows_Bool_Exp>>>;
  angle?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  item_id?: Maybe<Uuid_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  x?: Maybe<Numeric_Comparison_Exp>;
  y?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "checklist_item_arrows" */
export enum Checklist_Item_Arrows_Constraint {
  /** unique or primary key constraint */
  ChecklistItemArrowsPkey = 'checklist_item_arrows_pkey'
}

/** input type for incrementing integer column in table "checklist_item_arrows" */
export type Checklist_Item_Arrows_Inc_Input = {
  angle?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "checklist_item_arrows" */
export type Checklist_Item_Arrows_Insert_Input = {
  angle?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  item_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Checklist_Item_Arrows_Max_Fields = {
  __typename?: 'checklist_item_arrows_max_fields';
  angle?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  item_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "checklist_item_arrows" */
export type Checklist_Item_Arrows_Max_Order_By = {
  angle?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  item_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Checklist_Item_Arrows_Min_Fields = {
  __typename?: 'checklist_item_arrows_min_fields';
  angle?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  item_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "checklist_item_arrows" */
export type Checklist_Item_Arrows_Min_Order_By = {
  angle?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  item_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** response of any mutation on the table "checklist_item_arrows" */
export type Checklist_Item_Arrows_Mutation_Response = {
  __typename?: 'checklist_item_arrows_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Checklist_Item_Arrows>;
};

/** input type for inserting object relation for remote table "checklist_item_arrows" */
export type Checklist_Item_Arrows_Obj_Rel_Insert_Input = {
  data: Checklist_Item_Arrows_Insert_Input;
  on_conflict?: Maybe<Checklist_Item_Arrows_On_Conflict>;
};

/** on conflict condition type for table "checklist_item_arrows" */
export type Checklist_Item_Arrows_On_Conflict = {
  constraint: Checklist_Item_Arrows_Constraint;
  update_columns: Array<Checklist_Item_Arrows_Update_Column>;
  where?: Maybe<Checklist_Item_Arrows_Bool_Exp>;
};

/** ordering options when selecting data from "checklist_item_arrows" */
export type Checklist_Item_Arrows_Order_By = {
  angle?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  item_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** primary key columns input for table: "checklist_item_arrows" */
export type Checklist_Item_Arrows_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "checklist_item_arrows" */
export enum Checklist_Item_Arrows_Select_Column {
  /** column name */
  Angle = 'angle',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  Order = 'order',
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y'
}

/** input type for updating data in table "checklist_item_arrows" */
export type Checklist_Item_Arrows_Set_Input = {
  angle?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  item_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Checklist_Item_Arrows_Stddev_Fields = {
  __typename?: 'checklist_item_arrows_stddev_fields';
  angle?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "checklist_item_arrows" */
export type Checklist_Item_Arrows_Stddev_Order_By = {
  angle?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Checklist_Item_Arrows_Stddev_Pop_Fields = {
  __typename?: 'checklist_item_arrows_stddev_pop_fields';
  angle?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "checklist_item_arrows" */
export type Checklist_Item_Arrows_Stddev_Pop_Order_By = {
  angle?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Checklist_Item_Arrows_Stddev_Samp_Fields = {
  __typename?: 'checklist_item_arrows_stddev_samp_fields';
  angle?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "checklist_item_arrows" */
export type Checklist_Item_Arrows_Stddev_Samp_Order_By = {
  angle?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Checklist_Item_Arrows_Sum_Fields = {
  __typename?: 'checklist_item_arrows_sum_fields';
  angle?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "checklist_item_arrows" */
export type Checklist_Item_Arrows_Sum_Order_By = {
  angle?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** update columns of table "checklist_item_arrows" */
export enum Checklist_Item_Arrows_Update_Column {
  /** column name */
  Angle = 'angle',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  Order = 'order',
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y'
}

/** aggregate var_pop on columns */
export type Checklist_Item_Arrows_Var_Pop_Fields = {
  __typename?: 'checklist_item_arrows_var_pop_fields';
  angle?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "checklist_item_arrows" */
export type Checklist_Item_Arrows_Var_Pop_Order_By = {
  angle?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Checklist_Item_Arrows_Var_Samp_Fields = {
  __typename?: 'checklist_item_arrows_var_samp_fields';
  angle?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "checklist_item_arrows" */
export type Checklist_Item_Arrows_Var_Samp_Order_By = {
  angle?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Checklist_Item_Arrows_Variance_Fields = {
  __typename?: 'checklist_item_arrows_variance_fields';
  angle?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "checklist_item_arrows" */
export type Checklist_Item_Arrows_Variance_Order_By = {
  angle?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** columns and relationships of "checklist_items" */
export type Checklist_Items = {
  __typename?: 'checklist_items';
  /** An array relationship */
  arrows: Array<Checklist_Item_Arrows>;
  /** An aggregated array relationship */
  arrows_aggregate: Checklist_Item_Arrows_Aggregate;
  checklist_id: Scalars['uuid'];
  comment?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  /** An object relationship */
  file?: Maybe<Files>;
  file_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  order: Scalars['Int'];
  placement?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['String']>;
  ue?: Maybe<Scalars['String']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
};


/** columns and relationships of "checklist_items" */
export type Checklist_ItemsArrowsArgs = {
  distinct_on?: Maybe<Array<Checklist_Item_Arrows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Checklist_Item_Arrows_Order_By>>;
  where?: Maybe<Checklist_Item_Arrows_Bool_Exp>;
};


/** columns and relationships of "checklist_items" */
export type Checklist_ItemsArrows_AggregateArgs = {
  distinct_on?: Maybe<Array<Checklist_Item_Arrows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Checklist_Item_Arrows_Order_By>>;
  where?: Maybe<Checklist_Item_Arrows_Bool_Exp>;
};

/** aggregated selection of "checklist_items" */
export type Checklist_Items_Aggregate = {
  __typename?: 'checklist_items_aggregate';
  aggregate?: Maybe<Checklist_Items_Aggregate_Fields>;
  nodes: Array<Checklist_Items>;
};

/** aggregate fields of "checklist_items" */
export type Checklist_Items_Aggregate_Fields = {
  __typename?: 'checklist_items_aggregate_fields';
  avg?: Maybe<Checklist_Items_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Checklist_Items_Max_Fields>;
  min?: Maybe<Checklist_Items_Min_Fields>;
  stddev?: Maybe<Checklist_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Checklist_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Checklist_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Checklist_Items_Sum_Fields>;
  var_pop?: Maybe<Checklist_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Checklist_Items_Var_Samp_Fields>;
  variance?: Maybe<Checklist_Items_Variance_Fields>;
};


/** aggregate fields of "checklist_items" */
export type Checklist_Items_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Checklist_Items_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "checklist_items" */
export type Checklist_Items_Aggregate_Order_By = {
  avg?: Maybe<Checklist_Items_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Checklist_Items_Max_Order_By>;
  min?: Maybe<Checklist_Items_Min_Order_By>;
  stddev?: Maybe<Checklist_Items_Stddev_Order_By>;
  stddev_pop?: Maybe<Checklist_Items_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Checklist_Items_Stddev_Samp_Order_By>;
  sum?: Maybe<Checklist_Items_Sum_Order_By>;
  var_pop?: Maybe<Checklist_Items_Var_Pop_Order_By>;
  var_samp?: Maybe<Checklist_Items_Var_Samp_Order_By>;
  variance?: Maybe<Checklist_Items_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "checklist_items" */
export type Checklist_Items_Arr_Rel_Insert_Input = {
  data: Array<Checklist_Items_Insert_Input>;
  on_conflict?: Maybe<Checklist_Items_On_Conflict>;
};

/** aggregate avg on columns */
export type Checklist_Items_Avg_Fields = {
  __typename?: 'checklist_items_avg_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "checklist_items" */
export type Checklist_Items_Avg_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "checklist_items". All fields are combined with a logical 'AND'. */
export type Checklist_Items_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Checklist_Items_Bool_Exp>>>;
  _not?: Maybe<Checklist_Items_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Checklist_Items_Bool_Exp>>>;
  arrows?: Maybe<Checklist_Item_Arrows_Bool_Exp>;
  checklist_id?: Maybe<Uuid_Comparison_Exp>;
  comment?: Maybe<String_Comparison_Exp>;
  completed?: Maybe<Boolean_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  end_date?: Maybe<String_Comparison_Exp>;
  file?: Maybe<Files_Bool_Exp>;
  file_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  placement?: Maybe<String_Comparison_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  start_date?: Maybe<String_Comparison_Exp>;
  ue?: Maybe<String_Comparison_Exp>;
  x?: Maybe<Numeric_Comparison_Exp>;
  y?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "checklist_items" */
export enum Checklist_Items_Constraint {
  /** unique or primary key constraint */
  ChecklistItemsPkey = 'checklist_items_pkey'
}

/** input type for incrementing integer column in table "checklist_items" */
export type Checklist_Items_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "checklist_items" */
export type Checklist_Items_Insert_Input = {
  arrows?: Maybe<Checklist_Item_Arrows_Arr_Rel_Insert_Input>;
  checklist_id?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  file?: Maybe<Files_Obj_Rel_Insert_Input>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  placement?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['String']>;
  ue?: Maybe<Scalars['String']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Checklist_Items_Max_Fields = {
  __typename?: 'checklist_items_max_fields';
  checklist_id?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  placement?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['String']>;
  ue?: Maybe<Scalars['String']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "checklist_items" */
export type Checklist_Items_Max_Order_By = {
  checklist_id?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  placement?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  ue?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Checklist_Items_Min_Fields = {
  __typename?: 'checklist_items_min_fields';
  checklist_id?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  placement?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['String']>;
  ue?: Maybe<Scalars['String']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "checklist_items" */
export type Checklist_Items_Min_Order_By = {
  checklist_id?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  placement?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  ue?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** response of any mutation on the table "checklist_items" */
export type Checklist_Items_Mutation_Response = {
  __typename?: 'checklist_items_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Checklist_Items>;
};

/** input type for inserting object relation for remote table "checklist_items" */
export type Checklist_Items_Obj_Rel_Insert_Input = {
  data: Checklist_Items_Insert_Input;
  on_conflict?: Maybe<Checklist_Items_On_Conflict>;
};

/** on conflict condition type for table "checklist_items" */
export type Checklist_Items_On_Conflict = {
  constraint: Checklist_Items_Constraint;
  update_columns: Array<Checklist_Items_Update_Column>;
  where?: Maybe<Checklist_Items_Bool_Exp>;
};

/** ordering options when selecting data from "checklist_items" */
export type Checklist_Items_Order_By = {
  arrows_aggregate?: Maybe<Checklist_Item_Arrows_Aggregate_Order_By>;
  checklist_id?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  completed?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  end_date?: Maybe<Order_By>;
  file?: Maybe<Files_Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  placement?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  ue?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** primary key columns input for table: "checklist_items" */
export type Checklist_Items_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "checklist_items" */
export enum Checklist_Items_Select_Column {
  /** column name */
  ChecklistId = 'checklist_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  Completed = 'completed',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Placement = 'placement',
  /** column name */
  Price = 'price',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Ue = 'ue',
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y'
}

/** input type for updating data in table "checklist_items" */
export type Checklist_Items_Set_Input = {
  checklist_id?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  placement?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  start_date?: Maybe<Scalars['String']>;
  ue?: Maybe<Scalars['String']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Checklist_Items_Stddev_Fields = {
  __typename?: 'checklist_items_stddev_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "checklist_items" */
export type Checklist_Items_Stddev_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Checklist_Items_Stddev_Pop_Fields = {
  __typename?: 'checklist_items_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "checklist_items" */
export type Checklist_Items_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Checklist_Items_Stddev_Samp_Fields = {
  __typename?: 'checklist_items_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "checklist_items" */
export type Checklist_Items_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Checklist_Items_Sum_Fields = {
  __typename?: 'checklist_items_sum_fields';
  order?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "checklist_items" */
export type Checklist_Items_Sum_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** update columns of table "checklist_items" */
export enum Checklist_Items_Update_Column {
  /** column name */
  ChecklistId = 'checklist_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  Completed = 'completed',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Placement = 'placement',
  /** column name */
  Price = 'price',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Ue = 'ue',
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y'
}

/** aggregate var_pop on columns */
export type Checklist_Items_Var_Pop_Fields = {
  __typename?: 'checklist_items_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "checklist_items" */
export type Checklist_Items_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Checklist_Items_Var_Samp_Fields = {
  __typename?: 'checklist_items_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "checklist_items" */
export type Checklist_Items_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Checklist_Items_Variance_Fields = {
  __typename?: 'checklist_items_variance_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "checklist_items" */
export type Checklist_Items_Variance_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** columns and relationships of "construction_plan_entries" */
export type Construction_Plan_Entries = {
  __typename?: 'construction_plan_entries';
  /** An object relationship */
  construction_plan: Construction_Plans;
  construction_plan_id: Scalars['uuid'];
  days_after: Scalars['Int'];
  id: Scalars['uuid'];
  name: Scalars['String'];
};

/** aggregated selection of "construction_plan_entries" */
export type Construction_Plan_Entries_Aggregate = {
  __typename?: 'construction_plan_entries_aggregate';
  aggregate?: Maybe<Construction_Plan_Entries_Aggregate_Fields>;
  nodes: Array<Construction_Plan_Entries>;
};

/** aggregate fields of "construction_plan_entries" */
export type Construction_Plan_Entries_Aggregate_Fields = {
  __typename?: 'construction_plan_entries_aggregate_fields';
  avg?: Maybe<Construction_Plan_Entries_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Construction_Plan_Entries_Max_Fields>;
  min?: Maybe<Construction_Plan_Entries_Min_Fields>;
  stddev?: Maybe<Construction_Plan_Entries_Stddev_Fields>;
  stddev_pop?: Maybe<Construction_Plan_Entries_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Construction_Plan_Entries_Stddev_Samp_Fields>;
  sum?: Maybe<Construction_Plan_Entries_Sum_Fields>;
  var_pop?: Maybe<Construction_Plan_Entries_Var_Pop_Fields>;
  var_samp?: Maybe<Construction_Plan_Entries_Var_Samp_Fields>;
  variance?: Maybe<Construction_Plan_Entries_Variance_Fields>;
};


/** aggregate fields of "construction_plan_entries" */
export type Construction_Plan_Entries_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Construction_Plan_Entries_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "construction_plan_entries" */
export type Construction_Plan_Entries_Aggregate_Order_By = {
  avg?: Maybe<Construction_Plan_Entries_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Construction_Plan_Entries_Max_Order_By>;
  min?: Maybe<Construction_Plan_Entries_Min_Order_By>;
  stddev?: Maybe<Construction_Plan_Entries_Stddev_Order_By>;
  stddev_pop?: Maybe<Construction_Plan_Entries_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Construction_Plan_Entries_Stddev_Samp_Order_By>;
  sum?: Maybe<Construction_Plan_Entries_Sum_Order_By>;
  var_pop?: Maybe<Construction_Plan_Entries_Var_Pop_Order_By>;
  var_samp?: Maybe<Construction_Plan_Entries_Var_Samp_Order_By>;
  variance?: Maybe<Construction_Plan_Entries_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "construction_plan_entries" */
export type Construction_Plan_Entries_Arr_Rel_Insert_Input = {
  data: Array<Construction_Plan_Entries_Insert_Input>;
  on_conflict?: Maybe<Construction_Plan_Entries_On_Conflict>;
};

/** aggregate avg on columns */
export type Construction_Plan_Entries_Avg_Fields = {
  __typename?: 'construction_plan_entries_avg_fields';
  days_after?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "construction_plan_entries" */
export type Construction_Plan_Entries_Avg_Order_By = {
  days_after?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "construction_plan_entries". All fields are combined with a logical 'AND'. */
export type Construction_Plan_Entries_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Construction_Plan_Entries_Bool_Exp>>>;
  _not?: Maybe<Construction_Plan_Entries_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Construction_Plan_Entries_Bool_Exp>>>;
  construction_plan?: Maybe<Construction_Plans_Bool_Exp>;
  construction_plan_id?: Maybe<Uuid_Comparison_Exp>;
  days_after?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "construction_plan_entries" */
export enum Construction_Plan_Entries_Constraint {
  /** unique or primary key constraint */
  ConstructionPlanEntriesPkey = 'construction_plan_entries_pkey'
}

/** input type for incrementing integer column in table "construction_plan_entries" */
export type Construction_Plan_Entries_Inc_Input = {
  days_after?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "construction_plan_entries" */
export type Construction_Plan_Entries_Insert_Input = {
  construction_plan?: Maybe<Construction_Plans_Obj_Rel_Insert_Input>;
  construction_plan_id?: Maybe<Scalars['uuid']>;
  days_after?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Construction_Plan_Entries_Max_Fields = {
  __typename?: 'construction_plan_entries_max_fields';
  construction_plan_id?: Maybe<Scalars['uuid']>;
  days_after?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "construction_plan_entries" */
export type Construction_Plan_Entries_Max_Order_By = {
  construction_plan_id?: Maybe<Order_By>;
  days_after?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Construction_Plan_Entries_Min_Fields = {
  __typename?: 'construction_plan_entries_min_fields';
  construction_plan_id?: Maybe<Scalars['uuid']>;
  days_after?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "construction_plan_entries" */
export type Construction_Plan_Entries_Min_Order_By = {
  construction_plan_id?: Maybe<Order_By>;
  days_after?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "construction_plan_entries" */
export type Construction_Plan_Entries_Mutation_Response = {
  __typename?: 'construction_plan_entries_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Construction_Plan_Entries>;
};

/** input type for inserting object relation for remote table "construction_plan_entries" */
export type Construction_Plan_Entries_Obj_Rel_Insert_Input = {
  data: Construction_Plan_Entries_Insert_Input;
  on_conflict?: Maybe<Construction_Plan_Entries_On_Conflict>;
};

/** on conflict condition type for table "construction_plan_entries" */
export type Construction_Plan_Entries_On_Conflict = {
  constraint: Construction_Plan_Entries_Constraint;
  update_columns: Array<Construction_Plan_Entries_Update_Column>;
  where?: Maybe<Construction_Plan_Entries_Bool_Exp>;
};

/** ordering options when selecting data from "construction_plan_entries" */
export type Construction_Plan_Entries_Order_By = {
  construction_plan?: Maybe<Construction_Plans_Order_By>;
  construction_plan_id?: Maybe<Order_By>;
  days_after?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "construction_plan_entries" */
export type Construction_Plan_Entries_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "construction_plan_entries" */
export enum Construction_Plan_Entries_Select_Column {
  /** column name */
  ConstructionPlanId = 'construction_plan_id',
  /** column name */
  DaysAfter = 'days_after',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "construction_plan_entries" */
export type Construction_Plan_Entries_Set_Input = {
  construction_plan_id?: Maybe<Scalars['uuid']>;
  days_after?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Construction_Plan_Entries_Stddev_Fields = {
  __typename?: 'construction_plan_entries_stddev_fields';
  days_after?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "construction_plan_entries" */
export type Construction_Plan_Entries_Stddev_Order_By = {
  days_after?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Construction_Plan_Entries_Stddev_Pop_Fields = {
  __typename?: 'construction_plan_entries_stddev_pop_fields';
  days_after?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "construction_plan_entries" */
export type Construction_Plan_Entries_Stddev_Pop_Order_By = {
  days_after?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Construction_Plan_Entries_Stddev_Samp_Fields = {
  __typename?: 'construction_plan_entries_stddev_samp_fields';
  days_after?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "construction_plan_entries" */
export type Construction_Plan_Entries_Stddev_Samp_Order_By = {
  days_after?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Construction_Plan_Entries_Sum_Fields = {
  __typename?: 'construction_plan_entries_sum_fields';
  days_after?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "construction_plan_entries" */
export type Construction_Plan_Entries_Sum_Order_By = {
  days_after?: Maybe<Order_By>;
};

/** update columns of table "construction_plan_entries" */
export enum Construction_Plan_Entries_Update_Column {
  /** column name */
  ConstructionPlanId = 'construction_plan_id',
  /** column name */
  DaysAfter = 'days_after',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Construction_Plan_Entries_Var_Pop_Fields = {
  __typename?: 'construction_plan_entries_var_pop_fields';
  days_after?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "construction_plan_entries" */
export type Construction_Plan_Entries_Var_Pop_Order_By = {
  days_after?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Construction_Plan_Entries_Var_Samp_Fields = {
  __typename?: 'construction_plan_entries_var_samp_fields';
  days_after?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "construction_plan_entries" */
export type Construction_Plan_Entries_Var_Samp_Order_By = {
  days_after?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Construction_Plan_Entries_Variance_Fields = {
  __typename?: 'construction_plan_entries_variance_fields';
  days_after?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "construction_plan_entries" */
export type Construction_Plan_Entries_Variance_Order_By = {
  days_after?: Maybe<Order_By>;
};

/** columns and relationships of "construction_plans" */
export type Construction_Plans = {
  __typename?: 'construction_plans';
  /** An array relationship */
  entries: Array<Construction_Plan_Entries>;
  /** An aggregated array relationship */
  entries_aggregate: Construction_Plan_Entries_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
};


/** columns and relationships of "construction_plans" */
export type Construction_PlansEntriesArgs = {
  distinct_on?: Maybe<Array<Construction_Plan_Entries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Construction_Plan_Entries_Order_By>>;
  where?: Maybe<Construction_Plan_Entries_Bool_Exp>;
};


/** columns and relationships of "construction_plans" */
export type Construction_PlansEntries_AggregateArgs = {
  distinct_on?: Maybe<Array<Construction_Plan_Entries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Construction_Plan_Entries_Order_By>>;
  where?: Maybe<Construction_Plan_Entries_Bool_Exp>;
};

/** aggregated selection of "construction_plans" */
export type Construction_Plans_Aggregate = {
  __typename?: 'construction_plans_aggregate';
  aggregate?: Maybe<Construction_Plans_Aggregate_Fields>;
  nodes: Array<Construction_Plans>;
};

/** aggregate fields of "construction_plans" */
export type Construction_Plans_Aggregate_Fields = {
  __typename?: 'construction_plans_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Construction_Plans_Max_Fields>;
  min?: Maybe<Construction_Plans_Min_Fields>;
};


/** aggregate fields of "construction_plans" */
export type Construction_Plans_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Construction_Plans_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "construction_plans" */
export type Construction_Plans_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Construction_Plans_Max_Order_By>;
  min?: Maybe<Construction_Plans_Min_Order_By>;
};

/** input type for inserting array relation for remote table "construction_plans" */
export type Construction_Plans_Arr_Rel_Insert_Input = {
  data: Array<Construction_Plans_Insert_Input>;
  on_conflict?: Maybe<Construction_Plans_On_Conflict>;
};

/** Boolean expression to filter rows from the table "construction_plans". All fields are combined with a logical 'AND'. */
export type Construction_Plans_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Construction_Plans_Bool_Exp>>>;
  _not?: Maybe<Construction_Plans_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Construction_Plans_Bool_Exp>>>;
  entries?: Maybe<Construction_Plan_Entries_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "construction_plans" */
export enum Construction_Plans_Constraint {
  /** unique or primary key constraint */
  ConstructionPlansPkey = 'construction_plans_pkey'
}

/** input type for inserting data into table "construction_plans" */
export type Construction_Plans_Insert_Input = {
  entries?: Maybe<Construction_Plan_Entries_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Construction_Plans_Max_Fields = {
  __typename?: 'construction_plans_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "construction_plans" */
export type Construction_Plans_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Construction_Plans_Min_Fields = {
  __typename?: 'construction_plans_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "construction_plans" */
export type Construction_Plans_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "construction_plans" */
export type Construction_Plans_Mutation_Response = {
  __typename?: 'construction_plans_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Construction_Plans>;
};

/** input type for inserting object relation for remote table "construction_plans" */
export type Construction_Plans_Obj_Rel_Insert_Input = {
  data: Construction_Plans_Insert_Input;
  on_conflict?: Maybe<Construction_Plans_On_Conflict>;
};

/** on conflict condition type for table "construction_plans" */
export type Construction_Plans_On_Conflict = {
  constraint: Construction_Plans_Constraint;
  update_columns: Array<Construction_Plans_Update_Column>;
  where?: Maybe<Construction_Plans_Bool_Exp>;
};

/** ordering options when selecting data from "construction_plans" */
export type Construction_Plans_Order_By = {
  entries_aggregate?: Maybe<Construction_Plan_Entries_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "construction_plans" */
export type Construction_Plans_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "construction_plans" */
export enum Construction_Plans_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "construction_plans" */
export type Construction_Plans_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "construction_plans" */
export enum Construction_Plans_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** columns and relationships of "contacts" */
export type Contacts = {
  __typename?: 'contacts';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  id: Scalars['uuid'];
  last_name: Scalars['String'];
  mail?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  synced: Scalars['Boolean'];
  type?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregated selection of "contacts" */
export type Contacts_Aggregate = {
  __typename?: 'contacts_aggregate';
  aggregate?: Maybe<Contacts_Aggregate_Fields>;
  nodes: Array<Contacts>;
};

/** aggregate fields of "contacts" */
export type Contacts_Aggregate_Fields = {
  __typename?: 'contacts_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Contacts_Max_Fields>;
  min?: Maybe<Contacts_Min_Fields>;
};


/** aggregate fields of "contacts" */
export type Contacts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contacts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contacts" */
export type Contacts_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Contacts_Max_Order_By>;
  min?: Maybe<Contacts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "contacts" */
export type Contacts_Arr_Rel_Insert_Input = {
  data: Array<Contacts_Insert_Input>;
  on_conflict?: Maybe<Contacts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "contacts". All fields are combined with a logical 'AND'. */
export type Contacts_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Contacts_Bool_Exp>>>;
  _not?: Maybe<Contacts_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Contacts_Bool_Exp>>>;
  address?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  company?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  mail?: Maybe<String_Comparison_Exp>;
  mobile?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  synced?: Maybe<Boolean_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  zip_code?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contacts" */
export enum Contacts_Constraint {
  /** unique or primary key constraint */
  ContactsPkey = 'contacts_pkey'
}

/** input type for inserting data into table "contacts" */
export type Contacts_Insert_Input = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  synced?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Contacts_Max_Fields = {
  __typename?: 'contacts_max_fields';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "contacts" */
export type Contacts_Max_Order_By = {
  address?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  company?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  mail?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Contacts_Min_Fields = {
  __typename?: 'contacts_min_fields';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "contacts" */
export type Contacts_Min_Order_By = {
  address?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  company?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  mail?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

/** response of any mutation on the table "contacts" */
export type Contacts_Mutation_Response = {
  __typename?: 'contacts_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Contacts>;
};

/** input type for inserting object relation for remote table "contacts" */
export type Contacts_Obj_Rel_Insert_Input = {
  data: Contacts_Insert_Input;
  on_conflict?: Maybe<Contacts_On_Conflict>;
};

/** on conflict condition type for table "contacts" */
export type Contacts_On_Conflict = {
  constraint: Contacts_Constraint;
  update_columns: Array<Contacts_Update_Column>;
  where?: Maybe<Contacts_Bool_Exp>;
};

/** ordering options when selecting data from "contacts" */
export type Contacts_Order_By = {
  address?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  company?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  mail?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  synced?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

/** primary key columns input for table: "contacts" */
export type Contacts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contacts" */
export enum Contacts_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  Company = 'company',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Mail = 'mail',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Phone = 'phone',
  /** column name */
  Synced = 'synced',
  /** column name */
  Type = 'type',
  /** column name */
  ZipCode = 'zip_code'
}

/** input type for updating data in table "contacts" */
export type Contacts_Set_Input = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  synced?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** update columns of table "contacts" */
export enum Contacts_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  Company = 'company',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Mail = 'mail',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Phone = 'phone',
  /** column name */
  Synced = 'synced',
  /** column name */
  Type = 'type',
  /** column name */
  ZipCode = 'zip_code'
}

/** columns and relationships of "files" */
export type Files = {
  __typename?: 'files';
  created: Scalars['String'];
  file_path: Scalars['String'];
  filename: Scalars['String'];
  id: Scalars['uuid'];
  share_key: Scalars['uuid'];
};

/** aggregated selection of "files" */
export type Files_Aggregate = {
  __typename?: 'files_aggregate';
  aggregate?: Maybe<Files_Aggregate_Fields>;
  nodes: Array<Files>;
};

/** aggregate fields of "files" */
export type Files_Aggregate_Fields = {
  __typename?: 'files_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Files_Max_Fields>;
  min?: Maybe<Files_Min_Fields>;
};


/** aggregate fields of "files" */
export type Files_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Files_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "files" */
export type Files_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Files_Max_Order_By>;
  min?: Maybe<Files_Min_Order_By>;
};

/** input type for inserting array relation for remote table "files" */
export type Files_Arr_Rel_Insert_Input = {
  data: Array<Files_Insert_Input>;
  on_conflict?: Maybe<Files_On_Conflict>;
};

/** Boolean expression to filter rows from the table "files". All fields are combined with a logical 'AND'. */
export type Files_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Files_Bool_Exp>>>;
  _not?: Maybe<Files_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Files_Bool_Exp>>>;
  created?: Maybe<String_Comparison_Exp>;
  file_path?: Maybe<String_Comparison_Exp>;
  filename?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  share_key?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "files" */
export enum Files_Constraint {
  /** unique or primary key constraint */
  FilesPkey = 'files_pkey'
}

/** input type for inserting data into table "files" */
export type Files_Insert_Input = {
  created?: Maybe<Scalars['String']>;
  file_path?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  share_key?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Files_Max_Fields = {
  __typename?: 'files_max_fields';
  created?: Maybe<Scalars['String']>;
  file_path?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  share_key?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "files" */
export type Files_Max_Order_By = {
  created?: Maybe<Order_By>;
  file_path?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  share_key?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Files_Min_Fields = {
  __typename?: 'files_min_fields';
  created?: Maybe<Scalars['String']>;
  file_path?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  share_key?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "files" */
export type Files_Min_Order_By = {
  created?: Maybe<Order_By>;
  file_path?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  share_key?: Maybe<Order_By>;
};

/** response of any mutation on the table "files" */
export type Files_Mutation_Response = {
  __typename?: 'files_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Files>;
};

/** input type for inserting object relation for remote table "files" */
export type Files_Obj_Rel_Insert_Input = {
  data: Files_Insert_Input;
  on_conflict?: Maybe<Files_On_Conflict>;
};

/** on conflict condition type for table "files" */
export type Files_On_Conflict = {
  constraint: Files_Constraint;
  update_columns: Array<Files_Update_Column>;
  where?: Maybe<Files_Bool_Exp>;
};

/** ordering options when selecting data from "files" */
export type Files_Order_By = {
  created?: Maybe<Order_By>;
  file_path?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  share_key?: Maybe<Order_By>;
};

/** primary key columns input for table: "files" */
export type Files_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "files" */
export enum Files_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  ShareKey = 'share_key'
}

/** input type for updating data in table "files" */
export type Files_Set_Input = {
  created?: Maybe<Scalars['String']>;
  file_path?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  share_key?: Maybe<Scalars['uuid']>;
};

/** update columns of table "files" */
export enum Files_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  ShareKey = 'share_key'
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "absence" */
  delete_absence?: Maybe<Absence_Mutation_Response>;
  /** delete single row from the table: "absence" */
  delete_absence_by_pk?: Maybe<Absence>;
  /** delete data from the table: "absence_search_view" */
  delete_absence_search_view?: Maybe<Absence_Search_View_Mutation_Response>;
  /** delete data from the table: "absence_types" */
  delete_absence_types?: Maybe<Absence_Types_Mutation_Response>;
  /** delete single row from the table: "absence_types" */
  delete_absence_types_by_pk?: Maybe<Absence_Types>;
  /** delete data from the table: "archive_files" */
  delete_archive_files?: Maybe<Archive_Files_Mutation_Response>;
  /** delete single row from the table: "archive_files" */
  delete_archive_files_by_pk?: Maybe<Archive_Files>;
  /** delete data from the table: "case_doc_checklists" */
  delete_case_doc_checklists?: Maybe<Case_Doc_Checklists_Mutation_Response>;
  /** delete single row from the table: "case_doc_checklists" */
  delete_case_doc_checklists_by_pk?: Maybe<Case_Doc_Checklists>;
  /** delete data from the table: "case_doc_economy" */
  delete_case_doc_economy?: Maybe<Case_Doc_Economy_Mutation_Response>;
  /** delete single row from the table: "case_doc_economy" */
  delete_case_doc_economy_by_pk?: Maybe<Case_Doc_Economy>;
  /** delete data from the table: "case_doc_notes" */
  delete_case_doc_notes?: Maybe<Case_Doc_Notes_Mutation_Response>;
  /** delete single row from the table: "case_doc_notes" */
  delete_case_doc_notes_by_pk?: Maybe<Case_Doc_Notes>;
  /** delete data from the table: "case_doc_order_confirmations" */
  delete_case_doc_order_confirmations?: Maybe<Case_Doc_Order_Confirmations_Mutation_Response>;
  /** delete single row from the table: "case_doc_order_confirmations" */
  delete_case_doc_order_confirmations_by_pk?: Maybe<Case_Doc_Order_Confirmations>;
  /** delete data from the table: "case_doc_quality_controls" */
  delete_case_doc_quality_controls?: Maybe<Case_Doc_Quality_Controls_Mutation_Response>;
  /** delete single row from the table: "case_doc_quality_controls" */
  delete_case_doc_quality_controls_by_pk?: Maybe<Case_Doc_Quality_Controls>;
  /** delete data from the table: "case_files" */
  delete_case_files?: Maybe<Case_Files_Mutation_Response>;
  /** delete single row from the table: "case_files" */
  delete_case_files_by_pk?: Maybe<Case_Files>;
  /** delete data from the table: "case_photos" */
  delete_case_photos?: Maybe<Case_Photos_Mutation_Response>;
  /** delete single row from the table: "case_photos" */
  delete_case_photos_by_pk?: Maybe<Case_Photos>;
  /** delete data from the table: "case_suppliers" */
  delete_case_suppliers?: Maybe<Case_Suppliers_Mutation_Response>;
  /** delete single row from the table: "case_suppliers" */
  delete_case_suppliers_by_pk?: Maybe<Case_Suppliers>;
  /** delete data from the table: "case_time_entries" */
  delete_case_time_entries?: Maybe<Case_Time_Entries_Mutation_Response>;
  /** delete single row from the table: "case_time_entries" */
  delete_case_time_entries_by_pk?: Maybe<Case_Time_Entries>;
  /** delete data from the table: "case_types" */
  delete_case_types?: Maybe<Case_Types_Mutation_Response>;
  /** delete single row from the table: "case_types" */
  delete_case_types_by_pk?: Maybe<Case_Types>;
  /** delete data from the table: "cases" */
  delete_cases?: Maybe<Cases_Mutation_Response>;
  /** delete single row from the table: "cases" */
  delete_cases_by_pk?: Maybe<Cases>;
  /** delete data from the table: "checklist_item_arrows" */
  delete_checklist_item_arrows?: Maybe<Checklist_Item_Arrows_Mutation_Response>;
  /** delete single row from the table: "checklist_item_arrows" */
  delete_checklist_item_arrows_by_pk?: Maybe<Checklist_Item_Arrows>;
  /** delete data from the table: "checklist_items" */
  delete_checklist_items?: Maybe<Checklist_Items_Mutation_Response>;
  /** delete single row from the table: "checklist_items" */
  delete_checklist_items_by_pk?: Maybe<Checklist_Items>;
  /** delete data from the table: "construction_plan_entries" */
  delete_construction_plan_entries?: Maybe<Construction_Plan_Entries_Mutation_Response>;
  /** delete single row from the table: "construction_plan_entries" */
  delete_construction_plan_entries_by_pk?: Maybe<Construction_Plan_Entries>;
  /** delete data from the table: "construction_plans" */
  delete_construction_plans?: Maybe<Construction_Plans_Mutation_Response>;
  /** delete single row from the table: "construction_plans" */
  delete_construction_plans_by_pk?: Maybe<Construction_Plans>;
  /** delete data from the table: "contacts" */
  delete_contacts?: Maybe<Contacts_Mutation_Response>;
  /** delete single row from the table: "contacts" */
  delete_contacts_by_pk?: Maybe<Contacts>;
  /** delete data from the table: "files" */
  delete_files?: Maybe<Files_Mutation_Response>;
  /** delete single row from the table: "files" */
  delete_files_by_pk?: Maybe<Files>;
  /** delete data from the table: "order_confirmation_tasks" */
  delete_order_confirmation_tasks?: Maybe<Order_Confirmation_Tasks_Mutation_Response>;
  /** delete single row from the table: "order_confirmation_tasks" */
  delete_order_confirmation_tasks_by_pk?: Maybe<Order_Confirmation_Tasks>;
  /** delete data from the table: "order_confirmation_template_tasks" */
  delete_order_confirmation_template_tasks?: Maybe<Order_Confirmation_Template_Tasks_Mutation_Response>;
  /** delete single row from the table: "order_confirmation_template_tasks" */
  delete_order_confirmation_template_tasks_by_pk?: Maybe<Order_Confirmation_Template_Tasks>;
  /** delete data from the table: "order_confirmation_templates" */
  delete_order_confirmation_templates?: Maybe<Order_Confirmation_Templates_Mutation_Response>;
  /** delete single row from the table: "order_confirmation_templates" */
  delete_order_confirmation_templates_by_pk?: Maybe<Order_Confirmation_Templates>;
  /** delete data from the table: "quality_control_task_arrows" */
  delete_quality_control_task_arrows?: Maybe<Quality_Control_Task_Arrows_Mutation_Response>;
  /** delete single row from the table: "quality_control_task_arrows" */
  delete_quality_control_task_arrows_by_pk?: Maybe<Quality_Control_Task_Arrows>;
  /** delete data from the table: "quality_control_tasks" */
  delete_quality_control_tasks?: Maybe<Quality_Control_Tasks_Mutation_Response>;
  /** delete single row from the table: "quality_control_tasks" */
  delete_quality_control_tasks_by_pk?: Maybe<Quality_Control_Tasks>;
  /** delete data from the table: "quality_control_template_tasks" */
  delete_quality_control_template_tasks?: Maybe<Quality_Control_Template_Tasks_Mutation_Response>;
  /** delete single row from the table: "quality_control_template_tasks" */
  delete_quality_control_template_tasks_by_pk?: Maybe<Quality_Control_Template_Tasks>;
  /** delete data from the table: "quality_control_templates" */
  delete_quality_control_templates?: Maybe<Quality_Control_Templates_Mutation_Response>;
  /** delete single row from the table: "quality_control_templates" */
  delete_quality_control_templates_by_pk?: Maybe<Quality_Control_Templates>;
  /** delete data from the table: "reminder_recipients" */
  delete_reminder_recipients?: Maybe<Reminder_Recipients_Mutation_Response>;
  /** delete single row from the table: "reminder_recipients" */
  delete_reminder_recipients_by_pk?: Maybe<Reminder_Recipients>;
  /** delete data from the table: "reminders" */
  delete_reminders?: Maybe<Reminders_Mutation_Response>;
  /** delete single row from the table: "reminders" */
  delete_reminders_by_pk?: Maybe<Reminders>;
  /** delete data from the table: "request_notes" */
  delete_request_notes?: Maybe<Request_Notes_Mutation_Response>;
  /** delete single row from the table: "request_notes" */
  delete_request_notes_by_pk?: Maybe<Request_Notes>;
  /** delete data from the table: "requests" */
  delete_requests?: Maybe<Requests_Mutation_Response>;
  /** delete single row from the table: "requests" */
  delete_requests_by_pk?: Maybe<Requests>;
  /** delete data from the table: "standard_photos" */
  delete_standard_photos?: Maybe<Standard_Photos_Mutation_Response>;
  /** delete single row from the table: "standard_photos" */
  delete_standard_photos_by_pk?: Maybe<Standard_Photos>;
  /** delete data from the table: "standard_terms" */
  delete_standard_terms?: Maybe<Standard_Terms_Mutation_Response>;
  /** delete single row from the table: "standard_terms" */
  delete_standard_terms_by_pk?: Maybe<Standard_Terms>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** insert data into the table: "absence" */
  insert_absence?: Maybe<Absence_Mutation_Response>;
  /** insert a single row into the table: "absence" */
  insert_absence_one?: Maybe<Absence>;
  /** insert data into the table: "absence_search_view" */
  insert_absence_search_view?: Maybe<Absence_Search_View_Mutation_Response>;
  /** insert a single row into the table: "absence_search_view" */
  insert_absence_search_view_one?: Maybe<Absence_Search_View>;
  /** insert data into the table: "absence_types" */
  insert_absence_types?: Maybe<Absence_Types_Mutation_Response>;
  /** insert a single row into the table: "absence_types" */
  insert_absence_types_one?: Maybe<Absence_Types>;
  /** insert data into the table: "archive_files" */
  insert_archive_files?: Maybe<Archive_Files_Mutation_Response>;
  /** insert a single row into the table: "archive_files" */
  insert_archive_files_one?: Maybe<Archive_Files>;
  /** insert data into the table: "case_doc_checklists" */
  insert_case_doc_checklists?: Maybe<Case_Doc_Checklists_Mutation_Response>;
  /** insert a single row into the table: "case_doc_checklists" */
  insert_case_doc_checklists_one?: Maybe<Case_Doc_Checklists>;
  /** insert data into the table: "case_doc_economy" */
  insert_case_doc_economy?: Maybe<Case_Doc_Economy_Mutation_Response>;
  /** insert a single row into the table: "case_doc_economy" */
  insert_case_doc_economy_one?: Maybe<Case_Doc_Economy>;
  /** insert data into the table: "case_doc_notes" */
  insert_case_doc_notes?: Maybe<Case_Doc_Notes_Mutation_Response>;
  /** insert a single row into the table: "case_doc_notes" */
  insert_case_doc_notes_one?: Maybe<Case_Doc_Notes>;
  /** insert data into the table: "case_doc_order_confirmations" */
  insert_case_doc_order_confirmations?: Maybe<Case_Doc_Order_Confirmations_Mutation_Response>;
  /** insert a single row into the table: "case_doc_order_confirmations" */
  insert_case_doc_order_confirmations_one?: Maybe<Case_Doc_Order_Confirmations>;
  /** insert data into the table: "case_doc_quality_controls" */
  insert_case_doc_quality_controls?: Maybe<Case_Doc_Quality_Controls_Mutation_Response>;
  /** insert a single row into the table: "case_doc_quality_controls" */
  insert_case_doc_quality_controls_one?: Maybe<Case_Doc_Quality_Controls>;
  /** insert data into the table: "case_files" */
  insert_case_files?: Maybe<Case_Files_Mutation_Response>;
  /** insert a single row into the table: "case_files" */
  insert_case_files_one?: Maybe<Case_Files>;
  /** insert data into the table: "case_photos" */
  insert_case_photos?: Maybe<Case_Photos_Mutation_Response>;
  /** insert a single row into the table: "case_photos" */
  insert_case_photos_one?: Maybe<Case_Photos>;
  /** insert data into the table: "case_suppliers" */
  insert_case_suppliers?: Maybe<Case_Suppliers_Mutation_Response>;
  /** insert a single row into the table: "case_suppliers" */
  insert_case_suppliers_one?: Maybe<Case_Suppliers>;
  /** insert data into the table: "case_time_entries" */
  insert_case_time_entries?: Maybe<Case_Time_Entries_Mutation_Response>;
  /** insert a single row into the table: "case_time_entries" */
  insert_case_time_entries_one?: Maybe<Case_Time_Entries>;
  /** insert data into the table: "case_types" */
  insert_case_types?: Maybe<Case_Types_Mutation_Response>;
  /** insert a single row into the table: "case_types" */
  insert_case_types_one?: Maybe<Case_Types>;
  /** insert data into the table: "cases" */
  insert_cases?: Maybe<Cases_Mutation_Response>;
  /** insert a single row into the table: "cases" */
  insert_cases_one?: Maybe<Cases>;
  /** insert data into the table: "checklist_item_arrows" */
  insert_checklist_item_arrows?: Maybe<Checklist_Item_Arrows_Mutation_Response>;
  /** insert a single row into the table: "checklist_item_arrows" */
  insert_checklist_item_arrows_one?: Maybe<Checklist_Item_Arrows>;
  /** insert data into the table: "checklist_items" */
  insert_checklist_items?: Maybe<Checklist_Items_Mutation_Response>;
  /** insert a single row into the table: "checklist_items" */
  insert_checklist_items_one?: Maybe<Checklist_Items>;
  /** insert data into the table: "construction_plan_entries" */
  insert_construction_plan_entries?: Maybe<Construction_Plan_Entries_Mutation_Response>;
  /** insert a single row into the table: "construction_plan_entries" */
  insert_construction_plan_entries_one?: Maybe<Construction_Plan_Entries>;
  /** insert data into the table: "construction_plans" */
  insert_construction_plans?: Maybe<Construction_Plans_Mutation_Response>;
  /** insert a single row into the table: "construction_plans" */
  insert_construction_plans_one?: Maybe<Construction_Plans>;
  /** insert data into the table: "contacts" */
  insert_contacts?: Maybe<Contacts_Mutation_Response>;
  /** insert a single row into the table: "contacts" */
  insert_contacts_one?: Maybe<Contacts>;
  /** insert data into the table: "files" */
  insert_files?: Maybe<Files_Mutation_Response>;
  /** insert a single row into the table: "files" */
  insert_files_one?: Maybe<Files>;
  /** insert data into the table: "order_confirmation_tasks" */
  insert_order_confirmation_tasks?: Maybe<Order_Confirmation_Tasks_Mutation_Response>;
  /** insert a single row into the table: "order_confirmation_tasks" */
  insert_order_confirmation_tasks_one?: Maybe<Order_Confirmation_Tasks>;
  /** insert data into the table: "order_confirmation_template_tasks" */
  insert_order_confirmation_template_tasks?: Maybe<Order_Confirmation_Template_Tasks_Mutation_Response>;
  /** insert a single row into the table: "order_confirmation_template_tasks" */
  insert_order_confirmation_template_tasks_one?: Maybe<Order_Confirmation_Template_Tasks>;
  /** insert data into the table: "order_confirmation_templates" */
  insert_order_confirmation_templates?: Maybe<Order_Confirmation_Templates_Mutation_Response>;
  /** insert a single row into the table: "order_confirmation_templates" */
  insert_order_confirmation_templates_one?: Maybe<Order_Confirmation_Templates>;
  /** insert data into the table: "quality_control_task_arrows" */
  insert_quality_control_task_arrows?: Maybe<Quality_Control_Task_Arrows_Mutation_Response>;
  /** insert a single row into the table: "quality_control_task_arrows" */
  insert_quality_control_task_arrows_one?: Maybe<Quality_Control_Task_Arrows>;
  /** insert data into the table: "quality_control_tasks" */
  insert_quality_control_tasks?: Maybe<Quality_Control_Tasks_Mutation_Response>;
  /** insert a single row into the table: "quality_control_tasks" */
  insert_quality_control_tasks_one?: Maybe<Quality_Control_Tasks>;
  /** insert data into the table: "quality_control_template_tasks" */
  insert_quality_control_template_tasks?: Maybe<Quality_Control_Template_Tasks_Mutation_Response>;
  /** insert a single row into the table: "quality_control_template_tasks" */
  insert_quality_control_template_tasks_one?: Maybe<Quality_Control_Template_Tasks>;
  /** insert data into the table: "quality_control_templates" */
  insert_quality_control_templates?: Maybe<Quality_Control_Templates_Mutation_Response>;
  /** insert a single row into the table: "quality_control_templates" */
  insert_quality_control_templates_one?: Maybe<Quality_Control_Templates>;
  /** insert data into the table: "reminder_recipients" */
  insert_reminder_recipients?: Maybe<Reminder_Recipients_Mutation_Response>;
  /** insert a single row into the table: "reminder_recipients" */
  insert_reminder_recipients_one?: Maybe<Reminder_Recipients>;
  /** insert data into the table: "reminders" */
  insert_reminders?: Maybe<Reminders_Mutation_Response>;
  /** insert a single row into the table: "reminders" */
  insert_reminders_one?: Maybe<Reminders>;
  /** insert data into the table: "request_notes" */
  insert_request_notes?: Maybe<Request_Notes_Mutation_Response>;
  /** insert a single row into the table: "request_notes" */
  insert_request_notes_one?: Maybe<Request_Notes>;
  /** insert data into the table: "requests" */
  insert_requests?: Maybe<Requests_Mutation_Response>;
  /** insert a single row into the table: "requests" */
  insert_requests_one?: Maybe<Requests>;
  /** insert data into the table: "standard_photos" */
  insert_standard_photos?: Maybe<Standard_Photos_Mutation_Response>;
  /** insert a single row into the table: "standard_photos" */
  insert_standard_photos_one?: Maybe<Standard_Photos>;
  /** insert data into the table: "standard_terms" */
  insert_standard_terms?: Maybe<Standard_Terms_Mutation_Response>;
  /** insert a single row into the table: "standard_terms" */
  insert_standard_terms_one?: Maybe<Standard_Terms>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** update data of the table: "absence" */
  update_absence?: Maybe<Absence_Mutation_Response>;
  /** update single row of the table: "absence" */
  update_absence_by_pk?: Maybe<Absence>;
  /** update data of the table: "absence_search_view" */
  update_absence_search_view?: Maybe<Absence_Search_View_Mutation_Response>;
  /** update data of the table: "absence_types" */
  update_absence_types?: Maybe<Absence_Types_Mutation_Response>;
  /** update single row of the table: "absence_types" */
  update_absence_types_by_pk?: Maybe<Absence_Types>;
  /** update data of the table: "archive_files" */
  update_archive_files?: Maybe<Archive_Files_Mutation_Response>;
  /** update single row of the table: "archive_files" */
  update_archive_files_by_pk?: Maybe<Archive_Files>;
  /** update data of the table: "case_doc_checklists" */
  update_case_doc_checklists?: Maybe<Case_Doc_Checklists_Mutation_Response>;
  /** update single row of the table: "case_doc_checklists" */
  update_case_doc_checklists_by_pk?: Maybe<Case_Doc_Checklists>;
  /** update data of the table: "case_doc_economy" */
  update_case_doc_economy?: Maybe<Case_Doc_Economy_Mutation_Response>;
  /** update single row of the table: "case_doc_economy" */
  update_case_doc_economy_by_pk?: Maybe<Case_Doc_Economy>;
  /** update data of the table: "case_doc_notes" */
  update_case_doc_notes?: Maybe<Case_Doc_Notes_Mutation_Response>;
  /** update single row of the table: "case_doc_notes" */
  update_case_doc_notes_by_pk?: Maybe<Case_Doc_Notes>;
  /** update data of the table: "case_doc_order_confirmations" */
  update_case_doc_order_confirmations?: Maybe<Case_Doc_Order_Confirmations_Mutation_Response>;
  /** update single row of the table: "case_doc_order_confirmations" */
  update_case_doc_order_confirmations_by_pk?: Maybe<Case_Doc_Order_Confirmations>;
  /** update data of the table: "case_doc_quality_controls" */
  update_case_doc_quality_controls?: Maybe<Case_Doc_Quality_Controls_Mutation_Response>;
  /** update single row of the table: "case_doc_quality_controls" */
  update_case_doc_quality_controls_by_pk?: Maybe<Case_Doc_Quality_Controls>;
  /** update data of the table: "case_files" */
  update_case_files?: Maybe<Case_Files_Mutation_Response>;
  /** update single row of the table: "case_files" */
  update_case_files_by_pk?: Maybe<Case_Files>;
  /** update data of the table: "case_photos" */
  update_case_photos?: Maybe<Case_Photos_Mutation_Response>;
  /** update single row of the table: "case_photos" */
  update_case_photos_by_pk?: Maybe<Case_Photos>;
  /** update data of the table: "case_suppliers" */
  update_case_suppliers?: Maybe<Case_Suppliers_Mutation_Response>;
  /** update single row of the table: "case_suppliers" */
  update_case_suppliers_by_pk?: Maybe<Case_Suppliers>;
  /** update data of the table: "case_time_entries" */
  update_case_time_entries?: Maybe<Case_Time_Entries_Mutation_Response>;
  /** update single row of the table: "case_time_entries" */
  update_case_time_entries_by_pk?: Maybe<Case_Time_Entries>;
  /** update data of the table: "case_types" */
  update_case_types?: Maybe<Case_Types_Mutation_Response>;
  /** update single row of the table: "case_types" */
  update_case_types_by_pk?: Maybe<Case_Types>;
  /** update data of the table: "cases" */
  update_cases?: Maybe<Cases_Mutation_Response>;
  /** update single row of the table: "cases" */
  update_cases_by_pk?: Maybe<Cases>;
  /** update data of the table: "checklist_item_arrows" */
  update_checklist_item_arrows?: Maybe<Checklist_Item_Arrows_Mutation_Response>;
  /** update single row of the table: "checklist_item_arrows" */
  update_checklist_item_arrows_by_pk?: Maybe<Checklist_Item_Arrows>;
  /** update data of the table: "checklist_items" */
  update_checklist_items?: Maybe<Checklist_Items_Mutation_Response>;
  /** update single row of the table: "checklist_items" */
  update_checklist_items_by_pk?: Maybe<Checklist_Items>;
  /** update data of the table: "construction_plan_entries" */
  update_construction_plan_entries?: Maybe<Construction_Plan_Entries_Mutation_Response>;
  /** update single row of the table: "construction_plan_entries" */
  update_construction_plan_entries_by_pk?: Maybe<Construction_Plan_Entries>;
  /** update data of the table: "construction_plans" */
  update_construction_plans?: Maybe<Construction_Plans_Mutation_Response>;
  /** update single row of the table: "construction_plans" */
  update_construction_plans_by_pk?: Maybe<Construction_Plans>;
  /** update data of the table: "contacts" */
  update_contacts?: Maybe<Contacts_Mutation_Response>;
  /** update single row of the table: "contacts" */
  update_contacts_by_pk?: Maybe<Contacts>;
  /** update data of the table: "files" */
  update_files?: Maybe<Files_Mutation_Response>;
  /** update single row of the table: "files" */
  update_files_by_pk?: Maybe<Files>;
  /** update data of the table: "order_confirmation_tasks" */
  update_order_confirmation_tasks?: Maybe<Order_Confirmation_Tasks_Mutation_Response>;
  /** update single row of the table: "order_confirmation_tasks" */
  update_order_confirmation_tasks_by_pk?: Maybe<Order_Confirmation_Tasks>;
  /** update data of the table: "order_confirmation_template_tasks" */
  update_order_confirmation_template_tasks?: Maybe<Order_Confirmation_Template_Tasks_Mutation_Response>;
  /** update single row of the table: "order_confirmation_template_tasks" */
  update_order_confirmation_template_tasks_by_pk?: Maybe<Order_Confirmation_Template_Tasks>;
  /** update data of the table: "order_confirmation_templates" */
  update_order_confirmation_templates?: Maybe<Order_Confirmation_Templates_Mutation_Response>;
  /** update single row of the table: "order_confirmation_templates" */
  update_order_confirmation_templates_by_pk?: Maybe<Order_Confirmation_Templates>;
  /** update data of the table: "quality_control_task_arrows" */
  update_quality_control_task_arrows?: Maybe<Quality_Control_Task_Arrows_Mutation_Response>;
  /** update single row of the table: "quality_control_task_arrows" */
  update_quality_control_task_arrows_by_pk?: Maybe<Quality_Control_Task_Arrows>;
  /** update data of the table: "quality_control_tasks" */
  update_quality_control_tasks?: Maybe<Quality_Control_Tasks_Mutation_Response>;
  /** update single row of the table: "quality_control_tasks" */
  update_quality_control_tasks_by_pk?: Maybe<Quality_Control_Tasks>;
  /** update data of the table: "quality_control_template_tasks" */
  update_quality_control_template_tasks?: Maybe<Quality_Control_Template_Tasks_Mutation_Response>;
  /** update single row of the table: "quality_control_template_tasks" */
  update_quality_control_template_tasks_by_pk?: Maybe<Quality_Control_Template_Tasks>;
  /** update data of the table: "quality_control_templates" */
  update_quality_control_templates?: Maybe<Quality_Control_Templates_Mutation_Response>;
  /** update single row of the table: "quality_control_templates" */
  update_quality_control_templates_by_pk?: Maybe<Quality_Control_Templates>;
  /** update data of the table: "reminder_recipients" */
  update_reminder_recipients?: Maybe<Reminder_Recipients_Mutation_Response>;
  /** update single row of the table: "reminder_recipients" */
  update_reminder_recipients_by_pk?: Maybe<Reminder_Recipients>;
  /** update data of the table: "reminders" */
  update_reminders?: Maybe<Reminders_Mutation_Response>;
  /** update single row of the table: "reminders" */
  update_reminders_by_pk?: Maybe<Reminders>;
  /** update data of the table: "request_notes" */
  update_request_notes?: Maybe<Request_Notes_Mutation_Response>;
  /** update single row of the table: "request_notes" */
  update_request_notes_by_pk?: Maybe<Request_Notes>;
  /** update data of the table: "requests" */
  update_requests?: Maybe<Requests_Mutation_Response>;
  /** update single row of the table: "requests" */
  update_requests_by_pk?: Maybe<Requests>;
  /** update data of the table: "standard_photos" */
  update_standard_photos?: Maybe<Standard_Photos_Mutation_Response>;
  /** update single row of the table: "standard_photos" */
  update_standard_photos_by_pk?: Maybe<Standard_Photos>;
  /** update data of the table: "standard_terms" */
  update_standard_terms?: Maybe<Standard_Terms_Mutation_Response>;
  /** update single row of the table: "standard_terms" */
  update_standard_terms_by_pk?: Maybe<Standard_Terms>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
};


/** mutation root */
export type Mutation_RootDelete_AbsenceArgs = {
  where: Absence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Absence_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Absence_Search_ViewArgs = {
  where: Absence_Search_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Absence_TypesArgs = {
  where: Absence_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Absence_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Archive_FilesArgs = {
  where: Archive_Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Archive_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Case_Doc_ChecklistsArgs = {
  where: Case_Doc_Checklists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Case_Doc_Checklists_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Case_Doc_EconomyArgs = {
  where: Case_Doc_Economy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Case_Doc_Economy_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Case_Doc_NotesArgs = {
  where: Case_Doc_Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Case_Doc_Notes_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Case_Doc_Order_ConfirmationsArgs = {
  where: Case_Doc_Order_Confirmations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Case_Doc_Order_Confirmations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Case_Doc_Quality_ControlsArgs = {
  where: Case_Doc_Quality_Controls_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Case_Doc_Quality_Controls_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Case_FilesArgs = {
  where: Case_Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Case_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Case_PhotosArgs = {
  where: Case_Photos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Case_Photos_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Case_SuppliersArgs = {
  where: Case_Suppliers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Case_Suppliers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Case_Time_EntriesArgs = {
  where: Case_Time_Entries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Case_Time_Entries_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Case_TypesArgs = {
  where: Case_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Case_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CasesArgs = {
  where: Cases_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Cases_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Checklist_Item_ArrowsArgs = {
  where: Checklist_Item_Arrows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Checklist_Item_Arrows_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Checklist_ItemsArgs = {
  where: Checklist_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Checklist_Items_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Construction_Plan_EntriesArgs = {
  where: Construction_Plan_Entries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Construction_Plan_Entries_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Construction_PlansArgs = {
  where: Construction_Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Construction_Plans_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContactsArgs = {
  where: Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contacts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FilesArgs = {
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Order_Confirmation_TasksArgs = {
  where: Order_Confirmation_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Confirmation_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Order_Confirmation_Template_TasksArgs = {
  where: Order_Confirmation_Template_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Confirmation_Template_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Order_Confirmation_TemplatesArgs = {
  where: Order_Confirmation_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Order_Confirmation_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Quality_Control_Task_ArrowsArgs = {
  where: Quality_Control_Task_Arrows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Quality_Control_Task_Arrows_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Quality_Control_TasksArgs = {
  where: Quality_Control_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Quality_Control_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Quality_Control_Template_TasksArgs = {
  where: Quality_Control_Template_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Quality_Control_Template_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Quality_Control_TemplatesArgs = {
  where: Quality_Control_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Quality_Control_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Reminder_RecipientsArgs = {
  where: Reminder_Recipients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reminder_Recipients_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RemindersArgs = {
  where: Reminders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reminders_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Request_NotesArgs = {
  where: Request_Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Request_Notes_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RequestsArgs = {
  where: Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Requests_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Standard_PhotosArgs = {
  where: Standard_Photos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Standard_Photos_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Standard_TermsArgs = {
  where: Standard_Terms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Standard_Terms_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_AbsenceArgs = {
  objects: Array<Absence_Insert_Input>;
  on_conflict?: Maybe<Absence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Absence_OneArgs = {
  object: Absence_Insert_Input;
  on_conflict?: Maybe<Absence_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Absence_Search_ViewArgs = {
  objects: Array<Absence_Search_View_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Absence_Search_View_OneArgs = {
  object: Absence_Search_View_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Absence_TypesArgs = {
  objects: Array<Absence_Types_Insert_Input>;
  on_conflict?: Maybe<Absence_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Absence_Types_OneArgs = {
  object: Absence_Types_Insert_Input;
  on_conflict?: Maybe<Absence_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Archive_FilesArgs = {
  objects: Array<Archive_Files_Insert_Input>;
  on_conflict?: Maybe<Archive_Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Archive_Files_OneArgs = {
  object: Archive_Files_Insert_Input;
  on_conflict?: Maybe<Archive_Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_Doc_ChecklistsArgs = {
  objects: Array<Case_Doc_Checklists_Insert_Input>;
  on_conflict?: Maybe<Case_Doc_Checklists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_Doc_Checklists_OneArgs = {
  object: Case_Doc_Checklists_Insert_Input;
  on_conflict?: Maybe<Case_Doc_Checklists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_Doc_EconomyArgs = {
  objects: Array<Case_Doc_Economy_Insert_Input>;
  on_conflict?: Maybe<Case_Doc_Economy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_Doc_Economy_OneArgs = {
  object: Case_Doc_Economy_Insert_Input;
  on_conflict?: Maybe<Case_Doc_Economy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_Doc_NotesArgs = {
  objects: Array<Case_Doc_Notes_Insert_Input>;
  on_conflict?: Maybe<Case_Doc_Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_Doc_Notes_OneArgs = {
  object: Case_Doc_Notes_Insert_Input;
  on_conflict?: Maybe<Case_Doc_Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_Doc_Order_ConfirmationsArgs = {
  objects: Array<Case_Doc_Order_Confirmations_Insert_Input>;
  on_conflict?: Maybe<Case_Doc_Order_Confirmations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_Doc_Order_Confirmations_OneArgs = {
  object: Case_Doc_Order_Confirmations_Insert_Input;
  on_conflict?: Maybe<Case_Doc_Order_Confirmations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_Doc_Quality_ControlsArgs = {
  objects: Array<Case_Doc_Quality_Controls_Insert_Input>;
  on_conflict?: Maybe<Case_Doc_Quality_Controls_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_Doc_Quality_Controls_OneArgs = {
  object: Case_Doc_Quality_Controls_Insert_Input;
  on_conflict?: Maybe<Case_Doc_Quality_Controls_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_FilesArgs = {
  objects: Array<Case_Files_Insert_Input>;
  on_conflict?: Maybe<Case_Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_Files_OneArgs = {
  object: Case_Files_Insert_Input;
  on_conflict?: Maybe<Case_Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_PhotosArgs = {
  objects: Array<Case_Photos_Insert_Input>;
  on_conflict?: Maybe<Case_Photos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_Photos_OneArgs = {
  object: Case_Photos_Insert_Input;
  on_conflict?: Maybe<Case_Photos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_SuppliersArgs = {
  objects: Array<Case_Suppliers_Insert_Input>;
  on_conflict?: Maybe<Case_Suppliers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_Suppliers_OneArgs = {
  object: Case_Suppliers_Insert_Input;
  on_conflict?: Maybe<Case_Suppliers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_Time_EntriesArgs = {
  objects: Array<Case_Time_Entries_Insert_Input>;
  on_conflict?: Maybe<Case_Time_Entries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_Time_Entries_OneArgs = {
  object: Case_Time_Entries_Insert_Input;
  on_conflict?: Maybe<Case_Time_Entries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_TypesArgs = {
  objects: Array<Case_Types_Insert_Input>;
  on_conflict?: Maybe<Case_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_Types_OneArgs = {
  object: Case_Types_Insert_Input;
  on_conflict?: Maybe<Case_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CasesArgs = {
  objects: Array<Cases_Insert_Input>;
  on_conflict?: Maybe<Cases_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cases_OneArgs = {
  object: Cases_Insert_Input;
  on_conflict?: Maybe<Cases_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Checklist_Item_ArrowsArgs = {
  objects: Array<Checklist_Item_Arrows_Insert_Input>;
  on_conflict?: Maybe<Checklist_Item_Arrows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Checklist_Item_Arrows_OneArgs = {
  object: Checklist_Item_Arrows_Insert_Input;
  on_conflict?: Maybe<Checklist_Item_Arrows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Checklist_ItemsArgs = {
  objects: Array<Checklist_Items_Insert_Input>;
  on_conflict?: Maybe<Checklist_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Checklist_Items_OneArgs = {
  object: Checklist_Items_Insert_Input;
  on_conflict?: Maybe<Checklist_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Construction_Plan_EntriesArgs = {
  objects: Array<Construction_Plan_Entries_Insert_Input>;
  on_conflict?: Maybe<Construction_Plan_Entries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Construction_Plan_Entries_OneArgs = {
  object: Construction_Plan_Entries_Insert_Input;
  on_conflict?: Maybe<Construction_Plan_Entries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Construction_PlansArgs = {
  objects: Array<Construction_Plans_Insert_Input>;
  on_conflict?: Maybe<Construction_Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Construction_Plans_OneArgs = {
  object: Construction_Plans_Insert_Input;
  on_conflict?: Maybe<Construction_Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContactsArgs = {
  objects: Array<Contacts_Insert_Input>;
  on_conflict?: Maybe<Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contacts_OneArgs = {
  object: Contacts_Insert_Input;
  on_conflict?: Maybe<Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FilesArgs = {
  objects: Array<Files_Insert_Input>;
  on_conflict?: Maybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Files_OneArgs = {
  object: Files_Insert_Input;
  on_conflict?: Maybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Confirmation_TasksArgs = {
  objects: Array<Order_Confirmation_Tasks_Insert_Input>;
  on_conflict?: Maybe<Order_Confirmation_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Confirmation_Tasks_OneArgs = {
  object: Order_Confirmation_Tasks_Insert_Input;
  on_conflict?: Maybe<Order_Confirmation_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Confirmation_Template_TasksArgs = {
  objects: Array<Order_Confirmation_Template_Tasks_Insert_Input>;
  on_conflict?: Maybe<Order_Confirmation_Template_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Confirmation_Template_Tasks_OneArgs = {
  object: Order_Confirmation_Template_Tasks_Insert_Input;
  on_conflict?: Maybe<Order_Confirmation_Template_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Confirmation_TemplatesArgs = {
  objects: Array<Order_Confirmation_Templates_Insert_Input>;
  on_conflict?: Maybe<Order_Confirmation_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Order_Confirmation_Templates_OneArgs = {
  object: Order_Confirmation_Templates_Insert_Input;
  on_conflict?: Maybe<Order_Confirmation_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Quality_Control_Task_ArrowsArgs = {
  objects: Array<Quality_Control_Task_Arrows_Insert_Input>;
  on_conflict?: Maybe<Quality_Control_Task_Arrows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Quality_Control_Task_Arrows_OneArgs = {
  object: Quality_Control_Task_Arrows_Insert_Input;
  on_conflict?: Maybe<Quality_Control_Task_Arrows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Quality_Control_TasksArgs = {
  objects: Array<Quality_Control_Tasks_Insert_Input>;
  on_conflict?: Maybe<Quality_Control_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Quality_Control_Tasks_OneArgs = {
  object: Quality_Control_Tasks_Insert_Input;
  on_conflict?: Maybe<Quality_Control_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Quality_Control_Template_TasksArgs = {
  objects: Array<Quality_Control_Template_Tasks_Insert_Input>;
  on_conflict?: Maybe<Quality_Control_Template_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Quality_Control_Template_Tasks_OneArgs = {
  object: Quality_Control_Template_Tasks_Insert_Input;
  on_conflict?: Maybe<Quality_Control_Template_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Quality_Control_TemplatesArgs = {
  objects: Array<Quality_Control_Templates_Insert_Input>;
  on_conflict?: Maybe<Quality_Control_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Quality_Control_Templates_OneArgs = {
  object: Quality_Control_Templates_Insert_Input;
  on_conflict?: Maybe<Quality_Control_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reminder_RecipientsArgs = {
  objects: Array<Reminder_Recipients_Insert_Input>;
  on_conflict?: Maybe<Reminder_Recipients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reminder_Recipients_OneArgs = {
  object: Reminder_Recipients_Insert_Input;
  on_conflict?: Maybe<Reminder_Recipients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RemindersArgs = {
  objects: Array<Reminders_Insert_Input>;
  on_conflict?: Maybe<Reminders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reminders_OneArgs = {
  object: Reminders_Insert_Input;
  on_conflict?: Maybe<Reminders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Request_NotesArgs = {
  objects: Array<Request_Notes_Insert_Input>;
  on_conflict?: Maybe<Request_Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Request_Notes_OneArgs = {
  object: Request_Notes_Insert_Input;
  on_conflict?: Maybe<Request_Notes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RequestsArgs = {
  objects: Array<Requests_Insert_Input>;
  on_conflict?: Maybe<Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Requests_OneArgs = {
  object: Requests_Insert_Input;
  on_conflict?: Maybe<Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Standard_PhotosArgs = {
  objects: Array<Standard_Photos_Insert_Input>;
  on_conflict?: Maybe<Standard_Photos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Standard_Photos_OneArgs = {
  object: Standard_Photos_Insert_Input;
  on_conflict?: Maybe<Standard_Photos_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Standard_TermsArgs = {
  objects: Array<Standard_Terms_Insert_Input>;
  on_conflict?: Maybe<Standard_Terms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Standard_Terms_OneArgs = {
  object: Standard_Terms_Insert_Input;
  on_conflict?: Maybe<Standard_Terms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_AbsenceArgs = {
  _set?: Maybe<Absence_Set_Input>;
  where: Absence_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Absence_By_PkArgs = {
  _set?: Maybe<Absence_Set_Input>;
  pk_columns: Absence_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Absence_Search_ViewArgs = {
  _set?: Maybe<Absence_Search_View_Set_Input>;
  where: Absence_Search_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Absence_TypesArgs = {
  _set?: Maybe<Absence_Types_Set_Input>;
  where: Absence_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Absence_Types_By_PkArgs = {
  _set?: Maybe<Absence_Types_Set_Input>;
  pk_columns: Absence_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Archive_FilesArgs = {
  _set?: Maybe<Archive_Files_Set_Input>;
  where: Archive_Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Archive_Files_By_PkArgs = {
  _set?: Maybe<Archive_Files_Set_Input>;
  pk_columns: Archive_Files_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Case_Doc_ChecklistsArgs = {
  _set?: Maybe<Case_Doc_Checklists_Set_Input>;
  where: Case_Doc_Checklists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Case_Doc_Checklists_By_PkArgs = {
  _set?: Maybe<Case_Doc_Checklists_Set_Input>;
  pk_columns: Case_Doc_Checklists_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Case_Doc_EconomyArgs = {
  _set?: Maybe<Case_Doc_Economy_Set_Input>;
  where: Case_Doc_Economy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Case_Doc_Economy_By_PkArgs = {
  _set?: Maybe<Case_Doc_Economy_Set_Input>;
  pk_columns: Case_Doc_Economy_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Case_Doc_NotesArgs = {
  _set?: Maybe<Case_Doc_Notes_Set_Input>;
  where: Case_Doc_Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Case_Doc_Notes_By_PkArgs = {
  _set?: Maybe<Case_Doc_Notes_Set_Input>;
  pk_columns: Case_Doc_Notes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Case_Doc_Order_ConfirmationsArgs = {
  _set?: Maybe<Case_Doc_Order_Confirmations_Set_Input>;
  where: Case_Doc_Order_Confirmations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Case_Doc_Order_Confirmations_By_PkArgs = {
  _set?: Maybe<Case_Doc_Order_Confirmations_Set_Input>;
  pk_columns: Case_Doc_Order_Confirmations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Case_Doc_Quality_ControlsArgs = {
  _set?: Maybe<Case_Doc_Quality_Controls_Set_Input>;
  where: Case_Doc_Quality_Controls_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Case_Doc_Quality_Controls_By_PkArgs = {
  _set?: Maybe<Case_Doc_Quality_Controls_Set_Input>;
  pk_columns: Case_Doc_Quality_Controls_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Case_FilesArgs = {
  _set?: Maybe<Case_Files_Set_Input>;
  where: Case_Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Case_Files_By_PkArgs = {
  _set?: Maybe<Case_Files_Set_Input>;
  pk_columns: Case_Files_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Case_PhotosArgs = {
  _set?: Maybe<Case_Photos_Set_Input>;
  where: Case_Photos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Case_Photos_By_PkArgs = {
  _set?: Maybe<Case_Photos_Set_Input>;
  pk_columns: Case_Photos_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Case_SuppliersArgs = {
  _set?: Maybe<Case_Suppliers_Set_Input>;
  where: Case_Suppliers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Case_Suppliers_By_PkArgs = {
  _set?: Maybe<Case_Suppliers_Set_Input>;
  pk_columns: Case_Suppliers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Case_Time_EntriesArgs = {
  _inc?: Maybe<Case_Time_Entries_Inc_Input>;
  _set?: Maybe<Case_Time_Entries_Set_Input>;
  where: Case_Time_Entries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Case_Time_Entries_By_PkArgs = {
  _inc?: Maybe<Case_Time_Entries_Inc_Input>;
  _set?: Maybe<Case_Time_Entries_Set_Input>;
  pk_columns: Case_Time_Entries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Case_TypesArgs = {
  _inc?: Maybe<Case_Types_Inc_Input>;
  _set?: Maybe<Case_Types_Set_Input>;
  where: Case_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Case_Types_By_PkArgs = {
  _inc?: Maybe<Case_Types_Inc_Input>;
  _set?: Maybe<Case_Types_Set_Input>;
  pk_columns: Case_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CasesArgs = {
  _inc?: Maybe<Cases_Inc_Input>;
  _set?: Maybe<Cases_Set_Input>;
  where: Cases_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Cases_By_PkArgs = {
  _inc?: Maybe<Cases_Inc_Input>;
  _set?: Maybe<Cases_Set_Input>;
  pk_columns: Cases_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Checklist_Item_ArrowsArgs = {
  _inc?: Maybe<Checklist_Item_Arrows_Inc_Input>;
  _set?: Maybe<Checklist_Item_Arrows_Set_Input>;
  where: Checklist_Item_Arrows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Checklist_Item_Arrows_By_PkArgs = {
  _inc?: Maybe<Checklist_Item_Arrows_Inc_Input>;
  _set?: Maybe<Checklist_Item_Arrows_Set_Input>;
  pk_columns: Checklist_Item_Arrows_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Checklist_ItemsArgs = {
  _inc?: Maybe<Checklist_Items_Inc_Input>;
  _set?: Maybe<Checklist_Items_Set_Input>;
  where: Checklist_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Checklist_Items_By_PkArgs = {
  _inc?: Maybe<Checklist_Items_Inc_Input>;
  _set?: Maybe<Checklist_Items_Set_Input>;
  pk_columns: Checklist_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Construction_Plan_EntriesArgs = {
  _inc?: Maybe<Construction_Plan_Entries_Inc_Input>;
  _set?: Maybe<Construction_Plan_Entries_Set_Input>;
  where: Construction_Plan_Entries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Construction_Plan_Entries_By_PkArgs = {
  _inc?: Maybe<Construction_Plan_Entries_Inc_Input>;
  _set?: Maybe<Construction_Plan_Entries_Set_Input>;
  pk_columns: Construction_Plan_Entries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Construction_PlansArgs = {
  _set?: Maybe<Construction_Plans_Set_Input>;
  where: Construction_Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Construction_Plans_By_PkArgs = {
  _set?: Maybe<Construction_Plans_Set_Input>;
  pk_columns: Construction_Plans_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContactsArgs = {
  _set?: Maybe<Contacts_Set_Input>;
  where: Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contacts_By_PkArgs = {
  _set?: Maybe<Contacts_Set_Input>;
  pk_columns: Contacts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FilesArgs = {
  _set?: Maybe<Files_Set_Input>;
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Files_By_PkArgs = {
  _set?: Maybe<Files_Set_Input>;
  pk_columns: Files_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Confirmation_TasksArgs = {
  _inc?: Maybe<Order_Confirmation_Tasks_Inc_Input>;
  _set?: Maybe<Order_Confirmation_Tasks_Set_Input>;
  where: Order_Confirmation_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Confirmation_Tasks_By_PkArgs = {
  _inc?: Maybe<Order_Confirmation_Tasks_Inc_Input>;
  _set?: Maybe<Order_Confirmation_Tasks_Set_Input>;
  pk_columns: Order_Confirmation_Tasks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Confirmation_Template_TasksArgs = {
  _inc?: Maybe<Order_Confirmation_Template_Tasks_Inc_Input>;
  _set?: Maybe<Order_Confirmation_Template_Tasks_Set_Input>;
  where: Order_Confirmation_Template_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Confirmation_Template_Tasks_By_PkArgs = {
  _inc?: Maybe<Order_Confirmation_Template_Tasks_Inc_Input>;
  _set?: Maybe<Order_Confirmation_Template_Tasks_Set_Input>;
  pk_columns: Order_Confirmation_Template_Tasks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Confirmation_TemplatesArgs = {
  _set?: Maybe<Order_Confirmation_Templates_Set_Input>;
  where: Order_Confirmation_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Order_Confirmation_Templates_By_PkArgs = {
  _set?: Maybe<Order_Confirmation_Templates_Set_Input>;
  pk_columns: Order_Confirmation_Templates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Quality_Control_Task_ArrowsArgs = {
  _inc?: Maybe<Quality_Control_Task_Arrows_Inc_Input>;
  _set?: Maybe<Quality_Control_Task_Arrows_Set_Input>;
  where: Quality_Control_Task_Arrows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Quality_Control_Task_Arrows_By_PkArgs = {
  _inc?: Maybe<Quality_Control_Task_Arrows_Inc_Input>;
  _set?: Maybe<Quality_Control_Task_Arrows_Set_Input>;
  pk_columns: Quality_Control_Task_Arrows_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Quality_Control_TasksArgs = {
  _inc?: Maybe<Quality_Control_Tasks_Inc_Input>;
  _set?: Maybe<Quality_Control_Tasks_Set_Input>;
  where: Quality_Control_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Quality_Control_Tasks_By_PkArgs = {
  _inc?: Maybe<Quality_Control_Tasks_Inc_Input>;
  _set?: Maybe<Quality_Control_Tasks_Set_Input>;
  pk_columns: Quality_Control_Tasks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Quality_Control_Template_TasksArgs = {
  _inc?: Maybe<Quality_Control_Template_Tasks_Inc_Input>;
  _set?: Maybe<Quality_Control_Template_Tasks_Set_Input>;
  where: Quality_Control_Template_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Quality_Control_Template_Tasks_By_PkArgs = {
  _inc?: Maybe<Quality_Control_Template_Tasks_Inc_Input>;
  _set?: Maybe<Quality_Control_Template_Tasks_Set_Input>;
  pk_columns: Quality_Control_Template_Tasks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Quality_Control_TemplatesArgs = {
  _set?: Maybe<Quality_Control_Templates_Set_Input>;
  where: Quality_Control_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Quality_Control_Templates_By_PkArgs = {
  _set?: Maybe<Quality_Control_Templates_Set_Input>;
  pk_columns: Quality_Control_Templates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reminder_RecipientsArgs = {
  _set?: Maybe<Reminder_Recipients_Set_Input>;
  where: Reminder_Recipients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reminder_Recipients_By_PkArgs = {
  _set?: Maybe<Reminder_Recipients_Set_Input>;
  pk_columns: Reminder_Recipients_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RemindersArgs = {
  _set?: Maybe<Reminders_Set_Input>;
  where: Reminders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reminders_By_PkArgs = {
  _set?: Maybe<Reminders_Set_Input>;
  pk_columns: Reminders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Request_NotesArgs = {
  _set?: Maybe<Request_Notes_Set_Input>;
  where: Request_Notes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Request_Notes_By_PkArgs = {
  _set?: Maybe<Request_Notes_Set_Input>;
  pk_columns: Request_Notes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RequestsArgs = {
  _set?: Maybe<Requests_Set_Input>;
  where: Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Requests_By_PkArgs = {
  _set?: Maybe<Requests_Set_Input>;
  pk_columns: Requests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Standard_PhotosArgs = {
  _set?: Maybe<Standard_Photos_Set_Input>;
  where: Standard_Photos_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Standard_Photos_By_PkArgs = {
  _set?: Maybe<Standard_Photos_Set_Input>;
  pk_columns: Standard_Photos_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Standard_TermsArgs = {
  _set?: Maybe<Standard_Terms_Set_Input>;
  where: Standard_Terms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Standard_Terms_By_PkArgs = {
  _set?: Maybe<Standard_Terms_Set_Input>;
  pk_columns: Standard_Terms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _inc?: Maybe<Users_Inc_Input>;
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _inc?: Maybe<Users_Inc_Input>;
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** expression to compare columns of type numeric. All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "order_confirmation_tasks" */
export type Order_Confirmation_Tasks = {
  __typename?: 'order_confirmation_tasks';
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  order: Scalars['Int'];
  order_confirmation_id: Scalars['uuid'];
  price?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Aggregate = {
  __typename?: 'order_confirmation_tasks_aggregate';
  aggregate?: Maybe<Order_Confirmation_Tasks_Aggregate_Fields>;
  nodes: Array<Order_Confirmation_Tasks>;
};

/** aggregate fields of "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Aggregate_Fields = {
  __typename?: 'order_confirmation_tasks_aggregate_fields';
  avg?: Maybe<Order_Confirmation_Tasks_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Order_Confirmation_Tasks_Max_Fields>;
  min?: Maybe<Order_Confirmation_Tasks_Min_Fields>;
  stddev?: Maybe<Order_Confirmation_Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Confirmation_Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Confirmation_Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Confirmation_Tasks_Sum_Fields>;
  var_pop?: Maybe<Order_Confirmation_Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Confirmation_Tasks_Var_Samp_Fields>;
  variance?: Maybe<Order_Confirmation_Tasks_Variance_Fields>;
};


/** aggregate fields of "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Confirmation_Tasks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Aggregate_Order_By = {
  avg?: Maybe<Order_Confirmation_Tasks_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Confirmation_Tasks_Max_Order_By>;
  min?: Maybe<Order_Confirmation_Tasks_Min_Order_By>;
  stddev?: Maybe<Order_Confirmation_Tasks_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Confirmation_Tasks_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Confirmation_Tasks_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Confirmation_Tasks_Sum_Order_By>;
  var_pop?: Maybe<Order_Confirmation_Tasks_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Confirmation_Tasks_Var_Samp_Order_By>;
  variance?: Maybe<Order_Confirmation_Tasks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Order_Confirmation_Tasks_Insert_Input>;
  on_conflict?: Maybe<Order_Confirmation_Tasks_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_Confirmation_Tasks_Avg_Fields = {
  __typename?: 'order_confirmation_tasks_avg_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Avg_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_confirmation_tasks". All fields are combined with a logical 'AND'. */
export type Order_Confirmation_Tasks_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Order_Confirmation_Tasks_Bool_Exp>>>;
  _not?: Maybe<Order_Confirmation_Tasks_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Order_Confirmation_Tasks_Bool_Exp>>>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  order_confirmation_id?: Maybe<Uuid_Comparison_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_confirmation_tasks" */
export enum Order_Confirmation_Tasks_Constraint {
  /** unique or primary key constraint */
  OrderConfirmationTasksPkey = 'order_confirmation_tasks_pkey'
}

/** input type for incrementing integer column in table "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  order_confirmation_id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Order_Confirmation_Tasks_Max_Fields = {
  __typename?: 'order_confirmation_tasks_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  order_confirmation_id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Max_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  order_confirmation_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Confirmation_Tasks_Min_Fields = {
  __typename?: 'order_confirmation_tasks_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  order_confirmation_id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Min_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  order_confirmation_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Mutation_Response = {
  __typename?: 'order_confirmation_tasks_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Order_Confirmation_Tasks>;
};

/** input type for inserting object relation for remote table "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Obj_Rel_Insert_Input = {
  data: Order_Confirmation_Tasks_Insert_Input;
  on_conflict?: Maybe<Order_Confirmation_Tasks_On_Conflict>;
};

/** on conflict condition type for table "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_On_Conflict = {
  constraint: Order_Confirmation_Tasks_Constraint;
  update_columns: Array<Order_Confirmation_Tasks_Update_Column>;
  where?: Maybe<Order_Confirmation_Tasks_Bool_Exp>;
};

/** ordering options when selecting data from "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  order_confirmation_id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "order_confirmation_tasks" */
export enum Order_Confirmation_Tasks_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  OrderConfirmationId = 'order_confirmation_id',
  /** column name */
  Price = 'price',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Set_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  order_confirmation_id?: Maybe<Scalars['uuid']>;
  price?: Maybe<Scalars['numeric']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Order_Confirmation_Tasks_Stddev_Fields = {
  __typename?: 'order_confirmation_tasks_stddev_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Stddev_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Confirmation_Tasks_Stddev_Pop_Fields = {
  __typename?: 'order_confirmation_tasks_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Confirmation_Tasks_Stddev_Samp_Fields = {
  __typename?: 'order_confirmation_tasks_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Order_Confirmation_Tasks_Sum_Fields = {
  __typename?: 'order_confirmation_tasks_sum_fields';
  order?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Sum_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** update columns of table "order_confirmation_tasks" */
export enum Order_Confirmation_Tasks_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  OrderConfirmationId = 'order_confirmation_id',
  /** column name */
  Price = 'price',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type Order_Confirmation_Tasks_Var_Pop_Fields = {
  __typename?: 'order_confirmation_tasks_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Confirmation_Tasks_Var_Samp_Fields = {
  __typename?: 'order_confirmation_tasks_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Confirmation_Tasks_Variance_Fields = {
  __typename?: 'order_confirmation_tasks_variance_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_confirmation_tasks" */
export type Order_Confirmation_Tasks_Variance_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** columns and relationships of "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks = {
  __typename?: 'order_confirmation_template_tasks';
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  order: Scalars['Int'];
  price?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  template: Order_Confirmation_Templates;
  template_id: Scalars['uuid'];
  title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Aggregate = {
  __typename?: 'order_confirmation_template_tasks_aggregate';
  aggregate?: Maybe<Order_Confirmation_Template_Tasks_Aggregate_Fields>;
  nodes: Array<Order_Confirmation_Template_Tasks>;
};

/** aggregate fields of "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Aggregate_Fields = {
  __typename?: 'order_confirmation_template_tasks_aggregate_fields';
  avg?: Maybe<Order_Confirmation_Template_Tasks_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Order_Confirmation_Template_Tasks_Max_Fields>;
  min?: Maybe<Order_Confirmation_Template_Tasks_Min_Fields>;
  stddev?: Maybe<Order_Confirmation_Template_Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Order_Confirmation_Template_Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Order_Confirmation_Template_Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Order_Confirmation_Template_Tasks_Sum_Fields>;
  var_pop?: Maybe<Order_Confirmation_Template_Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Order_Confirmation_Template_Tasks_Var_Samp_Fields>;
  variance?: Maybe<Order_Confirmation_Template_Tasks_Variance_Fields>;
};


/** aggregate fields of "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Confirmation_Template_Tasks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Aggregate_Order_By = {
  avg?: Maybe<Order_Confirmation_Template_Tasks_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Confirmation_Template_Tasks_Max_Order_By>;
  min?: Maybe<Order_Confirmation_Template_Tasks_Min_Order_By>;
  stddev?: Maybe<Order_Confirmation_Template_Tasks_Stddev_Order_By>;
  stddev_pop?: Maybe<Order_Confirmation_Template_Tasks_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Order_Confirmation_Template_Tasks_Stddev_Samp_Order_By>;
  sum?: Maybe<Order_Confirmation_Template_Tasks_Sum_Order_By>;
  var_pop?: Maybe<Order_Confirmation_Template_Tasks_Var_Pop_Order_By>;
  var_samp?: Maybe<Order_Confirmation_Template_Tasks_Var_Samp_Order_By>;
  variance?: Maybe<Order_Confirmation_Template_Tasks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Order_Confirmation_Template_Tasks_Insert_Input>;
  on_conflict?: Maybe<Order_Confirmation_Template_Tasks_On_Conflict>;
};

/** aggregate avg on columns */
export type Order_Confirmation_Template_Tasks_Avg_Fields = {
  __typename?: 'order_confirmation_template_tasks_avg_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Avg_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_confirmation_template_tasks". All fields are combined with a logical 'AND'. */
export type Order_Confirmation_Template_Tasks_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Order_Confirmation_Template_Tasks_Bool_Exp>>>;
  _not?: Maybe<Order_Confirmation_Template_Tasks_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Order_Confirmation_Template_Tasks_Bool_Exp>>>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  template?: Maybe<Order_Confirmation_Templates_Bool_Exp>;
  template_id?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_confirmation_template_tasks" */
export enum Order_Confirmation_Template_Tasks_Constraint {
  /** unique or primary key constraint */
  OrderConfirmationTemplateTasksPkey = 'order_confirmation_template_tasks_pkey'
}

/** input type for incrementing integer column in table "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  template?: Maybe<Order_Confirmation_Templates_Obj_Rel_Insert_Input>;
  template_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Order_Confirmation_Template_Tasks_Max_Fields = {
  __typename?: 'order_confirmation_template_tasks_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  template_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Max_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  template_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Confirmation_Template_Tasks_Min_Fields = {
  __typename?: 'order_confirmation_template_tasks_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  template_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Min_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  template_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Mutation_Response = {
  __typename?: 'order_confirmation_template_tasks_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Order_Confirmation_Template_Tasks>;
};

/** input type for inserting object relation for remote table "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Obj_Rel_Insert_Input = {
  data: Order_Confirmation_Template_Tasks_Insert_Input;
  on_conflict?: Maybe<Order_Confirmation_Template_Tasks_On_Conflict>;
};

/** on conflict condition type for table "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_On_Conflict = {
  constraint: Order_Confirmation_Template_Tasks_Constraint;
  update_columns: Array<Order_Confirmation_Template_Tasks_Update_Column>;
  where?: Maybe<Order_Confirmation_Template_Tasks_Bool_Exp>;
};

/** ordering options when selecting data from "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  template?: Maybe<Order_Confirmation_Templates_Order_By>;
  template_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "order_confirmation_template_tasks" */
export enum Order_Confirmation_Template_Tasks_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Price = 'price',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Set_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  template_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Order_Confirmation_Template_Tasks_Stddev_Fields = {
  __typename?: 'order_confirmation_template_tasks_stddev_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Stddev_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Order_Confirmation_Template_Tasks_Stddev_Pop_Fields = {
  __typename?: 'order_confirmation_template_tasks_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Order_Confirmation_Template_Tasks_Stddev_Samp_Fields = {
  __typename?: 'order_confirmation_template_tasks_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Order_Confirmation_Template_Tasks_Sum_Fields = {
  __typename?: 'order_confirmation_template_tasks_sum_fields';
  order?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Sum_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** update columns of table "order_confirmation_template_tasks" */
export enum Order_Confirmation_Template_Tasks_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  Price = 'price',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type Order_Confirmation_Template_Tasks_Var_Pop_Fields = {
  __typename?: 'order_confirmation_template_tasks_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Order_Confirmation_Template_Tasks_Var_Samp_Fields = {
  __typename?: 'order_confirmation_template_tasks_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Order_Confirmation_Template_Tasks_Variance_Fields = {
  __typename?: 'order_confirmation_template_tasks_variance_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "order_confirmation_template_tasks" */
export type Order_Confirmation_Template_Tasks_Variance_Order_By = {
  order?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** columns and relationships of "order_confirmation_templates" */
export type Order_Confirmation_Templates = {
  __typename?: 'order_confirmation_templates';
  changed: Scalars['String'];
  created: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  other_terms?: Maybe<Scalars['String']>;
  /** An array relationship */
  tasks: Array<Order_Confirmation_Template_Tasks>;
  /** An aggregated array relationship */
  tasks_aggregate: Order_Confirmation_Template_Tasks_Aggregate;
  template_title: Scalars['String'];
  title: Scalars['String'];
};


/** columns and relationships of "order_confirmation_templates" */
export type Order_Confirmation_TemplatesTasksArgs = {
  distinct_on?: Maybe<Array<Order_Confirmation_Template_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Confirmation_Template_Tasks_Order_By>>;
  where?: Maybe<Order_Confirmation_Template_Tasks_Bool_Exp>;
};


/** columns and relationships of "order_confirmation_templates" */
export type Order_Confirmation_TemplatesTasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Confirmation_Template_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Confirmation_Template_Tasks_Order_By>>;
  where?: Maybe<Order_Confirmation_Template_Tasks_Bool_Exp>;
};

/** aggregated selection of "order_confirmation_templates" */
export type Order_Confirmation_Templates_Aggregate = {
  __typename?: 'order_confirmation_templates_aggregate';
  aggregate?: Maybe<Order_Confirmation_Templates_Aggregate_Fields>;
  nodes: Array<Order_Confirmation_Templates>;
};

/** aggregate fields of "order_confirmation_templates" */
export type Order_Confirmation_Templates_Aggregate_Fields = {
  __typename?: 'order_confirmation_templates_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Order_Confirmation_Templates_Max_Fields>;
  min?: Maybe<Order_Confirmation_Templates_Min_Fields>;
};


/** aggregate fields of "order_confirmation_templates" */
export type Order_Confirmation_Templates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Order_Confirmation_Templates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_confirmation_templates" */
export type Order_Confirmation_Templates_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Order_Confirmation_Templates_Max_Order_By>;
  min?: Maybe<Order_Confirmation_Templates_Min_Order_By>;
};

/** input type for inserting array relation for remote table "order_confirmation_templates" */
export type Order_Confirmation_Templates_Arr_Rel_Insert_Input = {
  data: Array<Order_Confirmation_Templates_Insert_Input>;
  on_conflict?: Maybe<Order_Confirmation_Templates_On_Conflict>;
};

/** Boolean expression to filter rows from the table "order_confirmation_templates". All fields are combined with a logical 'AND'. */
export type Order_Confirmation_Templates_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Order_Confirmation_Templates_Bool_Exp>>>;
  _not?: Maybe<Order_Confirmation_Templates_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Order_Confirmation_Templates_Bool_Exp>>>;
  changed?: Maybe<String_Comparison_Exp>;
  created?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  other_terms?: Maybe<String_Comparison_Exp>;
  tasks?: Maybe<Order_Confirmation_Template_Tasks_Bool_Exp>;
  template_title?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "order_confirmation_templates" */
export enum Order_Confirmation_Templates_Constraint {
  /** unique or primary key constraint */
  OrderConfirmationTemplatesPkey = 'order_confirmation_templates_pkey'
}

/** input type for inserting data into table "order_confirmation_templates" */
export type Order_Confirmation_Templates_Insert_Input = {
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  other_terms?: Maybe<Scalars['String']>;
  tasks?: Maybe<Order_Confirmation_Template_Tasks_Arr_Rel_Insert_Input>;
  template_title?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Order_Confirmation_Templates_Max_Fields = {
  __typename?: 'order_confirmation_templates_max_fields';
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  other_terms?: Maybe<Scalars['String']>;
  template_title?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "order_confirmation_templates" */
export type Order_Confirmation_Templates_Max_Order_By = {
  changed?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  other_terms?: Maybe<Order_By>;
  template_title?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Order_Confirmation_Templates_Min_Fields = {
  __typename?: 'order_confirmation_templates_min_fields';
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  other_terms?: Maybe<Scalars['String']>;
  template_title?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "order_confirmation_templates" */
export type Order_Confirmation_Templates_Min_Order_By = {
  changed?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  other_terms?: Maybe<Order_By>;
  template_title?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "order_confirmation_templates" */
export type Order_Confirmation_Templates_Mutation_Response = {
  __typename?: 'order_confirmation_templates_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Order_Confirmation_Templates>;
};

/** input type for inserting object relation for remote table "order_confirmation_templates" */
export type Order_Confirmation_Templates_Obj_Rel_Insert_Input = {
  data: Order_Confirmation_Templates_Insert_Input;
  on_conflict?: Maybe<Order_Confirmation_Templates_On_Conflict>;
};

/** on conflict condition type for table "order_confirmation_templates" */
export type Order_Confirmation_Templates_On_Conflict = {
  constraint: Order_Confirmation_Templates_Constraint;
  update_columns: Array<Order_Confirmation_Templates_Update_Column>;
  where?: Maybe<Order_Confirmation_Templates_Bool_Exp>;
};

/** ordering options when selecting data from "order_confirmation_templates" */
export type Order_Confirmation_Templates_Order_By = {
  changed?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  other_terms?: Maybe<Order_By>;
  tasks_aggregate?: Maybe<Order_Confirmation_Template_Tasks_Aggregate_Order_By>;
  template_title?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: "order_confirmation_templates" */
export type Order_Confirmation_Templates_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "order_confirmation_templates" */
export enum Order_Confirmation_Templates_Select_Column {
  /** column name */
  Changed = 'changed',
  /** column name */
  Created = 'created',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  OtherTerms = 'other_terms',
  /** column name */
  TemplateTitle = 'template_title',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "order_confirmation_templates" */
export type Order_Confirmation_Templates_Set_Input = {
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  other_terms?: Maybe<Scalars['String']>;
  template_title?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** update columns of table "order_confirmation_templates" */
export enum Order_Confirmation_Templates_Update_Column {
  /** column name */
  Changed = 'changed',
  /** column name */
  Created = 'created',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  OtherTerms = 'other_terms',
  /** column name */
  TemplateTitle = 'template_title',
  /** column name */
  Title = 'title'
}

/** columns and relationships of "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows = {
  __typename?: 'quality_control_task_arrows';
  angle: Scalars['Int'];
  id: Scalars['uuid'];
  order: Scalars['Int'];
  task_id: Scalars['uuid'];
  x: Scalars['numeric'];
  y: Scalars['numeric'];
};

/** aggregated selection of "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Aggregate = {
  __typename?: 'quality_control_task_arrows_aggregate';
  aggregate?: Maybe<Quality_Control_Task_Arrows_Aggregate_Fields>;
  nodes: Array<Quality_Control_Task_Arrows>;
};

/** aggregate fields of "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Aggregate_Fields = {
  __typename?: 'quality_control_task_arrows_aggregate_fields';
  avg?: Maybe<Quality_Control_Task_Arrows_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Quality_Control_Task_Arrows_Max_Fields>;
  min?: Maybe<Quality_Control_Task_Arrows_Min_Fields>;
  stddev?: Maybe<Quality_Control_Task_Arrows_Stddev_Fields>;
  stddev_pop?: Maybe<Quality_Control_Task_Arrows_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Quality_Control_Task_Arrows_Stddev_Samp_Fields>;
  sum?: Maybe<Quality_Control_Task_Arrows_Sum_Fields>;
  var_pop?: Maybe<Quality_Control_Task_Arrows_Var_Pop_Fields>;
  var_samp?: Maybe<Quality_Control_Task_Arrows_Var_Samp_Fields>;
  variance?: Maybe<Quality_Control_Task_Arrows_Variance_Fields>;
};


/** aggregate fields of "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Quality_Control_Task_Arrows_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Aggregate_Order_By = {
  avg?: Maybe<Quality_Control_Task_Arrows_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Quality_Control_Task_Arrows_Max_Order_By>;
  min?: Maybe<Quality_Control_Task_Arrows_Min_Order_By>;
  stddev?: Maybe<Quality_Control_Task_Arrows_Stddev_Order_By>;
  stddev_pop?: Maybe<Quality_Control_Task_Arrows_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Quality_Control_Task_Arrows_Stddev_Samp_Order_By>;
  sum?: Maybe<Quality_Control_Task_Arrows_Sum_Order_By>;
  var_pop?: Maybe<Quality_Control_Task_Arrows_Var_Pop_Order_By>;
  var_samp?: Maybe<Quality_Control_Task_Arrows_Var_Samp_Order_By>;
  variance?: Maybe<Quality_Control_Task_Arrows_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Arr_Rel_Insert_Input = {
  data: Array<Quality_Control_Task_Arrows_Insert_Input>;
  on_conflict?: Maybe<Quality_Control_Task_Arrows_On_Conflict>;
};

/** aggregate avg on columns */
export type Quality_Control_Task_Arrows_Avg_Fields = {
  __typename?: 'quality_control_task_arrows_avg_fields';
  angle?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Avg_Order_By = {
  angle?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "quality_control_task_arrows". All fields are combined with a logical 'AND'. */
export type Quality_Control_Task_Arrows_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Quality_Control_Task_Arrows_Bool_Exp>>>;
  _not?: Maybe<Quality_Control_Task_Arrows_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Quality_Control_Task_Arrows_Bool_Exp>>>;
  angle?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
  x?: Maybe<Numeric_Comparison_Exp>;
  y?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "quality_control_task_arrows" */
export enum Quality_Control_Task_Arrows_Constraint {
  /** unique or primary key constraint */
  QualityControlTaskArrowsPkey = 'quality_control_task_arrows_pkey'
}

/** input type for incrementing integer column in table "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Inc_Input = {
  angle?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Insert_Input = {
  angle?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  task_id?: Maybe<Scalars['uuid']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Quality_Control_Task_Arrows_Max_Fields = {
  __typename?: 'quality_control_task_arrows_max_fields';
  angle?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  task_id?: Maybe<Scalars['uuid']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Max_Order_By = {
  angle?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Quality_Control_Task_Arrows_Min_Fields = {
  __typename?: 'quality_control_task_arrows_min_fields';
  angle?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  task_id?: Maybe<Scalars['uuid']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Min_Order_By = {
  angle?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** response of any mutation on the table "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Mutation_Response = {
  __typename?: 'quality_control_task_arrows_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Quality_Control_Task_Arrows>;
};

/** input type for inserting object relation for remote table "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Obj_Rel_Insert_Input = {
  data: Quality_Control_Task_Arrows_Insert_Input;
  on_conflict?: Maybe<Quality_Control_Task_Arrows_On_Conflict>;
};

/** on conflict condition type for table "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_On_Conflict = {
  constraint: Quality_Control_Task_Arrows_Constraint;
  update_columns: Array<Quality_Control_Task_Arrows_Update_Column>;
  where?: Maybe<Quality_Control_Task_Arrows_Bool_Exp>;
};

/** ordering options when selecting data from "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Order_By = {
  angle?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** primary key columns input for table: "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "quality_control_task_arrows" */
export enum Quality_Control_Task_Arrows_Select_Column {
  /** column name */
  Angle = 'angle',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y'
}

/** input type for updating data in table "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Set_Input = {
  angle?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  task_id?: Maybe<Scalars['uuid']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Quality_Control_Task_Arrows_Stddev_Fields = {
  __typename?: 'quality_control_task_arrows_stddev_fields';
  angle?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Stddev_Order_By = {
  angle?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Quality_Control_Task_Arrows_Stddev_Pop_Fields = {
  __typename?: 'quality_control_task_arrows_stddev_pop_fields';
  angle?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Stddev_Pop_Order_By = {
  angle?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Quality_Control_Task_Arrows_Stddev_Samp_Fields = {
  __typename?: 'quality_control_task_arrows_stddev_samp_fields';
  angle?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Stddev_Samp_Order_By = {
  angle?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Quality_Control_Task_Arrows_Sum_Fields = {
  __typename?: 'quality_control_task_arrows_sum_fields';
  angle?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  x?: Maybe<Scalars['numeric']>;
  y?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Sum_Order_By = {
  angle?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** update columns of table "quality_control_task_arrows" */
export enum Quality_Control_Task_Arrows_Update_Column {
  /** column name */
  Angle = 'angle',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  X = 'x',
  /** column name */
  Y = 'y'
}

/** aggregate var_pop on columns */
export type Quality_Control_Task_Arrows_Var_Pop_Fields = {
  __typename?: 'quality_control_task_arrows_var_pop_fields';
  angle?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Var_Pop_Order_By = {
  angle?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Quality_Control_Task_Arrows_Var_Samp_Fields = {
  __typename?: 'quality_control_task_arrows_var_samp_fields';
  angle?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Var_Samp_Order_By = {
  angle?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Quality_Control_Task_Arrows_Variance_Fields = {
  __typename?: 'quality_control_task_arrows_variance_fields';
  angle?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "quality_control_task_arrows" */
export type Quality_Control_Task_Arrows_Variance_Order_By = {
  angle?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  x?: Maybe<Order_By>;
  y?: Maybe<Order_By>;
};

/** columns and relationships of "quality_control_tasks" */
export type Quality_Control_Tasks = {
  __typename?: 'quality_control_tasks';
  /** An array relationship */
  arrows: Array<Quality_Control_Task_Arrows>;
  /** An aggregated array relationship */
  arrows_aggregate: Quality_Control_Task_Arrows_Aggregate;
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  file?: Maybe<Files>;
  file_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  order: Scalars['Int'];
  quality_control_id: Scalars['uuid'];
  title?: Maybe<Scalars['String']>;
};


/** columns and relationships of "quality_control_tasks" */
export type Quality_Control_TasksArrowsArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Task_Arrows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Task_Arrows_Order_By>>;
  where?: Maybe<Quality_Control_Task_Arrows_Bool_Exp>;
};


/** columns and relationships of "quality_control_tasks" */
export type Quality_Control_TasksArrows_AggregateArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Task_Arrows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Task_Arrows_Order_By>>;
  where?: Maybe<Quality_Control_Task_Arrows_Bool_Exp>;
};

/** aggregated selection of "quality_control_tasks" */
export type Quality_Control_Tasks_Aggregate = {
  __typename?: 'quality_control_tasks_aggregate';
  aggregate?: Maybe<Quality_Control_Tasks_Aggregate_Fields>;
  nodes: Array<Quality_Control_Tasks>;
};

/** aggregate fields of "quality_control_tasks" */
export type Quality_Control_Tasks_Aggregate_Fields = {
  __typename?: 'quality_control_tasks_aggregate_fields';
  avg?: Maybe<Quality_Control_Tasks_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Quality_Control_Tasks_Max_Fields>;
  min?: Maybe<Quality_Control_Tasks_Min_Fields>;
  stddev?: Maybe<Quality_Control_Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Quality_Control_Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Quality_Control_Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Quality_Control_Tasks_Sum_Fields>;
  var_pop?: Maybe<Quality_Control_Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Quality_Control_Tasks_Var_Samp_Fields>;
  variance?: Maybe<Quality_Control_Tasks_Variance_Fields>;
};


/** aggregate fields of "quality_control_tasks" */
export type Quality_Control_Tasks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Quality_Control_Tasks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "quality_control_tasks" */
export type Quality_Control_Tasks_Aggregate_Order_By = {
  avg?: Maybe<Quality_Control_Tasks_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Quality_Control_Tasks_Max_Order_By>;
  min?: Maybe<Quality_Control_Tasks_Min_Order_By>;
  stddev?: Maybe<Quality_Control_Tasks_Stddev_Order_By>;
  stddev_pop?: Maybe<Quality_Control_Tasks_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Quality_Control_Tasks_Stddev_Samp_Order_By>;
  sum?: Maybe<Quality_Control_Tasks_Sum_Order_By>;
  var_pop?: Maybe<Quality_Control_Tasks_Var_Pop_Order_By>;
  var_samp?: Maybe<Quality_Control_Tasks_Var_Samp_Order_By>;
  variance?: Maybe<Quality_Control_Tasks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "quality_control_tasks" */
export type Quality_Control_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Quality_Control_Tasks_Insert_Input>;
  on_conflict?: Maybe<Quality_Control_Tasks_On_Conflict>;
};

/** aggregate avg on columns */
export type Quality_Control_Tasks_Avg_Fields = {
  __typename?: 'quality_control_tasks_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "quality_control_tasks" */
export type Quality_Control_Tasks_Avg_Order_By = {
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "quality_control_tasks". All fields are combined with a logical 'AND'. */
export type Quality_Control_Tasks_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Quality_Control_Tasks_Bool_Exp>>>;
  _not?: Maybe<Quality_Control_Tasks_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Quality_Control_Tasks_Bool_Exp>>>;
  arrows?: Maybe<Quality_Control_Task_Arrows_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  file?: Maybe<Files_Bool_Exp>;
  file_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  quality_control_id?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "quality_control_tasks" */
export enum Quality_Control_Tasks_Constraint {
  /** unique or primary key constraint */
  QualityControlTasksPkey = 'quality_control_tasks_pkey'
}

/** input type for incrementing integer column in table "quality_control_tasks" */
export type Quality_Control_Tasks_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "quality_control_tasks" */
export type Quality_Control_Tasks_Insert_Input = {
  arrows?: Maybe<Quality_Control_Task_Arrows_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  file?: Maybe<Files_Obj_Rel_Insert_Input>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  quality_control_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Quality_Control_Tasks_Max_Fields = {
  __typename?: 'quality_control_tasks_max_fields';
  description?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  quality_control_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "quality_control_tasks" */
export type Quality_Control_Tasks_Max_Order_By = {
  description?: Maybe<Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  quality_control_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Quality_Control_Tasks_Min_Fields = {
  __typename?: 'quality_control_tasks_min_fields';
  description?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  quality_control_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "quality_control_tasks" */
export type Quality_Control_Tasks_Min_Order_By = {
  description?: Maybe<Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  quality_control_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "quality_control_tasks" */
export type Quality_Control_Tasks_Mutation_Response = {
  __typename?: 'quality_control_tasks_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Quality_Control_Tasks>;
};

/** input type for inserting object relation for remote table "quality_control_tasks" */
export type Quality_Control_Tasks_Obj_Rel_Insert_Input = {
  data: Quality_Control_Tasks_Insert_Input;
  on_conflict?: Maybe<Quality_Control_Tasks_On_Conflict>;
};

/** on conflict condition type for table "quality_control_tasks" */
export type Quality_Control_Tasks_On_Conflict = {
  constraint: Quality_Control_Tasks_Constraint;
  update_columns: Array<Quality_Control_Tasks_Update_Column>;
  where?: Maybe<Quality_Control_Tasks_Bool_Exp>;
};

/** ordering options when selecting data from "quality_control_tasks" */
export type Quality_Control_Tasks_Order_By = {
  arrows_aggregate?: Maybe<Quality_Control_Task_Arrows_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  file?: Maybe<Files_Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  quality_control_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: "quality_control_tasks" */
export type Quality_Control_Tasks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "quality_control_tasks" */
export enum Quality_Control_Tasks_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  QualityControlId = 'quality_control_id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "quality_control_tasks" */
export type Quality_Control_Tasks_Set_Input = {
  description?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  quality_control_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Quality_Control_Tasks_Stddev_Fields = {
  __typename?: 'quality_control_tasks_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "quality_control_tasks" */
export type Quality_Control_Tasks_Stddev_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Quality_Control_Tasks_Stddev_Pop_Fields = {
  __typename?: 'quality_control_tasks_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "quality_control_tasks" */
export type Quality_Control_Tasks_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Quality_Control_Tasks_Stddev_Samp_Fields = {
  __typename?: 'quality_control_tasks_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "quality_control_tasks" */
export type Quality_Control_Tasks_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Quality_Control_Tasks_Sum_Fields = {
  __typename?: 'quality_control_tasks_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "quality_control_tasks" */
export type Quality_Control_Tasks_Sum_Order_By = {
  order?: Maybe<Order_By>;
};

/** update columns of table "quality_control_tasks" */
export enum Quality_Control_Tasks_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  QualityControlId = 'quality_control_id',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type Quality_Control_Tasks_Var_Pop_Fields = {
  __typename?: 'quality_control_tasks_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "quality_control_tasks" */
export type Quality_Control_Tasks_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Quality_Control_Tasks_Var_Samp_Fields = {
  __typename?: 'quality_control_tasks_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "quality_control_tasks" */
export type Quality_Control_Tasks_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Quality_Control_Tasks_Variance_Fields = {
  __typename?: 'quality_control_tasks_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "quality_control_tasks" */
export type Quality_Control_Tasks_Variance_Order_By = {
  order?: Maybe<Order_By>;
};

/** columns and relationships of "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks = {
  __typename?: 'quality_control_template_tasks';
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  file?: Maybe<Files>;
  file_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  order: Scalars['Int'];
  template_id: Scalars['uuid'];
  title?: Maybe<Scalars['String']>;
};

/** aggregated selection of "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Aggregate = {
  __typename?: 'quality_control_template_tasks_aggregate';
  aggregate?: Maybe<Quality_Control_Template_Tasks_Aggregate_Fields>;
  nodes: Array<Quality_Control_Template_Tasks>;
};

/** aggregate fields of "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Aggregate_Fields = {
  __typename?: 'quality_control_template_tasks_aggregate_fields';
  avg?: Maybe<Quality_Control_Template_Tasks_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Quality_Control_Template_Tasks_Max_Fields>;
  min?: Maybe<Quality_Control_Template_Tasks_Min_Fields>;
  stddev?: Maybe<Quality_Control_Template_Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Quality_Control_Template_Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Quality_Control_Template_Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Quality_Control_Template_Tasks_Sum_Fields>;
  var_pop?: Maybe<Quality_Control_Template_Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Quality_Control_Template_Tasks_Var_Samp_Fields>;
  variance?: Maybe<Quality_Control_Template_Tasks_Variance_Fields>;
};


/** aggregate fields of "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Quality_Control_Template_Tasks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Aggregate_Order_By = {
  avg?: Maybe<Quality_Control_Template_Tasks_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Quality_Control_Template_Tasks_Max_Order_By>;
  min?: Maybe<Quality_Control_Template_Tasks_Min_Order_By>;
  stddev?: Maybe<Quality_Control_Template_Tasks_Stddev_Order_By>;
  stddev_pop?: Maybe<Quality_Control_Template_Tasks_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Quality_Control_Template_Tasks_Stddev_Samp_Order_By>;
  sum?: Maybe<Quality_Control_Template_Tasks_Sum_Order_By>;
  var_pop?: Maybe<Quality_Control_Template_Tasks_Var_Pop_Order_By>;
  var_samp?: Maybe<Quality_Control_Template_Tasks_Var_Samp_Order_By>;
  variance?: Maybe<Quality_Control_Template_Tasks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Quality_Control_Template_Tasks_Insert_Input>;
  on_conflict?: Maybe<Quality_Control_Template_Tasks_On_Conflict>;
};

/** aggregate avg on columns */
export type Quality_Control_Template_Tasks_Avg_Fields = {
  __typename?: 'quality_control_template_tasks_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Avg_Order_By = {
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "quality_control_template_tasks". All fields are combined with a logical 'AND'. */
export type Quality_Control_Template_Tasks_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Quality_Control_Template_Tasks_Bool_Exp>>>;
  _not?: Maybe<Quality_Control_Template_Tasks_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Quality_Control_Template_Tasks_Bool_Exp>>>;
  description?: Maybe<String_Comparison_Exp>;
  file?: Maybe<Files_Bool_Exp>;
  file_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  template_id?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "quality_control_template_tasks" */
export enum Quality_Control_Template_Tasks_Constraint {
  /** unique or primary key constraint */
  QualityControlTemplateTasksPkey = 'quality_control_template_tasks_pkey'
}

/** input type for incrementing integer column in table "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  file?: Maybe<Files_Obj_Rel_Insert_Input>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  template_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Quality_Control_Template_Tasks_Max_Fields = {
  __typename?: 'quality_control_template_tasks_max_fields';
  description?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  template_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Max_Order_By = {
  description?: Maybe<Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  template_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Quality_Control_Template_Tasks_Min_Fields = {
  __typename?: 'quality_control_template_tasks_min_fields';
  description?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  template_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Min_Order_By = {
  description?: Maybe<Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  template_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Mutation_Response = {
  __typename?: 'quality_control_template_tasks_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Quality_Control_Template_Tasks>;
};

/** input type for inserting object relation for remote table "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Obj_Rel_Insert_Input = {
  data: Quality_Control_Template_Tasks_Insert_Input;
  on_conflict?: Maybe<Quality_Control_Template_Tasks_On_Conflict>;
};

/** on conflict condition type for table "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_On_Conflict = {
  constraint: Quality_Control_Template_Tasks_Constraint;
  update_columns: Array<Quality_Control_Template_Tasks_Update_Column>;
  where?: Maybe<Quality_Control_Template_Tasks_Bool_Exp>;
};

/** ordering options when selecting data from "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Order_By = {
  description?: Maybe<Order_By>;
  file?: Maybe<Files_Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  template_id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "quality_control_template_tasks" */
export enum Quality_Control_Template_Tasks_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Set_Input = {
  description?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  template_id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Quality_Control_Template_Tasks_Stddev_Fields = {
  __typename?: 'quality_control_template_tasks_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Stddev_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Quality_Control_Template_Tasks_Stddev_Pop_Fields = {
  __typename?: 'quality_control_template_tasks_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Quality_Control_Template_Tasks_Stddev_Samp_Fields = {
  __typename?: 'quality_control_template_tasks_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Quality_Control_Template_Tasks_Sum_Fields = {
  __typename?: 'quality_control_template_tasks_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Sum_Order_By = {
  order?: Maybe<Order_By>;
};

/** update columns of table "quality_control_template_tasks" */
export enum Quality_Control_Template_Tasks_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  Title = 'title'
}

/** aggregate var_pop on columns */
export type Quality_Control_Template_Tasks_Var_Pop_Fields = {
  __typename?: 'quality_control_template_tasks_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Quality_Control_Template_Tasks_Var_Samp_Fields = {
  __typename?: 'quality_control_template_tasks_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Quality_Control_Template_Tasks_Variance_Fields = {
  __typename?: 'quality_control_template_tasks_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "quality_control_template_tasks" */
export type Quality_Control_Template_Tasks_Variance_Order_By = {
  order?: Maybe<Order_By>;
};

/** columns and relationships of "quality_control_templates" */
export type Quality_Control_Templates = {
  __typename?: 'quality_control_templates';
  changed: Scalars['String'];
  created: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  tasks: Array<Quality_Control_Template_Tasks>;
  /** An aggregated array relationship */
  tasks_aggregate: Quality_Control_Template_Tasks_Aggregate;
  template_title: Scalars['String'];
  title: Scalars['String'];
};


/** columns and relationships of "quality_control_templates" */
export type Quality_Control_TemplatesTasksArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Template_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Template_Tasks_Order_By>>;
  where?: Maybe<Quality_Control_Template_Tasks_Bool_Exp>;
};


/** columns and relationships of "quality_control_templates" */
export type Quality_Control_TemplatesTasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Template_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Template_Tasks_Order_By>>;
  where?: Maybe<Quality_Control_Template_Tasks_Bool_Exp>;
};

/** aggregated selection of "quality_control_templates" */
export type Quality_Control_Templates_Aggregate = {
  __typename?: 'quality_control_templates_aggregate';
  aggregate?: Maybe<Quality_Control_Templates_Aggregate_Fields>;
  nodes: Array<Quality_Control_Templates>;
};

/** aggregate fields of "quality_control_templates" */
export type Quality_Control_Templates_Aggregate_Fields = {
  __typename?: 'quality_control_templates_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Quality_Control_Templates_Max_Fields>;
  min?: Maybe<Quality_Control_Templates_Min_Fields>;
};


/** aggregate fields of "quality_control_templates" */
export type Quality_Control_Templates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Quality_Control_Templates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "quality_control_templates" */
export type Quality_Control_Templates_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Quality_Control_Templates_Max_Order_By>;
  min?: Maybe<Quality_Control_Templates_Min_Order_By>;
};

/** input type for inserting array relation for remote table "quality_control_templates" */
export type Quality_Control_Templates_Arr_Rel_Insert_Input = {
  data: Array<Quality_Control_Templates_Insert_Input>;
  on_conflict?: Maybe<Quality_Control_Templates_On_Conflict>;
};

/** Boolean expression to filter rows from the table "quality_control_templates". All fields are combined with a logical 'AND'. */
export type Quality_Control_Templates_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Quality_Control_Templates_Bool_Exp>>>;
  _not?: Maybe<Quality_Control_Templates_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Quality_Control_Templates_Bool_Exp>>>;
  changed?: Maybe<String_Comparison_Exp>;
  created?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  tasks?: Maybe<Quality_Control_Template_Tasks_Bool_Exp>;
  template_title?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "quality_control_templates" */
export enum Quality_Control_Templates_Constraint {
  /** unique or primary key constraint */
  QualityControlTemplatesPkey = 'quality_control_templates_pkey'
}

/** input type for inserting data into table "quality_control_templates" */
export type Quality_Control_Templates_Insert_Input = {
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  tasks?: Maybe<Quality_Control_Template_Tasks_Arr_Rel_Insert_Input>;
  template_title?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Quality_Control_Templates_Max_Fields = {
  __typename?: 'quality_control_templates_max_fields';
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  template_title?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "quality_control_templates" */
export type Quality_Control_Templates_Max_Order_By = {
  changed?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  template_title?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Quality_Control_Templates_Min_Fields = {
  __typename?: 'quality_control_templates_min_fields';
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  template_title?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "quality_control_templates" */
export type Quality_Control_Templates_Min_Order_By = {
  changed?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  template_title?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "quality_control_templates" */
export type Quality_Control_Templates_Mutation_Response = {
  __typename?: 'quality_control_templates_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Quality_Control_Templates>;
};

/** input type for inserting object relation for remote table "quality_control_templates" */
export type Quality_Control_Templates_Obj_Rel_Insert_Input = {
  data: Quality_Control_Templates_Insert_Input;
  on_conflict?: Maybe<Quality_Control_Templates_On_Conflict>;
};

/** on conflict condition type for table "quality_control_templates" */
export type Quality_Control_Templates_On_Conflict = {
  constraint: Quality_Control_Templates_Constraint;
  update_columns: Array<Quality_Control_Templates_Update_Column>;
  where?: Maybe<Quality_Control_Templates_Bool_Exp>;
};

/** ordering options when selecting data from "quality_control_templates" */
export type Quality_Control_Templates_Order_By = {
  changed?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  tasks_aggregate?: Maybe<Quality_Control_Template_Tasks_Aggregate_Order_By>;
  template_title?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: "quality_control_templates" */
export type Quality_Control_Templates_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "quality_control_templates" */
export enum Quality_Control_Templates_Select_Column {
  /** column name */
  Changed = 'changed',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  TemplateTitle = 'template_title',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "quality_control_templates" */
export type Quality_Control_Templates_Set_Input = {
  changed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  template_title?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** update columns of table "quality_control_templates" */
export enum Quality_Control_Templates_Update_Column {
  /** column name */
  Changed = 'changed',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id',
  /** column name */
  TemplateTitle = 'template_title',
  /** column name */
  Title = 'title'
}

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "absence" */
  absence: Array<Absence>;
  /** fetch aggregated fields from the table: "absence" */
  absence_aggregate: Absence_Aggregate;
  /** fetch data from the table: "absence" using primary key columns */
  absence_by_pk?: Maybe<Absence>;
  /** fetch data from the table: "absence_search_view" */
  absence_search_view: Array<Absence_Search_View>;
  /** fetch aggregated fields from the table: "absence_search_view" */
  absence_search_view_aggregate: Absence_Search_View_Aggregate;
  /** fetch data from the table: "absence_types" */
  absence_types: Array<Absence_Types>;
  /** fetch aggregated fields from the table: "absence_types" */
  absence_types_aggregate: Absence_Types_Aggregate;
  /** fetch data from the table: "absence_types" using primary key columns */
  absence_types_by_pk?: Maybe<Absence_Types>;
  /** fetch data from the table: "archive_files" */
  archive_files: Array<Archive_Files>;
  /** fetch aggregated fields from the table: "archive_files" */
  archive_files_aggregate: Archive_Files_Aggregate;
  /** fetch data from the table: "archive_files" using primary key columns */
  archive_files_by_pk?: Maybe<Archive_Files>;
  /** fetch data from the table: "case_doc_checklists" */
  case_doc_checklists: Array<Case_Doc_Checklists>;
  /** fetch aggregated fields from the table: "case_doc_checklists" */
  case_doc_checklists_aggregate: Case_Doc_Checklists_Aggregate;
  /** fetch data from the table: "case_doc_checklists" using primary key columns */
  case_doc_checklists_by_pk?: Maybe<Case_Doc_Checklists>;
  /** fetch data from the table: "case_doc_economy" */
  case_doc_economy: Array<Case_Doc_Economy>;
  /** fetch aggregated fields from the table: "case_doc_economy" */
  case_doc_economy_aggregate: Case_Doc_Economy_Aggregate;
  /** fetch data from the table: "case_doc_economy" using primary key columns */
  case_doc_economy_by_pk?: Maybe<Case_Doc_Economy>;
  /** fetch data from the table: "case_doc_notes" */
  case_doc_notes: Array<Case_Doc_Notes>;
  /** fetch aggregated fields from the table: "case_doc_notes" */
  case_doc_notes_aggregate: Case_Doc_Notes_Aggregate;
  /** fetch data from the table: "case_doc_notes" using primary key columns */
  case_doc_notes_by_pk?: Maybe<Case_Doc_Notes>;
  /** fetch data from the table: "case_doc_order_confirmations" */
  case_doc_order_confirmations: Array<Case_Doc_Order_Confirmations>;
  /** fetch aggregated fields from the table: "case_doc_order_confirmations" */
  case_doc_order_confirmations_aggregate: Case_Doc_Order_Confirmations_Aggregate;
  /** fetch data from the table: "case_doc_order_confirmations" using primary key columns */
  case_doc_order_confirmations_by_pk?: Maybe<Case_Doc_Order_Confirmations>;
  /** fetch data from the table: "case_doc_quality_controls" */
  case_doc_quality_controls: Array<Case_Doc_Quality_Controls>;
  /** fetch aggregated fields from the table: "case_doc_quality_controls" */
  case_doc_quality_controls_aggregate: Case_Doc_Quality_Controls_Aggregate;
  /** fetch data from the table: "case_doc_quality_controls" using primary key columns */
  case_doc_quality_controls_by_pk?: Maybe<Case_Doc_Quality_Controls>;
  /** fetch data from the table: "case_files" */
  case_files: Array<Case_Files>;
  /** fetch aggregated fields from the table: "case_files" */
  case_files_aggregate: Case_Files_Aggregate;
  /** fetch data from the table: "case_files" using primary key columns */
  case_files_by_pk?: Maybe<Case_Files>;
  /** fetch data from the table: "case_photos" */
  case_photos: Array<Case_Photos>;
  /** fetch aggregated fields from the table: "case_photos" */
  case_photos_aggregate: Case_Photos_Aggregate;
  /** fetch data from the table: "case_photos" using primary key columns */
  case_photos_by_pk?: Maybe<Case_Photos>;
  /** fetch data from the table: "case_suppliers" */
  case_suppliers: Array<Case_Suppliers>;
  /** fetch aggregated fields from the table: "case_suppliers" */
  case_suppliers_aggregate: Case_Suppliers_Aggregate;
  /** fetch data from the table: "case_suppliers" using primary key columns */
  case_suppliers_by_pk?: Maybe<Case_Suppliers>;
  /** fetch data from the table: "case_time_entries" */
  case_time_entries: Array<Case_Time_Entries>;
  /** fetch aggregated fields from the table: "case_time_entries" */
  case_time_entries_aggregate: Case_Time_Entries_Aggregate;
  /** fetch data from the table: "case_time_entries" using primary key columns */
  case_time_entries_by_pk?: Maybe<Case_Time_Entries>;
  /** fetch data from the table: "case_types" */
  case_types: Array<Case_Types>;
  /** fetch aggregated fields from the table: "case_types" */
  case_types_aggregate: Case_Types_Aggregate;
  /** fetch data from the table: "case_types" using primary key columns */
  case_types_by_pk?: Maybe<Case_Types>;
  /** fetch data from the table: "cases" */
  cases: Array<Cases>;
  /** fetch aggregated fields from the table: "cases" */
  cases_aggregate: Cases_Aggregate;
  /** fetch data from the table: "cases_building_inspection_per_employee" */
  cases_building_inspection_per_employee: Array<Cases_Building_Inspection_Per_Employee>;
  /** fetch aggregated fields from the table: "cases_building_inspection_per_employee" */
  cases_building_inspection_per_employee_aggregate: Cases_Building_Inspection_Per_Employee_Aggregate;
  /** fetch data from the table: "cases" using primary key columns */
  cases_by_pk?: Maybe<Cases>;
  /** fetch data from the table: "cases_case_count_per_employee" */
  cases_case_count_per_employee: Array<Cases_Case_Count_Per_Employee>;
  /** fetch aggregated fields from the table: "cases_case_count_per_employee" */
  cases_case_count_per_employee_aggregate: Cases_Case_Count_Per_Employee_Aggregate;
  /** fetch data from the table: "cases_case_type_count" */
  cases_case_type_count: Array<Cases_Case_Type_Count>;
  /** fetch aggregated fields from the table: "cases_case_type_count" */
  cases_case_type_count_aggregate: Cases_Case_Type_Count_Aggregate;
  /** fetch data from the table: "cases_created_per_employee_per_month" */
  cases_created_per_employee_per_month: Array<Cases_Created_Per_Employee_Per_Month>;
  /** fetch aggregated fields from the table: "cases_created_per_employee_per_month" */
  cases_created_per_employee_per_month_aggregate: Cases_Created_Per_Employee_Per_Month_Aggregate;
  /** fetch data from the table: "cases_per_case_type_per_month" */
  cases_per_case_type_per_month: Array<Cases_Per_Case_Type_Per_Month>;
  /** fetch aggregated fields from the table: "cases_per_case_type_per_month" */
  cases_per_case_type_per_month_aggregate: Cases_Per_Case_Type_Per_Month_Aggregate;
  /** fetch data from the table: "checklist_item_arrows" */
  checklist_item_arrows: Array<Checklist_Item_Arrows>;
  /** fetch aggregated fields from the table: "checklist_item_arrows" */
  checklist_item_arrows_aggregate: Checklist_Item_Arrows_Aggregate;
  /** fetch data from the table: "checklist_item_arrows" using primary key columns */
  checklist_item_arrows_by_pk?: Maybe<Checklist_Item_Arrows>;
  /** fetch data from the table: "checklist_items" */
  checklist_items: Array<Checklist_Items>;
  /** fetch aggregated fields from the table: "checklist_items" */
  checklist_items_aggregate: Checklist_Items_Aggregate;
  /** fetch data from the table: "checklist_items" using primary key columns */
  checklist_items_by_pk?: Maybe<Checklist_Items>;
  /** fetch data from the table: "construction_plan_entries" */
  construction_plan_entries: Array<Construction_Plan_Entries>;
  /** fetch aggregated fields from the table: "construction_plan_entries" */
  construction_plan_entries_aggregate: Construction_Plan_Entries_Aggregate;
  /** fetch data from the table: "construction_plan_entries" using primary key columns */
  construction_plan_entries_by_pk?: Maybe<Construction_Plan_Entries>;
  /** fetch data from the table: "construction_plans" */
  construction_plans: Array<Construction_Plans>;
  /** fetch aggregated fields from the table: "construction_plans" */
  construction_plans_aggregate: Construction_Plans_Aggregate;
  /** fetch data from the table: "construction_plans" using primary key columns */
  construction_plans_by_pk?: Maybe<Construction_Plans>;
  /** fetch data from the table: "contacts" */
  contacts: Array<Contacts>;
  /** fetch aggregated fields from the table: "contacts" */
  contacts_aggregate: Contacts_Aggregate;
  /** fetch data from the table: "contacts" using primary key columns */
  contacts_by_pk?: Maybe<Contacts>;
  /** fetch data from the table: "files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "files" */
  files_aggregate: Files_Aggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  /** fetch data from the table: "order_confirmation_tasks" */
  order_confirmation_tasks: Array<Order_Confirmation_Tasks>;
  /** fetch aggregated fields from the table: "order_confirmation_tasks" */
  order_confirmation_tasks_aggregate: Order_Confirmation_Tasks_Aggregate;
  /** fetch data from the table: "order_confirmation_tasks" using primary key columns */
  order_confirmation_tasks_by_pk?: Maybe<Order_Confirmation_Tasks>;
  /** fetch data from the table: "order_confirmation_template_tasks" */
  order_confirmation_template_tasks: Array<Order_Confirmation_Template_Tasks>;
  /** fetch aggregated fields from the table: "order_confirmation_template_tasks" */
  order_confirmation_template_tasks_aggregate: Order_Confirmation_Template_Tasks_Aggregate;
  /** fetch data from the table: "order_confirmation_template_tasks" using primary key columns */
  order_confirmation_template_tasks_by_pk?: Maybe<Order_Confirmation_Template_Tasks>;
  /** fetch data from the table: "order_confirmation_templates" */
  order_confirmation_templates: Array<Order_Confirmation_Templates>;
  /** fetch aggregated fields from the table: "order_confirmation_templates" */
  order_confirmation_templates_aggregate: Order_Confirmation_Templates_Aggregate;
  /** fetch data from the table: "order_confirmation_templates" using primary key columns */
  order_confirmation_templates_by_pk?: Maybe<Order_Confirmation_Templates>;
  /** fetch data from the table: "quality_control_task_arrows" */
  quality_control_task_arrows: Array<Quality_Control_Task_Arrows>;
  /** fetch aggregated fields from the table: "quality_control_task_arrows" */
  quality_control_task_arrows_aggregate: Quality_Control_Task_Arrows_Aggregate;
  /** fetch data from the table: "quality_control_task_arrows" using primary key columns */
  quality_control_task_arrows_by_pk?: Maybe<Quality_Control_Task_Arrows>;
  /** fetch data from the table: "quality_control_tasks" */
  quality_control_tasks: Array<Quality_Control_Tasks>;
  /** fetch aggregated fields from the table: "quality_control_tasks" */
  quality_control_tasks_aggregate: Quality_Control_Tasks_Aggregate;
  /** fetch data from the table: "quality_control_tasks" using primary key columns */
  quality_control_tasks_by_pk?: Maybe<Quality_Control_Tasks>;
  /** fetch data from the table: "quality_control_template_tasks" */
  quality_control_template_tasks: Array<Quality_Control_Template_Tasks>;
  /** fetch aggregated fields from the table: "quality_control_template_tasks" */
  quality_control_template_tasks_aggregate: Quality_Control_Template_Tasks_Aggregate;
  /** fetch data from the table: "quality_control_template_tasks" using primary key columns */
  quality_control_template_tasks_by_pk?: Maybe<Quality_Control_Template_Tasks>;
  /** fetch data from the table: "quality_control_templates" */
  quality_control_templates: Array<Quality_Control_Templates>;
  /** fetch aggregated fields from the table: "quality_control_templates" */
  quality_control_templates_aggregate: Quality_Control_Templates_Aggregate;
  /** fetch data from the table: "quality_control_templates" using primary key columns */
  quality_control_templates_by_pk?: Maybe<Quality_Control_Templates>;
  /** fetch data from the table: "reminder_recipients" */
  reminder_recipients: Array<Reminder_Recipients>;
  /** fetch aggregated fields from the table: "reminder_recipients" */
  reminder_recipients_aggregate: Reminder_Recipients_Aggregate;
  /** fetch data from the table: "reminder_recipients" using primary key columns */
  reminder_recipients_by_pk?: Maybe<Reminder_Recipients>;
  /** fetch data from the table: "reminders" */
  reminders: Array<Reminders>;
  /** fetch aggregated fields from the table: "reminders" */
  reminders_aggregate: Reminders_Aggregate;
  /** fetch data from the table: "reminders" using primary key columns */
  reminders_by_pk?: Maybe<Reminders>;
  /** fetch data from the table: "request_notes" */
  request_notes: Array<Request_Notes>;
  /** fetch aggregated fields from the table: "request_notes" */
  request_notes_aggregate: Request_Notes_Aggregate;
  /** fetch data from the table: "request_notes" using primary key columns */
  request_notes_by_pk?: Maybe<Request_Notes>;
  /** fetch data from the table: "requests" */
  requests: Array<Requests>;
  /** fetch aggregated fields from the table: "requests" */
  requests_aggregate: Requests_Aggregate;
  /** fetch data from the table: "requests" using primary key columns */
  requests_by_pk?: Maybe<Requests>;
  /** fetch data from the table: "requests_done_per_employee_per_month" */
  requests_done_per_employee_per_month: Array<Requests_Done_Per_Employee_Per_Month>;
  /** fetch aggregated fields from the table: "requests_done_per_employee_per_month" */
  requests_done_per_employee_per_month_aggregate: Requests_Done_Per_Employee_Per_Month_Aggregate;
  /** fetch data from the table: "requests_followup_task_count" */
  requests_followup_task_count: Array<Requests_Followup_Task_Count>;
  /** fetch aggregated fields from the table: "requests_followup_task_count" */
  requests_followup_task_count_aggregate: Requests_Followup_Task_Count_Aggregate;
  /** execute function "requests_followup_task_count_for_user" which returns "requests_followup_task_count" */
  requests_followup_task_count_for_user: Array<Requests_Followup_Task_Count>;
  /** execute function "requests_followup_task_count_for_user" and query aggregates on result of table type "requests_followup_task_count" */
  requests_followup_task_count_for_user_aggregate: Requests_Followup_Task_Count_Aggregate;
  /** fetch data from the table: "requests_followup_task_cout" */
  requests_followup_task_cout: Array<Requests_Followup_Task_Cout>;
  /** fetch aggregated fields from the table: "requests_followup_task_cout" */
  requests_followup_task_cout_aggregate: Requests_Followup_Task_Cout_Aggregate;
  /** fetch data from the table: "requests_new_task_count" */
  requests_new_task_count: Array<Requests_New_Task_Count>;
  /** fetch aggregated fields from the table: "requests_new_task_count" */
  requests_new_task_count_aggregate: Requests_New_Task_Count_Aggregate;
  /** fetch data from the table: "requests_new_task_cout" */
  requests_new_task_cout: Array<Requests_New_Task_Cout>;
  /** fetch aggregated fields from the table: "requests_new_task_cout" */
  requests_new_task_cout_aggregate: Requests_New_Task_Cout_Aggregate;
  /** fetch data from the table: "requests_per_case_type_per_month" */
  requests_per_case_type_per_month: Array<Requests_Per_Case_Type_Per_Month>;
  /** fetch aggregated fields from the table: "requests_per_case_type_per_month" */
  requests_per_case_type_per_month_aggregate: Requests_Per_Case_Type_Per_Month_Aggregate;
  /** fetch data from the table: "standard_photos" */
  standard_photos: Array<Standard_Photos>;
  /** fetch aggregated fields from the table: "standard_photos" */
  standard_photos_aggregate: Standard_Photos_Aggregate;
  /** fetch data from the table: "standard_photos" using primary key columns */
  standard_photos_by_pk?: Maybe<Standard_Photos>;
  /** fetch data from the table: "standard_terms" */
  standard_terms: Array<Standard_Terms>;
  /** fetch aggregated fields from the table: "standard_terms" */
  standard_terms_aggregate: Standard_Terms_Aggregate;
  /** fetch data from the table: "standard_terms" using primary key columns */
  standard_terms_by_pk?: Maybe<Standard_Terms>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


/** query root */
export type Query_RootAbsenceArgs = {
  distinct_on?: Maybe<Array<Absence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Absence_Order_By>>;
  where?: Maybe<Absence_Bool_Exp>;
};


/** query root */
export type Query_RootAbsence_AggregateArgs = {
  distinct_on?: Maybe<Array<Absence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Absence_Order_By>>;
  where?: Maybe<Absence_Bool_Exp>;
};


/** query root */
export type Query_RootAbsence_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootAbsence_Search_ViewArgs = {
  distinct_on?: Maybe<Array<Absence_Search_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Absence_Search_View_Order_By>>;
  where?: Maybe<Absence_Search_View_Bool_Exp>;
};


/** query root */
export type Query_RootAbsence_Search_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Absence_Search_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Absence_Search_View_Order_By>>;
  where?: Maybe<Absence_Search_View_Bool_Exp>;
};


/** query root */
export type Query_RootAbsence_TypesArgs = {
  distinct_on?: Maybe<Array<Absence_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Absence_Types_Order_By>>;
  where?: Maybe<Absence_Types_Bool_Exp>;
};


/** query root */
export type Query_RootAbsence_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Absence_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Absence_Types_Order_By>>;
  where?: Maybe<Absence_Types_Bool_Exp>;
};


/** query root */
export type Query_RootAbsence_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootArchive_FilesArgs = {
  distinct_on?: Maybe<Array<Archive_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Archive_Files_Order_By>>;
  where?: Maybe<Archive_Files_Bool_Exp>;
};


/** query root */
export type Query_RootArchive_Files_AggregateArgs = {
  distinct_on?: Maybe<Array<Archive_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Archive_Files_Order_By>>;
  where?: Maybe<Archive_Files_Bool_Exp>;
};


/** query root */
export type Query_RootArchive_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCase_Doc_ChecklistsArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Checklists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Checklists_Order_By>>;
  where?: Maybe<Case_Doc_Checklists_Bool_Exp>;
};


/** query root */
export type Query_RootCase_Doc_Checklists_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Checklists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Checklists_Order_By>>;
  where?: Maybe<Case_Doc_Checklists_Bool_Exp>;
};


/** query root */
export type Query_RootCase_Doc_Checklists_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCase_Doc_EconomyArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Economy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Economy_Order_By>>;
  where?: Maybe<Case_Doc_Economy_Bool_Exp>;
};


/** query root */
export type Query_RootCase_Doc_Economy_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Economy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Economy_Order_By>>;
  where?: Maybe<Case_Doc_Economy_Bool_Exp>;
};


/** query root */
export type Query_RootCase_Doc_Economy_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCase_Doc_NotesArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Notes_Order_By>>;
  where?: Maybe<Case_Doc_Notes_Bool_Exp>;
};


/** query root */
export type Query_RootCase_Doc_Notes_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Notes_Order_By>>;
  where?: Maybe<Case_Doc_Notes_Bool_Exp>;
};


/** query root */
export type Query_RootCase_Doc_Notes_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCase_Doc_Order_ConfirmationsArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Order_Confirmations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Order_Confirmations_Order_By>>;
  where?: Maybe<Case_Doc_Order_Confirmations_Bool_Exp>;
};


/** query root */
export type Query_RootCase_Doc_Order_Confirmations_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Order_Confirmations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Order_Confirmations_Order_By>>;
  where?: Maybe<Case_Doc_Order_Confirmations_Bool_Exp>;
};


/** query root */
export type Query_RootCase_Doc_Order_Confirmations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCase_Doc_Quality_ControlsArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Quality_Controls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Quality_Controls_Order_By>>;
  where?: Maybe<Case_Doc_Quality_Controls_Bool_Exp>;
};


/** query root */
export type Query_RootCase_Doc_Quality_Controls_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Quality_Controls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Quality_Controls_Order_By>>;
  where?: Maybe<Case_Doc_Quality_Controls_Bool_Exp>;
};


/** query root */
export type Query_RootCase_Doc_Quality_Controls_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCase_FilesArgs = {
  distinct_on?: Maybe<Array<Case_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Files_Order_By>>;
  where?: Maybe<Case_Files_Bool_Exp>;
};


/** query root */
export type Query_RootCase_Files_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Files_Order_By>>;
  where?: Maybe<Case_Files_Bool_Exp>;
};


/** query root */
export type Query_RootCase_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCase_PhotosArgs = {
  distinct_on?: Maybe<Array<Case_Photos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Photos_Order_By>>;
  where?: Maybe<Case_Photos_Bool_Exp>;
};


/** query root */
export type Query_RootCase_Photos_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Photos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Photos_Order_By>>;
  where?: Maybe<Case_Photos_Bool_Exp>;
};


/** query root */
export type Query_RootCase_Photos_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCase_SuppliersArgs = {
  distinct_on?: Maybe<Array<Case_Suppliers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Suppliers_Order_By>>;
  where?: Maybe<Case_Suppliers_Bool_Exp>;
};


/** query root */
export type Query_RootCase_Suppliers_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Suppliers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Suppliers_Order_By>>;
  where?: Maybe<Case_Suppliers_Bool_Exp>;
};


/** query root */
export type Query_RootCase_Suppliers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCase_Time_EntriesArgs = {
  distinct_on?: Maybe<Array<Case_Time_Entries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Time_Entries_Order_By>>;
  where?: Maybe<Case_Time_Entries_Bool_Exp>;
};


/** query root */
export type Query_RootCase_Time_Entries_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Time_Entries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Time_Entries_Order_By>>;
  where?: Maybe<Case_Time_Entries_Bool_Exp>;
};


/** query root */
export type Query_RootCase_Time_Entries_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCase_TypesArgs = {
  distinct_on?: Maybe<Array<Case_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Types_Order_By>>;
  where?: Maybe<Case_Types_Bool_Exp>;
};


/** query root */
export type Query_RootCase_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Types_Order_By>>;
  where?: Maybe<Case_Types_Bool_Exp>;
};


/** query root */
export type Query_RootCase_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCasesArgs = {
  distinct_on?: Maybe<Array<Cases_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Order_By>>;
  where?: Maybe<Cases_Bool_Exp>;
};


/** query root */
export type Query_RootCases_AggregateArgs = {
  distinct_on?: Maybe<Array<Cases_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Order_By>>;
  where?: Maybe<Cases_Bool_Exp>;
};


/** query root */
export type Query_RootCases_Building_Inspection_Per_EmployeeArgs = {
  distinct_on?: Maybe<Array<Cases_Building_Inspection_Per_Employee_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Building_Inspection_Per_Employee_Order_By>>;
  where?: Maybe<Cases_Building_Inspection_Per_Employee_Bool_Exp>;
};


/** query root */
export type Query_RootCases_Building_Inspection_Per_Employee_AggregateArgs = {
  distinct_on?: Maybe<Array<Cases_Building_Inspection_Per_Employee_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Building_Inspection_Per_Employee_Order_By>>;
  where?: Maybe<Cases_Building_Inspection_Per_Employee_Bool_Exp>;
};


/** query root */
export type Query_RootCases_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCases_Case_Count_Per_EmployeeArgs = {
  distinct_on?: Maybe<Array<Cases_Case_Count_Per_Employee_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Case_Count_Per_Employee_Order_By>>;
  where?: Maybe<Cases_Case_Count_Per_Employee_Bool_Exp>;
};


/** query root */
export type Query_RootCases_Case_Count_Per_Employee_AggregateArgs = {
  distinct_on?: Maybe<Array<Cases_Case_Count_Per_Employee_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Case_Count_Per_Employee_Order_By>>;
  where?: Maybe<Cases_Case_Count_Per_Employee_Bool_Exp>;
};


/** query root */
export type Query_RootCases_Case_Type_CountArgs = {
  distinct_on?: Maybe<Array<Cases_Case_Type_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Case_Type_Count_Order_By>>;
  where?: Maybe<Cases_Case_Type_Count_Bool_Exp>;
};


/** query root */
export type Query_RootCases_Case_Type_Count_AggregateArgs = {
  distinct_on?: Maybe<Array<Cases_Case_Type_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Case_Type_Count_Order_By>>;
  where?: Maybe<Cases_Case_Type_Count_Bool_Exp>;
};


/** query root */
export type Query_RootCases_Created_Per_Employee_Per_MonthArgs = {
  distinct_on?: Maybe<Array<Cases_Created_Per_Employee_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Created_Per_Employee_Per_Month_Order_By>>;
  where?: Maybe<Cases_Created_Per_Employee_Per_Month_Bool_Exp>;
};


/** query root */
export type Query_RootCases_Created_Per_Employee_Per_Month_AggregateArgs = {
  distinct_on?: Maybe<Array<Cases_Created_Per_Employee_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Created_Per_Employee_Per_Month_Order_By>>;
  where?: Maybe<Cases_Created_Per_Employee_Per_Month_Bool_Exp>;
};


/** query root */
export type Query_RootCases_Per_Case_Type_Per_MonthArgs = {
  distinct_on?: Maybe<Array<Cases_Per_Case_Type_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Per_Case_Type_Per_Month_Order_By>>;
  where?: Maybe<Cases_Per_Case_Type_Per_Month_Bool_Exp>;
};


/** query root */
export type Query_RootCases_Per_Case_Type_Per_Month_AggregateArgs = {
  distinct_on?: Maybe<Array<Cases_Per_Case_Type_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Per_Case_Type_Per_Month_Order_By>>;
  where?: Maybe<Cases_Per_Case_Type_Per_Month_Bool_Exp>;
};


/** query root */
export type Query_RootChecklist_Item_ArrowsArgs = {
  distinct_on?: Maybe<Array<Checklist_Item_Arrows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Checklist_Item_Arrows_Order_By>>;
  where?: Maybe<Checklist_Item_Arrows_Bool_Exp>;
};


/** query root */
export type Query_RootChecklist_Item_Arrows_AggregateArgs = {
  distinct_on?: Maybe<Array<Checklist_Item_Arrows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Checklist_Item_Arrows_Order_By>>;
  where?: Maybe<Checklist_Item_Arrows_Bool_Exp>;
};


/** query root */
export type Query_RootChecklist_Item_Arrows_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootChecklist_ItemsArgs = {
  distinct_on?: Maybe<Array<Checklist_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Checklist_Items_Order_By>>;
  where?: Maybe<Checklist_Items_Bool_Exp>;
};


/** query root */
export type Query_RootChecklist_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Checklist_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Checklist_Items_Order_By>>;
  where?: Maybe<Checklist_Items_Bool_Exp>;
};


/** query root */
export type Query_RootChecklist_Items_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootConstruction_Plan_EntriesArgs = {
  distinct_on?: Maybe<Array<Construction_Plan_Entries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Construction_Plan_Entries_Order_By>>;
  where?: Maybe<Construction_Plan_Entries_Bool_Exp>;
};


/** query root */
export type Query_RootConstruction_Plan_Entries_AggregateArgs = {
  distinct_on?: Maybe<Array<Construction_Plan_Entries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Construction_Plan_Entries_Order_By>>;
  where?: Maybe<Construction_Plan_Entries_Bool_Exp>;
};


/** query root */
export type Query_RootConstruction_Plan_Entries_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootConstruction_PlansArgs = {
  distinct_on?: Maybe<Array<Construction_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Construction_Plans_Order_By>>;
  where?: Maybe<Construction_Plans_Bool_Exp>;
};


/** query root */
export type Query_RootConstruction_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Construction_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Construction_Plans_Order_By>>;
  where?: Maybe<Construction_Plans_Bool_Exp>;
};


/** query root */
export type Query_RootConstruction_Plans_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootContactsArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


/** query root */
export type Query_RootContacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


/** query root */
export type Query_RootContacts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootFilesArgs = {
  distinct_on?: Maybe<Array<Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Order_By>>;
  where?: Maybe<Files_Bool_Exp>;
};


/** query root */
export type Query_RootFiles_AggregateArgs = {
  distinct_on?: Maybe<Array<Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Order_By>>;
  where?: Maybe<Files_Bool_Exp>;
};


/** query root */
export type Query_RootFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOrder_Confirmation_TasksArgs = {
  distinct_on?: Maybe<Array<Order_Confirmation_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Confirmation_Tasks_Order_By>>;
  where?: Maybe<Order_Confirmation_Tasks_Bool_Exp>;
};


/** query root */
export type Query_RootOrder_Confirmation_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Confirmation_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Confirmation_Tasks_Order_By>>;
  where?: Maybe<Order_Confirmation_Tasks_Bool_Exp>;
};


/** query root */
export type Query_RootOrder_Confirmation_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOrder_Confirmation_Template_TasksArgs = {
  distinct_on?: Maybe<Array<Order_Confirmation_Template_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Confirmation_Template_Tasks_Order_By>>;
  where?: Maybe<Order_Confirmation_Template_Tasks_Bool_Exp>;
};


/** query root */
export type Query_RootOrder_Confirmation_Template_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Confirmation_Template_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Confirmation_Template_Tasks_Order_By>>;
  where?: Maybe<Order_Confirmation_Template_Tasks_Bool_Exp>;
};


/** query root */
export type Query_RootOrder_Confirmation_Template_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOrder_Confirmation_TemplatesArgs = {
  distinct_on?: Maybe<Array<Order_Confirmation_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Confirmation_Templates_Order_By>>;
  where?: Maybe<Order_Confirmation_Templates_Bool_Exp>;
};


/** query root */
export type Query_RootOrder_Confirmation_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Confirmation_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Confirmation_Templates_Order_By>>;
  where?: Maybe<Order_Confirmation_Templates_Bool_Exp>;
};


/** query root */
export type Query_RootOrder_Confirmation_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootQuality_Control_Task_ArrowsArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Task_Arrows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Task_Arrows_Order_By>>;
  where?: Maybe<Quality_Control_Task_Arrows_Bool_Exp>;
};


/** query root */
export type Query_RootQuality_Control_Task_Arrows_AggregateArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Task_Arrows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Task_Arrows_Order_By>>;
  where?: Maybe<Quality_Control_Task_Arrows_Bool_Exp>;
};


/** query root */
export type Query_RootQuality_Control_Task_Arrows_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootQuality_Control_TasksArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Tasks_Order_By>>;
  where?: Maybe<Quality_Control_Tasks_Bool_Exp>;
};


/** query root */
export type Query_RootQuality_Control_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Tasks_Order_By>>;
  where?: Maybe<Quality_Control_Tasks_Bool_Exp>;
};


/** query root */
export type Query_RootQuality_Control_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootQuality_Control_Template_TasksArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Template_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Template_Tasks_Order_By>>;
  where?: Maybe<Quality_Control_Template_Tasks_Bool_Exp>;
};


/** query root */
export type Query_RootQuality_Control_Template_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Template_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Template_Tasks_Order_By>>;
  where?: Maybe<Quality_Control_Template_Tasks_Bool_Exp>;
};


/** query root */
export type Query_RootQuality_Control_Template_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootQuality_Control_TemplatesArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Templates_Order_By>>;
  where?: Maybe<Quality_Control_Templates_Bool_Exp>;
};


/** query root */
export type Query_RootQuality_Control_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Templates_Order_By>>;
  where?: Maybe<Quality_Control_Templates_Bool_Exp>;
};


/** query root */
export type Query_RootQuality_Control_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootReminder_RecipientsArgs = {
  distinct_on?: Maybe<Array<Reminder_Recipients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reminder_Recipients_Order_By>>;
  where?: Maybe<Reminder_Recipients_Bool_Exp>;
};


/** query root */
export type Query_RootReminder_Recipients_AggregateArgs = {
  distinct_on?: Maybe<Array<Reminder_Recipients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reminder_Recipients_Order_By>>;
  where?: Maybe<Reminder_Recipients_Bool_Exp>;
};


/** query root */
export type Query_RootReminder_Recipients_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootRemindersArgs = {
  distinct_on?: Maybe<Array<Reminders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reminders_Order_By>>;
  where?: Maybe<Reminders_Bool_Exp>;
};


/** query root */
export type Query_RootReminders_AggregateArgs = {
  distinct_on?: Maybe<Array<Reminders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reminders_Order_By>>;
  where?: Maybe<Reminders_Bool_Exp>;
};


/** query root */
export type Query_RootReminders_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootRequest_NotesArgs = {
  distinct_on?: Maybe<Array<Request_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Request_Notes_Order_By>>;
  where?: Maybe<Request_Notes_Bool_Exp>;
};


/** query root */
export type Query_RootRequest_Notes_AggregateArgs = {
  distinct_on?: Maybe<Array<Request_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Request_Notes_Order_By>>;
  where?: Maybe<Request_Notes_Bool_Exp>;
};


/** query root */
export type Query_RootRequest_Notes_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootRequestsArgs = {
  distinct_on?: Maybe<Array<Requests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Order_By>>;
  where?: Maybe<Requests_Bool_Exp>;
};


/** query root */
export type Query_RootRequests_AggregateArgs = {
  distinct_on?: Maybe<Array<Requests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Order_By>>;
  where?: Maybe<Requests_Bool_Exp>;
};


/** query root */
export type Query_RootRequests_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootRequests_Done_Per_Employee_Per_MonthArgs = {
  distinct_on?: Maybe<Array<Requests_Done_Per_Employee_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Done_Per_Employee_Per_Month_Order_By>>;
  where?: Maybe<Requests_Done_Per_Employee_Per_Month_Bool_Exp>;
};


/** query root */
export type Query_RootRequests_Done_Per_Employee_Per_Month_AggregateArgs = {
  distinct_on?: Maybe<Array<Requests_Done_Per_Employee_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Done_Per_Employee_Per_Month_Order_By>>;
  where?: Maybe<Requests_Done_Per_Employee_Per_Month_Bool_Exp>;
};


/** query root */
export type Query_RootRequests_Followup_Task_CountArgs = {
  distinct_on?: Maybe<Array<Requests_Followup_Task_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Followup_Task_Count_Order_By>>;
  where?: Maybe<Requests_Followup_Task_Count_Bool_Exp>;
};


/** query root */
export type Query_RootRequests_Followup_Task_Count_AggregateArgs = {
  distinct_on?: Maybe<Array<Requests_Followup_Task_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Followup_Task_Count_Order_By>>;
  where?: Maybe<Requests_Followup_Task_Count_Bool_Exp>;
};


/** query root */
export type Query_RootRequests_Followup_Task_Count_For_UserArgs = {
  args: Requests_Followup_Task_Count_For_User_Args;
  distinct_on?: Maybe<Array<Requests_Followup_Task_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Followup_Task_Count_Order_By>>;
  where?: Maybe<Requests_Followup_Task_Count_Bool_Exp>;
};


/** query root */
export type Query_RootRequests_Followup_Task_Count_For_User_AggregateArgs = {
  args: Requests_Followup_Task_Count_For_User_Args;
  distinct_on?: Maybe<Array<Requests_Followup_Task_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Followup_Task_Count_Order_By>>;
  where?: Maybe<Requests_Followup_Task_Count_Bool_Exp>;
};


/** query root */
export type Query_RootRequests_Followup_Task_CoutArgs = {
  distinct_on?: Maybe<Array<Requests_Followup_Task_Cout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Followup_Task_Cout_Order_By>>;
  where?: Maybe<Requests_Followup_Task_Cout_Bool_Exp>;
};


/** query root */
export type Query_RootRequests_Followup_Task_Cout_AggregateArgs = {
  distinct_on?: Maybe<Array<Requests_Followup_Task_Cout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Followup_Task_Cout_Order_By>>;
  where?: Maybe<Requests_Followup_Task_Cout_Bool_Exp>;
};


/** query root */
export type Query_RootRequests_New_Task_CountArgs = {
  distinct_on?: Maybe<Array<Requests_New_Task_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_New_Task_Count_Order_By>>;
  where?: Maybe<Requests_New_Task_Count_Bool_Exp>;
};


/** query root */
export type Query_RootRequests_New_Task_Count_AggregateArgs = {
  distinct_on?: Maybe<Array<Requests_New_Task_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_New_Task_Count_Order_By>>;
  where?: Maybe<Requests_New_Task_Count_Bool_Exp>;
};


/** query root */
export type Query_RootRequests_New_Task_CoutArgs = {
  distinct_on?: Maybe<Array<Requests_New_Task_Cout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_New_Task_Cout_Order_By>>;
  where?: Maybe<Requests_New_Task_Cout_Bool_Exp>;
};


/** query root */
export type Query_RootRequests_New_Task_Cout_AggregateArgs = {
  distinct_on?: Maybe<Array<Requests_New_Task_Cout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_New_Task_Cout_Order_By>>;
  where?: Maybe<Requests_New_Task_Cout_Bool_Exp>;
};


/** query root */
export type Query_RootRequests_Per_Case_Type_Per_MonthArgs = {
  distinct_on?: Maybe<Array<Requests_Per_Case_Type_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Per_Case_Type_Per_Month_Order_By>>;
  where?: Maybe<Requests_Per_Case_Type_Per_Month_Bool_Exp>;
};


/** query root */
export type Query_RootRequests_Per_Case_Type_Per_Month_AggregateArgs = {
  distinct_on?: Maybe<Array<Requests_Per_Case_Type_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Per_Case_Type_Per_Month_Order_By>>;
  where?: Maybe<Requests_Per_Case_Type_Per_Month_Bool_Exp>;
};


/** query root */
export type Query_RootStandard_PhotosArgs = {
  distinct_on?: Maybe<Array<Standard_Photos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Photos_Order_By>>;
  where?: Maybe<Standard_Photos_Bool_Exp>;
};


/** query root */
export type Query_RootStandard_Photos_AggregateArgs = {
  distinct_on?: Maybe<Array<Standard_Photos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Photos_Order_By>>;
  where?: Maybe<Standard_Photos_Bool_Exp>;
};


/** query root */
export type Query_RootStandard_Photos_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootStandard_TermsArgs = {
  distinct_on?: Maybe<Array<Standard_Terms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Terms_Order_By>>;
  where?: Maybe<Standard_Terms_Bool_Exp>;
};


/** query root */
export type Query_RootStandard_Terms_AggregateArgs = {
  distinct_on?: Maybe<Array<Standard_Terms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Terms_Order_By>>;
  where?: Maybe<Standard_Terms_Bool_Exp>;
};


/** query root */
export type Query_RootStandard_Terms_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "reminder_recipients" */
export type Reminder_Recipients = {
  __typename?: 'reminder_recipients';
  id: Scalars['uuid'];
  /** An object relationship */
  reminder: Reminders;
  reminder_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "reminder_recipients" */
export type Reminder_Recipients_Aggregate = {
  __typename?: 'reminder_recipients_aggregate';
  aggregate?: Maybe<Reminder_Recipients_Aggregate_Fields>;
  nodes: Array<Reminder_Recipients>;
};

/** aggregate fields of "reminder_recipients" */
export type Reminder_Recipients_Aggregate_Fields = {
  __typename?: 'reminder_recipients_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Reminder_Recipients_Max_Fields>;
  min?: Maybe<Reminder_Recipients_Min_Fields>;
};


/** aggregate fields of "reminder_recipients" */
export type Reminder_Recipients_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Reminder_Recipients_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reminder_recipients" */
export type Reminder_Recipients_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Reminder_Recipients_Max_Order_By>;
  min?: Maybe<Reminder_Recipients_Min_Order_By>;
};

/** input type for inserting array relation for remote table "reminder_recipients" */
export type Reminder_Recipients_Arr_Rel_Insert_Input = {
  data: Array<Reminder_Recipients_Insert_Input>;
  on_conflict?: Maybe<Reminder_Recipients_On_Conflict>;
};

/** Boolean expression to filter rows from the table "reminder_recipients". All fields are combined with a logical 'AND'. */
export type Reminder_Recipients_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Reminder_Recipients_Bool_Exp>>>;
  _not?: Maybe<Reminder_Recipients_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Reminder_Recipients_Bool_Exp>>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  reminder?: Maybe<Reminders_Bool_Exp>;
  reminder_id?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "reminder_recipients" */
export enum Reminder_Recipients_Constraint {
  /** unique or primary key constraint */
  ReminderRecipientsPkey = 'reminder_recipients_pkey'
}

/** input type for inserting data into table "reminder_recipients" */
export type Reminder_Recipients_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  reminder?: Maybe<Reminders_Obj_Rel_Insert_Input>;
  reminder_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Reminder_Recipients_Max_Fields = {
  __typename?: 'reminder_recipients_max_fields';
  id?: Maybe<Scalars['uuid']>;
  reminder_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "reminder_recipients" */
export type Reminder_Recipients_Max_Order_By = {
  id?: Maybe<Order_By>;
  reminder_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Reminder_Recipients_Min_Fields = {
  __typename?: 'reminder_recipients_min_fields';
  id?: Maybe<Scalars['uuid']>;
  reminder_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "reminder_recipients" */
export type Reminder_Recipients_Min_Order_By = {
  id?: Maybe<Order_By>;
  reminder_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "reminder_recipients" */
export type Reminder_Recipients_Mutation_Response = {
  __typename?: 'reminder_recipients_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Reminder_Recipients>;
};

/** input type for inserting object relation for remote table "reminder_recipients" */
export type Reminder_Recipients_Obj_Rel_Insert_Input = {
  data: Reminder_Recipients_Insert_Input;
  on_conflict?: Maybe<Reminder_Recipients_On_Conflict>;
};

/** on conflict condition type for table "reminder_recipients" */
export type Reminder_Recipients_On_Conflict = {
  constraint: Reminder_Recipients_Constraint;
  update_columns: Array<Reminder_Recipients_Update_Column>;
  where?: Maybe<Reminder_Recipients_Bool_Exp>;
};

/** ordering options when selecting data from "reminder_recipients" */
export type Reminder_Recipients_Order_By = {
  id?: Maybe<Order_By>;
  reminder?: Maybe<Reminders_Order_By>;
  reminder_id?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "reminder_recipients" */
export type Reminder_Recipients_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "reminder_recipients" */
export enum Reminder_Recipients_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ReminderId = 'reminder_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "reminder_recipients" */
export type Reminder_Recipients_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  reminder_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "reminder_recipients" */
export enum Reminder_Recipients_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ReminderId = 'reminder_id',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "reminders" */
export type Reminders = {
  __typename?: 'reminders';
  /** An object relationship */
  case?: Maybe<Cases>;
  case_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  case_note?: Maybe<Case_Doc_Notes>;
  contacted: Scalars['Boolean'];
  /** An object relationship */
  creator: Users;
  creator_id: Scalars['uuid'];
  date: Scalars['String'];
  document_note_id?: Maybe<Scalars['uuid']>;
  document_order_confirmation_id?: Maybe<Scalars['uuid']>;
  document_quality_control_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  message?: Maybe<Scalars['String']>;
  /** An object relationship */
  next_reminder?: Maybe<Reminders>;
  next_reminder_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  order_confirmation?: Maybe<Case_Doc_Order_Confirmations>;
  /** An object relationship */
  quality_control?: Maybe<Case_Doc_Quality_Controls>;
  /** An array relationship */
  recipients: Array<Reminder_Recipients>;
  /** An aggregated array relationship */
  recipients_aggregate: Reminder_Recipients_Aggregate;
  /** An object relationship */
  request?: Maybe<Requests>;
  request_id?: Maybe<Scalars['uuid']>;
  triggered: Scalars['Boolean'];
};


/** columns and relationships of "reminders" */
export type RemindersRecipientsArgs = {
  distinct_on?: Maybe<Array<Reminder_Recipients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reminder_Recipients_Order_By>>;
  where?: Maybe<Reminder_Recipients_Bool_Exp>;
};


/** columns and relationships of "reminders" */
export type RemindersRecipients_AggregateArgs = {
  distinct_on?: Maybe<Array<Reminder_Recipients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reminder_Recipients_Order_By>>;
  where?: Maybe<Reminder_Recipients_Bool_Exp>;
};

/** aggregated selection of "reminders" */
export type Reminders_Aggregate = {
  __typename?: 'reminders_aggregate';
  aggregate?: Maybe<Reminders_Aggregate_Fields>;
  nodes: Array<Reminders>;
};

/** aggregate fields of "reminders" */
export type Reminders_Aggregate_Fields = {
  __typename?: 'reminders_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Reminders_Max_Fields>;
  min?: Maybe<Reminders_Min_Fields>;
};


/** aggregate fields of "reminders" */
export type Reminders_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Reminders_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reminders" */
export type Reminders_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Reminders_Max_Order_By>;
  min?: Maybe<Reminders_Min_Order_By>;
};

/** input type for inserting array relation for remote table "reminders" */
export type Reminders_Arr_Rel_Insert_Input = {
  data: Array<Reminders_Insert_Input>;
  on_conflict?: Maybe<Reminders_On_Conflict>;
};

/** Boolean expression to filter rows from the table "reminders". All fields are combined with a logical 'AND'. */
export type Reminders_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Reminders_Bool_Exp>>>;
  _not?: Maybe<Reminders_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Reminders_Bool_Exp>>>;
  case?: Maybe<Cases_Bool_Exp>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  case_note?: Maybe<Case_Doc_Notes_Bool_Exp>;
  contacted?: Maybe<Boolean_Comparison_Exp>;
  creator?: Maybe<Users_Bool_Exp>;
  creator_id?: Maybe<Uuid_Comparison_Exp>;
  date?: Maybe<String_Comparison_Exp>;
  document_note_id?: Maybe<Uuid_Comparison_Exp>;
  document_order_confirmation_id?: Maybe<Uuid_Comparison_Exp>;
  document_quality_control_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  next_reminder?: Maybe<Reminders_Bool_Exp>;
  next_reminder_id?: Maybe<Uuid_Comparison_Exp>;
  order_confirmation?: Maybe<Case_Doc_Order_Confirmations_Bool_Exp>;
  quality_control?: Maybe<Case_Doc_Quality_Controls_Bool_Exp>;
  recipients?: Maybe<Reminder_Recipients_Bool_Exp>;
  request?: Maybe<Requests_Bool_Exp>;
  request_id?: Maybe<Uuid_Comparison_Exp>;
  triggered?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "reminders" */
export enum Reminders_Constraint {
  /** unique or primary key constraint */
  RemindersPkey = 'reminders_pkey'
}

/** input type for inserting data into table "reminders" */
export type Reminders_Insert_Input = {
  case?: Maybe<Cases_Obj_Rel_Insert_Input>;
  case_id?: Maybe<Scalars['uuid']>;
  case_note?: Maybe<Case_Doc_Notes_Obj_Rel_Insert_Input>;
  contacted?: Maybe<Scalars['Boolean']>;
  creator?: Maybe<Users_Obj_Rel_Insert_Input>;
  creator_id?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['String']>;
  document_note_id?: Maybe<Scalars['uuid']>;
  document_order_confirmation_id?: Maybe<Scalars['uuid']>;
  document_quality_control_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  next_reminder?: Maybe<Reminders_Obj_Rel_Insert_Input>;
  next_reminder_id?: Maybe<Scalars['uuid']>;
  order_confirmation?: Maybe<Case_Doc_Order_Confirmations_Obj_Rel_Insert_Input>;
  quality_control?: Maybe<Case_Doc_Quality_Controls_Obj_Rel_Insert_Input>;
  recipients?: Maybe<Reminder_Recipients_Arr_Rel_Insert_Input>;
  request?: Maybe<Requests_Obj_Rel_Insert_Input>;
  request_id?: Maybe<Scalars['uuid']>;
  triggered?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Reminders_Max_Fields = {
  __typename?: 'reminders_max_fields';
  case_id?: Maybe<Scalars['uuid']>;
  creator_id?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['String']>;
  document_note_id?: Maybe<Scalars['uuid']>;
  document_order_confirmation_id?: Maybe<Scalars['uuid']>;
  document_quality_control_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  next_reminder_id?: Maybe<Scalars['uuid']>;
  request_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "reminders" */
export type Reminders_Max_Order_By = {
  case_id?: Maybe<Order_By>;
  creator_id?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  document_note_id?: Maybe<Order_By>;
  document_order_confirmation_id?: Maybe<Order_By>;
  document_quality_control_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  next_reminder_id?: Maybe<Order_By>;
  request_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Reminders_Min_Fields = {
  __typename?: 'reminders_min_fields';
  case_id?: Maybe<Scalars['uuid']>;
  creator_id?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['String']>;
  document_note_id?: Maybe<Scalars['uuid']>;
  document_order_confirmation_id?: Maybe<Scalars['uuid']>;
  document_quality_control_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  next_reminder_id?: Maybe<Scalars['uuid']>;
  request_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "reminders" */
export type Reminders_Min_Order_By = {
  case_id?: Maybe<Order_By>;
  creator_id?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  document_note_id?: Maybe<Order_By>;
  document_order_confirmation_id?: Maybe<Order_By>;
  document_quality_control_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  next_reminder_id?: Maybe<Order_By>;
  request_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "reminders" */
export type Reminders_Mutation_Response = {
  __typename?: 'reminders_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Reminders>;
};

/** input type for inserting object relation for remote table "reminders" */
export type Reminders_Obj_Rel_Insert_Input = {
  data: Reminders_Insert_Input;
  on_conflict?: Maybe<Reminders_On_Conflict>;
};

/** on conflict condition type for table "reminders" */
export type Reminders_On_Conflict = {
  constraint: Reminders_Constraint;
  update_columns: Array<Reminders_Update_Column>;
  where?: Maybe<Reminders_Bool_Exp>;
};

/** ordering options when selecting data from "reminders" */
export type Reminders_Order_By = {
  case?: Maybe<Cases_Order_By>;
  case_id?: Maybe<Order_By>;
  case_note?: Maybe<Case_Doc_Notes_Order_By>;
  contacted?: Maybe<Order_By>;
  creator?: Maybe<Users_Order_By>;
  creator_id?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  document_note_id?: Maybe<Order_By>;
  document_order_confirmation_id?: Maybe<Order_By>;
  document_quality_control_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  next_reminder?: Maybe<Reminders_Order_By>;
  next_reminder_id?: Maybe<Order_By>;
  order_confirmation?: Maybe<Case_Doc_Order_Confirmations_Order_By>;
  quality_control?: Maybe<Case_Doc_Quality_Controls_Order_By>;
  recipients_aggregate?: Maybe<Reminder_Recipients_Aggregate_Order_By>;
  request?: Maybe<Requests_Order_By>;
  request_id?: Maybe<Order_By>;
  triggered?: Maybe<Order_By>;
};

/** primary key columns input for table: "reminders" */
export type Reminders_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "reminders" */
export enum Reminders_Select_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  Contacted = 'contacted',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Date = 'date',
  /** column name */
  DocumentNoteId = 'document_note_id',
  /** column name */
  DocumentOrderConfirmationId = 'document_order_confirmation_id',
  /** column name */
  DocumentQualityControlId = 'document_quality_control_id',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  NextReminderId = 'next_reminder_id',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  Triggered = 'triggered'
}

/** input type for updating data in table "reminders" */
export type Reminders_Set_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  contacted?: Maybe<Scalars['Boolean']>;
  creator_id?: Maybe<Scalars['uuid']>;
  date?: Maybe<Scalars['String']>;
  document_note_id?: Maybe<Scalars['uuid']>;
  document_order_confirmation_id?: Maybe<Scalars['uuid']>;
  document_quality_control_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  next_reminder_id?: Maybe<Scalars['uuid']>;
  request_id?: Maybe<Scalars['uuid']>;
  triggered?: Maybe<Scalars['Boolean']>;
};

/** update columns of table "reminders" */
export enum Reminders_Update_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  Contacted = 'contacted',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Date = 'date',
  /** column name */
  DocumentNoteId = 'document_note_id',
  /** column name */
  DocumentOrderConfirmationId = 'document_order_confirmation_id',
  /** column name */
  DocumentQualityControlId = 'document_quality_control_id',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  NextReminderId = 'next_reminder_id',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  Triggered = 'triggered'
}

/** columns and relationships of "request_notes" */
export type Request_Notes = {
  __typename?: 'request_notes';
  added_date: Scalars['String'];
  id: Scalars['uuid'];
  notes?: Maybe<Scalars['String']>;
  request_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "request_notes" */
export type Request_Notes_Aggregate = {
  __typename?: 'request_notes_aggregate';
  aggregate?: Maybe<Request_Notes_Aggregate_Fields>;
  nodes: Array<Request_Notes>;
};

/** aggregate fields of "request_notes" */
export type Request_Notes_Aggregate_Fields = {
  __typename?: 'request_notes_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Request_Notes_Max_Fields>;
  min?: Maybe<Request_Notes_Min_Fields>;
};


/** aggregate fields of "request_notes" */
export type Request_Notes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Request_Notes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "request_notes" */
export type Request_Notes_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Request_Notes_Max_Order_By>;
  min?: Maybe<Request_Notes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "request_notes" */
export type Request_Notes_Arr_Rel_Insert_Input = {
  data: Array<Request_Notes_Insert_Input>;
  on_conflict?: Maybe<Request_Notes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "request_notes". All fields are combined with a logical 'AND'. */
export type Request_Notes_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Request_Notes_Bool_Exp>>>;
  _not?: Maybe<Request_Notes_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Request_Notes_Bool_Exp>>>;
  added_date?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  request_id?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "request_notes" */
export enum Request_Notes_Constraint {
  /** unique or primary key constraint */
  RequestNotesPkey = 'request_notes_pkey'
}

/** input type for inserting data into table "request_notes" */
export type Request_Notes_Insert_Input = {
  added_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Request_Notes_Max_Fields = {
  __typename?: 'request_notes_max_fields';
  added_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "request_notes" */
export type Request_Notes_Max_Order_By = {
  added_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  request_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Request_Notes_Min_Fields = {
  __typename?: 'request_notes_min_fields';
  added_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "request_notes" */
export type Request_Notes_Min_Order_By = {
  added_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  request_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "request_notes" */
export type Request_Notes_Mutation_Response = {
  __typename?: 'request_notes_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Request_Notes>;
};

/** input type for inserting object relation for remote table "request_notes" */
export type Request_Notes_Obj_Rel_Insert_Input = {
  data: Request_Notes_Insert_Input;
  on_conflict?: Maybe<Request_Notes_On_Conflict>;
};

/** on conflict condition type for table "request_notes" */
export type Request_Notes_On_Conflict = {
  constraint: Request_Notes_Constraint;
  update_columns: Array<Request_Notes_Update_Column>;
  where?: Maybe<Request_Notes_Bool_Exp>;
};

/** ordering options when selecting data from "request_notes" */
export type Request_Notes_Order_By = {
  added_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  request_id?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "request_notes" */
export type Request_Notes_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "request_notes" */
export enum Request_Notes_Select_Column {
  /** column name */
  AddedDate = 'added_date',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "request_notes" */
export type Request_Notes_Set_Input = {
  added_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  notes?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "request_notes" */
export enum Request_Notes_Update_Column {
  /** column name */
  AddedDate = 'added_date',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "requests" */
export type Requests = {
  __typename?: 'requests';
  address: Scalars['String'];
  builder: Scalars['String'];
  case_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  case_type?: Maybe<Case_Types>;
  case_type_id?: Maybe<Scalars['uuid']>;
  city: Scalars['String'];
  date: Scalars['timestamptz'];
  description: Scalars['String'];
  /** An object relationship */
  external?: Maybe<Contacts>;
  external_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  inspection_date?: Maybe<Scalars['String']>;
  inspection_time?: Maybe<Scalars['String']>;
  mail: Scalars['String'];
  mobile: Scalars['String'];
  /** An array relationship */
  reminders: Array<Reminders>;
  /** An aggregated array relationship */
  reminders_aggregate: Reminders_Aggregate;
  /** An array relationship */
  request_notes: Array<Request_Notes>;
  /** An aggregated array relationship */
  request_notes_aggregate: Request_Notes_Aggregate;
  /** An object relationship */
  responsible?: Maybe<Users>;
  responsible_id?: Maybe<Scalars['uuid']>;
  state: Scalars['String'];
  task: Scalars['String'];
  zip_code: Scalars['String'];
};


/** columns and relationships of "requests" */
export type RequestsRemindersArgs = {
  distinct_on?: Maybe<Array<Reminders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reminders_Order_By>>;
  where?: Maybe<Reminders_Bool_Exp>;
};


/** columns and relationships of "requests" */
export type RequestsReminders_AggregateArgs = {
  distinct_on?: Maybe<Array<Reminders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reminders_Order_By>>;
  where?: Maybe<Reminders_Bool_Exp>;
};


/** columns and relationships of "requests" */
export type RequestsRequest_NotesArgs = {
  distinct_on?: Maybe<Array<Request_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Request_Notes_Order_By>>;
  where?: Maybe<Request_Notes_Bool_Exp>;
};


/** columns and relationships of "requests" */
export type RequestsRequest_Notes_AggregateArgs = {
  distinct_on?: Maybe<Array<Request_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Request_Notes_Order_By>>;
  where?: Maybe<Request_Notes_Bool_Exp>;
};

/** aggregated selection of "requests" */
export type Requests_Aggregate = {
  __typename?: 'requests_aggregate';
  aggregate?: Maybe<Requests_Aggregate_Fields>;
  nodes: Array<Requests>;
};

/** aggregate fields of "requests" */
export type Requests_Aggregate_Fields = {
  __typename?: 'requests_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Requests_Max_Fields>;
  min?: Maybe<Requests_Min_Fields>;
};


/** aggregate fields of "requests" */
export type Requests_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Requests_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "requests" */
export type Requests_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Requests_Max_Order_By>;
  min?: Maybe<Requests_Min_Order_By>;
};

/** input type for inserting array relation for remote table "requests" */
export type Requests_Arr_Rel_Insert_Input = {
  data: Array<Requests_Insert_Input>;
  on_conflict?: Maybe<Requests_On_Conflict>;
};

/** Boolean expression to filter rows from the table "requests". All fields are combined with a logical 'AND'. */
export type Requests_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Requests_Bool_Exp>>>;
  _not?: Maybe<Requests_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Requests_Bool_Exp>>>;
  address?: Maybe<String_Comparison_Exp>;
  builder?: Maybe<String_Comparison_Exp>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  case_type?: Maybe<Case_Types_Bool_Exp>;
  case_type_id?: Maybe<Uuid_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  date?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  external?: Maybe<Contacts_Bool_Exp>;
  external_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  inspection_date?: Maybe<String_Comparison_Exp>;
  inspection_time?: Maybe<String_Comparison_Exp>;
  mail?: Maybe<String_Comparison_Exp>;
  mobile?: Maybe<String_Comparison_Exp>;
  reminders?: Maybe<Reminders_Bool_Exp>;
  request_notes?: Maybe<Request_Notes_Bool_Exp>;
  responsible?: Maybe<Users_Bool_Exp>;
  responsible_id?: Maybe<Uuid_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  task?: Maybe<String_Comparison_Exp>;
  zip_code?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "requests" */
export enum Requests_Constraint {
  /** unique or primary key constraint */
  RequestsIdKey = 'requests_id_key',
  /** unique or primary key constraint */
  RequestsPkey = 'requests_pkey'
}

/** columns and relationships of "requests_done_per_employee_per_month" */
export type Requests_Done_Per_Employee_Per_Month = {
  __typename?: 'requests_done_per_employee_per_month';
  count?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  employee?: Maybe<Users>;
  responsible_id?: Maybe<Scalars['uuid']>;
  year_month?: Maybe<Scalars['String']>;
};

/** aggregated selection of "requests_done_per_employee_per_month" */
export type Requests_Done_Per_Employee_Per_Month_Aggregate = {
  __typename?: 'requests_done_per_employee_per_month_aggregate';
  aggregate?: Maybe<Requests_Done_Per_Employee_Per_Month_Aggregate_Fields>;
  nodes: Array<Requests_Done_Per_Employee_Per_Month>;
};

/** aggregate fields of "requests_done_per_employee_per_month" */
export type Requests_Done_Per_Employee_Per_Month_Aggregate_Fields = {
  __typename?: 'requests_done_per_employee_per_month_aggregate_fields';
  avg?: Maybe<Requests_Done_Per_Employee_Per_Month_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Requests_Done_Per_Employee_Per_Month_Max_Fields>;
  min?: Maybe<Requests_Done_Per_Employee_Per_Month_Min_Fields>;
  stddev?: Maybe<Requests_Done_Per_Employee_Per_Month_Stddev_Fields>;
  stddev_pop?: Maybe<Requests_Done_Per_Employee_Per_Month_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Requests_Done_Per_Employee_Per_Month_Stddev_Samp_Fields>;
  sum?: Maybe<Requests_Done_Per_Employee_Per_Month_Sum_Fields>;
  var_pop?: Maybe<Requests_Done_Per_Employee_Per_Month_Var_Pop_Fields>;
  var_samp?: Maybe<Requests_Done_Per_Employee_Per_Month_Var_Samp_Fields>;
  variance?: Maybe<Requests_Done_Per_Employee_Per_Month_Variance_Fields>;
};


/** aggregate fields of "requests_done_per_employee_per_month" */
export type Requests_Done_Per_Employee_Per_Month_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Requests_Done_Per_Employee_Per_Month_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "requests_done_per_employee_per_month" */
export type Requests_Done_Per_Employee_Per_Month_Aggregate_Order_By = {
  avg?: Maybe<Requests_Done_Per_Employee_Per_Month_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Requests_Done_Per_Employee_Per_Month_Max_Order_By>;
  min?: Maybe<Requests_Done_Per_Employee_Per_Month_Min_Order_By>;
  stddev?: Maybe<Requests_Done_Per_Employee_Per_Month_Stddev_Order_By>;
  stddev_pop?: Maybe<Requests_Done_Per_Employee_Per_Month_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Requests_Done_Per_Employee_Per_Month_Stddev_Samp_Order_By>;
  sum?: Maybe<Requests_Done_Per_Employee_Per_Month_Sum_Order_By>;
  var_pop?: Maybe<Requests_Done_Per_Employee_Per_Month_Var_Pop_Order_By>;
  var_samp?: Maybe<Requests_Done_Per_Employee_Per_Month_Var_Samp_Order_By>;
  variance?: Maybe<Requests_Done_Per_Employee_Per_Month_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Requests_Done_Per_Employee_Per_Month_Avg_Fields = {
  __typename?: 'requests_done_per_employee_per_month_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "requests_done_per_employee_per_month" */
export type Requests_Done_Per_Employee_Per_Month_Avg_Order_By = {
  count?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "requests_done_per_employee_per_month". All fields are combined with a logical 'AND'. */
export type Requests_Done_Per_Employee_Per_Month_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Requests_Done_Per_Employee_Per_Month_Bool_Exp>>>;
  _not?: Maybe<Requests_Done_Per_Employee_Per_Month_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Requests_Done_Per_Employee_Per_Month_Bool_Exp>>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  employee?: Maybe<Users_Bool_Exp>;
  responsible_id?: Maybe<Uuid_Comparison_Exp>;
  year_month?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Requests_Done_Per_Employee_Per_Month_Max_Fields = {
  __typename?: 'requests_done_per_employee_per_month_max_fields';
  count?: Maybe<Scalars['bigint']>;
  responsible_id?: Maybe<Scalars['uuid']>;
  year_month?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "requests_done_per_employee_per_month" */
export type Requests_Done_Per_Employee_Per_Month_Max_Order_By = {
  count?: Maybe<Order_By>;
  responsible_id?: Maybe<Order_By>;
  year_month?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Requests_Done_Per_Employee_Per_Month_Min_Fields = {
  __typename?: 'requests_done_per_employee_per_month_min_fields';
  count?: Maybe<Scalars['bigint']>;
  responsible_id?: Maybe<Scalars['uuid']>;
  year_month?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "requests_done_per_employee_per_month" */
export type Requests_Done_Per_Employee_Per_Month_Min_Order_By = {
  count?: Maybe<Order_By>;
  responsible_id?: Maybe<Order_By>;
  year_month?: Maybe<Order_By>;
};

/** ordering options when selecting data from "requests_done_per_employee_per_month" */
export type Requests_Done_Per_Employee_Per_Month_Order_By = {
  count?: Maybe<Order_By>;
  employee?: Maybe<Users_Order_By>;
  responsible_id?: Maybe<Order_By>;
  year_month?: Maybe<Order_By>;
};

/** select columns of table "requests_done_per_employee_per_month" */
export enum Requests_Done_Per_Employee_Per_Month_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  ResponsibleId = 'responsible_id',
  /** column name */
  YearMonth = 'year_month'
}

/** aggregate stddev on columns */
export type Requests_Done_Per_Employee_Per_Month_Stddev_Fields = {
  __typename?: 'requests_done_per_employee_per_month_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "requests_done_per_employee_per_month" */
export type Requests_Done_Per_Employee_Per_Month_Stddev_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Requests_Done_Per_Employee_Per_Month_Stddev_Pop_Fields = {
  __typename?: 'requests_done_per_employee_per_month_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "requests_done_per_employee_per_month" */
export type Requests_Done_Per_Employee_Per_Month_Stddev_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Requests_Done_Per_Employee_Per_Month_Stddev_Samp_Fields = {
  __typename?: 'requests_done_per_employee_per_month_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "requests_done_per_employee_per_month" */
export type Requests_Done_Per_Employee_Per_Month_Stddev_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Requests_Done_Per_Employee_Per_Month_Sum_Fields = {
  __typename?: 'requests_done_per_employee_per_month_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "requests_done_per_employee_per_month" */
export type Requests_Done_Per_Employee_Per_Month_Sum_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Requests_Done_Per_Employee_Per_Month_Var_Pop_Fields = {
  __typename?: 'requests_done_per_employee_per_month_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "requests_done_per_employee_per_month" */
export type Requests_Done_Per_Employee_Per_Month_Var_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Requests_Done_Per_Employee_Per_Month_Var_Samp_Fields = {
  __typename?: 'requests_done_per_employee_per_month_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "requests_done_per_employee_per_month" */
export type Requests_Done_Per_Employee_Per_Month_Var_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Requests_Done_Per_Employee_Per_Month_Variance_Fields = {
  __typename?: 'requests_done_per_employee_per_month_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "requests_done_per_employee_per_month" */
export type Requests_Done_Per_Employee_Per_Month_Variance_Order_By = {
  count?: Maybe<Order_By>;
};

/** columns and relationships of "requests_followup_task_count" */
export type Requests_Followup_Task_Count = {
  __typename?: 'requests_followup_task_count';
  /** An object relationship */
  case_type?: Maybe<Case_Types>;
  case_type_id?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
  from_date?: Maybe<Scalars['timestamptz']>;
  to_date?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "requests_followup_task_count" */
export type Requests_Followup_Task_Count_Aggregate = {
  __typename?: 'requests_followup_task_count_aggregate';
  aggregate?: Maybe<Requests_Followup_Task_Count_Aggregate_Fields>;
  nodes: Array<Requests_Followup_Task_Count>;
};

/** aggregate fields of "requests_followup_task_count" */
export type Requests_Followup_Task_Count_Aggregate_Fields = {
  __typename?: 'requests_followup_task_count_aggregate_fields';
  avg?: Maybe<Requests_Followup_Task_Count_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Requests_Followup_Task_Count_Max_Fields>;
  min?: Maybe<Requests_Followup_Task_Count_Min_Fields>;
  stddev?: Maybe<Requests_Followup_Task_Count_Stddev_Fields>;
  stddev_pop?: Maybe<Requests_Followup_Task_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Requests_Followup_Task_Count_Stddev_Samp_Fields>;
  sum?: Maybe<Requests_Followup_Task_Count_Sum_Fields>;
  var_pop?: Maybe<Requests_Followup_Task_Count_Var_Pop_Fields>;
  var_samp?: Maybe<Requests_Followup_Task_Count_Var_Samp_Fields>;
  variance?: Maybe<Requests_Followup_Task_Count_Variance_Fields>;
};


/** aggregate fields of "requests_followup_task_count" */
export type Requests_Followup_Task_Count_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Requests_Followup_Task_Count_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "requests_followup_task_count" */
export type Requests_Followup_Task_Count_Aggregate_Order_By = {
  avg?: Maybe<Requests_Followup_Task_Count_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Requests_Followup_Task_Count_Max_Order_By>;
  min?: Maybe<Requests_Followup_Task_Count_Min_Order_By>;
  stddev?: Maybe<Requests_Followup_Task_Count_Stddev_Order_By>;
  stddev_pop?: Maybe<Requests_Followup_Task_Count_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Requests_Followup_Task_Count_Stddev_Samp_Order_By>;
  sum?: Maybe<Requests_Followup_Task_Count_Sum_Order_By>;
  var_pop?: Maybe<Requests_Followup_Task_Count_Var_Pop_Order_By>;
  var_samp?: Maybe<Requests_Followup_Task_Count_Var_Samp_Order_By>;
  variance?: Maybe<Requests_Followup_Task_Count_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Requests_Followup_Task_Count_Avg_Fields = {
  __typename?: 'requests_followup_task_count_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "requests_followup_task_count" */
export type Requests_Followup_Task_Count_Avg_Order_By = {
  count?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "requests_followup_task_count". All fields are combined with a logical 'AND'. */
export type Requests_Followup_Task_Count_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Requests_Followup_Task_Count_Bool_Exp>>>;
  _not?: Maybe<Requests_Followup_Task_Count_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Requests_Followup_Task_Count_Bool_Exp>>>;
  case_type?: Maybe<Case_Types_Bool_Exp>;
  case_type_id?: Maybe<Uuid_Comparison_Exp>;
  count?: Maybe<Bigint_Comparison_Exp>;
  from_date?: Maybe<Timestamptz_Comparison_Exp>;
  to_date?: Maybe<Timestamptz_Comparison_Exp>;
};

export type Requests_Followup_Task_Count_For_User_Args = {
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Requests_Followup_Task_Count_Max_Fields = {
  __typename?: 'requests_followup_task_count_max_fields';
  case_type_id?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
  from_date?: Maybe<Scalars['timestamptz']>;
  to_date?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "requests_followup_task_count" */
export type Requests_Followup_Task_Count_Max_Order_By = {
  case_type_id?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
  from_date?: Maybe<Order_By>;
  to_date?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Requests_Followup_Task_Count_Min_Fields = {
  __typename?: 'requests_followup_task_count_min_fields';
  case_type_id?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
  from_date?: Maybe<Scalars['timestamptz']>;
  to_date?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "requests_followup_task_count" */
export type Requests_Followup_Task_Count_Min_Order_By = {
  case_type_id?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
  from_date?: Maybe<Order_By>;
  to_date?: Maybe<Order_By>;
};

/** ordering options when selecting data from "requests_followup_task_count" */
export type Requests_Followup_Task_Count_Order_By = {
  case_type?: Maybe<Case_Types_Order_By>;
  case_type_id?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
  from_date?: Maybe<Order_By>;
  to_date?: Maybe<Order_By>;
};

/** select columns of table "requests_followup_task_count" */
export enum Requests_Followup_Task_Count_Select_Column {
  /** column name */
  CaseTypeId = 'case_type_id',
  /** column name */
  Count = 'count',
  /** column name */
  FromDate = 'from_date',
  /** column name */
  ToDate = 'to_date'
}

/** aggregate stddev on columns */
export type Requests_Followup_Task_Count_Stddev_Fields = {
  __typename?: 'requests_followup_task_count_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "requests_followup_task_count" */
export type Requests_Followup_Task_Count_Stddev_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Requests_Followup_Task_Count_Stddev_Pop_Fields = {
  __typename?: 'requests_followup_task_count_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "requests_followup_task_count" */
export type Requests_Followup_Task_Count_Stddev_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Requests_Followup_Task_Count_Stddev_Samp_Fields = {
  __typename?: 'requests_followup_task_count_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "requests_followup_task_count" */
export type Requests_Followup_Task_Count_Stddev_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Requests_Followup_Task_Count_Sum_Fields = {
  __typename?: 'requests_followup_task_count_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "requests_followup_task_count" */
export type Requests_Followup_Task_Count_Sum_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Requests_Followup_Task_Count_Var_Pop_Fields = {
  __typename?: 'requests_followup_task_count_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "requests_followup_task_count" */
export type Requests_Followup_Task_Count_Var_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Requests_Followup_Task_Count_Var_Samp_Fields = {
  __typename?: 'requests_followup_task_count_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "requests_followup_task_count" */
export type Requests_Followup_Task_Count_Var_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Requests_Followup_Task_Count_Variance_Fields = {
  __typename?: 'requests_followup_task_count_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "requests_followup_task_count" */
export type Requests_Followup_Task_Count_Variance_Order_By = {
  count?: Maybe<Order_By>;
};

/** columns and relationships of "requests_followup_task_cout" */
export type Requests_Followup_Task_Cout = {
  __typename?: 'requests_followup_task_cout';
  count?: Maybe<Scalars['bigint']>;
  from_date?: Maybe<Scalars['timestamptz']>;
  task?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "requests_followup_task_cout" */
export type Requests_Followup_Task_Cout_Aggregate = {
  __typename?: 'requests_followup_task_cout_aggregate';
  aggregate?: Maybe<Requests_Followup_Task_Cout_Aggregate_Fields>;
  nodes: Array<Requests_Followup_Task_Cout>;
};

/** aggregate fields of "requests_followup_task_cout" */
export type Requests_Followup_Task_Cout_Aggregate_Fields = {
  __typename?: 'requests_followup_task_cout_aggregate_fields';
  avg?: Maybe<Requests_Followup_Task_Cout_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Requests_Followup_Task_Cout_Max_Fields>;
  min?: Maybe<Requests_Followup_Task_Cout_Min_Fields>;
  stddev?: Maybe<Requests_Followup_Task_Cout_Stddev_Fields>;
  stddev_pop?: Maybe<Requests_Followup_Task_Cout_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Requests_Followup_Task_Cout_Stddev_Samp_Fields>;
  sum?: Maybe<Requests_Followup_Task_Cout_Sum_Fields>;
  var_pop?: Maybe<Requests_Followup_Task_Cout_Var_Pop_Fields>;
  var_samp?: Maybe<Requests_Followup_Task_Cout_Var_Samp_Fields>;
  variance?: Maybe<Requests_Followup_Task_Cout_Variance_Fields>;
};


/** aggregate fields of "requests_followup_task_cout" */
export type Requests_Followup_Task_Cout_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Requests_Followup_Task_Cout_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "requests_followup_task_cout" */
export type Requests_Followup_Task_Cout_Aggregate_Order_By = {
  avg?: Maybe<Requests_Followup_Task_Cout_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Requests_Followup_Task_Cout_Max_Order_By>;
  min?: Maybe<Requests_Followup_Task_Cout_Min_Order_By>;
  stddev?: Maybe<Requests_Followup_Task_Cout_Stddev_Order_By>;
  stddev_pop?: Maybe<Requests_Followup_Task_Cout_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Requests_Followup_Task_Cout_Stddev_Samp_Order_By>;
  sum?: Maybe<Requests_Followup_Task_Cout_Sum_Order_By>;
  var_pop?: Maybe<Requests_Followup_Task_Cout_Var_Pop_Order_By>;
  var_samp?: Maybe<Requests_Followup_Task_Cout_Var_Samp_Order_By>;
  variance?: Maybe<Requests_Followup_Task_Cout_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Requests_Followup_Task_Cout_Avg_Fields = {
  __typename?: 'requests_followup_task_cout_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "requests_followup_task_cout" */
export type Requests_Followup_Task_Cout_Avg_Order_By = {
  count?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "requests_followup_task_cout". All fields are combined with a logical 'AND'. */
export type Requests_Followup_Task_Cout_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Requests_Followup_Task_Cout_Bool_Exp>>>;
  _not?: Maybe<Requests_Followup_Task_Cout_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Requests_Followup_Task_Cout_Bool_Exp>>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  from_date?: Maybe<Timestamptz_Comparison_Exp>;
  task?: Maybe<String_Comparison_Exp>;
  to_date?: Maybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Requests_Followup_Task_Cout_Max_Fields = {
  __typename?: 'requests_followup_task_cout_max_fields';
  count?: Maybe<Scalars['bigint']>;
  from_date?: Maybe<Scalars['timestamptz']>;
  task?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "requests_followup_task_cout" */
export type Requests_Followup_Task_Cout_Max_Order_By = {
  count?: Maybe<Order_By>;
  from_date?: Maybe<Order_By>;
  task?: Maybe<Order_By>;
  to_date?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Requests_Followup_Task_Cout_Min_Fields = {
  __typename?: 'requests_followup_task_cout_min_fields';
  count?: Maybe<Scalars['bigint']>;
  from_date?: Maybe<Scalars['timestamptz']>;
  task?: Maybe<Scalars['String']>;
  to_date?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "requests_followup_task_cout" */
export type Requests_Followup_Task_Cout_Min_Order_By = {
  count?: Maybe<Order_By>;
  from_date?: Maybe<Order_By>;
  task?: Maybe<Order_By>;
  to_date?: Maybe<Order_By>;
};

/** ordering options when selecting data from "requests_followup_task_cout" */
export type Requests_Followup_Task_Cout_Order_By = {
  count?: Maybe<Order_By>;
  from_date?: Maybe<Order_By>;
  task?: Maybe<Order_By>;
  to_date?: Maybe<Order_By>;
};

/** select columns of table "requests_followup_task_cout" */
export enum Requests_Followup_Task_Cout_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  FromDate = 'from_date',
  /** column name */
  Task = 'task',
  /** column name */
  ToDate = 'to_date'
}

/** aggregate stddev on columns */
export type Requests_Followup_Task_Cout_Stddev_Fields = {
  __typename?: 'requests_followup_task_cout_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "requests_followup_task_cout" */
export type Requests_Followup_Task_Cout_Stddev_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Requests_Followup_Task_Cout_Stddev_Pop_Fields = {
  __typename?: 'requests_followup_task_cout_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "requests_followup_task_cout" */
export type Requests_Followup_Task_Cout_Stddev_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Requests_Followup_Task_Cout_Stddev_Samp_Fields = {
  __typename?: 'requests_followup_task_cout_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "requests_followup_task_cout" */
export type Requests_Followup_Task_Cout_Stddev_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Requests_Followup_Task_Cout_Sum_Fields = {
  __typename?: 'requests_followup_task_cout_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "requests_followup_task_cout" */
export type Requests_Followup_Task_Cout_Sum_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Requests_Followup_Task_Cout_Var_Pop_Fields = {
  __typename?: 'requests_followup_task_cout_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "requests_followup_task_cout" */
export type Requests_Followup_Task_Cout_Var_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Requests_Followup_Task_Cout_Var_Samp_Fields = {
  __typename?: 'requests_followup_task_cout_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "requests_followup_task_cout" */
export type Requests_Followup_Task_Cout_Var_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Requests_Followup_Task_Cout_Variance_Fields = {
  __typename?: 'requests_followup_task_cout_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "requests_followup_task_cout" */
export type Requests_Followup_Task_Cout_Variance_Order_By = {
  count?: Maybe<Order_By>;
};

/** input type for inserting data into table "requests" */
export type Requests_Insert_Input = {
  address?: Maybe<Scalars['String']>;
  builder?: Maybe<Scalars['String']>;
  case_id?: Maybe<Scalars['uuid']>;
  case_type?: Maybe<Case_Types_Obj_Rel_Insert_Input>;
  case_type_id?: Maybe<Scalars['uuid']>;
  city?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  external?: Maybe<Contacts_Obj_Rel_Insert_Input>;
  external_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  inspection_date?: Maybe<Scalars['String']>;
  inspection_time?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  reminders?: Maybe<Reminders_Arr_Rel_Insert_Input>;
  request_notes?: Maybe<Request_Notes_Arr_Rel_Insert_Input>;
  responsible?: Maybe<Users_Obj_Rel_Insert_Input>;
  responsible_id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
  task?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Requests_Max_Fields = {
  __typename?: 'requests_max_fields';
  address?: Maybe<Scalars['String']>;
  builder?: Maybe<Scalars['String']>;
  case_id?: Maybe<Scalars['uuid']>;
  case_type_id?: Maybe<Scalars['uuid']>;
  city?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  inspection_date?: Maybe<Scalars['String']>;
  inspection_time?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  responsible_id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
  task?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "requests" */
export type Requests_Max_Order_By = {
  address?: Maybe<Order_By>;
  builder?: Maybe<Order_By>;
  case_id?: Maybe<Order_By>;
  case_type_id?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inspection_date?: Maybe<Order_By>;
  inspection_time?: Maybe<Order_By>;
  mail?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  responsible_id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  task?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Requests_Min_Fields = {
  __typename?: 'requests_min_fields';
  address?: Maybe<Scalars['String']>;
  builder?: Maybe<Scalars['String']>;
  case_id?: Maybe<Scalars['uuid']>;
  case_type_id?: Maybe<Scalars['uuid']>;
  city?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  inspection_date?: Maybe<Scalars['String']>;
  inspection_time?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  responsible_id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
  task?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "requests" */
export type Requests_Min_Order_By = {
  address?: Maybe<Order_By>;
  builder?: Maybe<Order_By>;
  case_id?: Maybe<Order_By>;
  case_type_id?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inspection_date?: Maybe<Order_By>;
  inspection_time?: Maybe<Order_By>;
  mail?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  responsible_id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  task?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

/** response of any mutation on the table "requests" */
export type Requests_Mutation_Response = {
  __typename?: 'requests_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Requests>;
};

/** columns and relationships of "requests_new_task_count" */
export type Requests_New_Task_Count = {
  __typename?: 'requests_new_task_count';
  /** An object relationship */
  case_type?: Maybe<Case_Types>;
  case_type_id?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "requests_new_task_count" */
export type Requests_New_Task_Count_Aggregate = {
  __typename?: 'requests_new_task_count_aggregate';
  aggregate?: Maybe<Requests_New_Task_Count_Aggregate_Fields>;
  nodes: Array<Requests_New_Task_Count>;
};

/** aggregate fields of "requests_new_task_count" */
export type Requests_New_Task_Count_Aggregate_Fields = {
  __typename?: 'requests_new_task_count_aggregate_fields';
  avg?: Maybe<Requests_New_Task_Count_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Requests_New_Task_Count_Max_Fields>;
  min?: Maybe<Requests_New_Task_Count_Min_Fields>;
  stddev?: Maybe<Requests_New_Task_Count_Stddev_Fields>;
  stddev_pop?: Maybe<Requests_New_Task_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Requests_New_Task_Count_Stddev_Samp_Fields>;
  sum?: Maybe<Requests_New_Task_Count_Sum_Fields>;
  var_pop?: Maybe<Requests_New_Task_Count_Var_Pop_Fields>;
  var_samp?: Maybe<Requests_New_Task_Count_Var_Samp_Fields>;
  variance?: Maybe<Requests_New_Task_Count_Variance_Fields>;
};


/** aggregate fields of "requests_new_task_count" */
export type Requests_New_Task_Count_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Requests_New_Task_Count_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "requests_new_task_count" */
export type Requests_New_Task_Count_Aggregate_Order_By = {
  avg?: Maybe<Requests_New_Task_Count_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Requests_New_Task_Count_Max_Order_By>;
  min?: Maybe<Requests_New_Task_Count_Min_Order_By>;
  stddev?: Maybe<Requests_New_Task_Count_Stddev_Order_By>;
  stddev_pop?: Maybe<Requests_New_Task_Count_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Requests_New_Task_Count_Stddev_Samp_Order_By>;
  sum?: Maybe<Requests_New_Task_Count_Sum_Order_By>;
  var_pop?: Maybe<Requests_New_Task_Count_Var_Pop_Order_By>;
  var_samp?: Maybe<Requests_New_Task_Count_Var_Samp_Order_By>;
  variance?: Maybe<Requests_New_Task_Count_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Requests_New_Task_Count_Avg_Fields = {
  __typename?: 'requests_new_task_count_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "requests_new_task_count" */
export type Requests_New_Task_Count_Avg_Order_By = {
  count?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "requests_new_task_count". All fields are combined with a logical 'AND'. */
export type Requests_New_Task_Count_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Requests_New_Task_Count_Bool_Exp>>>;
  _not?: Maybe<Requests_New_Task_Count_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Requests_New_Task_Count_Bool_Exp>>>;
  case_type?: Maybe<Case_Types_Bool_Exp>;
  case_type_id?: Maybe<Uuid_Comparison_Exp>;
  count?: Maybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Requests_New_Task_Count_Max_Fields = {
  __typename?: 'requests_new_task_count_max_fields';
  case_type_id?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "requests_new_task_count" */
export type Requests_New_Task_Count_Max_Order_By = {
  case_type_id?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Requests_New_Task_Count_Min_Fields = {
  __typename?: 'requests_new_task_count_min_fields';
  case_type_id?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "requests_new_task_count" */
export type Requests_New_Task_Count_Min_Order_By = {
  case_type_id?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
};

/** ordering options when selecting data from "requests_new_task_count" */
export type Requests_New_Task_Count_Order_By = {
  case_type?: Maybe<Case_Types_Order_By>;
  case_type_id?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
};

/** select columns of table "requests_new_task_count" */
export enum Requests_New_Task_Count_Select_Column {
  /** column name */
  CaseTypeId = 'case_type_id',
  /** column name */
  Count = 'count'
}

/** aggregate stddev on columns */
export type Requests_New_Task_Count_Stddev_Fields = {
  __typename?: 'requests_new_task_count_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "requests_new_task_count" */
export type Requests_New_Task_Count_Stddev_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Requests_New_Task_Count_Stddev_Pop_Fields = {
  __typename?: 'requests_new_task_count_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "requests_new_task_count" */
export type Requests_New_Task_Count_Stddev_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Requests_New_Task_Count_Stddev_Samp_Fields = {
  __typename?: 'requests_new_task_count_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "requests_new_task_count" */
export type Requests_New_Task_Count_Stddev_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Requests_New_Task_Count_Sum_Fields = {
  __typename?: 'requests_new_task_count_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "requests_new_task_count" */
export type Requests_New_Task_Count_Sum_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Requests_New_Task_Count_Var_Pop_Fields = {
  __typename?: 'requests_new_task_count_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "requests_new_task_count" */
export type Requests_New_Task_Count_Var_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Requests_New_Task_Count_Var_Samp_Fields = {
  __typename?: 'requests_new_task_count_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "requests_new_task_count" */
export type Requests_New_Task_Count_Var_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Requests_New_Task_Count_Variance_Fields = {
  __typename?: 'requests_new_task_count_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "requests_new_task_count" */
export type Requests_New_Task_Count_Variance_Order_By = {
  count?: Maybe<Order_By>;
};

/** columns and relationships of "requests_new_task_cout" */
export type Requests_New_Task_Cout = {
  __typename?: 'requests_new_task_cout';
  count?: Maybe<Scalars['bigint']>;
  task?: Maybe<Scalars['String']>;
};

/** aggregated selection of "requests_new_task_cout" */
export type Requests_New_Task_Cout_Aggregate = {
  __typename?: 'requests_new_task_cout_aggregate';
  aggregate?: Maybe<Requests_New_Task_Cout_Aggregate_Fields>;
  nodes: Array<Requests_New_Task_Cout>;
};

/** aggregate fields of "requests_new_task_cout" */
export type Requests_New_Task_Cout_Aggregate_Fields = {
  __typename?: 'requests_new_task_cout_aggregate_fields';
  avg?: Maybe<Requests_New_Task_Cout_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Requests_New_Task_Cout_Max_Fields>;
  min?: Maybe<Requests_New_Task_Cout_Min_Fields>;
  stddev?: Maybe<Requests_New_Task_Cout_Stddev_Fields>;
  stddev_pop?: Maybe<Requests_New_Task_Cout_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Requests_New_Task_Cout_Stddev_Samp_Fields>;
  sum?: Maybe<Requests_New_Task_Cout_Sum_Fields>;
  var_pop?: Maybe<Requests_New_Task_Cout_Var_Pop_Fields>;
  var_samp?: Maybe<Requests_New_Task_Cout_Var_Samp_Fields>;
  variance?: Maybe<Requests_New_Task_Cout_Variance_Fields>;
};


/** aggregate fields of "requests_new_task_cout" */
export type Requests_New_Task_Cout_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Requests_New_Task_Cout_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "requests_new_task_cout" */
export type Requests_New_Task_Cout_Aggregate_Order_By = {
  avg?: Maybe<Requests_New_Task_Cout_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Requests_New_Task_Cout_Max_Order_By>;
  min?: Maybe<Requests_New_Task_Cout_Min_Order_By>;
  stddev?: Maybe<Requests_New_Task_Cout_Stddev_Order_By>;
  stddev_pop?: Maybe<Requests_New_Task_Cout_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Requests_New_Task_Cout_Stddev_Samp_Order_By>;
  sum?: Maybe<Requests_New_Task_Cout_Sum_Order_By>;
  var_pop?: Maybe<Requests_New_Task_Cout_Var_Pop_Order_By>;
  var_samp?: Maybe<Requests_New_Task_Cout_Var_Samp_Order_By>;
  variance?: Maybe<Requests_New_Task_Cout_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Requests_New_Task_Cout_Avg_Fields = {
  __typename?: 'requests_new_task_cout_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "requests_new_task_cout" */
export type Requests_New_Task_Cout_Avg_Order_By = {
  count?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "requests_new_task_cout". All fields are combined with a logical 'AND'. */
export type Requests_New_Task_Cout_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Requests_New_Task_Cout_Bool_Exp>>>;
  _not?: Maybe<Requests_New_Task_Cout_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Requests_New_Task_Cout_Bool_Exp>>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  task?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Requests_New_Task_Cout_Max_Fields = {
  __typename?: 'requests_new_task_cout_max_fields';
  count?: Maybe<Scalars['bigint']>;
  task?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "requests_new_task_cout" */
export type Requests_New_Task_Cout_Max_Order_By = {
  count?: Maybe<Order_By>;
  task?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Requests_New_Task_Cout_Min_Fields = {
  __typename?: 'requests_new_task_cout_min_fields';
  count?: Maybe<Scalars['bigint']>;
  task?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "requests_new_task_cout" */
export type Requests_New_Task_Cout_Min_Order_By = {
  count?: Maybe<Order_By>;
  task?: Maybe<Order_By>;
};

/** ordering options when selecting data from "requests_new_task_cout" */
export type Requests_New_Task_Cout_Order_By = {
  count?: Maybe<Order_By>;
  task?: Maybe<Order_By>;
};

/** select columns of table "requests_new_task_cout" */
export enum Requests_New_Task_Cout_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Task = 'task'
}

/** aggregate stddev on columns */
export type Requests_New_Task_Cout_Stddev_Fields = {
  __typename?: 'requests_new_task_cout_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "requests_new_task_cout" */
export type Requests_New_Task_Cout_Stddev_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Requests_New_Task_Cout_Stddev_Pop_Fields = {
  __typename?: 'requests_new_task_cout_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "requests_new_task_cout" */
export type Requests_New_Task_Cout_Stddev_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Requests_New_Task_Cout_Stddev_Samp_Fields = {
  __typename?: 'requests_new_task_cout_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "requests_new_task_cout" */
export type Requests_New_Task_Cout_Stddev_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Requests_New_Task_Cout_Sum_Fields = {
  __typename?: 'requests_new_task_cout_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "requests_new_task_cout" */
export type Requests_New_Task_Cout_Sum_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Requests_New_Task_Cout_Var_Pop_Fields = {
  __typename?: 'requests_new_task_cout_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "requests_new_task_cout" */
export type Requests_New_Task_Cout_Var_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Requests_New_Task_Cout_Var_Samp_Fields = {
  __typename?: 'requests_new_task_cout_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "requests_new_task_cout" */
export type Requests_New_Task_Cout_Var_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Requests_New_Task_Cout_Variance_Fields = {
  __typename?: 'requests_new_task_cout_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "requests_new_task_cout" */
export type Requests_New_Task_Cout_Variance_Order_By = {
  count?: Maybe<Order_By>;
};

/** input type for inserting object relation for remote table "requests" */
export type Requests_Obj_Rel_Insert_Input = {
  data: Requests_Insert_Input;
  on_conflict?: Maybe<Requests_On_Conflict>;
};

/** on conflict condition type for table "requests" */
export type Requests_On_Conflict = {
  constraint: Requests_Constraint;
  update_columns: Array<Requests_Update_Column>;
  where?: Maybe<Requests_Bool_Exp>;
};

/** ordering options when selecting data from "requests" */
export type Requests_Order_By = {
  address?: Maybe<Order_By>;
  builder?: Maybe<Order_By>;
  case_id?: Maybe<Order_By>;
  case_type?: Maybe<Case_Types_Order_By>;
  case_type_id?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  external?: Maybe<Contacts_Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inspection_date?: Maybe<Order_By>;
  inspection_time?: Maybe<Order_By>;
  mail?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  reminders_aggregate?: Maybe<Reminders_Aggregate_Order_By>;
  request_notes_aggregate?: Maybe<Request_Notes_Aggregate_Order_By>;
  responsible?: Maybe<Users_Order_By>;
  responsible_id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  task?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

/** columns and relationships of "requests_per_case_type_per_month" */
export type Requests_Per_Case_Type_Per_Month = {
  __typename?: 'requests_per_case_type_per_month';
  /** An object relationship */
  case_type?: Maybe<Case_Types>;
  case_type_id?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
  year_month?: Maybe<Scalars['String']>;
};

/** aggregated selection of "requests_per_case_type_per_month" */
export type Requests_Per_Case_Type_Per_Month_Aggregate = {
  __typename?: 'requests_per_case_type_per_month_aggregate';
  aggregate?: Maybe<Requests_Per_Case_Type_Per_Month_Aggregate_Fields>;
  nodes: Array<Requests_Per_Case_Type_Per_Month>;
};

/** aggregate fields of "requests_per_case_type_per_month" */
export type Requests_Per_Case_Type_Per_Month_Aggregate_Fields = {
  __typename?: 'requests_per_case_type_per_month_aggregate_fields';
  avg?: Maybe<Requests_Per_Case_Type_Per_Month_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Requests_Per_Case_Type_Per_Month_Max_Fields>;
  min?: Maybe<Requests_Per_Case_Type_Per_Month_Min_Fields>;
  stddev?: Maybe<Requests_Per_Case_Type_Per_Month_Stddev_Fields>;
  stddev_pop?: Maybe<Requests_Per_Case_Type_Per_Month_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Requests_Per_Case_Type_Per_Month_Stddev_Samp_Fields>;
  sum?: Maybe<Requests_Per_Case_Type_Per_Month_Sum_Fields>;
  var_pop?: Maybe<Requests_Per_Case_Type_Per_Month_Var_Pop_Fields>;
  var_samp?: Maybe<Requests_Per_Case_Type_Per_Month_Var_Samp_Fields>;
  variance?: Maybe<Requests_Per_Case_Type_Per_Month_Variance_Fields>;
};


/** aggregate fields of "requests_per_case_type_per_month" */
export type Requests_Per_Case_Type_Per_Month_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Requests_Per_Case_Type_Per_Month_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "requests_per_case_type_per_month" */
export type Requests_Per_Case_Type_Per_Month_Aggregate_Order_By = {
  avg?: Maybe<Requests_Per_Case_Type_Per_Month_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Requests_Per_Case_Type_Per_Month_Max_Order_By>;
  min?: Maybe<Requests_Per_Case_Type_Per_Month_Min_Order_By>;
  stddev?: Maybe<Requests_Per_Case_Type_Per_Month_Stddev_Order_By>;
  stddev_pop?: Maybe<Requests_Per_Case_Type_Per_Month_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Requests_Per_Case_Type_Per_Month_Stddev_Samp_Order_By>;
  sum?: Maybe<Requests_Per_Case_Type_Per_Month_Sum_Order_By>;
  var_pop?: Maybe<Requests_Per_Case_Type_Per_Month_Var_Pop_Order_By>;
  var_samp?: Maybe<Requests_Per_Case_Type_Per_Month_Var_Samp_Order_By>;
  variance?: Maybe<Requests_Per_Case_Type_Per_Month_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Requests_Per_Case_Type_Per_Month_Avg_Fields = {
  __typename?: 'requests_per_case_type_per_month_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "requests_per_case_type_per_month" */
export type Requests_Per_Case_Type_Per_Month_Avg_Order_By = {
  count?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "requests_per_case_type_per_month". All fields are combined with a logical 'AND'. */
export type Requests_Per_Case_Type_Per_Month_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Requests_Per_Case_Type_Per_Month_Bool_Exp>>>;
  _not?: Maybe<Requests_Per_Case_Type_Per_Month_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Requests_Per_Case_Type_Per_Month_Bool_Exp>>>;
  case_type?: Maybe<Case_Types_Bool_Exp>;
  case_type_id?: Maybe<Uuid_Comparison_Exp>;
  count?: Maybe<Bigint_Comparison_Exp>;
  year_month?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Requests_Per_Case_Type_Per_Month_Max_Fields = {
  __typename?: 'requests_per_case_type_per_month_max_fields';
  case_type_id?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
  year_month?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "requests_per_case_type_per_month" */
export type Requests_Per_Case_Type_Per_Month_Max_Order_By = {
  case_type_id?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
  year_month?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Requests_Per_Case_Type_Per_Month_Min_Fields = {
  __typename?: 'requests_per_case_type_per_month_min_fields';
  case_type_id?: Maybe<Scalars['uuid']>;
  count?: Maybe<Scalars['bigint']>;
  year_month?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "requests_per_case_type_per_month" */
export type Requests_Per_Case_Type_Per_Month_Min_Order_By = {
  case_type_id?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
  year_month?: Maybe<Order_By>;
};

/** ordering options when selecting data from "requests_per_case_type_per_month" */
export type Requests_Per_Case_Type_Per_Month_Order_By = {
  case_type?: Maybe<Case_Types_Order_By>;
  case_type_id?: Maybe<Order_By>;
  count?: Maybe<Order_By>;
  year_month?: Maybe<Order_By>;
};

/** select columns of table "requests_per_case_type_per_month" */
export enum Requests_Per_Case_Type_Per_Month_Select_Column {
  /** column name */
  CaseTypeId = 'case_type_id',
  /** column name */
  Count = 'count',
  /** column name */
  YearMonth = 'year_month'
}

/** aggregate stddev on columns */
export type Requests_Per_Case_Type_Per_Month_Stddev_Fields = {
  __typename?: 'requests_per_case_type_per_month_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "requests_per_case_type_per_month" */
export type Requests_Per_Case_Type_Per_Month_Stddev_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Requests_Per_Case_Type_Per_Month_Stddev_Pop_Fields = {
  __typename?: 'requests_per_case_type_per_month_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "requests_per_case_type_per_month" */
export type Requests_Per_Case_Type_Per_Month_Stddev_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Requests_Per_Case_Type_Per_Month_Stddev_Samp_Fields = {
  __typename?: 'requests_per_case_type_per_month_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "requests_per_case_type_per_month" */
export type Requests_Per_Case_Type_Per_Month_Stddev_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Requests_Per_Case_Type_Per_Month_Sum_Fields = {
  __typename?: 'requests_per_case_type_per_month_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "requests_per_case_type_per_month" */
export type Requests_Per_Case_Type_Per_Month_Sum_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Requests_Per_Case_Type_Per_Month_Var_Pop_Fields = {
  __typename?: 'requests_per_case_type_per_month_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "requests_per_case_type_per_month" */
export type Requests_Per_Case_Type_Per_Month_Var_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Requests_Per_Case_Type_Per_Month_Var_Samp_Fields = {
  __typename?: 'requests_per_case_type_per_month_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "requests_per_case_type_per_month" */
export type Requests_Per_Case_Type_Per_Month_Var_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Requests_Per_Case_Type_Per_Month_Variance_Fields = {
  __typename?: 'requests_per_case_type_per_month_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "requests_per_case_type_per_month" */
export type Requests_Per_Case_Type_Per_Month_Variance_Order_By = {
  count?: Maybe<Order_By>;
};

/** primary key columns input for table: "requests" */
export type Requests_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "requests" */
export enum Requests_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Builder = 'builder',
  /** column name */
  CaseId = 'case_id',
  /** column name */
  CaseTypeId = 'case_type_id',
  /** column name */
  City = 'city',
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  InspectionDate = 'inspection_date',
  /** column name */
  InspectionTime = 'inspection_time',
  /** column name */
  Mail = 'mail',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  ResponsibleId = 'responsible_id',
  /** column name */
  State = 'state',
  /** column name */
  Task = 'task',
  /** column name */
  ZipCode = 'zip_code'
}

/** input type for updating data in table "requests" */
export type Requests_Set_Input = {
  address?: Maybe<Scalars['String']>;
  builder?: Maybe<Scalars['String']>;
  case_id?: Maybe<Scalars['uuid']>;
  case_type_id?: Maybe<Scalars['uuid']>;
  city?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  inspection_date?: Maybe<Scalars['String']>;
  inspection_time?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  responsible_id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
  task?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** update columns of table "requests" */
export enum Requests_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Builder = 'builder',
  /** column name */
  CaseId = 'case_id',
  /** column name */
  CaseTypeId = 'case_type_id',
  /** column name */
  City = 'city',
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  InspectionDate = 'inspection_date',
  /** column name */
  InspectionTime = 'inspection_time',
  /** column name */
  Mail = 'mail',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  ResponsibleId = 'responsible_id',
  /** column name */
  State = 'state',
  /** column name */
  Task = 'task',
  /** column name */
  ZipCode = 'zip_code'
}

/** columns and relationships of "standard_photos" */
export type Standard_Photos = {
  __typename?: 'standard_photos';
  category?: Maybe<Scalars['String']>;
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid'];
  id: Scalars['uuid'];
  title: Scalars['String'];
};

/** aggregated selection of "standard_photos" */
export type Standard_Photos_Aggregate = {
  __typename?: 'standard_photos_aggregate';
  aggregate?: Maybe<Standard_Photos_Aggregate_Fields>;
  nodes: Array<Standard_Photos>;
};

/** aggregate fields of "standard_photos" */
export type Standard_Photos_Aggregate_Fields = {
  __typename?: 'standard_photos_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Standard_Photos_Max_Fields>;
  min?: Maybe<Standard_Photos_Min_Fields>;
};


/** aggregate fields of "standard_photos" */
export type Standard_Photos_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Standard_Photos_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "standard_photos" */
export type Standard_Photos_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Standard_Photos_Max_Order_By>;
  min?: Maybe<Standard_Photos_Min_Order_By>;
};

/** input type for inserting array relation for remote table "standard_photos" */
export type Standard_Photos_Arr_Rel_Insert_Input = {
  data: Array<Standard_Photos_Insert_Input>;
  on_conflict?: Maybe<Standard_Photos_On_Conflict>;
};

/** Boolean expression to filter rows from the table "standard_photos". All fields are combined with a logical 'AND'. */
export type Standard_Photos_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Standard_Photos_Bool_Exp>>>;
  _not?: Maybe<Standard_Photos_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Standard_Photos_Bool_Exp>>>;
  category?: Maybe<String_Comparison_Exp>;
  file?: Maybe<Files_Bool_Exp>;
  file_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "standard_photos" */
export enum Standard_Photos_Constraint {
  /** unique or primary key constraint */
  StandardPhotosPkey = 'standard_photos_pkey'
}

/** input type for inserting data into table "standard_photos" */
export type Standard_Photos_Insert_Input = {
  category?: Maybe<Scalars['String']>;
  file?: Maybe<Files_Obj_Rel_Insert_Input>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Standard_Photos_Max_Fields = {
  __typename?: 'standard_photos_max_fields';
  category?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "standard_photos" */
export type Standard_Photos_Max_Order_By = {
  category?: Maybe<Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Standard_Photos_Min_Fields = {
  __typename?: 'standard_photos_min_fields';
  category?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "standard_photos" */
export type Standard_Photos_Min_Order_By = {
  category?: Maybe<Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** response of any mutation on the table "standard_photos" */
export type Standard_Photos_Mutation_Response = {
  __typename?: 'standard_photos_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Standard_Photos>;
};

/** input type for inserting object relation for remote table "standard_photos" */
export type Standard_Photos_Obj_Rel_Insert_Input = {
  data: Standard_Photos_Insert_Input;
  on_conflict?: Maybe<Standard_Photos_On_Conflict>;
};

/** on conflict condition type for table "standard_photos" */
export type Standard_Photos_On_Conflict = {
  constraint: Standard_Photos_Constraint;
  update_columns: Array<Standard_Photos_Update_Column>;
  where?: Maybe<Standard_Photos_Bool_Exp>;
};

/** ordering options when selecting data from "standard_photos" */
export type Standard_Photos_Order_By = {
  category?: Maybe<Order_By>;
  file?: Maybe<Files_Order_By>;
  file_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
};

/** primary key columns input for table: "standard_photos" */
export type Standard_Photos_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "standard_photos" */
export enum Standard_Photos_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "standard_photos" */
export type Standard_Photos_Set_Input = {
  category?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** update columns of table "standard_photos" */
export enum Standard_Photos_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

/** columns and relationships of "standard_terms" */
export type Standard_Terms = {
  __typename?: 'standard_terms';
  content: Scalars['String'];
  created: Scalars['String'];
  id: Scalars['uuid'];
};

/** aggregated selection of "standard_terms" */
export type Standard_Terms_Aggregate = {
  __typename?: 'standard_terms_aggregate';
  aggregate?: Maybe<Standard_Terms_Aggregate_Fields>;
  nodes: Array<Standard_Terms>;
};

/** aggregate fields of "standard_terms" */
export type Standard_Terms_Aggregate_Fields = {
  __typename?: 'standard_terms_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Standard_Terms_Max_Fields>;
  min?: Maybe<Standard_Terms_Min_Fields>;
};


/** aggregate fields of "standard_terms" */
export type Standard_Terms_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Standard_Terms_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "standard_terms" */
export type Standard_Terms_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Standard_Terms_Max_Order_By>;
  min?: Maybe<Standard_Terms_Min_Order_By>;
};

/** input type for inserting array relation for remote table "standard_terms" */
export type Standard_Terms_Arr_Rel_Insert_Input = {
  data: Array<Standard_Terms_Insert_Input>;
  on_conflict?: Maybe<Standard_Terms_On_Conflict>;
};

/** Boolean expression to filter rows from the table "standard_terms". All fields are combined with a logical 'AND'. */
export type Standard_Terms_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Standard_Terms_Bool_Exp>>>;
  _not?: Maybe<Standard_Terms_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Standard_Terms_Bool_Exp>>>;
  content?: Maybe<String_Comparison_Exp>;
  created?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "standard_terms" */
export enum Standard_Terms_Constraint {
  /** unique or primary key constraint */
  StandardTermsPkey = 'standard_terms_pkey'
}

/** input type for inserting data into table "standard_terms" */
export type Standard_Terms_Insert_Input = {
  content?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Standard_Terms_Max_Fields = {
  __typename?: 'standard_terms_max_fields';
  content?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "standard_terms" */
export type Standard_Terms_Max_Order_By = {
  content?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Standard_Terms_Min_Fields = {
  __typename?: 'standard_terms_min_fields';
  content?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "standard_terms" */
export type Standard_Terms_Min_Order_By = {
  content?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "standard_terms" */
export type Standard_Terms_Mutation_Response = {
  __typename?: 'standard_terms_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Standard_Terms>;
};

/** input type for inserting object relation for remote table "standard_terms" */
export type Standard_Terms_Obj_Rel_Insert_Input = {
  data: Standard_Terms_Insert_Input;
  on_conflict?: Maybe<Standard_Terms_On_Conflict>;
};

/** on conflict condition type for table "standard_terms" */
export type Standard_Terms_On_Conflict = {
  constraint: Standard_Terms_Constraint;
  update_columns: Array<Standard_Terms_Update_Column>;
  where?: Maybe<Standard_Terms_Bool_Exp>;
};

/** ordering options when selecting data from "standard_terms" */
export type Standard_Terms_Order_By = {
  content?: Maybe<Order_By>;
  created?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: "standard_terms" */
export type Standard_Terms_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "standard_terms" */
export enum Standard_Terms_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "standard_terms" */
export type Standard_Terms_Set_Input = {
  content?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "standard_terms" */
export enum Standard_Terms_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  Created = 'created',
  /** column name */
  Id = 'id'
}

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "absence" */
  absence: Array<Absence>;
  /** fetch aggregated fields from the table: "absence" */
  absence_aggregate: Absence_Aggregate;
  /** fetch data from the table: "absence" using primary key columns */
  absence_by_pk?: Maybe<Absence>;
  /** fetch data from the table: "absence_search_view" */
  absence_search_view: Array<Absence_Search_View>;
  /** fetch aggregated fields from the table: "absence_search_view" */
  absence_search_view_aggregate: Absence_Search_View_Aggregate;
  /** fetch data from the table: "absence_types" */
  absence_types: Array<Absence_Types>;
  /** fetch aggregated fields from the table: "absence_types" */
  absence_types_aggregate: Absence_Types_Aggregate;
  /** fetch data from the table: "absence_types" using primary key columns */
  absence_types_by_pk?: Maybe<Absence_Types>;
  /** fetch data from the table: "archive_files" */
  archive_files: Array<Archive_Files>;
  /** fetch aggregated fields from the table: "archive_files" */
  archive_files_aggregate: Archive_Files_Aggregate;
  /** fetch data from the table: "archive_files" using primary key columns */
  archive_files_by_pk?: Maybe<Archive_Files>;
  /** fetch data from the table: "case_doc_checklists" */
  case_doc_checklists: Array<Case_Doc_Checklists>;
  /** fetch aggregated fields from the table: "case_doc_checklists" */
  case_doc_checklists_aggregate: Case_Doc_Checklists_Aggregate;
  /** fetch data from the table: "case_doc_checklists" using primary key columns */
  case_doc_checklists_by_pk?: Maybe<Case_Doc_Checklists>;
  /** fetch data from the table: "case_doc_economy" */
  case_doc_economy: Array<Case_Doc_Economy>;
  /** fetch aggregated fields from the table: "case_doc_economy" */
  case_doc_economy_aggregate: Case_Doc_Economy_Aggregate;
  /** fetch data from the table: "case_doc_economy" using primary key columns */
  case_doc_economy_by_pk?: Maybe<Case_Doc_Economy>;
  /** fetch data from the table: "case_doc_notes" */
  case_doc_notes: Array<Case_Doc_Notes>;
  /** fetch aggregated fields from the table: "case_doc_notes" */
  case_doc_notes_aggregate: Case_Doc_Notes_Aggregate;
  /** fetch data from the table: "case_doc_notes" using primary key columns */
  case_doc_notes_by_pk?: Maybe<Case_Doc_Notes>;
  /** fetch data from the table: "case_doc_order_confirmations" */
  case_doc_order_confirmations: Array<Case_Doc_Order_Confirmations>;
  /** fetch aggregated fields from the table: "case_doc_order_confirmations" */
  case_doc_order_confirmations_aggregate: Case_Doc_Order_Confirmations_Aggregate;
  /** fetch data from the table: "case_doc_order_confirmations" using primary key columns */
  case_doc_order_confirmations_by_pk?: Maybe<Case_Doc_Order_Confirmations>;
  /** fetch data from the table: "case_doc_quality_controls" */
  case_doc_quality_controls: Array<Case_Doc_Quality_Controls>;
  /** fetch aggregated fields from the table: "case_doc_quality_controls" */
  case_doc_quality_controls_aggregate: Case_Doc_Quality_Controls_Aggregate;
  /** fetch data from the table: "case_doc_quality_controls" using primary key columns */
  case_doc_quality_controls_by_pk?: Maybe<Case_Doc_Quality_Controls>;
  /** fetch data from the table: "case_files" */
  case_files: Array<Case_Files>;
  /** fetch aggregated fields from the table: "case_files" */
  case_files_aggregate: Case_Files_Aggregate;
  /** fetch data from the table: "case_files" using primary key columns */
  case_files_by_pk?: Maybe<Case_Files>;
  /** fetch data from the table: "case_photos" */
  case_photos: Array<Case_Photos>;
  /** fetch aggregated fields from the table: "case_photos" */
  case_photos_aggregate: Case_Photos_Aggregate;
  /** fetch data from the table: "case_photos" using primary key columns */
  case_photos_by_pk?: Maybe<Case_Photos>;
  /** fetch data from the table: "case_suppliers" */
  case_suppliers: Array<Case_Suppliers>;
  /** fetch aggregated fields from the table: "case_suppliers" */
  case_suppliers_aggregate: Case_Suppliers_Aggregate;
  /** fetch data from the table: "case_suppliers" using primary key columns */
  case_suppliers_by_pk?: Maybe<Case_Suppliers>;
  /** fetch data from the table: "case_time_entries" */
  case_time_entries: Array<Case_Time_Entries>;
  /** fetch aggregated fields from the table: "case_time_entries" */
  case_time_entries_aggregate: Case_Time_Entries_Aggregate;
  /** fetch data from the table: "case_time_entries" using primary key columns */
  case_time_entries_by_pk?: Maybe<Case_Time_Entries>;
  /** fetch data from the table: "case_types" */
  case_types: Array<Case_Types>;
  /** fetch aggregated fields from the table: "case_types" */
  case_types_aggregate: Case_Types_Aggregate;
  /** fetch data from the table: "case_types" using primary key columns */
  case_types_by_pk?: Maybe<Case_Types>;
  /** fetch data from the table: "cases" */
  cases: Array<Cases>;
  /** fetch aggregated fields from the table: "cases" */
  cases_aggregate: Cases_Aggregate;
  /** fetch data from the table: "cases_building_inspection_per_employee" */
  cases_building_inspection_per_employee: Array<Cases_Building_Inspection_Per_Employee>;
  /** fetch aggregated fields from the table: "cases_building_inspection_per_employee" */
  cases_building_inspection_per_employee_aggregate: Cases_Building_Inspection_Per_Employee_Aggregate;
  /** fetch data from the table: "cases" using primary key columns */
  cases_by_pk?: Maybe<Cases>;
  /** fetch data from the table: "cases_case_count_per_employee" */
  cases_case_count_per_employee: Array<Cases_Case_Count_Per_Employee>;
  /** fetch aggregated fields from the table: "cases_case_count_per_employee" */
  cases_case_count_per_employee_aggregate: Cases_Case_Count_Per_Employee_Aggregate;
  /** fetch data from the table: "cases_case_type_count" */
  cases_case_type_count: Array<Cases_Case_Type_Count>;
  /** fetch aggregated fields from the table: "cases_case_type_count" */
  cases_case_type_count_aggregate: Cases_Case_Type_Count_Aggregate;
  /** fetch data from the table: "cases_created_per_employee_per_month" */
  cases_created_per_employee_per_month: Array<Cases_Created_Per_Employee_Per_Month>;
  /** fetch aggregated fields from the table: "cases_created_per_employee_per_month" */
  cases_created_per_employee_per_month_aggregate: Cases_Created_Per_Employee_Per_Month_Aggregate;
  /** fetch data from the table: "cases_per_case_type_per_month" */
  cases_per_case_type_per_month: Array<Cases_Per_Case_Type_Per_Month>;
  /** fetch aggregated fields from the table: "cases_per_case_type_per_month" */
  cases_per_case_type_per_month_aggregate: Cases_Per_Case_Type_Per_Month_Aggregate;
  /** fetch data from the table: "checklist_item_arrows" */
  checklist_item_arrows: Array<Checklist_Item_Arrows>;
  /** fetch aggregated fields from the table: "checklist_item_arrows" */
  checklist_item_arrows_aggregate: Checklist_Item_Arrows_Aggregate;
  /** fetch data from the table: "checklist_item_arrows" using primary key columns */
  checklist_item_arrows_by_pk?: Maybe<Checklist_Item_Arrows>;
  /** fetch data from the table: "checklist_items" */
  checklist_items: Array<Checklist_Items>;
  /** fetch aggregated fields from the table: "checklist_items" */
  checklist_items_aggregate: Checklist_Items_Aggregate;
  /** fetch data from the table: "checklist_items" using primary key columns */
  checklist_items_by_pk?: Maybe<Checklist_Items>;
  /** fetch data from the table: "construction_plan_entries" */
  construction_plan_entries: Array<Construction_Plan_Entries>;
  /** fetch aggregated fields from the table: "construction_plan_entries" */
  construction_plan_entries_aggregate: Construction_Plan_Entries_Aggregate;
  /** fetch data from the table: "construction_plan_entries" using primary key columns */
  construction_plan_entries_by_pk?: Maybe<Construction_Plan_Entries>;
  /** fetch data from the table: "construction_plans" */
  construction_plans: Array<Construction_Plans>;
  /** fetch aggregated fields from the table: "construction_plans" */
  construction_plans_aggregate: Construction_Plans_Aggregate;
  /** fetch data from the table: "construction_plans" using primary key columns */
  construction_plans_by_pk?: Maybe<Construction_Plans>;
  /** fetch data from the table: "contacts" */
  contacts: Array<Contacts>;
  /** fetch aggregated fields from the table: "contacts" */
  contacts_aggregate: Contacts_Aggregate;
  /** fetch data from the table: "contacts" using primary key columns */
  contacts_by_pk?: Maybe<Contacts>;
  /** fetch data from the table: "files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "files" */
  files_aggregate: Files_Aggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  /** fetch data from the table: "order_confirmation_tasks" */
  order_confirmation_tasks: Array<Order_Confirmation_Tasks>;
  /** fetch aggregated fields from the table: "order_confirmation_tasks" */
  order_confirmation_tasks_aggregate: Order_Confirmation_Tasks_Aggregate;
  /** fetch data from the table: "order_confirmation_tasks" using primary key columns */
  order_confirmation_tasks_by_pk?: Maybe<Order_Confirmation_Tasks>;
  /** fetch data from the table: "order_confirmation_template_tasks" */
  order_confirmation_template_tasks: Array<Order_Confirmation_Template_Tasks>;
  /** fetch aggregated fields from the table: "order_confirmation_template_tasks" */
  order_confirmation_template_tasks_aggregate: Order_Confirmation_Template_Tasks_Aggregate;
  /** fetch data from the table: "order_confirmation_template_tasks" using primary key columns */
  order_confirmation_template_tasks_by_pk?: Maybe<Order_Confirmation_Template_Tasks>;
  /** fetch data from the table: "order_confirmation_templates" */
  order_confirmation_templates: Array<Order_Confirmation_Templates>;
  /** fetch aggregated fields from the table: "order_confirmation_templates" */
  order_confirmation_templates_aggregate: Order_Confirmation_Templates_Aggregate;
  /** fetch data from the table: "order_confirmation_templates" using primary key columns */
  order_confirmation_templates_by_pk?: Maybe<Order_Confirmation_Templates>;
  /** fetch data from the table: "quality_control_task_arrows" */
  quality_control_task_arrows: Array<Quality_Control_Task_Arrows>;
  /** fetch aggregated fields from the table: "quality_control_task_arrows" */
  quality_control_task_arrows_aggregate: Quality_Control_Task_Arrows_Aggregate;
  /** fetch data from the table: "quality_control_task_arrows" using primary key columns */
  quality_control_task_arrows_by_pk?: Maybe<Quality_Control_Task_Arrows>;
  /** fetch data from the table: "quality_control_tasks" */
  quality_control_tasks: Array<Quality_Control_Tasks>;
  /** fetch aggregated fields from the table: "quality_control_tasks" */
  quality_control_tasks_aggregate: Quality_Control_Tasks_Aggregate;
  /** fetch data from the table: "quality_control_tasks" using primary key columns */
  quality_control_tasks_by_pk?: Maybe<Quality_Control_Tasks>;
  /** fetch data from the table: "quality_control_template_tasks" */
  quality_control_template_tasks: Array<Quality_Control_Template_Tasks>;
  /** fetch aggregated fields from the table: "quality_control_template_tasks" */
  quality_control_template_tasks_aggregate: Quality_Control_Template_Tasks_Aggregate;
  /** fetch data from the table: "quality_control_template_tasks" using primary key columns */
  quality_control_template_tasks_by_pk?: Maybe<Quality_Control_Template_Tasks>;
  /** fetch data from the table: "quality_control_templates" */
  quality_control_templates: Array<Quality_Control_Templates>;
  /** fetch aggregated fields from the table: "quality_control_templates" */
  quality_control_templates_aggregate: Quality_Control_Templates_Aggregate;
  /** fetch data from the table: "quality_control_templates" using primary key columns */
  quality_control_templates_by_pk?: Maybe<Quality_Control_Templates>;
  /** fetch data from the table: "reminder_recipients" */
  reminder_recipients: Array<Reminder_Recipients>;
  /** fetch aggregated fields from the table: "reminder_recipients" */
  reminder_recipients_aggregate: Reminder_Recipients_Aggregate;
  /** fetch data from the table: "reminder_recipients" using primary key columns */
  reminder_recipients_by_pk?: Maybe<Reminder_Recipients>;
  /** fetch data from the table: "reminders" */
  reminders: Array<Reminders>;
  /** fetch aggregated fields from the table: "reminders" */
  reminders_aggregate: Reminders_Aggregate;
  /** fetch data from the table: "reminders" using primary key columns */
  reminders_by_pk?: Maybe<Reminders>;
  /** fetch data from the table: "request_notes" */
  request_notes: Array<Request_Notes>;
  /** fetch aggregated fields from the table: "request_notes" */
  request_notes_aggregate: Request_Notes_Aggregate;
  /** fetch data from the table: "request_notes" using primary key columns */
  request_notes_by_pk?: Maybe<Request_Notes>;
  /** fetch data from the table: "requests" */
  requests: Array<Requests>;
  /** fetch aggregated fields from the table: "requests" */
  requests_aggregate: Requests_Aggregate;
  /** fetch data from the table: "requests" using primary key columns */
  requests_by_pk?: Maybe<Requests>;
  /** fetch data from the table: "requests_done_per_employee_per_month" */
  requests_done_per_employee_per_month: Array<Requests_Done_Per_Employee_Per_Month>;
  /** fetch aggregated fields from the table: "requests_done_per_employee_per_month" */
  requests_done_per_employee_per_month_aggregate: Requests_Done_Per_Employee_Per_Month_Aggregate;
  /** fetch data from the table: "requests_followup_task_count" */
  requests_followup_task_count: Array<Requests_Followup_Task_Count>;
  /** fetch aggregated fields from the table: "requests_followup_task_count" */
  requests_followup_task_count_aggregate: Requests_Followup_Task_Count_Aggregate;
  /** execute function "requests_followup_task_count_for_user" which returns "requests_followup_task_count" */
  requests_followup_task_count_for_user: Array<Requests_Followup_Task_Count>;
  /** execute function "requests_followup_task_count_for_user" and query aggregates on result of table type "requests_followup_task_count" */
  requests_followup_task_count_for_user_aggregate: Requests_Followup_Task_Count_Aggregate;
  /** fetch data from the table: "requests_followup_task_cout" */
  requests_followup_task_cout: Array<Requests_Followup_Task_Cout>;
  /** fetch aggregated fields from the table: "requests_followup_task_cout" */
  requests_followup_task_cout_aggregate: Requests_Followup_Task_Cout_Aggregate;
  /** fetch data from the table: "requests_new_task_count" */
  requests_new_task_count: Array<Requests_New_Task_Count>;
  /** fetch aggregated fields from the table: "requests_new_task_count" */
  requests_new_task_count_aggregate: Requests_New_Task_Count_Aggregate;
  /** fetch data from the table: "requests_new_task_cout" */
  requests_new_task_cout: Array<Requests_New_Task_Cout>;
  /** fetch aggregated fields from the table: "requests_new_task_cout" */
  requests_new_task_cout_aggregate: Requests_New_Task_Cout_Aggregate;
  /** fetch data from the table: "requests_per_case_type_per_month" */
  requests_per_case_type_per_month: Array<Requests_Per_Case_Type_Per_Month>;
  /** fetch aggregated fields from the table: "requests_per_case_type_per_month" */
  requests_per_case_type_per_month_aggregate: Requests_Per_Case_Type_Per_Month_Aggregate;
  /** fetch data from the table: "standard_photos" */
  standard_photos: Array<Standard_Photos>;
  /** fetch aggregated fields from the table: "standard_photos" */
  standard_photos_aggregate: Standard_Photos_Aggregate;
  /** fetch data from the table: "standard_photos" using primary key columns */
  standard_photos_by_pk?: Maybe<Standard_Photos>;
  /** fetch data from the table: "standard_terms" */
  standard_terms: Array<Standard_Terms>;
  /** fetch aggregated fields from the table: "standard_terms" */
  standard_terms_aggregate: Standard_Terms_Aggregate;
  /** fetch data from the table: "standard_terms" using primary key columns */
  standard_terms_by_pk?: Maybe<Standard_Terms>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


/** subscription root */
export type Subscription_RootAbsenceArgs = {
  distinct_on?: Maybe<Array<Absence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Absence_Order_By>>;
  where?: Maybe<Absence_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAbsence_AggregateArgs = {
  distinct_on?: Maybe<Array<Absence_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Absence_Order_By>>;
  where?: Maybe<Absence_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAbsence_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootAbsence_Search_ViewArgs = {
  distinct_on?: Maybe<Array<Absence_Search_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Absence_Search_View_Order_By>>;
  where?: Maybe<Absence_Search_View_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAbsence_Search_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Absence_Search_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Absence_Search_View_Order_By>>;
  where?: Maybe<Absence_Search_View_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAbsence_TypesArgs = {
  distinct_on?: Maybe<Array<Absence_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Absence_Types_Order_By>>;
  where?: Maybe<Absence_Types_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAbsence_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Absence_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Absence_Types_Order_By>>;
  where?: Maybe<Absence_Types_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAbsence_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootArchive_FilesArgs = {
  distinct_on?: Maybe<Array<Archive_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Archive_Files_Order_By>>;
  where?: Maybe<Archive_Files_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootArchive_Files_AggregateArgs = {
  distinct_on?: Maybe<Array<Archive_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Archive_Files_Order_By>>;
  where?: Maybe<Archive_Files_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootArchive_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCase_Doc_ChecklistsArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Checklists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Checklists_Order_By>>;
  where?: Maybe<Case_Doc_Checklists_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCase_Doc_Checklists_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Checklists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Checklists_Order_By>>;
  where?: Maybe<Case_Doc_Checklists_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCase_Doc_Checklists_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCase_Doc_EconomyArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Economy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Economy_Order_By>>;
  where?: Maybe<Case_Doc_Economy_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCase_Doc_Economy_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Economy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Economy_Order_By>>;
  where?: Maybe<Case_Doc_Economy_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCase_Doc_Economy_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCase_Doc_NotesArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Notes_Order_By>>;
  where?: Maybe<Case_Doc_Notes_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCase_Doc_Notes_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Notes_Order_By>>;
  where?: Maybe<Case_Doc_Notes_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCase_Doc_Notes_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCase_Doc_Order_ConfirmationsArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Order_Confirmations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Order_Confirmations_Order_By>>;
  where?: Maybe<Case_Doc_Order_Confirmations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCase_Doc_Order_Confirmations_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Order_Confirmations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Order_Confirmations_Order_By>>;
  where?: Maybe<Case_Doc_Order_Confirmations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCase_Doc_Order_Confirmations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCase_Doc_Quality_ControlsArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Quality_Controls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Quality_Controls_Order_By>>;
  where?: Maybe<Case_Doc_Quality_Controls_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCase_Doc_Quality_Controls_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Doc_Quality_Controls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Doc_Quality_Controls_Order_By>>;
  where?: Maybe<Case_Doc_Quality_Controls_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCase_Doc_Quality_Controls_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCase_FilesArgs = {
  distinct_on?: Maybe<Array<Case_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Files_Order_By>>;
  where?: Maybe<Case_Files_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCase_Files_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Files_Order_By>>;
  where?: Maybe<Case_Files_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCase_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCase_PhotosArgs = {
  distinct_on?: Maybe<Array<Case_Photos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Photos_Order_By>>;
  where?: Maybe<Case_Photos_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCase_Photos_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Photos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Photos_Order_By>>;
  where?: Maybe<Case_Photos_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCase_Photos_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCase_SuppliersArgs = {
  distinct_on?: Maybe<Array<Case_Suppliers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Suppliers_Order_By>>;
  where?: Maybe<Case_Suppliers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCase_Suppliers_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Suppliers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Suppliers_Order_By>>;
  where?: Maybe<Case_Suppliers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCase_Suppliers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCase_Time_EntriesArgs = {
  distinct_on?: Maybe<Array<Case_Time_Entries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Time_Entries_Order_By>>;
  where?: Maybe<Case_Time_Entries_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCase_Time_Entries_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Time_Entries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Time_Entries_Order_By>>;
  where?: Maybe<Case_Time_Entries_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCase_Time_Entries_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCase_TypesArgs = {
  distinct_on?: Maybe<Array<Case_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Types_Order_By>>;
  where?: Maybe<Case_Types_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCase_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Types_Order_By>>;
  where?: Maybe<Case_Types_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCase_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCasesArgs = {
  distinct_on?: Maybe<Array<Cases_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Order_By>>;
  where?: Maybe<Cases_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCases_AggregateArgs = {
  distinct_on?: Maybe<Array<Cases_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Order_By>>;
  where?: Maybe<Cases_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCases_Building_Inspection_Per_EmployeeArgs = {
  distinct_on?: Maybe<Array<Cases_Building_Inspection_Per_Employee_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Building_Inspection_Per_Employee_Order_By>>;
  where?: Maybe<Cases_Building_Inspection_Per_Employee_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCases_Building_Inspection_Per_Employee_AggregateArgs = {
  distinct_on?: Maybe<Array<Cases_Building_Inspection_Per_Employee_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Building_Inspection_Per_Employee_Order_By>>;
  where?: Maybe<Cases_Building_Inspection_Per_Employee_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCases_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCases_Case_Count_Per_EmployeeArgs = {
  distinct_on?: Maybe<Array<Cases_Case_Count_Per_Employee_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Case_Count_Per_Employee_Order_By>>;
  where?: Maybe<Cases_Case_Count_Per_Employee_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCases_Case_Count_Per_Employee_AggregateArgs = {
  distinct_on?: Maybe<Array<Cases_Case_Count_Per_Employee_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Case_Count_Per_Employee_Order_By>>;
  where?: Maybe<Cases_Case_Count_Per_Employee_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCases_Case_Type_CountArgs = {
  distinct_on?: Maybe<Array<Cases_Case_Type_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Case_Type_Count_Order_By>>;
  where?: Maybe<Cases_Case_Type_Count_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCases_Case_Type_Count_AggregateArgs = {
  distinct_on?: Maybe<Array<Cases_Case_Type_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Case_Type_Count_Order_By>>;
  where?: Maybe<Cases_Case_Type_Count_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCases_Created_Per_Employee_Per_MonthArgs = {
  distinct_on?: Maybe<Array<Cases_Created_Per_Employee_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Created_Per_Employee_Per_Month_Order_By>>;
  where?: Maybe<Cases_Created_Per_Employee_Per_Month_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCases_Created_Per_Employee_Per_Month_AggregateArgs = {
  distinct_on?: Maybe<Array<Cases_Created_Per_Employee_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Created_Per_Employee_Per_Month_Order_By>>;
  where?: Maybe<Cases_Created_Per_Employee_Per_Month_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCases_Per_Case_Type_Per_MonthArgs = {
  distinct_on?: Maybe<Array<Cases_Per_Case_Type_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Per_Case_Type_Per_Month_Order_By>>;
  where?: Maybe<Cases_Per_Case_Type_Per_Month_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCases_Per_Case_Type_Per_Month_AggregateArgs = {
  distinct_on?: Maybe<Array<Cases_Per_Case_Type_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cases_Per_Case_Type_Per_Month_Order_By>>;
  where?: Maybe<Cases_Per_Case_Type_Per_Month_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootChecklist_Item_ArrowsArgs = {
  distinct_on?: Maybe<Array<Checklist_Item_Arrows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Checklist_Item_Arrows_Order_By>>;
  where?: Maybe<Checklist_Item_Arrows_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootChecklist_Item_Arrows_AggregateArgs = {
  distinct_on?: Maybe<Array<Checklist_Item_Arrows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Checklist_Item_Arrows_Order_By>>;
  where?: Maybe<Checklist_Item_Arrows_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootChecklist_Item_Arrows_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootChecklist_ItemsArgs = {
  distinct_on?: Maybe<Array<Checklist_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Checklist_Items_Order_By>>;
  where?: Maybe<Checklist_Items_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootChecklist_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Checklist_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Checklist_Items_Order_By>>;
  where?: Maybe<Checklist_Items_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootChecklist_Items_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootConstruction_Plan_EntriesArgs = {
  distinct_on?: Maybe<Array<Construction_Plan_Entries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Construction_Plan_Entries_Order_By>>;
  where?: Maybe<Construction_Plan_Entries_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootConstruction_Plan_Entries_AggregateArgs = {
  distinct_on?: Maybe<Array<Construction_Plan_Entries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Construction_Plan_Entries_Order_By>>;
  where?: Maybe<Construction_Plan_Entries_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootConstruction_Plan_Entries_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootConstruction_PlansArgs = {
  distinct_on?: Maybe<Array<Construction_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Construction_Plans_Order_By>>;
  where?: Maybe<Construction_Plans_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootConstruction_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Construction_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Construction_Plans_Order_By>>;
  where?: Maybe<Construction_Plans_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootConstruction_Plans_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootContactsArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootContacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootContacts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootFilesArgs = {
  distinct_on?: Maybe<Array<Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Order_By>>;
  where?: Maybe<Files_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootFiles_AggregateArgs = {
  distinct_on?: Maybe<Array<Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Order_By>>;
  where?: Maybe<Files_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOrder_Confirmation_TasksArgs = {
  distinct_on?: Maybe<Array<Order_Confirmation_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Confirmation_Tasks_Order_By>>;
  where?: Maybe<Order_Confirmation_Tasks_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOrder_Confirmation_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Confirmation_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Confirmation_Tasks_Order_By>>;
  where?: Maybe<Order_Confirmation_Tasks_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOrder_Confirmation_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOrder_Confirmation_Template_TasksArgs = {
  distinct_on?: Maybe<Array<Order_Confirmation_Template_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Confirmation_Template_Tasks_Order_By>>;
  where?: Maybe<Order_Confirmation_Template_Tasks_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOrder_Confirmation_Template_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Confirmation_Template_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Confirmation_Template_Tasks_Order_By>>;
  where?: Maybe<Order_Confirmation_Template_Tasks_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOrder_Confirmation_Template_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOrder_Confirmation_TemplatesArgs = {
  distinct_on?: Maybe<Array<Order_Confirmation_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Confirmation_Templates_Order_By>>;
  where?: Maybe<Order_Confirmation_Templates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOrder_Confirmation_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Order_Confirmation_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Order_Confirmation_Templates_Order_By>>;
  where?: Maybe<Order_Confirmation_Templates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOrder_Confirmation_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootQuality_Control_Task_ArrowsArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Task_Arrows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Task_Arrows_Order_By>>;
  where?: Maybe<Quality_Control_Task_Arrows_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootQuality_Control_Task_Arrows_AggregateArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Task_Arrows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Task_Arrows_Order_By>>;
  where?: Maybe<Quality_Control_Task_Arrows_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootQuality_Control_Task_Arrows_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootQuality_Control_TasksArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Tasks_Order_By>>;
  where?: Maybe<Quality_Control_Tasks_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootQuality_Control_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Tasks_Order_By>>;
  where?: Maybe<Quality_Control_Tasks_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootQuality_Control_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootQuality_Control_Template_TasksArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Template_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Template_Tasks_Order_By>>;
  where?: Maybe<Quality_Control_Template_Tasks_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootQuality_Control_Template_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Template_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Template_Tasks_Order_By>>;
  where?: Maybe<Quality_Control_Template_Tasks_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootQuality_Control_Template_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootQuality_Control_TemplatesArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Templates_Order_By>>;
  where?: Maybe<Quality_Control_Templates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootQuality_Control_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Quality_Control_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Quality_Control_Templates_Order_By>>;
  where?: Maybe<Quality_Control_Templates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootQuality_Control_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootReminder_RecipientsArgs = {
  distinct_on?: Maybe<Array<Reminder_Recipients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reminder_Recipients_Order_By>>;
  where?: Maybe<Reminder_Recipients_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReminder_Recipients_AggregateArgs = {
  distinct_on?: Maybe<Array<Reminder_Recipients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reminder_Recipients_Order_By>>;
  where?: Maybe<Reminder_Recipients_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReminder_Recipients_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootRemindersArgs = {
  distinct_on?: Maybe<Array<Reminders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reminders_Order_By>>;
  where?: Maybe<Reminders_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReminders_AggregateArgs = {
  distinct_on?: Maybe<Array<Reminders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reminders_Order_By>>;
  where?: Maybe<Reminders_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReminders_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootRequest_NotesArgs = {
  distinct_on?: Maybe<Array<Request_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Request_Notes_Order_By>>;
  where?: Maybe<Request_Notes_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRequest_Notes_AggregateArgs = {
  distinct_on?: Maybe<Array<Request_Notes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Request_Notes_Order_By>>;
  where?: Maybe<Request_Notes_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRequest_Notes_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootRequestsArgs = {
  distinct_on?: Maybe<Array<Requests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Order_By>>;
  where?: Maybe<Requests_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRequests_AggregateArgs = {
  distinct_on?: Maybe<Array<Requests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Order_By>>;
  where?: Maybe<Requests_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRequests_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootRequests_Done_Per_Employee_Per_MonthArgs = {
  distinct_on?: Maybe<Array<Requests_Done_Per_Employee_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Done_Per_Employee_Per_Month_Order_By>>;
  where?: Maybe<Requests_Done_Per_Employee_Per_Month_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRequests_Done_Per_Employee_Per_Month_AggregateArgs = {
  distinct_on?: Maybe<Array<Requests_Done_Per_Employee_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Done_Per_Employee_Per_Month_Order_By>>;
  where?: Maybe<Requests_Done_Per_Employee_Per_Month_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRequests_Followup_Task_CountArgs = {
  distinct_on?: Maybe<Array<Requests_Followup_Task_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Followup_Task_Count_Order_By>>;
  where?: Maybe<Requests_Followup_Task_Count_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRequests_Followup_Task_Count_AggregateArgs = {
  distinct_on?: Maybe<Array<Requests_Followup_Task_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Followup_Task_Count_Order_By>>;
  where?: Maybe<Requests_Followup_Task_Count_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRequests_Followup_Task_Count_For_UserArgs = {
  args: Requests_Followup_Task_Count_For_User_Args;
  distinct_on?: Maybe<Array<Requests_Followup_Task_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Followup_Task_Count_Order_By>>;
  where?: Maybe<Requests_Followup_Task_Count_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRequests_Followup_Task_Count_For_User_AggregateArgs = {
  args: Requests_Followup_Task_Count_For_User_Args;
  distinct_on?: Maybe<Array<Requests_Followup_Task_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Followup_Task_Count_Order_By>>;
  where?: Maybe<Requests_Followup_Task_Count_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRequests_Followup_Task_CoutArgs = {
  distinct_on?: Maybe<Array<Requests_Followup_Task_Cout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Followup_Task_Cout_Order_By>>;
  where?: Maybe<Requests_Followup_Task_Cout_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRequests_Followup_Task_Cout_AggregateArgs = {
  distinct_on?: Maybe<Array<Requests_Followup_Task_Cout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Followup_Task_Cout_Order_By>>;
  where?: Maybe<Requests_Followup_Task_Cout_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRequests_New_Task_CountArgs = {
  distinct_on?: Maybe<Array<Requests_New_Task_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_New_Task_Count_Order_By>>;
  where?: Maybe<Requests_New_Task_Count_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRequests_New_Task_Count_AggregateArgs = {
  distinct_on?: Maybe<Array<Requests_New_Task_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_New_Task_Count_Order_By>>;
  where?: Maybe<Requests_New_Task_Count_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRequests_New_Task_CoutArgs = {
  distinct_on?: Maybe<Array<Requests_New_Task_Cout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_New_Task_Cout_Order_By>>;
  where?: Maybe<Requests_New_Task_Cout_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRequests_New_Task_Cout_AggregateArgs = {
  distinct_on?: Maybe<Array<Requests_New_Task_Cout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_New_Task_Cout_Order_By>>;
  where?: Maybe<Requests_New_Task_Cout_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRequests_Per_Case_Type_Per_MonthArgs = {
  distinct_on?: Maybe<Array<Requests_Per_Case_Type_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Per_Case_Type_Per_Month_Order_By>>;
  where?: Maybe<Requests_Per_Case_Type_Per_Month_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRequests_Per_Case_Type_Per_Month_AggregateArgs = {
  distinct_on?: Maybe<Array<Requests_Per_Case_Type_Per_Month_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Requests_Per_Case_Type_Per_Month_Order_By>>;
  where?: Maybe<Requests_Per_Case_Type_Per_Month_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStandard_PhotosArgs = {
  distinct_on?: Maybe<Array<Standard_Photos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Photos_Order_By>>;
  where?: Maybe<Standard_Photos_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStandard_Photos_AggregateArgs = {
  distinct_on?: Maybe<Array<Standard_Photos_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Photos_Order_By>>;
  where?: Maybe<Standard_Photos_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStandard_Photos_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootStandard_TermsArgs = {
  distinct_on?: Maybe<Array<Standard_Terms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Terms_Order_By>>;
  where?: Maybe<Standard_Terms_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStandard_Terms_AggregateArgs = {
  distinct_on?: Maybe<Array<Standard_Terms_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Standard_Terms_Order_By>>;
  where?: Maybe<Standard_Terms_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStandard_Terms_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  acronym?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  employee_number: Scalars['Int'];
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  last_name?: Maybe<Scalars['String']>;
  mail: Scalars['String'];
  mobile?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  reminders_via_email: Scalars['Boolean'];
  reminders_via_sms: Scalars['Boolean'];
  /** An object relationship */
  substitute?: Maybe<Users>;
  substitute_id?: Maybe<Scalars['uuid']>;
  token?: Maybe<Scalars['String']>;
  user_type: Scalars['String'];
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  avg?: Maybe<Users_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Users_Max_Order_By>;
  min?: Maybe<Users_Min_Order_By>;
  stddev?: Maybe<Users_Stddev_Order_By>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Order_By>;
  sum?: Maybe<Users_Sum_Order_By>;
  var_pop?: Maybe<Users_Var_Pop_Order_By>;
  var_samp?: Maybe<Users_Var_Samp_Order_By>;
  variance?: Maybe<Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  employee_number?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "users" */
export type Users_Avg_Order_By = {
  employee_number?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  acronym?: Maybe<String_Comparison_Exp>;
  active?: Maybe<Boolean_Comparison_Exp>;
  employee_number?: Maybe<Int_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  mail?: Maybe<String_Comparison_Exp>;
  mobile?: Maybe<String_Comparison_Exp>;
  password?: Maybe<String_Comparison_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  reminders_via_email?: Maybe<Boolean_Comparison_Exp>;
  reminders_via_sms?: Maybe<Boolean_Comparison_Exp>;
  substitute?: Maybe<Users_Bool_Exp>;
  substitute_id?: Maybe<Uuid_Comparison_Exp>;
  token?: Maybe<String_Comparison_Exp>;
  user_type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersIdKey = 'users_id_key',
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for incrementing integer column in table "users" */
export type Users_Inc_Input = {
  employee_number?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  acronym?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  employee_number?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  reminders_via_email?: Maybe<Scalars['Boolean']>;
  reminders_via_sms?: Maybe<Scalars['Boolean']>;
  substitute?: Maybe<Users_Obj_Rel_Insert_Input>;
  substitute_id?: Maybe<Scalars['uuid']>;
  token?: Maybe<Scalars['String']>;
  user_type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  acronym?: Maybe<Scalars['String']>;
  employee_number?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  substitute_id?: Maybe<Scalars['uuid']>;
  token?: Maybe<Scalars['String']>;
  user_type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  acronym?: Maybe<Order_By>;
  employee_number?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  mail?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  substitute_id?: Maybe<Order_By>;
  token?: Maybe<Order_By>;
  user_type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  acronym?: Maybe<Scalars['String']>;
  employee_number?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  substitute_id?: Maybe<Scalars['uuid']>;
  token?: Maybe<Scalars['String']>;
  user_type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  acronym?: Maybe<Order_By>;
  employee_number?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  mail?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  substitute_id?: Maybe<Order_By>;
  token?: Maybe<Order_By>;
  user_type?: Maybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** ordering options when selecting data from "users" */
export type Users_Order_By = {
  acronym?: Maybe<Order_By>;
  active?: Maybe<Order_By>;
  employee_number?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  mail?: Maybe<Order_By>;
  mobile?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  reminders_via_email?: Maybe<Order_By>;
  reminders_via_sms?: Maybe<Order_By>;
  substitute?: Maybe<Users_Order_By>;
  substitute_id?: Maybe<Order_By>;
  token?: Maybe<Order_By>;
  user_type?: Maybe<Order_By>;
};

/** primary key columns input for table: "users" */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Acronym = 'acronym',
  /** column name */
  Active = 'active',
  /** column name */
  EmployeeNumber = 'employee_number',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Mail = 'mail',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Password = 'password',
  /** column name */
  Phone = 'phone',
  /** column name */
  RemindersViaEmail = 'reminders_via_email',
  /** column name */
  RemindersViaSms = 'reminders_via_sms',
  /** column name */
  SubstituteId = 'substitute_id',
  /** column name */
  Token = 'token',
  /** column name */
  UserType = 'user_type'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  acronym?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  employee_number?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  reminders_via_email?: Maybe<Scalars['Boolean']>;
  reminders_via_sms?: Maybe<Scalars['Boolean']>;
  substitute_id?: Maybe<Scalars['uuid']>;
  token?: Maybe<Scalars['String']>;
  user_type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  employee_number?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "users" */
export type Users_Stddev_Order_By = {
  employee_number?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  employee_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "users" */
export type Users_Stddev_Pop_Order_By = {
  employee_number?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  employee_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "users" */
export type Users_Stddev_Samp_Order_By = {
  employee_number?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  employee_number?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "users" */
export type Users_Sum_Order_By = {
  employee_number?: Maybe<Order_By>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Acronym = 'acronym',
  /** column name */
  Active = 'active',
  /** column name */
  EmployeeNumber = 'employee_number',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Mail = 'mail',
  /** column name */
  Mobile = 'mobile',
  /** column name */
  Password = 'password',
  /** column name */
  Phone = 'phone',
  /** column name */
  RemindersViaEmail = 'reminders_via_email',
  /** column name */
  RemindersViaSms = 'reminders_via_sms',
  /** column name */
  SubstituteId = 'substitute_id',
  /** column name */
  Token = 'token',
  /** column name */
  UserType = 'user_type'
}

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  employee_number?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "users" */
export type Users_Var_Pop_Order_By = {
  employee_number?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  employee_number?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "users" */
export type Users_Var_Samp_Order_By = {
  employee_number?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  employee_number?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "users" */
export type Users_Variance_Order_By = {
  employee_number?: Maybe<Order_By>;
};


/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type GetAbsenceTypesQueryVariables = Exact<{
  orderBy: Absence_Types_Order_By;
  where?: Maybe<Absence_Types_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetAbsenceTypesQuery = (
  { __typename?: 'query_root' }
  & { absence_types: Array<(
    { __typename?: 'absence_types' }
    & Pick<Absence_Types, 'id' | 'label' | 'active'>
  )> }
);

export type List_Archive_FilesQueryVariables = Exact<{
  orderBy: Archive_Files_Order_By;
  where?: Maybe<Archive_Files_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type List_Archive_FilesQuery = (
  { __typename?: 'query_root' }
  & { archive_files: Array<(
    { __typename?: 'archive_files' }
    & Pick<Archive_Files, 'id' | 'file_id' | 'title'>
    & { file: (
      { __typename?: 'files' }
      & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
    ) }
  )> }
);

export type Insert_Archive_FileMutationVariables = Exact<{
  archive_files: Array<Archive_Files_Insert_Input>;
}>;


export type Insert_Archive_FileMutation = (
  { __typename?: 'mutation_root' }
  & { insert_archive_files?: Maybe<(
    { __typename?: 'archive_files_mutation_response' }
    & { returning: Array<(
      { __typename?: 'archive_files' }
      & Pick<Archive_Files, 'id' | 'file_id' | 'title'>
      & { file: (
        { __typename?: 'files' }
        & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
      ) }
    )> }
  )> }
);

export type Get_Archive_FilesQueryVariables = Exact<{
  fileId?: Maybe<Scalars['uuid']>;
}>;


export type Get_Archive_FilesQuery = (
  { __typename?: 'query_root' }
  & { archive_files: Array<(
    { __typename?: 'archive_files' }
    & Pick<Archive_Files, 'id' | 'file_id' | 'title'>
    & { file: (
      { __typename?: 'files' }
      & Pick<Files, 'id' | 'filename' | 'share_key' | 'created'>
    ) }
  )> }
);

export type Update_Archive_FileMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  archive_file: Archive_Files_Set_Input;
}>;


export type Update_Archive_FileMutation = (
  { __typename?: 'mutation_root' }
  & { update_archive_files?: Maybe<(
    { __typename?: 'archive_files_mutation_response' }
    & { returning: Array<(
      { __typename?: 'archive_files' }
      & Pick<Archive_Files, 'id' | 'file_id' | 'title'>
      & { file: (
        { __typename?: 'files' }
        & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
      ) }
    )> }
  )> }
);

export type Delete_Archive_FilesMutationVariables = Exact<{
  where: Archive_Files_Bool_Exp;
}>;


export type Delete_Archive_FilesMutation = (
  { __typename?: 'mutation_root' }
  & { delete_archive_files?: Maybe<(
    { __typename?: 'archive_files_mutation_response' }
    & Pick<Archive_Files_Mutation_Response, 'affected_rows'>
  )> }
);

export type Insert_Case_Doc_EconomyMutationVariables = Exact<{
  case_doc_economy: Case_Doc_Economy_Insert_Input;
}>;


export type Insert_Case_Doc_EconomyMutation = (
  { __typename?: 'mutation_root' }
  & { insert_case_doc_economy?: Maybe<(
    { __typename?: 'case_doc_economy_mutation_response' }
    & { returning: Array<(
      { __typename?: 'case_doc_economy' }
      & Pick<Case_Doc_Economy, 'id' | 'type' | 'case_id' | 'title' | 'text'>
    )> }
  )> }
);

export type Insert_Case_Doc_EconomysMutationVariables = Exact<{
  case_doc_economy: Array<Case_Doc_Economy_Insert_Input>;
}>;


export type Insert_Case_Doc_EconomysMutation = (
  { __typename?: 'mutation_root' }
  & { insert_case_doc_economy?: Maybe<(
    { __typename?: 'case_doc_economy_mutation_response' }
    & { returning: Array<(
      { __typename?: 'case_doc_economy' }
      & Pick<Case_Doc_Economy, 'id' | 'type' | 'case_id' | 'title' | 'text'>
    )> }
  )> }
);

export type Get_Case_Doc_EconomyQueryVariables = Exact<{
  economyId?: Maybe<Scalars['uuid']>;
}>;


export type Get_Case_Doc_EconomyQuery = (
  { __typename?: 'query_root' }
  & { case_doc_economy: Array<(
    { __typename?: 'case_doc_economy' }
    & Pick<Case_Doc_Economy, 'id' | 'case_id' | 'title' | 'text' | 'type' | 'document_date' | 'revision_date'>
  )> }
);

export type Update_Case_Doc_EconomyMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  case_doc_economy: Case_Doc_Economy_Set_Input;
}>;


export type Update_Case_Doc_EconomyMutation = (
  { __typename?: 'mutation_root' }
  & { update_case_doc_economy?: Maybe<(
    { __typename?: 'case_doc_economy_mutation_response' }
    & { returning: Array<(
      { __typename?: 'case_doc_economy' }
      & Pick<Case_Doc_Economy, 'id' | 'case_id' | 'title' | 'type' | 'text' | 'document_date' | 'revision_date'>
    )> }
  )> }
);

export type Insert_Case_Doc_NoteMutationVariables = Exact<{
  case_doc_note: Case_Doc_Notes_Insert_Input;
}>;


export type Insert_Case_Doc_NoteMutation = (
  { __typename?: 'mutation_root' }
  & { insert_case_doc_notes?: Maybe<(
    { __typename?: 'case_doc_notes_mutation_response' }
    & { returning: Array<(
      { __typename?: 'case_doc_notes' }
      & Pick<Case_Doc_Notes, 'id' | 'type' | 'case_id' | 'title' | 'text'>
    )> }
  )> }
);

export type Insert_Case_Doc_NotesMutationVariables = Exact<{
  case_doc_notes: Array<Case_Doc_Notes_Insert_Input>;
}>;


export type Insert_Case_Doc_NotesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_case_doc_notes?: Maybe<(
    { __typename?: 'case_doc_notes_mutation_response' }
    & { returning: Array<(
      { __typename?: 'case_doc_notes' }
      & Pick<Case_Doc_Notes, 'id' | 'type' | 'case_id' | 'title' | 'text'>
    )> }
  )> }
);

export type Get_Case_Doc_NotesQueryVariables = Exact<{
  caseNoteId?: Maybe<Scalars['uuid']>;
}>;


export type Get_Case_Doc_NotesQuery = (
  { __typename?: 'query_root' }
  & { case_doc_notes: Array<(
    { __typename?: 'case_doc_notes' }
    & Pick<Case_Doc_Notes, 'id' | 'case_id' | 'title' | 'text' | 'type' | 'document_date' | 'revision_date'>
  )> }
);

export type Update_Case_Doc_NoteMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  case_doc_note: Case_Doc_Notes_Set_Input;
}>;


export type Update_Case_Doc_NoteMutation = (
  { __typename?: 'mutation_root' }
  & { update_case_doc_notes?: Maybe<(
    { __typename?: 'case_doc_notes_mutation_response' }
    & { returning: Array<(
      { __typename?: 'case_doc_notes' }
      & Pick<Case_Doc_Notes, 'id' | 'case_id' | 'title' | 'type' | 'text' | 'document_date' | 'revision_date'>
    )> }
  )> }
);

export type Get_TimeQueryVariables = Exact<{
  timeId: Scalars['uuid'];
}>;


export type Get_TimeQuery = (
  { __typename?: 'query_root' }
  & { case_time_entries_by_pk?: Maybe<(
    { __typename?: 'case_time_entries' }
    & Pick<Case_Time_Entries, 'date' | 'description' | 'id' | 'time' | 'user_id'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'acronym' | 'first_name' | 'last_name' | 'mail'>
    ) }
  )> }
);

export type Insert_Case_TimeMutationVariables = Exact<{
  caseTime: Case_Time_Entries_Insert_Input;
}>;


export type Insert_Case_TimeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_case_time_entries?: Maybe<(
    { __typename?: 'case_time_entries_mutation_response' }
    & { returning: Array<(
      { __typename?: 'case_time_entries' }
      & Pick<Case_Time_Entries, 'date' | 'description' | 'id' | 'time' | 'user_id'>
      & { user: (
        { __typename?: 'users' }
        & Pick<Users, 'acronym' | 'first_name' | 'last_name' | 'mail'>
      ) }
    )> }
  )> }
);

export type Update_Case_TimeMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  caseTime: Case_Time_Entries_Set_Input;
}>;


export type Update_Case_TimeMutation = (
  { __typename?: 'mutation_root' }
  & { update_case_time_entries?: Maybe<(
    { __typename?: 'case_time_entries_mutation_response' }
    & { returning: Array<(
      { __typename?: 'case_time_entries' }
      & Pick<Case_Time_Entries, 'date' | 'description' | 'id' | 'time' | 'user_id'>
      & { user: (
        { __typename?: 'users' }
        & Pick<Users, 'acronym' | 'first_name' | 'last_name' | 'mail'>
      ) }
    )> }
  )> }
);

export type Case_Types_AgregateQueryVariables = Exact<{
  orderBy: Case_Types_Order_By;
  where?: Maybe<Case_Types_Bool_Exp>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type Case_Types_AgregateQuery = (
  { __typename?: 'query_root' }
  & { case_types_aggregate: (
    { __typename?: 'case_types_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'case_types_aggregate_fields' }
      & Pick<Case_Types_Aggregate_Fields, 'count'>
    )>, nodes: Array<(
      { __typename?: 'case_types' }
      & Pick<Case_Types, 'id' | 'name' | 'icon' | 'color' | 'price'>
    )> }
  ) }
);

export type Get_Case_TypesQueryVariables = Exact<{
  caseTypeId?: Maybe<Scalars['uuid']>;
}>;


export type Get_Case_TypesQuery = (
  { __typename?: 'query_root' }
  & { case_types: Array<(
    { __typename?: 'case_types' }
    & Pick<Case_Types, 'id' | 'name' | 'icon' | 'color' | 'price'>
  )> }
);

export type Case_Types_CheckQueryVariables = Exact<{
  name: Scalars['String'];
  icon: Scalars['String'];
  color: Scalars['String'];
}>;


export type Case_Types_CheckQuery = (
  { __typename?: 'query_root' }
  & { case_types: Array<(
    { __typename?: 'case_types' }
    & Pick<Case_Types, 'id'>
  )> }
);

export type Insert_Case_TypeMutationVariables = Exact<{
  caseType: Case_Types_Insert_Input;
}>;


export type Insert_Case_TypeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_case_types?: Maybe<(
    { __typename?: 'case_types_mutation_response' }
    & { returning: Array<(
      { __typename?: 'case_types' }
      & Pick<Case_Types, 'id' | 'name' | 'icon' | 'color' | 'price'>
    )> }
  )> }
);

export type Update_Case_TypeMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  caseType: Case_Types_Set_Input;
}>;


export type Update_Case_TypeMutation = (
  { __typename?: 'mutation_root' }
  & { update_case_types?: Maybe<(
    { __typename?: 'case_types_mutation_response' }
    & { returning: Array<(
      { __typename?: 'case_types' }
      & Pick<Case_Types, 'id' | 'name' | 'icon' | 'color' | 'price'>
    )> }
  )> }
);

export type List_CasesQueryVariables = Exact<{
  orderBy: Cases_Order_By;
  where?: Maybe<Cases_Bool_Exp>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type List_CasesQuery = (
  { __typename?: 'query_root' }
  & { cases_aggregate: (
    { __typename?: 'cases_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'cases_aggregate_fields' }
      & Pick<Cases_Aggregate_Fields, 'count'>
    )>, nodes: Array<(
      { __typename?: 'cases' }
      & Pick<Cases, 'id' | 'task' | 'description' | 'responsible_id' | 'builder_id' | 'secondary_builder_id' | 'changed' | 'closed' | 'address' | 'zip_code' | 'city' | 'date_of_inspection' | 'estimated_time' | 'other_address' | 'flag'>
      & { responsible?: Maybe<(
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'mail' | 'acronym' | 'first_name' | 'last_name' | 'phone' | 'mobile'>
      )>, builder?: Maybe<(
        { __typename?: 'contacts' }
        & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'company' | 'address' | 'zip_code' | 'city' | 'phone' | 'mobile' | 'mail'>
      )>, secondary_builder?: Maybe<(
        { __typename?: 'contacts' }
        & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'company' | 'address' | 'zip_code' | 'city' | 'phone' | 'mobile' | 'mail'>
      )>, case_type?: Maybe<(
        { __typename?: 'case_types' }
        & Pick<Case_Types, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type List_Cases_OpenQueryVariables = Exact<{
  orderBy: Cases_Order_By;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type List_Cases_OpenQuery = (
  { __typename?: 'query_root' }
  & { cases_aggregate: (
    { __typename?: 'cases_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'cases_aggregate_fields' }
      & Pick<Cases_Aggregate_Fields, 'count'>
    )>, nodes: Array<(
      { __typename?: 'cases' }
      & Pick<Cases, 'id' | 'task' | 'description' | 'responsible_id' | 'builder_id' | 'secondary_builder_id' | 'changed' | 'closed' | 'address' | 'zip_code' | 'city' | 'date_of_inspection' | 'estimated_time' | 'flag'>
      & { responsible?: Maybe<(
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'mail' | 'acronym' | 'first_name' | 'last_name' | 'phone' | 'mobile'>
      )>, builder?: Maybe<(
        { __typename?: 'contacts' }
        & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'company' | 'address' | 'zip_code' | 'city' | 'phone' | 'mobile' | 'mail' | 'synced'>
      )>, secondary_builder?: Maybe<(
        { __typename?: 'contacts' }
        & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'company' | 'address' | 'zip_code' | 'city' | 'phone' | 'mobile' | 'mail'>
      )>, case_type?: Maybe<(
        { __typename?: 'case_types' }
        & Pick<Case_Types, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type UpdateCaseInspectionDateMutationVariables = Exact<{
  id: Scalars['uuid'];
  date?: Maybe<Scalars['String']>;
}>;


export type UpdateCaseInspectionDateMutation = (
  { __typename?: 'mutation_root' }
  & { update_cases_by_pk?: Maybe<(
    { __typename?: 'cases' }
    & Pick<Cases, 'date_of_inspection'>
  )> }
);

export type Update_CaseMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  case: Cases_Set_Input;
}>;


export type Update_CaseMutation = (
  { __typename?: 'mutation_root' }
  & { update_cases?: Maybe<(
    { __typename?: 'cases_mutation_response' }
    & { returning: Array<(
      { __typename?: 'cases' }
      & Pick<Cases, 'id' | 'task' | 'description' | 'responsible_id' | 'builder_id' | 'secondary_builder_id' | 'company_id' | 'changed' | 'closed' | 'address' | 'zip_code' | 'city' | 'date_of_inspection' | 'estimated_time' | 'important_note' | 'lat' | 'lng' | 'other_address' | 'other_note' | 'toilet' | 'usefull_note' | 'flag' | 'construction_plan_id'>
      & { responsible?: Maybe<(
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'mail' | 'acronym' | 'first_name' | 'last_name' | 'phone' | 'mobile'>
      )>, builder?: Maybe<(
        { __typename?: 'contacts' }
        & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'company' | 'address' | 'zip_code' | 'city' | 'phone' | 'mobile' | 'mail' | 'synced'>
      )>, secondary_builder?: Maybe<(
        { __typename?: 'contacts' }
        & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'company' | 'address' | 'zip_code' | 'city' | 'phone' | 'mobile' | 'mail'>
      )>, company?: Maybe<(
        { __typename?: 'contacts' }
        & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'company' | 'address' | 'zip_code' | 'city' | 'phone' | 'mobile' | 'mail'>
      )>, case_type?: Maybe<(
        { __typename?: 'case_types' }
        & Pick<Case_Types, 'id' | 'name'>
      )>, suppliers: Array<(
        { __typename?: 'case_suppliers' }
        & { contact: (
          { __typename?: 'contacts' }
          & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'company' | 'address' | 'zip_code' | 'city' | 'phone' | 'mobile' | 'mail'>
        ) }
      )>, construction_plan?: Maybe<(
        { __typename?: 'construction_plans' }
        & Pick<Construction_Plans, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type Insert_CaseMutationVariables = Exact<{
  case: Cases_Insert_Input;
}>;


export type Insert_CaseMutation = (
  { __typename?: 'mutation_root' }
  & { insert_cases?: Maybe<(
    { __typename?: 'cases_mutation_response' }
    & { returning: Array<(
      { __typename?: 'cases' }
      & Pick<Cases, 'id' | 'task' | 'description' | 'responsible_id' | 'builder_id' | 'secondary_builder_id' | 'company_id' | 'changed' | 'closed' | 'address' | 'zip_code' | 'city' | 'flag' | 'date_of_inspection' | 'estimated_time' | 'important_note' | 'lat' | 'lng' | 'other_address' | 'other_note' | 'toilet' | 'usefull_note' | 'construction_plan_id'>
      & { responsible?: Maybe<(
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'mail' | 'acronym' | 'first_name' | 'last_name' | 'phone' | 'mobile'>
      )>, builder?: Maybe<(
        { __typename?: 'contacts' }
        & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'company' | 'address' | 'zip_code' | 'city' | 'phone' | 'mobile' | 'mail' | 'synced'>
      )>, secondary_builder?: Maybe<(
        { __typename?: 'contacts' }
        & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'company' | 'address' | 'zip_code' | 'city' | 'phone' | 'mobile' | 'mail'>
      )>, company?: Maybe<(
        { __typename?: 'contacts' }
        & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'company' | 'address' | 'zip_code' | 'city' | 'phone' | 'mobile' | 'mail'>
      )>, case_type?: Maybe<(
        { __typename?: 'case_types' }
        & Pick<Case_Types, 'id' | 'name'>
      )>, suppliers: Array<(
        { __typename?: 'case_suppliers' }
        & { contact: (
          { __typename?: 'contacts' }
          & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'company' | 'address' | 'zip_code' | 'city' | 'phone' | 'mobile' | 'mail'>
        ) }
      )>, construction_plan?: Maybe<(
        { __typename?: 'construction_plans' }
        & Pick<Construction_Plans, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type Get_CaseQueryVariables = Exact<{
  caseId?: Maybe<Scalars['uuid']>;
}>;


export type Get_CaseQuery = (
  { __typename?: 'query_root' }
  & { cases: Array<(
    { __typename?: 'cases' }
    & Pick<Cases, 'id' | 'task' | 'description' | 'responsible_id' | 'builder_id' | 'secondary_builder_id' | 'flag' | 'company_id' | 'changed' | 'closed' | 'address' | 'zip_code' | 'city' | 'date_of_inspection' | 'estimated_time' | 'important_note' | 'lat' | 'lng' | 'other_address' | 'other_note' | 'toilet' | 'usefull_note' | 'construction_plan_id'>
    & { responsible?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'mail' | 'acronym' | 'first_name' | 'last_name' | 'phone' | 'mobile'>
    )>, builder?: Maybe<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'company' | 'address' | 'zip_code' | 'city' | 'phone' | 'mobile' | 'mail' | 'synced'>
    )>, secondary_builder?: Maybe<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'company' | 'address' | 'zip_code' | 'city' | 'phone' | 'mobile' | 'mail'>
    )>, company?: Maybe<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'company' | 'address' | 'zip_code' | 'city' | 'phone' | 'mobile' | 'mail'>
    )>, case_type?: Maybe<(
      { __typename?: 'case_types' }
      & Pick<Case_Types, 'id' | 'name'>
    )>, suppliers: Array<(
      { __typename?: 'case_suppliers' }
      & { contact: (
        { __typename?: 'contacts' }
        & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'company' | 'address' | 'zip_code' | 'city' | 'phone' | 'mobile' | 'mail'>
      ) }
    )>, construction_plan?: Maybe<(
      { __typename?: 'construction_plans' }
      & Pick<Construction_Plans, 'id' | 'name'>
    )> }
  )> }
);

export type Insert_Case_SupplierMutationVariables = Exact<{
  case_suppliers: Array<Case_Suppliers_Insert_Input>;
}>;


export type Insert_Case_SupplierMutation = (
  { __typename?: 'mutation_root' }
  & { insert_case_suppliers?: Maybe<(
    { __typename?: 'case_suppliers_mutation_response' }
    & { returning: Array<(
      { __typename?: 'case_suppliers' }
      & Pick<Case_Suppliers, 'id' | 'case_id' | 'contact_id'>
    )> }
  )> }
);

export type Delete_Case_SuppliersMutationVariables = Exact<{
  where: Case_Suppliers_Bool_Exp;
}>;


export type Delete_Case_SuppliersMutation = (
  { __typename?: 'mutation_root' }
  & { delete_case_suppliers?: Maybe<(
    { __typename?: 'case_suppliers_mutation_response' }
    & Pick<Case_Suppliers_Mutation_Response, 'affected_rows'>
  )> }
);

export type List_Case_PhotosQueryVariables = Exact<{
  orderBy: Case_Photos_Order_By;
  where?: Maybe<Case_Photos_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type List_Case_PhotosQuery = (
  { __typename?: 'query_root' }
  & { case_photos: Array<(
    { __typename?: 'case_photos' }
    & Pick<Case_Photos, 'id' | 'file_id' | 'case_id' | 'title'>
    & { file: (
      { __typename?: 'files' }
      & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
    ) }
  )> }
);

export type List_Case_Photos_And_Case_TypeQueryVariables = Exact<{
  orderBy: Case_Photos_Order_By;
  caseId: Scalars['uuid'];
  limit?: Maybe<Scalars['Int']>;
}>;


export type List_Case_Photos_And_Case_TypeQuery = (
  { __typename?: 'query_root' }
  & { case_photos: Array<(
    { __typename?: 'case_photos' }
    & Pick<Case_Photos, 'id' | 'file_id' | 'case_id' | 'title'>
    & { file: (
      { __typename?: 'files' }
      & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
    ) }
  )>, cases_by_pk?: Maybe<(
    { __typename?: 'cases' }
    & Pick<Cases, 'case_type_id'>
  )> }
);

export type Insert_Case_PhotoMutationVariables = Exact<{
  case_photos: Array<Case_Photos_Insert_Input>;
}>;


export type Insert_Case_PhotoMutation = (
  { __typename?: 'mutation_root' }
  & { insert_case_photos?: Maybe<(
    { __typename?: 'case_photos_mutation_response' }
    & { returning: Array<(
      { __typename?: 'case_photos' }
      & Pick<Case_Photos, 'id' | 'file_id' | 'case_id' | 'title'>
      & { file: (
        { __typename?: 'files' }
        & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
      ) }
    )> }
  )> }
);

export type Get_Case_PhotoQueryVariables = Exact<{
  photoId?: Maybe<Scalars['uuid']>;
}>;


export type Get_Case_PhotoQuery = (
  { __typename?: 'query_root' }
  & { case_photos: Array<(
    { __typename?: 'case_photos' }
    & Pick<Case_Photos, 'id' | 'file_id' | 'case_id' | 'title'>
    & { file: (
      { __typename?: 'files' }
      & Pick<Files, 'id' | 'filename' | 'share_key' | 'created'>
    ) }
  )> }
);

export type Update_Case_PhotoMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  case_photo: Case_Photos_Set_Input;
}>;


export type Update_Case_PhotoMutation = (
  { __typename?: 'mutation_root' }
  & { update_case_photos?: Maybe<(
    { __typename?: 'case_photos_mutation_response' }
    & { returning: Array<(
      { __typename?: 'case_photos' }
      & Pick<Case_Photos, 'id' | 'file_id' | 'case_id' | 'title'>
      & { file: (
        { __typename?: 'files' }
        & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
      ) }
    )> }
  )> }
);

export type Update_FileMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  file: Files_Set_Input;
}>;


export type Update_FileMutation = (
  { __typename?: 'mutation_root' }
  & { update_files?: Maybe<(
    { __typename?: 'files_mutation_response' }
    & { returning: Array<(
      { __typename?: 'files' }
      & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
    )> }
  )> }
);

export type List_Case_FilesQueryVariables = Exact<{
  orderBy: Case_Files_Order_By;
  where?: Maybe<Case_Files_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type List_Case_FilesQuery = (
  { __typename?: 'query_root' }
  & { case_files: Array<(
    { __typename?: 'case_files' }
    & Pick<Case_Files, 'id' | 'file_id' | 'case_id' | 'title'>
    & { file: (
      { __typename?: 'files' }
      & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
    ) }
  )> }
);

export type Insert_Case_FileMutationVariables = Exact<{
  case_files: Array<Case_Files_Insert_Input>;
}>;


export type Insert_Case_FileMutation = (
  { __typename?: 'mutation_root' }
  & { insert_case_files?: Maybe<(
    { __typename?: 'case_files_mutation_response' }
    & { returning: Array<(
      { __typename?: 'case_files' }
      & Pick<Case_Files, 'id' | 'file_id' | 'case_id' | 'title'>
      & { file: (
        { __typename?: 'files' }
        & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
      ) }
    )> }
  )> }
);

export type Get_Case_FileQueryVariables = Exact<{
  fileId?: Maybe<Scalars['uuid']>;
}>;


export type Get_Case_FileQuery = (
  { __typename?: 'query_root' }
  & { case_files: Array<(
    { __typename?: 'case_files' }
    & Pick<Case_Files, 'id' | 'file_id' | 'case_id' | 'title'>
    & { file: (
      { __typename?: 'files' }
      & Pick<Files, 'id' | 'filename' | 'share_key' | 'created'>
    ) }
  )> }
);

export type Update_Case_FileMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  case_file: Case_Files_Set_Input;
}>;


export type Update_Case_FileMutation = (
  { __typename?: 'mutation_root' }
  & { update_case_files?: Maybe<(
    { __typename?: 'case_files_mutation_response' }
    & { returning: Array<(
      { __typename?: 'case_files' }
      & Pick<Case_Files, 'id' | 'file_id' | 'case_id' | 'title'>
      & { file: (
        { __typename?: 'files' }
        & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
      ) }
    )> }
  )> }
);

export type List_Case_DocumentsQueryVariables = Exact<{
  case_id: Scalars['uuid'];
}>;


export type List_Case_DocumentsQuery = (
  { __typename?: 'query_root' }
  & { case_doc_notes: Array<(
    { __typename?: 'case_doc_notes' }
    & Pick<Case_Doc_Notes, 'id' | 'changed' | 'created' | 'type' | 'title' | 'share_key'>
    & { user?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'acronym'>
    )> }
  )>, case_doc_order_confirmations: Array<(
    { __typename?: 'case_doc_order_confirmations' }
    & Pick<Case_Doc_Order_Confirmations, 'id' | 'changed' | 'created' | 'type' | 'title' | 'share_key'>
    & { user?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'acronym'>
    )> }
  )>, case_doc_quality_controls: Array<(
    { __typename?: 'case_doc_quality_controls' }
    & Pick<Case_Doc_Quality_Controls, 'id' | 'changed' | 'created' | 'type' | 'title' | 'share_key'>
    & { user?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'acronym'>
    )> }
  )>, case_doc_checklists: Array<(
    { __typename?: 'case_doc_checklists' }
    & Pick<Case_Doc_Checklists, 'id' | 'changed' | 'created' | 'type' | 'title' | 'share_key'>
    & { user?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'acronym'>
    )> }
  )>, case_doc_economy: Array<(
    { __typename?: 'case_doc_economy' }
    & Pick<Case_Doc_Economy, 'id' | 'changed' | 'created' | 'type' | 'title' | 'share_key'>
    & { user?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'acronym'>
    )> }
  )> }
);

export type List_Cases_CordsQueryVariables = Exact<{
  where?: Maybe<Cases_Bool_Exp>;
}>;


export type List_Cases_CordsQuery = (
  { __typename?: 'query_root' }
  & { cases: Array<(
    { __typename?: 'cases' }
    & Pick<Cases, 'id' | 'lat' | 'lng' | 'case_type_id'>
  )> }
);

export type Delete_CasesMutationVariables = Exact<{
  where: Cases_Bool_Exp;
}>;


export type Delete_CasesMutation = (
  { __typename?: 'mutation_root' }
  & { update_cases?: Maybe<(
    { __typename?: 'cases_mutation_response' }
    & Pick<Cases_Mutation_Response, 'affected_rows'>
  )> }
);

export type List_Case_Doc_ChecklistsQueryVariables = Exact<{
  orderBy: Case_Doc_Checklists_Order_By;
  where?: Maybe<Case_Doc_Checklists_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type List_Case_Doc_ChecklistsQuery = (
  { __typename?: 'query_root' }
  & { case_doc_checklists: Array<(
    { __typename?: 'case_doc_checklists' }
    & Pick<Case_Doc_Checklists, 'changed' | 'created' | 'id' | 'title' | 'type'>
  )> }
);

export type Get_ChecklistQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Get_ChecklistQuery = (
  { __typename?: 'query_root' }
  & { case_doc_checklists_by_pk?: Maybe<(
    { __typename?: 'case_doc_checklists' }
    & Pick<Case_Doc_Checklists, 'changed' | 'created' | 'document_date' | 'revision_date' | 'id' | 'share_key' | 'title' | 'file_id' | 'type' | 'introduction' | 'closing'>
    & { file?: Maybe<(
      { __typename?: 'files' }
      & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
    )>, case: (
      { __typename?: 'cases' }
      & Pick<Cases, 'address' | 'builder_id' | 'city' | 'date_of_inspection' | 'other_address' | 'responsible_id' | 'zip_code'>
      & { builder?: Maybe<(
        { __typename?: 'contacts' }
        & Pick<Contacts, 'address' | 'city' | 'company' | 'first_name' | 'id' | 'last_name' | 'mail' | 'mobile' | 'phone' | 'zip_code'>
      )>, responsible?: Maybe<(
        { __typename?: 'users' }
        & Pick<Users, 'acronym' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'phone'>
      )> }
    ), items: Array<(
      { __typename?: 'checklist_items' }
      & Pick<Checklist_Items, 'comment' | 'completed' | 'description' | 'end_date' | 'id' | 'order' | 'placement' | 'price' | 'start_date' | 'ue' | 'x' | 'y' | 'file_id'>
      & { file?: Maybe<(
        { __typename?: 'files' }
        & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
      )>, arrows: Array<(
        { __typename?: 'checklist_item_arrows' }
        & Pick<Checklist_Item_Arrows, 'id' | 'order' | 'item_id' | 'angle' | 'x' | 'y'>
      )> }
    )> }
  )> }
);

export type Insert_Case_Doc_ChecklistsMutationVariables = Exact<{
  checklist: Case_Doc_Checklists_Insert_Input;
}>;


export type Insert_Case_Doc_ChecklistsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_case_doc_checklists?: Maybe<(
    { __typename?: 'case_doc_checklists_mutation_response' }
    & { returning: Array<(
      { __typename?: 'case_doc_checklists' }
      & Pick<Case_Doc_Checklists, 'changed' | 'created' | 'document_date' | 'revision_date' | 'id' | 'type' | 'share_key' | 'title' | 'file_id' | 'introduction' | 'closing'>
      & { file?: Maybe<(
        { __typename?: 'files' }
        & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
      )>, case: (
        { __typename?: 'cases' }
        & Pick<Cases, 'address' | 'city' | 'date_of_inspection' | 'other_address' | 'zip_code'>
        & { builder?: Maybe<(
          { __typename?: 'contacts' }
          & Pick<Contacts, 'address' | 'city' | 'company' | 'first_name' | 'id' | 'last_name' | 'mail' | 'mobile' | 'phone' | 'zip_code'>
        )>, responsible?: Maybe<(
          { __typename?: 'users' }
          & Pick<Users, 'acronym' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'phone'>
        )> }
      ), items: Array<(
        { __typename?: 'checklist_items' }
        & Pick<Checklist_Items, 'comment' | 'completed' | 'description' | 'end_date' | 'id' | 'order' | 'placement' | 'price' | 'start_date' | 'ue' | 'x' | 'y' | 'file_id'>
        & { file?: Maybe<(
          { __typename?: 'files' }
          & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
        )>, arrows: Array<(
          { __typename?: 'checklist_item_arrows' }
          & Pick<Checklist_Item_Arrows, 'id' | 'order' | 'item_id' | 'angle' | 'x' | 'y'>
        )> }
      )> }
    )> }
  )> }
);

export type Update_Case_Doc_ChecklistsMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  checklist: Case_Doc_Checklists_Set_Input;
}>;


export type Update_Case_Doc_ChecklistsMutation = (
  { __typename?: 'mutation_root' }
  & { update_case_doc_checklists?: Maybe<(
    { __typename?: 'case_doc_checklists_mutation_response' }
    & { returning: Array<(
      { __typename?: 'case_doc_checklists' }
      & Pick<Case_Doc_Checklists, 'changed' | 'created' | 'document_date' | 'revision_date' | 'type' | 'id' | 'share_key' | 'file_id' | 'introduction' | 'closing' | 'title'>
      & { file?: Maybe<(
        { __typename?: 'files' }
        & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
      )>, case: (
        { __typename?: 'cases' }
        & Pick<Cases, 'address' | 'city' | 'date_of_inspection' | 'other_address' | 'zip_code'>
        & { builder?: Maybe<(
          { __typename?: 'contacts' }
          & Pick<Contacts, 'address' | 'city' | 'company' | 'first_name' | 'id' | 'last_name' | 'mail' | 'mobile' | 'phone' | 'zip_code'>
        )>, responsible?: Maybe<(
          { __typename?: 'users' }
          & Pick<Users, 'acronym' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'phone'>
        )> }
      ), items: Array<(
        { __typename?: 'checklist_items' }
        & Pick<Checklist_Items, 'comment' | 'completed' | 'description' | 'end_date' | 'id' | 'order' | 'placement' | 'price' | 'start_date' | 'ue' | 'x' | 'y' | 'file_id'>
        & { file?: Maybe<(
          { __typename?: 'files' }
          & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
        )>, arrows: Array<(
          { __typename?: 'checklist_item_arrows' }
          & Pick<Checklist_Item_Arrows, 'id' | 'item_id' | 'order' | 'angle' | 'x' | 'y'>
        )> }
      )> }
    )> }
  )> }
);

export type Insert_Case_Doc_Checklists_TaskMutationVariables = Exact<{
  items: Array<Checklist_Items_Insert_Input>;
}>;


export type Insert_Case_Doc_Checklists_TaskMutation = (
  { __typename?: 'mutation_root' }
  & { insert_checklist_items?: Maybe<(
    { __typename?: 'checklist_items_mutation_response' }
    & { returning: Array<(
      { __typename?: 'checklist_items' }
      & Pick<Checklist_Items, 'comment' | 'completed' | 'description' | 'end_date' | 'id' | 'order' | 'placement' | 'price' | 'start_date' | 'ue' | 'x' | 'y' | 'file_id'>
    )> }
  )> }
);

export type Update_Case_Doc_Checklists_TaskMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  item: Checklist_Items_Set_Input;
}>;


export type Update_Case_Doc_Checklists_TaskMutation = (
  { __typename?: 'mutation_root' }
  & { update_checklist_items?: Maybe<(
    { __typename?: 'checklist_items_mutation_response' }
    & { returning: Array<(
      { __typename?: 'checklist_items' }
      & Pick<Checklist_Items, 'comment' | 'completed' | 'description' | 'end_date' | 'id' | 'order' | 'placement' | 'price' | 'start_date' | 'ue' | 'x' | 'y' | 'file_id'>
      & { file?: Maybe<(
        { __typename?: 'files' }
        & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
      )> }
    )> }
  )> }
);

export type Delete_Checklist_ItemsMutationVariables = Exact<{
  where: Checklist_Items_Bool_Exp;
}>;


export type Delete_Checklist_ItemsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_checklist_items?: Maybe<(
    { __typename?: 'checklist_items_mutation_response' }
    & Pick<Checklist_Items_Mutation_Response, 'affected_rows'>
  )> }
);

export type Insert_Checklist_Item_ArrowsMutationVariables = Exact<{
  itemArrows: Array<Checklist_Item_Arrows_Insert_Input>;
}>;


export type Insert_Checklist_Item_ArrowsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_checklist_item_arrows?: Maybe<(
    { __typename?: 'checklist_item_arrows_mutation_response' }
    & { returning: Array<(
      { __typename?: 'checklist_item_arrows' }
      & Pick<Checklist_Item_Arrows, 'id' | 'order' | 'item_id' | 'angle' | 'x' | 'y'>
    )> }
  )> }
);

export type Update_Checklist_Item_ArrowsMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  itemArrow: Checklist_Item_Arrows_Set_Input;
}>;


export type Update_Checklist_Item_ArrowsMutation = (
  { __typename?: 'mutation_root' }
  & { update_checklist_item_arrows?: Maybe<(
    { __typename?: 'checklist_item_arrows_mutation_response' }
    & { returning: Array<(
      { __typename?: 'checklist_item_arrows' }
      & Pick<Checklist_Item_Arrows, 'id' | 'order' | 'item_id' | 'angle' | 'x' | 'y'>
    )> }
  )> }
);

export type Delete_Checklist_Item_ArrowsMutationVariables = Exact<{
  where: Checklist_Item_Arrows_Bool_Exp;
}>;


export type Delete_Checklist_Item_ArrowsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_checklist_item_arrows?: Maybe<(
    { __typename?: 'checklist_item_arrows_mutation_response' }
    & Pick<Checklist_Item_Arrows_Mutation_Response, 'affected_rows'>
  )> }
);

export type GetConstructionPlansQueryVariables = Exact<{
  orderBy: Construction_Plans_Order_By;
  where?: Maybe<Construction_Plans_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetConstructionPlansQuery = (
  { __typename?: 'query_root' }
  & { construction_plans: Array<(
    { __typename?: 'construction_plans' }
    & Pick<Construction_Plans, 'id' | 'name'>
  )> }
);

export type List_ContactsQueryVariables = Exact<{
  orderBy: Contacts_Order_By;
  where?: Maybe<Contacts_Bool_Exp>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type List_ContactsQuery = (
  { __typename?: 'query_root' }
  & { contacts_aggregate: (
    { __typename?: 'contacts_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'contacts_aggregate_fields' }
      & Pick<Contacts_Aggregate_Fields, 'count'>
    )>, nodes: Array<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'company' | 'address' | 'zip_code' | 'city' | 'phone' | 'mobile' | 'mail' | 'type'>
    )> }
  ) }
);

export type Get_ContactQueryVariables = Exact<{
  mobile: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
}>;


export type Get_ContactQuery = (
  { __typename?: 'query_root' }
  & { contacts: Array<(
    { __typename?: 'contacts' }
    & Pick<Contacts, 'id' | 'address' | 'city' | 'company' | 'mail' | 'first_name' | 'last_name' | 'mobile' | 'phone' | 'zip_code'>
  )> }
);

export type Get_ContactsQueryVariables = Exact<{
  contactId?: Maybe<Scalars['uuid']>;
}>;


export type Get_ContactsQuery = (
  { __typename?: 'query_root' }
  & { contacts: Array<(
    { __typename?: 'contacts' }
    & Pick<Contacts, 'id' | 'address' | 'city' | 'company' | 'mail' | 'first_name' | 'last_name' | 'mobile' | 'phone' | 'zip_code' | 'type'>
  )> }
);

export type Insert_ContactMutationVariables = Exact<{
  contact: Contacts_Insert_Input;
}>;


export type Insert_ContactMutation = (
  { __typename?: 'mutation_root' }
  & { insert_contacts?: Maybe<(
    { __typename?: 'contacts_mutation_response' }
    & { returning: Array<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id' | 'address' | 'city' | 'company' | 'mail' | 'first_name' | 'last_name' | 'mobile' | 'phone' | 'zip_code'>
    )> }
  )> }
);

export type Update_ContactMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  contact: Contacts_Set_Input;
}>;


export type Update_ContactMutation = (
  { __typename?: 'mutation_root' }
  & { update_contacts?: Maybe<(
    { __typename?: 'contacts_mutation_response' }
    & { returning: Array<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id' | 'address' | 'city' | 'company' | 'mail' | 'first_name' | 'last_name' | 'mobile' | 'phone' | 'zip_code' | 'type'>
    )> }
  )> }
);

export type List_Order_Confirmation_TemplatesQueryVariables = Exact<{
  orderBy: Order_Confirmation_Templates_Order_By;
  where?: Maybe<Order_Confirmation_Templates_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type List_Order_Confirmation_TemplatesQuery = (
  { __typename?: 'query_root' }
  & { order_confirmation_templates: Array<(
    { __typename?: 'order_confirmation_templates' }
    & Pick<Order_Confirmation_Templates, 'changed' | 'created' | 'description' | 'id' | 'other_terms' | 'template_title' | 'title'>
  )> }
);

export type Get_Order_Confirmation_TemplatesQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Get_Order_Confirmation_TemplatesQuery = (
  { __typename?: 'query_root' }
  & { order_confirmation_templates_by_pk?: Maybe<(
    { __typename?: 'order_confirmation_templates' }
    & Pick<Order_Confirmation_Templates, 'changed' | 'created' | 'description' | 'id' | 'other_terms' | 'template_title' | 'title'>
    & { tasks: Array<(
      { __typename?: 'order_confirmation_template_tasks' }
      & Pick<Order_Confirmation_Template_Tasks, 'description' | 'id' | 'order' | 'price' | 'title'>
    )> }
  )> }
);

export type Get_Order_Confirmation_Template_With_CaseQueryVariables = Exact<{
  id: Scalars['uuid'];
  caseId: Scalars['uuid'];
}>;


export type Get_Order_Confirmation_Template_With_CaseQuery = (
  { __typename?: 'query_root' }
  & { order_confirmation_templates_by_pk?: Maybe<(
    { __typename?: 'order_confirmation_templates' }
    & Pick<Order_Confirmation_Templates, 'changed' | 'created' | 'description' | 'id' | 'other_terms' | 'template_title' | 'title'>
    & { tasks: Array<(
      { __typename?: 'order_confirmation_template_tasks' }
      & Pick<Order_Confirmation_Template_Tasks, 'description' | 'id' | 'order' | 'price' | 'title'>
    )> }
  )>, cases_by_pk?: Maybe<(
    { __typename?: 'cases' }
    & Pick<Cases, 'address' | 'builder_id' | 'city' | 'date_of_inspection' | 'other_address' | 'responsible_id' | 'zip_code'>
    & { builder?: Maybe<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'address' | 'city' | 'company' | 'first_name' | 'id' | 'last_name' | 'mail' | 'mobile' | 'phone' | 'zip_code'>
    )>, responsible?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'acronym' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'phone'>
    )> }
  )> }
);

export type Insert_Order_Confirmation_TemplateMutationVariables = Exact<{
  orderConfirmationTemplate: Order_Confirmation_Templates_Insert_Input;
}>;


export type Insert_Order_Confirmation_TemplateMutation = (
  { __typename?: 'mutation_root' }
  & { insert_order_confirmation_templates?: Maybe<(
    { __typename?: 'order_confirmation_templates_mutation_response' }
    & { returning: Array<(
      { __typename?: 'order_confirmation_templates' }
      & Pick<Order_Confirmation_Templates, 'changed' | 'created' | 'description' | 'id' | 'other_terms' | 'template_title' | 'title'>
      & { tasks: Array<(
        { __typename?: 'order_confirmation_template_tasks' }
        & Pick<Order_Confirmation_Template_Tasks, 'description' | 'id' | 'order' | 'price' | 'title'>
      )> }
    )> }
  )> }
);

export type Update_Order_Confirmation_TemplateMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  orderConfirmationTemplate: Order_Confirmation_Templates_Set_Input;
}>;


export type Update_Order_Confirmation_TemplateMutation = (
  { __typename?: 'mutation_root' }
  & { update_order_confirmation_templates?: Maybe<(
    { __typename?: 'order_confirmation_templates_mutation_response' }
    & { returning: Array<(
      { __typename?: 'order_confirmation_templates' }
      & Pick<Order_Confirmation_Templates, 'changed' | 'created' | 'description' | 'id' | 'other_terms' | 'template_title' | 'title'>
      & { tasks: Array<(
        { __typename?: 'order_confirmation_template_tasks' }
        & Pick<Order_Confirmation_Template_Tasks, 'description' | 'id' | 'order' | 'price' | 'title'>
      )> }
    )> }
  )> }
);

export type Insert_Order_Confirmation_Template_TaskMutationVariables = Exact<{
  orderConfirmationTemplateTasks: Array<Order_Confirmation_Template_Tasks_Insert_Input>;
}>;


export type Insert_Order_Confirmation_Template_TaskMutation = (
  { __typename?: 'mutation_root' }
  & { insert_order_confirmation_template_tasks?: Maybe<(
    { __typename?: 'order_confirmation_template_tasks_mutation_response' }
    & { returning: Array<(
      { __typename?: 'order_confirmation_template_tasks' }
      & Pick<Order_Confirmation_Template_Tasks, 'description' | 'id' | 'order' | 'price' | 'title'>
    )> }
  )> }
);

export type Update_Order_Confirmation_Template_TaskMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  orderConfirmationTemplateTask: Order_Confirmation_Template_Tasks_Set_Input;
}>;


export type Update_Order_Confirmation_Template_TaskMutation = (
  { __typename?: 'mutation_root' }
  & { update_order_confirmation_template_tasks?: Maybe<(
    { __typename?: 'order_confirmation_template_tasks_mutation_response' }
    & { returning: Array<(
      { __typename?: 'order_confirmation_template_tasks' }
      & Pick<Order_Confirmation_Template_Tasks, 'description' | 'id' | 'order' | 'price' | 'title'>
    )> }
  )> }
);

export type Delete_Order_Confirmation_Template_TasksMutationVariables = Exact<{
  where: Order_Confirmation_Template_Tasks_Bool_Exp;
}>;


export type Delete_Order_Confirmation_Template_TasksMutation = (
  { __typename?: 'mutation_root' }
  & { delete_order_confirmation_template_tasks?: Maybe<(
    { __typename?: 'order_confirmation_template_tasks_mutation_response' }
    & Pick<Order_Confirmation_Template_Tasks_Mutation_Response, 'affected_rows'>
  )> }
);

export type List_Case_Doc_Order_ConfirmationsQueryVariables = Exact<{
  orderBy: Case_Doc_Order_Confirmations_Order_By;
  where?: Maybe<Case_Doc_Order_Confirmations_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type List_Case_Doc_Order_ConfirmationsQuery = (
  { __typename?: 'query_root' }
  & { case_doc_order_confirmations: Array<(
    { __typename?: 'case_doc_order_confirmations' }
    & Pick<Case_Doc_Order_Confirmations, 'changed' | 'created' | 'description' | 'id' | 'other_terms' | 'title' | 'type'>
  )> }
);

export type Get_Order_ConfirmationQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Get_Order_ConfirmationQuery = (
  { __typename?: 'query_root' }
  & { case_doc_order_confirmations_by_pk?: Maybe<(
    { __typename?: 'case_doc_order_confirmations' }
    & Pick<Case_Doc_Order_Confirmations, 'changed' | 'created' | 'description' | 'document_date' | 'revision_date' | 'type' | 'drawings_date' | 'id' | 'other_terms' | 'share_key' | 'title' | 'work_ends_date' | 'work_starts_date'>
    & { case: (
      { __typename?: 'cases' }
      & Pick<Cases, 'address' | 'builder_id' | 'city' | 'date_of_inspection' | 'other_address' | 'responsible_id' | 'zip_code'>
      & { builder?: Maybe<(
        { __typename?: 'contacts' }
        & Pick<Contacts, 'address' | 'city' | 'company' | 'first_name' | 'id' | 'last_name' | 'mail' | 'mobile' | 'phone' | 'zip_code'>
      )>, responsible?: Maybe<(
        { __typename?: 'users' }
        & Pick<Users, 'acronym' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'phone'>
      )> }
    ), tasks: Array<(
      { __typename?: 'order_confirmation_tasks' }
      & Pick<Order_Confirmation_Tasks, 'description' | 'id' | 'order' | 'price' | 'title'>
    )> }
  )> }
);

export type Insert_Case_Doc_Order_ConfirmationsMutationVariables = Exact<{
  orderConfirmation: Case_Doc_Order_Confirmations_Insert_Input;
}>;


export type Insert_Case_Doc_Order_ConfirmationsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_case_doc_order_confirmations?: Maybe<(
    { __typename?: 'case_doc_order_confirmations_mutation_response' }
    & { returning: Array<(
      { __typename?: 'case_doc_order_confirmations' }
      & Pick<Case_Doc_Order_Confirmations, 'changed' | 'created' | 'description' | 'document_date' | 'revision_date' | 'drawings_date' | 'id' | 'type' | 'other_terms' | 'share_key' | 'title' | 'work_ends_date' | 'work_starts_date'>
      & { case: (
        { __typename?: 'cases' }
        & Pick<Cases, 'address' | 'city' | 'date_of_inspection' | 'other_address' | 'zip_code'>
        & { builder?: Maybe<(
          { __typename?: 'contacts' }
          & Pick<Contacts, 'address' | 'city' | 'company' | 'first_name' | 'id' | 'last_name' | 'mail' | 'mobile' | 'phone' | 'zip_code'>
        )>, responsible?: Maybe<(
          { __typename?: 'users' }
          & Pick<Users, 'acronym' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'phone'>
        )> }
      ), tasks: Array<(
        { __typename?: 'order_confirmation_tasks' }
        & Pick<Order_Confirmation_Tasks, 'description' | 'id' | 'order' | 'price' | 'title'>
      )> }
    )> }
  )> }
);

export type Update_Case_Doc_Order_ConfirmationsMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  orderConfirmation: Case_Doc_Order_Confirmations_Set_Input;
}>;


export type Update_Case_Doc_Order_ConfirmationsMutation = (
  { __typename?: 'mutation_root' }
  & { update_case_doc_order_confirmations?: Maybe<(
    { __typename?: 'case_doc_order_confirmations_mutation_response' }
    & { returning: Array<(
      { __typename?: 'case_doc_order_confirmations' }
      & Pick<Case_Doc_Order_Confirmations, 'changed' | 'created' | 'description' | 'type' | 'document_date' | 'revision_date' | 'drawings_date' | 'id' | 'other_terms' | 'share_key' | 'title' | 'work_ends_date' | 'work_starts_date'>
      & { case: (
        { __typename?: 'cases' }
        & Pick<Cases, 'address' | 'city' | 'date_of_inspection' | 'other_address' | 'zip_code'>
        & { builder?: Maybe<(
          { __typename?: 'contacts' }
          & Pick<Contacts, 'address' | 'city' | 'company' | 'first_name' | 'id' | 'last_name' | 'mail' | 'mobile' | 'phone' | 'zip_code'>
        )>, responsible?: Maybe<(
          { __typename?: 'users' }
          & Pick<Users, 'acronym' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'phone'>
        )> }
      ), tasks: Array<(
        { __typename?: 'order_confirmation_tasks' }
        & Pick<Order_Confirmation_Tasks, 'description' | 'id' | 'order' | 'price' | 'title'>
      )> }
    )> }
  )> }
);

export type Insert_Case_Doc_Order_Confirmations_TaskMutationVariables = Exact<{
  orderConfirmationTasks: Array<Order_Confirmation_Tasks_Insert_Input>;
}>;


export type Insert_Case_Doc_Order_Confirmations_TaskMutation = (
  { __typename?: 'mutation_root' }
  & { insert_order_confirmation_tasks?: Maybe<(
    { __typename?: 'order_confirmation_tasks_mutation_response' }
    & { returning: Array<(
      { __typename?: 'order_confirmation_tasks' }
      & Pick<Order_Confirmation_Tasks, 'description' | 'id' | 'order' | 'price' | 'title'>
    )> }
  )> }
);

export type Update_Case_Doc_Order_Confirmations_TaskMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  orderConfirmationTask: Order_Confirmation_Tasks_Set_Input;
}>;


export type Update_Case_Doc_Order_Confirmations_TaskMutation = (
  { __typename?: 'mutation_root' }
  & { update_order_confirmation_tasks?: Maybe<(
    { __typename?: 'order_confirmation_tasks_mutation_response' }
    & { returning: Array<(
      { __typename?: 'order_confirmation_tasks' }
      & Pick<Order_Confirmation_Tasks, 'description' | 'id' | 'order' | 'price' | 'title'>
    )> }
  )> }
);

export type Delete_Order_Confirmation_TasksMutationVariables = Exact<{
  where: Order_Confirmation_Tasks_Bool_Exp;
}>;


export type Delete_Order_Confirmation_TasksMutation = (
  { __typename?: 'mutation_root' }
  & { delete_order_confirmation_tasks?: Maybe<(
    { __typename?: 'order_confirmation_tasks_mutation_response' }
    & Pick<Order_Confirmation_Tasks_Mutation_Response, 'affected_rows'>
  )> }
);

export type List_Quality_Control_TemplatesQueryVariables = Exact<{
  orderBy: Quality_Control_Templates_Order_By;
  where?: Maybe<Quality_Control_Templates_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type List_Quality_Control_TemplatesQuery = (
  { __typename?: 'query_root' }
  & { quality_control_templates: Array<(
    { __typename?: 'quality_control_templates' }
    & Pick<Quality_Control_Templates, 'changed' | 'created' | 'id' | 'template_title' | 'title'>
  )> }
);

export type Get_Quality_Control_TemplatesQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Get_Quality_Control_TemplatesQuery = (
  { __typename?: 'query_root' }
  & { quality_control_templates_by_pk?: Maybe<(
    { __typename?: 'quality_control_templates' }
    & Pick<Quality_Control_Templates, 'changed' | 'created' | 'id' | 'template_title' | 'title'>
    & { tasks: Array<(
      { __typename?: 'quality_control_template_tasks' }
      & Pick<Quality_Control_Template_Tasks, 'description' | 'id' | 'order' | 'file_id' | 'title'>
      & { file?: Maybe<(
        { __typename?: 'files' }
        & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
      )> }
    )> }
  )> }
);

export type Get_Quality_Control_Template_With_CaseQueryVariables = Exact<{
  id: Scalars['uuid'];
  caseId: Scalars['uuid'];
}>;


export type Get_Quality_Control_Template_With_CaseQuery = (
  { __typename?: 'query_root' }
  & { quality_control_templates_by_pk?: Maybe<(
    { __typename?: 'quality_control_templates' }
    & Pick<Quality_Control_Templates, 'changed' | 'created' | 'id' | 'template_title' | 'title'>
    & { tasks: Array<(
      { __typename?: 'quality_control_template_tasks' }
      & Pick<Quality_Control_Template_Tasks, 'description' | 'id' | 'order' | 'file_id' | 'title'>
      & { file?: Maybe<(
        { __typename?: 'files' }
        & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
      )> }
    )> }
  )>, cases_by_pk?: Maybe<(
    { __typename?: 'cases' }
    & Pick<Cases, 'address' | 'builder_id' | 'city' | 'date_of_inspection' | 'other_address' | 'responsible_id' | 'zip_code'>
    & { builder?: Maybe<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'address' | 'city' | 'company' | 'first_name' | 'id' | 'last_name' | 'mail' | 'mobile' | 'phone' | 'zip_code'>
    )>, responsible?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'acronym' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'phone'>
    )> }
  )> }
);

export type Insert_Quality_Control_TemplateMutationVariables = Exact<{
  qualityControlTemplate: Quality_Control_Templates_Insert_Input;
}>;


export type Insert_Quality_Control_TemplateMutation = (
  { __typename?: 'mutation_root' }
  & { insert_quality_control_templates?: Maybe<(
    { __typename?: 'quality_control_templates_mutation_response' }
    & { returning: Array<(
      { __typename?: 'quality_control_templates' }
      & Pick<Quality_Control_Templates, 'changed' | 'created' | 'id' | 'template_title' | 'title'>
      & { tasks: Array<(
        { __typename?: 'quality_control_template_tasks' }
        & Pick<Quality_Control_Template_Tasks, 'description' | 'id' | 'order' | 'file_id' | 'title'>
      )> }
    )> }
  )> }
);

export type Update_Quality_Control_TemplateMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  qualityControlTemplate: Quality_Control_Templates_Set_Input;
}>;


export type Update_Quality_Control_TemplateMutation = (
  { __typename?: 'mutation_root' }
  & { update_quality_control_templates?: Maybe<(
    { __typename?: 'quality_control_templates_mutation_response' }
    & { returning: Array<(
      { __typename?: 'quality_control_templates' }
      & Pick<Quality_Control_Templates, 'changed' | 'created' | 'id' | 'template_title' | 'title'>
      & { tasks: Array<(
        { __typename?: 'quality_control_template_tasks' }
        & Pick<Quality_Control_Template_Tasks, 'description' | 'id' | 'order' | 'template_id' | 'file_id' | 'title'>
        & { file?: Maybe<(
          { __typename?: 'files' }
          & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
        )> }
      )> }
    )> }
  )> }
);

export type Insert_Quality_Control_Template_TaskMutationVariables = Exact<{
  qualityControlTemplateTasks: Array<Quality_Control_Template_Tasks_Insert_Input>;
}>;


export type Insert_Quality_Control_Template_TaskMutation = (
  { __typename?: 'mutation_root' }
  & { insert_quality_control_template_tasks?: Maybe<(
    { __typename?: 'quality_control_template_tasks_mutation_response' }
    & { returning: Array<(
      { __typename?: 'quality_control_template_tasks' }
      & Pick<Quality_Control_Template_Tasks, 'description' | 'id' | 'order' | 'file_id' | 'title'>
    )> }
  )> }
);

export type Update_Quality_Control_Template_TaskMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  qualityControlTemplateTask: Quality_Control_Template_Tasks_Set_Input;
}>;


export type Update_Quality_Control_Template_TaskMutation = (
  { __typename?: 'mutation_root' }
  & { update_quality_control_template_tasks?: Maybe<(
    { __typename?: 'quality_control_template_tasks_mutation_response' }
    & { returning: Array<(
      { __typename?: 'quality_control_template_tasks' }
      & Pick<Quality_Control_Template_Tasks, 'description' | 'id' | 'order' | 'title' | 'file_id'>
    )> }
  )> }
);

export type Delete_Quality_Control_Template_TasksMutationVariables = Exact<{
  where: Quality_Control_Template_Tasks_Bool_Exp;
}>;


export type Delete_Quality_Control_Template_TasksMutation = (
  { __typename?: 'mutation_root' }
  & { delete_quality_control_template_tasks?: Maybe<(
    { __typename?: 'quality_control_template_tasks_mutation_response' }
    & Pick<Quality_Control_Template_Tasks_Mutation_Response, 'affected_rows'>
  )> }
);

export type List_Case_Doc_Quality_ControlsQueryVariables = Exact<{
  orderBy: Case_Doc_Quality_Controls_Order_By;
  where?: Maybe<Case_Doc_Quality_Controls_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type List_Case_Doc_Quality_ControlsQuery = (
  { __typename?: 'query_root' }
  & { case_doc_quality_controls: Array<(
    { __typename?: 'case_doc_quality_controls' }
    & Pick<Case_Doc_Quality_Controls, 'changed' | 'created' | 'id' | 'title' | 'type'>
  )> }
);

export type Get_Quality_ControlQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Get_Quality_ControlQuery = (
  { __typename?: 'query_root' }
  & { case_doc_quality_controls_by_pk?: Maybe<(
    { __typename?: 'case_doc_quality_controls' }
    & Pick<Case_Doc_Quality_Controls, 'changed' | 'created' | 'document_date' | 'revision_date' | 'date_of_inspection' | 'id' | 'type' | 'share_key' | 'title'>
    & { case: (
      { __typename?: 'cases' }
      & Pick<Cases, 'address' | 'builder_id' | 'city' | 'date_of_inspection' | 'other_address' | 'responsible_id' | 'zip_code'>
      & { builder?: Maybe<(
        { __typename?: 'contacts' }
        & Pick<Contacts, 'address' | 'city' | 'company' | 'first_name' | 'id' | 'last_name' | 'mail' | 'mobile' | 'phone' | 'zip_code'>
      )>, responsible?: Maybe<(
        { __typename?: 'users' }
        & Pick<Users, 'acronym' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'phone'>
      )> }
    ), tasks: Array<(
      { __typename?: 'quality_control_tasks' }
      & Pick<Quality_Control_Tasks, 'description' | 'id' | 'order' | 'title' | 'file_id'>
      & { file?: Maybe<(
        { __typename?: 'files' }
        & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
      )>, arrows: Array<(
        { __typename?: 'quality_control_task_arrows' }
        & Pick<Quality_Control_Task_Arrows, 'id' | 'order' | 'angle' | 'x' | 'y'>
      )> }
    )> }
  )> }
);

export type Insert_Case_Doc_Quality_ControlsMutationVariables = Exact<{
  qualityControl: Case_Doc_Quality_Controls_Insert_Input;
}>;


export type Insert_Case_Doc_Quality_ControlsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_case_doc_quality_controls?: Maybe<(
    { __typename?: 'case_doc_quality_controls_mutation_response' }
    & { returning: Array<(
      { __typename?: 'case_doc_quality_controls' }
      & Pick<Case_Doc_Quality_Controls, 'changed' | 'created' | 'document_date' | 'revision_date' | 'id' | 'type' | 'share_key' | 'title' | 'date_of_inspection'>
      & { case: (
        { __typename?: 'cases' }
        & Pick<Cases, 'address' | 'city' | 'date_of_inspection' | 'other_address' | 'zip_code'>
        & { builder?: Maybe<(
          { __typename?: 'contacts' }
          & Pick<Contacts, 'address' | 'city' | 'company' | 'first_name' | 'id' | 'last_name' | 'mail' | 'mobile' | 'phone' | 'zip_code'>
        )>, responsible?: Maybe<(
          { __typename?: 'users' }
          & Pick<Users, 'acronym' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'phone'>
        )> }
      ), tasks: Array<(
        { __typename?: 'quality_control_tasks' }
        & Pick<Quality_Control_Tasks, 'description' | 'id' | 'order' | 'title'>
        & { arrows: Array<(
          { __typename?: 'quality_control_task_arrows' }
          & Pick<Quality_Control_Task_Arrows, 'id' | 'order' | 'angle' | 'x' | 'y'>
        )> }
      )> }
    )> }
  )> }
);

export type Update_Case_Doc_Quality_ControlsMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  qualityControl: Case_Doc_Quality_Controls_Set_Input;
}>;


export type Update_Case_Doc_Quality_ControlsMutation = (
  { __typename?: 'mutation_root' }
  & { update_case_doc_quality_controls?: Maybe<(
    { __typename?: 'case_doc_quality_controls_mutation_response' }
    & { returning: Array<(
      { __typename?: 'case_doc_quality_controls' }
      & Pick<Case_Doc_Quality_Controls, 'changed' | 'created' | 'type' | 'document_date' | 'revision_date' | 'id' | 'share_key' | 'date_of_inspection' | 'title'>
      & { case: (
        { __typename?: 'cases' }
        & Pick<Cases, 'address' | 'city' | 'date_of_inspection' | 'other_address' | 'zip_code'>
        & { builder?: Maybe<(
          { __typename?: 'contacts' }
          & Pick<Contacts, 'address' | 'city' | 'company' | 'first_name' | 'id' | 'last_name' | 'mail' | 'mobile' | 'phone' | 'zip_code'>
        )>, responsible?: Maybe<(
          { __typename?: 'users' }
          & Pick<Users, 'acronym' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'phone'>
        )> }
      ), tasks: Array<(
        { __typename?: 'quality_control_tasks' }
        & Pick<Quality_Control_Tasks, 'description' | 'id' | 'order' | 'title' | 'file_id'>
        & { file?: Maybe<(
          { __typename?: 'files' }
          & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
        )>, arrows: Array<(
          { __typename?: 'quality_control_task_arrows' }
          & Pick<Quality_Control_Task_Arrows, 'id' | 'order' | 'task_id' | 'angle' | 'x' | 'y'>
        )> }
      )> }
    )> }
  )> }
);

export type Insert_Case_Doc_Quality_Controls_TaskMutationVariables = Exact<{
  qualityControlTasks: Array<Quality_Control_Tasks_Insert_Input>;
}>;


export type Insert_Case_Doc_Quality_Controls_TaskMutation = (
  { __typename?: 'mutation_root' }
  & { insert_quality_control_tasks?: Maybe<(
    { __typename?: 'quality_control_tasks_mutation_response' }
    & { returning: Array<(
      { __typename?: 'quality_control_tasks' }
      & Pick<Quality_Control_Tasks, 'description' | 'id' | 'order' | 'title' | 'file_id'>
    )> }
  )> }
);

export type Update_Case_Doc_Quality_Controls_TaskMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  qualityControlTask: Quality_Control_Tasks_Set_Input;
}>;


export type Update_Case_Doc_Quality_Controls_TaskMutation = (
  { __typename?: 'mutation_root' }
  & { update_quality_control_tasks?: Maybe<(
    { __typename?: 'quality_control_tasks_mutation_response' }
    & { returning: Array<(
      { __typename?: 'quality_control_tasks' }
      & Pick<Quality_Control_Tasks, 'description' | 'id' | 'order' | 'title' | 'file_id'>
      & { file?: Maybe<(
        { __typename?: 'files' }
        & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
      )> }
    )> }
  )> }
);

export type Delete_Quality_Control_TasksMutationVariables = Exact<{
  where: Quality_Control_Tasks_Bool_Exp;
}>;


export type Delete_Quality_Control_TasksMutation = (
  { __typename?: 'mutation_root' }
  & { delete_quality_control_tasks?: Maybe<(
    { __typename?: 'quality_control_tasks_mutation_response' }
    & Pick<Quality_Control_Tasks_Mutation_Response, 'affected_rows'>
  )> }
);

export type Insert_Quality_Control_Task_ArrowsMutationVariables = Exact<{
  qualityControlTaskArrows: Array<Quality_Control_Task_Arrows_Insert_Input>;
}>;


export type Insert_Quality_Control_Task_ArrowsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_quality_control_task_arrows?: Maybe<(
    { __typename?: 'quality_control_task_arrows_mutation_response' }
    & { returning: Array<(
      { __typename?: 'quality_control_task_arrows' }
      & Pick<Quality_Control_Task_Arrows, 'id' | 'order' | 'task_id' | 'angle' | 'x' | 'y'>
    )> }
  )> }
);

export type Update_Quality_Control_Task_ArrowsMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  qualityControlTaskArrow: Quality_Control_Task_Arrows_Set_Input;
}>;


export type Update_Quality_Control_Task_ArrowsMutation = (
  { __typename?: 'mutation_root' }
  & { update_quality_control_task_arrows?: Maybe<(
    { __typename?: 'quality_control_task_arrows_mutation_response' }
    & { returning: Array<(
      { __typename?: 'quality_control_task_arrows' }
      & Pick<Quality_Control_Task_Arrows, 'id' | 'order' | 'task_id' | 'angle' | 'x' | 'y'>
    )> }
  )> }
);

export type Delete_Quality_Control_Task_ArrowsMutationVariables = Exact<{
  where: Quality_Control_Task_Arrows_Bool_Exp;
}>;


export type Delete_Quality_Control_Task_ArrowsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_quality_control_task_arrows?: Maybe<(
    { __typename?: 'quality_control_task_arrows_mutation_response' }
    & Pick<Quality_Control_Task_Arrows_Mutation_Response, 'affected_rows'>
  )> }
);

export type List_RemindersQueryVariables = Exact<{
  orderBy?: Maybe<Array<Reminders_Order_By>>;
  limit?: Maybe<Scalars['Int']>;
  where?: Maybe<Reminders_Bool_Exp>;
}>;


export type List_RemindersQuery = (
  { __typename?: 'query_root' }
  & { reminders: Array<(
    { __typename?: 'reminders' }
    & Pick<Reminders, 'id' | 'date' | 'message' | 'contacted' | 'request_id' | 'case_id'>
    & { creator: (
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'acronym'>
    ), recipients: Array<(
      { __typename?: 'reminder_recipients' }
      & { user: (
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'acronym' | 'mail'>
      ) }
    )>, request?: Maybe<(
      { __typename?: 'requests' }
      & Pick<Requests, 'id' | 'builder' | 'address' | 'zip_code' | 'city' | 'mail' | 'mobile'>
    )>, case?: Maybe<(
      { __typename?: 'cases' }
      & Pick<Cases, 'id' | 'company_id' | 'other_address' | 'address' | 'zip_code' | 'city'>
      & { builder?: Maybe<(
        { __typename?: 'contacts' }
        & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'address' | 'zip_code' | 'city'>
      )>, company?: Maybe<(
        { __typename?: 'contacts' }
        & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'company' | 'address' | 'zip_code' | 'city' | 'phone' | 'mobile' | 'mail'>
      )>, suppliers: Array<(
        { __typename?: 'case_suppliers' }
        & { contact: (
          { __typename?: 'contacts' }
          & Pick<Contacts, 'company' | 'first_name' | 'last_name' | 'mobile' | 'mail'>
        ) }
      )> }
    )> }
  )> }
);

export type Insert_ReminderMutationVariables = Exact<{
  reminder: Reminders_Insert_Input;
}>;


export type Insert_ReminderMutation = (
  { __typename?: 'mutation_root' }
  & { insert_reminders?: Maybe<(
    { __typename?: 'reminders_mutation_response' }
    & { returning: Array<(
      { __typename?: 'reminders' }
      & Pick<Reminders, 'id' | 'date' | 'message' | 'contacted'>
      & { creator: (
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'acronym'>
      ), recipients: Array<(
        { __typename?: 'reminder_recipients' }
        & { user: (
          { __typename?: 'users' }
          & Pick<Users, 'id' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'phone' | 'acronym'>
        ) }
      )> }
    )> }
  )> }
);

export type Get_RemindersQueryVariables = Exact<{
  reminderId?: Maybe<Scalars['uuid']>;
}>;


export type Get_RemindersQuery = (
  { __typename?: 'query_root' }
  & { reminders: Array<(
    { __typename?: 'reminders' }
    & Pick<Reminders, 'id' | 'date' | 'message' | 'contacted'>
    & { creator: (
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'acronym'>
    ), recipients: Array<(
      { __typename?: 'reminder_recipients' }
      & { user: (
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'phone' | 'acronym'>
      ) }
    )> }
  )> }
);

export type UpdateReminderMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  reminder: Reminders_Set_Input;
}>;


export type UpdateReminderMutation = (
  { __typename?: 'mutation_root' }
  & { update_reminders?: Maybe<(
    { __typename?: 'reminders_mutation_response' }
    & { returning: Array<(
      { __typename?: 'reminders' }
      & Pick<Reminders, 'id' | 'date' | 'message' | 'contacted'>
      & { creator: (
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'acronym'>
      ), recipients: Array<(
        { __typename?: 'reminder_recipients' }
        & { user: (
          { __typename?: 'users' }
          & Pick<Users, 'id' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'phone' | 'acronym'>
        ) }
      )> }
    )> }
  )> }
);

export type Insert_Reminder_RecipientMutationVariables = Exact<{
  reminder_recipients: Array<Reminder_Recipients_Insert_Input>;
}>;


export type Insert_Reminder_RecipientMutation = (
  { __typename?: 'mutation_root' }
  & { insert_reminder_recipients?: Maybe<(
    { __typename?: 'reminder_recipients_mutation_response' }
    & { returning: Array<(
      { __typename?: 'reminder_recipients' }
      & Pick<Reminder_Recipients, 'id' | 'user_id' | 'reminder_id'>
    )> }
  )> }
);

export type Delete_Reminder_RecipientsMutationVariables = Exact<{
  where: Reminder_Recipients_Bool_Exp;
}>;


export type Delete_Reminder_RecipientsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_reminder_recipients?: Maybe<(
    { __typename?: 'reminder_recipients_mutation_response' }
    & Pick<Reminder_Recipients_Mutation_Response, 'affected_rows'>
  )> }
);

export type UpdateRemindersContactedMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  contacted: Scalars['Boolean'];
}>;


export type UpdateRemindersContactedMutation = (
  { __typename?: 'mutation_root' }
  & { update_reminders?: Maybe<(
    { __typename?: 'reminders_mutation_response' }
    & { returning: Array<(
      { __typename?: 'reminders' }
      & Pick<Reminders, 'id' | 'date' | 'message' | 'contacted'>
      & { creator: (
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'acronym'>
      ), recipients: Array<(
        { __typename?: 'reminder_recipients' }
        & { user: (
          { __typename?: 'users' }
          & Pick<Users, 'id' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'phone' | 'acronym'>
        ) }
      )> }
    )> }
  )> }
);

export type List_RequestsQueryVariables = Exact<{
  orderBy: Requests_Order_By;
  where?: Maybe<Requests_Bool_Exp>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type List_RequestsQuery = (
  { __typename?: 'query_root' }
  & { requests_aggregate: (
    { __typename?: 'requests_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'requests_aggregate_fields' }
      & Pick<Requests_Aggregate_Fields, 'count'>
    )>, nodes: Array<(
      { __typename?: 'requests' }
      & Pick<Requests, 'id' | 'builder' | 'task' | 'address' | 'date' | 'state' | 'zip_code' | 'city' | 'mail' | 'mobile' | 'description' | 'inspection_date' | 'inspection_time'>
      & { case_type?: Maybe<(
        { __typename?: 'case_types' }
        & Pick<Case_Types, 'id' | 'name'>
      )>, responsible?: Maybe<(
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'phone' | 'acronym'>
      )>, external?: Maybe<(
        { __typename?: 'contacts' }
        & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'company' | 'address' | 'zip_code' | 'city' | 'phone' | 'mobile' | 'mail'>
      )> }
    )> }
  ) }
);

export type Get_RequestsQueryVariables = Exact<{
  requestId?: Maybe<Scalars['uuid']>;
}>;


export type Get_RequestsQuery = (
  { __typename?: 'query_root' }
  & { requests: Array<(
    { __typename?: 'requests' }
    & Pick<Requests, 'id' | 'builder' | 'task' | 'address' | 'date' | 'state' | 'zip_code' | 'city' | 'mail' | 'mobile' | 'description' | 'inspection_date' | 'inspection_time'>
    & { case_type?: Maybe<(
      { __typename?: 'case_types' }
      & Pick<Case_Types, 'id' | 'name'>
    )>, responsible?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'phone' | 'acronym'>
    )>, external?: Maybe<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'company' | 'address' | 'zip_code' | 'city' | 'phone' | 'mobile' | 'mail'>
    )>, request_notes: Array<(
      { __typename?: 'request_notes' }
      & Pick<Request_Notes, 'id' | 'notes' | 'added_date'>
      & { user: (
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'acronym'>
      ) }
    )>, reminders: Array<(
      { __typename?: 'reminders' }
      & Pick<Reminders, 'id' | 'date' | 'message'>
      & { creator: (
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'acronym'>
      ), recipients: Array<(
        { __typename?: 'reminder_recipients' }
        & { user: (
          { __typename?: 'users' }
          & Pick<Users, 'id' | 'acronym'>
        ) }
      )> }
    )> }
  )> }
);

export type Update_RequestMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  request: Requests_Set_Input;
}>;


export type Update_RequestMutation = (
  { __typename?: 'mutation_root' }
  & { update_requests?: Maybe<(
    { __typename?: 'requests_mutation_response' }
    & { returning: Array<(
      { __typename?: 'requests' }
      & Pick<Requests, 'id' | 'builder' | 'task' | 'address' | 'date' | 'state' | 'zip_code' | 'city' | 'mail' | 'mobile' | 'description' | 'inspection_date' | 'inspection_time'>
      & { case_type?: Maybe<(
        { __typename?: 'case_types' }
        & Pick<Case_Types, 'id' | 'name'>
      )>, responsible?: Maybe<(
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'phone' | 'acronym'>
      )>, external?: Maybe<(
        { __typename?: 'contacts' }
        & Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'company' | 'address' | 'zip_code' | 'city' | 'phone' | 'mobile' | 'mail'>
      )>, request_notes: Array<(
        { __typename?: 'request_notes' }
        & Pick<Request_Notes, 'id' | 'notes' | 'added_date'>
        & { user: (
          { __typename?: 'users' }
          & Pick<Users, 'id' | 'acronym'>
        ) }
      )>, reminders: Array<(
        { __typename?: 'reminders' }
        & Pick<Reminders, 'id' | 'date' | 'message'>
        & { creator: (
          { __typename?: 'users' }
          & Pick<Users, 'id' | 'acronym'>
        ), recipients: Array<(
          { __typename?: 'reminder_recipients' }
          & { user: (
            { __typename?: 'users' }
            & Pick<Users, 'id' | 'acronym'>
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type Insert_Request_NotesMutationVariables = Exact<{
  request_notes: Array<Request_Notes_Insert_Input>;
}>;


export type Insert_Request_NotesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_request_notes?: Maybe<(
    { __typename?: 'request_notes_mutation_response' }
    & { returning: Array<(
      { __typename?: 'request_notes' }
      & Pick<Request_Notes, 'id' | 'notes' | 'added_date'>
      & { user: (
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'acronym'>
      ) }
    )> }
  )> }
);

export type List_Request_RemindersQueryVariables = Exact<{
  orderBy: Reminders_Order_By;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  where?: Maybe<Reminders_Bool_Exp>;
}>;


export type List_Request_RemindersQuery = (
  { __typename?: 'query_root' }
  & { reminders_aggregate: (
    { __typename?: 'reminders_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'reminders_aggregate_fields' }
      & Pick<Reminders_Aggregate_Fields, 'count'>
    )>, nodes: Array<(
      { __typename?: 'reminders' }
      & Pick<Reminders, 'id' | 'date' | 'message'>
      & { creator: (
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'acronym'>
      ), recipients: Array<(
        { __typename?: 'reminder_recipients' }
        & { user: (
          { __typename?: 'users' }
          & Pick<Users, 'id' | 'acronym'>
        ) }
      )> }
    )> }
  ) }
);

export type CreateReminderMutationVariables = Exact<{
  reminder: Reminders_Insert_Input;
}>;


export type CreateReminderMutation = (
  { __typename?: 'mutation_root' }
  & { insert_reminders?: Maybe<(
    { __typename?: 'reminders_mutation_response' }
    & { returning: Array<(
      { __typename?: 'reminders' }
      & Pick<Reminders, 'id' | 'date' | 'message'>
      & { creator: (
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'acronym'>
      ), recipients: Array<(
        { __typename?: 'reminder_recipients' }
        & { user: (
          { __typename?: 'users' }
          & Pick<Users, 'id' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'phone' | 'acronym'>
        ) }
      )> }
    )> }
  )> }
);

export type Get_Request_RemindersQueryVariables = Exact<{
  reminderId?: Maybe<Scalars['uuid']>;
}>;


export type Get_Request_RemindersQuery = (
  { __typename?: 'query_root' }
  & { reminders: Array<(
    { __typename?: 'reminders' }
    & Pick<Reminders, 'id' | 'date' | 'message'>
    & { creator: (
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'acronym'>
    ), recipients: Array<(
      { __typename?: 'reminder_recipients' }
      & { user: (
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'phone' | 'acronym'>
      ) }
    )> }
  )> }
);

export type Update_Request_RemindersMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  reminder: Reminders_Set_Input;
}>;


export type Update_Request_RemindersMutation = (
  { __typename?: 'mutation_root' }
  & { update_reminders?: Maybe<(
    { __typename?: 'reminders_mutation_response' }
    & { returning: Array<(
      { __typename?: 'reminders' }
      & Pick<Reminders, 'id' | 'date' | 'message'>
      & { creator: (
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'acronym'>
      ), recipients: Array<(
        { __typename?: 'reminder_recipients' }
        & { user: (
          { __typename?: 'users' }
          & Pick<Users, 'id' | 'first_name' | 'last_name' | 'mail' | 'mobile' | 'phone' | 'acronym'>
        ) }
      )> }
    )> }
  )> }
);

export type CreateReminderRecipientMutationVariables = Exact<{
  reminder_recipients: Array<Reminder_Recipients_Insert_Input>;
}>;


export type CreateReminderRecipientMutation = (
  { __typename?: 'mutation_root' }
  & { insert_reminder_recipients?: Maybe<(
    { __typename?: 'reminder_recipients_mutation_response' }
    & { returning: Array<(
      { __typename?: 'reminder_recipients' }
      & Pick<Reminder_Recipients, 'id' | 'user_id' | 'reminder_id'>
    )> }
  )> }
);

export type List_Standard_PhotosQueryVariables = Exact<{
  orderBy: Standard_Photos_Order_By;
  where?: Maybe<Standard_Photos_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type List_Standard_PhotosQuery = (
  { __typename?: 'query_root' }
  & { standard_photos: Array<(
    { __typename?: 'standard_photos' }
    & Pick<Standard_Photos, 'id' | 'file_id' | 'category' | 'title'>
    & { file: (
      { __typename?: 'files' }
      & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
    ) }
  )> }
);

export type Insert_Standard_PhotoMutationVariables = Exact<{
  standard_photos: Array<Standard_Photos_Insert_Input>;
}>;


export type Insert_Standard_PhotoMutation = (
  { __typename?: 'mutation_root' }
  & { insert_standard_photos?: Maybe<(
    { __typename?: 'standard_photos_mutation_response' }
    & { returning: Array<(
      { __typename?: 'standard_photos' }
      & Pick<Standard_Photos, 'id' | 'file_id' | 'category' | 'title'>
      & { file: (
        { __typename?: 'files' }
        & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
      ) }
    )> }
  )> }
);

export type Get_StandardQueryVariables = Exact<{
  photoId?: Maybe<Scalars['uuid']>;
}>;


export type Get_StandardQuery = (
  { __typename?: 'query_root' }
  & { standard_photos: Array<(
    { __typename?: 'standard_photos' }
    & Pick<Standard_Photos, 'id' | 'file_id' | 'category' | 'title'>
    & { file: (
      { __typename?: 'files' }
      & Pick<Files, 'id' | 'filename' | 'share_key' | 'created'>
    ) }
  )> }
);

export type Update_Standard_PhotoMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  standard_photo: Standard_Photos_Set_Input;
}>;


export type Update_Standard_PhotoMutation = (
  { __typename?: 'mutation_root' }
  & { update_standard_photos?: Maybe<(
    { __typename?: 'standard_photos_mutation_response' }
    & { returning: Array<(
      { __typename?: 'standard_photos' }
      & Pick<Standard_Photos, 'id' | 'file_id' | 'category' | 'title'>
      & { file: (
        { __typename?: 'files' }
        & Pick<Files, 'id' | 'share_key' | 'filename' | 'created'>
      ) }
    )> }
  )> }
);

export type Delete_Standard_PhotosMutationVariables = Exact<{
  photoId?: Maybe<Scalars['uuid']>;
}>;


export type Delete_Standard_PhotosMutation = (
  { __typename?: 'mutation_root' }
  & { delete_standard_photos?: Maybe<(
    { __typename?: 'standard_photos_mutation_response' }
    & Pick<Standard_Photos_Mutation_Response, 'affected_rows'>
  )> }
);

export type ListUsersQueryVariables = Exact<{
  orderBy: Users_Order_By;
  where?: Maybe<Users_Bool_Exp>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type ListUsersQuery = (
  { __typename?: 'query_root' }
  & { users_aggregate: (
    { __typename?: 'users_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'users_aggregate_fields' }
      & Pick<Users_Aggregate_Fields, 'count'>
    )>, nodes: Array<(
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'mail' | 'user_type' | 'phone' | 'mobile' | 'first_name' | 'last_name' | 'acronym' | 'active' | 'employee_number'>
    )> }
  ) }
);

export type Get_UsersQueryVariables = Exact<{
  userId?: Maybe<Scalars['uuid']>;
}>;


export type Get_UsersQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'mail' | 'user_type' | 'phone' | 'mobile' | 'first_name' | 'last_name' | 'acronym' | 'active' | 'substitute_id' | 'employee_number'>
    & { substitute?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'mail' | 'first_name' | 'last_name' | 'acronym'>
    )> }
  )> }
);

export type Get_Users_With_MailQueryVariables = Exact<{
  mail: Scalars['String'];
}>;


export type Get_Users_With_MailQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'id'>
  )> }
);

export type Insert_UserMutationVariables = Exact<{
  user: Users_Insert_Input;
}>;


export type Insert_UserMutation = (
  { __typename?: 'mutation_root' }
  & { insert_users?: Maybe<(
    { __typename?: 'users_mutation_response' }
    & { returning: Array<(
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'mail' | 'user_type' | 'phone' | 'mobile' | 'first_name' | 'last_name' | 'acronym' | 'active' | 'substitute_id' | 'employee_number'>
      & { substitute?: Maybe<(
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'mail' | 'first_name' | 'last_name' | 'acronym'>
      )> }
    )> }
  )> }
);

export type Update_UserMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  user: Users_Set_Input;
}>;


export type Update_UserMutation = (
  { __typename?: 'mutation_root' }
  & { update_users?: Maybe<(
    { __typename?: 'users_mutation_response' }
    & { returning: Array<(
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'mail' | 'user_type' | 'phone' | 'mobile' | 'first_name' | 'last_name' | 'acronym' | 'active' | 'substitute_id' | 'employee_number'>
      & { substitute?: Maybe<(
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'mail' | 'first_name' | 'last_name' | 'acronym'>
      )> }
    )> }
  )> }
);

export type Update_User_PasswordMutationVariables = Exact<{
  mail: Scalars['String'];
  password: Scalars['String'];
}>;


export type Update_User_PasswordMutation = (
  { __typename?: 'mutation_root' }
  & { update_users?: Maybe<(
    { __typename?: 'users_mutation_response' }
    & { returning: Array<(
      { __typename?: 'users' }
      & Pick<Users, 'id'>
    )> }
  )> }
);

export type Get_AbsenceQueryVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;


export type Get_AbsenceQuery = (
  { __typename?: 'query_root' }
  & { absence: Array<(
    { __typename?: 'absence' }
    & Pick<Absence, 'absence_type_id' | 'employee_id' | 'end' | 'id' | 'notes' | 'start' | 'half_day'>
    & { employee: (
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'mail' | 'acronym' | 'first_name' | 'last_name'>
    ) }
  )> }
);

export type Insert_AbsenceMutationVariables = Exact<{
  absence: Absence_Insert_Input;
}>;


export type Insert_AbsenceMutation = (
  { __typename?: 'mutation_root' }
  & { insert_absence?: Maybe<(
    { __typename?: 'absence_mutation_response' }
    & { returning: Array<(
      { __typename?: 'absence' }
      & Pick<Absence, 'absence_type_id' | 'employee_id' | 'end' | 'id' | 'notes' | 'start' | 'half_day'>
      & { employee: (
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'mail' | 'acronym' | 'first_name' | 'last_name'>
      ) }
    )> }
  )> }
);

export type Update_AbsenceMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  absence: Absence_Set_Input;
}>;


export type Update_AbsenceMutation = (
  { __typename?: 'mutation_root' }
  & { update_absence?: Maybe<(
    { __typename?: 'absence_mutation_response' }
    & { returning: Array<(
      { __typename?: 'absence' }
      & Pick<Absence, 'absence_type_id' | 'employee_id' | 'end' | 'id' | 'notes' | 'start' | 'half_day'>
      & { employee: (
        { __typename?: 'users' }
        & Pick<Users, 'id' | 'mail' | 'acronym' | 'first_name' | 'last_name'>
      ) }
    )> }
  )> }
);

export type Delete_AbsenceMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type Delete_AbsenceMutation = (
  { __typename?: 'mutation_root' }
  & { delete_absence?: Maybe<(
    { __typename?: 'absence_mutation_response' }
    & Pick<Absence_Mutation_Response, 'affected_rows'>
  )> }
);

export type GetAbsenceQueryVariables = Exact<{
  orderBy: Absence_Search_View_Order_By;
  where?: Maybe<Absence_Search_View_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetAbsenceQuery = (
  { __typename?: 'query_root' }
  & { absence_search_view: Array<(
    { __typename?: 'absence_search_view' }
    & Pick<Absence_Search_View, 'id' | 'start' | 'end' | 'half_day'>
    & { employee?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'mail' | 'acronym' | 'first_name' | 'last_name'>
    )>, absence_type?: Maybe<(
      { __typename?: 'absence_types' }
      & Pick<Absence_Types, 'label'>
    )> }
  )> }
);

export type AbsenceQueryVariables = Exact<{
  where?: Maybe<Absence_Bool_Exp>;
}>;


export type AbsenceQuery = (
  { __typename?: 'query_root' }
  & { absence: Array<(
    { __typename?: 'absence' }
    & Pick<Absence, 'notes' | 'end' | 'start' | 'half_day'>
    & { employee: (
      { __typename?: 'users' }
      & Pick<Users, 'acronym'>
    ), absence_type: (
      { __typename?: 'absence_types' }
      & Pick<Absence_Types, 'category' | 'label'>
    ) }
  )> }
);

export type GetUsersQueryVariables = Exact<{
  orderBy: Users_Order_By;
  where?: Maybe<Users_Bool_Exp>;
}>;


export type GetUsersQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'id' | 'mail' | 'first_name' | 'last_name'>
  )> }
);

export type Get_Case_TimeQueryVariables = Exact<{
  case_id?: Maybe<Scalars['uuid']>;
}>;


export type Get_Case_TimeQuery = (
  { __typename?: 'query_root' }
  & { case_time_entries: Array<(
    { __typename?: 'case_time_entries' }
    & Pick<Case_Time_Entries, 'date' | 'description' | 'id' | 'time'>
    & { user: (
      { __typename?: 'users' }
      & Pick<Users, 'acronym'>
    ) }
  )> }
);

export type GetCaseClosedQueryVariables = Exact<{
  case_id: Scalars['uuid'];
}>;


export type GetCaseClosedQuery = (
  { __typename?: 'query_root' }
  & { cases_by_pk?: Maybe<(
    { __typename?: 'cases' }
    & Pick<Cases, 'closed'>
  )> }
);

export type UpdateRequestResponsibleMutationVariables = Exact<{
  id: Scalars['uuid'];
  responsibleId?: Maybe<Scalars['uuid']>;
}>;


export type UpdateRequestResponsibleMutation = (
  { __typename?: 'mutation_root' }
  & { update_requests_by_pk?: Maybe<(
    { __typename?: 'requests' }
    & Pick<Requests, 'id'>
  )> }
);

export type Get_Absence_TypesQueryVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;


export type Get_Absence_TypesQuery = (
  { __typename?: 'query_root' }
  & { absence_types: Array<(
    { __typename?: 'absence_types' }
    & Pick<Absence_Types, 'id' | 'label' | 'active' | 'category'>
  )> }
);

export type Insert_Absence_TypeMutationVariables = Exact<{
  absenceType: Absence_Types_Insert_Input;
}>;


export type Insert_Absence_TypeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_absence_types?: Maybe<(
    { __typename?: 'absence_types_mutation_response' }
    & { returning: Array<(
      { __typename?: 'absence_types' }
      & Pick<Absence_Types, 'id' | 'label' | 'active' | 'category'>
    )> }
  )> }
);

export type Update_Absence_TypeMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  absenceType: Absence_Types_Set_Input;
}>;


export type Update_Absence_TypeMutation = (
  { __typename?: 'mutation_root' }
  & { update_absence_types?: Maybe<(
    { __typename?: 'absence_types_mutation_response' }
    & { returning: Array<(
      { __typename?: 'absence_types' }
      & Pick<Absence_Types, 'id' | 'label' | 'active' | 'category'>
    )> }
  )> }
);

export type GetConstructionPlanEntryQueryVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;


export type GetConstructionPlanEntryQuery = (
  { __typename?: 'query_root' }
  & { construction_plan_entries: Array<(
    { __typename?: 'construction_plan_entries' }
    & Pick<Construction_Plan_Entries, 'id' | 'name' | 'days_after'>
    & { construction_plan: (
      { __typename?: 'construction_plans' }
      & Pick<Construction_Plans, 'name'>
    ) }
  )> }
);

export type InsertConstructionPlanEntryMutationVariables = Exact<{
  constructionPlanEntry: Construction_Plan_Entries_Insert_Input;
}>;


export type InsertConstructionPlanEntryMutation = (
  { __typename?: 'mutation_root' }
  & { insert_construction_plan_entries?: Maybe<(
    { __typename?: 'construction_plan_entries_mutation_response' }
    & { returning: Array<(
      { __typename?: 'construction_plan_entries' }
      & Pick<Construction_Plan_Entries, 'id' | 'name' | 'days_after'>
    )> }
  )> }
);

export type UpdateConstructionPlanEntryMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  constructionPlanEntry: Construction_Plan_Entries_Set_Input;
}>;


export type UpdateConstructionPlanEntryMutation = (
  { __typename?: 'mutation_root' }
  & { update_construction_plan_entries?: Maybe<(
    { __typename?: 'construction_plan_entries_mutation_response' }
    & { returning: Array<(
      { __typename?: 'construction_plan_entries' }
      & Pick<Construction_Plan_Entries, 'id' | 'name' | 'days_after'>
    )> }
  )> }
);

export type GetConstructionPlanQueryVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;


export type GetConstructionPlanQuery = (
  { __typename?: 'query_root' }
  & { construction_plans: Array<(
    { __typename?: 'construction_plans' }
    & Pick<Construction_Plans, 'id' | 'name'>
    & { entries: Array<(
      { __typename?: 'construction_plan_entries' }
      & Pick<Construction_Plan_Entries, 'days_after' | 'id' | 'name' | 'construction_plan_id'>
    )> }
  )> }
);

export type InsertConstructionPlanMutationVariables = Exact<{
  constructionPlan: Construction_Plans_Insert_Input;
}>;


export type InsertConstructionPlanMutation = (
  { __typename?: 'mutation_root' }
  & { insert_construction_plans?: Maybe<(
    { __typename?: 'construction_plans_mutation_response' }
    & { returning: Array<(
      { __typename?: 'construction_plans' }
      & Pick<Construction_Plans, 'id' | 'name'>
    )> }
  )> }
);

export type UpdateConstructionPlanMutationVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
  constructionPlan: Construction_Plans_Set_Input;
}>;


export type UpdateConstructionPlanMutation = (
  { __typename?: 'mutation_root' }
  & { update_construction_plans?: Maybe<(
    { __typename?: 'construction_plans_mutation_response' }
    & { returning: Array<(
      { __typename?: 'construction_plans' }
      & Pick<Construction_Plans, 'id' | 'name'>
    )> }
  )> }
);

export type CaseControlBuildingInspectionQueryVariables = Exact<{
  orderBy: Cases_Order_By;
  where?: Maybe<Cases_Bool_Exp>;
}>;


export type CaseControlBuildingInspectionQuery = (
  { __typename?: 'query_root' }
  & { cases: Array<(
    { __typename?: 'cases' }
    & Pick<Cases, 'id' | 'task' | 'description' | 'responsible_id' | 'company_id' | 'address' | 'zip_code' | 'city' | 'case_type_id' | 'date_of_inspection' | 'estimated_time' | 'other_address' | 'changed' | 'flag'>
    & { responsible?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'acronym'>
    )>, builder?: Maybe<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id' | 'address' | 'zip_code' | 'city'>
    )>, case_files: Array<(
      { __typename?: 'case_files' }
      & Pick<Case_Files, 'id'>
    )>, case_photos: Array<(
      { __typename?: 'case_photos' }
      & { file: (
        { __typename?: 'files' }
        & Pick<Files, 'created'>
      ) }
    )>, reminders: Array<(
      { __typename?: 'reminders' }
      & Pick<Reminders, 'id' | 'date'>
    )>, checklists_aggregate: (
      { __typename?: 'case_doc_checklists_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'case_doc_checklists_aggregate_fields' }
        & Pick<Case_Doc_Checklists_Aggregate_Fields, 'count'>
      )> }
    ), quality_controls_aggregate: (
      { __typename?: 'case_doc_quality_controls_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'case_doc_quality_controls_aggregate_fields' }
        & Pick<Case_Doc_Quality_Controls_Aggregate_Fields, 'count'>
      )> }
    ) }
  )> }
);

export type CaseControlMoistureTechnologyQueryVariables = Exact<{
  orderBy: Cases_Order_By;
  where?: Maybe<Cases_Bool_Exp>;
}>;


export type CaseControlMoistureTechnologyQuery = (
  { __typename?: 'query_root' }
  & { cases: Array<(
    { __typename?: 'cases' }
    & Pick<Cases, 'id' | 'task' | 'description' | 'responsible_id' | 'address' | 'zip_code' | 'city' | 'case_type_id' | 'date_of_inspection' | 'estimated_time' | 'other_address' | 'changed'>
    & { responsible?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'acronym'>
    )>, quality_controls: Array<(
      { __typename?: 'case_doc_quality_controls' }
      & Pick<Case_Doc_Quality_Controls, 'id'>
    )>, checklists: Array<(
      { __typename?: 'case_doc_checklists' }
      & Pick<Case_Doc_Checklists, 'id'>
    )>, builder?: Maybe<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id' | 'address' | 'zip_code' | 'city'>
    )>, case_type?: Maybe<(
      { __typename?: 'case_types' }
      & Pick<Case_Types, 'name'>
    )>, case_files: Array<(
      { __typename?: 'case_files' }
      & Pick<Case_Files, 'id'>
    )>, case_photos: Array<(
      { __typename?: 'case_photos' }
      & { file: (
        { __typename?: 'files' }
        & Pick<Files, 'created'>
      ) }
    )>, reminders: Array<(
      { __typename?: 'reminders' }
      & Pick<Reminders, 'id' | 'date'>
    )> }
  )> }
);

export type Case_StatsQueryVariables = Exact<{
  startMonth?: Maybe<Scalars['String']>;
  endMonth?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
}>;


export type Case_StatsQuery = (
  { __typename?: 'query_root' }
  & { cases: Array<(
    { __typename?: 'cases' }
    & { case_type?: Maybe<(
      { __typename?: 'case_types' }
      & Pick<Case_Types, 'name'>
    )> }
  )>, cases_case_type_count: Array<(
    { __typename?: 'cases_case_type_count' }
    & Pick<Cases_Case_Type_Count, 'count' | 'case_type_id'>
    & { case_type?: Maybe<(
      { __typename?: 'case_types' }
      & Pick<Case_Types, 'name'>
    )> }
  )>, cases_case_count_per_employee: Array<(
    { __typename?: 'cases_case_count_per_employee' }
    & Pick<Cases_Case_Count_Per_Employee, 'count' | 'responsible_id'>
    & { responsible?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'first_name' | 'last_name' | 'acronym' | 'employee_number'>
    )> }
  )>, cases_building_inspection_per_employee: Array<(
    { __typename?: 'cases_building_inspection_per_employee' }
    & Pick<Cases_Building_Inspection_Per_Employee, 'case_type_id' | 'count' | 'responsible_id'>
    & { case_type?: Maybe<(
      { __typename?: 'case_types' }
      & Pick<Case_Types, 'name'>
    )>, responsible?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'first_name' | 'last_name' | 'acronym' | 'employee_number'>
    )> }
  )>, cases_per_case_type_per_month: Array<(
    { __typename?: 'cases_per_case_type_per_month' }
    & Pick<Cases_Per_Case_Type_Per_Month, 'case_type_id' | 'count' | 'year_month'>
    & { case_type?: Maybe<(
      { __typename?: 'case_types' }
      & Pick<Case_Types, 'color' | 'name'>
    )> }
  )> }
);

export type RequestStatesNotUserQueryVariables = Exact<{
  startDate?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['timestamptz']>;
}>;


export type RequestStatesNotUserQuery = (
  { __typename?: 'query_root' }
  & { cases: Array<(
    { __typename?: 'cases' }
    & Pick<Cases, 'created_by_id' | 'created'>
    & { case_type?: Maybe<(
      { __typename?: 'case_types' }
      & Pick<Case_Types, 'id' | 'name'>
    )>, created_by?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'acronym' | 'employee_number'>
    )> }
  )> }
);

export type CasesCreatedPerEmployeePerMonthQueryVariables = Exact<{
  startMonth?: Maybe<Scalars['String']>;
  endMonth?: Maybe<Scalars['String']>;
}>;


export type CasesCreatedPerEmployeePerMonthQuery = (
  { __typename?: 'query_root' }
  & { cases_created_per_employee_per_month: Array<(
    { __typename?: 'cases_created_per_employee_per_month' }
    & Pick<Cases_Created_Per_Employee_Per_Month, 'count' | 'created_by_id' | 'year_month'>
    & { employee?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'acronym' | 'employee_number'>
    )> }
  )> }
);

export type RequestStatsQueryVariables = Exact<{
  startDate?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['timestamptz']>;
}>;


export type RequestStatsQuery = (
  { __typename?: 'query_root' }
  & { cases: Array<(
    { __typename?: 'cases' }
    & Pick<Cases, 'responsible_id' | 'created'>
    & { case_type?: Maybe<(
      { __typename?: 'case_types' }
      & Pick<Case_Types, 'id' | 'name'>
    )>, responsible?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'acronym' | 'employee_number'>
    )> }
  )> }
);

export type CasesCreatedEmployeeNumbersQueryVariables = Exact<{
  startMonth?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['timestamptz']>;
}>;


export type CasesCreatedEmployeeNumbersQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'employee_number' | 'acronym'>
  )> }
);

export type ClosedCaseRemindersQueryVariables = Exact<{
  orderBy: Cases_Order_By;
  where?: Maybe<Cases_Bool_Exp>;
}>;


export type ClosedCaseRemindersQuery = (
  { __typename?: 'query_root' }
  & { cases: Array<(
    { __typename?: 'cases' }
    & Pick<Cases, 'id' | 'task' | 'description' | 'responsible_id' | 'address' | 'zip_code' | 'city' | 'case_type_id' | 'date_of_inspection' | 'other_address' | 'changed' | 'closed'>
    & { responsible?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'id' | 'acronym'>
    )>, builder?: Maybe<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id' | 'address' | 'zip_code' | 'city'>
    )>, case_type?: Maybe<(
      { __typename?: 'case_types' }
      & Pick<Case_Types, 'name'>
    )>, reminders: Array<(
      { __typename?: 'reminders' }
      & Pick<Reminders, 'id' | 'date'>
    )> }
  )> }
);

export type RequestExternalCaseStatsForUserQueryVariables = Exact<{
  startDate?: Maybe<Scalars['timestamptz']>;
  contactId?: Maybe<Scalars['uuid']>;
  endDate?: Maybe<Scalars['timestamptz']>;
}>;


export type RequestExternalCaseStatsForUserQuery = (
  { __typename?: 'query_root' }
  & { requests: Array<(
    { __typename?: 'requests' }
    & Pick<Requests, 'task' | 'id'>
    & { case_type?: Maybe<(
      { __typename?: 'case_types' }
      & Pick<Case_Types, 'name' | 'price'>
    )> }
  )> }
);

export type RequestExternalCaseStatsQueryVariables = Exact<{
  startDate?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['timestamptz']>;
}>;


export type RequestExternalCaseStatsQuery = (
  { __typename?: 'query_root' }
  & { requests: Array<(
    { __typename?: 'requests' }
    & Pick<Requests, 'task' | 'id'>
    & { case_type?: Maybe<(
      { __typename?: 'case_types' }
      & Pick<Case_Types, 'name' | 'price'>
    )> }
  )> }
);

export type CasesWithConstructionPlansQueryVariables = Exact<{
  where?: Maybe<Cases_Bool_Exp>;
}>;


export type CasesWithConstructionPlansQuery = (
  { __typename?: 'query_root' }
  & { cases: Array<(
    { __typename?: 'cases' }
    & Pick<Cases, 'id' | 'task' | 'description' | 'address' | 'zip_code' | 'city' | 'date_of_inspection' | 'other_address'>
    & { builder?: Maybe<(
      { __typename?: 'contacts' }
      & Pick<Contacts, 'id' | 'address' | 'zip_code' | 'city'>
    )>, construction_plan?: Maybe<(
      { __typename?: 'construction_plans' }
      & { entries: Array<(
        { __typename?: 'construction_plan_entries' }
        & Pick<Construction_Plan_Entries, 'days_after' | 'name' | 'id'>
      )> }
    )> }
  )> }
);

export type RequestsDonePerEmployeePerMonthQueryVariables = Exact<{
  startMonth?: Maybe<Scalars['String']>;
  endMonth?: Maybe<Scalars['String']>;
}>;


export type RequestsDonePerEmployeePerMonthQuery = (
  { __typename?: 'query_root' }
  & { requests_done_per_employee_per_month: Array<(
    { __typename?: 'requests_done_per_employee_per_month' }
    & Pick<Requests_Done_Per_Employee_Per_Month, 'count' | 'responsible_id' | 'year_month'>
    & { employee?: Maybe<(
      { __typename?: 'users' }
      & Pick<Users, 'acronym'>
    )> }
  )> }
);

export type MultipleStatsQueryVariables = Exact<{
  date?: Maybe<Scalars['timestamptz']>;
  startMonth?: Maybe<Scalars['String']>;
}>;


export type MultipleStatsQuery = (
  { __typename?: 'query_root' }
  & { requests_new_task_count: Array<(
    { __typename?: 'requests_new_task_count' }
    & Pick<Requests_New_Task_Count, 'count'>
    & { case_type?: Maybe<(
      { __typename?: 'case_types' }
      & Pick<Case_Types, 'name'>
    )> }
  )>, requests: Array<(
    { __typename?: 'requests' }
    & Pick<Requests, 'date'>
    & { case_type?: Maybe<(
      { __typename?: 'case_types' }
      & Pick<Case_Types, 'name'>
    )> }
  )>, requests_followup_task_count: Array<(
    { __typename?: 'requests_followup_task_count' }
    & Pick<Requests_Followup_Task_Count, 'count' | 'from_date' | 'to_date'>
    & { case_type?: Maybe<(
      { __typename?: 'case_types' }
      & Pick<Case_Types, 'name'>
    )> }
  )>, requests_per_case_type_per_month: Array<(
    { __typename?: 'requests_per_case_type_per_month' }
    & Pick<Requests_Per_Case_Type_Per_Month, 'case_type_id' | 'count' | 'year_month'>
    & { case_type?: Maybe<(
      { __typename?: 'case_types' }
      & Pick<Case_Types, 'price'>
    )> }
  )> }
);

export type Request_FollowupQueryVariables = Exact<{
  userId?: Maybe<Scalars['uuid']>;
}>;


export type Request_FollowupQuery = (
  { __typename?: 'query_root' }
  & { requests_followup_task_count_for_user: Array<(
    { __typename?: 'requests_followup_task_count' }
    & Pick<Requests_Followup_Task_Count, 'count' | 'from_date' | 'to_date'>
    & { case_type?: Maybe<(
      { __typename?: 'case_types' }
      & Pick<Case_Types, 'name'>
    )> }
  )> }
);


export const GetAbsenceTypesDocument = gql`
    query getAbsenceTypes($orderBy: absence_types_order_by!, $where: absence_types_bool_exp, $limit: Int) {
  absence_types(order_by: [$orderBy], where: $where, limit: $limit) {
    id
    label
    active
  }
}
    `;

/**
 * __useGetAbsenceTypesQuery__
 *
 * To run a query within a React component, call `useGetAbsenceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAbsenceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAbsenceTypesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAbsenceTypesQuery(baseOptions: Apollo.QueryHookOptions<GetAbsenceTypesQuery, GetAbsenceTypesQueryVariables>) {
        return Apollo.useQuery<GetAbsenceTypesQuery, GetAbsenceTypesQueryVariables>(GetAbsenceTypesDocument, baseOptions);
      }
export function useGetAbsenceTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAbsenceTypesQuery, GetAbsenceTypesQueryVariables>) {
          return Apollo.useLazyQuery<GetAbsenceTypesQuery, GetAbsenceTypesQueryVariables>(GetAbsenceTypesDocument, baseOptions);
        }
export type GetAbsenceTypesQueryHookResult = ReturnType<typeof useGetAbsenceTypesQuery>;
export type GetAbsenceTypesLazyQueryHookResult = ReturnType<typeof useGetAbsenceTypesLazyQuery>;
export type GetAbsenceTypesQueryResult = Apollo.QueryResult<GetAbsenceTypesQuery, GetAbsenceTypesQueryVariables>;
export const List_Archive_FilesDocument = gql`
    query list_archive_files($orderBy: archive_files_order_by!, $where: archive_files_bool_exp, $limit: Int) {
  archive_files(order_by: [$orderBy], where: $where, limit: $limit) {
    id
    file_id
    title
    file {
      id
      share_key
      filename
      created
    }
  }
}
    `;

/**
 * __useList_Archive_FilesQuery__
 *
 * To run a query within a React component, call `useList_Archive_FilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_Archive_FilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_Archive_FilesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useList_Archive_FilesQuery(baseOptions: Apollo.QueryHookOptions<List_Archive_FilesQuery, List_Archive_FilesQueryVariables>) {
        return Apollo.useQuery<List_Archive_FilesQuery, List_Archive_FilesQueryVariables>(List_Archive_FilesDocument, baseOptions);
      }
export function useList_Archive_FilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_Archive_FilesQuery, List_Archive_FilesQueryVariables>) {
          return Apollo.useLazyQuery<List_Archive_FilesQuery, List_Archive_FilesQueryVariables>(List_Archive_FilesDocument, baseOptions);
        }
export type List_Archive_FilesQueryHookResult = ReturnType<typeof useList_Archive_FilesQuery>;
export type List_Archive_FilesLazyQueryHookResult = ReturnType<typeof useList_Archive_FilesLazyQuery>;
export type List_Archive_FilesQueryResult = Apollo.QueryResult<List_Archive_FilesQuery, List_Archive_FilesQueryVariables>;
export const Insert_Archive_FileDocument = gql`
    mutation insert_archive_file($archive_files: [archive_files_insert_input!]!) {
  insert_archive_files(objects: $archive_files) {
    returning {
      id
      file_id
      title
      file {
        id
        share_key
        filename
        created
      }
    }
  }
}
    `;
export type Insert_Archive_FileMutationFn = Apollo.MutationFunction<Insert_Archive_FileMutation, Insert_Archive_FileMutationVariables>;

/**
 * __useInsert_Archive_FileMutation__
 *
 * To run a mutation, you first call `useInsert_Archive_FileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Archive_FileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertArchiveFileMutation, { data, loading, error }] = useInsert_Archive_FileMutation({
 *   variables: {
 *      archive_files: // value for 'archive_files'
 *   },
 * });
 */
export function useInsert_Archive_FileMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Archive_FileMutation, Insert_Archive_FileMutationVariables>) {
        return Apollo.useMutation<Insert_Archive_FileMutation, Insert_Archive_FileMutationVariables>(Insert_Archive_FileDocument, baseOptions);
      }
export type Insert_Archive_FileMutationHookResult = ReturnType<typeof useInsert_Archive_FileMutation>;
export type Insert_Archive_FileMutationResult = Apollo.MutationResult<Insert_Archive_FileMutation>;
export type Insert_Archive_FileMutationOptions = Apollo.BaseMutationOptions<Insert_Archive_FileMutation, Insert_Archive_FileMutationVariables>;
export const Get_Archive_FilesDocument = gql`
    query get_archive_files($fileId: uuid) {
  archive_files(where: {id: {_eq: $fileId}}) {
    id
    file_id
    title
    file {
      id
      filename
      share_key
      created
    }
  }
}
    `;

/**
 * __useGet_Archive_FilesQuery__
 *
 * To run a query within a React component, call `useGet_Archive_FilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Archive_FilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Archive_FilesQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useGet_Archive_FilesQuery(baseOptions?: Apollo.QueryHookOptions<Get_Archive_FilesQuery, Get_Archive_FilesQueryVariables>) {
        return Apollo.useQuery<Get_Archive_FilesQuery, Get_Archive_FilesQueryVariables>(Get_Archive_FilesDocument, baseOptions);
      }
export function useGet_Archive_FilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Archive_FilesQuery, Get_Archive_FilesQueryVariables>) {
          return Apollo.useLazyQuery<Get_Archive_FilesQuery, Get_Archive_FilesQueryVariables>(Get_Archive_FilesDocument, baseOptions);
        }
export type Get_Archive_FilesQueryHookResult = ReturnType<typeof useGet_Archive_FilesQuery>;
export type Get_Archive_FilesLazyQueryHookResult = ReturnType<typeof useGet_Archive_FilesLazyQuery>;
export type Get_Archive_FilesQueryResult = Apollo.QueryResult<Get_Archive_FilesQuery, Get_Archive_FilesQueryVariables>;
export const Update_Archive_FileDocument = gql`
    mutation update_archive_file($id: uuid, $archive_file: archive_files_set_input!) {
  update_archive_files(where: {id: {_eq: $id}}, _set: $archive_file) {
    returning {
      id
      file_id
      title
      file {
        id
        share_key
        filename
        created
      }
    }
  }
}
    `;
export type Update_Archive_FileMutationFn = Apollo.MutationFunction<Update_Archive_FileMutation, Update_Archive_FileMutationVariables>;

/**
 * __useUpdate_Archive_FileMutation__
 *
 * To run a mutation, you first call `useUpdate_Archive_FileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Archive_FileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArchiveFileMutation, { data, loading, error }] = useUpdate_Archive_FileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive_file: // value for 'archive_file'
 *   },
 * });
 */
export function useUpdate_Archive_FileMutation(baseOptions?: Apollo.MutationHookOptions<Update_Archive_FileMutation, Update_Archive_FileMutationVariables>) {
        return Apollo.useMutation<Update_Archive_FileMutation, Update_Archive_FileMutationVariables>(Update_Archive_FileDocument, baseOptions);
      }
export type Update_Archive_FileMutationHookResult = ReturnType<typeof useUpdate_Archive_FileMutation>;
export type Update_Archive_FileMutationResult = Apollo.MutationResult<Update_Archive_FileMutation>;
export type Update_Archive_FileMutationOptions = Apollo.BaseMutationOptions<Update_Archive_FileMutation, Update_Archive_FileMutationVariables>;
export const Delete_Archive_FilesDocument = gql`
    mutation delete_archive_files($where: archive_files_bool_exp!) {
  delete_archive_files(where: $where) {
    affected_rows
  }
}
    `;
export type Delete_Archive_FilesMutationFn = Apollo.MutationFunction<Delete_Archive_FilesMutation, Delete_Archive_FilesMutationVariables>;

/**
 * __useDelete_Archive_FilesMutation__
 *
 * To run a mutation, you first call `useDelete_Archive_FilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Archive_FilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArchiveFilesMutation, { data, loading, error }] = useDelete_Archive_FilesMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDelete_Archive_FilesMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Archive_FilesMutation, Delete_Archive_FilesMutationVariables>) {
        return Apollo.useMutation<Delete_Archive_FilesMutation, Delete_Archive_FilesMutationVariables>(Delete_Archive_FilesDocument, baseOptions);
      }
export type Delete_Archive_FilesMutationHookResult = ReturnType<typeof useDelete_Archive_FilesMutation>;
export type Delete_Archive_FilesMutationResult = Apollo.MutationResult<Delete_Archive_FilesMutation>;
export type Delete_Archive_FilesMutationOptions = Apollo.BaseMutationOptions<Delete_Archive_FilesMutation, Delete_Archive_FilesMutationVariables>;
export const Insert_Case_Doc_EconomyDocument = gql`
    mutation insert_case_doc_economy($case_doc_economy: case_doc_economy_insert_input!) {
  insert_case_doc_economy(objects: [$case_doc_economy]) {
    returning {
      id
      type
      case_id
      title
      text
    }
  }
}
    `;
export type Insert_Case_Doc_EconomyMutationFn = Apollo.MutationFunction<Insert_Case_Doc_EconomyMutation, Insert_Case_Doc_EconomyMutationVariables>;

/**
 * __useInsert_Case_Doc_EconomyMutation__
 *
 * To run a mutation, you first call `useInsert_Case_Doc_EconomyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Case_Doc_EconomyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCaseDocEconomyMutation, { data, loading, error }] = useInsert_Case_Doc_EconomyMutation({
 *   variables: {
 *      case_doc_economy: // value for 'case_doc_economy'
 *   },
 * });
 */
export function useInsert_Case_Doc_EconomyMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Case_Doc_EconomyMutation, Insert_Case_Doc_EconomyMutationVariables>) {
        return Apollo.useMutation<Insert_Case_Doc_EconomyMutation, Insert_Case_Doc_EconomyMutationVariables>(Insert_Case_Doc_EconomyDocument, baseOptions);
      }
export type Insert_Case_Doc_EconomyMutationHookResult = ReturnType<typeof useInsert_Case_Doc_EconomyMutation>;
export type Insert_Case_Doc_EconomyMutationResult = Apollo.MutationResult<Insert_Case_Doc_EconomyMutation>;
export type Insert_Case_Doc_EconomyMutationOptions = Apollo.BaseMutationOptions<Insert_Case_Doc_EconomyMutation, Insert_Case_Doc_EconomyMutationVariables>;
export const Insert_Case_Doc_EconomysDocument = gql`
    mutation insert_case_doc_economys($case_doc_economy: [case_doc_economy_insert_input!]!) {
  insert_case_doc_economy(objects: $case_doc_economy) {
    returning {
      id
      type
      case_id
      title
      text
    }
  }
}
    `;
export type Insert_Case_Doc_EconomysMutationFn = Apollo.MutationFunction<Insert_Case_Doc_EconomysMutation, Insert_Case_Doc_EconomysMutationVariables>;

/**
 * __useInsert_Case_Doc_EconomysMutation__
 *
 * To run a mutation, you first call `useInsert_Case_Doc_EconomysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Case_Doc_EconomysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCaseDocEconomysMutation, { data, loading, error }] = useInsert_Case_Doc_EconomysMutation({
 *   variables: {
 *      case_doc_economy: // value for 'case_doc_economy'
 *   },
 * });
 */
export function useInsert_Case_Doc_EconomysMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Case_Doc_EconomysMutation, Insert_Case_Doc_EconomysMutationVariables>) {
        return Apollo.useMutation<Insert_Case_Doc_EconomysMutation, Insert_Case_Doc_EconomysMutationVariables>(Insert_Case_Doc_EconomysDocument, baseOptions);
      }
export type Insert_Case_Doc_EconomysMutationHookResult = ReturnType<typeof useInsert_Case_Doc_EconomysMutation>;
export type Insert_Case_Doc_EconomysMutationResult = Apollo.MutationResult<Insert_Case_Doc_EconomysMutation>;
export type Insert_Case_Doc_EconomysMutationOptions = Apollo.BaseMutationOptions<Insert_Case_Doc_EconomysMutation, Insert_Case_Doc_EconomysMutationVariables>;
export const Get_Case_Doc_EconomyDocument = gql`
    query get_case_doc_economy($economyId: uuid) {
  case_doc_economy(where: {id: {_eq: $economyId}}) {
    id
    case_id
    title
    text
    type
    document_date
    revision_date
  }
}
    `;

/**
 * __useGet_Case_Doc_EconomyQuery__
 *
 * To run a query within a React component, call `useGet_Case_Doc_EconomyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Case_Doc_EconomyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Case_Doc_EconomyQuery({
 *   variables: {
 *      economyId: // value for 'economyId'
 *   },
 * });
 */
export function useGet_Case_Doc_EconomyQuery(baseOptions?: Apollo.QueryHookOptions<Get_Case_Doc_EconomyQuery, Get_Case_Doc_EconomyQueryVariables>) {
        return Apollo.useQuery<Get_Case_Doc_EconomyQuery, Get_Case_Doc_EconomyQueryVariables>(Get_Case_Doc_EconomyDocument, baseOptions);
      }
export function useGet_Case_Doc_EconomyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Case_Doc_EconomyQuery, Get_Case_Doc_EconomyQueryVariables>) {
          return Apollo.useLazyQuery<Get_Case_Doc_EconomyQuery, Get_Case_Doc_EconomyQueryVariables>(Get_Case_Doc_EconomyDocument, baseOptions);
        }
export type Get_Case_Doc_EconomyQueryHookResult = ReturnType<typeof useGet_Case_Doc_EconomyQuery>;
export type Get_Case_Doc_EconomyLazyQueryHookResult = ReturnType<typeof useGet_Case_Doc_EconomyLazyQuery>;
export type Get_Case_Doc_EconomyQueryResult = Apollo.QueryResult<Get_Case_Doc_EconomyQuery, Get_Case_Doc_EconomyQueryVariables>;
export const Update_Case_Doc_EconomyDocument = gql`
    mutation update_case_doc_economy($id: uuid, $case_doc_economy: case_doc_economy_set_input!) {
  update_case_doc_economy(where: {id: {_eq: $id}}, _set: $case_doc_economy) {
    returning {
      id
      case_id
      title
      type
      text
      document_date
      revision_date
    }
  }
}
    `;
export type Update_Case_Doc_EconomyMutationFn = Apollo.MutationFunction<Update_Case_Doc_EconomyMutation, Update_Case_Doc_EconomyMutationVariables>;

/**
 * __useUpdate_Case_Doc_EconomyMutation__
 *
 * To run a mutation, you first call `useUpdate_Case_Doc_EconomyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Case_Doc_EconomyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseDocEconomyMutation, { data, loading, error }] = useUpdate_Case_Doc_EconomyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      case_doc_economy: // value for 'case_doc_economy'
 *   },
 * });
 */
export function useUpdate_Case_Doc_EconomyMutation(baseOptions?: Apollo.MutationHookOptions<Update_Case_Doc_EconomyMutation, Update_Case_Doc_EconomyMutationVariables>) {
        return Apollo.useMutation<Update_Case_Doc_EconomyMutation, Update_Case_Doc_EconomyMutationVariables>(Update_Case_Doc_EconomyDocument, baseOptions);
      }
export type Update_Case_Doc_EconomyMutationHookResult = ReturnType<typeof useUpdate_Case_Doc_EconomyMutation>;
export type Update_Case_Doc_EconomyMutationResult = Apollo.MutationResult<Update_Case_Doc_EconomyMutation>;
export type Update_Case_Doc_EconomyMutationOptions = Apollo.BaseMutationOptions<Update_Case_Doc_EconomyMutation, Update_Case_Doc_EconomyMutationVariables>;
export const Insert_Case_Doc_NoteDocument = gql`
    mutation insert_case_doc_note($case_doc_note: case_doc_notes_insert_input!) {
  insert_case_doc_notes(objects: [$case_doc_note]) {
    returning {
      id
      type
      case_id
      title
      text
    }
  }
}
    `;
export type Insert_Case_Doc_NoteMutationFn = Apollo.MutationFunction<Insert_Case_Doc_NoteMutation, Insert_Case_Doc_NoteMutationVariables>;

/**
 * __useInsert_Case_Doc_NoteMutation__
 *
 * To run a mutation, you first call `useInsert_Case_Doc_NoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Case_Doc_NoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCaseDocNoteMutation, { data, loading, error }] = useInsert_Case_Doc_NoteMutation({
 *   variables: {
 *      case_doc_note: // value for 'case_doc_note'
 *   },
 * });
 */
export function useInsert_Case_Doc_NoteMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Case_Doc_NoteMutation, Insert_Case_Doc_NoteMutationVariables>) {
        return Apollo.useMutation<Insert_Case_Doc_NoteMutation, Insert_Case_Doc_NoteMutationVariables>(Insert_Case_Doc_NoteDocument, baseOptions);
      }
export type Insert_Case_Doc_NoteMutationHookResult = ReturnType<typeof useInsert_Case_Doc_NoteMutation>;
export type Insert_Case_Doc_NoteMutationResult = Apollo.MutationResult<Insert_Case_Doc_NoteMutation>;
export type Insert_Case_Doc_NoteMutationOptions = Apollo.BaseMutationOptions<Insert_Case_Doc_NoteMutation, Insert_Case_Doc_NoteMutationVariables>;
export const Insert_Case_Doc_NotesDocument = gql`
    mutation insert_case_doc_notes($case_doc_notes: [case_doc_notes_insert_input!]!) {
  insert_case_doc_notes(objects: $case_doc_notes) {
    returning {
      id
      type
      case_id
      title
      text
    }
  }
}
    `;
export type Insert_Case_Doc_NotesMutationFn = Apollo.MutationFunction<Insert_Case_Doc_NotesMutation, Insert_Case_Doc_NotesMutationVariables>;

/**
 * __useInsert_Case_Doc_NotesMutation__
 *
 * To run a mutation, you first call `useInsert_Case_Doc_NotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Case_Doc_NotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCaseDocNotesMutation, { data, loading, error }] = useInsert_Case_Doc_NotesMutation({
 *   variables: {
 *      case_doc_notes: // value for 'case_doc_notes'
 *   },
 * });
 */
export function useInsert_Case_Doc_NotesMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Case_Doc_NotesMutation, Insert_Case_Doc_NotesMutationVariables>) {
        return Apollo.useMutation<Insert_Case_Doc_NotesMutation, Insert_Case_Doc_NotesMutationVariables>(Insert_Case_Doc_NotesDocument, baseOptions);
      }
export type Insert_Case_Doc_NotesMutationHookResult = ReturnType<typeof useInsert_Case_Doc_NotesMutation>;
export type Insert_Case_Doc_NotesMutationResult = Apollo.MutationResult<Insert_Case_Doc_NotesMutation>;
export type Insert_Case_Doc_NotesMutationOptions = Apollo.BaseMutationOptions<Insert_Case_Doc_NotesMutation, Insert_Case_Doc_NotesMutationVariables>;
export const Get_Case_Doc_NotesDocument = gql`
    query get_case_doc_notes($caseNoteId: uuid) {
  case_doc_notes(where: {id: {_eq: $caseNoteId}}) {
    id
    case_id
    title
    text
    type
    document_date
    revision_date
  }
}
    `;

/**
 * __useGet_Case_Doc_NotesQuery__
 *
 * To run a query within a React component, call `useGet_Case_Doc_NotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Case_Doc_NotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Case_Doc_NotesQuery({
 *   variables: {
 *      caseNoteId: // value for 'caseNoteId'
 *   },
 * });
 */
export function useGet_Case_Doc_NotesQuery(baseOptions?: Apollo.QueryHookOptions<Get_Case_Doc_NotesQuery, Get_Case_Doc_NotesQueryVariables>) {
        return Apollo.useQuery<Get_Case_Doc_NotesQuery, Get_Case_Doc_NotesQueryVariables>(Get_Case_Doc_NotesDocument, baseOptions);
      }
export function useGet_Case_Doc_NotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Case_Doc_NotesQuery, Get_Case_Doc_NotesQueryVariables>) {
          return Apollo.useLazyQuery<Get_Case_Doc_NotesQuery, Get_Case_Doc_NotesQueryVariables>(Get_Case_Doc_NotesDocument, baseOptions);
        }
export type Get_Case_Doc_NotesQueryHookResult = ReturnType<typeof useGet_Case_Doc_NotesQuery>;
export type Get_Case_Doc_NotesLazyQueryHookResult = ReturnType<typeof useGet_Case_Doc_NotesLazyQuery>;
export type Get_Case_Doc_NotesQueryResult = Apollo.QueryResult<Get_Case_Doc_NotesQuery, Get_Case_Doc_NotesQueryVariables>;
export const Update_Case_Doc_NoteDocument = gql`
    mutation update_case_doc_note($id: uuid, $case_doc_note: case_doc_notes_set_input!) {
  update_case_doc_notes(where: {id: {_eq: $id}}, _set: $case_doc_note) {
    returning {
      id
      case_id
      title
      type
      text
      document_date
      revision_date
    }
  }
}
    `;
export type Update_Case_Doc_NoteMutationFn = Apollo.MutationFunction<Update_Case_Doc_NoteMutation, Update_Case_Doc_NoteMutationVariables>;

/**
 * __useUpdate_Case_Doc_NoteMutation__
 *
 * To run a mutation, you first call `useUpdate_Case_Doc_NoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Case_Doc_NoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseDocNoteMutation, { data, loading, error }] = useUpdate_Case_Doc_NoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      case_doc_note: // value for 'case_doc_note'
 *   },
 * });
 */
export function useUpdate_Case_Doc_NoteMutation(baseOptions?: Apollo.MutationHookOptions<Update_Case_Doc_NoteMutation, Update_Case_Doc_NoteMutationVariables>) {
        return Apollo.useMutation<Update_Case_Doc_NoteMutation, Update_Case_Doc_NoteMutationVariables>(Update_Case_Doc_NoteDocument, baseOptions);
      }
export type Update_Case_Doc_NoteMutationHookResult = ReturnType<typeof useUpdate_Case_Doc_NoteMutation>;
export type Update_Case_Doc_NoteMutationResult = Apollo.MutationResult<Update_Case_Doc_NoteMutation>;
export type Update_Case_Doc_NoteMutationOptions = Apollo.BaseMutationOptions<Update_Case_Doc_NoteMutation, Update_Case_Doc_NoteMutationVariables>;
export const Get_TimeDocument = gql`
    query get_time($timeId: uuid!) {
  case_time_entries_by_pk(id: $timeId) {
    date
    description
    id
    time
    user_id
    user {
      acronym
      first_name
      last_name
      mail
    }
  }
}
    `;

/**
 * __useGet_TimeQuery__
 *
 * To run a query within a React component, call `useGet_TimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_TimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_TimeQuery({
 *   variables: {
 *      timeId: // value for 'timeId'
 *   },
 * });
 */
export function useGet_TimeQuery(baseOptions: Apollo.QueryHookOptions<Get_TimeQuery, Get_TimeQueryVariables>) {
        return Apollo.useQuery<Get_TimeQuery, Get_TimeQueryVariables>(Get_TimeDocument, baseOptions);
      }
export function useGet_TimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_TimeQuery, Get_TimeQueryVariables>) {
          return Apollo.useLazyQuery<Get_TimeQuery, Get_TimeQueryVariables>(Get_TimeDocument, baseOptions);
        }
export type Get_TimeQueryHookResult = ReturnType<typeof useGet_TimeQuery>;
export type Get_TimeLazyQueryHookResult = ReturnType<typeof useGet_TimeLazyQuery>;
export type Get_TimeQueryResult = Apollo.QueryResult<Get_TimeQuery, Get_TimeQueryVariables>;
export const Insert_Case_TimeDocument = gql`
    mutation insert_case_time($caseTime: case_time_entries_insert_input!) {
  insert_case_time_entries(objects: [$caseTime]) {
    returning {
      date
      description
      id
      time
      user_id
      user {
        acronym
        first_name
        last_name
        mail
      }
    }
  }
}
    `;
export type Insert_Case_TimeMutationFn = Apollo.MutationFunction<Insert_Case_TimeMutation, Insert_Case_TimeMutationVariables>;

/**
 * __useInsert_Case_TimeMutation__
 *
 * To run a mutation, you first call `useInsert_Case_TimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Case_TimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCaseTimeMutation, { data, loading, error }] = useInsert_Case_TimeMutation({
 *   variables: {
 *      caseTime: // value for 'caseTime'
 *   },
 * });
 */
export function useInsert_Case_TimeMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Case_TimeMutation, Insert_Case_TimeMutationVariables>) {
        return Apollo.useMutation<Insert_Case_TimeMutation, Insert_Case_TimeMutationVariables>(Insert_Case_TimeDocument, baseOptions);
      }
export type Insert_Case_TimeMutationHookResult = ReturnType<typeof useInsert_Case_TimeMutation>;
export type Insert_Case_TimeMutationResult = Apollo.MutationResult<Insert_Case_TimeMutation>;
export type Insert_Case_TimeMutationOptions = Apollo.BaseMutationOptions<Insert_Case_TimeMutation, Insert_Case_TimeMutationVariables>;
export const Update_Case_TimeDocument = gql`
    mutation update_case_time($id: uuid, $caseTime: case_time_entries_set_input!) {
  update_case_time_entries(where: {id: {_eq: $id}}, _set: $caseTime) {
    returning {
      date
      description
      id
      time
      user_id
      user {
        acronym
        first_name
        last_name
        mail
      }
    }
  }
}
    `;
export type Update_Case_TimeMutationFn = Apollo.MutationFunction<Update_Case_TimeMutation, Update_Case_TimeMutationVariables>;

/**
 * __useUpdate_Case_TimeMutation__
 *
 * To run a mutation, you first call `useUpdate_Case_TimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Case_TimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseTimeMutation, { data, loading, error }] = useUpdate_Case_TimeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      caseTime: // value for 'caseTime'
 *   },
 * });
 */
export function useUpdate_Case_TimeMutation(baseOptions?: Apollo.MutationHookOptions<Update_Case_TimeMutation, Update_Case_TimeMutationVariables>) {
        return Apollo.useMutation<Update_Case_TimeMutation, Update_Case_TimeMutationVariables>(Update_Case_TimeDocument, baseOptions);
      }
export type Update_Case_TimeMutationHookResult = ReturnType<typeof useUpdate_Case_TimeMutation>;
export type Update_Case_TimeMutationResult = Apollo.MutationResult<Update_Case_TimeMutation>;
export type Update_Case_TimeMutationOptions = Apollo.BaseMutationOptions<Update_Case_TimeMutation, Update_Case_TimeMutationVariables>;
export const Case_Types_AgregateDocument = gql`
    query case_types_agregate($orderBy: case_types_order_by!, $where: case_types_bool_exp, $offset: Int, $limit: Int) {
  case_types_aggregate(
    offset: $offset
    order_by: [$orderBy]
    where: $where
    limit: $limit
  ) {
    aggregate {
      count
    }
    nodes {
      id
      name
      icon
      color
      price
    }
  }
}
    `;

/**
 * __useCase_Types_AgregateQuery__
 *
 * To run a query within a React component, call `useCase_Types_AgregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCase_Types_AgregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCase_Types_AgregateQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCase_Types_AgregateQuery(baseOptions: Apollo.QueryHookOptions<Case_Types_AgregateQuery, Case_Types_AgregateQueryVariables>) {
        return Apollo.useQuery<Case_Types_AgregateQuery, Case_Types_AgregateQueryVariables>(Case_Types_AgregateDocument, baseOptions);
      }
export function useCase_Types_AgregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Case_Types_AgregateQuery, Case_Types_AgregateQueryVariables>) {
          return Apollo.useLazyQuery<Case_Types_AgregateQuery, Case_Types_AgregateQueryVariables>(Case_Types_AgregateDocument, baseOptions);
        }
export type Case_Types_AgregateQueryHookResult = ReturnType<typeof useCase_Types_AgregateQuery>;
export type Case_Types_AgregateLazyQueryHookResult = ReturnType<typeof useCase_Types_AgregateLazyQuery>;
export type Case_Types_AgregateQueryResult = Apollo.QueryResult<Case_Types_AgregateQuery, Case_Types_AgregateQueryVariables>;
export const Get_Case_TypesDocument = gql`
    query get_case_types($caseTypeId: uuid) {
  case_types(where: {id: {_eq: $caseTypeId}}) {
    id
    name
    icon
    color
    price
  }
}
    `;

/**
 * __useGet_Case_TypesQuery__
 *
 * To run a query within a React component, call `useGet_Case_TypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Case_TypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Case_TypesQuery({
 *   variables: {
 *      caseTypeId: // value for 'caseTypeId'
 *   },
 * });
 */
export function useGet_Case_TypesQuery(baseOptions?: Apollo.QueryHookOptions<Get_Case_TypesQuery, Get_Case_TypesQueryVariables>) {
        return Apollo.useQuery<Get_Case_TypesQuery, Get_Case_TypesQueryVariables>(Get_Case_TypesDocument, baseOptions);
      }
export function useGet_Case_TypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Case_TypesQuery, Get_Case_TypesQueryVariables>) {
          return Apollo.useLazyQuery<Get_Case_TypesQuery, Get_Case_TypesQueryVariables>(Get_Case_TypesDocument, baseOptions);
        }
export type Get_Case_TypesQueryHookResult = ReturnType<typeof useGet_Case_TypesQuery>;
export type Get_Case_TypesLazyQueryHookResult = ReturnType<typeof useGet_Case_TypesLazyQuery>;
export type Get_Case_TypesQueryResult = Apollo.QueryResult<Get_Case_TypesQuery, Get_Case_TypesQueryVariables>;
export const Case_Types_CheckDocument = gql`
    query case_types_check($name: String!, $icon: String!, $color: String!) {
  case_types(
    where: {_or: [{name: {_eq: $name}}, {color: {_eq: $color}, _and: {icon: {_eq: $icon}}}]}
  ) {
    id
  }
}
    `;

/**
 * __useCase_Types_CheckQuery__
 *
 * To run a query within a React component, call `useCase_Types_CheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useCase_Types_CheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCase_Types_CheckQuery({
 *   variables: {
 *      name: // value for 'name'
 *      icon: // value for 'icon'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useCase_Types_CheckQuery(baseOptions: Apollo.QueryHookOptions<Case_Types_CheckQuery, Case_Types_CheckQueryVariables>) {
        return Apollo.useQuery<Case_Types_CheckQuery, Case_Types_CheckQueryVariables>(Case_Types_CheckDocument, baseOptions);
      }
export function useCase_Types_CheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Case_Types_CheckQuery, Case_Types_CheckQueryVariables>) {
          return Apollo.useLazyQuery<Case_Types_CheckQuery, Case_Types_CheckQueryVariables>(Case_Types_CheckDocument, baseOptions);
        }
export type Case_Types_CheckQueryHookResult = ReturnType<typeof useCase_Types_CheckQuery>;
export type Case_Types_CheckLazyQueryHookResult = ReturnType<typeof useCase_Types_CheckLazyQuery>;
export type Case_Types_CheckQueryResult = Apollo.QueryResult<Case_Types_CheckQuery, Case_Types_CheckQueryVariables>;
export const Insert_Case_TypeDocument = gql`
    mutation insert_case_type($caseType: case_types_insert_input!) {
  insert_case_types(objects: [$caseType]) {
    returning {
      id
      name
      icon
      color
      price
    }
  }
}
    `;
export type Insert_Case_TypeMutationFn = Apollo.MutationFunction<Insert_Case_TypeMutation, Insert_Case_TypeMutationVariables>;

/**
 * __useInsert_Case_TypeMutation__
 *
 * To run a mutation, you first call `useInsert_Case_TypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Case_TypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCaseTypeMutation, { data, loading, error }] = useInsert_Case_TypeMutation({
 *   variables: {
 *      caseType: // value for 'caseType'
 *   },
 * });
 */
export function useInsert_Case_TypeMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Case_TypeMutation, Insert_Case_TypeMutationVariables>) {
        return Apollo.useMutation<Insert_Case_TypeMutation, Insert_Case_TypeMutationVariables>(Insert_Case_TypeDocument, baseOptions);
      }
export type Insert_Case_TypeMutationHookResult = ReturnType<typeof useInsert_Case_TypeMutation>;
export type Insert_Case_TypeMutationResult = Apollo.MutationResult<Insert_Case_TypeMutation>;
export type Insert_Case_TypeMutationOptions = Apollo.BaseMutationOptions<Insert_Case_TypeMutation, Insert_Case_TypeMutationVariables>;
export const Update_Case_TypeDocument = gql`
    mutation update_case_type($id: uuid, $caseType: case_types_set_input!) {
  update_case_types(where: {id: {_eq: $id}}, _set: $caseType) {
    returning {
      id
      name
      icon
      color
      price
    }
  }
}
    `;
export type Update_Case_TypeMutationFn = Apollo.MutationFunction<Update_Case_TypeMutation, Update_Case_TypeMutationVariables>;

/**
 * __useUpdate_Case_TypeMutation__
 *
 * To run a mutation, you first call `useUpdate_Case_TypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Case_TypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseTypeMutation, { data, loading, error }] = useUpdate_Case_TypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      caseType: // value for 'caseType'
 *   },
 * });
 */
export function useUpdate_Case_TypeMutation(baseOptions?: Apollo.MutationHookOptions<Update_Case_TypeMutation, Update_Case_TypeMutationVariables>) {
        return Apollo.useMutation<Update_Case_TypeMutation, Update_Case_TypeMutationVariables>(Update_Case_TypeDocument, baseOptions);
      }
export type Update_Case_TypeMutationHookResult = ReturnType<typeof useUpdate_Case_TypeMutation>;
export type Update_Case_TypeMutationResult = Apollo.MutationResult<Update_Case_TypeMutation>;
export type Update_Case_TypeMutationOptions = Apollo.BaseMutationOptions<Update_Case_TypeMutation, Update_Case_TypeMutationVariables>;
export const List_CasesDocument = gql`
    query list_cases($orderBy: cases_order_by!, $where: cases_bool_exp, $offset: Int, $limit: Int) {
  cases_aggregate(
    offset: $offset
    order_by: [$orderBy]
    where: $where
    limit: $limit
  ) {
    aggregate {
      count
    }
    nodes {
      id
      task
      description
      responsible_id
      responsible {
        id
        mail
        acronym
        first_name
        last_name
        phone
        mobile
      }
      builder_id
      builder {
        id
        first_name
        last_name
        company
        address
        zip_code
        city
        phone
        mobile
        mail
      }
      secondary_builder_id
      secondary_builder {
        id
        first_name
        last_name
        company
        address
        zip_code
        city
        phone
        mobile
        mail
      }
      changed
      closed
      address
      zip_code
      city
      case_type {
        id
        name
      }
      date_of_inspection
      estimated_time
      other_address
      flag
    }
  }
}
    `;

/**
 * __useList_CasesQuery__
 *
 * To run a query within a React component, call `useList_CasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_CasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_CasesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useList_CasesQuery(baseOptions: Apollo.QueryHookOptions<List_CasesQuery, List_CasesQueryVariables>) {
        return Apollo.useQuery<List_CasesQuery, List_CasesQueryVariables>(List_CasesDocument, baseOptions);
      }
export function useList_CasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_CasesQuery, List_CasesQueryVariables>) {
          return Apollo.useLazyQuery<List_CasesQuery, List_CasesQueryVariables>(List_CasesDocument, baseOptions);
        }
export type List_CasesQueryHookResult = ReturnType<typeof useList_CasesQuery>;
export type List_CasesLazyQueryHookResult = ReturnType<typeof useList_CasesLazyQuery>;
export type List_CasesQueryResult = Apollo.QueryResult<List_CasesQuery, List_CasesQueryVariables>;
export const List_Cases_OpenDocument = gql`
    query list_cases_open($orderBy: cases_order_by!, $offset: Int, $limit: Int) {
  cases_aggregate(
    offset: $offset
    order_by: [$orderBy]
    where: {closed: {_eq: true}}
    limit: $limit
  ) {
    aggregate {
      count
    }
    nodes {
      id
      task
      description
      responsible_id
      responsible {
        id
        mail
        acronym
        first_name
        last_name
        phone
        mobile
      }
      builder_id
      builder {
        id
        first_name
        last_name
        company
        address
        zip_code
        city
        phone
        mobile
        mail
        synced
      }
      secondary_builder_id
      secondary_builder {
        id
        first_name
        last_name
        company
        address
        zip_code
        city
        phone
        mobile
        mail
      }
      changed
      closed
      address
      zip_code
      city
      case_type {
        id
        name
      }
      date_of_inspection
      estimated_time
      flag
    }
  }
}
    `;

/**
 * __useList_Cases_OpenQuery__
 *
 * To run a query within a React component, call `useList_Cases_OpenQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_Cases_OpenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_Cases_OpenQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useList_Cases_OpenQuery(baseOptions: Apollo.QueryHookOptions<List_Cases_OpenQuery, List_Cases_OpenQueryVariables>) {
        return Apollo.useQuery<List_Cases_OpenQuery, List_Cases_OpenQueryVariables>(List_Cases_OpenDocument, baseOptions);
      }
export function useList_Cases_OpenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_Cases_OpenQuery, List_Cases_OpenQueryVariables>) {
          return Apollo.useLazyQuery<List_Cases_OpenQuery, List_Cases_OpenQueryVariables>(List_Cases_OpenDocument, baseOptions);
        }
export type List_Cases_OpenQueryHookResult = ReturnType<typeof useList_Cases_OpenQuery>;
export type List_Cases_OpenLazyQueryHookResult = ReturnType<typeof useList_Cases_OpenLazyQuery>;
export type List_Cases_OpenQueryResult = Apollo.QueryResult<List_Cases_OpenQuery, List_Cases_OpenQueryVariables>;
export const UpdateCaseInspectionDateDocument = gql`
    mutation updateCaseInspectionDate($id: uuid!, $date: String) {
  update_cases_by_pk(pk_columns: {id: $id}, _set: {date_of_inspection: $date}) {
    date_of_inspection
  }
}
    `;
export type UpdateCaseInspectionDateMutationFn = Apollo.MutationFunction<UpdateCaseInspectionDateMutation, UpdateCaseInspectionDateMutationVariables>;

/**
 * __useUpdateCaseInspectionDateMutation__
 *
 * To run a mutation, you first call `useUpdateCaseInspectionDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCaseInspectionDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseInspectionDateMutation, { data, loading, error }] = useUpdateCaseInspectionDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUpdateCaseInspectionDateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCaseInspectionDateMutation, UpdateCaseInspectionDateMutationVariables>) {
        return Apollo.useMutation<UpdateCaseInspectionDateMutation, UpdateCaseInspectionDateMutationVariables>(UpdateCaseInspectionDateDocument, baseOptions);
      }
export type UpdateCaseInspectionDateMutationHookResult = ReturnType<typeof useUpdateCaseInspectionDateMutation>;
export type UpdateCaseInspectionDateMutationResult = Apollo.MutationResult<UpdateCaseInspectionDateMutation>;
export type UpdateCaseInspectionDateMutationOptions = Apollo.BaseMutationOptions<UpdateCaseInspectionDateMutation, UpdateCaseInspectionDateMutationVariables>;
export const Update_CaseDocument = gql`
    mutation update_case($id: uuid, $case: cases_set_input!) {
  update_cases(where: {id: {_eq: $id}}, _set: $case) {
    returning {
      id
      task
      description
      responsible_id
      responsible {
        id
        mail
        acronym
        first_name
        last_name
        phone
        mobile
      }
      builder_id
      builder {
        id
        first_name
        last_name
        company
        address
        zip_code
        city
        phone
        mobile
        mail
        synced
      }
      secondary_builder_id
      secondary_builder {
        id
        first_name
        last_name
        company
        address
        zip_code
        city
        phone
        mobile
        mail
      }
      company_id
      company {
        id
        first_name
        last_name
        company
        address
        zip_code
        city
        phone
        mobile
        mail
      }
      changed
      closed
      address
      zip_code
      city
      case_type {
        id
        name
      }
      date_of_inspection
      estimated_time
      important_note
      lat
      lng
      other_address
      other_note
      toilet
      usefull_note
      flag
      suppliers {
        contact {
          id
          first_name
          last_name
          company
          address
          zip_code
          city
          phone
          mobile
          mail
        }
      }
      construction_plan_id
      construction_plan {
        id
        name
      }
    }
  }
}
    `;
export type Update_CaseMutationFn = Apollo.MutationFunction<Update_CaseMutation, Update_CaseMutationVariables>;

/**
 * __useUpdate_CaseMutation__
 *
 * To run a mutation, you first call `useUpdate_CaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_CaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseMutation, { data, loading, error }] = useUpdate_CaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      case: // value for 'case'
 *   },
 * });
 */
export function useUpdate_CaseMutation(baseOptions?: Apollo.MutationHookOptions<Update_CaseMutation, Update_CaseMutationVariables>) {
        return Apollo.useMutation<Update_CaseMutation, Update_CaseMutationVariables>(Update_CaseDocument, baseOptions);
      }
export type Update_CaseMutationHookResult = ReturnType<typeof useUpdate_CaseMutation>;
export type Update_CaseMutationResult = Apollo.MutationResult<Update_CaseMutation>;
export type Update_CaseMutationOptions = Apollo.BaseMutationOptions<Update_CaseMutation, Update_CaseMutationVariables>;
export const Insert_CaseDocument = gql`
    mutation insert_case($case: cases_insert_input!) {
  insert_cases(objects: [$case]) {
    returning {
      id
      task
      description
      responsible_id
      responsible {
        id
        mail
        acronym
        first_name
        last_name
        phone
        mobile
      }
      builder_id
      builder {
        id
        first_name
        last_name
        company
        address
        zip_code
        city
        phone
        mobile
        mail
        synced
      }
      secondary_builder_id
      secondary_builder {
        id
        first_name
        last_name
        company
        address
        zip_code
        city
        phone
        mobile
        mail
      }
      company_id
      company {
        id
        first_name
        last_name
        company
        address
        zip_code
        city
        phone
        mobile
        mail
      }
      changed
      closed
      address
      zip_code
      city
      flag
      case_type {
        id
        name
      }
      date_of_inspection
      estimated_time
      important_note
      lat
      lng
      other_address
      other_note
      toilet
      usefull_note
      suppliers {
        contact {
          id
          first_name
          last_name
          company
          address
          zip_code
          city
          phone
          mobile
          mail
        }
      }
      construction_plan_id
      construction_plan {
        id
        name
      }
    }
  }
}
    `;
export type Insert_CaseMutationFn = Apollo.MutationFunction<Insert_CaseMutation, Insert_CaseMutationVariables>;

/**
 * __useInsert_CaseMutation__
 *
 * To run a mutation, you first call `useInsert_CaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_CaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCaseMutation, { data, loading, error }] = useInsert_CaseMutation({
 *   variables: {
 *      case: // value for 'case'
 *   },
 * });
 */
export function useInsert_CaseMutation(baseOptions?: Apollo.MutationHookOptions<Insert_CaseMutation, Insert_CaseMutationVariables>) {
        return Apollo.useMutation<Insert_CaseMutation, Insert_CaseMutationVariables>(Insert_CaseDocument, baseOptions);
      }
export type Insert_CaseMutationHookResult = ReturnType<typeof useInsert_CaseMutation>;
export type Insert_CaseMutationResult = Apollo.MutationResult<Insert_CaseMutation>;
export type Insert_CaseMutationOptions = Apollo.BaseMutationOptions<Insert_CaseMutation, Insert_CaseMutationVariables>;
export const Get_CaseDocument = gql`
    query get_case($caseId: uuid) {
  cases(where: {id: {_eq: $caseId}}) {
    id
    task
    description
    responsible_id
    responsible {
      id
      mail
      acronym
      first_name
      last_name
      phone
      mobile
    }
    builder_id
    builder {
      id
      first_name
      last_name
      company
      address
      zip_code
      city
      phone
      mobile
      mail
      synced
    }
    secondary_builder_id
    flag
    secondary_builder {
      id
      first_name
      last_name
      company
      address
      zip_code
      city
      phone
      mobile
      mail
    }
    company_id
    company {
      id
      first_name
      last_name
      company
      address
      zip_code
      city
      phone
      mobile
      mail
    }
    changed
    closed
    address
    zip_code
    city
    case_type {
      id
      name
    }
    date_of_inspection
    estimated_time
    important_note
    lat
    lng
    other_address
    other_note
    toilet
    usefull_note
    suppliers {
      contact {
        id
        first_name
        last_name
        company
        address
        zip_code
        city
        phone
        mobile
        mail
      }
    }
    construction_plan_id
    construction_plan {
      id
      name
    }
  }
}
    `;

/**
 * __useGet_CaseQuery__
 *
 * To run a query within a React component, call `useGet_CaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_CaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_CaseQuery({
 *   variables: {
 *      caseId: // value for 'caseId'
 *   },
 * });
 */
export function useGet_CaseQuery(baseOptions?: Apollo.QueryHookOptions<Get_CaseQuery, Get_CaseQueryVariables>) {
        return Apollo.useQuery<Get_CaseQuery, Get_CaseQueryVariables>(Get_CaseDocument, baseOptions);
      }
export function useGet_CaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_CaseQuery, Get_CaseQueryVariables>) {
          return Apollo.useLazyQuery<Get_CaseQuery, Get_CaseQueryVariables>(Get_CaseDocument, baseOptions);
        }
export type Get_CaseQueryHookResult = ReturnType<typeof useGet_CaseQuery>;
export type Get_CaseLazyQueryHookResult = ReturnType<typeof useGet_CaseLazyQuery>;
export type Get_CaseQueryResult = Apollo.QueryResult<Get_CaseQuery, Get_CaseQueryVariables>;
export const Insert_Case_SupplierDocument = gql`
    mutation insert_case_supplier($case_suppliers: [case_suppliers_insert_input!]!) {
  insert_case_suppliers(objects: $case_suppliers) {
    returning {
      id
      case_id
      contact_id
    }
  }
}
    `;
export type Insert_Case_SupplierMutationFn = Apollo.MutationFunction<Insert_Case_SupplierMutation, Insert_Case_SupplierMutationVariables>;

/**
 * __useInsert_Case_SupplierMutation__
 *
 * To run a mutation, you first call `useInsert_Case_SupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Case_SupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCaseSupplierMutation, { data, loading, error }] = useInsert_Case_SupplierMutation({
 *   variables: {
 *      case_suppliers: // value for 'case_suppliers'
 *   },
 * });
 */
export function useInsert_Case_SupplierMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Case_SupplierMutation, Insert_Case_SupplierMutationVariables>) {
        return Apollo.useMutation<Insert_Case_SupplierMutation, Insert_Case_SupplierMutationVariables>(Insert_Case_SupplierDocument, baseOptions);
      }
export type Insert_Case_SupplierMutationHookResult = ReturnType<typeof useInsert_Case_SupplierMutation>;
export type Insert_Case_SupplierMutationResult = Apollo.MutationResult<Insert_Case_SupplierMutation>;
export type Insert_Case_SupplierMutationOptions = Apollo.BaseMutationOptions<Insert_Case_SupplierMutation, Insert_Case_SupplierMutationVariables>;
export const Delete_Case_SuppliersDocument = gql`
    mutation delete_case_suppliers($where: case_suppliers_bool_exp!) {
  delete_case_suppliers(where: $where) {
    affected_rows
  }
}
    `;
export type Delete_Case_SuppliersMutationFn = Apollo.MutationFunction<Delete_Case_SuppliersMutation, Delete_Case_SuppliersMutationVariables>;

/**
 * __useDelete_Case_SuppliersMutation__
 *
 * To run a mutation, you first call `useDelete_Case_SuppliersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Case_SuppliersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCaseSuppliersMutation, { data, loading, error }] = useDelete_Case_SuppliersMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDelete_Case_SuppliersMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Case_SuppliersMutation, Delete_Case_SuppliersMutationVariables>) {
        return Apollo.useMutation<Delete_Case_SuppliersMutation, Delete_Case_SuppliersMutationVariables>(Delete_Case_SuppliersDocument, baseOptions);
      }
export type Delete_Case_SuppliersMutationHookResult = ReturnType<typeof useDelete_Case_SuppliersMutation>;
export type Delete_Case_SuppliersMutationResult = Apollo.MutationResult<Delete_Case_SuppliersMutation>;
export type Delete_Case_SuppliersMutationOptions = Apollo.BaseMutationOptions<Delete_Case_SuppliersMutation, Delete_Case_SuppliersMutationVariables>;
export const List_Case_PhotosDocument = gql`
    query list_case_photos($orderBy: case_photos_order_by!, $where: case_photos_bool_exp, $limit: Int) {
  case_photos(order_by: [$orderBy], where: $where, limit: $limit) {
    id
    file_id
    case_id
    title
    file {
      id
      share_key
      filename
      created
    }
  }
}
    `;

/**
 * __useList_Case_PhotosQuery__
 *
 * To run a query within a React component, call `useList_Case_PhotosQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_Case_PhotosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_Case_PhotosQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useList_Case_PhotosQuery(baseOptions: Apollo.QueryHookOptions<List_Case_PhotosQuery, List_Case_PhotosQueryVariables>) {
        return Apollo.useQuery<List_Case_PhotosQuery, List_Case_PhotosQueryVariables>(List_Case_PhotosDocument, baseOptions);
      }
export function useList_Case_PhotosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_Case_PhotosQuery, List_Case_PhotosQueryVariables>) {
          return Apollo.useLazyQuery<List_Case_PhotosQuery, List_Case_PhotosQueryVariables>(List_Case_PhotosDocument, baseOptions);
        }
export type List_Case_PhotosQueryHookResult = ReturnType<typeof useList_Case_PhotosQuery>;
export type List_Case_PhotosLazyQueryHookResult = ReturnType<typeof useList_Case_PhotosLazyQuery>;
export type List_Case_PhotosQueryResult = Apollo.QueryResult<List_Case_PhotosQuery, List_Case_PhotosQueryVariables>;
export const List_Case_Photos_And_Case_TypeDocument = gql`
    query list_case_photos_and_case_type($orderBy: case_photos_order_by!, $caseId: uuid!, $limit: Int) {
  case_photos(
    order_by: [$orderBy]
    where: {case_id: {_eq: $caseId}}
    limit: $limit
  ) {
    id
    file_id
    case_id
    title
    file {
      id
      share_key
      filename
      created
    }
  }
  cases_by_pk(id: $caseId) {
    case_type_id
  }
}
    `;

/**
 * __useList_Case_Photos_And_Case_TypeQuery__
 *
 * To run a query within a React component, call `useList_Case_Photos_And_Case_TypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_Case_Photos_And_Case_TypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_Case_Photos_And_Case_TypeQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      caseId: // value for 'caseId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useList_Case_Photos_And_Case_TypeQuery(baseOptions: Apollo.QueryHookOptions<List_Case_Photos_And_Case_TypeQuery, List_Case_Photos_And_Case_TypeQueryVariables>) {
        return Apollo.useQuery<List_Case_Photos_And_Case_TypeQuery, List_Case_Photos_And_Case_TypeQueryVariables>(List_Case_Photos_And_Case_TypeDocument, baseOptions);
      }
export function useList_Case_Photos_And_Case_TypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_Case_Photos_And_Case_TypeQuery, List_Case_Photos_And_Case_TypeQueryVariables>) {
          return Apollo.useLazyQuery<List_Case_Photos_And_Case_TypeQuery, List_Case_Photos_And_Case_TypeQueryVariables>(List_Case_Photos_And_Case_TypeDocument, baseOptions);
        }
export type List_Case_Photos_And_Case_TypeQueryHookResult = ReturnType<typeof useList_Case_Photos_And_Case_TypeQuery>;
export type List_Case_Photos_And_Case_TypeLazyQueryHookResult = ReturnType<typeof useList_Case_Photos_And_Case_TypeLazyQuery>;
export type List_Case_Photos_And_Case_TypeQueryResult = Apollo.QueryResult<List_Case_Photos_And_Case_TypeQuery, List_Case_Photos_And_Case_TypeQueryVariables>;
export const Insert_Case_PhotoDocument = gql`
    mutation insert_case_photo($case_photos: [case_photos_insert_input!]!) {
  insert_case_photos(objects: $case_photos) {
    returning {
      id
      file_id
      case_id
      title
      file {
        id
        share_key
        filename
        created
      }
    }
  }
}
    `;
export type Insert_Case_PhotoMutationFn = Apollo.MutationFunction<Insert_Case_PhotoMutation, Insert_Case_PhotoMutationVariables>;

/**
 * __useInsert_Case_PhotoMutation__
 *
 * To run a mutation, you first call `useInsert_Case_PhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Case_PhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCasePhotoMutation, { data, loading, error }] = useInsert_Case_PhotoMutation({
 *   variables: {
 *      case_photos: // value for 'case_photos'
 *   },
 * });
 */
export function useInsert_Case_PhotoMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Case_PhotoMutation, Insert_Case_PhotoMutationVariables>) {
        return Apollo.useMutation<Insert_Case_PhotoMutation, Insert_Case_PhotoMutationVariables>(Insert_Case_PhotoDocument, baseOptions);
      }
export type Insert_Case_PhotoMutationHookResult = ReturnType<typeof useInsert_Case_PhotoMutation>;
export type Insert_Case_PhotoMutationResult = Apollo.MutationResult<Insert_Case_PhotoMutation>;
export type Insert_Case_PhotoMutationOptions = Apollo.BaseMutationOptions<Insert_Case_PhotoMutation, Insert_Case_PhotoMutationVariables>;
export const Get_Case_PhotoDocument = gql`
    query get_case_photo($photoId: uuid) {
  case_photos(where: {id: {_eq: $photoId}}) {
    id
    file_id
    case_id
    title
    file {
      id
      filename
      share_key
      created
    }
  }
}
    `;

/**
 * __useGet_Case_PhotoQuery__
 *
 * To run a query within a React component, call `useGet_Case_PhotoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Case_PhotoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Case_PhotoQuery({
 *   variables: {
 *      photoId: // value for 'photoId'
 *   },
 * });
 */
export function useGet_Case_PhotoQuery(baseOptions?: Apollo.QueryHookOptions<Get_Case_PhotoQuery, Get_Case_PhotoQueryVariables>) {
        return Apollo.useQuery<Get_Case_PhotoQuery, Get_Case_PhotoQueryVariables>(Get_Case_PhotoDocument, baseOptions);
      }
export function useGet_Case_PhotoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Case_PhotoQuery, Get_Case_PhotoQueryVariables>) {
          return Apollo.useLazyQuery<Get_Case_PhotoQuery, Get_Case_PhotoQueryVariables>(Get_Case_PhotoDocument, baseOptions);
        }
export type Get_Case_PhotoQueryHookResult = ReturnType<typeof useGet_Case_PhotoQuery>;
export type Get_Case_PhotoLazyQueryHookResult = ReturnType<typeof useGet_Case_PhotoLazyQuery>;
export type Get_Case_PhotoQueryResult = Apollo.QueryResult<Get_Case_PhotoQuery, Get_Case_PhotoQueryVariables>;
export const Update_Case_PhotoDocument = gql`
    mutation update_case_photo($id: uuid, $case_photo: case_photos_set_input!) {
  update_case_photos(where: {id: {_eq: $id}}, _set: $case_photo) {
    returning {
      id
      file_id
      case_id
      title
      file {
        id
        share_key
        filename
        created
      }
    }
  }
}
    `;
export type Update_Case_PhotoMutationFn = Apollo.MutationFunction<Update_Case_PhotoMutation, Update_Case_PhotoMutationVariables>;

/**
 * __useUpdate_Case_PhotoMutation__
 *
 * To run a mutation, you first call `useUpdate_Case_PhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Case_PhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCasePhotoMutation, { data, loading, error }] = useUpdate_Case_PhotoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      case_photo: // value for 'case_photo'
 *   },
 * });
 */
export function useUpdate_Case_PhotoMutation(baseOptions?: Apollo.MutationHookOptions<Update_Case_PhotoMutation, Update_Case_PhotoMutationVariables>) {
        return Apollo.useMutation<Update_Case_PhotoMutation, Update_Case_PhotoMutationVariables>(Update_Case_PhotoDocument, baseOptions);
      }
export type Update_Case_PhotoMutationHookResult = ReturnType<typeof useUpdate_Case_PhotoMutation>;
export type Update_Case_PhotoMutationResult = Apollo.MutationResult<Update_Case_PhotoMutation>;
export type Update_Case_PhotoMutationOptions = Apollo.BaseMutationOptions<Update_Case_PhotoMutation, Update_Case_PhotoMutationVariables>;
export const Update_FileDocument = gql`
    mutation update_file($id: uuid, $file: files_set_input!) {
  update_files(where: {id: {_eq: $id}}, _set: $file) {
    returning {
      id
      share_key
      filename
      created
    }
  }
}
    `;
export type Update_FileMutationFn = Apollo.MutationFunction<Update_FileMutation, Update_FileMutationVariables>;

/**
 * __useUpdate_FileMutation__
 *
 * To run a mutation, you first call `useUpdate_FileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_FileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileMutation, { data, loading, error }] = useUpdate_FileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdate_FileMutation(baseOptions?: Apollo.MutationHookOptions<Update_FileMutation, Update_FileMutationVariables>) {
        return Apollo.useMutation<Update_FileMutation, Update_FileMutationVariables>(Update_FileDocument, baseOptions);
      }
export type Update_FileMutationHookResult = ReturnType<typeof useUpdate_FileMutation>;
export type Update_FileMutationResult = Apollo.MutationResult<Update_FileMutation>;
export type Update_FileMutationOptions = Apollo.BaseMutationOptions<Update_FileMutation, Update_FileMutationVariables>;
export const List_Case_FilesDocument = gql`
    query list_case_files($orderBy: case_files_order_by!, $where: case_files_bool_exp, $limit: Int) {
  case_files(order_by: [$orderBy], where: $where, limit: $limit) {
    id
    file_id
    case_id
    title
    file {
      id
      share_key
      filename
      created
    }
  }
}
    `;

/**
 * __useList_Case_FilesQuery__
 *
 * To run a query within a React component, call `useList_Case_FilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_Case_FilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_Case_FilesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useList_Case_FilesQuery(baseOptions: Apollo.QueryHookOptions<List_Case_FilesQuery, List_Case_FilesQueryVariables>) {
        return Apollo.useQuery<List_Case_FilesQuery, List_Case_FilesQueryVariables>(List_Case_FilesDocument, baseOptions);
      }
export function useList_Case_FilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_Case_FilesQuery, List_Case_FilesQueryVariables>) {
          return Apollo.useLazyQuery<List_Case_FilesQuery, List_Case_FilesQueryVariables>(List_Case_FilesDocument, baseOptions);
        }
export type List_Case_FilesQueryHookResult = ReturnType<typeof useList_Case_FilesQuery>;
export type List_Case_FilesLazyQueryHookResult = ReturnType<typeof useList_Case_FilesLazyQuery>;
export type List_Case_FilesQueryResult = Apollo.QueryResult<List_Case_FilesQuery, List_Case_FilesQueryVariables>;
export const Insert_Case_FileDocument = gql`
    mutation insert_case_file($case_files: [case_files_insert_input!]!) {
  insert_case_files(objects: $case_files) {
    returning {
      id
      file_id
      case_id
      title
      file {
        id
        share_key
        filename
        created
      }
    }
  }
}
    `;
export type Insert_Case_FileMutationFn = Apollo.MutationFunction<Insert_Case_FileMutation, Insert_Case_FileMutationVariables>;

/**
 * __useInsert_Case_FileMutation__
 *
 * To run a mutation, you first call `useInsert_Case_FileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Case_FileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCaseFileMutation, { data, loading, error }] = useInsert_Case_FileMutation({
 *   variables: {
 *      case_files: // value for 'case_files'
 *   },
 * });
 */
export function useInsert_Case_FileMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Case_FileMutation, Insert_Case_FileMutationVariables>) {
        return Apollo.useMutation<Insert_Case_FileMutation, Insert_Case_FileMutationVariables>(Insert_Case_FileDocument, baseOptions);
      }
export type Insert_Case_FileMutationHookResult = ReturnType<typeof useInsert_Case_FileMutation>;
export type Insert_Case_FileMutationResult = Apollo.MutationResult<Insert_Case_FileMutation>;
export type Insert_Case_FileMutationOptions = Apollo.BaseMutationOptions<Insert_Case_FileMutation, Insert_Case_FileMutationVariables>;
export const Get_Case_FileDocument = gql`
    query get_case_file($fileId: uuid) {
  case_files(where: {id: {_eq: $fileId}}) {
    id
    file_id
    case_id
    title
    file {
      id
      filename
      share_key
      created
    }
  }
}
    `;

/**
 * __useGet_Case_FileQuery__
 *
 * To run a query within a React component, call `useGet_Case_FileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Case_FileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Case_FileQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useGet_Case_FileQuery(baseOptions?: Apollo.QueryHookOptions<Get_Case_FileQuery, Get_Case_FileQueryVariables>) {
        return Apollo.useQuery<Get_Case_FileQuery, Get_Case_FileQueryVariables>(Get_Case_FileDocument, baseOptions);
      }
export function useGet_Case_FileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Case_FileQuery, Get_Case_FileQueryVariables>) {
          return Apollo.useLazyQuery<Get_Case_FileQuery, Get_Case_FileQueryVariables>(Get_Case_FileDocument, baseOptions);
        }
export type Get_Case_FileQueryHookResult = ReturnType<typeof useGet_Case_FileQuery>;
export type Get_Case_FileLazyQueryHookResult = ReturnType<typeof useGet_Case_FileLazyQuery>;
export type Get_Case_FileQueryResult = Apollo.QueryResult<Get_Case_FileQuery, Get_Case_FileQueryVariables>;
export const Update_Case_FileDocument = gql`
    mutation update_case_file($id: uuid, $case_file: case_files_set_input!) {
  update_case_files(where: {id: {_eq: $id}}, _set: $case_file) {
    returning {
      id
      file_id
      case_id
      title
      file {
        id
        share_key
        filename
        created
      }
    }
  }
}
    `;
export type Update_Case_FileMutationFn = Apollo.MutationFunction<Update_Case_FileMutation, Update_Case_FileMutationVariables>;

/**
 * __useUpdate_Case_FileMutation__
 *
 * To run a mutation, you first call `useUpdate_Case_FileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Case_FileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseFileMutation, { data, loading, error }] = useUpdate_Case_FileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      case_file: // value for 'case_file'
 *   },
 * });
 */
export function useUpdate_Case_FileMutation(baseOptions?: Apollo.MutationHookOptions<Update_Case_FileMutation, Update_Case_FileMutationVariables>) {
        return Apollo.useMutation<Update_Case_FileMutation, Update_Case_FileMutationVariables>(Update_Case_FileDocument, baseOptions);
      }
export type Update_Case_FileMutationHookResult = ReturnType<typeof useUpdate_Case_FileMutation>;
export type Update_Case_FileMutationResult = Apollo.MutationResult<Update_Case_FileMutation>;
export type Update_Case_FileMutationOptions = Apollo.BaseMutationOptions<Update_Case_FileMutation, Update_Case_FileMutationVariables>;
export const List_Case_DocumentsDocument = gql`
    query list_case_documents($case_id: uuid!) {
  case_doc_notes(where: {case_id: {_eq: $case_id}}) {
    id
    changed
    created
    type
    title
    share_key
    user {
      acronym
    }
  }
  case_doc_order_confirmations(where: {case_id: {_eq: $case_id}}) {
    id
    changed
    created
    type
    title
    share_key
    user {
      acronym
    }
  }
  case_doc_quality_controls(where: {case_id: {_eq: $case_id}}) {
    id
    changed
    created
    type
    title
    share_key
    user {
      acronym
    }
  }
  case_doc_checklists(where: {case_id: {_eq: $case_id}}) {
    id
    changed
    created
    type
    title
    share_key
    user {
      acronym
    }
  }
  case_doc_economy(where: {case_id: {_eq: $case_id}}) {
    id
    changed
    created
    type
    title
    share_key
    user {
      acronym
    }
  }
}
    `;

/**
 * __useList_Case_DocumentsQuery__
 *
 * To run a query within a React component, call `useList_Case_DocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_Case_DocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_Case_DocumentsQuery({
 *   variables: {
 *      case_id: // value for 'case_id'
 *   },
 * });
 */
export function useList_Case_DocumentsQuery(baseOptions: Apollo.QueryHookOptions<List_Case_DocumentsQuery, List_Case_DocumentsQueryVariables>) {
        return Apollo.useQuery<List_Case_DocumentsQuery, List_Case_DocumentsQueryVariables>(List_Case_DocumentsDocument, baseOptions);
      }
export function useList_Case_DocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_Case_DocumentsQuery, List_Case_DocumentsQueryVariables>) {
          return Apollo.useLazyQuery<List_Case_DocumentsQuery, List_Case_DocumentsQueryVariables>(List_Case_DocumentsDocument, baseOptions);
        }
export type List_Case_DocumentsQueryHookResult = ReturnType<typeof useList_Case_DocumentsQuery>;
export type List_Case_DocumentsLazyQueryHookResult = ReturnType<typeof useList_Case_DocumentsLazyQuery>;
export type List_Case_DocumentsQueryResult = Apollo.QueryResult<List_Case_DocumentsQuery, List_Case_DocumentsQueryVariables>;
export const List_Cases_CordsDocument = gql`
    query list_cases_cords($where: cases_bool_exp) {
  cases(where: $where) {
    id
    lat
    lng
    case_type_id
  }
}
    `;

/**
 * __useList_Cases_CordsQuery__
 *
 * To run a query within a React component, call `useList_Cases_CordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_Cases_CordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_Cases_CordsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useList_Cases_CordsQuery(baseOptions?: Apollo.QueryHookOptions<List_Cases_CordsQuery, List_Cases_CordsQueryVariables>) {
        return Apollo.useQuery<List_Cases_CordsQuery, List_Cases_CordsQueryVariables>(List_Cases_CordsDocument, baseOptions);
      }
export function useList_Cases_CordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_Cases_CordsQuery, List_Cases_CordsQueryVariables>) {
          return Apollo.useLazyQuery<List_Cases_CordsQuery, List_Cases_CordsQueryVariables>(List_Cases_CordsDocument, baseOptions);
        }
export type List_Cases_CordsQueryHookResult = ReturnType<typeof useList_Cases_CordsQuery>;
export type List_Cases_CordsLazyQueryHookResult = ReturnType<typeof useList_Cases_CordsLazyQuery>;
export type List_Cases_CordsQueryResult = Apollo.QueryResult<List_Cases_CordsQuery, List_Cases_CordsQueryVariables>;
export const Delete_CasesDocument = gql`
    mutation delete_cases($where: cases_bool_exp!) {
  update_cases(where: $where, _set: {deleted: true}) {
    affected_rows
  }
}
    `;
export type Delete_CasesMutationFn = Apollo.MutationFunction<Delete_CasesMutation, Delete_CasesMutationVariables>;

/**
 * __useDelete_CasesMutation__
 *
 * To run a mutation, you first call `useDelete_CasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_CasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCasesMutation, { data, loading, error }] = useDelete_CasesMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDelete_CasesMutation(baseOptions?: Apollo.MutationHookOptions<Delete_CasesMutation, Delete_CasesMutationVariables>) {
        return Apollo.useMutation<Delete_CasesMutation, Delete_CasesMutationVariables>(Delete_CasesDocument, baseOptions);
      }
export type Delete_CasesMutationHookResult = ReturnType<typeof useDelete_CasesMutation>;
export type Delete_CasesMutationResult = Apollo.MutationResult<Delete_CasesMutation>;
export type Delete_CasesMutationOptions = Apollo.BaseMutationOptions<Delete_CasesMutation, Delete_CasesMutationVariables>;
export const List_Case_Doc_ChecklistsDocument = gql`
    query list_case_doc_checklists($orderBy: case_doc_checklists_order_by!, $where: case_doc_checklists_bool_exp, $limit: Int) {
  case_doc_checklists(order_by: [$orderBy], where: $where, limit: $limit) {
    changed
    created
    id
    title
    type
  }
}
    `;

/**
 * __useList_Case_Doc_ChecklistsQuery__
 *
 * To run a query within a React component, call `useList_Case_Doc_ChecklistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_Case_Doc_ChecklistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_Case_Doc_ChecklistsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useList_Case_Doc_ChecklistsQuery(baseOptions: Apollo.QueryHookOptions<List_Case_Doc_ChecklistsQuery, List_Case_Doc_ChecklistsQueryVariables>) {
        return Apollo.useQuery<List_Case_Doc_ChecklistsQuery, List_Case_Doc_ChecklistsQueryVariables>(List_Case_Doc_ChecklistsDocument, baseOptions);
      }
export function useList_Case_Doc_ChecklistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_Case_Doc_ChecklistsQuery, List_Case_Doc_ChecklistsQueryVariables>) {
          return Apollo.useLazyQuery<List_Case_Doc_ChecklistsQuery, List_Case_Doc_ChecklistsQueryVariables>(List_Case_Doc_ChecklistsDocument, baseOptions);
        }
export type List_Case_Doc_ChecklistsQueryHookResult = ReturnType<typeof useList_Case_Doc_ChecklistsQuery>;
export type List_Case_Doc_ChecklistsLazyQueryHookResult = ReturnType<typeof useList_Case_Doc_ChecklistsLazyQuery>;
export type List_Case_Doc_ChecklistsQueryResult = Apollo.QueryResult<List_Case_Doc_ChecklistsQuery, List_Case_Doc_ChecklistsQueryVariables>;
export const Get_ChecklistDocument = gql`
    query get_checklist($id: uuid!) {
  case_doc_checklists_by_pk(id: $id) {
    changed
    created
    document_date
    revision_date
    id
    share_key
    title
    file_id
    type
    introduction
    closing
    file {
      id
      share_key
      filename
      created
    }
    case {
      address
      builder_id
      builder {
        address
        city
        company
        first_name
        id
        last_name
        mail
        mobile
        phone
        zip_code
      }
      city
      date_of_inspection
      other_address
      responsible_id
      responsible {
        acronym
        first_name
        last_name
        mail
        mobile
        phone
      }
      zip_code
    }
    items(order_by: {order: asc}) {
      comment
      completed
      description
      end_date
      id
      order
      placement
      price
      start_date
      ue
      x
      y
      file_id
      file {
        id
        share_key
        filename
        created
      }
      arrows(order_by: {order: asc}) {
        id
        order
        item_id
        angle
        x
        y
      }
    }
  }
}
    `;

/**
 * __useGet_ChecklistQuery__
 *
 * To run a query within a React component, call `useGet_ChecklistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_ChecklistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_ChecklistQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_ChecklistQuery(baseOptions: Apollo.QueryHookOptions<Get_ChecklistQuery, Get_ChecklistQueryVariables>) {
        return Apollo.useQuery<Get_ChecklistQuery, Get_ChecklistQueryVariables>(Get_ChecklistDocument, baseOptions);
      }
export function useGet_ChecklistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_ChecklistQuery, Get_ChecklistQueryVariables>) {
          return Apollo.useLazyQuery<Get_ChecklistQuery, Get_ChecklistQueryVariables>(Get_ChecklistDocument, baseOptions);
        }
export type Get_ChecklistQueryHookResult = ReturnType<typeof useGet_ChecklistQuery>;
export type Get_ChecklistLazyQueryHookResult = ReturnType<typeof useGet_ChecklistLazyQuery>;
export type Get_ChecklistQueryResult = Apollo.QueryResult<Get_ChecklistQuery, Get_ChecklistQueryVariables>;
export const Insert_Case_Doc_ChecklistsDocument = gql`
    mutation insert_case_doc_checklists($checklist: case_doc_checklists_insert_input!) {
  insert_case_doc_checklists(objects: [$checklist]) {
    returning {
      changed
      created
      document_date
      revision_date
      id
      type
      share_key
      title
      file_id
      introduction
      closing
      file {
        id
        share_key
        filename
        created
      }
      case {
        address
        builder {
          address
          city
          company
          first_name
          id
          last_name
          mail
          mobile
          phone
          zip_code
        }
        city
        date_of_inspection
        other_address
        responsible {
          acronym
          first_name
          last_name
          mail
          mobile
          phone
        }
        zip_code
      }
      items(order_by: {order: asc}) {
        comment
        completed
        description
        end_date
        id
        order
        placement
        price
        start_date
        ue
        x
        y
        file_id
        file {
          id
          share_key
          filename
          created
        }
        arrows(order_by: {order: asc}) {
          id
          order
          item_id
          angle
          item_id
          x
          y
        }
      }
    }
  }
}
    `;
export type Insert_Case_Doc_ChecklistsMutationFn = Apollo.MutationFunction<Insert_Case_Doc_ChecklistsMutation, Insert_Case_Doc_ChecklistsMutationVariables>;

/**
 * __useInsert_Case_Doc_ChecklistsMutation__
 *
 * To run a mutation, you first call `useInsert_Case_Doc_ChecklistsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Case_Doc_ChecklistsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCaseDocChecklistsMutation, { data, loading, error }] = useInsert_Case_Doc_ChecklistsMutation({
 *   variables: {
 *      checklist: // value for 'checklist'
 *   },
 * });
 */
export function useInsert_Case_Doc_ChecklistsMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Case_Doc_ChecklistsMutation, Insert_Case_Doc_ChecklistsMutationVariables>) {
        return Apollo.useMutation<Insert_Case_Doc_ChecklistsMutation, Insert_Case_Doc_ChecklistsMutationVariables>(Insert_Case_Doc_ChecklistsDocument, baseOptions);
      }
export type Insert_Case_Doc_ChecklistsMutationHookResult = ReturnType<typeof useInsert_Case_Doc_ChecklistsMutation>;
export type Insert_Case_Doc_ChecklistsMutationResult = Apollo.MutationResult<Insert_Case_Doc_ChecklistsMutation>;
export type Insert_Case_Doc_ChecklistsMutationOptions = Apollo.BaseMutationOptions<Insert_Case_Doc_ChecklistsMutation, Insert_Case_Doc_ChecklistsMutationVariables>;
export const Update_Case_Doc_ChecklistsDocument = gql`
    mutation update_case_doc_checklists($id: uuid, $checklist: case_doc_checklists_set_input!) {
  update_case_doc_checklists(where: {id: {_eq: $id}}, _set: $checklist) {
    returning {
      changed
      created
      document_date
      revision_date
      type
      id
      share_key
      file_id
      introduction
      closing
      file {
        id
        share_key
        filename
        created
      }
      title
      case {
        address
        builder {
          address
          city
          company
          first_name
          id
          last_name
          mail
          mobile
          phone
          zip_code
        }
        city
        date_of_inspection
        other_address
        responsible {
          acronym
          first_name
          last_name
          mail
          mobile
          phone
        }
        zip_code
      }
      items(order_by: {order: asc}) {
        comment
        completed
        description
        end_date
        id
        order
        placement
        price
        start_date
        ue
        x
        y
        file_id
        file {
          id
          share_key
          filename
          created
        }
        arrows(order_by: {order: asc}) {
          id
          item_id
          order
          angle
          x
          y
        }
      }
    }
  }
}
    `;
export type Update_Case_Doc_ChecklistsMutationFn = Apollo.MutationFunction<Update_Case_Doc_ChecklistsMutation, Update_Case_Doc_ChecklistsMutationVariables>;

/**
 * __useUpdate_Case_Doc_ChecklistsMutation__
 *
 * To run a mutation, you first call `useUpdate_Case_Doc_ChecklistsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Case_Doc_ChecklistsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseDocChecklistsMutation, { data, loading, error }] = useUpdate_Case_Doc_ChecklistsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      checklist: // value for 'checklist'
 *   },
 * });
 */
export function useUpdate_Case_Doc_ChecklistsMutation(baseOptions?: Apollo.MutationHookOptions<Update_Case_Doc_ChecklistsMutation, Update_Case_Doc_ChecklistsMutationVariables>) {
        return Apollo.useMutation<Update_Case_Doc_ChecklistsMutation, Update_Case_Doc_ChecklistsMutationVariables>(Update_Case_Doc_ChecklistsDocument, baseOptions);
      }
export type Update_Case_Doc_ChecklistsMutationHookResult = ReturnType<typeof useUpdate_Case_Doc_ChecklistsMutation>;
export type Update_Case_Doc_ChecklistsMutationResult = Apollo.MutationResult<Update_Case_Doc_ChecklistsMutation>;
export type Update_Case_Doc_ChecklistsMutationOptions = Apollo.BaseMutationOptions<Update_Case_Doc_ChecklistsMutation, Update_Case_Doc_ChecklistsMutationVariables>;
export const Insert_Case_Doc_Checklists_TaskDocument = gql`
    mutation insert_case_doc_checklists_task($items: [checklist_items_insert_input!]!) {
  insert_checklist_items(objects: $items) {
    returning {
      comment
      completed
      description
      end_date
      id
      order
      placement
      price
      start_date
      ue
      x
      y
      file_id
    }
  }
}
    `;
export type Insert_Case_Doc_Checklists_TaskMutationFn = Apollo.MutationFunction<Insert_Case_Doc_Checklists_TaskMutation, Insert_Case_Doc_Checklists_TaskMutationVariables>;

/**
 * __useInsert_Case_Doc_Checklists_TaskMutation__
 *
 * To run a mutation, you first call `useInsert_Case_Doc_Checklists_TaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Case_Doc_Checklists_TaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCaseDocChecklistsTaskMutation, { data, loading, error }] = useInsert_Case_Doc_Checklists_TaskMutation({
 *   variables: {
 *      items: // value for 'items'
 *   },
 * });
 */
export function useInsert_Case_Doc_Checklists_TaskMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Case_Doc_Checklists_TaskMutation, Insert_Case_Doc_Checklists_TaskMutationVariables>) {
        return Apollo.useMutation<Insert_Case_Doc_Checklists_TaskMutation, Insert_Case_Doc_Checklists_TaskMutationVariables>(Insert_Case_Doc_Checklists_TaskDocument, baseOptions);
      }
export type Insert_Case_Doc_Checklists_TaskMutationHookResult = ReturnType<typeof useInsert_Case_Doc_Checklists_TaskMutation>;
export type Insert_Case_Doc_Checklists_TaskMutationResult = Apollo.MutationResult<Insert_Case_Doc_Checklists_TaskMutation>;
export type Insert_Case_Doc_Checklists_TaskMutationOptions = Apollo.BaseMutationOptions<Insert_Case_Doc_Checklists_TaskMutation, Insert_Case_Doc_Checklists_TaskMutationVariables>;
export const Update_Case_Doc_Checklists_TaskDocument = gql`
    mutation update_case_doc_checklists_task($id: uuid, $item: checklist_items_set_input!) {
  update_checklist_items(where: {id: {_eq: $id}}, _set: $item) {
    returning {
      comment
      completed
      description
      end_date
      id
      order
      placement
      price
      start_date
      ue
      x
      y
      file_id
      file {
        id
        share_key
        filename
        created
      }
    }
  }
}
    `;
export type Update_Case_Doc_Checklists_TaskMutationFn = Apollo.MutationFunction<Update_Case_Doc_Checklists_TaskMutation, Update_Case_Doc_Checklists_TaskMutationVariables>;

/**
 * __useUpdate_Case_Doc_Checklists_TaskMutation__
 *
 * To run a mutation, you first call `useUpdate_Case_Doc_Checklists_TaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Case_Doc_Checklists_TaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseDocChecklistsTaskMutation, { data, loading, error }] = useUpdate_Case_Doc_Checklists_TaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      item: // value for 'item'
 *   },
 * });
 */
export function useUpdate_Case_Doc_Checklists_TaskMutation(baseOptions?: Apollo.MutationHookOptions<Update_Case_Doc_Checklists_TaskMutation, Update_Case_Doc_Checklists_TaskMutationVariables>) {
        return Apollo.useMutation<Update_Case_Doc_Checklists_TaskMutation, Update_Case_Doc_Checklists_TaskMutationVariables>(Update_Case_Doc_Checklists_TaskDocument, baseOptions);
      }
export type Update_Case_Doc_Checklists_TaskMutationHookResult = ReturnType<typeof useUpdate_Case_Doc_Checklists_TaskMutation>;
export type Update_Case_Doc_Checklists_TaskMutationResult = Apollo.MutationResult<Update_Case_Doc_Checklists_TaskMutation>;
export type Update_Case_Doc_Checklists_TaskMutationOptions = Apollo.BaseMutationOptions<Update_Case_Doc_Checklists_TaskMutation, Update_Case_Doc_Checklists_TaskMutationVariables>;
export const Delete_Checklist_ItemsDocument = gql`
    mutation delete_checklist_items($where: checklist_items_bool_exp!) {
  delete_checklist_items(where: $where) {
    affected_rows
  }
}
    `;
export type Delete_Checklist_ItemsMutationFn = Apollo.MutationFunction<Delete_Checklist_ItemsMutation, Delete_Checklist_ItemsMutationVariables>;

/**
 * __useDelete_Checklist_ItemsMutation__
 *
 * To run a mutation, you first call `useDelete_Checklist_ItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Checklist_ItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChecklistItemsMutation, { data, loading, error }] = useDelete_Checklist_ItemsMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDelete_Checklist_ItemsMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Checklist_ItemsMutation, Delete_Checklist_ItemsMutationVariables>) {
        return Apollo.useMutation<Delete_Checklist_ItemsMutation, Delete_Checklist_ItemsMutationVariables>(Delete_Checklist_ItemsDocument, baseOptions);
      }
export type Delete_Checklist_ItemsMutationHookResult = ReturnType<typeof useDelete_Checklist_ItemsMutation>;
export type Delete_Checklist_ItemsMutationResult = Apollo.MutationResult<Delete_Checklist_ItemsMutation>;
export type Delete_Checklist_ItemsMutationOptions = Apollo.BaseMutationOptions<Delete_Checklist_ItemsMutation, Delete_Checklist_ItemsMutationVariables>;
export const Insert_Checklist_Item_ArrowsDocument = gql`
    mutation insert_checklist_item_arrows($itemArrows: [checklist_item_arrows_insert_input!]!) {
  insert_checklist_item_arrows(objects: $itemArrows) {
    returning {
      id
      order
      item_id
      angle
      x
      y
    }
  }
}
    `;
export type Insert_Checklist_Item_ArrowsMutationFn = Apollo.MutationFunction<Insert_Checklist_Item_ArrowsMutation, Insert_Checklist_Item_ArrowsMutationVariables>;

/**
 * __useInsert_Checklist_Item_ArrowsMutation__
 *
 * To run a mutation, you first call `useInsert_Checklist_Item_ArrowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Checklist_Item_ArrowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertChecklistItemArrowsMutation, { data, loading, error }] = useInsert_Checklist_Item_ArrowsMutation({
 *   variables: {
 *      itemArrows: // value for 'itemArrows'
 *   },
 * });
 */
export function useInsert_Checklist_Item_ArrowsMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Checklist_Item_ArrowsMutation, Insert_Checklist_Item_ArrowsMutationVariables>) {
        return Apollo.useMutation<Insert_Checklist_Item_ArrowsMutation, Insert_Checklist_Item_ArrowsMutationVariables>(Insert_Checklist_Item_ArrowsDocument, baseOptions);
      }
export type Insert_Checklist_Item_ArrowsMutationHookResult = ReturnType<typeof useInsert_Checklist_Item_ArrowsMutation>;
export type Insert_Checklist_Item_ArrowsMutationResult = Apollo.MutationResult<Insert_Checklist_Item_ArrowsMutation>;
export type Insert_Checklist_Item_ArrowsMutationOptions = Apollo.BaseMutationOptions<Insert_Checklist_Item_ArrowsMutation, Insert_Checklist_Item_ArrowsMutationVariables>;
export const Update_Checklist_Item_ArrowsDocument = gql`
    mutation update_checklist_item_arrows($id: uuid, $itemArrow: checklist_item_arrows_set_input!) {
  update_checklist_item_arrows(where: {id: {_eq: $id}}, _set: $itemArrow) {
    returning {
      id
      order
      item_id
      angle
      x
      y
    }
  }
}
    `;
export type Update_Checklist_Item_ArrowsMutationFn = Apollo.MutationFunction<Update_Checklist_Item_ArrowsMutation, Update_Checklist_Item_ArrowsMutationVariables>;

/**
 * __useUpdate_Checklist_Item_ArrowsMutation__
 *
 * To run a mutation, you first call `useUpdate_Checklist_Item_ArrowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Checklist_Item_ArrowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChecklistItemArrowsMutation, { data, loading, error }] = useUpdate_Checklist_Item_ArrowsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      itemArrow: // value for 'itemArrow'
 *   },
 * });
 */
export function useUpdate_Checklist_Item_ArrowsMutation(baseOptions?: Apollo.MutationHookOptions<Update_Checklist_Item_ArrowsMutation, Update_Checklist_Item_ArrowsMutationVariables>) {
        return Apollo.useMutation<Update_Checklist_Item_ArrowsMutation, Update_Checklist_Item_ArrowsMutationVariables>(Update_Checklist_Item_ArrowsDocument, baseOptions);
      }
export type Update_Checklist_Item_ArrowsMutationHookResult = ReturnType<typeof useUpdate_Checklist_Item_ArrowsMutation>;
export type Update_Checklist_Item_ArrowsMutationResult = Apollo.MutationResult<Update_Checklist_Item_ArrowsMutation>;
export type Update_Checklist_Item_ArrowsMutationOptions = Apollo.BaseMutationOptions<Update_Checklist_Item_ArrowsMutation, Update_Checklist_Item_ArrowsMutationVariables>;
export const Delete_Checklist_Item_ArrowsDocument = gql`
    mutation delete_checklist_item_arrows($where: checklist_item_arrows_bool_exp!) {
  delete_checklist_item_arrows(where: $where) {
    affected_rows
  }
}
    `;
export type Delete_Checklist_Item_ArrowsMutationFn = Apollo.MutationFunction<Delete_Checklist_Item_ArrowsMutation, Delete_Checklist_Item_ArrowsMutationVariables>;

/**
 * __useDelete_Checklist_Item_ArrowsMutation__
 *
 * To run a mutation, you first call `useDelete_Checklist_Item_ArrowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Checklist_Item_ArrowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChecklistItemArrowsMutation, { data, loading, error }] = useDelete_Checklist_Item_ArrowsMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDelete_Checklist_Item_ArrowsMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Checklist_Item_ArrowsMutation, Delete_Checklist_Item_ArrowsMutationVariables>) {
        return Apollo.useMutation<Delete_Checklist_Item_ArrowsMutation, Delete_Checklist_Item_ArrowsMutationVariables>(Delete_Checklist_Item_ArrowsDocument, baseOptions);
      }
export type Delete_Checklist_Item_ArrowsMutationHookResult = ReturnType<typeof useDelete_Checklist_Item_ArrowsMutation>;
export type Delete_Checklist_Item_ArrowsMutationResult = Apollo.MutationResult<Delete_Checklist_Item_ArrowsMutation>;
export type Delete_Checklist_Item_ArrowsMutationOptions = Apollo.BaseMutationOptions<Delete_Checklist_Item_ArrowsMutation, Delete_Checklist_Item_ArrowsMutationVariables>;
export const GetConstructionPlansDocument = gql`
    query getConstructionPlans($orderBy: construction_plans_order_by!, $where: construction_plans_bool_exp, $limit: Int) {
  construction_plans(order_by: [$orderBy], where: $where, limit: $limit) {
    id
    name
  }
}
    `;

/**
 * __useGetConstructionPlansQuery__
 *
 * To run a query within a React component, call `useGetConstructionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConstructionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConstructionPlansQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetConstructionPlansQuery(baseOptions: Apollo.QueryHookOptions<GetConstructionPlansQuery, GetConstructionPlansQueryVariables>) {
        return Apollo.useQuery<GetConstructionPlansQuery, GetConstructionPlansQueryVariables>(GetConstructionPlansDocument, baseOptions);
      }
export function useGetConstructionPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConstructionPlansQuery, GetConstructionPlansQueryVariables>) {
          return Apollo.useLazyQuery<GetConstructionPlansQuery, GetConstructionPlansQueryVariables>(GetConstructionPlansDocument, baseOptions);
        }
export type GetConstructionPlansQueryHookResult = ReturnType<typeof useGetConstructionPlansQuery>;
export type GetConstructionPlansLazyQueryHookResult = ReturnType<typeof useGetConstructionPlansLazyQuery>;
export type GetConstructionPlansQueryResult = Apollo.QueryResult<GetConstructionPlansQuery, GetConstructionPlansQueryVariables>;
export const List_ContactsDocument = gql`
    query list_contacts($orderBy: contacts_order_by!, $where: contacts_bool_exp, $offset: Int, $limit: Int) {
  contacts_aggregate(
    offset: $offset
    order_by: [$orderBy]
    where: $where
    limit: $limit
  ) {
    aggregate {
      count
    }
    nodes {
      id
      first_name
      last_name
      company
      address
      zip_code
      city
      phone
      mobile
      mail
      type
    }
  }
}
    `;

/**
 * __useList_ContactsQuery__
 *
 * To run a query within a React component, call `useList_ContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_ContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_ContactsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useList_ContactsQuery(baseOptions: Apollo.QueryHookOptions<List_ContactsQuery, List_ContactsQueryVariables>) {
        return Apollo.useQuery<List_ContactsQuery, List_ContactsQueryVariables>(List_ContactsDocument, baseOptions);
      }
export function useList_ContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_ContactsQuery, List_ContactsQueryVariables>) {
          return Apollo.useLazyQuery<List_ContactsQuery, List_ContactsQueryVariables>(List_ContactsDocument, baseOptions);
        }
export type List_ContactsQueryHookResult = ReturnType<typeof useList_ContactsQuery>;
export type List_ContactsLazyQueryHookResult = ReturnType<typeof useList_ContactsLazyQuery>;
export type List_ContactsQueryResult = Apollo.QueryResult<List_ContactsQuery, List_ContactsQueryVariables>;
export const Get_ContactDocument = gql`
    query get_contact($mobile: String!, $first_name: String!, $last_name: String!) {
  contacts(
    where: {mobile: {_eq: $mobile}, _and: {first_name: {_eq: $first_name}, _and: {last_name: {_eq: $last_name}}}}
  ) {
    id
    address
    city
    company
    mail
    first_name
    last_name
    mobile
    phone
    zip_code
  }
}
    `;

/**
 * __useGet_ContactQuery__
 *
 * To run a query within a React component, call `useGet_ContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_ContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_ContactQuery({
 *   variables: {
 *      mobile: // value for 'mobile'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *   },
 * });
 */
export function useGet_ContactQuery(baseOptions: Apollo.QueryHookOptions<Get_ContactQuery, Get_ContactQueryVariables>) {
        return Apollo.useQuery<Get_ContactQuery, Get_ContactQueryVariables>(Get_ContactDocument, baseOptions);
      }
export function useGet_ContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_ContactQuery, Get_ContactQueryVariables>) {
          return Apollo.useLazyQuery<Get_ContactQuery, Get_ContactQueryVariables>(Get_ContactDocument, baseOptions);
        }
export type Get_ContactQueryHookResult = ReturnType<typeof useGet_ContactQuery>;
export type Get_ContactLazyQueryHookResult = ReturnType<typeof useGet_ContactLazyQuery>;
export type Get_ContactQueryResult = Apollo.QueryResult<Get_ContactQuery, Get_ContactQueryVariables>;
export const Get_ContactsDocument = gql`
    query get_contacts($contactId: uuid) {
  contacts(where: {id: {_eq: $contactId}}) {
    id
    address
    city
    company
    mail
    first_name
    last_name
    mobile
    phone
    zip_code
    type
  }
}
    `;

/**
 * __useGet_ContactsQuery__
 *
 * To run a query within a React component, call `useGet_ContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_ContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_ContactsQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useGet_ContactsQuery(baseOptions?: Apollo.QueryHookOptions<Get_ContactsQuery, Get_ContactsQueryVariables>) {
        return Apollo.useQuery<Get_ContactsQuery, Get_ContactsQueryVariables>(Get_ContactsDocument, baseOptions);
      }
export function useGet_ContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_ContactsQuery, Get_ContactsQueryVariables>) {
          return Apollo.useLazyQuery<Get_ContactsQuery, Get_ContactsQueryVariables>(Get_ContactsDocument, baseOptions);
        }
export type Get_ContactsQueryHookResult = ReturnType<typeof useGet_ContactsQuery>;
export type Get_ContactsLazyQueryHookResult = ReturnType<typeof useGet_ContactsLazyQuery>;
export type Get_ContactsQueryResult = Apollo.QueryResult<Get_ContactsQuery, Get_ContactsQueryVariables>;
export const Insert_ContactDocument = gql`
    mutation insert_contact($contact: contacts_insert_input!) {
  insert_contacts(objects: [$contact]) {
    returning {
      id
      address
      city
      company
      mail
      first_name
      last_name
      mobile
      phone
      zip_code
    }
  }
}
    `;
export type Insert_ContactMutationFn = Apollo.MutationFunction<Insert_ContactMutation, Insert_ContactMutationVariables>;

/**
 * __useInsert_ContactMutation__
 *
 * To run a mutation, you first call `useInsert_ContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_ContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertContactMutation, { data, loading, error }] = useInsert_ContactMutation({
 *   variables: {
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useInsert_ContactMutation(baseOptions?: Apollo.MutationHookOptions<Insert_ContactMutation, Insert_ContactMutationVariables>) {
        return Apollo.useMutation<Insert_ContactMutation, Insert_ContactMutationVariables>(Insert_ContactDocument, baseOptions);
      }
export type Insert_ContactMutationHookResult = ReturnType<typeof useInsert_ContactMutation>;
export type Insert_ContactMutationResult = Apollo.MutationResult<Insert_ContactMutation>;
export type Insert_ContactMutationOptions = Apollo.BaseMutationOptions<Insert_ContactMutation, Insert_ContactMutationVariables>;
export const Update_ContactDocument = gql`
    mutation update_contact($id: uuid, $contact: contacts_set_input!) {
  update_contacts(where: {id: {_eq: $id}}, _set: $contact) {
    returning {
      id
      address
      city
      company
      mail
      first_name
      last_name
      mobile
      phone
      zip_code
      type
    }
  }
}
    `;
export type Update_ContactMutationFn = Apollo.MutationFunction<Update_ContactMutation, Update_ContactMutationVariables>;

/**
 * __useUpdate_ContactMutation__
 *
 * To run a mutation, you first call `useUpdate_ContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_ContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdate_ContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useUpdate_ContactMutation(baseOptions?: Apollo.MutationHookOptions<Update_ContactMutation, Update_ContactMutationVariables>) {
        return Apollo.useMutation<Update_ContactMutation, Update_ContactMutationVariables>(Update_ContactDocument, baseOptions);
      }
export type Update_ContactMutationHookResult = ReturnType<typeof useUpdate_ContactMutation>;
export type Update_ContactMutationResult = Apollo.MutationResult<Update_ContactMutation>;
export type Update_ContactMutationOptions = Apollo.BaseMutationOptions<Update_ContactMutation, Update_ContactMutationVariables>;
export const List_Order_Confirmation_TemplatesDocument = gql`
    query list_order_confirmation_templates($orderBy: order_confirmation_templates_order_by!, $where: order_confirmation_templates_bool_exp, $limit: Int) {
  order_confirmation_templates(order_by: [$orderBy], where: $where, limit: $limit) {
    changed
    created
    description
    id
    other_terms
    template_title
    title
  }
}
    `;

/**
 * __useList_Order_Confirmation_TemplatesQuery__
 *
 * To run a query within a React component, call `useList_Order_Confirmation_TemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_Order_Confirmation_TemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_Order_Confirmation_TemplatesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useList_Order_Confirmation_TemplatesQuery(baseOptions: Apollo.QueryHookOptions<List_Order_Confirmation_TemplatesQuery, List_Order_Confirmation_TemplatesQueryVariables>) {
        return Apollo.useQuery<List_Order_Confirmation_TemplatesQuery, List_Order_Confirmation_TemplatesQueryVariables>(List_Order_Confirmation_TemplatesDocument, baseOptions);
      }
export function useList_Order_Confirmation_TemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_Order_Confirmation_TemplatesQuery, List_Order_Confirmation_TemplatesQueryVariables>) {
          return Apollo.useLazyQuery<List_Order_Confirmation_TemplatesQuery, List_Order_Confirmation_TemplatesQueryVariables>(List_Order_Confirmation_TemplatesDocument, baseOptions);
        }
export type List_Order_Confirmation_TemplatesQueryHookResult = ReturnType<typeof useList_Order_Confirmation_TemplatesQuery>;
export type List_Order_Confirmation_TemplatesLazyQueryHookResult = ReturnType<typeof useList_Order_Confirmation_TemplatesLazyQuery>;
export type List_Order_Confirmation_TemplatesQueryResult = Apollo.QueryResult<List_Order_Confirmation_TemplatesQuery, List_Order_Confirmation_TemplatesQueryVariables>;
export const Get_Order_Confirmation_TemplatesDocument = gql`
    query get_order_confirmation_templates($id: uuid!) {
  order_confirmation_templates_by_pk(id: $id) {
    changed
    created
    description
    id
    other_terms
    template_title
    title
    tasks(order_by: {order: asc}) {
      description
      id
      order
      price
      title
    }
  }
}
    `;

/**
 * __useGet_Order_Confirmation_TemplatesQuery__
 *
 * To run a query within a React component, call `useGet_Order_Confirmation_TemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Order_Confirmation_TemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Order_Confirmation_TemplatesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Order_Confirmation_TemplatesQuery(baseOptions: Apollo.QueryHookOptions<Get_Order_Confirmation_TemplatesQuery, Get_Order_Confirmation_TemplatesQueryVariables>) {
        return Apollo.useQuery<Get_Order_Confirmation_TemplatesQuery, Get_Order_Confirmation_TemplatesQueryVariables>(Get_Order_Confirmation_TemplatesDocument, baseOptions);
      }
export function useGet_Order_Confirmation_TemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Order_Confirmation_TemplatesQuery, Get_Order_Confirmation_TemplatesQueryVariables>) {
          return Apollo.useLazyQuery<Get_Order_Confirmation_TemplatesQuery, Get_Order_Confirmation_TemplatesQueryVariables>(Get_Order_Confirmation_TemplatesDocument, baseOptions);
        }
export type Get_Order_Confirmation_TemplatesQueryHookResult = ReturnType<typeof useGet_Order_Confirmation_TemplatesQuery>;
export type Get_Order_Confirmation_TemplatesLazyQueryHookResult = ReturnType<typeof useGet_Order_Confirmation_TemplatesLazyQuery>;
export type Get_Order_Confirmation_TemplatesQueryResult = Apollo.QueryResult<Get_Order_Confirmation_TemplatesQuery, Get_Order_Confirmation_TemplatesQueryVariables>;
export const Get_Order_Confirmation_Template_With_CaseDocument = gql`
    query get_order_confirmation_template_with_case($id: uuid!, $caseId: uuid!) {
  order_confirmation_templates_by_pk(id: $id) {
    changed
    created
    description
    id
    other_terms
    template_title
    title
    tasks(order_by: {order: asc}) {
      description
      id
      order
      price
      title
    }
  }
  cases_by_pk(id: $caseId) {
    address
    builder_id
    builder {
      address
      city
      company
      first_name
      id
      last_name
      mail
      mobile
      phone
      zip_code
    }
    city
    date_of_inspection
    other_address
    responsible_id
    responsible {
      acronym
      first_name
      last_name
      mail
      mobile
      phone
    }
    zip_code
  }
}
    `;

/**
 * __useGet_Order_Confirmation_Template_With_CaseQuery__
 *
 * To run a query within a React component, call `useGet_Order_Confirmation_Template_With_CaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Order_Confirmation_Template_With_CaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Order_Confirmation_Template_With_CaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *      caseId: // value for 'caseId'
 *   },
 * });
 */
export function useGet_Order_Confirmation_Template_With_CaseQuery(baseOptions: Apollo.QueryHookOptions<Get_Order_Confirmation_Template_With_CaseQuery, Get_Order_Confirmation_Template_With_CaseQueryVariables>) {
        return Apollo.useQuery<Get_Order_Confirmation_Template_With_CaseQuery, Get_Order_Confirmation_Template_With_CaseQueryVariables>(Get_Order_Confirmation_Template_With_CaseDocument, baseOptions);
      }
export function useGet_Order_Confirmation_Template_With_CaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Order_Confirmation_Template_With_CaseQuery, Get_Order_Confirmation_Template_With_CaseQueryVariables>) {
          return Apollo.useLazyQuery<Get_Order_Confirmation_Template_With_CaseQuery, Get_Order_Confirmation_Template_With_CaseQueryVariables>(Get_Order_Confirmation_Template_With_CaseDocument, baseOptions);
        }
export type Get_Order_Confirmation_Template_With_CaseQueryHookResult = ReturnType<typeof useGet_Order_Confirmation_Template_With_CaseQuery>;
export type Get_Order_Confirmation_Template_With_CaseLazyQueryHookResult = ReturnType<typeof useGet_Order_Confirmation_Template_With_CaseLazyQuery>;
export type Get_Order_Confirmation_Template_With_CaseQueryResult = Apollo.QueryResult<Get_Order_Confirmation_Template_With_CaseQuery, Get_Order_Confirmation_Template_With_CaseQueryVariables>;
export const Insert_Order_Confirmation_TemplateDocument = gql`
    mutation insert_order_confirmation_template($orderConfirmationTemplate: order_confirmation_templates_insert_input!) {
  insert_order_confirmation_templates(objects: [$orderConfirmationTemplate]) {
    returning {
      changed
      created
      description
      id
      other_terms
      template_title
      title
      tasks(order_by: {order: asc}) {
        description
        id
        order
        price
        title
      }
    }
  }
}
    `;
export type Insert_Order_Confirmation_TemplateMutationFn = Apollo.MutationFunction<Insert_Order_Confirmation_TemplateMutation, Insert_Order_Confirmation_TemplateMutationVariables>;

/**
 * __useInsert_Order_Confirmation_TemplateMutation__
 *
 * To run a mutation, you first call `useInsert_Order_Confirmation_TemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Order_Confirmation_TemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOrderConfirmationTemplateMutation, { data, loading, error }] = useInsert_Order_Confirmation_TemplateMutation({
 *   variables: {
 *      orderConfirmationTemplate: // value for 'orderConfirmationTemplate'
 *   },
 * });
 */
export function useInsert_Order_Confirmation_TemplateMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Order_Confirmation_TemplateMutation, Insert_Order_Confirmation_TemplateMutationVariables>) {
        return Apollo.useMutation<Insert_Order_Confirmation_TemplateMutation, Insert_Order_Confirmation_TemplateMutationVariables>(Insert_Order_Confirmation_TemplateDocument, baseOptions);
      }
export type Insert_Order_Confirmation_TemplateMutationHookResult = ReturnType<typeof useInsert_Order_Confirmation_TemplateMutation>;
export type Insert_Order_Confirmation_TemplateMutationResult = Apollo.MutationResult<Insert_Order_Confirmation_TemplateMutation>;
export type Insert_Order_Confirmation_TemplateMutationOptions = Apollo.BaseMutationOptions<Insert_Order_Confirmation_TemplateMutation, Insert_Order_Confirmation_TemplateMutationVariables>;
export const Update_Order_Confirmation_TemplateDocument = gql`
    mutation update_order_confirmation_template($id: uuid, $orderConfirmationTemplate: order_confirmation_templates_set_input!) {
  update_order_confirmation_templates(
    where: {id: {_eq: $id}}
    _set: $orderConfirmationTemplate
  ) {
    returning {
      changed
      created
      description
      id
      other_terms
      template_title
      title
      tasks(order_by: {order: asc}) {
        description
        id
        order
        price
        title
      }
    }
  }
}
    `;
export type Update_Order_Confirmation_TemplateMutationFn = Apollo.MutationFunction<Update_Order_Confirmation_TemplateMutation, Update_Order_Confirmation_TemplateMutationVariables>;

/**
 * __useUpdate_Order_Confirmation_TemplateMutation__
 *
 * To run a mutation, you first call `useUpdate_Order_Confirmation_TemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Order_Confirmation_TemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderConfirmationTemplateMutation, { data, loading, error }] = useUpdate_Order_Confirmation_TemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      orderConfirmationTemplate: // value for 'orderConfirmationTemplate'
 *   },
 * });
 */
export function useUpdate_Order_Confirmation_TemplateMutation(baseOptions?: Apollo.MutationHookOptions<Update_Order_Confirmation_TemplateMutation, Update_Order_Confirmation_TemplateMutationVariables>) {
        return Apollo.useMutation<Update_Order_Confirmation_TemplateMutation, Update_Order_Confirmation_TemplateMutationVariables>(Update_Order_Confirmation_TemplateDocument, baseOptions);
      }
export type Update_Order_Confirmation_TemplateMutationHookResult = ReturnType<typeof useUpdate_Order_Confirmation_TemplateMutation>;
export type Update_Order_Confirmation_TemplateMutationResult = Apollo.MutationResult<Update_Order_Confirmation_TemplateMutation>;
export type Update_Order_Confirmation_TemplateMutationOptions = Apollo.BaseMutationOptions<Update_Order_Confirmation_TemplateMutation, Update_Order_Confirmation_TemplateMutationVariables>;
export const Insert_Order_Confirmation_Template_TaskDocument = gql`
    mutation insert_order_confirmation_template_task($orderConfirmationTemplateTasks: [order_confirmation_template_tasks_insert_input!]!) {
  insert_order_confirmation_template_tasks(
    objects: $orderConfirmationTemplateTasks
  ) {
    returning {
      description
      id
      order
      price
      title
    }
  }
}
    `;
export type Insert_Order_Confirmation_Template_TaskMutationFn = Apollo.MutationFunction<Insert_Order_Confirmation_Template_TaskMutation, Insert_Order_Confirmation_Template_TaskMutationVariables>;

/**
 * __useInsert_Order_Confirmation_Template_TaskMutation__
 *
 * To run a mutation, you first call `useInsert_Order_Confirmation_Template_TaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Order_Confirmation_Template_TaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertOrderConfirmationTemplateTaskMutation, { data, loading, error }] = useInsert_Order_Confirmation_Template_TaskMutation({
 *   variables: {
 *      orderConfirmationTemplateTasks: // value for 'orderConfirmationTemplateTasks'
 *   },
 * });
 */
export function useInsert_Order_Confirmation_Template_TaskMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Order_Confirmation_Template_TaskMutation, Insert_Order_Confirmation_Template_TaskMutationVariables>) {
        return Apollo.useMutation<Insert_Order_Confirmation_Template_TaskMutation, Insert_Order_Confirmation_Template_TaskMutationVariables>(Insert_Order_Confirmation_Template_TaskDocument, baseOptions);
      }
export type Insert_Order_Confirmation_Template_TaskMutationHookResult = ReturnType<typeof useInsert_Order_Confirmation_Template_TaskMutation>;
export type Insert_Order_Confirmation_Template_TaskMutationResult = Apollo.MutationResult<Insert_Order_Confirmation_Template_TaskMutation>;
export type Insert_Order_Confirmation_Template_TaskMutationOptions = Apollo.BaseMutationOptions<Insert_Order_Confirmation_Template_TaskMutation, Insert_Order_Confirmation_Template_TaskMutationVariables>;
export const Update_Order_Confirmation_Template_TaskDocument = gql`
    mutation update_order_confirmation_template_task($id: uuid, $orderConfirmationTemplateTask: order_confirmation_template_tasks_set_input!) {
  update_order_confirmation_template_tasks(
    where: {id: {_eq: $id}}
    _set: $orderConfirmationTemplateTask
  ) {
    returning {
      description
      id
      order
      price
      title
    }
  }
}
    `;
export type Update_Order_Confirmation_Template_TaskMutationFn = Apollo.MutationFunction<Update_Order_Confirmation_Template_TaskMutation, Update_Order_Confirmation_Template_TaskMutationVariables>;

/**
 * __useUpdate_Order_Confirmation_Template_TaskMutation__
 *
 * To run a mutation, you first call `useUpdate_Order_Confirmation_Template_TaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Order_Confirmation_Template_TaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderConfirmationTemplateTaskMutation, { data, loading, error }] = useUpdate_Order_Confirmation_Template_TaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      orderConfirmationTemplateTask: // value for 'orderConfirmationTemplateTask'
 *   },
 * });
 */
export function useUpdate_Order_Confirmation_Template_TaskMutation(baseOptions?: Apollo.MutationHookOptions<Update_Order_Confirmation_Template_TaskMutation, Update_Order_Confirmation_Template_TaskMutationVariables>) {
        return Apollo.useMutation<Update_Order_Confirmation_Template_TaskMutation, Update_Order_Confirmation_Template_TaskMutationVariables>(Update_Order_Confirmation_Template_TaskDocument, baseOptions);
      }
export type Update_Order_Confirmation_Template_TaskMutationHookResult = ReturnType<typeof useUpdate_Order_Confirmation_Template_TaskMutation>;
export type Update_Order_Confirmation_Template_TaskMutationResult = Apollo.MutationResult<Update_Order_Confirmation_Template_TaskMutation>;
export type Update_Order_Confirmation_Template_TaskMutationOptions = Apollo.BaseMutationOptions<Update_Order_Confirmation_Template_TaskMutation, Update_Order_Confirmation_Template_TaskMutationVariables>;
export const Delete_Order_Confirmation_Template_TasksDocument = gql`
    mutation delete_order_confirmation_template_tasks($where: order_confirmation_template_tasks_bool_exp!) {
  delete_order_confirmation_template_tasks(where: $where) {
    affected_rows
  }
}
    `;
export type Delete_Order_Confirmation_Template_TasksMutationFn = Apollo.MutationFunction<Delete_Order_Confirmation_Template_TasksMutation, Delete_Order_Confirmation_Template_TasksMutationVariables>;

/**
 * __useDelete_Order_Confirmation_Template_TasksMutation__
 *
 * To run a mutation, you first call `useDelete_Order_Confirmation_Template_TasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Order_Confirmation_Template_TasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderConfirmationTemplateTasksMutation, { data, loading, error }] = useDelete_Order_Confirmation_Template_TasksMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDelete_Order_Confirmation_Template_TasksMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Order_Confirmation_Template_TasksMutation, Delete_Order_Confirmation_Template_TasksMutationVariables>) {
        return Apollo.useMutation<Delete_Order_Confirmation_Template_TasksMutation, Delete_Order_Confirmation_Template_TasksMutationVariables>(Delete_Order_Confirmation_Template_TasksDocument, baseOptions);
      }
export type Delete_Order_Confirmation_Template_TasksMutationHookResult = ReturnType<typeof useDelete_Order_Confirmation_Template_TasksMutation>;
export type Delete_Order_Confirmation_Template_TasksMutationResult = Apollo.MutationResult<Delete_Order_Confirmation_Template_TasksMutation>;
export type Delete_Order_Confirmation_Template_TasksMutationOptions = Apollo.BaseMutationOptions<Delete_Order_Confirmation_Template_TasksMutation, Delete_Order_Confirmation_Template_TasksMutationVariables>;
export const List_Case_Doc_Order_ConfirmationsDocument = gql`
    query list_case_doc_order_confirmations($orderBy: case_doc_order_confirmations_order_by!, $where: case_doc_order_confirmations_bool_exp, $limit: Int) {
  case_doc_order_confirmations(order_by: [$orderBy], where: $where, limit: $limit) {
    changed
    created
    description
    id
    other_terms
    title
    type
  }
}
    `;

/**
 * __useList_Case_Doc_Order_ConfirmationsQuery__
 *
 * To run a query within a React component, call `useList_Case_Doc_Order_ConfirmationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_Case_Doc_Order_ConfirmationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_Case_Doc_Order_ConfirmationsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useList_Case_Doc_Order_ConfirmationsQuery(baseOptions: Apollo.QueryHookOptions<List_Case_Doc_Order_ConfirmationsQuery, List_Case_Doc_Order_ConfirmationsQueryVariables>) {
        return Apollo.useQuery<List_Case_Doc_Order_ConfirmationsQuery, List_Case_Doc_Order_ConfirmationsQueryVariables>(List_Case_Doc_Order_ConfirmationsDocument, baseOptions);
      }
export function useList_Case_Doc_Order_ConfirmationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_Case_Doc_Order_ConfirmationsQuery, List_Case_Doc_Order_ConfirmationsQueryVariables>) {
          return Apollo.useLazyQuery<List_Case_Doc_Order_ConfirmationsQuery, List_Case_Doc_Order_ConfirmationsQueryVariables>(List_Case_Doc_Order_ConfirmationsDocument, baseOptions);
        }
export type List_Case_Doc_Order_ConfirmationsQueryHookResult = ReturnType<typeof useList_Case_Doc_Order_ConfirmationsQuery>;
export type List_Case_Doc_Order_ConfirmationsLazyQueryHookResult = ReturnType<typeof useList_Case_Doc_Order_ConfirmationsLazyQuery>;
export type List_Case_Doc_Order_ConfirmationsQueryResult = Apollo.QueryResult<List_Case_Doc_Order_ConfirmationsQuery, List_Case_Doc_Order_ConfirmationsQueryVariables>;
export const Get_Order_ConfirmationDocument = gql`
    query get_order_confirmation($id: uuid!) {
  case_doc_order_confirmations_by_pk(id: $id) {
    changed
    created
    description
    document_date
    revision_date
    type
    drawings_date
    id
    other_terms
    share_key
    title
    work_ends_date
    work_starts_date
    case {
      address
      builder_id
      builder {
        address
        city
        company
        first_name
        id
        last_name
        mail
        mobile
        phone
        zip_code
      }
      city
      date_of_inspection
      other_address
      responsible_id
      responsible {
        acronym
        first_name
        last_name
        mail
        mobile
        phone
      }
      zip_code
    }
    tasks(order_by: {order: asc}) {
      description
      id
      order
      price
      title
    }
  }
}
    `;

/**
 * __useGet_Order_ConfirmationQuery__
 *
 * To run a query within a React component, call `useGet_Order_ConfirmationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Order_ConfirmationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Order_ConfirmationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Order_ConfirmationQuery(baseOptions: Apollo.QueryHookOptions<Get_Order_ConfirmationQuery, Get_Order_ConfirmationQueryVariables>) {
        return Apollo.useQuery<Get_Order_ConfirmationQuery, Get_Order_ConfirmationQueryVariables>(Get_Order_ConfirmationDocument, baseOptions);
      }
export function useGet_Order_ConfirmationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Order_ConfirmationQuery, Get_Order_ConfirmationQueryVariables>) {
          return Apollo.useLazyQuery<Get_Order_ConfirmationQuery, Get_Order_ConfirmationQueryVariables>(Get_Order_ConfirmationDocument, baseOptions);
        }
export type Get_Order_ConfirmationQueryHookResult = ReturnType<typeof useGet_Order_ConfirmationQuery>;
export type Get_Order_ConfirmationLazyQueryHookResult = ReturnType<typeof useGet_Order_ConfirmationLazyQuery>;
export type Get_Order_ConfirmationQueryResult = Apollo.QueryResult<Get_Order_ConfirmationQuery, Get_Order_ConfirmationQueryVariables>;
export const Insert_Case_Doc_Order_ConfirmationsDocument = gql`
    mutation insert_case_doc_order_confirmations($orderConfirmation: case_doc_order_confirmations_insert_input!) {
  insert_case_doc_order_confirmations(objects: [$orderConfirmation]) {
    returning {
      changed
      created
      description
      document_date
      revision_date
      drawings_date
      id
      type
      other_terms
      share_key
      title
      work_ends_date
      work_starts_date
      case {
        address
        builder {
          address
          city
          company
          first_name
          id
          last_name
          mail
          mobile
          phone
          zip_code
        }
        city
        date_of_inspection
        other_address
        responsible {
          acronym
          first_name
          last_name
          mail
          mobile
          phone
        }
        zip_code
      }
      tasks(order_by: {order: asc}) {
        description
        id
        order
        price
        title
      }
    }
  }
}
    `;
export type Insert_Case_Doc_Order_ConfirmationsMutationFn = Apollo.MutationFunction<Insert_Case_Doc_Order_ConfirmationsMutation, Insert_Case_Doc_Order_ConfirmationsMutationVariables>;

/**
 * __useInsert_Case_Doc_Order_ConfirmationsMutation__
 *
 * To run a mutation, you first call `useInsert_Case_Doc_Order_ConfirmationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Case_Doc_Order_ConfirmationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCaseDocOrderConfirmationsMutation, { data, loading, error }] = useInsert_Case_Doc_Order_ConfirmationsMutation({
 *   variables: {
 *      orderConfirmation: // value for 'orderConfirmation'
 *   },
 * });
 */
export function useInsert_Case_Doc_Order_ConfirmationsMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Case_Doc_Order_ConfirmationsMutation, Insert_Case_Doc_Order_ConfirmationsMutationVariables>) {
        return Apollo.useMutation<Insert_Case_Doc_Order_ConfirmationsMutation, Insert_Case_Doc_Order_ConfirmationsMutationVariables>(Insert_Case_Doc_Order_ConfirmationsDocument, baseOptions);
      }
export type Insert_Case_Doc_Order_ConfirmationsMutationHookResult = ReturnType<typeof useInsert_Case_Doc_Order_ConfirmationsMutation>;
export type Insert_Case_Doc_Order_ConfirmationsMutationResult = Apollo.MutationResult<Insert_Case_Doc_Order_ConfirmationsMutation>;
export type Insert_Case_Doc_Order_ConfirmationsMutationOptions = Apollo.BaseMutationOptions<Insert_Case_Doc_Order_ConfirmationsMutation, Insert_Case_Doc_Order_ConfirmationsMutationVariables>;
export const Update_Case_Doc_Order_ConfirmationsDocument = gql`
    mutation update_case_doc_order_confirmations($id: uuid, $orderConfirmation: case_doc_order_confirmations_set_input!) {
  update_case_doc_order_confirmations(
    where: {id: {_eq: $id}}
    _set: $orderConfirmation
  ) {
    returning {
      changed
      created
      description
      type
      document_date
      revision_date
      drawings_date
      id
      other_terms
      share_key
      title
      work_ends_date
      work_starts_date
      case {
        address
        builder {
          address
          city
          company
          first_name
          id
          last_name
          mail
          mobile
          phone
          zip_code
        }
        city
        date_of_inspection
        other_address
        responsible {
          acronym
          first_name
          last_name
          mail
          mobile
          phone
        }
        zip_code
      }
      tasks(order_by: {order: asc}) {
        description
        id
        order
        price
        title
      }
    }
  }
}
    `;
export type Update_Case_Doc_Order_ConfirmationsMutationFn = Apollo.MutationFunction<Update_Case_Doc_Order_ConfirmationsMutation, Update_Case_Doc_Order_ConfirmationsMutationVariables>;

/**
 * __useUpdate_Case_Doc_Order_ConfirmationsMutation__
 *
 * To run a mutation, you first call `useUpdate_Case_Doc_Order_ConfirmationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Case_Doc_Order_ConfirmationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseDocOrderConfirmationsMutation, { data, loading, error }] = useUpdate_Case_Doc_Order_ConfirmationsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      orderConfirmation: // value for 'orderConfirmation'
 *   },
 * });
 */
export function useUpdate_Case_Doc_Order_ConfirmationsMutation(baseOptions?: Apollo.MutationHookOptions<Update_Case_Doc_Order_ConfirmationsMutation, Update_Case_Doc_Order_ConfirmationsMutationVariables>) {
        return Apollo.useMutation<Update_Case_Doc_Order_ConfirmationsMutation, Update_Case_Doc_Order_ConfirmationsMutationVariables>(Update_Case_Doc_Order_ConfirmationsDocument, baseOptions);
      }
export type Update_Case_Doc_Order_ConfirmationsMutationHookResult = ReturnType<typeof useUpdate_Case_Doc_Order_ConfirmationsMutation>;
export type Update_Case_Doc_Order_ConfirmationsMutationResult = Apollo.MutationResult<Update_Case_Doc_Order_ConfirmationsMutation>;
export type Update_Case_Doc_Order_ConfirmationsMutationOptions = Apollo.BaseMutationOptions<Update_Case_Doc_Order_ConfirmationsMutation, Update_Case_Doc_Order_ConfirmationsMutationVariables>;
export const Insert_Case_Doc_Order_Confirmations_TaskDocument = gql`
    mutation insert_case_doc_order_confirmations_task($orderConfirmationTasks: [order_confirmation_tasks_insert_input!]!) {
  insert_order_confirmation_tasks(objects: $orderConfirmationTasks) {
    returning {
      description
      id
      order
      price
      title
    }
  }
}
    `;
export type Insert_Case_Doc_Order_Confirmations_TaskMutationFn = Apollo.MutationFunction<Insert_Case_Doc_Order_Confirmations_TaskMutation, Insert_Case_Doc_Order_Confirmations_TaskMutationVariables>;

/**
 * __useInsert_Case_Doc_Order_Confirmations_TaskMutation__
 *
 * To run a mutation, you first call `useInsert_Case_Doc_Order_Confirmations_TaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Case_Doc_Order_Confirmations_TaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCaseDocOrderConfirmationsTaskMutation, { data, loading, error }] = useInsert_Case_Doc_Order_Confirmations_TaskMutation({
 *   variables: {
 *      orderConfirmationTasks: // value for 'orderConfirmationTasks'
 *   },
 * });
 */
export function useInsert_Case_Doc_Order_Confirmations_TaskMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Case_Doc_Order_Confirmations_TaskMutation, Insert_Case_Doc_Order_Confirmations_TaskMutationVariables>) {
        return Apollo.useMutation<Insert_Case_Doc_Order_Confirmations_TaskMutation, Insert_Case_Doc_Order_Confirmations_TaskMutationVariables>(Insert_Case_Doc_Order_Confirmations_TaskDocument, baseOptions);
      }
export type Insert_Case_Doc_Order_Confirmations_TaskMutationHookResult = ReturnType<typeof useInsert_Case_Doc_Order_Confirmations_TaskMutation>;
export type Insert_Case_Doc_Order_Confirmations_TaskMutationResult = Apollo.MutationResult<Insert_Case_Doc_Order_Confirmations_TaskMutation>;
export type Insert_Case_Doc_Order_Confirmations_TaskMutationOptions = Apollo.BaseMutationOptions<Insert_Case_Doc_Order_Confirmations_TaskMutation, Insert_Case_Doc_Order_Confirmations_TaskMutationVariables>;
export const Update_Case_Doc_Order_Confirmations_TaskDocument = gql`
    mutation update_case_doc_order_confirmations_task($id: uuid, $orderConfirmationTask: order_confirmation_tasks_set_input!) {
  update_order_confirmation_tasks(
    where: {id: {_eq: $id}}
    _set: $orderConfirmationTask
  ) {
    returning {
      description
      id
      order
      price
      title
    }
  }
}
    `;
export type Update_Case_Doc_Order_Confirmations_TaskMutationFn = Apollo.MutationFunction<Update_Case_Doc_Order_Confirmations_TaskMutation, Update_Case_Doc_Order_Confirmations_TaskMutationVariables>;

/**
 * __useUpdate_Case_Doc_Order_Confirmations_TaskMutation__
 *
 * To run a mutation, you first call `useUpdate_Case_Doc_Order_Confirmations_TaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Case_Doc_Order_Confirmations_TaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseDocOrderConfirmationsTaskMutation, { data, loading, error }] = useUpdate_Case_Doc_Order_Confirmations_TaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      orderConfirmationTask: // value for 'orderConfirmationTask'
 *   },
 * });
 */
export function useUpdate_Case_Doc_Order_Confirmations_TaskMutation(baseOptions?: Apollo.MutationHookOptions<Update_Case_Doc_Order_Confirmations_TaskMutation, Update_Case_Doc_Order_Confirmations_TaskMutationVariables>) {
        return Apollo.useMutation<Update_Case_Doc_Order_Confirmations_TaskMutation, Update_Case_Doc_Order_Confirmations_TaskMutationVariables>(Update_Case_Doc_Order_Confirmations_TaskDocument, baseOptions);
      }
export type Update_Case_Doc_Order_Confirmations_TaskMutationHookResult = ReturnType<typeof useUpdate_Case_Doc_Order_Confirmations_TaskMutation>;
export type Update_Case_Doc_Order_Confirmations_TaskMutationResult = Apollo.MutationResult<Update_Case_Doc_Order_Confirmations_TaskMutation>;
export type Update_Case_Doc_Order_Confirmations_TaskMutationOptions = Apollo.BaseMutationOptions<Update_Case_Doc_Order_Confirmations_TaskMutation, Update_Case_Doc_Order_Confirmations_TaskMutationVariables>;
export const Delete_Order_Confirmation_TasksDocument = gql`
    mutation delete_order_confirmation_tasks($where: order_confirmation_tasks_bool_exp!) {
  delete_order_confirmation_tasks(where: $where) {
    affected_rows
  }
}
    `;
export type Delete_Order_Confirmation_TasksMutationFn = Apollo.MutationFunction<Delete_Order_Confirmation_TasksMutation, Delete_Order_Confirmation_TasksMutationVariables>;

/**
 * __useDelete_Order_Confirmation_TasksMutation__
 *
 * To run a mutation, you first call `useDelete_Order_Confirmation_TasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Order_Confirmation_TasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderConfirmationTasksMutation, { data, loading, error }] = useDelete_Order_Confirmation_TasksMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDelete_Order_Confirmation_TasksMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Order_Confirmation_TasksMutation, Delete_Order_Confirmation_TasksMutationVariables>) {
        return Apollo.useMutation<Delete_Order_Confirmation_TasksMutation, Delete_Order_Confirmation_TasksMutationVariables>(Delete_Order_Confirmation_TasksDocument, baseOptions);
      }
export type Delete_Order_Confirmation_TasksMutationHookResult = ReturnType<typeof useDelete_Order_Confirmation_TasksMutation>;
export type Delete_Order_Confirmation_TasksMutationResult = Apollo.MutationResult<Delete_Order_Confirmation_TasksMutation>;
export type Delete_Order_Confirmation_TasksMutationOptions = Apollo.BaseMutationOptions<Delete_Order_Confirmation_TasksMutation, Delete_Order_Confirmation_TasksMutationVariables>;
export const List_Quality_Control_TemplatesDocument = gql`
    query list_quality_control_templates($orderBy: quality_control_templates_order_by!, $where: quality_control_templates_bool_exp, $limit: Int) {
  quality_control_templates(order_by: [$orderBy], where: $where, limit: $limit) {
    changed
    created
    id
    template_title
    title
  }
}
    `;

/**
 * __useList_Quality_Control_TemplatesQuery__
 *
 * To run a query within a React component, call `useList_Quality_Control_TemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_Quality_Control_TemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_Quality_Control_TemplatesQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useList_Quality_Control_TemplatesQuery(baseOptions: Apollo.QueryHookOptions<List_Quality_Control_TemplatesQuery, List_Quality_Control_TemplatesQueryVariables>) {
        return Apollo.useQuery<List_Quality_Control_TemplatesQuery, List_Quality_Control_TemplatesQueryVariables>(List_Quality_Control_TemplatesDocument, baseOptions);
      }
export function useList_Quality_Control_TemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_Quality_Control_TemplatesQuery, List_Quality_Control_TemplatesQueryVariables>) {
          return Apollo.useLazyQuery<List_Quality_Control_TemplatesQuery, List_Quality_Control_TemplatesQueryVariables>(List_Quality_Control_TemplatesDocument, baseOptions);
        }
export type List_Quality_Control_TemplatesQueryHookResult = ReturnType<typeof useList_Quality_Control_TemplatesQuery>;
export type List_Quality_Control_TemplatesLazyQueryHookResult = ReturnType<typeof useList_Quality_Control_TemplatesLazyQuery>;
export type List_Quality_Control_TemplatesQueryResult = Apollo.QueryResult<List_Quality_Control_TemplatesQuery, List_Quality_Control_TemplatesQueryVariables>;
export const Get_Quality_Control_TemplatesDocument = gql`
    query get_quality_control_templates($id: uuid!) {
  quality_control_templates_by_pk(id: $id) {
    changed
    created
    id
    template_title
    title
    tasks(order_by: {order: asc}) {
      description
      id
      order
      file_id
      file {
        id
        share_key
        filename
        created
      }
      title
    }
  }
}
    `;

/**
 * __useGet_Quality_Control_TemplatesQuery__
 *
 * To run a query within a React component, call `useGet_Quality_Control_TemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Quality_Control_TemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Quality_Control_TemplatesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Quality_Control_TemplatesQuery(baseOptions: Apollo.QueryHookOptions<Get_Quality_Control_TemplatesQuery, Get_Quality_Control_TemplatesQueryVariables>) {
        return Apollo.useQuery<Get_Quality_Control_TemplatesQuery, Get_Quality_Control_TemplatesQueryVariables>(Get_Quality_Control_TemplatesDocument, baseOptions);
      }
export function useGet_Quality_Control_TemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Quality_Control_TemplatesQuery, Get_Quality_Control_TemplatesQueryVariables>) {
          return Apollo.useLazyQuery<Get_Quality_Control_TemplatesQuery, Get_Quality_Control_TemplatesQueryVariables>(Get_Quality_Control_TemplatesDocument, baseOptions);
        }
export type Get_Quality_Control_TemplatesQueryHookResult = ReturnType<typeof useGet_Quality_Control_TemplatesQuery>;
export type Get_Quality_Control_TemplatesLazyQueryHookResult = ReturnType<typeof useGet_Quality_Control_TemplatesLazyQuery>;
export type Get_Quality_Control_TemplatesQueryResult = Apollo.QueryResult<Get_Quality_Control_TemplatesQuery, Get_Quality_Control_TemplatesQueryVariables>;
export const Get_Quality_Control_Template_With_CaseDocument = gql`
    query get_quality_control_template_with_case($id: uuid!, $caseId: uuid!) {
  quality_control_templates_by_pk(id: $id) {
    changed
    created
    id
    template_title
    title
    tasks(order_by: {order: asc}) {
      description
      id
      order
      file_id
      file {
        id
        share_key
        filename
        created
      }
      title
    }
  }
  cases_by_pk(id: $caseId) {
    address
    builder_id
    builder {
      address
      city
      company
      first_name
      id
      last_name
      mail
      mobile
      phone
      zip_code
    }
    city
    date_of_inspection
    other_address
    responsible_id
    responsible {
      acronym
      first_name
      last_name
      mail
      mobile
      phone
    }
    zip_code
  }
}
    `;

/**
 * __useGet_Quality_Control_Template_With_CaseQuery__
 *
 * To run a query within a React component, call `useGet_Quality_Control_Template_With_CaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Quality_Control_Template_With_CaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Quality_Control_Template_With_CaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *      caseId: // value for 'caseId'
 *   },
 * });
 */
export function useGet_Quality_Control_Template_With_CaseQuery(baseOptions: Apollo.QueryHookOptions<Get_Quality_Control_Template_With_CaseQuery, Get_Quality_Control_Template_With_CaseQueryVariables>) {
        return Apollo.useQuery<Get_Quality_Control_Template_With_CaseQuery, Get_Quality_Control_Template_With_CaseQueryVariables>(Get_Quality_Control_Template_With_CaseDocument, baseOptions);
      }
export function useGet_Quality_Control_Template_With_CaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Quality_Control_Template_With_CaseQuery, Get_Quality_Control_Template_With_CaseQueryVariables>) {
          return Apollo.useLazyQuery<Get_Quality_Control_Template_With_CaseQuery, Get_Quality_Control_Template_With_CaseQueryVariables>(Get_Quality_Control_Template_With_CaseDocument, baseOptions);
        }
export type Get_Quality_Control_Template_With_CaseQueryHookResult = ReturnType<typeof useGet_Quality_Control_Template_With_CaseQuery>;
export type Get_Quality_Control_Template_With_CaseLazyQueryHookResult = ReturnType<typeof useGet_Quality_Control_Template_With_CaseLazyQuery>;
export type Get_Quality_Control_Template_With_CaseQueryResult = Apollo.QueryResult<Get_Quality_Control_Template_With_CaseQuery, Get_Quality_Control_Template_With_CaseQueryVariables>;
export const Insert_Quality_Control_TemplateDocument = gql`
    mutation insert_quality_control_template($qualityControlTemplate: quality_control_templates_insert_input!) {
  insert_quality_control_templates(objects: [$qualityControlTemplate]) {
    returning {
      changed
      created
      id
      template_title
      title
      tasks(order_by: {order: asc}) {
        description
        id
        order
        file_id
        title
      }
    }
  }
}
    `;
export type Insert_Quality_Control_TemplateMutationFn = Apollo.MutationFunction<Insert_Quality_Control_TemplateMutation, Insert_Quality_Control_TemplateMutationVariables>;

/**
 * __useInsert_Quality_Control_TemplateMutation__
 *
 * To run a mutation, you first call `useInsert_Quality_Control_TemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Quality_Control_TemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertQualityControlTemplateMutation, { data, loading, error }] = useInsert_Quality_Control_TemplateMutation({
 *   variables: {
 *      qualityControlTemplate: // value for 'qualityControlTemplate'
 *   },
 * });
 */
export function useInsert_Quality_Control_TemplateMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Quality_Control_TemplateMutation, Insert_Quality_Control_TemplateMutationVariables>) {
        return Apollo.useMutation<Insert_Quality_Control_TemplateMutation, Insert_Quality_Control_TemplateMutationVariables>(Insert_Quality_Control_TemplateDocument, baseOptions);
      }
export type Insert_Quality_Control_TemplateMutationHookResult = ReturnType<typeof useInsert_Quality_Control_TemplateMutation>;
export type Insert_Quality_Control_TemplateMutationResult = Apollo.MutationResult<Insert_Quality_Control_TemplateMutation>;
export type Insert_Quality_Control_TemplateMutationOptions = Apollo.BaseMutationOptions<Insert_Quality_Control_TemplateMutation, Insert_Quality_Control_TemplateMutationVariables>;
export const Update_Quality_Control_TemplateDocument = gql`
    mutation update_quality_control_template($id: uuid, $qualityControlTemplate: quality_control_templates_set_input!) {
  update_quality_control_templates(
    where: {id: {_eq: $id}}
    _set: $qualityControlTemplate
  ) {
    returning {
      changed
      created
      id
      template_title
      title
      tasks(order_by: {order: asc}) {
        description
        id
        order
        template_id
        file_id
        file {
          id
          share_key
          filename
          created
        }
        title
      }
    }
  }
}
    `;
export type Update_Quality_Control_TemplateMutationFn = Apollo.MutationFunction<Update_Quality_Control_TemplateMutation, Update_Quality_Control_TemplateMutationVariables>;

/**
 * __useUpdate_Quality_Control_TemplateMutation__
 *
 * To run a mutation, you first call `useUpdate_Quality_Control_TemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Quality_Control_TemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQualityControlTemplateMutation, { data, loading, error }] = useUpdate_Quality_Control_TemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      qualityControlTemplate: // value for 'qualityControlTemplate'
 *   },
 * });
 */
export function useUpdate_Quality_Control_TemplateMutation(baseOptions?: Apollo.MutationHookOptions<Update_Quality_Control_TemplateMutation, Update_Quality_Control_TemplateMutationVariables>) {
        return Apollo.useMutation<Update_Quality_Control_TemplateMutation, Update_Quality_Control_TemplateMutationVariables>(Update_Quality_Control_TemplateDocument, baseOptions);
      }
export type Update_Quality_Control_TemplateMutationHookResult = ReturnType<typeof useUpdate_Quality_Control_TemplateMutation>;
export type Update_Quality_Control_TemplateMutationResult = Apollo.MutationResult<Update_Quality_Control_TemplateMutation>;
export type Update_Quality_Control_TemplateMutationOptions = Apollo.BaseMutationOptions<Update_Quality_Control_TemplateMutation, Update_Quality_Control_TemplateMutationVariables>;
export const Insert_Quality_Control_Template_TaskDocument = gql`
    mutation insert_quality_control_template_task($qualityControlTemplateTasks: [quality_control_template_tasks_insert_input!]!) {
  insert_quality_control_template_tasks(objects: $qualityControlTemplateTasks) {
    returning {
      description
      id
      order
      file_id
      title
    }
  }
}
    `;
export type Insert_Quality_Control_Template_TaskMutationFn = Apollo.MutationFunction<Insert_Quality_Control_Template_TaskMutation, Insert_Quality_Control_Template_TaskMutationVariables>;

/**
 * __useInsert_Quality_Control_Template_TaskMutation__
 *
 * To run a mutation, you first call `useInsert_Quality_Control_Template_TaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Quality_Control_Template_TaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertQualityControlTemplateTaskMutation, { data, loading, error }] = useInsert_Quality_Control_Template_TaskMutation({
 *   variables: {
 *      qualityControlTemplateTasks: // value for 'qualityControlTemplateTasks'
 *   },
 * });
 */
export function useInsert_Quality_Control_Template_TaskMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Quality_Control_Template_TaskMutation, Insert_Quality_Control_Template_TaskMutationVariables>) {
        return Apollo.useMutation<Insert_Quality_Control_Template_TaskMutation, Insert_Quality_Control_Template_TaskMutationVariables>(Insert_Quality_Control_Template_TaskDocument, baseOptions);
      }
export type Insert_Quality_Control_Template_TaskMutationHookResult = ReturnType<typeof useInsert_Quality_Control_Template_TaskMutation>;
export type Insert_Quality_Control_Template_TaskMutationResult = Apollo.MutationResult<Insert_Quality_Control_Template_TaskMutation>;
export type Insert_Quality_Control_Template_TaskMutationOptions = Apollo.BaseMutationOptions<Insert_Quality_Control_Template_TaskMutation, Insert_Quality_Control_Template_TaskMutationVariables>;
export const Update_Quality_Control_Template_TaskDocument = gql`
    mutation update_quality_control_template_task($id: uuid, $qualityControlTemplateTask: quality_control_template_tasks_set_input!) {
  update_quality_control_template_tasks(
    where: {id: {_eq: $id}}
    _set: $qualityControlTemplateTask
  ) {
    returning {
      description
      id
      order
      title
      file_id
    }
  }
}
    `;
export type Update_Quality_Control_Template_TaskMutationFn = Apollo.MutationFunction<Update_Quality_Control_Template_TaskMutation, Update_Quality_Control_Template_TaskMutationVariables>;

/**
 * __useUpdate_Quality_Control_Template_TaskMutation__
 *
 * To run a mutation, you first call `useUpdate_Quality_Control_Template_TaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Quality_Control_Template_TaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQualityControlTemplateTaskMutation, { data, loading, error }] = useUpdate_Quality_Control_Template_TaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      qualityControlTemplateTask: // value for 'qualityControlTemplateTask'
 *   },
 * });
 */
export function useUpdate_Quality_Control_Template_TaskMutation(baseOptions?: Apollo.MutationHookOptions<Update_Quality_Control_Template_TaskMutation, Update_Quality_Control_Template_TaskMutationVariables>) {
        return Apollo.useMutation<Update_Quality_Control_Template_TaskMutation, Update_Quality_Control_Template_TaskMutationVariables>(Update_Quality_Control_Template_TaskDocument, baseOptions);
      }
export type Update_Quality_Control_Template_TaskMutationHookResult = ReturnType<typeof useUpdate_Quality_Control_Template_TaskMutation>;
export type Update_Quality_Control_Template_TaskMutationResult = Apollo.MutationResult<Update_Quality_Control_Template_TaskMutation>;
export type Update_Quality_Control_Template_TaskMutationOptions = Apollo.BaseMutationOptions<Update_Quality_Control_Template_TaskMutation, Update_Quality_Control_Template_TaskMutationVariables>;
export const Delete_Quality_Control_Template_TasksDocument = gql`
    mutation delete_quality_control_template_tasks($where: quality_control_template_tasks_bool_exp!) {
  delete_quality_control_template_tasks(where: $where) {
    affected_rows
  }
}
    `;
export type Delete_Quality_Control_Template_TasksMutationFn = Apollo.MutationFunction<Delete_Quality_Control_Template_TasksMutation, Delete_Quality_Control_Template_TasksMutationVariables>;

/**
 * __useDelete_Quality_Control_Template_TasksMutation__
 *
 * To run a mutation, you first call `useDelete_Quality_Control_Template_TasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Quality_Control_Template_TasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQualityControlTemplateTasksMutation, { data, loading, error }] = useDelete_Quality_Control_Template_TasksMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDelete_Quality_Control_Template_TasksMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Quality_Control_Template_TasksMutation, Delete_Quality_Control_Template_TasksMutationVariables>) {
        return Apollo.useMutation<Delete_Quality_Control_Template_TasksMutation, Delete_Quality_Control_Template_TasksMutationVariables>(Delete_Quality_Control_Template_TasksDocument, baseOptions);
      }
export type Delete_Quality_Control_Template_TasksMutationHookResult = ReturnType<typeof useDelete_Quality_Control_Template_TasksMutation>;
export type Delete_Quality_Control_Template_TasksMutationResult = Apollo.MutationResult<Delete_Quality_Control_Template_TasksMutation>;
export type Delete_Quality_Control_Template_TasksMutationOptions = Apollo.BaseMutationOptions<Delete_Quality_Control_Template_TasksMutation, Delete_Quality_Control_Template_TasksMutationVariables>;
export const List_Case_Doc_Quality_ControlsDocument = gql`
    query list_case_doc_quality_controls($orderBy: case_doc_quality_controls_order_by!, $where: case_doc_quality_controls_bool_exp, $limit: Int) {
  case_doc_quality_controls(order_by: [$orderBy], where: $where, limit: $limit) {
    changed
    created
    id
    title
    type
  }
}
    `;

/**
 * __useList_Case_Doc_Quality_ControlsQuery__
 *
 * To run a query within a React component, call `useList_Case_Doc_Quality_ControlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_Case_Doc_Quality_ControlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_Case_Doc_Quality_ControlsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useList_Case_Doc_Quality_ControlsQuery(baseOptions: Apollo.QueryHookOptions<List_Case_Doc_Quality_ControlsQuery, List_Case_Doc_Quality_ControlsQueryVariables>) {
        return Apollo.useQuery<List_Case_Doc_Quality_ControlsQuery, List_Case_Doc_Quality_ControlsQueryVariables>(List_Case_Doc_Quality_ControlsDocument, baseOptions);
      }
export function useList_Case_Doc_Quality_ControlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_Case_Doc_Quality_ControlsQuery, List_Case_Doc_Quality_ControlsQueryVariables>) {
          return Apollo.useLazyQuery<List_Case_Doc_Quality_ControlsQuery, List_Case_Doc_Quality_ControlsQueryVariables>(List_Case_Doc_Quality_ControlsDocument, baseOptions);
        }
export type List_Case_Doc_Quality_ControlsQueryHookResult = ReturnType<typeof useList_Case_Doc_Quality_ControlsQuery>;
export type List_Case_Doc_Quality_ControlsLazyQueryHookResult = ReturnType<typeof useList_Case_Doc_Quality_ControlsLazyQuery>;
export type List_Case_Doc_Quality_ControlsQueryResult = Apollo.QueryResult<List_Case_Doc_Quality_ControlsQuery, List_Case_Doc_Quality_ControlsQueryVariables>;
export const Get_Quality_ControlDocument = gql`
    query get_quality_control($id: uuid!) {
  case_doc_quality_controls_by_pk(id: $id) {
    changed
    created
    document_date
    revision_date
    date_of_inspection
    id
    type
    share_key
    title
    case {
      address
      builder_id
      builder {
        address
        city
        company
        first_name
        id
        last_name
        mail
        mobile
        phone
        zip_code
      }
      city
      date_of_inspection
      other_address
      responsible_id
      responsible {
        acronym
        first_name
        last_name
        mail
        mobile
        phone
      }
      zip_code
    }
    tasks(order_by: {order: asc}) {
      description
      id
      order
      title
      file_id
      file {
        id
        share_key
        filename
        created
      }
      arrows(order_by: {order: asc}) {
        id
        order
        angle
        x
        y
      }
    }
  }
}
    `;

/**
 * __useGet_Quality_ControlQuery__
 *
 * To run a query within a React component, call `useGet_Quality_ControlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Quality_ControlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Quality_ControlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Quality_ControlQuery(baseOptions: Apollo.QueryHookOptions<Get_Quality_ControlQuery, Get_Quality_ControlQueryVariables>) {
        return Apollo.useQuery<Get_Quality_ControlQuery, Get_Quality_ControlQueryVariables>(Get_Quality_ControlDocument, baseOptions);
      }
export function useGet_Quality_ControlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Quality_ControlQuery, Get_Quality_ControlQueryVariables>) {
          return Apollo.useLazyQuery<Get_Quality_ControlQuery, Get_Quality_ControlQueryVariables>(Get_Quality_ControlDocument, baseOptions);
        }
export type Get_Quality_ControlQueryHookResult = ReturnType<typeof useGet_Quality_ControlQuery>;
export type Get_Quality_ControlLazyQueryHookResult = ReturnType<typeof useGet_Quality_ControlLazyQuery>;
export type Get_Quality_ControlQueryResult = Apollo.QueryResult<Get_Quality_ControlQuery, Get_Quality_ControlQueryVariables>;
export const Insert_Case_Doc_Quality_ControlsDocument = gql`
    mutation insert_case_doc_quality_controls($qualityControl: case_doc_quality_controls_insert_input!) {
  insert_case_doc_quality_controls(objects: [$qualityControl]) {
    returning {
      changed
      created
      document_date
      revision_date
      id
      type
      share_key
      title
      date_of_inspection
      case {
        address
        builder {
          address
          city
          company
          first_name
          id
          last_name
          mail
          mobile
          phone
          zip_code
        }
        city
        date_of_inspection
        other_address
        responsible {
          acronym
          first_name
          last_name
          mail
          mobile
          phone
        }
        zip_code
      }
      tasks(order_by: {order: asc}) {
        description
        id
        order
        title
        arrows(order_by: {order: asc}) {
          id
          order
          angle
          x
          y
        }
      }
    }
  }
}
    `;
export type Insert_Case_Doc_Quality_ControlsMutationFn = Apollo.MutationFunction<Insert_Case_Doc_Quality_ControlsMutation, Insert_Case_Doc_Quality_ControlsMutationVariables>;

/**
 * __useInsert_Case_Doc_Quality_ControlsMutation__
 *
 * To run a mutation, you first call `useInsert_Case_Doc_Quality_ControlsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Case_Doc_Quality_ControlsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCaseDocQualityControlsMutation, { data, loading, error }] = useInsert_Case_Doc_Quality_ControlsMutation({
 *   variables: {
 *      qualityControl: // value for 'qualityControl'
 *   },
 * });
 */
export function useInsert_Case_Doc_Quality_ControlsMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Case_Doc_Quality_ControlsMutation, Insert_Case_Doc_Quality_ControlsMutationVariables>) {
        return Apollo.useMutation<Insert_Case_Doc_Quality_ControlsMutation, Insert_Case_Doc_Quality_ControlsMutationVariables>(Insert_Case_Doc_Quality_ControlsDocument, baseOptions);
      }
export type Insert_Case_Doc_Quality_ControlsMutationHookResult = ReturnType<typeof useInsert_Case_Doc_Quality_ControlsMutation>;
export type Insert_Case_Doc_Quality_ControlsMutationResult = Apollo.MutationResult<Insert_Case_Doc_Quality_ControlsMutation>;
export type Insert_Case_Doc_Quality_ControlsMutationOptions = Apollo.BaseMutationOptions<Insert_Case_Doc_Quality_ControlsMutation, Insert_Case_Doc_Quality_ControlsMutationVariables>;
export const Update_Case_Doc_Quality_ControlsDocument = gql`
    mutation update_case_doc_quality_controls($id: uuid, $qualityControl: case_doc_quality_controls_set_input!) {
  update_case_doc_quality_controls(where: {id: {_eq: $id}}, _set: $qualityControl) {
    returning {
      changed
      created
      type
      document_date
      revision_date
      id
      share_key
      date_of_inspection
      title
      case {
        address
        builder {
          address
          city
          company
          first_name
          id
          last_name
          mail
          mobile
          phone
          zip_code
        }
        city
        date_of_inspection
        other_address
        responsible {
          acronym
          first_name
          last_name
          mail
          mobile
          phone
        }
        zip_code
      }
      tasks(order_by: {order: asc}) {
        description
        id
        order
        title
        file_id
        file {
          id
          share_key
          filename
          created
        }
        arrows(order_by: {order: asc}) {
          id
          order
          task_id
          angle
          x
          y
        }
      }
    }
  }
}
    `;
export type Update_Case_Doc_Quality_ControlsMutationFn = Apollo.MutationFunction<Update_Case_Doc_Quality_ControlsMutation, Update_Case_Doc_Quality_ControlsMutationVariables>;

/**
 * __useUpdate_Case_Doc_Quality_ControlsMutation__
 *
 * To run a mutation, you first call `useUpdate_Case_Doc_Quality_ControlsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Case_Doc_Quality_ControlsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseDocQualityControlsMutation, { data, loading, error }] = useUpdate_Case_Doc_Quality_ControlsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      qualityControl: // value for 'qualityControl'
 *   },
 * });
 */
export function useUpdate_Case_Doc_Quality_ControlsMutation(baseOptions?: Apollo.MutationHookOptions<Update_Case_Doc_Quality_ControlsMutation, Update_Case_Doc_Quality_ControlsMutationVariables>) {
        return Apollo.useMutation<Update_Case_Doc_Quality_ControlsMutation, Update_Case_Doc_Quality_ControlsMutationVariables>(Update_Case_Doc_Quality_ControlsDocument, baseOptions);
      }
export type Update_Case_Doc_Quality_ControlsMutationHookResult = ReturnType<typeof useUpdate_Case_Doc_Quality_ControlsMutation>;
export type Update_Case_Doc_Quality_ControlsMutationResult = Apollo.MutationResult<Update_Case_Doc_Quality_ControlsMutation>;
export type Update_Case_Doc_Quality_ControlsMutationOptions = Apollo.BaseMutationOptions<Update_Case_Doc_Quality_ControlsMutation, Update_Case_Doc_Quality_ControlsMutationVariables>;
export const Insert_Case_Doc_Quality_Controls_TaskDocument = gql`
    mutation insert_case_doc_quality_controls_task($qualityControlTasks: [quality_control_tasks_insert_input!]!) {
  insert_quality_control_tasks(objects: $qualityControlTasks) {
    returning {
      description
      id
      order
      title
      file_id
    }
  }
}
    `;
export type Insert_Case_Doc_Quality_Controls_TaskMutationFn = Apollo.MutationFunction<Insert_Case_Doc_Quality_Controls_TaskMutation, Insert_Case_Doc_Quality_Controls_TaskMutationVariables>;

/**
 * __useInsert_Case_Doc_Quality_Controls_TaskMutation__
 *
 * To run a mutation, you first call `useInsert_Case_Doc_Quality_Controls_TaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Case_Doc_Quality_Controls_TaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCaseDocQualityControlsTaskMutation, { data, loading, error }] = useInsert_Case_Doc_Quality_Controls_TaskMutation({
 *   variables: {
 *      qualityControlTasks: // value for 'qualityControlTasks'
 *   },
 * });
 */
export function useInsert_Case_Doc_Quality_Controls_TaskMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Case_Doc_Quality_Controls_TaskMutation, Insert_Case_Doc_Quality_Controls_TaskMutationVariables>) {
        return Apollo.useMutation<Insert_Case_Doc_Quality_Controls_TaskMutation, Insert_Case_Doc_Quality_Controls_TaskMutationVariables>(Insert_Case_Doc_Quality_Controls_TaskDocument, baseOptions);
      }
export type Insert_Case_Doc_Quality_Controls_TaskMutationHookResult = ReturnType<typeof useInsert_Case_Doc_Quality_Controls_TaskMutation>;
export type Insert_Case_Doc_Quality_Controls_TaskMutationResult = Apollo.MutationResult<Insert_Case_Doc_Quality_Controls_TaskMutation>;
export type Insert_Case_Doc_Quality_Controls_TaskMutationOptions = Apollo.BaseMutationOptions<Insert_Case_Doc_Quality_Controls_TaskMutation, Insert_Case_Doc_Quality_Controls_TaskMutationVariables>;
export const Update_Case_Doc_Quality_Controls_TaskDocument = gql`
    mutation update_case_doc_quality_controls_task($id: uuid, $qualityControlTask: quality_control_tasks_set_input!) {
  update_quality_control_tasks(where: {id: {_eq: $id}}, _set: $qualityControlTask) {
    returning {
      description
      id
      order
      title
      file_id
      file {
        id
        share_key
        filename
        created
      }
    }
  }
}
    `;
export type Update_Case_Doc_Quality_Controls_TaskMutationFn = Apollo.MutationFunction<Update_Case_Doc_Quality_Controls_TaskMutation, Update_Case_Doc_Quality_Controls_TaskMutationVariables>;

/**
 * __useUpdate_Case_Doc_Quality_Controls_TaskMutation__
 *
 * To run a mutation, you first call `useUpdate_Case_Doc_Quality_Controls_TaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Case_Doc_Quality_Controls_TaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseDocQualityControlsTaskMutation, { data, loading, error }] = useUpdate_Case_Doc_Quality_Controls_TaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      qualityControlTask: // value for 'qualityControlTask'
 *   },
 * });
 */
export function useUpdate_Case_Doc_Quality_Controls_TaskMutation(baseOptions?: Apollo.MutationHookOptions<Update_Case_Doc_Quality_Controls_TaskMutation, Update_Case_Doc_Quality_Controls_TaskMutationVariables>) {
        return Apollo.useMutation<Update_Case_Doc_Quality_Controls_TaskMutation, Update_Case_Doc_Quality_Controls_TaskMutationVariables>(Update_Case_Doc_Quality_Controls_TaskDocument, baseOptions);
      }
export type Update_Case_Doc_Quality_Controls_TaskMutationHookResult = ReturnType<typeof useUpdate_Case_Doc_Quality_Controls_TaskMutation>;
export type Update_Case_Doc_Quality_Controls_TaskMutationResult = Apollo.MutationResult<Update_Case_Doc_Quality_Controls_TaskMutation>;
export type Update_Case_Doc_Quality_Controls_TaskMutationOptions = Apollo.BaseMutationOptions<Update_Case_Doc_Quality_Controls_TaskMutation, Update_Case_Doc_Quality_Controls_TaskMutationVariables>;
export const Delete_Quality_Control_TasksDocument = gql`
    mutation delete_quality_control_tasks($where: quality_control_tasks_bool_exp!) {
  delete_quality_control_tasks(where: $where) {
    affected_rows
  }
}
    `;
export type Delete_Quality_Control_TasksMutationFn = Apollo.MutationFunction<Delete_Quality_Control_TasksMutation, Delete_Quality_Control_TasksMutationVariables>;

/**
 * __useDelete_Quality_Control_TasksMutation__
 *
 * To run a mutation, you first call `useDelete_Quality_Control_TasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Quality_Control_TasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQualityControlTasksMutation, { data, loading, error }] = useDelete_Quality_Control_TasksMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDelete_Quality_Control_TasksMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Quality_Control_TasksMutation, Delete_Quality_Control_TasksMutationVariables>) {
        return Apollo.useMutation<Delete_Quality_Control_TasksMutation, Delete_Quality_Control_TasksMutationVariables>(Delete_Quality_Control_TasksDocument, baseOptions);
      }
export type Delete_Quality_Control_TasksMutationHookResult = ReturnType<typeof useDelete_Quality_Control_TasksMutation>;
export type Delete_Quality_Control_TasksMutationResult = Apollo.MutationResult<Delete_Quality_Control_TasksMutation>;
export type Delete_Quality_Control_TasksMutationOptions = Apollo.BaseMutationOptions<Delete_Quality_Control_TasksMutation, Delete_Quality_Control_TasksMutationVariables>;
export const Insert_Quality_Control_Task_ArrowsDocument = gql`
    mutation insert_quality_control_task_arrows($qualityControlTaskArrows: [quality_control_task_arrows_insert_input!]!) {
  insert_quality_control_task_arrows(objects: $qualityControlTaskArrows) {
    returning {
      id
      order
      task_id
      angle
      x
      y
    }
  }
}
    `;
export type Insert_Quality_Control_Task_ArrowsMutationFn = Apollo.MutationFunction<Insert_Quality_Control_Task_ArrowsMutation, Insert_Quality_Control_Task_ArrowsMutationVariables>;

/**
 * __useInsert_Quality_Control_Task_ArrowsMutation__
 *
 * To run a mutation, you first call `useInsert_Quality_Control_Task_ArrowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Quality_Control_Task_ArrowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertQualityControlTaskArrowsMutation, { data, loading, error }] = useInsert_Quality_Control_Task_ArrowsMutation({
 *   variables: {
 *      qualityControlTaskArrows: // value for 'qualityControlTaskArrows'
 *   },
 * });
 */
export function useInsert_Quality_Control_Task_ArrowsMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Quality_Control_Task_ArrowsMutation, Insert_Quality_Control_Task_ArrowsMutationVariables>) {
        return Apollo.useMutation<Insert_Quality_Control_Task_ArrowsMutation, Insert_Quality_Control_Task_ArrowsMutationVariables>(Insert_Quality_Control_Task_ArrowsDocument, baseOptions);
      }
export type Insert_Quality_Control_Task_ArrowsMutationHookResult = ReturnType<typeof useInsert_Quality_Control_Task_ArrowsMutation>;
export type Insert_Quality_Control_Task_ArrowsMutationResult = Apollo.MutationResult<Insert_Quality_Control_Task_ArrowsMutation>;
export type Insert_Quality_Control_Task_ArrowsMutationOptions = Apollo.BaseMutationOptions<Insert_Quality_Control_Task_ArrowsMutation, Insert_Quality_Control_Task_ArrowsMutationVariables>;
export const Update_Quality_Control_Task_ArrowsDocument = gql`
    mutation update_quality_control_task_arrows($id: uuid, $qualityControlTaskArrow: quality_control_task_arrows_set_input!) {
  update_quality_control_task_arrows(
    where: {id: {_eq: $id}}
    _set: $qualityControlTaskArrow
  ) {
    returning {
      id
      order
      task_id
      angle
      x
      y
    }
  }
}
    `;
export type Update_Quality_Control_Task_ArrowsMutationFn = Apollo.MutationFunction<Update_Quality_Control_Task_ArrowsMutation, Update_Quality_Control_Task_ArrowsMutationVariables>;

/**
 * __useUpdate_Quality_Control_Task_ArrowsMutation__
 *
 * To run a mutation, you first call `useUpdate_Quality_Control_Task_ArrowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Quality_Control_Task_ArrowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQualityControlTaskArrowsMutation, { data, loading, error }] = useUpdate_Quality_Control_Task_ArrowsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      qualityControlTaskArrow: // value for 'qualityControlTaskArrow'
 *   },
 * });
 */
export function useUpdate_Quality_Control_Task_ArrowsMutation(baseOptions?: Apollo.MutationHookOptions<Update_Quality_Control_Task_ArrowsMutation, Update_Quality_Control_Task_ArrowsMutationVariables>) {
        return Apollo.useMutation<Update_Quality_Control_Task_ArrowsMutation, Update_Quality_Control_Task_ArrowsMutationVariables>(Update_Quality_Control_Task_ArrowsDocument, baseOptions);
      }
export type Update_Quality_Control_Task_ArrowsMutationHookResult = ReturnType<typeof useUpdate_Quality_Control_Task_ArrowsMutation>;
export type Update_Quality_Control_Task_ArrowsMutationResult = Apollo.MutationResult<Update_Quality_Control_Task_ArrowsMutation>;
export type Update_Quality_Control_Task_ArrowsMutationOptions = Apollo.BaseMutationOptions<Update_Quality_Control_Task_ArrowsMutation, Update_Quality_Control_Task_ArrowsMutationVariables>;
export const Delete_Quality_Control_Task_ArrowsDocument = gql`
    mutation delete_quality_control_task_arrows($where: quality_control_task_arrows_bool_exp!) {
  delete_quality_control_task_arrows(where: $where) {
    affected_rows
  }
}
    `;
export type Delete_Quality_Control_Task_ArrowsMutationFn = Apollo.MutationFunction<Delete_Quality_Control_Task_ArrowsMutation, Delete_Quality_Control_Task_ArrowsMutationVariables>;

/**
 * __useDelete_Quality_Control_Task_ArrowsMutation__
 *
 * To run a mutation, you first call `useDelete_Quality_Control_Task_ArrowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Quality_Control_Task_ArrowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQualityControlTaskArrowsMutation, { data, loading, error }] = useDelete_Quality_Control_Task_ArrowsMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDelete_Quality_Control_Task_ArrowsMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Quality_Control_Task_ArrowsMutation, Delete_Quality_Control_Task_ArrowsMutationVariables>) {
        return Apollo.useMutation<Delete_Quality_Control_Task_ArrowsMutation, Delete_Quality_Control_Task_ArrowsMutationVariables>(Delete_Quality_Control_Task_ArrowsDocument, baseOptions);
      }
export type Delete_Quality_Control_Task_ArrowsMutationHookResult = ReturnType<typeof useDelete_Quality_Control_Task_ArrowsMutation>;
export type Delete_Quality_Control_Task_ArrowsMutationResult = Apollo.MutationResult<Delete_Quality_Control_Task_ArrowsMutation>;
export type Delete_Quality_Control_Task_ArrowsMutationOptions = Apollo.BaseMutationOptions<Delete_Quality_Control_Task_ArrowsMutation, Delete_Quality_Control_Task_ArrowsMutationVariables>;
export const List_RemindersDocument = gql`
    query list_reminders($orderBy: [reminders_order_by!], $limit: Int, $where: reminders_bool_exp) {
  reminders(order_by: $orderBy, where: $where, limit: $limit) {
    id
    date
    message
    contacted
    creator {
      id
      acronym
    }
    recipients {
      user {
        id
        acronym
        mail
      }
    }
    request_id
    request {
      id
      builder
      address
      zip_code
      city
      mail
      mobile
    }
    case_id
    case {
      id
      builder {
        id
        first_name
        last_name
        mail
        mobile
        address
        zip_code
        city
      }
      company_id
      company {
        id
        first_name
        last_name
        company
        address
        zip_code
        city
        phone
        mobile
        mail
      }
      other_address
      address
      zip_code
      city
      suppliers(where: {contact: {type: {_eq: "manager"}}}, limit: 1) {
        contact {
          company
          first_name
          last_name
          mobile
          mail
        }
      }
    }
  }
}
    `;

/**
 * __useList_RemindersQuery__
 *
 * To run a query within a React component, call `useList_RemindersQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_RemindersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_RemindersQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useList_RemindersQuery(baseOptions?: Apollo.QueryHookOptions<List_RemindersQuery, List_RemindersQueryVariables>) {
        return Apollo.useQuery<List_RemindersQuery, List_RemindersQueryVariables>(List_RemindersDocument, baseOptions);
      }
export function useList_RemindersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_RemindersQuery, List_RemindersQueryVariables>) {
          return Apollo.useLazyQuery<List_RemindersQuery, List_RemindersQueryVariables>(List_RemindersDocument, baseOptions);
        }
export type List_RemindersQueryHookResult = ReturnType<typeof useList_RemindersQuery>;
export type List_RemindersLazyQueryHookResult = ReturnType<typeof useList_RemindersLazyQuery>;
export type List_RemindersQueryResult = Apollo.QueryResult<List_RemindersQuery, List_RemindersQueryVariables>;
export const Insert_ReminderDocument = gql`
    mutation insert_reminder($reminder: reminders_insert_input!) {
  insert_reminders(objects: [$reminder]) {
    returning {
      id
      date
      message
      contacted
      creator {
        id
        acronym
      }
      recipients {
        user {
          id
          first_name
          last_name
          mail
          mobile
          phone
          acronym
        }
      }
    }
  }
}
    `;
export type Insert_ReminderMutationFn = Apollo.MutationFunction<Insert_ReminderMutation, Insert_ReminderMutationVariables>;

/**
 * __useInsert_ReminderMutation__
 *
 * To run a mutation, you first call `useInsert_ReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_ReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertReminderMutation, { data, loading, error }] = useInsert_ReminderMutation({
 *   variables: {
 *      reminder: // value for 'reminder'
 *   },
 * });
 */
export function useInsert_ReminderMutation(baseOptions?: Apollo.MutationHookOptions<Insert_ReminderMutation, Insert_ReminderMutationVariables>) {
        return Apollo.useMutation<Insert_ReminderMutation, Insert_ReminderMutationVariables>(Insert_ReminderDocument, baseOptions);
      }
export type Insert_ReminderMutationHookResult = ReturnType<typeof useInsert_ReminderMutation>;
export type Insert_ReminderMutationResult = Apollo.MutationResult<Insert_ReminderMutation>;
export type Insert_ReminderMutationOptions = Apollo.BaseMutationOptions<Insert_ReminderMutation, Insert_ReminderMutationVariables>;
export const Get_RemindersDocument = gql`
    query get_reminders($reminderId: uuid) {
  reminders(where: {id: {_eq: $reminderId}}) {
    id
    date
    message
    contacted
    creator {
      id
      acronym
    }
    recipients {
      user {
        id
        first_name
        last_name
        mail
        mobile
        phone
        acronym
      }
    }
  }
}
    `;

/**
 * __useGet_RemindersQuery__
 *
 * To run a query within a React component, call `useGet_RemindersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_RemindersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_RemindersQuery({
 *   variables: {
 *      reminderId: // value for 'reminderId'
 *   },
 * });
 */
export function useGet_RemindersQuery(baseOptions?: Apollo.QueryHookOptions<Get_RemindersQuery, Get_RemindersQueryVariables>) {
        return Apollo.useQuery<Get_RemindersQuery, Get_RemindersQueryVariables>(Get_RemindersDocument, baseOptions);
      }
export function useGet_RemindersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_RemindersQuery, Get_RemindersQueryVariables>) {
          return Apollo.useLazyQuery<Get_RemindersQuery, Get_RemindersQueryVariables>(Get_RemindersDocument, baseOptions);
        }
export type Get_RemindersQueryHookResult = ReturnType<typeof useGet_RemindersQuery>;
export type Get_RemindersLazyQueryHookResult = ReturnType<typeof useGet_RemindersLazyQuery>;
export type Get_RemindersQueryResult = Apollo.QueryResult<Get_RemindersQuery, Get_RemindersQueryVariables>;
export const UpdateReminderDocument = gql`
    mutation updateReminder($id: uuid, $reminder: reminders_set_input!) {
  update_reminders(where: {id: {_eq: $id}}, _set: $reminder) {
    returning {
      id
      date
      message
      contacted
      creator {
        id
        acronym
      }
      recipients {
        user {
          id
          first_name
          last_name
          mail
          mobile
          phone
          acronym
        }
      }
    }
  }
}
    `;
export type UpdateReminderMutationFn = Apollo.MutationFunction<UpdateReminderMutation, UpdateReminderMutationVariables>;

/**
 * __useUpdateReminderMutation__
 *
 * To run a mutation, you first call `useUpdateReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReminderMutation, { data, loading, error }] = useUpdateReminderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reminder: // value for 'reminder'
 *   },
 * });
 */
export function useUpdateReminderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReminderMutation, UpdateReminderMutationVariables>) {
        return Apollo.useMutation<UpdateReminderMutation, UpdateReminderMutationVariables>(UpdateReminderDocument, baseOptions);
      }
export type UpdateReminderMutationHookResult = ReturnType<typeof useUpdateReminderMutation>;
export type UpdateReminderMutationResult = Apollo.MutationResult<UpdateReminderMutation>;
export type UpdateReminderMutationOptions = Apollo.BaseMutationOptions<UpdateReminderMutation, UpdateReminderMutationVariables>;
export const Insert_Reminder_RecipientDocument = gql`
    mutation insert_reminder_recipient($reminder_recipients: [reminder_recipients_insert_input!]!) {
  insert_reminder_recipients(objects: $reminder_recipients) {
    returning {
      id
      user_id
      reminder_id
    }
  }
}
    `;
export type Insert_Reminder_RecipientMutationFn = Apollo.MutationFunction<Insert_Reminder_RecipientMutation, Insert_Reminder_RecipientMutationVariables>;

/**
 * __useInsert_Reminder_RecipientMutation__
 *
 * To run a mutation, you first call `useInsert_Reminder_RecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Reminder_RecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertReminderRecipientMutation, { data, loading, error }] = useInsert_Reminder_RecipientMutation({
 *   variables: {
 *      reminder_recipients: // value for 'reminder_recipients'
 *   },
 * });
 */
export function useInsert_Reminder_RecipientMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Reminder_RecipientMutation, Insert_Reminder_RecipientMutationVariables>) {
        return Apollo.useMutation<Insert_Reminder_RecipientMutation, Insert_Reminder_RecipientMutationVariables>(Insert_Reminder_RecipientDocument, baseOptions);
      }
export type Insert_Reminder_RecipientMutationHookResult = ReturnType<typeof useInsert_Reminder_RecipientMutation>;
export type Insert_Reminder_RecipientMutationResult = Apollo.MutationResult<Insert_Reminder_RecipientMutation>;
export type Insert_Reminder_RecipientMutationOptions = Apollo.BaseMutationOptions<Insert_Reminder_RecipientMutation, Insert_Reminder_RecipientMutationVariables>;
export const Delete_Reminder_RecipientsDocument = gql`
    mutation delete_reminder_recipients($where: reminder_recipients_bool_exp!) {
  delete_reminder_recipients(where: $where) {
    affected_rows
  }
}
    `;
export type Delete_Reminder_RecipientsMutationFn = Apollo.MutationFunction<Delete_Reminder_RecipientsMutation, Delete_Reminder_RecipientsMutationVariables>;

/**
 * __useDelete_Reminder_RecipientsMutation__
 *
 * To run a mutation, you first call `useDelete_Reminder_RecipientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Reminder_RecipientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReminderRecipientsMutation, { data, loading, error }] = useDelete_Reminder_RecipientsMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDelete_Reminder_RecipientsMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Reminder_RecipientsMutation, Delete_Reminder_RecipientsMutationVariables>) {
        return Apollo.useMutation<Delete_Reminder_RecipientsMutation, Delete_Reminder_RecipientsMutationVariables>(Delete_Reminder_RecipientsDocument, baseOptions);
      }
export type Delete_Reminder_RecipientsMutationHookResult = ReturnType<typeof useDelete_Reminder_RecipientsMutation>;
export type Delete_Reminder_RecipientsMutationResult = Apollo.MutationResult<Delete_Reminder_RecipientsMutation>;
export type Delete_Reminder_RecipientsMutationOptions = Apollo.BaseMutationOptions<Delete_Reminder_RecipientsMutation, Delete_Reminder_RecipientsMutationVariables>;
export const UpdateRemindersContactedDocument = gql`
    mutation updateRemindersContacted($id: uuid, $contacted: Boolean!) {
  update_reminders(where: {id: {_eq: $id}}, _set: {contacted: $contacted}) {
    returning {
      id
      date
      message
      contacted
      creator {
        id
        acronym
      }
      recipients {
        user {
          id
          first_name
          last_name
          mail
          mobile
          phone
          acronym
        }
      }
    }
  }
}
    `;
export type UpdateRemindersContactedMutationFn = Apollo.MutationFunction<UpdateRemindersContactedMutation, UpdateRemindersContactedMutationVariables>;

/**
 * __useUpdateRemindersContactedMutation__
 *
 * To run a mutation, you first call `useUpdateRemindersContactedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRemindersContactedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRemindersContactedMutation, { data, loading, error }] = useUpdateRemindersContactedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      contacted: // value for 'contacted'
 *   },
 * });
 */
export function useUpdateRemindersContactedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRemindersContactedMutation, UpdateRemindersContactedMutationVariables>) {
        return Apollo.useMutation<UpdateRemindersContactedMutation, UpdateRemindersContactedMutationVariables>(UpdateRemindersContactedDocument, baseOptions);
      }
export type UpdateRemindersContactedMutationHookResult = ReturnType<typeof useUpdateRemindersContactedMutation>;
export type UpdateRemindersContactedMutationResult = Apollo.MutationResult<UpdateRemindersContactedMutation>;
export type UpdateRemindersContactedMutationOptions = Apollo.BaseMutationOptions<UpdateRemindersContactedMutation, UpdateRemindersContactedMutationVariables>;
export const List_RequestsDocument = gql`
    query list_requests($orderBy: requests_order_by!, $where: requests_bool_exp, $offset: Int, $limit: Int) {
  requests_aggregate(
    offset: $offset
    order_by: [$orderBy]
    where: $where
    limit: $limit
  ) {
    aggregate {
      count
    }
    nodes {
      id
      builder
      task
      address
      date
      state
      zip_code
      city
      mail
      mobile
      description
      case_type {
        id
        name
      }
      inspection_date
      inspection_time
      responsible {
        id
        first_name
        last_name
        mail
        mobile
        phone
        acronym
      }
      external {
        id
        first_name
        last_name
        company
        address
        zip_code
        city
        phone
        mobile
        mail
      }
    }
  }
}
    `;

/**
 * __useList_RequestsQuery__
 *
 * To run a query within a React component, call `useList_RequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_RequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_RequestsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useList_RequestsQuery(baseOptions: Apollo.QueryHookOptions<List_RequestsQuery, List_RequestsQueryVariables>) {
        return Apollo.useQuery<List_RequestsQuery, List_RequestsQueryVariables>(List_RequestsDocument, baseOptions);
      }
export function useList_RequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_RequestsQuery, List_RequestsQueryVariables>) {
          return Apollo.useLazyQuery<List_RequestsQuery, List_RequestsQueryVariables>(List_RequestsDocument, baseOptions);
        }
export type List_RequestsQueryHookResult = ReturnType<typeof useList_RequestsQuery>;
export type List_RequestsLazyQueryHookResult = ReturnType<typeof useList_RequestsLazyQuery>;
export type List_RequestsQueryResult = Apollo.QueryResult<List_RequestsQuery, List_RequestsQueryVariables>;
export const Get_RequestsDocument = gql`
    query get_requests($requestId: uuid) {
  requests(where: {id: {_eq: $requestId}}) {
    id
    builder
    task
    address
    date
    state
    zip_code
    city
    mail
    mobile
    description
    case_type {
      id
      name
    }
    inspection_date
    inspection_time
    responsible {
      id
      first_name
      last_name
      mail
      mobile
      phone
      acronym
    }
    external {
      id
      first_name
      last_name
      company
      address
      zip_code
      city
      phone
      mobile
      mail
    }
    request_notes(order_by: {added_date: desc}) {
      id
      user {
        id
        acronym
      }
      notes
      added_date
    }
    reminders(order_by: {date: asc}) {
      id
      date
      message
      creator {
        id
        acronym
      }
      recipients {
        user {
          id
          acronym
        }
      }
    }
  }
}
    `;

/**
 * __useGet_RequestsQuery__
 *
 * To run a query within a React component, call `useGet_RequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_RequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_RequestsQuery({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useGet_RequestsQuery(baseOptions?: Apollo.QueryHookOptions<Get_RequestsQuery, Get_RequestsQueryVariables>) {
        return Apollo.useQuery<Get_RequestsQuery, Get_RequestsQueryVariables>(Get_RequestsDocument, baseOptions);
      }
export function useGet_RequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_RequestsQuery, Get_RequestsQueryVariables>) {
          return Apollo.useLazyQuery<Get_RequestsQuery, Get_RequestsQueryVariables>(Get_RequestsDocument, baseOptions);
        }
export type Get_RequestsQueryHookResult = ReturnType<typeof useGet_RequestsQuery>;
export type Get_RequestsLazyQueryHookResult = ReturnType<typeof useGet_RequestsLazyQuery>;
export type Get_RequestsQueryResult = Apollo.QueryResult<Get_RequestsQuery, Get_RequestsQueryVariables>;
export const Update_RequestDocument = gql`
    mutation update_request($id: uuid, $request: requests_set_input!) {
  update_requests(where: {id: {_eq: $id}}, _set: $request) {
    returning {
      id
      builder
      task
      address
      date
      state
      zip_code
      city
      mail
      mobile
      description
      case_type {
        id
        name
      }
      inspection_date
      inspection_time
      responsible {
        id
        first_name
        last_name
        mail
        mobile
        phone
        acronym
      }
      external {
        id
        first_name
        last_name
        company
        address
        zip_code
        city
        phone
        mobile
        mail
      }
      request_notes(order_by: {added_date: desc}) {
        id
        user {
          id
          acronym
        }
        notes
        added_date
      }
      reminders(order_by: {date: asc}) {
        id
        date
        message
        creator {
          id
          acronym
        }
        recipients {
          user {
            id
            acronym
          }
        }
      }
    }
  }
}
    `;
export type Update_RequestMutationFn = Apollo.MutationFunction<Update_RequestMutation, Update_RequestMutationVariables>;

/**
 * __useUpdate_RequestMutation__
 *
 * To run a mutation, you first call `useUpdate_RequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_RequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRequestMutation, { data, loading, error }] = useUpdate_RequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useUpdate_RequestMutation(baseOptions?: Apollo.MutationHookOptions<Update_RequestMutation, Update_RequestMutationVariables>) {
        return Apollo.useMutation<Update_RequestMutation, Update_RequestMutationVariables>(Update_RequestDocument, baseOptions);
      }
export type Update_RequestMutationHookResult = ReturnType<typeof useUpdate_RequestMutation>;
export type Update_RequestMutationResult = Apollo.MutationResult<Update_RequestMutation>;
export type Update_RequestMutationOptions = Apollo.BaseMutationOptions<Update_RequestMutation, Update_RequestMutationVariables>;
export const Insert_Request_NotesDocument = gql`
    mutation insert_request_notes($request_notes: [request_notes_insert_input!]!) {
  insert_request_notes(objects: $request_notes) {
    returning {
      id
      user {
        id
        acronym
      }
      notes
      added_date
    }
  }
}
    `;
export type Insert_Request_NotesMutationFn = Apollo.MutationFunction<Insert_Request_NotesMutation, Insert_Request_NotesMutationVariables>;

/**
 * __useInsert_Request_NotesMutation__
 *
 * To run a mutation, you first call `useInsert_Request_NotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Request_NotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertRequestNotesMutation, { data, loading, error }] = useInsert_Request_NotesMutation({
 *   variables: {
 *      request_notes: // value for 'request_notes'
 *   },
 * });
 */
export function useInsert_Request_NotesMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Request_NotesMutation, Insert_Request_NotesMutationVariables>) {
        return Apollo.useMutation<Insert_Request_NotesMutation, Insert_Request_NotesMutationVariables>(Insert_Request_NotesDocument, baseOptions);
      }
export type Insert_Request_NotesMutationHookResult = ReturnType<typeof useInsert_Request_NotesMutation>;
export type Insert_Request_NotesMutationResult = Apollo.MutationResult<Insert_Request_NotesMutation>;
export type Insert_Request_NotesMutationOptions = Apollo.BaseMutationOptions<Insert_Request_NotesMutation, Insert_Request_NotesMutationVariables>;
export const List_Request_RemindersDocument = gql`
    query list_request_reminders($orderBy: reminders_order_by!, $offset: Int, $limit: Int, $where: reminders_bool_exp) {
  reminders_aggregate(
    offset: $offset
    order_by: [$orderBy]
    where: $where
    limit: $limit
  ) {
    aggregate {
      count
    }
    nodes {
      id
      date
      message
      creator {
        id
        acronym
      }
      recipients {
        user {
          id
          acronym
        }
      }
    }
  }
}
    `;

/**
 * __useList_Request_RemindersQuery__
 *
 * To run a query within a React component, call `useList_Request_RemindersQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_Request_RemindersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_Request_RemindersQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useList_Request_RemindersQuery(baseOptions: Apollo.QueryHookOptions<List_Request_RemindersQuery, List_Request_RemindersQueryVariables>) {
        return Apollo.useQuery<List_Request_RemindersQuery, List_Request_RemindersQueryVariables>(List_Request_RemindersDocument, baseOptions);
      }
export function useList_Request_RemindersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_Request_RemindersQuery, List_Request_RemindersQueryVariables>) {
          return Apollo.useLazyQuery<List_Request_RemindersQuery, List_Request_RemindersQueryVariables>(List_Request_RemindersDocument, baseOptions);
        }
export type List_Request_RemindersQueryHookResult = ReturnType<typeof useList_Request_RemindersQuery>;
export type List_Request_RemindersLazyQueryHookResult = ReturnType<typeof useList_Request_RemindersLazyQuery>;
export type List_Request_RemindersQueryResult = Apollo.QueryResult<List_Request_RemindersQuery, List_Request_RemindersQueryVariables>;
export const CreateReminderDocument = gql`
    mutation createReminder($reminder: reminders_insert_input!) {
  insert_reminders(objects: [$reminder]) {
    returning {
      id
      date
      message
      creator {
        id
        acronym
      }
      recipients {
        user {
          id
          first_name
          last_name
          mail
          mobile
          phone
          acronym
        }
      }
    }
  }
}
    `;
export type CreateReminderMutationFn = Apollo.MutationFunction<CreateReminderMutation, CreateReminderMutationVariables>;

/**
 * __useCreateReminderMutation__
 *
 * To run a mutation, you first call `useCreateReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReminderMutation, { data, loading, error }] = useCreateReminderMutation({
 *   variables: {
 *      reminder: // value for 'reminder'
 *   },
 * });
 */
export function useCreateReminderMutation(baseOptions?: Apollo.MutationHookOptions<CreateReminderMutation, CreateReminderMutationVariables>) {
        return Apollo.useMutation<CreateReminderMutation, CreateReminderMutationVariables>(CreateReminderDocument, baseOptions);
      }
export type CreateReminderMutationHookResult = ReturnType<typeof useCreateReminderMutation>;
export type CreateReminderMutationResult = Apollo.MutationResult<CreateReminderMutation>;
export type CreateReminderMutationOptions = Apollo.BaseMutationOptions<CreateReminderMutation, CreateReminderMutationVariables>;
export const Get_Request_RemindersDocument = gql`
    query get_request_reminders($reminderId: uuid) {
  reminders(where: {id: {_eq: $reminderId}}) {
    id
    date
    message
    creator {
      id
      acronym
    }
    recipients {
      user {
        id
        first_name
        last_name
        mail
        mobile
        phone
        acronym
      }
    }
  }
}
    `;

/**
 * __useGet_Request_RemindersQuery__
 *
 * To run a query within a React component, call `useGet_Request_RemindersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Request_RemindersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Request_RemindersQuery({
 *   variables: {
 *      reminderId: // value for 'reminderId'
 *   },
 * });
 */
export function useGet_Request_RemindersQuery(baseOptions?: Apollo.QueryHookOptions<Get_Request_RemindersQuery, Get_Request_RemindersQueryVariables>) {
        return Apollo.useQuery<Get_Request_RemindersQuery, Get_Request_RemindersQueryVariables>(Get_Request_RemindersDocument, baseOptions);
      }
export function useGet_Request_RemindersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Request_RemindersQuery, Get_Request_RemindersQueryVariables>) {
          return Apollo.useLazyQuery<Get_Request_RemindersQuery, Get_Request_RemindersQueryVariables>(Get_Request_RemindersDocument, baseOptions);
        }
export type Get_Request_RemindersQueryHookResult = ReturnType<typeof useGet_Request_RemindersQuery>;
export type Get_Request_RemindersLazyQueryHookResult = ReturnType<typeof useGet_Request_RemindersLazyQuery>;
export type Get_Request_RemindersQueryResult = Apollo.QueryResult<Get_Request_RemindersQuery, Get_Request_RemindersQueryVariables>;
export const Update_Request_RemindersDocument = gql`
    mutation update_request_reminders($id: uuid, $reminder: reminders_set_input!) {
  update_reminders(where: {id: {_eq: $id}}, _set: $reminder) {
    returning {
      id
      date
      message
      creator {
        id
        acronym
      }
      recipients {
        user {
          id
          first_name
          last_name
          mail
          mobile
          phone
          acronym
        }
      }
    }
  }
}
    `;
export type Update_Request_RemindersMutationFn = Apollo.MutationFunction<Update_Request_RemindersMutation, Update_Request_RemindersMutationVariables>;

/**
 * __useUpdate_Request_RemindersMutation__
 *
 * To run a mutation, you first call `useUpdate_Request_RemindersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Request_RemindersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRequestRemindersMutation, { data, loading, error }] = useUpdate_Request_RemindersMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reminder: // value for 'reminder'
 *   },
 * });
 */
export function useUpdate_Request_RemindersMutation(baseOptions?: Apollo.MutationHookOptions<Update_Request_RemindersMutation, Update_Request_RemindersMutationVariables>) {
        return Apollo.useMutation<Update_Request_RemindersMutation, Update_Request_RemindersMutationVariables>(Update_Request_RemindersDocument, baseOptions);
      }
export type Update_Request_RemindersMutationHookResult = ReturnType<typeof useUpdate_Request_RemindersMutation>;
export type Update_Request_RemindersMutationResult = Apollo.MutationResult<Update_Request_RemindersMutation>;
export type Update_Request_RemindersMutationOptions = Apollo.BaseMutationOptions<Update_Request_RemindersMutation, Update_Request_RemindersMutationVariables>;
export const CreateReminderRecipientDocument = gql`
    mutation createReminderRecipient($reminder_recipients: [reminder_recipients_insert_input!]!) {
  insert_reminder_recipients(objects: $reminder_recipients) {
    returning {
      id
      user_id
      reminder_id
    }
  }
}
    `;
export type CreateReminderRecipientMutationFn = Apollo.MutationFunction<CreateReminderRecipientMutation, CreateReminderRecipientMutationVariables>;

/**
 * __useCreateReminderRecipientMutation__
 *
 * To run a mutation, you first call `useCreateReminderRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReminderRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReminderRecipientMutation, { data, loading, error }] = useCreateReminderRecipientMutation({
 *   variables: {
 *      reminder_recipients: // value for 'reminder_recipients'
 *   },
 * });
 */
export function useCreateReminderRecipientMutation(baseOptions?: Apollo.MutationHookOptions<CreateReminderRecipientMutation, CreateReminderRecipientMutationVariables>) {
        return Apollo.useMutation<CreateReminderRecipientMutation, CreateReminderRecipientMutationVariables>(CreateReminderRecipientDocument, baseOptions);
      }
export type CreateReminderRecipientMutationHookResult = ReturnType<typeof useCreateReminderRecipientMutation>;
export type CreateReminderRecipientMutationResult = Apollo.MutationResult<CreateReminderRecipientMutation>;
export type CreateReminderRecipientMutationOptions = Apollo.BaseMutationOptions<CreateReminderRecipientMutation, CreateReminderRecipientMutationVariables>;
export const List_Standard_PhotosDocument = gql`
    query list_standard_photos($orderBy: standard_photos_order_by!, $where: standard_photos_bool_exp, $limit: Int) {
  standard_photos(order_by: [$orderBy], where: $where, limit: $limit) {
    id
    file_id
    category
    title
    file {
      id
      share_key
      filename
      created
    }
  }
}
    `;

/**
 * __useList_Standard_PhotosQuery__
 *
 * To run a query within a React component, call `useList_Standard_PhotosQuery` and pass it any options that fit your needs.
 * When your component renders, `useList_Standard_PhotosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useList_Standard_PhotosQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useList_Standard_PhotosQuery(baseOptions: Apollo.QueryHookOptions<List_Standard_PhotosQuery, List_Standard_PhotosQueryVariables>) {
        return Apollo.useQuery<List_Standard_PhotosQuery, List_Standard_PhotosQueryVariables>(List_Standard_PhotosDocument, baseOptions);
      }
export function useList_Standard_PhotosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<List_Standard_PhotosQuery, List_Standard_PhotosQueryVariables>) {
          return Apollo.useLazyQuery<List_Standard_PhotosQuery, List_Standard_PhotosQueryVariables>(List_Standard_PhotosDocument, baseOptions);
        }
export type List_Standard_PhotosQueryHookResult = ReturnType<typeof useList_Standard_PhotosQuery>;
export type List_Standard_PhotosLazyQueryHookResult = ReturnType<typeof useList_Standard_PhotosLazyQuery>;
export type List_Standard_PhotosQueryResult = Apollo.QueryResult<List_Standard_PhotosQuery, List_Standard_PhotosQueryVariables>;
export const Insert_Standard_PhotoDocument = gql`
    mutation insert_standard_photo($standard_photos: [standard_photos_insert_input!]!) {
  insert_standard_photos(objects: $standard_photos) {
    returning {
      id
      file_id
      category
      title
      file {
        id
        share_key
        filename
        created
      }
    }
  }
}
    `;
export type Insert_Standard_PhotoMutationFn = Apollo.MutationFunction<Insert_Standard_PhotoMutation, Insert_Standard_PhotoMutationVariables>;

/**
 * __useInsert_Standard_PhotoMutation__
 *
 * To run a mutation, you first call `useInsert_Standard_PhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Standard_PhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertStandardPhotoMutation, { data, loading, error }] = useInsert_Standard_PhotoMutation({
 *   variables: {
 *      standard_photos: // value for 'standard_photos'
 *   },
 * });
 */
export function useInsert_Standard_PhotoMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Standard_PhotoMutation, Insert_Standard_PhotoMutationVariables>) {
        return Apollo.useMutation<Insert_Standard_PhotoMutation, Insert_Standard_PhotoMutationVariables>(Insert_Standard_PhotoDocument, baseOptions);
      }
export type Insert_Standard_PhotoMutationHookResult = ReturnType<typeof useInsert_Standard_PhotoMutation>;
export type Insert_Standard_PhotoMutationResult = Apollo.MutationResult<Insert_Standard_PhotoMutation>;
export type Insert_Standard_PhotoMutationOptions = Apollo.BaseMutationOptions<Insert_Standard_PhotoMutation, Insert_Standard_PhotoMutationVariables>;
export const Get_StandardDocument = gql`
    query get_standard($photoId: uuid) {
  standard_photos(where: {id: {_eq: $photoId}}) {
    id
    file_id
    category
    title
    file {
      id
      filename
      share_key
      created
    }
  }
}
    `;

/**
 * __useGet_StandardQuery__
 *
 * To run a query within a React component, call `useGet_StandardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_StandardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_StandardQuery({
 *   variables: {
 *      photoId: // value for 'photoId'
 *   },
 * });
 */
export function useGet_StandardQuery(baseOptions?: Apollo.QueryHookOptions<Get_StandardQuery, Get_StandardQueryVariables>) {
        return Apollo.useQuery<Get_StandardQuery, Get_StandardQueryVariables>(Get_StandardDocument, baseOptions);
      }
export function useGet_StandardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_StandardQuery, Get_StandardQueryVariables>) {
          return Apollo.useLazyQuery<Get_StandardQuery, Get_StandardQueryVariables>(Get_StandardDocument, baseOptions);
        }
export type Get_StandardQueryHookResult = ReturnType<typeof useGet_StandardQuery>;
export type Get_StandardLazyQueryHookResult = ReturnType<typeof useGet_StandardLazyQuery>;
export type Get_StandardQueryResult = Apollo.QueryResult<Get_StandardQuery, Get_StandardQueryVariables>;
export const Update_Standard_PhotoDocument = gql`
    mutation update_standard_photo($id: uuid, $standard_photo: standard_photos_set_input!) {
  update_standard_photos(where: {id: {_eq: $id}}, _set: $standard_photo) {
    returning {
      id
      file_id
      category
      title
      file {
        id
        share_key
        filename
        created
      }
    }
  }
}
    `;
export type Update_Standard_PhotoMutationFn = Apollo.MutationFunction<Update_Standard_PhotoMutation, Update_Standard_PhotoMutationVariables>;

/**
 * __useUpdate_Standard_PhotoMutation__
 *
 * To run a mutation, you first call `useUpdate_Standard_PhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Standard_PhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStandardPhotoMutation, { data, loading, error }] = useUpdate_Standard_PhotoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      standard_photo: // value for 'standard_photo'
 *   },
 * });
 */
export function useUpdate_Standard_PhotoMutation(baseOptions?: Apollo.MutationHookOptions<Update_Standard_PhotoMutation, Update_Standard_PhotoMutationVariables>) {
        return Apollo.useMutation<Update_Standard_PhotoMutation, Update_Standard_PhotoMutationVariables>(Update_Standard_PhotoDocument, baseOptions);
      }
export type Update_Standard_PhotoMutationHookResult = ReturnType<typeof useUpdate_Standard_PhotoMutation>;
export type Update_Standard_PhotoMutationResult = Apollo.MutationResult<Update_Standard_PhotoMutation>;
export type Update_Standard_PhotoMutationOptions = Apollo.BaseMutationOptions<Update_Standard_PhotoMutation, Update_Standard_PhotoMutationVariables>;
export const Delete_Standard_PhotosDocument = gql`
    mutation delete_standard_photos($photoId: uuid) {
  delete_standard_photos(where: {id: {_eq: $photoId}}) {
    affected_rows
  }
}
    `;
export type Delete_Standard_PhotosMutationFn = Apollo.MutationFunction<Delete_Standard_PhotosMutation, Delete_Standard_PhotosMutationVariables>;

/**
 * __useDelete_Standard_PhotosMutation__
 *
 * To run a mutation, you first call `useDelete_Standard_PhotosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Standard_PhotosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStandardPhotosMutation, { data, loading, error }] = useDelete_Standard_PhotosMutation({
 *   variables: {
 *      photoId: // value for 'photoId'
 *   },
 * });
 */
export function useDelete_Standard_PhotosMutation(baseOptions?: Apollo.MutationHookOptions<Delete_Standard_PhotosMutation, Delete_Standard_PhotosMutationVariables>) {
        return Apollo.useMutation<Delete_Standard_PhotosMutation, Delete_Standard_PhotosMutationVariables>(Delete_Standard_PhotosDocument, baseOptions);
      }
export type Delete_Standard_PhotosMutationHookResult = ReturnType<typeof useDelete_Standard_PhotosMutation>;
export type Delete_Standard_PhotosMutationResult = Apollo.MutationResult<Delete_Standard_PhotosMutation>;
export type Delete_Standard_PhotosMutationOptions = Apollo.BaseMutationOptions<Delete_Standard_PhotosMutation, Delete_Standard_PhotosMutationVariables>;
export const ListUsersDocument = gql`
    query listUsers($orderBy: users_order_by!, $where: users_bool_exp, $offset: Int, $limit: Int) {
  users_aggregate(
    offset: $offset
    order_by: [$orderBy]
    where: $where
    limit: $limit
  ) {
    aggregate {
      count
    }
    nodes {
      id
      mail
      user_type
      phone
      mobile
      first_name
      last_name
      acronym
      active
      employee_number
    }
  }
}
    `;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListUsersQuery(baseOptions: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
        return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, baseOptions);
      }
export function useListUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
          return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, baseOptions);
        }
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<ListUsersQuery, ListUsersQueryVariables>;
export const Get_UsersDocument = gql`
    query get_users($userId: uuid) {
  users(where: {id: {_eq: $userId}}) {
    id
    mail
    user_type
    phone
    mobile
    first_name
    last_name
    acronym
    active
    substitute_id
    substitute {
      id
      mail
      first_name
      last_name
      acronym
    }
    employee_number
  }
}
    `;

/**
 * __useGet_UsersQuery__
 *
 * To run a query within a React component, call `useGet_UsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_UsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_UsersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGet_UsersQuery(baseOptions?: Apollo.QueryHookOptions<Get_UsersQuery, Get_UsersQueryVariables>) {
        return Apollo.useQuery<Get_UsersQuery, Get_UsersQueryVariables>(Get_UsersDocument, baseOptions);
      }
export function useGet_UsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_UsersQuery, Get_UsersQueryVariables>) {
          return Apollo.useLazyQuery<Get_UsersQuery, Get_UsersQueryVariables>(Get_UsersDocument, baseOptions);
        }
export type Get_UsersQueryHookResult = ReturnType<typeof useGet_UsersQuery>;
export type Get_UsersLazyQueryHookResult = ReturnType<typeof useGet_UsersLazyQuery>;
export type Get_UsersQueryResult = Apollo.QueryResult<Get_UsersQuery, Get_UsersQueryVariables>;
export const Get_Users_With_MailDocument = gql`
    query get_users_with_mail($mail: String!) {
  users(where: {mail: {_eq: $mail}}) {
    id
  }
}
    `;

/**
 * __useGet_Users_With_MailQuery__
 *
 * To run a query within a React component, call `useGet_Users_With_MailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Users_With_MailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Users_With_MailQuery({
 *   variables: {
 *      mail: // value for 'mail'
 *   },
 * });
 */
export function useGet_Users_With_MailQuery(baseOptions: Apollo.QueryHookOptions<Get_Users_With_MailQuery, Get_Users_With_MailQueryVariables>) {
        return Apollo.useQuery<Get_Users_With_MailQuery, Get_Users_With_MailQueryVariables>(Get_Users_With_MailDocument, baseOptions);
      }
export function useGet_Users_With_MailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Users_With_MailQuery, Get_Users_With_MailQueryVariables>) {
          return Apollo.useLazyQuery<Get_Users_With_MailQuery, Get_Users_With_MailQueryVariables>(Get_Users_With_MailDocument, baseOptions);
        }
export type Get_Users_With_MailQueryHookResult = ReturnType<typeof useGet_Users_With_MailQuery>;
export type Get_Users_With_MailLazyQueryHookResult = ReturnType<typeof useGet_Users_With_MailLazyQuery>;
export type Get_Users_With_MailQueryResult = Apollo.QueryResult<Get_Users_With_MailQuery, Get_Users_With_MailQueryVariables>;
export const Insert_UserDocument = gql`
    mutation insert_user($user: users_insert_input!) {
  insert_users(objects: [$user]) {
    returning {
      id
      mail
      user_type
      phone
      mobile
      first_name
      last_name
      acronym
      active
      substitute_id
      substitute {
        id
        mail
        first_name
        last_name
        acronym
      }
      employee_number
    }
  }
}
    `;
export type Insert_UserMutationFn = Apollo.MutationFunction<Insert_UserMutation, Insert_UserMutationVariables>;

/**
 * __useInsert_UserMutation__
 *
 * To run a mutation, you first call `useInsert_UserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_UserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserMutation, { data, loading, error }] = useInsert_UserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useInsert_UserMutation(baseOptions?: Apollo.MutationHookOptions<Insert_UserMutation, Insert_UserMutationVariables>) {
        return Apollo.useMutation<Insert_UserMutation, Insert_UserMutationVariables>(Insert_UserDocument, baseOptions);
      }
export type Insert_UserMutationHookResult = ReturnType<typeof useInsert_UserMutation>;
export type Insert_UserMutationResult = Apollo.MutationResult<Insert_UserMutation>;
export type Insert_UserMutationOptions = Apollo.BaseMutationOptions<Insert_UserMutation, Insert_UserMutationVariables>;
export const Update_UserDocument = gql`
    mutation update_user($id: uuid, $user: users_set_input!) {
  update_users(where: {id: {_eq: $id}}, _set: $user) {
    returning {
      id
      mail
      user_type
      phone
      mobile
      first_name
      last_name
      acronym
      active
      substitute_id
      substitute {
        id
        mail
        first_name
        last_name
        acronym
      }
      employee_number
    }
  }
}
    `;
export type Update_UserMutationFn = Apollo.MutationFunction<Update_UserMutation, Update_UserMutationVariables>;

/**
 * __useUpdate_UserMutation__
 *
 * To run a mutation, you first call `useUpdate_UserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_UserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdate_UserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdate_UserMutation(baseOptions?: Apollo.MutationHookOptions<Update_UserMutation, Update_UserMutationVariables>) {
        return Apollo.useMutation<Update_UserMutation, Update_UserMutationVariables>(Update_UserDocument, baseOptions);
      }
export type Update_UserMutationHookResult = ReturnType<typeof useUpdate_UserMutation>;
export type Update_UserMutationResult = Apollo.MutationResult<Update_UserMutation>;
export type Update_UserMutationOptions = Apollo.BaseMutationOptions<Update_UserMutation, Update_UserMutationVariables>;
export const Update_User_PasswordDocument = gql`
    mutation update_user_password($mail: String!, $password: String!) {
  update_users(where: {mail: {_eq: $mail}}, _set: {password: $password}) {
    returning {
      id
    }
  }
}
    `;
export type Update_User_PasswordMutationFn = Apollo.MutationFunction<Update_User_PasswordMutation, Update_User_PasswordMutationVariables>;

/**
 * __useUpdate_User_PasswordMutation__
 *
 * To run a mutation, you first call `useUpdate_User_PasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_User_PasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordMutation, { data, loading, error }] = useUpdate_User_PasswordMutation({
 *   variables: {
 *      mail: // value for 'mail'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdate_User_PasswordMutation(baseOptions?: Apollo.MutationHookOptions<Update_User_PasswordMutation, Update_User_PasswordMutationVariables>) {
        return Apollo.useMutation<Update_User_PasswordMutation, Update_User_PasswordMutationVariables>(Update_User_PasswordDocument, baseOptions);
      }
export type Update_User_PasswordMutationHookResult = ReturnType<typeof useUpdate_User_PasswordMutation>;
export type Update_User_PasswordMutationResult = Apollo.MutationResult<Update_User_PasswordMutation>;
export type Update_User_PasswordMutationOptions = Apollo.BaseMutationOptions<Update_User_PasswordMutation, Update_User_PasswordMutationVariables>;
export const Get_AbsenceDocument = gql`
    query get_absence($id: uuid) {
  absence(where: {id: {_eq: $id}}) {
    absence_type_id
    employee_id
    employee {
      id
      mail
      acronym
      first_name
      last_name
    }
    end
    id
    notes
    start
    half_day
  }
}
    `;

/**
 * __useGet_AbsenceQuery__
 *
 * To run a query within a React component, call `useGet_AbsenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_AbsenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_AbsenceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_AbsenceQuery(baseOptions?: Apollo.QueryHookOptions<Get_AbsenceQuery, Get_AbsenceQueryVariables>) {
        return Apollo.useQuery<Get_AbsenceQuery, Get_AbsenceQueryVariables>(Get_AbsenceDocument, baseOptions);
      }
export function useGet_AbsenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_AbsenceQuery, Get_AbsenceQueryVariables>) {
          return Apollo.useLazyQuery<Get_AbsenceQuery, Get_AbsenceQueryVariables>(Get_AbsenceDocument, baseOptions);
        }
export type Get_AbsenceQueryHookResult = ReturnType<typeof useGet_AbsenceQuery>;
export type Get_AbsenceLazyQueryHookResult = ReturnType<typeof useGet_AbsenceLazyQuery>;
export type Get_AbsenceQueryResult = Apollo.QueryResult<Get_AbsenceQuery, Get_AbsenceQueryVariables>;
export const Insert_AbsenceDocument = gql`
    mutation insert_absence($absence: absence_insert_input!) {
  insert_absence(objects: [$absence]) {
    returning {
      absence_type_id
      employee_id
      employee {
        id
        mail
        acronym
        first_name
        last_name
      }
      end
      id
      notes
      start
      half_day
    }
  }
}
    `;
export type Insert_AbsenceMutationFn = Apollo.MutationFunction<Insert_AbsenceMutation, Insert_AbsenceMutationVariables>;

/**
 * __useInsert_AbsenceMutation__
 *
 * To run a mutation, you first call `useInsert_AbsenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_AbsenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAbsenceMutation, { data, loading, error }] = useInsert_AbsenceMutation({
 *   variables: {
 *      absence: // value for 'absence'
 *   },
 * });
 */
export function useInsert_AbsenceMutation(baseOptions?: Apollo.MutationHookOptions<Insert_AbsenceMutation, Insert_AbsenceMutationVariables>) {
        return Apollo.useMutation<Insert_AbsenceMutation, Insert_AbsenceMutationVariables>(Insert_AbsenceDocument, baseOptions);
      }
export type Insert_AbsenceMutationHookResult = ReturnType<typeof useInsert_AbsenceMutation>;
export type Insert_AbsenceMutationResult = Apollo.MutationResult<Insert_AbsenceMutation>;
export type Insert_AbsenceMutationOptions = Apollo.BaseMutationOptions<Insert_AbsenceMutation, Insert_AbsenceMutationVariables>;
export const Update_AbsenceDocument = gql`
    mutation update_absence($id: uuid, $absence: absence_set_input!) {
  update_absence(where: {id: {_eq: $id}}, _set: $absence) {
    returning {
      absence_type_id
      employee_id
      employee {
        id
        mail
        acronym
        first_name
        last_name
      }
      end
      id
      notes
      start
      half_day
    }
  }
}
    `;
export type Update_AbsenceMutationFn = Apollo.MutationFunction<Update_AbsenceMutation, Update_AbsenceMutationVariables>;

/**
 * __useUpdate_AbsenceMutation__
 *
 * To run a mutation, you first call `useUpdate_AbsenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_AbsenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAbsenceMutation, { data, loading, error }] = useUpdate_AbsenceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      absence: // value for 'absence'
 *   },
 * });
 */
export function useUpdate_AbsenceMutation(baseOptions?: Apollo.MutationHookOptions<Update_AbsenceMutation, Update_AbsenceMutationVariables>) {
        return Apollo.useMutation<Update_AbsenceMutation, Update_AbsenceMutationVariables>(Update_AbsenceDocument, baseOptions);
      }
export type Update_AbsenceMutationHookResult = ReturnType<typeof useUpdate_AbsenceMutation>;
export type Update_AbsenceMutationResult = Apollo.MutationResult<Update_AbsenceMutation>;
export type Update_AbsenceMutationOptions = Apollo.BaseMutationOptions<Update_AbsenceMutation, Update_AbsenceMutationVariables>;
export const Delete_AbsenceDocument = gql`
    mutation delete_absence($id: uuid!) {
  delete_absence(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type Delete_AbsenceMutationFn = Apollo.MutationFunction<Delete_AbsenceMutation, Delete_AbsenceMutationVariables>;

/**
 * __useDelete_AbsenceMutation__
 *
 * To run a mutation, you first call `useDelete_AbsenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_AbsenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAbsenceMutation, { data, loading, error }] = useDelete_AbsenceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_AbsenceMutation(baseOptions?: Apollo.MutationHookOptions<Delete_AbsenceMutation, Delete_AbsenceMutationVariables>) {
        return Apollo.useMutation<Delete_AbsenceMutation, Delete_AbsenceMutationVariables>(Delete_AbsenceDocument, baseOptions);
      }
export type Delete_AbsenceMutationHookResult = ReturnType<typeof useDelete_AbsenceMutation>;
export type Delete_AbsenceMutationResult = Apollo.MutationResult<Delete_AbsenceMutation>;
export type Delete_AbsenceMutationOptions = Apollo.BaseMutationOptions<Delete_AbsenceMutation, Delete_AbsenceMutationVariables>;
export const GetAbsenceDocument = gql`
    query getAbsence($orderBy: absence_search_view_order_by!, $where: absence_search_view_bool_exp, $limit: Int) {
  absence_search_view(order_by: [$orderBy], where: $where, limit: $limit) {
    id
    start
    end
    half_day
    employee {
      id
      mail
      acronym
      first_name
      last_name
    }
    absence_type {
      label
    }
  }
}
    `;

/**
 * __useGetAbsenceQuery__
 *
 * To run a query within a React component, call `useGetAbsenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAbsenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAbsenceQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAbsenceQuery(baseOptions: Apollo.QueryHookOptions<GetAbsenceQuery, GetAbsenceQueryVariables>) {
        return Apollo.useQuery<GetAbsenceQuery, GetAbsenceQueryVariables>(GetAbsenceDocument, baseOptions);
      }
export function useGetAbsenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAbsenceQuery, GetAbsenceQueryVariables>) {
          return Apollo.useLazyQuery<GetAbsenceQuery, GetAbsenceQueryVariables>(GetAbsenceDocument, baseOptions);
        }
export type GetAbsenceQueryHookResult = ReturnType<typeof useGetAbsenceQuery>;
export type GetAbsenceLazyQueryHookResult = ReturnType<typeof useGetAbsenceLazyQuery>;
export type GetAbsenceQueryResult = Apollo.QueryResult<GetAbsenceQuery, GetAbsenceQueryVariables>;
export const AbsenceDocument = gql`
    query absence($where: absence_bool_exp) {
  absence(where: $where) {
    employee {
      acronym
    }
    notes
    absence_type {
      category
      label
    }
    end
    start
    half_day
  }
}
    `;

/**
 * __useAbsenceQuery__
 *
 * To run a query within a React component, call `useAbsenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAbsenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAbsenceQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAbsenceQuery(baseOptions?: Apollo.QueryHookOptions<AbsenceQuery, AbsenceQueryVariables>) {
        return Apollo.useQuery<AbsenceQuery, AbsenceQueryVariables>(AbsenceDocument, baseOptions);
      }
export function useAbsenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AbsenceQuery, AbsenceQueryVariables>) {
          return Apollo.useLazyQuery<AbsenceQuery, AbsenceQueryVariables>(AbsenceDocument, baseOptions);
        }
export type AbsenceQueryHookResult = ReturnType<typeof useAbsenceQuery>;
export type AbsenceLazyQueryHookResult = ReturnType<typeof useAbsenceLazyQuery>;
export type AbsenceQueryResult = Apollo.QueryResult<AbsenceQuery, AbsenceQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers($orderBy: users_order_by!, $where: users_bool_exp) {
  users(order_by: [$orderBy], where: $where) {
    id
    mail
    first_name
    last_name
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const Get_Case_TimeDocument = gql`
    query get_case_time($case_id: uuid) {
  case_time_entries(where: {case_id: {_eq: $case_id}}) {
    date
    description
    id
    time
    user {
      acronym
    }
  }
}
    `;

/**
 * __useGet_Case_TimeQuery__
 *
 * To run a query within a React component, call `useGet_Case_TimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Case_TimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Case_TimeQuery({
 *   variables: {
 *      case_id: // value for 'case_id'
 *   },
 * });
 */
export function useGet_Case_TimeQuery(baseOptions?: Apollo.QueryHookOptions<Get_Case_TimeQuery, Get_Case_TimeQueryVariables>) {
        return Apollo.useQuery<Get_Case_TimeQuery, Get_Case_TimeQueryVariables>(Get_Case_TimeDocument, baseOptions);
      }
export function useGet_Case_TimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Case_TimeQuery, Get_Case_TimeQueryVariables>) {
          return Apollo.useLazyQuery<Get_Case_TimeQuery, Get_Case_TimeQueryVariables>(Get_Case_TimeDocument, baseOptions);
        }
export type Get_Case_TimeQueryHookResult = ReturnType<typeof useGet_Case_TimeQuery>;
export type Get_Case_TimeLazyQueryHookResult = ReturnType<typeof useGet_Case_TimeLazyQuery>;
export type Get_Case_TimeQueryResult = Apollo.QueryResult<Get_Case_TimeQuery, Get_Case_TimeQueryVariables>;
export const GetCaseClosedDocument = gql`
    query getCaseClosed($case_id: uuid!) {
  cases_by_pk(id: $case_id) {
    closed
  }
}
    `;

/**
 * __useGetCaseClosedQuery__
 *
 * To run a query within a React component, call `useGetCaseClosedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseClosedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseClosedQuery({
 *   variables: {
 *      case_id: // value for 'case_id'
 *   },
 * });
 */
export function useGetCaseClosedQuery(baseOptions: Apollo.QueryHookOptions<GetCaseClosedQuery, GetCaseClosedQueryVariables>) {
        return Apollo.useQuery<GetCaseClosedQuery, GetCaseClosedQueryVariables>(GetCaseClosedDocument, baseOptions);
      }
export function useGetCaseClosedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCaseClosedQuery, GetCaseClosedQueryVariables>) {
          return Apollo.useLazyQuery<GetCaseClosedQuery, GetCaseClosedQueryVariables>(GetCaseClosedDocument, baseOptions);
        }
export type GetCaseClosedQueryHookResult = ReturnType<typeof useGetCaseClosedQuery>;
export type GetCaseClosedLazyQueryHookResult = ReturnType<typeof useGetCaseClosedLazyQuery>;
export type GetCaseClosedQueryResult = Apollo.QueryResult<GetCaseClosedQuery, GetCaseClosedQueryVariables>;
export const UpdateRequestResponsibleDocument = gql`
    mutation updateRequestResponsible($id: uuid!, $responsibleId: uuid) {
  update_requests_by_pk(
    pk_columns: {id: $id}
    _set: {responsible_id: $responsibleId}
  ) {
    id
  }
}
    `;
export type UpdateRequestResponsibleMutationFn = Apollo.MutationFunction<UpdateRequestResponsibleMutation, UpdateRequestResponsibleMutationVariables>;

/**
 * __useUpdateRequestResponsibleMutation__
 *
 * To run a mutation, you first call `useUpdateRequestResponsibleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRequestResponsibleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRequestResponsibleMutation, { data, loading, error }] = useUpdateRequestResponsibleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      responsibleId: // value for 'responsibleId'
 *   },
 * });
 */
export function useUpdateRequestResponsibleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRequestResponsibleMutation, UpdateRequestResponsibleMutationVariables>) {
        return Apollo.useMutation<UpdateRequestResponsibleMutation, UpdateRequestResponsibleMutationVariables>(UpdateRequestResponsibleDocument, baseOptions);
      }
export type UpdateRequestResponsibleMutationHookResult = ReturnType<typeof useUpdateRequestResponsibleMutation>;
export type UpdateRequestResponsibleMutationResult = Apollo.MutationResult<UpdateRequestResponsibleMutation>;
export type UpdateRequestResponsibleMutationOptions = Apollo.BaseMutationOptions<UpdateRequestResponsibleMutation, UpdateRequestResponsibleMutationVariables>;
export const Get_Absence_TypesDocument = gql`
    query get_absence_types($id: uuid) {
  absence_types(where: {id: {_eq: $id}}) {
    id
    label
    active
    category
  }
}
    `;

/**
 * __useGet_Absence_TypesQuery__
 *
 * To run a query within a React component, call `useGet_Absence_TypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Absence_TypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Absence_TypesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGet_Absence_TypesQuery(baseOptions?: Apollo.QueryHookOptions<Get_Absence_TypesQuery, Get_Absence_TypesQueryVariables>) {
        return Apollo.useQuery<Get_Absence_TypesQuery, Get_Absence_TypesQueryVariables>(Get_Absence_TypesDocument, baseOptions);
      }
export function useGet_Absence_TypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Absence_TypesQuery, Get_Absence_TypesQueryVariables>) {
          return Apollo.useLazyQuery<Get_Absence_TypesQuery, Get_Absence_TypesQueryVariables>(Get_Absence_TypesDocument, baseOptions);
        }
export type Get_Absence_TypesQueryHookResult = ReturnType<typeof useGet_Absence_TypesQuery>;
export type Get_Absence_TypesLazyQueryHookResult = ReturnType<typeof useGet_Absence_TypesLazyQuery>;
export type Get_Absence_TypesQueryResult = Apollo.QueryResult<Get_Absence_TypesQuery, Get_Absence_TypesQueryVariables>;
export const Insert_Absence_TypeDocument = gql`
    mutation insert_absence_type($absenceType: absence_types_insert_input!) {
  insert_absence_types(objects: [$absenceType]) {
    returning {
      id
      label
      active
      category
    }
  }
}
    `;
export type Insert_Absence_TypeMutationFn = Apollo.MutationFunction<Insert_Absence_TypeMutation, Insert_Absence_TypeMutationVariables>;

/**
 * __useInsert_Absence_TypeMutation__
 *
 * To run a mutation, you first call `useInsert_Absence_TypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Absence_TypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAbsenceTypeMutation, { data, loading, error }] = useInsert_Absence_TypeMutation({
 *   variables: {
 *      absenceType: // value for 'absenceType'
 *   },
 * });
 */
export function useInsert_Absence_TypeMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Absence_TypeMutation, Insert_Absence_TypeMutationVariables>) {
        return Apollo.useMutation<Insert_Absence_TypeMutation, Insert_Absence_TypeMutationVariables>(Insert_Absence_TypeDocument, baseOptions);
      }
export type Insert_Absence_TypeMutationHookResult = ReturnType<typeof useInsert_Absence_TypeMutation>;
export type Insert_Absence_TypeMutationResult = Apollo.MutationResult<Insert_Absence_TypeMutation>;
export type Insert_Absence_TypeMutationOptions = Apollo.BaseMutationOptions<Insert_Absence_TypeMutation, Insert_Absence_TypeMutationVariables>;
export const Update_Absence_TypeDocument = gql`
    mutation update_absence_type($id: uuid, $absenceType: absence_types_set_input!) {
  update_absence_types(where: {id: {_eq: $id}}, _set: $absenceType) {
    returning {
      id
      label
      active
      category
    }
  }
}
    `;
export type Update_Absence_TypeMutationFn = Apollo.MutationFunction<Update_Absence_TypeMutation, Update_Absence_TypeMutationVariables>;

/**
 * __useUpdate_Absence_TypeMutation__
 *
 * To run a mutation, you first call `useUpdate_Absence_TypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdate_Absence_TypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAbsenceTypeMutation, { data, loading, error }] = useUpdate_Absence_TypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      absenceType: // value for 'absenceType'
 *   },
 * });
 */
export function useUpdate_Absence_TypeMutation(baseOptions?: Apollo.MutationHookOptions<Update_Absence_TypeMutation, Update_Absence_TypeMutationVariables>) {
        return Apollo.useMutation<Update_Absence_TypeMutation, Update_Absence_TypeMutationVariables>(Update_Absence_TypeDocument, baseOptions);
      }
export type Update_Absence_TypeMutationHookResult = ReturnType<typeof useUpdate_Absence_TypeMutation>;
export type Update_Absence_TypeMutationResult = Apollo.MutationResult<Update_Absence_TypeMutation>;
export type Update_Absence_TypeMutationOptions = Apollo.BaseMutationOptions<Update_Absence_TypeMutation, Update_Absence_TypeMutationVariables>;
export const GetConstructionPlanEntryDocument = gql`
    query getConstructionPlanEntry($id: uuid) {
  construction_plan_entries(where: {id: {_eq: $id}}) {
    id
    name
    days_after
    construction_plan {
      name
    }
  }
}
    `;

/**
 * __useGetConstructionPlanEntryQuery__
 *
 * To run a query within a React component, call `useGetConstructionPlanEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConstructionPlanEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConstructionPlanEntryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetConstructionPlanEntryQuery(baseOptions?: Apollo.QueryHookOptions<GetConstructionPlanEntryQuery, GetConstructionPlanEntryQueryVariables>) {
        return Apollo.useQuery<GetConstructionPlanEntryQuery, GetConstructionPlanEntryQueryVariables>(GetConstructionPlanEntryDocument, baseOptions);
      }
export function useGetConstructionPlanEntryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConstructionPlanEntryQuery, GetConstructionPlanEntryQueryVariables>) {
          return Apollo.useLazyQuery<GetConstructionPlanEntryQuery, GetConstructionPlanEntryQueryVariables>(GetConstructionPlanEntryDocument, baseOptions);
        }
export type GetConstructionPlanEntryQueryHookResult = ReturnType<typeof useGetConstructionPlanEntryQuery>;
export type GetConstructionPlanEntryLazyQueryHookResult = ReturnType<typeof useGetConstructionPlanEntryLazyQuery>;
export type GetConstructionPlanEntryQueryResult = Apollo.QueryResult<GetConstructionPlanEntryQuery, GetConstructionPlanEntryQueryVariables>;
export const InsertConstructionPlanEntryDocument = gql`
    mutation insertConstructionPlanEntry($constructionPlanEntry: construction_plan_entries_insert_input!) {
  insert_construction_plan_entries(objects: [$constructionPlanEntry]) {
    returning {
      id
      name
      days_after
    }
  }
}
    `;
export type InsertConstructionPlanEntryMutationFn = Apollo.MutationFunction<InsertConstructionPlanEntryMutation, InsertConstructionPlanEntryMutationVariables>;

/**
 * __useInsertConstructionPlanEntryMutation__
 *
 * To run a mutation, you first call `useInsertConstructionPlanEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertConstructionPlanEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertConstructionPlanEntryMutation, { data, loading, error }] = useInsertConstructionPlanEntryMutation({
 *   variables: {
 *      constructionPlanEntry: // value for 'constructionPlanEntry'
 *   },
 * });
 */
export function useInsertConstructionPlanEntryMutation(baseOptions?: Apollo.MutationHookOptions<InsertConstructionPlanEntryMutation, InsertConstructionPlanEntryMutationVariables>) {
        return Apollo.useMutation<InsertConstructionPlanEntryMutation, InsertConstructionPlanEntryMutationVariables>(InsertConstructionPlanEntryDocument, baseOptions);
      }
export type InsertConstructionPlanEntryMutationHookResult = ReturnType<typeof useInsertConstructionPlanEntryMutation>;
export type InsertConstructionPlanEntryMutationResult = Apollo.MutationResult<InsertConstructionPlanEntryMutation>;
export type InsertConstructionPlanEntryMutationOptions = Apollo.BaseMutationOptions<InsertConstructionPlanEntryMutation, InsertConstructionPlanEntryMutationVariables>;
export const UpdateConstructionPlanEntryDocument = gql`
    mutation updateConstructionPlanEntry($id: uuid, $constructionPlanEntry: construction_plan_entries_set_input!) {
  update_construction_plan_entries(
    where: {id: {_eq: $id}}
    _set: $constructionPlanEntry
  ) {
    returning {
      id
      name
      days_after
    }
  }
}
    `;
export type UpdateConstructionPlanEntryMutationFn = Apollo.MutationFunction<UpdateConstructionPlanEntryMutation, UpdateConstructionPlanEntryMutationVariables>;

/**
 * __useUpdateConstructionPlanEntryMutation__
 *
 * To run a mutation, you first call `useUpdateConstructionPlanEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConstructionPlanEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConstructionPlanEntryMutation, { data, loading, error }] = useUpdateConstructionPlanEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      constructionPlanEntry: // value for 'constructionPlanEntry'
 *   },
 * });
 */
export function useUpdateConstructionPlanEntryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConstructionPlanEntryMutation, UpdateConstructionPlanEntryMutationVariables>) {
        return Apollo.useMutation<UpdateConstructionPlanEntryMutation, UpdateConstructionPlanEntryMutationVariables>(UpdateConstructionPlanEntryDocument, baseOptions);
      }
export type UpdateConstructionPlanEntryMutationHookResult = ReturnType<typeof useUpdateConstructionPlanEntryMutation>;
export type UpdateConstructionPlanEntryMutationResult = Apollo.MutationResult<UpdateConstructionPlanEntryMutation>;
export type UpdateConstructionPlanEntryMutationOptions = Apollo.BaseMutationOptions<UpdateConstructionPlanEntryMutation, UpdateConstructionPlanEntryMutationVariables>;
export const GetConstructionPlanDocument = gql`
    query getConstructionPlan($id: uuid) {
  construction_plans(where: {id: {_eq: $id}}) {
    id
    name
    entries(order_by: {days_after: asc}) {
      days_after
      id
      name
      construction_plan_id
    }
  }
}
    `;

/**
 * __useGetConstructionPlanQuery__
 *
 * To run a query within a React component, call `useGetConstructionPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConstructionPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConstructionPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetConstructionPlanQuery(baseOptions?: Apollo.QueryHookOptions<GetConstructionPlanQuery, GetConstructionPlanQueryVariables>) {
        return Apollo.useQuery<GetConstructionPlanQuery, GetConstructionPlanQueryVariables>(GetConstructionPlanDocument, baseOptions);
      }
export function useGetConstructionPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConstructionPlanQuery, GetConstructionPlanQueryVariables>) {
          return Apollo.useLazyQuery<GetConstructionPlanQuery, GetConstructionPlanQueryVariables>(GetConstructionPlanDocument, baseOptions);
        }
export type GetConstructionPlanQueryHookResult = ReturnType<typeof useGetConstructionPlanQuery>;
export type GetConstructionPlanLazyQueryHookResult = ReturnType<typeof useGetConstructionPlanLazyQuery>;
export type GetConstructionPlanQueryResult = Apollo.QueryResult<GetConstructionPlanQuery, GetConstructionPlanQueryVariables>;
export const InsertConstructionPlanDocument = gql`
    mutation insertConstructionPlan($constructionPlan: construction_plans_insert_input!) {
  insert_construction_plans(objects: [$constructionPlan]) {
    returning {
      id
      name
    }
  }
}
    `;
export type InsertConstructionPlanMutationFn = Apollo.MutationFunction<InsertConstructionPlanMutation, InsertConstructionPlanMutationVariables>;

/**
 * __useInsertConstructionPlanMutation__
 *
 * To run a mutation, you first call `useInsertConstructionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertConstructionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertConstructionPlanMutation, { data, loading, error }] = useInsertConstructionPlanMutation({
 *   variables: {
 *      constructionPlan: // value for 'constructionPlan'
 *   },
 * });
 */
export function useInsertConstructionPlanMutation(baseOptions?: Apollo.MutationHookOptions<InsertConstructionPlanMutation, InsertConstructionPlanMutationVariables>) {
        return Apollo.useMutation<InsertConstructionPlanMutation, InsertConstructionPlanMutationVariables>(InsertConstructionPlanDocument, baseOptions);
      }
export type InsertConstructionPlanMutationHookResult = ReturnType<typeof useInsertConstructionPlanMutation>;
export type InsertConstructionPlanMutationResult = Apollo.MutationResult<InsertConstructionPlanMutation>;
export type InsertConstructionPlanMutationOptions = Apollo.BaseMutationOptions<InsertConstructionPlanMutation, InsertConstructionPlanMutationVariables>;
export const UpdateConstructionPlanDocument = gql`
    mutation updateConstructionPlan($id: uuid, $constructionPlan: construction_plans_set_input!) {
  update_construction_plans(where: {id: {_eq: $id}}, _set: $constructionPlan) {
    returning {
      id
      name
    }
  }
}
    `;
export type UpdateConstructionPlanMutationFn = Apollo.MutationFunction<UpdateConstructionPlanMutation, UpdateConstructionPlanMutationVariables>;

/**
 * __useUpdateConstructionPlanMutation__
 *
 * To run a mutation, you first call `useUpdateConstructionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConstructionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConstructionPlanMutation, { data, loading, error }] = useUpdateConstructionPlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      constructionPlan: // value for 'constructionPlan'
 *   },
 * });
 */
export function useUpdateConstructionPlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConstructionPlanMutation, UpdateConstructionPlanMutationVariables>) {
        return Apollo.useMutation<UpdateConstructionPlanMutation, UpdateConstructionPlanMutationVariables>(UpdateConstructionPlanDocument, baseOptions);
      }
export type UpdateConstructionPlanMutationHookResult = ReturnType<typeof useUpdateConstructionPlanMutation>;
export type UpdateConstructionPlanMutationResult = Apollo.MutationResult<UpdateConstructionPlanMutation>;
export type UpdateConstructionPlanMutationOptions = Apollo.BaseMutationOptions<UpdateConstructionPlanMutation, UpdateConstructionPlanMutationVariables>;
export const CaseControlBuildingInspectionDocument = gql`
    query caseControlBuildingInspection($orderBy: cases_order_by!, $where: cases_bool_exp) {
  cases(order_by: [$orderBy], where: $where) {
    id
    task
    description
    responsible_id
    responsible {
      id
      acronym
    }
    company_id
    builder {
      id
      address
      zip_code
      city
    }
    address
    zip_code
    city
    case_type_id
    case_files {
      id
    }
    case_photos {
      file {
        created
      }
    }
    reminders {
      id
      date
    }
    date_of_inspection
    estimated_time
    other_address
    changed
    flag
    checklists_aggregate {
      aggregate {
        count
      }
    }
    quality_controls_aggregate {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useCaseControlBuildingInspectionQuery__
 *
 * To run a query within a React component, call `useCaseControlBuildingInspectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseControlBuildingInspectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseControlBuildingInspectionQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCaseControlBuildingInspectionQuery(baseOptions: Apollo.QueryHookOptions<CaseControlBuildingInspectionQuery, CaseControlBuildingInspectionQueryVariables>) {
        return Apollo.useQuery<CaseControlBuildingInspectionQuery, CaseControlBuildingInspectionQueryVariables>(CaseControlBuildingInspectionDocument, baseOptions);
      }
export function useCaseControlBuildingInspectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CaseControlBuildingInspectionQuery, CaseControlBuildingInspectionQueryVariables>) {
          return Apollo.useLazyQuery<CaseControlBuildingInspectionQuery, CaseControlBuildingInspectionQueryVariables>(CaseControlBuildingInspectionDocument, baseOptions);
        }
export type CaseControlBuildingInspectionQueryHookResult = ReturnType<typeof useCaseControlBuildingInspectionQuery>;
export type CaseControlBuildingInspectionLazyQueryHookResult = ReturnType<typeof useCaseControlBuildingInspectionLazyQuery>;
export type CaseControlBuildingInspectionQueryResult = Apollo.QueryResult<CaseControlBuildingInspectionQuery, CaseControlBuildingInspectionQueryVariables>;
export const CaseControlMoistureTechnologyDocument = gql`
    query caseControlMoistureTechnology($orderBy: cases_order_by!, $where: cases_bool_exp) {
  cases(order_by: [$orderBy], where: $where) {
    id
    task
    description
    responsible_id
    responsible {
      id
      acronym
    }
    quality_controls {
      id
    }
    checklists {
      id
    }
    builder {
      id
      address
      zip_code
      city
    }
    address
    zip_code
    city
    case_type_id
    case_type {
      name
    }
    case_files {
      id
    }
    case_photos {
      file {
        created
      }
    }
    reminders {
      id
      date
    }
    date_of_inspection
    estimated_time
    other_address
    changed
  }
}
    `;

/**
 * __useCaseControlMoistureTechnologyQuery__
 *
 * To run a query within a React component, call `useCaseControlMoistureTechnologyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseControlMoistureTechnologyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseControlMoistureTechnologyQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCaseControlMoistureTechnologyQuery(baseOptions: Apollo.QueryHookOptions<CaseControlMoistureTechnologyQuery, CaseControlMoistureTechnologyQueryVariables>) {
        return Apollo.useQuery<CaseControlMoistureTechnologyQuery, CaseControlMoistureTechnologyQueryVariables>(CaseControlMoistureTechnologyDocument, baseOptions);
      }
export function useCaseControlMoistureTechnologyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CaseControlMoistureTechnologyQuery, CaseControlMoistureTechnologyQueryVariables>) {
          return Apollo.useLazyQuery<CaseControlMoistureTechnologyQuery, CaseControlMoistureTechnologyQueryVariables>(CaseControlMoistureTechnologyDocument, baseOptions);
        }
export type CaseControlMoistureTechnologyQueryHookResult = ReturnType<typeof useCaseControlMoistureTechnologyQuery>;
export type CaseControlMoistureTechnologyLazyQueryHookResult = ReturnType<typeof useCaseControlMoistureTechnologyLazyQuery>;
export type CaseControlMoistureTechnologyQueryResult = Apollo.QueryResult<CaseControlMoistureTechnologyQuery, CaseControlMoistureTechnologyQueryVariables>;
export const Case_StatsDocument = gql`
    query case_stats($startMonth: String, $endMonth: String, $timestamp: timestamptz) {
  cases(where: {created: {_lte: $timestamp}, closed: {_gte: $timestamp}}) {
    case_type {
      name
    }
  }
  cases_case_type_count(order_by: {count: desc}) {
    case_type {
      name
    }
    count
    case_type_id
  }
  cases_case_count_per_employee(order_by: {responsible: {employee_number: asc}}) {
    count
    responsible {
      first_name
      last_name
      acronym
      employee_number
    }
    responsible_id
  }
  cases_building_inspection_per_employee(
    order_by: {responsible: {employee_number: asc}}
  ) {
    case_type_id
    count
    responsible_id
    case_type {
      name
    }
    responsible {
      first_name
      last_name
      acronym
      employee_number
    }
  }
  cases_per_case_type_per_month(
    where: {_and: {year_month: {_lte: $endMonth}}, year_month: {_gte: $startMonth}}
    order_by: {year_month: asc}
  ) {
    case_type_id
    count
    year_month
    case_type {
      color
      name
    }
  }
}
    `;

/**
 * __useCase_StatsQuery__
 *
 * To run a query within a React component, call `useCase_StatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCase_StatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCase_StatsQuery({
 *   variables: {
 *      startMonth: // value for 'startMonth'
 *      endMonth: // value for 'endMonth'
 *      timestamp: // value for 'timestamp'
 *   },
 * });
 */
export function useCase_StatsQuery(baseOptions?: Apollo.QueryHookOptions<Case_StatsQuery, Case_StatsQueryVariables>) {
        return Apollo.useQuery<Case_StatsQuery, Case_StatsQueryVariables>(Case_StatsDocument, baseOptions);
      }
export function useCase_StatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Case_StatsQuery, Case_StatsQueryVariables>) {
          return Apollo.useLazyQuery<Case_StatsQuery, Case_StatsQueryVariables>(Case_StatsDocument, baseOptions);
        }
export type Case_StatsQueryHookResult = ReturnType<typeof useCase_StatsQuery>;
export type Case_StatsLazyQueryHookResult = ReturnType<typeof useCase_StatsLazyQuery>;
export type Case_StatsQueryResult = Apollo.QueryResult<Case_StatsQuery, Case_StatsQueryVariables>;
export const RequestStatesNotUserDocument = gql`
    query requestStatesNotUser($startDate: timestamptz, $endDate: timestamptz) {
  cases(
    where: {created: {_gte: $startDate, _lte: $endDate}, created_by: {user_type: {_in: ["sale", "admin", "superuser"]}}, deleted: {_eq: false}}
  ) {
    created_by_id
    case_type {
      id
      name
    }
    created
    created_by {
      acronym
      employee_number
    }
  }
}
    `;

/**
 * __useRequestStatesNotUserQuery__
 *
 * To run a query within a React component, call `useRequestStatesNotUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestStatesNotUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestStatesNotUserQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useRequestStatesNotUserQuery(baseOptions?: Apollo.QueryHookOptions<RequestStatesNotUserQuery, RequestStatesNotUserQueryVariables>) {
        return Apollo.useQuery<RequestStatesNotUserQuery, RequestStatesNotUserQueryVariables>(RequestStatesNotUserDocument, baseOptions);
      }
export function useRequestStatesNotUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestStatesNotUserQuery, RequestStatesNotUserQueryVariables>) {
          return Apollo.useLazyQuery<RequestStatesNotUserQuery, RequestStatesNotUserQueryVariables>(RequestStatesNotUserDocument, baseOptions);
        }
export type RequestStatesNotUserQueryHookResult = ReturnType<typeof useRequestStatesNotUserQuery>;
export type RequestStatesNotUserLazyQueryHookResult = ReturnType<typeof useRequestStatesNotUserLazyQuery>;
export type RequestStatesNotUserQueryResult = Apollo.QueryResult<RequestStatesNotUserQuery, RequestStatesNotUserQueryVariables>;
export const CasesCreatedPerEmployeePerMonthDocument = gql`
    query casesCreatedPerEmployeePerMonth($startMonth: String, $endMonth: String) {
  cases_created_per_employee_per_month(
    order_by: {year_month: desc}
    where: {year_month: {_gte: $startMonth, _lte: $endMonth}, employee: {active: {_eq: true}}}
  ) {
    count
    employee {
      acronym
      employee_number
    }
    created_by_id
    year_month
  }
}
    `;

/**
 * __useCasesCreatedPerEmployeePerMonthQuery__
 *
 * To run a query within a React component, call `useCasesCreatedPerEmployeePerMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useCasesCreatedPerEmployeePerMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCasesCreatedPerEmployeePerMonthQuery({
 *   variables: {
 *      startMonth: // value for 'startMonth'
 *      endMonth: // value for 'endMonth'
 *   },
 * });
 */
export function useCasesCreatedPerEmployeePerMonthQuery(baseOptions?: Apollo.QueryHookOptions<CasesCreatedPerEmployeePerMonthQuery, CasesCreatedPerEmployeePerMonthQueryVariables>) {
        return Apollo.useQuery<CasesCreatedPerEmployeePerMonthQuery, CasesCreatedPerEmployeePerMonthQueryVariables>(CasesCreatedPerEmployeePerMonthDocument, baseOptions);
      }
export function useCasesCreatedPerEmployeePerMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CasesCreatedPerEmployeePerMonthQuery, CasesCreatedPerEmployeePerMonthQueryVariables>) {
          return Apollo.useLazyQuery<CasesCreatedPerEmployeePerMonthQuery, CasesCreatedPerEmployeePerMonthQueryVariables>(CasesCreatedPerEmployeePerMonthDocument, baseOptions);
        }
export type CasesCreatedPerEmployeePerMonthQueryHookResult = ReturnType<typeof useCasesCreatedPerEmployeePerMonthQuery>;
export type CasesCreatedPerEmployeePerMonthLazyQueryHookResult = ReturnType<typeof useCasesCreatedPerEmployeePerMonthLazyQuery>;
export type CasesCreatedPerEmployeePerMonthQueryResult = Apollo.QueryResult<CasesCreatedPerEmployeePerMonthQuery, CasesCreatedPerEmployeePerMonthQueryVariables>;
export const RequestStatsDocument = gql`
    query requestStats($startDate: timestamptz, $endDate: timestamptz) {
  cases(
    where: {created: {_gte: $startDate, _lte: $endDate}, deleted: {_eq: false}}
  ) {
    responsible_id
    case_type {
      id
      name
    }
    created
    responsible {
      acronym
      employee_number
    }
  }
}
    `;

/**
 * __useRequestStatsQuery__
 *
 * To run a query within a React component, call `useRequestStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestStatsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useRequestStatsQuery(baseOptions?: Apollo.QueryHookOptions<RequestStatsQuery, RequestStatsQueryVariables>) {
        return Apollo.useQuery<RequestStatsQuery, RequestStatsQueryVariables>(RequestStatsDocument, baseOptions);
      }
export function useRequestStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestStatsQuery, RequestStatsQueryVariables>) {
          return Apollo.useLazyQuery<RequestStatsQuery, RequestStatsQueryVariables>(RequestStatsDocument, baseOptions);
        }
export type RequestStatsQueryHookResult = ReturnType<typeof useRequestStatsQuery>;
export type RequestStatsLazyQueryHookResult = ReturnType<typeof useRequestStatsLazyQuery>;
export type RequestStatsQueryResult = Apollo.QueryResult<RequestStatsQuery, RequestStatsQueryVariables>;
export const CasesCreatedEmployeeNumbersDocument = gql`
    query casesCreatedEmployeeNumbers($startMonth: String, $startDate: timestamptz, $endDate: timestamptz) {
  users {
    employee_number
    acronym
  }
}
    `;

/**
 * __useCasesCreatedEmployeeNumbersQuery__
 *
 * To run a query within a React component, call `useCasesCreatedEmployeeNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCasesCreatedEmployeeNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCasesCreatedEmployeeNumbersQuery({
 *   variables: {
 *      startMonth: // value for 'startMonth'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCasesCreatedEmployeeNumbersQuery(baseOptions?: Apollo.QueryHookOptions<CasesCreatedEmployeeNumbersQuery, CasesCreatedEmployeeNumbersQueryVariables>) {
        return Apollo.useQuery<CasesCreatedEmployeeNumbersQuery, CasesCreatedEmployeeNumbersQueryVariables>(CasesCreatedEmployeeNumbersDocument, baseOptions);
      }
export function useCasesCreatedEmployeeNumbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CasesCreatedEmployeeNumbersQuery, CasesCreatedEmployeeNumbersQueryVariables>) {
          return Apollo.useLazyQuery<CasesCreatedEmployeeNumbersQuery, CasesCreatedEmployeeNumbersQueryVariables>(CasesCreatedEmployeeNumbersDocument, baseOptions);
        }
export type CasesCreatedEmployeeNumbersQueryHookResult = ReturnType<typeof useCasesCreatedEmployeeNumbersQuery>;
export type CasesCreatedEmployeeNumbersLazyQueryHookResult = ReturnType<typeof useCasesCreatedEmployeeNumbersLazyQuery>;
export type CasesCreatedEmployeeNumbersQueryResult = Apollo.QueryResult<CasesCreatedEmployeeNumbersQuery, CasesCreatedEmployeeNumbersQueryVariables>;
export const ClosedCaseRemindersDocument = gql`
    query closedCaseReminders($orderBy: cases_order_by!, $where: cases_bool_exp) {
  cases(order_by: [$orderBy], where: $where) {
    id
    task
    description
    responsible_id
    responsible {
      id
      acronym
    }
    builder {
      id
      address
      zip_code
      city
    }
    address
    zip_code
    city
    case_type_id
    case_type {
      name
    }
    reminders {
      id
      date
    }
    date_of_inspection
    other_address
    changed
    closed
  }
}
    `;

/**
 * __useClosedCaseRemindersQuery__
 *
 * To run a query within a React component, call `useClosedCaseRemindersQuery` and pass it any options that fit your needs.
 * When your component renders, `useClosedCaseRemindersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClosedCaseRemindersQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useClosedCaseRemindersQuery(baseOptions: Apollo.QueryHookOptions<ClosedCaseRemindersQuery, ClosedCaseRemindersQueryVariables>) {
        return Apollo.useQuery<ClosedCaseRemindersQuery, ClosedCaseRemindersQueryVariables>(ClosedCaseRemindersDocument, baseOptions);
      }
export function useClosedCaseRemindersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClosedCaseRemindersQuery, ClosedCaseRemindersQueryVariables>) {
          return Apollo.useLazyQuery<ClosedCaseRemindersQuery, ClosedCaseRemindersQueryVariables>(ClosedCaseRemindersDocument, baseOptions);
        }
export type ClosedCaseRemindersQueryHookResult = ReturnType<typeof useClosedCaseRemindersQuery>;
export type ClosedCaseRemindersLazyQueryHookResult = ReturnType<typeof useClosedCaseRemindersLazyQuery>;
export type ClosedCaseRemindersQueryResult = Apollo.QueryResult<ClosedCaseRemindersQuery, ClosedCaseRemindersQueryVariables>;
export const RequestExternalCaseStatsForUserDocument = gql`
    query requestExternalCaseStatsForUser($startDate: timestamptz, $contactId: uuid = "", $endDate: timestamptz = "2099-12-21") {
  requests(
    where: {date: {_gt: $startDate, _lte: $endDate}, state: {_eq: "external"}, external_id: {_eq: $contactId}}
    order_by: {date: asc}
  ) {
    task
    id
    case_type {
      name
      price
    }
  }
}
    `;

/**
 * __useRequestExternalCaseStatsForUserQuery__
 *
 * To run a query within a React component, call `useRequestExternalCaseStatsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestExternalCaseStatsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestExternalCaseStatsForUserQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      contactId: // value for 'contactId'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useRequestExternalCaseStatsForUserQuery(baseOptions?: Apollo.QueryHookOptions<RequestExternalCaseStatsForUserQuery, RequestExternalCaseStatsForUserQueryVariables>) {
        return Apollo.useQuery<RequestExternalCaseStatsForUserQuery, RequestExternalCaseStatsForUserQueryVariables>(RequestExternalCaseStatsForUserDocument, baseOptions);
      }
export function useRequestExternalCaseStatsForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestExternalCaseStatsForUserQuery, RequestExternalCaseStatsForUserQueryVariables>) {
          return Apollo.useLazyQuery<RequestExternalCaseStatsForUserQuery, RequestExternalCaseStatsForUserQueryVariables>(RequestExternalCaseStatsForUserDocument, baseOptions);
        }
export type RequestExternalCaseStatsForUserQueryHookResult = ReturnType<typeof useRequestExternalCaseStatsForUserQuery>;
export type RequestExternalCaseStatsForUserLazyQueryHookResult = ReturnType<typeof useRequestExternalCaseStatsForUserLazyQuery>;
export type RequestExternalCaseStatsForUserQueryResult = Apollo.QueryResult<RequestExternalCaseStatsForUserQuery, RequestExternalCaseStatsForUserQueryVariables>;
export const RequestExternalCaseStatsDocument = gql`
    query requestExternalCaseStats($startDate: timestamptz, $endDate: timestamptz = "2099-12-21") {
  requests(
    where: {date: {_gt: $startDate, _lte: $endDate}, state: {_eq: "external"}}
    order_by: {date: asc}
  ) {
    task
    id
    case_type {
      name
      price
    }
  }
}
    `;

/**
 * __useRequestExternalCaseStatsQuery__
 *
 * To run a query within a React component, call `useRequestExternalCaseStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestExternalCaseStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestExternalCaseStatsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useRequestExternalCaseStatsQuery(baseOptions?: Apollo.QueryHookOptions<RequestExternalCaseStatsQuery, RequestExternalCaseStatsQueryVariables>) {
        return Apollo.useQuery<RequestExternalCaseStatsQuery, RequestExternalCaseStatsQueryVariables>(RequestExternalCaseStatsDocument, baseOptions);
      }
export function useRequestExternalCaseStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestExternalCaseStatsQuery, RequestExternalCaseStatsQueryVariables>) {
          return Apollo.useLazyQuery<RequestExternalCaseStatsQuery, RequestExternalCaseStatsQueryVariables>(RequestExternalCaseStatsDocument, baseOptions);
        }
export type RequestExternalCaseStatsQueryHookResult = ReturnType<typeof useRequestExternalCaseStatsQuery>;
export type RequestExternalCaseStatsLazyQueryHookResult = ReturnType<typeof useRequestExternalCaseStatsLazyQuery>;
export type RequestExternalCaseStatsQueryResult = Apollo.QueryResult<RequestExternalCaseStatsQuery, RequestExternalCaseStatsQueryVariables>;
export const CasesWithConstructionPlansDocument = gql`
    query casesWithConstructionPlans($where: cases_bool_exp) {
  cases(where: $where, order_by: {date_of_inspection: asc}) {
    id
    task
    description
    builder {
      id
      address
      zip_code
      city
    }
    address
    zip_code
    city
    date_of_inspection
    other_address
    construction_plan {
      entries {
        days_after
        name
        id
      }
    }
  }
}
    `;

/**
 * __useCasesWithConstructionPlansQuery__
 *
 * To run a query within a React component, call `useCasesWithConstructionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useCasesWithConstructionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCasesWithConstructionPlansQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCasesWithConstructionPlansQuery(baseOptions?: Apollo.QueryHookOptions<CasesWithConstructionPlansQuery, CasesWithConstructionPlansQueryVariables>) {
        return Apollo.useQuery<CasesWithConstructionPlansQuery, CasesWithConstructionPlansQueryVariables>(CasesWithConstructionPlansDocument, baseOptions);
      }
export function useCasesWithConstructionPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CasesWithConstructionPlansQuery, CasesWithConstructionPlansQueryVariables>) {
          return Apollo.useLazyQuery<CasesWithConstructionPlansQuery, CasesWithConstructionPlansQueryVariables>(CasesWithConstructionPlansDocument, baseOptions);
        }
export type CasesWithConstructionPlansQueryHookResult = ReturnType<typeof useCasesWithConstructionPlansQuery>;
export type CasesWithConstructionPlansLazyQueryHookResult = ReturnType<typeof useCasesWithConstructionPlansLazyQuery>;
export type CasesWithConstructionPlansQueryResult = Apollo.QueryResult<CasesWithConstructionPlansQuery, CasesWithConstructionPlansQueryVariables>;
export const RequestsDonePerEmployeePerMonthDocument = gql`
    query requestsDonePerEmployeePerMonth($startMonth: String, $endMonth: String) {
  requests_done_per_employee_per_month(
    order_by: {year_month: desc}
    where: {year_month: {_gte: $startMonth, _lte: $endMonth}}
  ) {
    count
    employee {
      acronym
    }
    responsible_id
    year_month
  }
}
    `;

/**
 * __useRequestsDonePerEmployeePerMonthQuery__
 *
 * To run a query within a React component, call `useRequestsDonePerEmployeePerMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestsDonePerEmployeePerMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestsDonePerEmployeePerMonthQuery({
 *   variables: {
 *      startMonth: // value for 'startMonth'
 *      endMonth: // value for 'endMonth'
 *   },
 * });
 */
export function useRequestsDonePerEmployeePerMonthQuery(baseOptions?: Apollo.QueryHookOptions<RequestsDonePerEmployeePerMonthQuery, RequestsDonePerEmployeePerMonthQueryVariables>) {
        return Apollo.useQuery<RequestsDonePerEmployeePerMonthQuery, RequestsDonePerEmployeePerMonthQueryVariables>(RequestsDonePerEmployeePerMonthDocument, baseOptions);
      }
export function useRequestsDonePerEmployeePerMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestsDonePerEmployeePerMonthQuery, RequestsDonePerEmployeePerMonthQueryVariables>) {
          return Apollo.useLazyQuery<RequestsDonePerEmployeePerMonthQuery, RequestsDonePerEmployeePerMonthQueryVariables>(RequestsDonePerEmployeePerMonthDocument, baseOptions);
        }
export type RequestsDonePerEmployeePerMonthQueryHookResult = ReturnType<typeof useRequestsDonePerEmployeePerMonthQuery>;
export type RequestsDonePerEmployeePerMonthLazyQueryHookResult = ReturnType<typeof useRequestsDonePerEmployeePerMonthLazyQuery>;
export type RequestsDonePerEmployeePerMonthQueryResult = Apollo.QueryResult<RequestsDonePerEmployeePerMonthQuery, RequestsDonePerEmployeePerMonthQueryVariables>;
export const MultipleStatsDocument = gql`
    query multipleStats($date: timestamptz, $startMonth: String) {
  requests_new_task_count(order_by: {count: desc}) {
    count
    case_type {
      name
    }
  }
  requests(
    where: {date: {_gt: $date}, state: {_eq: "external"}}
    order_by: {date: asc}
  ) {
    case_type {
      name
    }
    date
  }
  requests_followup_task_count(order_by: {count: desc}) {
    count
    case_type {
      name
    }
    from_date
    to_date
  }
  requests_per_case_type_per_month(
    where: {year_month: {_gte: $startMonth}}
    order_by: {year_month: asc}
  ) {
    case_type_id
    count
    year_month
    case_type {
      price
    }
  }
}
    `;

/**
 * __useMultipleStatsQuery__
 *
 * To run a query within a React component, call `useMultipleStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultipleStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultipleStatsQuery({
 *   variables: {
 *      date: // value for 'date'
 *      startMonth: // value for 'startMonth'
 *   },
 * });
 */
export function useMultipleStatsQuery(baseOptions?: Apollo.QueryHookOptions<MultipleStatsQuery, MultipleStatsQueryVariables>) {
        return Apollo.useQuery<MultipleStatsQuery, MultipleStatsQueryVariables>(MultipleStatsDocument, baseOptions);
      }
export function useMultipleStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MultipleStatsQuery, MultipleStatsQueryVariables>) {
          return Apollo.useLazyQuery<MultipleStatsQuery, MultipleStatsQueryVariables>(MultipleStatsDocument, baseOptions);
        }
export type MultipleStatsQueryHookResult = ReturnType<typeof useMultipleStatsQuery>;
export type MultipleStatsLazyQueryHookResult = ReturnType<typeof useMultipleStatsLazyQuery>;
export type MultipleStatsQueryResult = Apollo.QueryResult<MultipleStatsQuery, MultipleStatsQueryVariables>;
export const Request_FollowupDocument = gql`
    query request_followup($userId: uuid = "") {
  requests_followup_task_count_for_user(
    args: {user_id: $userId}
    order_by: {count: desc}
  ) {
    count
    case_type {
      name
    }
    from_date
    to_date
  }
}
    `;

/**
 * __useRequest_FollowupQuery__
 *
 * To run a query within a React component, call `useRequest_FollowupQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequest_FollowupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequest_FollowupQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRequest_FollowupQuery(baseOptions?: Apollo.QueryHookOptions<Request_FollowupQuery, Request_FollowupQueryVariables>) {
        return Apollo.useQuery<Request_FollowupQuery, Request_FollowupQueryVariables>(Request_FollowupDocument, baseOptions);
      }
export function useRequest_FollowupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Request_FollowupQuery, Request_FollowupQueryVariables>) {
          return Apollo.useLazyQuery<Request_FollowupQuery, Request_FollowupQueryVariables>(Request_FollowupDocument, baseOptions);
        }
export type Request_FollowupQueryHookResult = ReturnType<typeof useRequest_FollowupQuery>;
export type Request_FollowupLazyQueryHookResult = ReturnType<typeof useRequest_FollowupLazyQuery>;
export type Request_FollowupQueryResult = Apollo.QueryResult<Request_FollowupQuery, Request_FollowupQueryVariables>;