import * as yup from "yup";
import {
  CREATE_QUALITY_CONTROL_TEMPLATE,
  CREATE_QUALITY_CONTROL_TEMPLATE_TASK,
  DELETE_QUALITY_CONTROL_TEMPLATE_TASK,
  GET_QUALITY_CONTROL_TEMPLATE,
  UPDATE_QUALITY_CONTROL_TEMPLATE,
  UPDATE_QUALITY_CONTROL_TEMPLATE_TASK
} from "../../graphql/quality-control-templates";
import { Page } from "../../components/layout/page";
import { QualityControlTemplateForm } from "./quality-control-templates-form";
import { Query } from "@apollo/client/react/components";
import { flowRight as compose } from "lodash";
import { defineMessages, injectIntl } from "react-intl";
import { graphql, withApollo } from "@apollo/client/react/hoc";
import { relatedDiff } from "./order-confirmation-templates-entry";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import AreYouSure from "../../components/are-you-sure";
import Header from "../../components/layout/header";
import PropTypes from "prop-types";
import React, { PureComponent, useState } from "react";

const messages = defineMessages({
  add: {
    defaultMessage: "Tilføj",
    id: "qualityControlTemplate-entry.add"
  },
  createNew: {
    defaultMessage: "Opret ny ordrebekræftelsesskabelon",
    id: "qualityControlTemplate-entry.create-new-qualityControlTemplate"
  },
  update: {
    defaultMessage: "Redigér ordrebekræftelsesskabelon",
    id: "qualityControlTemplate-entry.update-qualityControlTemplate"
  }
});

const schema = yup.object().shape({
  //name: yup.string().required(),
  //color: yup.string().required(),
  //icon: yup.string().required()
});

class QualityControlTemplateEntry extends PureComponent {
  static propTypes = {
    client: PropTypes.object.isRequired,
    dirty: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    id: PropTypes.string,
    intl: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    resetForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired
  };
  handleResetForm = () => {
    this.props.handleReset();
  };

  showNavigationWarning = () => {
    return !this.props.isSubmitting && this.props.dirty;
  };

  render() {
    const {
      dirty,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      id,
      intl: { formatMessage },
      isSubmitting,
      setFieldValue,
      touched,
      values
    } = this.props;

    let heading;
    if (id) {
      heading = formatMessage(messages.update);
    } else {
      heading = formatMessage(messages.createNew);
    }
    return (
      <Page
        appBar={
          <Header
            dirty={dirty}
            title={heading}
            onResetButton={this.handleResetForm}
            onSaveButton={handleSubmit}
          />
        }
      >
        <div
          style={{
            backgroundColor: "#FFF",
            minHeight: "calc(100% - 64px)",
            padding: 20
          }}
        >
          <QualityControlTemplateForm
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            touched={touched}
            values={values}
          />
        </div>
        <AreYouSure when={this.showNavigationWarning} />
      </Page>
    );
  }
}

const QualityControlTemplateEntryWithApollo = withApollo(
  QualityControlTemplateEntry
);

const handleSubmit = async (
  payload,
  { props, resetForm, setErrors, setSubmitting }
) => {
  const nowTimestamp = new Date().toISOString();
  const { tasks, template_title, title } = payload;
  const variables = {
    id: undefined,
    qualityControlTemplate: {
      changed: nowTimestamp,
      template_title,
      title
    }
  };
  try {
    const { id } = props.match.params;
    if (id) {
      const oldTasks = props.variables.tasks;
      const newTasks = tasks || [];
      const { toAdd, toDelete, toUpdate } = relatedDiff(oldTasks, newTasks);

      if (toDelete.length) {
        await props.deleteQualityControlTemplateTask({
          variables: {
            where: {
              _or: toDelete.map(instance => {
                return { id: { _eq: instance.id } };
              })
            }
          }
        });
      }
      if (toAdd.length) {
        await props.createQualityControlTemplateTask({
          variables: {
            qualityControlTemplateTasks: toAdd.map(instance => {
              const copy = { ...instance };
              delete copy.file;
              copy.template_id = id;
              return copy;
            })
          }
        });
      }
      if (toUpdate.length) {
        Promise.all(
          toUpdate.map(async instance => {
            const copy = { ...instance };
            delete copy.file;
            return await props.updateQualityControlTemplateTask({
              variables: {
                id: instance.id,
                qualityControlTemplateTask: copy
              }
            });
          })
        );
      }

      variables.id = id;
      const response = await props.updateQualityControlTemplate({
        variables
      });
      /*props.setVariables(
        response.data.update_quality_control_templates.returning[0]
      );*/
      resetForm();
    } else {
      variables.qualityControlTemplate.tasks = {
        data: tasks.map(t => {
          delete t.file;
          return t;
        })
      };
      variables.qualityControlTemplate.created = nowTimestamp;
      const response = await props.createQualityControlTemplate({
        variables
      });
      const createdId =
        response.data.insert_quality_control_templates.returning[0].id;
      props.history.replace(`/settings/quality-control/${createdId}`);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    const errors = e.graphQLErrors.map(error => error.message);
    // eslint-disable-next-line no-console
    console.log(errors);
    setSubmitting(false);
    setErrors({ form: errors });
  }
};

const QualityControlTemplateFormWithGraphQL = compose(
  graphql(CREATE_QUALITY_CONTROL_TEMPLATE, {
    name: "createQualityControlTemplate"
  }),
  graphql(UPDATE_QUALITY_CONTROL_TEMPLATE, {
    name: "updateQualityControlTemplate"
  }),

  graphql(CREATE_QUALITY_CONTROL_TEMPLATE_TASK, {
    name: "createQualityControlTemplateTask"
  }),
  graphql(UPDATE_QUALITY_CONTROL_TEMPLATE_TASK, {
    name: "updateQualityControlTemplateTask"
  }),
  graphql(DELETE_QUALITY_CONTROL_TEMPLATE_TASK, {
    name: "deleteQualityControlTemplateTask"
  }),
  withFormik({
    displayName: "QualityControlTemplateForm",
    handleSubmit,
    mapPropsToValues: ({ variables }) => ({
      ...variables
    }),
    validationSchema: schema
  })
)(QualityControlTemplateEntryWithApollo);

const QualityControlTemplateEntryWithIntl = withRouter(
  injectIntl(QualityControlTemplateFormWithGraphQL)
);

const QualityControlTemplateEntryWrapper = ({
  match: {
    params: { id }
  }
}) => {
  const [variables, setVariables] = useState(null);
  if (variables) {
    return (
      <QualityControlTemplateEntryWithIntl
        id={id}
        setVariables={setVariables}
        variables={variables}
      />
    );
  }
  if (id) {
    return (
      <Query query={GET_QUALITY_CONTROL_TEMPLATE} variables={{ id }}>
        {({ data, error, loading }) => {
          if (loading) {
            return <p>Loading...</p>;
          }
          if (error) {
            return <p>Error :(</p>;
          }

          return (
            <QualityControlTemplateEntryWithIntl
              id={id}
              setVariables={setVariables}
              variables={data.quality_control_templates_by_pk}
            />
          );
        }}
      </Query>
    );
  } else {
    return <QualityControlTemplateEntryWithIntl variables={{ tasks: [] }} />;
  }
};

const QualityControlTemplateEntryWrapperWithRouter = withRouter(
  QualityControlTemplateEntryWrapper
);
export { QualityControlTemplateEntryWrapperWithRouter as QualityControlTemplatesEntry };
