import * as yup from "yup";

import { Alert } from "@material-ui/lab";
import {
  CREATE_CASE_TIME,
  GET_TIME,
  UPDATE_CASE_TIME
} from "../../../graphql/case-time";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { Page } from "../../../components/layout/page";
import { Snackbar } from "@material-ui/core";
import { flowRight as compose } from "lodash";
import { formatUserLabel } from "../../../components/user-select";
import { generateSelectValue } from "../../../utils/form-helpers";
import { graphql } from "@apollo/client/react/hoc";
import { useQuery } from "@apollo/client";
import { withAppContext } from "../../../utils/with-app-context";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import AreYouSure from "../../../components/are-you-sure";
import Header from "../../../components/layout/header";
import React, { useCallback, useState } from "react";
import TimeForm from "./form";

const messages = defineMessages({
  add: {
    defaultMessage: "Tilføj",
    id: "case-time-entry.add"
  },
  createNew: {
    defaultMessage: "Ny tidsregistrering",
    id: "case-time-entry.create-new-time"
  },
  update: {
    defaultMessage: "Redigér tidsregistrering",
    id: "case-time-entry.update-time"
  }
});

function createSchema(checkCase: boolean): any {
  return yup.object().shape({
    case_id: checkCase ? yup.string().required() : undefined,
    date: yup.string().required(),
    description: yup.string().required(),
    time: yup.string().required(),
    user: yup.string().required()
  });
}

interface TimeEntryProps {
  dirty: boolean;
  errors: any;
  handleBlur: () => void;
  handleChange: () => void;
  handleReset: () => void;
  handleSubmit: () => void;
  isSubmitting: boolean;
  match: any;
  resetForm: () => void;
  setFieldValue: () => void;
  submitForm: () => void;
  timeId: string;
  touched: any;
  values: any;
}
function TimeEntry({
  dirty,
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
  match,
  resetForm,
  timeId,
  touched,
  values
}: TimeEntryProps): JSX.Element {
  const showNavigationWarning = useCallback(() => !isSubmitting && dirty, [
    dirty,
    isSubmitting
  ]);
  const { formatMessage } = useIntl();
  const { caseId } = match.params;
  let heading;
  if (timeId) {
    heading = formatMessage(messages.update);
  } else {
    heading = formatMessage(messages.createNew);
  }

  return (
    <Page
      appBar={
        <Header
          dirty={dirty}
          title={heading}
          onResetButton={resetForm}
          onSaveButton={handleSubmit}
        />
      }
    >
      <div
        style={{
          backgroundColor: "#FFF",
          minHeight: "calc(100% - 64px)",
          padding: 20
        }}
      >
        <TimeForm
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          showCaseSelect={!caseId}
          touched={touched}
          values={values}
        />
      </div>
      <AreYouSure when={showNavigationWarning} />
    </Page>
  );
}

const handleSubmit = async (
  payload,
  { props, resetForm, setErrors, setSubmitting }
): Promise<any> => {
  const { case_id, date, description, id, time, user } = payload;
  let action;
  const { caseId, timeId } = props.match.params;
  const variables = {
    caseTime: {
      case_id: caseId || case_id.id,
      date,
      description,
      id,
      time: time.replace(",", "."),
      user_id: user && user.id ? user.id : props.context.state.currentUser.id
    },
    id: undefined
  };

  if (timeId) {
    action = props.update_case_time;
    variables.id = timeId;
  } else {
    action = props.insert_case_time;
  }
  try {
    await action({
      variables
    });
    if (caseId) {
      props.history.replace(`/cases/${caseId}/time`);
    } else {
      props.setVariables({
        ...payload,
        case_id: null,
        description: "",
        saved: true,
        time: ""
      });
      resetForm();
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    const errors = e.graphQLErrors.map(error => error.message);
    // eslint-disable-next-line no-console
    console.log(errors);
    setSubmitting(false);
    setErrors({ form: errors });
  }
};

const EnhancedTimeEntry = compose(
  withAppContext,
  withRouter,
  graphql(CREATE_CASE_TIME, {
    name: "insert_case_time"
  }),
  graphql(UPDATE_CASE_TIME, { name: "update_case_time" }),
  withFormik({
    displayName: "TimeEntry",
    handleSubmit,
    mapPropsToValues: ({ context, variables }) => {
      let { user } = variables;
      const date = variables.date || new Date().toISOString();
      user = generateSelectValue(
        user || context.state.currentUser,
        formatUserLabel
      );

      return {
        ...variables,
        date,
        user
      };
    },
    validationSchema: ({ match }) => {
      return createSchema(!match.params.caseId);
    }
  })
)(TimeEntry);

const TimeEntryWrapper = ({
  match: {
    params: { timeId }
  }
}): JSX.Element => {
  const [variables, setVariables] = useState(null);
  const { data, error, loading } = useQuery(GET_TIME, {
    skip: !timeId,
    variables: { timeId }
  });
  const handleCloseSnackbar = useCallback(() => {
    setVariables({ ...variables, saved: false });
  }, [variables]);
  const EnhancedTimeEntryWithOutType = EnhancedTimeEntry as any;
  if (variables) {
    return (
      <>
        <EnhancedTimeEntryWithOutType
          setVariables={setVariables}
          timeId={timeId}
          variables={variables}
        />
        <Snackbar
          autoHideDuration={6000}
          open={!!variables.saved}
          onClose={handleCloseSnackbar}
        >
          <Alert severity="success" variant="filled">
            <FormattedMessage
              defaultMessage="Registreringen er gemt"
              id="time-entry.saved"
            />
          </Alert>
        </Snackbar>
      </>
    );
  }
  if (timeId) {
    if (loading) {
      return <p>Loading...</p>;
    }
    if (error) {
      return <p>Error :(</p>;
    }
    return (
      <EnhancedTimeEntryWithOutType
        setVariables={setVariables}
        timeId={timeId}
        variables={data.case_time_entries_by_pk}
      />
    );
  } else {
    return (
      <EnhancedTimeEntryWithOutType
        setVariables={setVariables}
        variables={{}}
      />
    );
  }
};

const TimeEntryWrapperWithRouter = withRouter(TimeEntryWrapper);
export { TimeEntryWrapperWithRouter as TimeEntry };
