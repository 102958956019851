import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { ExternalCaseCard } from "./external-case-card";
import { FormattedMessage } from "react-intl";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { UserSelect } from "../../components/user-select";
import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useCallback, useMemo, useState } from "react";
import _ from "lodash";
import gql from "graphql-tag";
import moment from "moment";

const GET_CASE_STATS = gql`
  query multipleStats($date: timestamptz, $startMonth: String) {
    requests_new_task_count(order_by: { count: desc }) {
      count
      case_type {
        name
      }
    }

    requests(
      where: { date: { _gt: $date }, state: { _eq: "external" } }
      order_by: { date: asc }
    ) {
      case_type {
        name
      }
      date
    }

    requests_followup_task_count(order_by: { count: desc }) {
      count
      case_type {
        name
      }
      from_date
      to_date
    }

    requests_per_case_type_per_month(
      where: { year_month: { _gte: $startMonth } }
      order_by: { year_month: asc }
    ) {
      case_type_id
      count
      year_month
      case_type {
        price
      }
    }
  }
`;

const GET_FOLLOW_UP_REQUESTS_FOR_USER = gql`
  query request_followup($userId: uuid = "") {
    requests_followup_task_count_for_user(
      args: { user_id: $userId }
      order_by: { count: desc }
    ) {
      count
      case_type {
        name
      }
      from_date
      to_date
    }
  }
`;

export function Requests(): JSX.Element {
  const startdate = moment()
    .subtract(30, "days")
    .format("YYYY-MM-DD");

  const startMonth = moment()
    .subtract(24, "months")
    .format("YYYY-MM-DD");

  const { data, error, loading } = useQuery(GET_CASE_STATS, {
    variables: {
      date: startdate,
      startMonth
    }
  });

  const [getData, { data: followupData }] = useLazyQuery(
    GET_FOLLOW_UP_REQUESTS_FOR_USER
  );

  const [user, setUser] = useState<{
    id: string;
  } | null>(null);

  const onUserSelected = useCallback(
    (option): void => {
      setUser(option || null);
      getData({
        variables: {
          userId: option?.id
        }
      });
    },
    [getData]
  );

  const requestExternalRows = new Map<string, number>();
  if (!loading && data) {
    if (data.requests?.length) {
      data.requests.forEach(request => {
        requestExternalRows.set(
          request.case_type?.name,
          1 + (requestExternalRows.get(request.case_type?.name) || 0)
        );
      });
    }
  }

  let totalCaseTypeCount = 0;
  let totalExternalCaseTypeCount = 0;
  let totalFollowUpCaseTypeCount = 0;

  const graphData = useMemo(() => {
    const casesPrice = new Map<string, number>();
    const lastPrices = new Map<string, number>();
    if (!loading && data && data.requests_per_case_type_per_month?.length) {
      const cut = moment()
        .subtract(12, "months")
        .format("YYYY-MM");
      data.requests_per_case_type_per_month.forEach(entry => {
        if (!entry.case_type_id) {
          return;
        }
        const month = entry.year_month;
        if (month <= cut) {
          lastPrices.set(month, (lastPrices.get(month) || 0) + entry.count);
        } else {
          casesPrice.set(month, (casesPrice.get(month) || 0) + entry.count);
        }
      });
    }
    return [...casesPrice].map(([month, amount]) => {
      const monthSplit = month.split("-");
      const pastMonth = `${parseInt(monthSplit[0]) - 1}-${monthSplit[1]}`;
      return {
        amount,
        name: month,
        pastAmount: lastPrices.get(pastMonth)
      };
    });
  }, [data, loading]);

  return (
    <div
      style={{
        minHeight: "calc(100% - 64px)",
        padding: 20
      }}
    >
      <Grid container spacing={1}>
        <Grid item lg={3}>
          <h3>Nye forespørgsler</h3>
          <Paper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Formulartype"
                      id="requests.case-type"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Antal"
                      id="requests.count"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading || error ? (
                  <TableRow>
                    <TableCell colSpan={4}>Loader</TableCell>
                  </TableRow>
                ) : (
                  <>
                    {data.requests_new_task_count.map(caseTypeCount => {
                      totalCaseTypeCount += caseTypeCount.count;
                      return (
                        <TableRow key={caseTypeCount.case_type?.name || "none"}>
                          <TableCell>{caseTypeCount.case_type?.name}</TableCell>
                          <TableCell>{caseTypeCount.count}</TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      <TableCell>
                        <FormattedMessage
                          defaultMessage="Total"
                          id="requests.total"
                        />
                      </TableCell>
                      <TableCell>{totalCaseTypeCount}</TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item lg={3}>
          <h3>Sendt til ekst. de sidste 30 dage</h3>
          <Paper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Formulartype"
                      id="requests.case-type"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Antal"
                      id="requests.count"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading || error ? (
                  <TableRow>
                    <TableCell colSpan={4}>Loader</TableCell>
                  </TableRow>
                ) : (
                  <>
                    {_.sortBy(
                      Array.from(requestExternalRows),
                      ([, count]) => -count
                    ).map(([name, count]) => {
                      totalExternalCaseTypeCount += count;
                      return (
                        <TableRow key={name}>
                          <TableCell>{name}</TableCell>
                          <TableCell>{count}</TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      <TableCell>
                        <FormattedMessage
                          defaultMessage="Total"
                          id="requests.total"
                        />
                      </TableCell>
                      <TableCell>{totalExternalCaseTypeCount}</TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item lg={6}>
          <h3>Antal sager der ligger til opfølgning</h3>
          <Paper>
            <div style={{ padding: 10, position: "relative", top: -20 }}>
              <UserSelect
                field={{ name: "user", value: user }}
                form={{ errors: {} }}
                SelectProps={{
                  noTopPadding: true
                }}
                onSelect={onUserSelected}
              />
            </div>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Formulartype"
                      id="requests.case-type"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Antal"
                      id="requests.count"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Første"
                      id="requests.first"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      defaultMessage="Sidste"
                      id="requests.last"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading || error ? (
                  <TableRow>
                    <TableCell colSpan={4}>Loader</TableCell>
                  </TableRow>
                ) : (
                  <>
                    {(followupData &&
                    followupData.requests_followup_task_count_for_user
                      ? followupData.requests_followup_task_count_for_user
                      : data.requests_followup_task_count
                    ).map(caseTypeCount => {
                      totalFollowUpCaseTypeCount += caseTypeCount.count;
                      return (
                        <TableRow key={caseTypeCount.case_type?.name || "none"}>
                          <TableCell>{caseTypeCount.case_type?.name}</TableCell>
                          <TableCell>{caseTypeCount.count}</TableCell>
                          <TableCell>
                            {moment(caseTypeCount.from_date).format(
                              "DD-MM-YYYY"
                            )}
                          </TableCell>
                          <TableCell>
                            {moment(caseTypeCount.to_date).format("DD-MM-YYYY")}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      <TableCell>
                        <FormattedMessage
                          defaultMessage="Total"
                          id="requests.total"
                        />
                      </TableCell>
                      <TableCell>{totalFollowUpCaseTypeCount}</TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item lg={6}>
          <ExternalCaseCard />
        </Grid>
        <Grid item lg={6}>
          <Paper style={{ padding: 10 }}>
            <div style={{ fontSize: 14, fontWeight: 500 }}>
              <FormattedMessage
                defaultMessage="Udvikling på konvertering af formularer til et salg"
                id="case-overview.cases_per_case_type_per_month"
              />
            </div>
            <ResponsiveContainer height={400} width="100%">
              <LineChart data={graphData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />

                <Line dataKey="amount" name="Antal" stroke="green" />
                <Line dataKey="pastAmount" name="Året før" stroke="red" />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
