import {DocumentInstructions} from "../../components/document-instructions";
import {Field} from "formik";
import {FormLabel, Grid} from "@material-ui/core";
import {FormattedMessage, defineMessages, injectIntl} from "react-intl";
import {RichTextEditor} from "../../components/draftjs-formik";
import {Tasks} from "../../components/quality-control-tasks";
import {TextFieldAutosize} from "../../components/resizing-text-area";
import {formikPropTypes} from "../../utils/form-helpers";
import React from "react";
import TextField from "@material-ui/core/TextField";

const messages = defineMessages({
  description: {
    defaultMessage: "Beskrivelse",
    id: "quality-control-templates-form.description"
  },
  documentTitle: {
    defaultMessage: "Dokumenttitel",
    id: "quality-control-templates-form.document-title"
  },
  otherTerms: {
    defaultMessage: "Forbehold",
    id: "quality-control-templates-form.other-terms"
  },
  templateTitle: {
    defaultMessage: "Skabelontitel",
    id: "quality-control-templates-form.template-title"
  }
});

const QualityControlTemplateForm =
  // eslint-disable-next-line react/prop-types
  ({
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    intl,
    values,
  }) => (
    <>
      <form onSubmit={handleSubmit}>
        <div>{errors.form}</div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={errors.template_title}
              label={intl.formatMessage(messages.templateTitle)}
              name="template_title"
              type="text"
              value={values.template_title}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DocumentInstructions />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={errors.title}
              label={intl.formatMessage(messages.documentTitle)}
              name="title"
              type="text"
              value={values.title}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <h4>
          <FormattedMessage
            defaultMessage="Punkter"
            id="quality-control-templates-form.tasks"
          />
        </h4>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field component={Tasks} name="tasks" useCasePhotos={false} />
          </Grid>
        </Grid>
      </form>
    </>
  );
QualityControlTemplateForm.propTypes = {
  ...formikPropTypes
};

const QualityControlTemplateFormWithIntl = injectIntl(
  React.memo(QualityControlTemplateForm)
);

export {QualityControlTemplateFormWithIntl as QualityControlTemplateForm};
