import gql from "graphql-tag";

export const CHECKLISTS = gql`
  query list_case_doc_checklists(
    $orderBy: case_doc_checklists_order_by!
    $where: case_doc_checklists_bool_exp
    $limit: Int
  ) {
    case_doc_checklists(order_by: [$orderBy], where: $where, limit: $limit) {
      changed
      created
      id
      title
      type
    }
  }
`;

export const GET_CHECKLIST = gql`
  query get_checklist($id: uuid!) {
    case_doc_checklists_by_pk(id: $id) {
      changed
      created
      document_date
      revision_date
      id
      share_key
      title
      file_id
      type
      introduction
      closing
      file {
        id
        share_key
        filename
        created
      }
      case {
        address
        builder_id
        builder {
          address
          city
          company
          first_name
          id
          last_name
          mail
          mobile
          phone
          zip_code
        }
        city
        date_of_inspection
        other_address
        responsible_id
        responsible {
          acronym
          first_name
          last_name
          mail
          mobile
          phone
        }
        zip_code
      }
      items(order_by: {order: asc}) {
        comment
        completed
        description
        end_date
        id
        order
        placement
        price
        start_date
        ue
        x
        y
        file_id
        file {
          id
          share_key
          filename
          created
        }
        arrows(order_by: {order: asc}) {
          id
          order
          item_id
          angle
          x
          y
        }
      }
    }
  }
`;

export const CREATE_CHECKLISTS = gql`
  mutation insert_case_doc_checklists(
    $checklist: case_doc_checklists_insert_input!
  ) {
    insert_case_doc_checklists(objects: [$checklist]) {
      returning {
        changed
        created
        document_date
        revision_date
        id
        type
        share_key
        title
        file_id
        introduction
        closing
        file {
          id
          share_key
          filename
          created
        }
        case {
          address
          builder {
            address
            city
            company
            first_name
            id
            last_name
            mail
            mobile
            phone
            zip_code
          }
          city
          date_of_inspection
          other_address
          responsible {
            acronym
            first_name
            last_name
            mail
            mobile
            phone
          }
          zip_code
        }
        items(order_by: {order: asc}) {
          comment
          completed
          description
          end_date
          id
          order
          placement
          price
          start_date
          ue
          x
          y
          file_id
          file {
            id
            share_key
            filename
            created
          }
          arrows(order_by: {order: asc}) {
            id
            order
            item_id
            angle
            item_id
            x
            y
          }
        }
      }
    }
  }
`;

export const UPDATE_CHECKLISTS = gql`
  mutation update_case_doc_checklists(
    $id: uuid
    $checklist: case_doc_checklists_set_input!
  ) {
    update_case_doc_checklists(where: {id: {_eq: $id}}, _set: $checklist) {
      returning {
        changed
        created
        document_date
        revision_date
        type
        id
        share_key
        file_id
        introduction
        closing
        file {
          id
          share_key
          filename
          created
        }
        title
        case {
          address
          builder {
            address
            city
            company
            first_name
            id
            last_name
            mail
            mobile
            phone
            zip_code
          }
          city
          date_of_inspection
          other_address
          responsible {
            acronym
            first_name
            last_name
            mail
            mobile
            phone
          }
          zip_code
        }
        items(order_by: {order: asc}) {
          comment
          completed
          description
          end_date
          id
          order
          placement
          price
          start_date
          ue
          x
          y
          file_id
          file {
            id
            share_key
            filename
            created
          }
          arrows(order_by: {order: asc}) {
            id
            item_id
            order
            angle
            x
            y
          }
        }
      }
    }
  }
`;

export const CREATE_CHECKLISTS_TASK = gql`
  mutation insert_case_doc_checklists_task(
    $items: [checklist_items_insert_input!]!
  ) {
    insert_checklist_items(objects: $items) {
      returning {
        comment
        completed
        description
        end_date
        id
        order
        placement
        price
        start_date
        ue
        x
        y
        file_id
      }
    }
  }
`;

export const UPDATE_CHECKLISTS_TASK = gql`
  mutation update_case_doc_checklists_task(
    $id: uuid
    $item: checklist_items_set_input!
  ) {
    update_checklist_items(where: {id: {_eq: $id}}, _set: $item) {
      returning {
        comment
        completed
        description
        end_date
        id
        order
        placement
        price
        start_date
        ue
        x
        y
        file_id
        file {
          id
          share_key
          filename
          created
        }
      }
    }
  }
`;

export const DELETE_CHECKLISTS_TASK = gql`
  mutation delete_checklist_items($where: checklist_items_bool_exp!) {
    delete_checklist_items(where: $where) {
      affected_rows
    }
  }
`;

export const CREATE_CHECKLIST_TASK_ARROWS = gql`
  mutation insert_checklist_item_arrows(
    $itemArrows: [checklist_item_arrows_insert_input!]!
  ) {
    insert_checklist_item_arrows(objects: $itemArrows) {
      returning {
        id
        order
        item_id
        angle
        x
        y
      }
    }
  }
`;

export const UPDATE_CHECKLIST_TASK_ARROWS = gql`
  mutation update_checklist_item_arrows(
    $id: uuid
    $itemArrow: checklist_item_arrows_set_input!
  ) {
    update_checklist_item_arrows(where: {id: {_eq: $id}}, _set: $itemArrow) {
      returning {
        id
        order
        item_id
        angle
        x
        y
      }
    }
  }
`;

export const DELETE_CHECKLIST_TASK_ARROWS = gql`
  mutation delete_checklist_item_arrows(
    $where: checklist_item_arrows_bool_exp!
  ) {
    delete_checklist_item_arrows(where: $where) {
      affected_rows
    }
  }
`;
