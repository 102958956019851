import { AppContext } from "../components/AppContext";
import { BuildingInspectionCard } from "./statistics/case-control/building-inspection";
import { FormattedMessage } from "react-intl";
import { MoistureTechnologyCard } from "./statistics/case-control/moisture-technology";
import { Page } from "../components/layout/page";
import { useDebouncedCallback } from "use-debounce";
import Header from "../components/layout/header";
import React, { useContext, useState } from "react";
import _ from "lodash";

const buildingInspectionUsers = new Set([1, 2, 3]);
const moistureTechnologyUsers = new Set([4, 5]);

export const MyCases = React.memo(function MyCases(): JSX.Element {
  const {
    state: { currentUser }
  } = useContext(AppContext);
  const [search, setSearch] = useState("");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useDebouncedCallback(setSearch, 300);

  return (
    <div style={{ flexGrow: 1 }}>
      <Page
        appBar={
          <Header
            title={
              <FormattedMessage
                defaultMessage="Mine sager"
                id="my-cases.title"
              />
            }
            onSearchChange={debouncedOnChange}
          />
        }
      >
        {currentUser?.id ? (
          <>
            {buildingInspectionUsers.has(currentUser.employee_number) ? (
              <BuildingInspectionCard
                search={search}
                userId={currentUser?.id}
              />
            ) : moistureTechnologyUsers.has(currentUser.employee_number) ? (
              <MoistureTechnologyCard
                search={search}
                userId={currentUser?.id}
              />
            ) : null}
          </>
        ) : null}
      </Page>
    </div>
  );
});
