import {
  ArrayParam,
  StringParam,
  useQueryParam,
  withDefault
} from "use-query-params";
import { DatePicker } from "@material-ui/pickers";
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { FormattedDate, FormattedMessage } from "react-intl";
import { GET_ABSENCE_TYPES } from "../../graphql/absence";
import { formatUserLabel } from "../../components/user-select";
import { getBusinessDatesCount } from "./absence-list";
import { isMobile } from "../../utils";
import { useQuery } from "@apollo/client";
import React, { useCallback, useMemo } from "react";
import _ from "lodash";
import gql from "graphql-tag";
import moment, { Moment } from "moment";

const GET_ABSENCE = gql`
  query absence($where: absence_bool_exp) {
    absence(where: $where) {
      employee {
        acronym
      }
      notes
      absence_type {
        category
        label
      }
      end
      start
      half_day
    }
  }
`;

const GET_USERS = gql`
  query getUsers($orderBy: users_order_by!, $where: users_bool_exp) {
    users(order_by: [$orderBy], where: $where) {
      id
      mail
      first_name
      last_name
    }
  }
`;

const formatAbsenceTypeLabel = (absenceType): string => absenceType.label;

export const Absence = React.memo(function Absence(): JSX.Element {
  const { data: absenceTypeData } = useQuery(GET_ABSENCE_TYPES, {
    variables: {
      orderBy: { label: "asc" },
      where: { active: { _eq: true } }
    }
  });

  const [absenceTypes, setAbsenceTypes] = useQueryParam(
    "absenceTypes",
    withDefault(
      ArrayParam,
      absenceTypeData?.absence_types
        ? absenceTypeData.absence_types.map(t => t.id)
        : []
    )
  );

  const handleAbsenceTypesChange = useCallback(
    event => {
      setAbsenceTypes(event.target.value);
    },
    [setAbsenceTypes]
  );

  const [start, end] = useMemo(() => {
    return [
      moment()
        .startOf("year")
        .toISOString(),
      moment().toISOString()
    ];
  }, []);

  const [fromDateTimetamp, setFromDateTimetamp] = useQueryParam(
    "fromDate",
    withDefault(StringParam, start)
  );

  const handleFromDateChange = useCallback(
    (date: Moment) => {
      setFromDateTimetamp(date.toISOString());
    },
    [setFromDateTimetamp]
  );

  const [toDateTimestamp, setToDateTimestamp] = useQueryParam(
    "toDate",
    withDefault(StringParam, end)
  );

  const handleToDateChange = useCallback(
    (date: Moment) => {
      setToDateTimestamp(date.toISOString());
    },
    [setToDateTimestamp]
  );

  const { data: usersData } = useQuery(GET_USERS, {
    variables: {
      orderBy: { first_name: "asc" },
      where: { active: { _eq: true } }
    }
  });

  const [user, setUser] = useQueryParam("user", StringParam);

  const handleUserChange = useCallback(
    event => {
      setUser(event.target.value);
    },
    [setUser]
  );

  const where: { [field: string]: any } = {};

  if (fromDateTimetamp) {
    where.end = { _gte: fromDateTimetamp };
  }

  if (toDateTimestamp) {
    where.start = { _lte: toDateTimestamp };
  }

  if (user) {
    where.employee_id = { _eq: user };
  }

  if (absenceTypes.length) {
    where.absence_type_id = { _in: absenceTypes };
  }

  const {
    data,
    error
  }: //loading
  {
    data: {
      absence: {
        absence_type: {
          category: "sick" | "vacation";
          label: string;
        } | null;
        employee: {
          acronym: string;
        } | null;
        end: string;
        half_day: boolean;
        notes: string;
        start: string;
      }[];
    } | null;
    error?: any;
    loading: boolean;
  } = useQuery(GET_ABSENCE, {
    variables: {
      where
    }
  });
  const [sickCount, vacationCount] = useMemo(() => {
    const tempSickCount = new Map<string, number>();
    const tempVacationCount = new Map<string, number>();
    if (!data?.absence) {
      return [tempSickCount, tempVacationCount];
    }

    const { absence } = data;

    const correctedAbsence: {
      absence_type: {
        category: "sick" | "vacation";
        label: string;
      } | null;
      employee: {
        acronym: string;
      } | null;
      end: string;
      half_day: boolean;
      start: string;
    }[] = [];
    absence.forEach(entry => {
      const corrected = { ...entry };
      if (entry.start < fromDateTimetamp) {
        corrected.start = fromDateTimetamp;
      }
      if (entry.end > toDateTimestamp) {
        corrected.end = toDateTimestamp;
      }
      correctedAbsence.push(corrected);

      /*if (corrected.start < thisYearStart && corrected.end > thisYearStart) {
        correctedAbsence.push({
          ...corrected,
          end: moment(corrected.start)
            .endOf("year")
            .toISOString(),
          start: corrected.start
        });
        correctedAbsence.push({
          ...corrected,
          end: corrected.end,
          start: thisYearStart
        });
      } else {
        correctedAbsence.push(corrected);
      }*/
    });

    correctedAbsence.forEach(entry => {
      if (!entry.employee) {
        return;
      }
      const existing =
        entry.absence_type?.category === "sick"
          ? tempSickCount.get(entry.employee?.acronym)
          : tempVacationCount.get(entry.employee?.acronym);

      const entryDays = getBusinessDatesCount(
        new Date(entry.start),
        new Date(entry.end),
        entry.half_day
      );
      if (entry.absence_type?.category === "sick") {
        tempSickCount.set(entry.employee?.acronym, (existing || 0) + entryDays);
      } else {
        tempVacationCount.set(
          entry.employee?.acronym,
          (existing || 0) + entryDays
        );
      }
      /*if (entry.end < thisYearStart) {
        existing[1] += getBusinessDatesCount(
          new Date(entry.start),
          new Date(entry.end)
        );
      } else {
        existing[0] += getBusinessDatesCount(
          new Date(entry.start),
          new Date(entry.end)
        );
      }*/
    });
    return [tempSickCount, tempVacationCount];
  }, [data, data?.absence, fromDateTimetamp, toDateTimestamp]);

  const [sickTotal, vacationTotal] = useMemo(() => {
    return [
      [...sickCount.values()].reduce((prev, current): number => {
        return prev + current;
      }, 0),
      [...vacationCount.values()].reduce((prev, current): number => {
        return prev + current;
      }, 0)
    ];
  }, [sickCount, vacationCount]);

  if (error) {
    return (
      <div>
        Error: <pre>{JSON.stringify(error)}</pre>
      </div>
    );
  }

  return (
    <div
      style={{
        minHeight: "calc(100% - 64px)",
        padding: 20
      }}
    >
      <Paper
        style={{
          padding: 20
        }}
      >
        <Grid container spacing={1}>
          <Grid item lg={4}>
            <DatePicker
              fullWidth
              DialogProps={{ fullScreen: isMobile }}
              format="D. MMMM YYYY"
              label={
                <FormattedMessage
                  defaultMessage="Fra dato"
                  id="absence-report.from-date"
                />
              }
              value={moment(fromDateTimetamp)}
              onChange={handleFromDateChange}
            />
          </Grid>
          <Grid item lg={4}>
            <DatePicker
              fullWidth
              DialogProps={{ fullScreen: isMobile }}
              format="D. MMMM YYYY"
              label={
                <FormattedMessage
                  defaultMessage="Til dato"
                  id="absence-report.from-date"
                />
              }
              value={moment(toDateTimestamp)}
              onChange={handleToDateChange}
            />
          </Grid>
          <Grid item lg={4}>
            <FormControl fullWidth>
              <InputLabel id="absence-types">
                <FormattedMessage
                  defaultMessage="Fraværstyper"
                  id="absence.thisYear"
                />
              </InputLabel>
              <Select
                fullWidth
                multiple
                id="absence-types"
                input={<Input />}
                labelId="absence-types"
                value={absenceTypes}
                onChange={handleAbsenceTypesChange}
              >
                {absenceTypeData?.absence_types?.map(absenceType => (
                  <MenuItem key={absenceType.id} value={absenceType.id}>
                    {formatAbsenceTypeLabel(absenceType)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={4}>
            <FormControl fullWidth>
              <InputLabel id="users">
                <FormattedMessage
                  defaultMessage="Medarbejder"
                  id="absence.employee"
                />
              </InputLabel>
              <Select
                fullWidth
                id="users"
                input={<Input />}
                labelId="users"
                value={user}
                onChange={handleUserChange}
              >
                {usersData?.users?.map(u => (
                  <MenuItem key={u.id} value={u.id}>
                    {formatUserLabel(u)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      <br />
      <Grid container spacing={1}>
        <Grid item xs>
          <Paper style={{ textAlign: "center" }}>
            <h1>Sygedage i perioden</h1>
            <h2>{sickTotal}</h2>
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper style={{ textAlign: "center" }}>
            <h1>Feriedage i perioden</h1>
            <h2>{vacationTotal}</h2>
          </Paper>
        </Grid>
      </Grid>

      <div
        style={{
          paddingTop: 20
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs>
            <Paper>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage
                        defaultMessage="Fraværstype"
                        id="absence.initials"
                      />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage
                        defaultMessage="Dage"
                        id="absence.thisYear"
                      />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage
                        defaultMessage="Fra"
                        id="absence.thisYear"
                      />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage
                        defaultMessage="Til"
                        id="absence.LastYear"
                      />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage
                        defaultMessage="Noter"
                        id="absence.LastYear"
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.orderBy(
                    data?.absence,
                    ({ start: startDate }) => startDate
                  ).map(
                    (
                      {
                        absence_type,
                        end: endDate,
                        half_day,
                        notes,
                        start: startDate
                      },
                      index
                    ) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{absence_type?.label}</TableCell>
                          <TableCell>
                            {getBusinessDatesCount(
                              new Date(startDate),
                              new Date(endDate),
                              half_day
                            )}
                          </TableCell>
                          <TableCell>
                            <FormattedDate value={startDate} />
                          </TableCell>
                          <TableCell>
                            <FormattedDate value={endDate} />
                          </TableCell>
                          <TableCell>{notes}</TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
});
