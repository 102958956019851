import gql from "graphql-tag";

export const QUALITY_CONTROLS = gql`
  query list_case_doc_quality_controls(
    $orderBy: case_doc_quality_controls_order_by!
    $where: case_doc_quality_controls_bool_exp
    $limit: Int
  ) {
    case_doc_quality_controls(
      order_by: [$orderBy]
      where: $where
      limit: $limit
    ) {
      changed
      created
      id
      title
      type
    }
  }
`;

export const GET_QUALITY_CONTROL = gql`
  query get_quality_control($id: uuid!) {
    case_doc_quality_controls_by_pk(id: $id) {
      changed
      created
      document_date
      revision_date
      date_of_inspection
      id
      type
      share_key
      title
      case {
        address
        builder_id
        builder {
          address
          city
          company
          first_name
          id
          last_name
          mail
          mobile
          phone
          zip_code
        }
        city
        date_of_inspection
        other_address
        responsible_id
        responsible {
          acronym
          first_name
          last_name
          mail
          mobile
          phone
        }
        zip_code
      }
      tasks(order_by: {order: asc}) {
        description
        id
        order
        title
        file_id
        file {
          id
          share_key
          filename
          created
        }
        arrows(order_by: {order: asc}) {
          id
          order
          angle
          x
          y
        }
      }
    }
  }
`;

export const CREATE_QUALITY_CONTROLS = gql`
  mutation insert_case_doc_quality_controls(
    $qualityControl: case_doc_quality_controls_insert_input!
  ) {
    insert_case_doc_quality_controls(objects: [$qualityControl]) {
      returning {
        changed
        created
        document_date
        revision_date
        id
        type
        share_key
        title
        date_of_inspection
        case {
          address
          builder {
            address
            city
            company
            first_name
            id
            last_name
            mail
            mobile
            phone
            zip_code
          }
          city
          date_of_inspection
          other_address
          responsible {
            acronym
            first_name
            last_name
            mail
            mobile
            phone
          }
          zip_code
        }
        tasks(order_by: {order: asc}) {
          description
          id
          order
          title
          arrows(order_by: {order: asc}) {
            id
            order
            angle
            x
            y
          }
        }
      }
    }
  }
`;

export const UPDATE_QUALITY_CONTROLS = gql`
  mutation update_case_doc_quality_controls(
    $id: uuid
    $qualityControl: case_doc_quality_controls_set_input!
  ) {
    update_case_doc_quality_controls(
      where: {id: {_eq: $id}}
      _set: $qualityControl
    ) {
      returning {
        changed
        created
        type
        document_date
        revision_date
        id
        share_key
        date_of_inspection
        title
        case {
          address
          builder {
            address
            city
            company
            first_name
            id
            last_name
            mail
            mobile
            phone
            zip_code
          }
          city
          date_of_inspection
          other_address
          responsible {
            acronym
            first_name
            last_name
            mail
            mobile
            phone
          }
          zip_code
        }
        tasks(order_by: {order: asc}) {
          description
          id
          order
          title
          file_id
          file {
            id
            share_key
            filename
            created
          }
          arrows(order_by: {order: asc}) {
            id
            order
            task_id
            angle
            x
            y
          }
        }
      }
    }
  }
`;

export const CREATE_QUALITY_CONTROLS_TASK = gql`
  mutation insert_case_doc_quality_controls_task(
    $qualityControlTasks: [quality_control_tasks_insert_input!]!
  ) {
    insert_quality_control_tasks(objects: $qualityControlTasks) {
      returning {
        description
        id
        order
        title
        file_id
      }
    }
  }
`;

export const UPDATE_QUALITY_CONTROLS_TASK = gql`
  mutation update_case_doc_quality_controls_task(
    $id: uuid
    $qualityControlTask: quality_control_tasks_set_input!
  ) {
    update_quality_control_tasks(
      where: {id: {_eq: $id}}
      _set: $qualityControlTask
    ) {
      returning {
        description
        id
        order
        title
        file_id
        file {
          id
          share_key
          filename
          created
        }
      }
    }
  }
`;

export const DELETE_QUALITY_CONTROLS_TASK = gql`
  mutation delete_quality_control_tasks(
    $where: quality_control_tasks_bool_exp!
  ) {
    delete_quality_control_tasks(where: $where) {
      affected_rows
    }
  }
`;

export const CREATE_QUALITY_CONTROL_TASK_ARROWS = gql`
  mutation insert_quality_control_task_arrows(
    $qualityControlTaskArrows: [quality_control_task_arrows_insert_input!]!
  ) {
    insert_quality_control_task_arrows(objects: $qualityControlTaskArrows) {
      returning {
        id
        order
        task_id
        angle
        x
        y
      }
    }
  }
`;

export const UPDATE_QUALITY_CONTROL_TASK_ARROWS = gql`
  mutation update_quality_control_task_arrows(
    $id: uuid
    $qualityControlTaskArrow: quality_control_task_arrows_set_input!
  ) {
    update_quality_control_task_arrows(
      where: {id: {_eq: $id}}
      _set: $qualityControlTaskArrow
    ) {
      returning {
        id
        order
        task_id
        angle
        x
        y
      }
    }
  }
`;

export const DELETE_QUALITY_CONTROL_TASK_ARROWS = gql`
  mutation delete_quality_control_task_arrows(
    $where: quality_control_task_arrows_bool_exp!
  ) {
    delete_quality_control_task_arrows(where: $where) {
      affected_rows
    }
  }
`;
