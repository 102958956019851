import { AUTH_TOKEN } from "./constants/constants";
import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import "tachyons";
import { ApolloClient, ApolloLink } from "@apollo/client";
import { ApolloProvider } from "@apollo/client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { IntlProvider } from "react-intl";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Route, HashRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { blue } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core";
import App from "./App";
import MomentUtils from "@date-io/moment";
//import da from "react-intl/locale-data/da";
import daLocale from "moment/locale/da";
//import de from "react-intl/locale-data/de";
import { QueryParamProvider } from "use-query-params";
import moment from "moment";
moment.locale("da");
//import messages_da from "./da.json";
//addLocaleData([...da, ...de]);

const httpLink = new HttpLink({
  uri: "/v1/graphql"
});

const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  const authorizationHeader = token ? `Bearer ${token}` : null;
  operation.setContext({
    headers: {
      authorization: authorizationHeader
    }
  });
  return forward(operation);
});

const httpLinkWithAuthToken = middlewareAuthLink.concat(httpLink);

const defaultOptions = {
  query: {
    errorPolicy: "all",
    fetchPolicy: "no-cache"
  }
};

const client = new ApolloClient({
  cache: new InMemoryCache(),
  defaultOptions,
  link: httpLinkWithAuthToken
});

/*const messages = {
  da: messages_da
};*/
const { language } = navigator;

const theme = createMuiTheme({
  palette: {
    primary: blue
  }
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider
        libInstance={moment}
        locale={daLocale}
        utils={MomentUtils}
      >
        <>
          <link
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
            rel="stylesheet"
          />
          <IntlProvider
            defaultLocale="da-DK"
            locale={language}
            /*messages={messages[language.split(/[-_]/)[0]]}*/
          >
            <Router>
              <QueryParamProvider ReactRouterRoute={Route}>
                <App />
              </QueryParamProvider>
            </Router>
          </IntlProvider>
        </>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
