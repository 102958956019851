import { CaseSelect } from "../case-select";
import { DateTimePickerField } from "../date-time-fields";
import { Field } from "formik";
import { FormControl, Grid, InputLabel, Select } from "@material-ui/core";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { TextFieldAutosize } from "../resizing-text-area";
import { UserSelect } from "../user-select";
import { formikPropTypes } from "../../utils/form-helpers";
import PropTypes from "prop-types";
import React, { useState } from "react";

const messages = defineMessages({
  case: {
    defaultMessage: "Sag",
    id: "reminders-form.case"
  },
  date: {
    defaultMessage: "Dato og tid",
    id: "reminders-form.date"
  },
  message: {
    defaultMessage: "Besked",
    id: "reminders-form.message"
  },
  recipients: {
    defaultMessage: "Modtagere",
    id: "reminders-form.recipients"
  }
});

const RemindersForm = React.memo(
  // eslint-disable-next-line react/prop-types
  ({
    caseInfo,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    intl,
    values
  }) => {
    let form;
    const [dateTimePickerOpen, setDateTimePickerOpen] = useState(!values.date);

    return (
      <div
        style={{
          backgroundColor: "#FFF",
          minHeight: "calc(100% - 64px)"
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                fullWidth
                component={DateTimePickerField}
                label={intl.formatMessage(messages.date)}
                name="date"
                open={dateTimePickerOpen}
                onClose={() => {
                  setDateTimePickerOpen(false);
                }}
                onOpen={() => {
                  setDateTimePickerOpen(true);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                isMulti
                component={UserSelect}
                label={intl.formatMessage(messages.recipients)}
                name="recipients"
              />
            </Grid>
            {!caseInfo ? (
              <Grid item xs={12}>
                <Field
                  noInitial
                  component={CaseSelect}
                  label={intl.formatMessage(messages.case)}
                  name="case_id"
                />
              </Grid>
            ) : null}
          </Grid>
          {!values.id ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="age-native-simple">
                    <FormattedMessage
                      defaultMessage="Vælg besked"
                      id="reminders-form.quick-message"
                    />
                  </InputLabel>
                  <Select
                    native
                    inputProps={{
                      name: "message"
                    }}
                    onChange={handleChange}
                  >
                    <option value="" />
                    <option value="Ring til byggeleder vedr. støbetilsyn, hvornår betonbilen kommer">
                      Tilsyn 01 - Fundament
                    </option>
                    <option value="Ring til byggeleder vedr. dato for tilsyn med installationer i gulv inden der støbes">
                      Tilsyn 02 - Terrændæk
                    </option>
                    <option value="Ring til byggeleder vedr. dato for tilsyn med tagkonstruktion, hvornår der er forankring klar til tilsyn">
                      Tilsyn 03 - Tagkonstruktion
                    </option>
                    <option value="Ring til byggeleder vedr. dato for tilsyn med opstart af murerarbejder, hvilken dato mureren starter">
                      Tilsyn 04 - Murearbejde
                    </option>
                    <option value="Ring til byggeleder vedr. dato for tilsyn med dampspærre, hvilken da tømreren starter">
                      Tilsyn 05 - Dampspærre
                    </option>
                    <option value="Ring til byggeleder vedr. dato for tilsyn med vådrumssikring, hvilken dag flisemureren starter">
                      Tilsyn 06 - Vådrum
                    </option>
                    <option value="Ring til byggeleder vedr. dato for førgennemgang ">
                      Tilsyn 07 - Førgennemgang &amp; Aflevering
                    </option>
                    <option value="Ring til byggeleder vedr. dato for afleveringsforretning">
                      Tilsyn 08 - Aflevering
                    </option>
                    <option value="Send tilbud til kunde på 1 års gennemgang">
                      Tilbud: 1 års gennemgang
                    </option>
                    <option value="Send tilbud til kunde på 3 års gennemgang">
                      Tilbud: på 3 års gennemgang
                    </option>
                    <option value="Send tilbud til kunde på 5 års gennemgang">
                      Tilbud: på 5 års gennemgang
                    </option>
                    <option value="Der er udført tilsyn klar til fakturering på denne sag, og du må gerne fakturer kunden for 2 tilsyn">
                      Send tilsyn til fakturering
                    </option>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          ) : null}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldAutosize
                fullWidth
                multiline
                error={errors.message}
                label={intl.formatMessage(messages.message)}
                name="message"
                type="text"
                value={values.message ?? ""}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
);
RemindersForm.displayName = "RemindersForm";
RemindersForm.propTypes = {
  ...formikPropTypes,
  caseInfo: PropTypes.object
};
const RemindersFormWithIntl = injectIntl(RemindersForm);

export { RemindersFormWithIntl as RemindersForm };
