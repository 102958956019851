import { AppBar, Link, Tab, Tabs } from "@material-ui/core";
import { CaseControl } from "./case-control";
import { CaseOverview } from "./case-overview";
import { CasesCreated } from "./cases-created";
import { ClosedCaseReminders } from "./closed-case-reminders";
import { FormattedMessage } from "react-intl";
import { GanttPage } from "./gantt";
import { Page } from "../../components/layout/page";
import { Requests } from "./requests";
import { Route, Switch, useParams } from "react-router";
import Header from "../../components/layout/header";
import React from "react";

export const Statistics = React.memo(function Statistics(): JSX.Element {
  const { tab } = useParams<{ tab: "case-overview" }>();
  const currentTab = tab || "case-overview";

  return (
    <div style={{ flexGrow: 1 }}>
      <Page
        appBar={
          <Header
            title={
              <FormattedMessage
                defaultMessage="Statistik"
                id="statistics.title"
              />
            }
          />
        }
      >
        <AppBar position="static">
          <Tabs scrollButtons="auto" value={currentTab} variant="scrollable">
            <Tab
              component={Link}
              href="#/statistics/case-overview"
              label={
                <FormattedMessage
                  defaultMessage="Sagsoverblik"
                  id="statistics.case-overview"
                />
              }
              value="case-overview"
            />
            <Tab
              component={Link}
              href="#/statistics/requests"
              label={
                <FormattedMessage
                  defaultMessage="Forespørgsler"
                  id="statistics.requests"
                />
              }
              value="requests"
            />
            <Tab
              component={Link}
              href="#/statistics/cases-created"
              label={
                <FormattedMessage
                  defaultMessage="Oprettede sager"
                  id="statistics.cases-created"
                />
              }
              value="cases-created"
            />
            <Tab
              component={Link}
              href="#/statistics/case-control"
              label={
                <FormattedMessage
                  defaultMessage="Sagskontrol"
                  id="statistics.case-control"
                />
              }
              value="case-control"
            />
            <Tab
              component={Link}
              href="#/statistics/reminders"
              label={
                <FormattedMessage
                  defaultMessage="1 og 5 års påmindelser"
                  id="statistics.reminders"
                />
              }
              value="reminders"
            />
            <Tab
              component={Link}
              href="#/statistics/gantt"
              label={
                <FormattedMessage
                  defaultMessage="Gantt"
                  id="statistics.gantt"
                />
              }
              value="gantt"
            />
          </Tabs>
        </AppBar>
        <Switch>
          <Route exact component={CaseOverview} path="/statistics" />
          <Route
            exact
            component={CaseOverview}
            path="/statistics/case-overview"
          />
          <Route exact component={Requests} path="/statistics/requests" />
          <Route
            exact
            component={CasesCreated}
            path="/statistics/cases-created"
          />
          <Route
            exact
            component={CaseControl}
            path="/statistics/case-control"
          />
          <Route
            exact
            component={ClosedCaseReminders}
            path="/statistics/reminders"
          />
          <Route exact component={GanttPage} path="/statistics/gantt" />
        </Switch>
      </Page>
    </div>
  );
});
