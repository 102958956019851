/* eslint-disable react/display-name */
import { Contact } from "../../../types/types";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import { MUTable } from "../../../components/mu-table";
import { useGetCaseClosedQuery } from "../../../generated/graphql";
import { useHistory, useParams } from "react-router";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import React, { useCallback } from "react";
import gql from "graphql-tag";

const TIME_LIST = gql`
  query get_case_time($case_id: uuid) {
    case_time_entries(where: { case_id: { _eq: $case_id } }) {
      date
      description
      id
      time
      user {
        acronym
      }
    }
  }
`;

export const GET_CASE_CLOSED = gql`
  query getCaseClosed($case_id: uuid!) {
    cases_by_pk(id: $case_id) {
      closed
    }
  }
`;

export function TimeList(): JSX.Element {
  const history = useHistory();
  const { caseId } = useParams<{ caseId: string }>();
  const handleRowClick = useCallback(
    (contact: Contact): void => {
      history.push(`/cases/${caseId}/time/${contact.id}`);
    },
    [caseId, history]
  );
  const handleFabClick = useCallback((): void => {
    history.push(`/cases/${caseId}/time/create`);
  }, [caseId, history]);

  const { data: caseClosedData } = useGetCaseClosedQuery({
    variables: {
      case_id: caseId
    }
  });

  const MUTableWithoutType: any = MUTable;
  return (
    <>
      <MUTableWithoutType
        columns={[
          {
            field: "date",
            render: entry =>
              entry.date ? <FormattedDate value={entry.date} /> : null,
            style: { width: 100 },
            title: (
              <FormattedMessage defaultMessage="Dato" id="time-list.date" />
            )
          },
          {
            field: "user",
            render: entry => entry.user.acronym,
            style: { width: 100 },
            title: (
              <FormattedMessage
                defaultMessage="Initialer"
                id="time-list.initials"
              />
            )
          },
          {
            field: "description",
            title: (
              <FormattedMessage
                defaultMessage="Beskrivelse"
                id="time-list.description"
              />
            )
          },
          {
            field: "time",
            render: entry => (
              <FormattedNumber
                maximumFractionDigits={2}
                minimumFractionDigits={2}
                value={entry.time}
              />
            ),
            title: (
              <FormattedMessage defaultMessage="Timer" id="time-list.hours" />
            )
          }
        ]}
        dataSourceName="case_time_entries"
        extraVariables={{ case_id: caseId }}
        gql={TIME_LIST}
        limit={100}
        orderBy="date"
        onRowClick={handleRowClick}
      />
      {caseClosedData?.cases_by_pk && !caseClosedData.cases_by_pk.closed ? (
        <Fab
          color="primary"
          style={{ bottom: 5 * 2, position: "absolute", right: 5 * 2 }}
          onClick={handleFabClick}
        >
          <AddIcon />
        </Fab>
      ) : null}
    </>
  );
}
