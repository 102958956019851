import * as yup from "yup";
import {
  CREATE_CASE_TYPE,
  GET_CASE_TYPE,
  GET_CASE_TYPE_CHECK,
  GET_CASE_TYPE_LIST,
  UPDATE_CASE_TYPE
} from "../../graphql/case-types";
import { CaseTypeForm } from "./case-types-form";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { Page } from "../../components/layout/page";
import { Query } from "@apollo/client/react/components";
import { Typography } from "@material-ui/core";
import { flowRight as compose } from "lodash";
import { graphql, withApollo } from "@apollo/client/react/hoc";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import AreYouSure from "../../components/are-you-sure";
import Header from "../../components/layout/header";
import PropTypes from "prop-types";
import React, { PureComponent, useState } from "react";

const messages = defineMessages({
  add: {
    defaultMessage: "Tilføj",
    id: "caseType-entry.add"
  },
  createNew: {
    defaultMessage: "Opret ny sagstype",
    id: "caseType-entry.create-new-caseType"
  },
  update: {
    defaultMessage: "Redigér sagstype",
    id: "caseType-entry.update-caseType"
  }
});

const schema = yup.object().shape({
  color: yup.string().required(),
  icon: yup.string().required(),
  name: yup.string().required()
});

class CaseTypeEntry extends PureComponent {
  static propTypes = {
    caseTypeId: PropTypes.string.isRequired,
    client: PropTypes.object.isRequired,
    dirty: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    resetForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired
  };
  state = {
    caseTypeExists: false
  };
  handleSubmitForm = async () => {
    const {
      caseTypeId,
      client,
      submitForm,
      values: { color, icon, name }
    } = this.props;
    if (!!caseTypeId || !name || !color || !icon) {
      submitForm();
      return;
    }
    const existing = await client.query({
      fetchPolicy: "network-only",
      query: GET_CASE_TYPE_CHECK,
      variables: {
        color,
        icon,
        name
      }
    });
    if (!existing.data.case_types.length) {
      submitForm();
      this.setState({ caseTypeExists: false });
    } else {
      this.setState({ caseTypeExists: true });
    }
  };

  handleResetForm = () => {
    this.props.handleReset();
  };

  showNavigationWarning = () => {
    return !this.props.isSubmitting && this.props.dirty;
  };

  render() {
    const {
      caseTypeId,
      dirty,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      intl: { formatMessage },
      isSubmitting,
      touched,
      values
    } = this.props;

    let heading;
    if (caseTypeId) {
      heading = formatMessage(messages.update);
    } else {
      heading = formatMessage(messages.createNew);
    }

    return (
      <Page
        appBar={
          <Header
            dirty={dirty}
            title={heading}
            onResetButton={this.handleResetForm}
            onSaveButton={this.handleSubmitForm}
          />
        }
      >
        <div
          style={{
            backgroundColor: "#FFF",
            minHeight: "calc(100% - 64px)",
            padding: 20
          }}
        >
          {this.state.caseTypeExists ? (
            <Typography color="error" variant="subtitle1">
              <FormattedMessage
                defaultMessage="En sagstype med det angivne navn eller farve og symbolkombination eksisterer allerede"
                id="caseType-entry.create-new-caseType"
              />
            </Typography>
          ) : null}
          <CaseTypeForm
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            touched={touched}
            values={values}
          />
        </div>
        <AreYouSure when={this.showNavigationWarning} />
      </Page>
    );
  }
}

const CaseTypeEntryWithApollo = withApollo(CaseTypeEntry);

const handleSubmit = async (
  payload,
  { props, resetForm, setErrors, setSubmitting }
) => {
  const { color, icon, id, name, price } = payload;
  let action;
  const variables = {
    caseType: {
      color,
      icon,
      id,
      name,
      price: price.replace(",", ".")
    },
    id: undefined
  };

  const { caseTypeId } = props.match.params;
  if (caseTypeId) {
    action = props.update_caseType;
    variables.id = caseTypeId;
  } else {
    action = props.insert_caseType;
  }
  try {
    const response = await action({
      variables
    });
    if (caseTypeId) {
      //props.setVariables(response.data.update_case_types.returning[0]);
      resetForm();
    } else {
      const createdCaseTypeId = response.data.insert_case_types.returning[0].id;
      props.history.replace(`/settings/case-types/${createdCaseTypeId}`);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    const errors = e.graphQLErrors.map(error => error.message);
    // eslint-disable-next-line no-console
    console.log(errors);
    setSubmitting(false);
    setErrors({ form: errors });
  }
};

const CaseTypeFormWithGraphQL = compose(
  graphql(CREATE_CASE_TYPE, {
    name: "insert_caseType",
    options: {
      refetchQueries: [
        {
          query: GET_CASE_TYPE_LIST,
          variables: { orderBy: { first_name: "asc" } }
        }
      ]
    }
  }),
  graphql(UPDATE_CASE_TYPE, { name: "update_caseType" }),
  withFormik({
    displayName: "CaseTypeForm",
    handleSubmit,
    mapPropsToValues: ({ variables }) => ({
      ...variables
    }),
    validationSchema: schema
  })
)(CaseTypeEntryWithApollo);

const CaseTypeEntryWithIntl = withRouter(injectIntl(CaseTypeFormWithGraphQL));

const CaseTypeEntryWrapper = ({
  match: {
    params: { caseTypeId }
  }
}) => {
  const [variables, setVariables] = useState(null);
  if (variables) {
    return (
      <CaseTypeEntryWithIntl
        caseTypeId={caseTypeId}
        setVariables={setVariables}
        variables={variables}
      />
    );
  }
  if (caseTypeId) {
    return (
      <Query query={GET_CASE_TYPE} variables={{ caseTypeId }}>
        {({ data, error, loading }) => {
          if (loading) {
            return <p>Loading...</p>;
          }
          if (error) {
            return <p>Error :(</p>;
          }

          return (
            <CaseTypeEntryWithIntl
              caseTypeId={caseTypeId}
              setVariables={setVariables}
              variables={data.case_types[0]}
            />
          );
        }}
      </Query>
    );
  } else {
    return <CaseTypeEntryWithIntl variables={{}} />;
  }
};

const CaseTypeEntryWrapperWithRouter = withRouter(CaseTypeEntryWrapper);
export { CaseTypeEntryWrapperWithRouter as CaseTypesEntry };
