import { Button, Grid, Paper } from "@material-ui/core";
import { ChecklistMarker, Items } from "../../../../components/checklist-items";
import { DatePickerField } from "../../../../components/date-time-fields";
import { Field } from "formik";
import { FormattedMessage, injectIntl } from "react-intl";
import { RichTextEditor } from "../../../../components/draftjs-formik";
import { SelectCasePhotoDialog } from "../../../../components/select-case-photo-dialog";
import { fileInstanceToURL } from "../../../settings/photos/list";
import { formikPropTypes } from "../../../../utils/form-helpers";
import ImageIcon from "@material-ui/icons/Image";
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import TextField from "@material-ui/core/TextField";

const ChecklistForm =
  // eslint-disable-next-line react/prop-types
  ({
    caseId,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    values
  }) => {
    const [state, setstate] = useState({ casePhotoDialogOpen: false });
    const handleToggleCasePhotoDialog = useCallback(() => {
      setstate({ ...state, casePhotoDialogOpen: !state.casePhotoDialogOpen });
    }, [state]);

    const [imageSize, setImageSize] = useState();
    const onImgLoaded = useCallback(
      ({ target: img }) => {
        setImageSize({
          height: img.height,
          width: img.width
        });
      },
      [setImageSize]
    );

    const imageRef = useRef(null);

    useLayoutEffect(() => {
      function orientationChange() {
        if (imageRef.current) {
          setTimeout(()=>onImgLoaded({target: imageRef.current}), 1000)
        }
      }
      window.addEventListener('orientationchange', orientationChange);
      return () => window.removeEventListener('orientationchange', orientationChange);
    }, [onImgLoaded]);

    const handleCasePhotoDialogSelected = useCallback(
      files => {
        handleToggleCasePhotoDialog();
        if (!files) {
          setFieldValue("file_id", null);
          setFieldValue("file", null);
          return
        }
        
        const file = files[0];
        setFieldValue("file_id", file.id);
        setFieldValue("file", file);
      },
      [setFieldValue, handleToggleCasePhotoDialog]
    );
    return (
      <>
        <form onSubmit={handleSubmit}>
          <div>{errors.form}</div>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <Field
                fullWidth
                component={DatePickerField}
                label={
                  <FormattedMessage
                    defaultMessage="Dokument dato"
                    id="quality-controls-form.document-date"
                  />
                }
                name="document_date"
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <Field
                fullWidth
                component={DatePickerField}
                label={
                  <FormattedMessage
                    defaultMessage="Rev. dato"
                    id="quality-controls-form.revision_date"
                  />
                }
                name="revision_date"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                error={errors.title}
                label={
                  <FormattedMessage
                    defaultMessage="Titel"
                    id="quality-controls-form.document-title"
                  />
                }
                name="title"
                type="text"
                value={values.title}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <h4>
            <FormattedMessage
              defaultMessage="Punkter"
              id="quality-controls-form.items"
            />
          </h4>

          <Paper square>
            {values.file && values.file.id ? (
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%"
                }}
              >
                <div style={{ position: "relative" }}>
                  {imageSize &&
                    values.items &&
                    values.items.map((marker, index) => {
                      if (!marker.x || !marker.y) {
                        return null;
                      }
                      const number = marker.order + 1;

                      const realX = marker.x * imageSize.width;
                      const realY = marker.y * imageSize.height;
                      return (
                        <ChecklistMarker
                          key={`${index}-${marker.x}-${marker.y}`}
                          active={number === marker.number}
                          left={realX}
                          number={number}
                          top={realY}
                        />
                      );
                    })}
                  <img
                    ref={imageRef}
                    src={fileInstanceToURL(values, 1000)}
                    width="100%"
                    onLoad={onImgLoaded}
                  />
                </div>
              </div>
            ) : null}
            <Button fullWidth onClick={handleToggleCasePhotoDialog}>
              <ImageIcon />
            </Button>
          </Paper>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field caseId={caseId} component={Items} file={values.file} name="items" />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RichTextEditor
                label={
                  <FormattedMessage
                    defaultMessage="Introduktion"
                    id="quality-controls-form.document-introduction"
                  />
                }
                name="introduction"
                value={values.introduction}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RichTextEditor
                label={
                  <FormattedMessage
                    defaultMessage="Afslutning"
                    id="quality-controls-form.document-closing"
                  />
                }
                name="closing"
                value={values.closing}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </form>
        <SelectCasePhotoDialog
          open={state.casePhotoDialogOpen}
          onCancel={handleToggleCasePhotoDialog}
          onFileSelected={handleCasePhotoDialogSelected}
        />
      </>
    );
  };
ChecklistForm.propTypes = {
  ...formikPropTypes
};

const ChecklistFormWithIntl = injectIntl(React.memo(ChecklistForm));

export { ChecklistFormWithIntl as ChecklistForm };
