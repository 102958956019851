import * as yup from "yup";
import { CaseFileForm } from "./form";
import { GET_CASE_FILE, UPDATE_CASE_FILE } from "../../../graphql/cases";
import { Page } from "../../../components/layout/page";
import { Query } from "@apollo/client/react/components";
import { flowRight as compose } from "lodash";
import { defineMessages, injectIntl } from "react-intl";
import { graphql, withApollo } from "@apollo/client/react/hoc";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import AreYouSure from "../../../components/are-you-sure";
import Header from "../../../components/layout/header";
import PropTypes from "prop-types";
import React, { PureComponent, useState } from "react";

const messages = defineMessages({
  update: {
    defaultMessage: "Redigér navn",
    id: "file-entry.update-file"
  }
});

const schema = yup.object().shape({
  title: yup.string().required()
});

class FileEntry extends PureComponent {
  static propTypes = {
    client: PropTypes.object.isRequired,
    dirty: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    fileId: PropTypes.string.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    resetForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired
  };

  handleResetForm = () => {
    this.props.handleReset();
  };

  showNavigationWarning = () => {
    return !this.props.isSubmitting && this.props.dirty;
  };

  render() {
    const {
      dirty,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      intl: { formatMessage },
      isSubmitting,
      setFieldValue,
      touched,
      values
    } = this.props;

    const heading = formatMessage(messages.update);

    return (
      <Page
        appBar={
          <Header
            dirty={dirty}
            title={heading}
            onResetButton={this.handleResetForm}
            onSaveButton={handleSubmit}
          />
        }
      >
        <div
          style={{
            backgroundColor: "#FFF",
            minHeight: "calc(100% - 64px)",
            padding: 20
          }}
        >
          <CaseFileForm
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            touched={touched}
            values={values}
          />
        </div>
        <AreYouSure when={this.showNavigationWarning} />
      </Page>
    );
  }
}

const FileEntryWithApollo = withApollo(FileEntry);

const handleSubmit = async (
  payload,
  { props, resetForm, setErrors, setSubmitting }
) => {
  const { id, title } = payload;
  const { fileId } = props.match.params;
  const variables = {
    case_file: {
      id,
      title
    },
    id: fileId
  };

  try {
    const response = await props.updateFile({
      variables
    });
    //props.setVariables(response.data.update_case_files.returning[0]);
    resetForm();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    const errors = e.graphQLErrors.map(error => error.message);
    // eslint-disable-next-line no-console
    console.log(errors);
    setSubmitting(false);
    setErrors({ form: errors });
  }
};

const FileFormWithGraphQL = compose(
  graphql(UPDATE_CASE_FILE, { name: "updateFile" }),
  withFormik({
    displayName: "FileForm",
    handleSubmit,
    mapPropsToValues: ({ variables }) => ({
      ...variables,
      rotate: 0
    }),
    validationSchema: schema
  })
)(FileEntryWithApollo);

const FileEntryWithIntl = withRouter(injectIntl(FileFormWithGraphQL));

const FileEntryWrapper = ({
  match: {
    params: { fileId }
  }
}) => {
  const [variables, setVariables] = useState(null);
  if (variables) {
    return (
      <FileEntryWithIntl
        fileId={fileId}
        setVariables={setVariables}
        variables={variables}
      />
    );
  }
  return (
    <Query query={GET_CASE_FILE} variables={{ fileId }}>
      {({ data, error, loading }) => {
        if (loading) {
          return <p>Loading...</p>;
        }
        if (error) {
          return <p>Error :(</p>;
        }

        return (
          <FileEntryWithIntl
            fileId={fileId}
            setVariables={setVariables}
            variables={data.case_files[0]}
          />
        );
      }}
    </Query>
  );
};

const FileEntryWrapperWithRouter = withRouter(FileEntryWrapper);
export { FileEntryWrapperWithRouter as FilesEntry };
