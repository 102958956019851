import {DialogBase} from "./dialog";
import {DialogContent} from "@material-ui/core";
import {Windows} from "mdi-material-ui";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";

/**
 * A replacement component for the react-router `Prompt`.
 * Allows for more flexible dialogs.
 *
 * @example
 * <NavigationPrompt when={this.props.isDirty}>
 *   {(isOpen, onConfirm, onCancel) => (
 *     <Modal show={isOpen}>
 *       <div>
 *         <p>Do you really want to leave?</p>
 *         <button onClick={onCancel}>Cancel</button>
 *         <button onClick={onConfirm}>Ok</button>
 *       </div>
 *     </Modal>
 *   )}
 * </NavigationPrompt>
 */
class NavigationPrompt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {nextLocation: null, openModal: false};
    this.onCancel = this.onCancel.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  componentDidMount() {
    this.unblock = this.props.history.block(nextLocation => {
      const when = this.props.when(nextLocation);
      if (when) {
        this.setState({
          nextLocation,
          openModal: true
        });
      }
      return !when;
    });
  }

  componentWillUnmount() {
    this.unblock();
  }

  onCancel() {
    this.setState({nextLocation: null, openModal: false});
  }

  onConfirm() {
    this.navigateToNextLocation();
  }

  navigateToNextLocation() {
    this.unblock();
    const next = this.state.nextLocation.pathname;
    this.setState({nextLocation: null, openModal: false});
    this.props.history.push(next)
  }

  render() {
    return (
      <div>
        {this.props.children(
          this.state.openModal,
          this.onConfirm,
          this.onCancel
        )}
      </div>
    );
  }
}

NavigationPrompt.propTypes = {
  children: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  when: PropTypes.func.isRequired
};

const EnhancedNavigationPromt = withRouter(NavigationPrompt);

// eslint-disable-next-line react/display-name
export default props => (
  <EnhancedNavigationPromt when={props.when}>
    {(isOpen, onConfirm, onCancel) => (
      <DialogBase
        open={isOpen}
        title="Forkast ændringer?"
        onCancel={onCancel}
        onOk={onConfirm}
      >
        <DialogContent>
          Du har ikke gemt dine ændringer. Vil du slette dem og gå videre?
        </DialogContent>
      </DialogBase>
    )}
  </EnhancedNavigationPromt>
);
