import { emphasize } from "@material-ui/core/styles/colorManipulator";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import React from "react";

import { FormControl, InputLabel } from "@material-ui/core";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import AsyncSelect from "react-select/async";
import Select, { components } from "react-select";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

const messages = defineMessages({
  choose: {
    defaultMessage: "Vælg",
    id: "autocomplete-select.choose"
  }
});
const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
    overflow: "hidden"
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  chipLabel: {
    alignItems: "center",
    cursor: "inherit",
    display: "initial",
    overflow: "hidden",
    paddingLeft: 12,
    textOverflow: "ellipsis",
    userSelect: "none",
    whiteSpace: "nowrap"
  },
  divider: {
    height: theme.spacing(2)
  },
  input: {
    display: "flex",
    minHeight: 40,
    padding: 0
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  paper: {
    left: 0,
    marginTop: theme.spacing(1),
    position: "absolute",
    right: 0,
    zIndex: 1
  },
  placeholder: {
    bottom: 6,
    fontSize: 16,
    left: 2,
    position: "absolute"
  },
  root: {
    flexGrow: 1,
    height: 250
  },
  singleValue: {
    fontSize: 16
  },
  valueContainer: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    overflow: "hidden"
  }
}));

function NoOptionsMessage(props) {
  return (
    <Typography
      className={props.selectProps.classes.noOptionsMessage}
      color="textSecondary"
      {...props.innerProps}
    >
      <FormattedMessage
        defaultMessage="Skriv for at søge..."
        id="auto-select.noOptions"
      />
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};

function inputComponent(props) {
  return <div ref={props.inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  valueCount: PropTypes.number.isRequired
};

function Control(props) {
  const {
    selectProps: { classes, TextFieldProps }
  } = props;
  return (
    <FormControl className={classes.formControl} style={{ width: "100%" }}>
      <InputLabel
        htmlFor={TextFieldProps.InputLabelProps.htmlFor.id}
        style={{
          transform: "translate(0, 1.5px) scale(0.75)",
          transformOrigin: "top left"
        }}
      >
        {TextFieldProps.label}
      </InputLabel>
      <div style={{ marginTop: "16px" }}>
        <components.Control {...props} />
      </div>
    </FormControl>
  );
}

Control.propTypes = {
  children: PropTypes.node,
  getValue: PropTypes.func.isRequired,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired
};

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      component="div"
      selected={props.isFocused}
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool
};

function Placeholder(props) {
  return (
    <Typography
      className={props.selectProps.classes.placeholder}
      color="textSecondary"
      {...props.innerProps}
      style={props.selectProps.error ? { color: "red" } : undefined}
    >
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
};

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired
};

function MultiValue(props) {
  return (
    <Chip
      classes={{ label: props.selectProps.classes.chipLabel }}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      deleteIcon={<CancelIcon {...props.removeProps} />}
      label={props.children}
      // eslint-disable-next-line react/jsx-handler-names
      tabIndex={-1}
      onDelete={props.removeProps.onClick}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired
};

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object
};

const customComponents = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

export const AutocompleteSelect = React.memo(
  ({
    autoFocus,
    defaultOptions,
    field: { name, value },
    form: { errors, setFieldValue },
    id,
    inputStyle,
    isClearable,
    isMulti,
    label,
    onLoadOptions,
    onSelect,
    options,
    placeholder
  }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const selectStyles = {
      control: styles => ({
        ...styles,
        border: 0,
        borderBottomColor: "rgba(0, 0, 0, 0.42)",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderRadius: "none",
        boxShadow: "none",
        ...inputStyle
      }),
      input: base => ({
        ...base,
        "& input": {
          font: "inherit"
        },
        color: theme.palette.text.primary,
        margin: 0,
        padding: 0
      })
    };
    const currentError = errors[name];

    const finalPlaceholder = placeholder
      ? placeholder
      : formatMessage(messages.choose);
    if (onLoadOptions) {
      return (
        <AsyncSelect
          cacheOptions
          autoFocus={autoFocus}
          classes={classes}
          components={customComponents}
          defaultOptions={defaultOptions}
          error={currentError}
          // eslint-disable-next-line react/jsx-handler-names
          inputId={id}
          isClearable={isClearable}
          isMulti={isMulti}
          loadOptions={onLoadOptions}
          name={name}
          options={options}
          pageSize={30}
          placeholder={finalPlaceholder}
          styles={selectStyles}
          TextFieldProps={{
            error: !!currentError,
            InputLabelProps: {
              htmlFor: { id },
              shrink: true
            },
            label
          }}
          value={value}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={option => {
            if (setFieldValue) {
              setFieldValue(name, option);
            }
            if (onSelect) {
              onSelect(option);
            }
          }}
        />
      );
    }
    return (
      <Select
        autoFocus={autoFocus}
        classes={classes}
        components={customComponents}
        error={currentError}
        inputId={id}
        isClearable={isClearable}
        isMulti={isMulti}
        name={name}
        options={options}
        pageSize={30}
        placeholder={finalPlaceholder}
        styles={selectStyles}
        TextFieldProps={{
          InputLabelProps: {
            htmlFor: { id },
            shrink: true
          },
          label
        }}
        value={value}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={option => {
          if (setFieldValue) {
            setFieldValue(name, option);
          }
          if (onSelect) {
            onSelect(option);
          }
        }}
      />
    );
  }
);
AutocompleteSelect.displayName = "AutocompleteSelect";

AutocompleteSelect.defaultProps = {
  isClearable: true
};

AutocompleteSelect.propTypes = {
  autoFocus: PropTypes.any,
  defaultOptions: PropTypes.any,
  field: PropTypes.any,
  form: PropTypes.any,
  id: PropTypes.any,
  inputStyle: PropTypes.any,
  isClearable: PropTypes.any,
  isMulti: PropTypes.any,
  label: PropTypes.any,
  onLoadOptions: PropTypes.any,
  onSelect: PropTypes.any,
  options: PropTypes.any,
  placeholder: PropTypes.any
};
