import { DatePickerField } from "../../../../components/date-time-fields";
import { Field } from "formik";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { Grid } from "@material-ui/core";
import { TextFieldAutosize } from "../../../../components/resizing-text-area";
import { formikPropTypes } from "../../../../utils/form-helpers";
import { withApollo } from "@apollo/client/react/hoc";
import React, { PureComponent } from "react";
import TextField from "@material-ui/core/TextField";

const messages = defineMessages({
  text: {
    defaultMessage: "Tekst",
    id: "economy-form.text"
  },
  title: {
    defaultMessage: "Titel",
    id: "economy-form.title"
  }
});

class CaseEconomyForm extends PureComponent {
  static propTypes = {
    ...formikPropTypes
  };

  render() {
    const {
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      intl,
      values
    } = this.props;

    return (
      <>
        <form onSubmit={handleSubmit}>
          <div>{errors.form}</div>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <Field
                fullWidth
                component={DatePickerField}
                label={
                  <FormattedMessage
                    defaultMessage="Dokument dato"
                    id="notes-form.document_date"
                  />
                }
                name="document_date"
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <Field
                fullWidth
                component={DatePickerField}
                label={
                  <FormattedMessage
                    defaultMessage="Rev. dato"
                    id="notes-form.revision_date"
                  />
                }
                name="revision_date"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                error={errors.title}
                label={intl.formatMessage(messages.title)}
                maxLength="300"
                name="title"
                type="text"
                value={values.title}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldAutosize
                fullWidth
                multiline
                error={errors.text}
                label={intl.formatMessage(messages.text)}
                minRows={2}
                name="text"
                type="text"
                value={values.text}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </form>
      </>
    );
  }
}

const CaseEconomyFormWithIntl = injectIntl(withApollo(CaseEconomyForm));

export { CaseEconomyFormWithIntl as CaseEconomyForm };
