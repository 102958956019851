/* eslint-disable react/jsx-no-bind */
import { FormattedMessage } from "react-intl";
import { Formik } from "formik";
import {
  GetConstructionPlanQuery,
  useGetConstructionPlanLazyQuery
} from "../../../generated/graphql";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from "@material-ui/core";
import { Page } from "../../../components/layout/page";
import { useApolloClient } from "@apollo/client";
import { useHistory, useParams } from "react-router";
import AreYouSure from "../../../components/are-you-sure";
import Bowser from "bowser";
import Header from "../../../components/layout/header";
import MuFab from "../../../components/fab";
import React, { useCallback, useEffect } from "react";
import _ from "lodash";
import gql from "graphql-tag";
const browser = Bowser.getParser(window.navigator.userAgent);
const isMobile = browser.getPlatformType(true) !== "desktop";

const CELL_STYLE = isMobile ? { paddingRight: 0 } : {};

export const GET_CONSTRUCTION_PLAN = gql`
  query getConstructionPlan($id: uuid) {
    construction_plans(where: { id: { _eq: $id } }) {
      id
      name
      entries(order_by: { days_after: asc }) {
        days_after
        id
        name
        construction_plan_id
      }
    }
  }
`;

export const CREATE_CONSTRUCTION_PLAN = gql`
  mutation insertConstructionPlan(
    $constructionPlan: construction_plans_insert_input!
  ) {
    insert_construction_plans(objects: [$constructionPlan]) {
      returning {
        id
        name
      }
    }
  }
`;

export const UPDATE_CONSTRUCTION_PLAN = gql`
  mutation updateConstructionPlan(
    $id: uuid
    $constructionPlan: construction_plans_set_input!
  ) {
    update_construction_plans(
      where: { id: { _eq: $id } }
      _set: $constructionPlan
    ) {
      returning {
        id
        name
      }
    }
  }
`;
type Entry = GetConstructionPlanQuery["construction_plans"][number]["entries"][number];

const Row = React.memo(function Row({ entry }: { entry: Entry }): JSX.Element {
  const history = useHistory();
  const handleRowClick = useCallback(() => {
    history.push(
      `/settings/construction-plans/${entry.construction_plan_id}/${entry.id}`
    );
  }, [entry.construction_plan_id, entry.id, history]);
  return (
    <TableRow style={{ cursor: "pointer" }} onClick={handleRowClick}>
      <TableCell>{entry.days_after}</TableCell>
      <TableCell>{entry.name}</TableCell>
    </TableRow>
  );
});

export const ConstructionPlanEntry = function ConstructionPlanEntry(): JSX.Element {
  const { id } = useParams<{ id?: string; planId: string }>();
  const history = useHistory();

  const client = useApolloClient();

  const [getData, { data }] = useGetConstructionPlanLazyQuery();

  useEffect(() => {
    getData({
      variables: {
        id
      }
    });
  }, [getData, id]);

  const handleFabClick = useCallback(() => {
    history.push(`/settings/construction-plans/${id}/create-entry`);
  }, [history, id]);

  const handleSubmit = useCallback(
    async (
      values: { name: string },
      { resetForm, setErrors, setSubmitting }
    ) => {
      const { name } = values;
      const variables = {
        constructionPlan: {
          name
        },
        id
      };
      try {
        let response: { data?: any } | null;

        if (id) {
          response = await client.mutate({
            mutation: UPDATE_CONSTRUCTION_PLAN,
            variables
          });
        } else {
          response = await client.mutate({
            mutation: CREATE_CONSTRUCTION_PLAN,
            variables
          });
        }

        if (id) {
          resetForm();
        } else {
          const createdConstructionPlanId =
            response.data?.insert_construction_plans.returning[0].id;
          history.replace(
            `/settings/construction-plans/${createdConstructionPlanId}`
          );
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        const errors = e.graphQLErrors?.map(error => error.message);
        // eslint-disable-next-line no-console
        console.log(errors);
        setSubmitting(false);
        setErrors({ form: errors });
      }
    },
    [client, history, id]
  );
  if (id && !data?.construction_plans) {
    return <div>loading</div>;
  }

  return (
    <Formik
      initialValues={
        data?.construction_plans
          ? data.construction_plans[0]
          : {
              name: ""
            }
      }
      onSubmit={handleSubmit}
    >
      {({
        dirty,
        errors,
        handleBlur,
        handleChange,
        handleReset,
        isSubmitting,
        submitForm,
        values
      }) => {
        const showNavigationWarning = (): boolean => {
          return !isSubmitting && dirty;
        };
        return (
          <Page
            appBar={
              <Header
                dirty={dirty}
                title={
                  <FormattedMessage
                    defaultMessage="Byggeplan"
                    id="construction-plan.title"
                  />
                }
                onResetButton={handleReset}
                onSaveButton={submitForm}
              />
            }
          >
            <div
              style={{
                backgroundColor: "#FFF",
                minHeight: "calc(100% - 64px)",
                padding: 20
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    error={!!errors.name}
                    label={
                      <FormattedMessage
                        defaultMessage="Navn"
                        id="construction-plans.name"
                      />
                    }
                    name="name"
                    type="text"
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </div>
            {id && data?.construction_plans ? (
              <Table
                size={isMobile ? "small" : "medium"}
                style={{
                  backgroundColor: "#FFF",
                  width: "100%"
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        color: "#000",
                        fontSize: 14,
                        width: 300,
                        ...CELL_STYLE
                      }}
                    >
                      <FormattedMessage
                        defaultMessage="Dage efter første indspektion"
                        id="construction-plans.days-after-inspection"
                      />
                    </TableCell>
                    <TableCell
                      style={{ color: "#000", fontSize: 14, ...CELL_STYLE }}
                    >
                      <FormattedMessage
                        defaultMessage="Navn"
                        id="construction-plans.name"
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.sortBy(
                    data.construction_plans[0].entries,
                    entry => entry.days_after
                  ).map(entry => {
                    return <Row key={entry.id} entry={entry} />;
                  })}
                </TableBody>
              </Table>
            ) : null}
            <AreYouSure when={showNavigationWarning} />

            <MuFab onClick={handleFabClick} />
          </Page>
        );
      }}
    </Formik>
  );
};
