import {CircularProgress, IconButton} from "@material-ui/core";
import {withAppContext} from "../utils/with-app-context";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import MuFab from "./fab";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import PropTypes from "prop-types";
import React, {PureComponent} from "react";

const fileListToArray = list => {
  const array = [];
  for (let i = 0; i < list.length; i++) {
    array.push(list.item(i));
  }
  return array;
};

class Upload extends PureComponent {
  static propTypes = {
    accept: PropTypes.string,
    capture: PropTypes.bool,
    context: PropTypes.object.isRequired,
    floating: PropTypes.bool,
    localSpinner: PropTypes.bool,
    multiple: PropTypes.bool,
    onError: PropTypes.func,
    onFileUploaded: PropTypes.func.isRequired,
    onUploadStart: PropTypes.func,
    row: PropTypes.number
  };
  static defaultProps = {
    capture: false,
    floating: true,
    localSpinner: false,
    multiple: true,
  };

  state = {
    totalCount: 0,
    uploading: false,
    uploadProgress: {},
  };
  fileInputRef = React.createRef();

  handleAddFileFabClick = () => {
    if (this.state.uploading) {
      return;
    }
    this.fileInputRef.current.click();
  };
  handleFilesAdded = evt => {
    if (this.state.uploading) {
      return;
    }
    const {files} = evt.target;
    const array = fileListToArray(files);
    if (this.props.onUploadStart) {
      this.props.onUploadStart();
    }
    this.uploadFiles(array);
  };

  uploadFiles = async files => {
    this.setState({
      totalCount: files.length,
      uploading: true,
      uploadProgress: {},
    });
    this.props.context.setLoading(`0 af ${files.length}`);

    const promises = [];
    files.forEach(file => {
      promises.push(this.sendRequest(file));
    });

    try {
      await Promise.all(promises);
      this.setState({
        totalCount: 0,
        uploading: false,
      });
    } catch (e) {
      if (this.props.onError) {
        this.props.onError(e);
      }
      this.setState({
        totalCount: 0,
        uploading: false,
      });
    }
    this.props.context.setLoading(false);
  };

  sendRequest = file => {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      const token = localStorage.getItem("token");
      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          const copy = this.state.uploadProgress;
          copy[file.name] = {
            percentage: (event.loaded / event.total) * 100,
            state: "pending",
          };
          //console.log(copy[file.name].percentage)
          const uploadCount = Object.values(copy).reduce(
            (totalCount, progress) =>
              progress.percentage === 100 ? totalCount + 1 : totalCount,
            0,
          );
          if (!this.props.localSpinner) {

            this.props.context.setLoading(
              `${uploadCount} af ${this.state.totalCount}`,
              );
            }

          this.setState({uploadProgress: copy});
        }
      });

      req.upload.addEventListener("load", () => {
        const copy = {...this.state.uploadProgress};
        copy[file.name] = {percentage: 100, state: "done"};
        this.setState({uploadProgress: copy});
        resolve(req.response);
      });

      req.upload.addEventListener("error", () => {
        const copy = {...this.state.uploadProgress};
        copy[file.name] = {percentage: 0, state: "error"};
        this.setState({uploadProgress: copy});
        reject(req.response);
      });

      req.addEventListener("readystatechange", () => {
        if (req.readyState === 4) {
          if (req.status === 200) {
            const response = JSON.parse(req.responseText);
            this.props.onFileUploaded(response);
          } else {
            if(this.props.onError) {
              this.props.onError();
            }
            reject(req.response);
          }
        }
      });

      const formData = new FormData();
      formData.append("files", file, file.name);
      req.open("POST", "/files/upload");
      req.setRequestHeader("authorization", `Bearer ${token}`);
      req.send(formData);
    });
  };

  render() {
    const icon = this.props.capture ? <PhotoCameraIcon /> : <LibraryAddIcon />;
    ;

    const showSpinner = this.props.localSpinner && this.state.uploading;
    return (
      <>
        {this.props.floating ? (
          <MuFab
            icon={icon}
            row={this.props.row}
            onClick={this.handleAddFileFabClick}
          />
        ) : (
          showSpinner ? <CircularProgress size={24}/> : <IconButton onClick={this.handleAddFileFabClick}>{icon}</IconButton>
        )}
        <div style={{display: "none"}}>
          {this.props.capture ? (
            <input
              ref={this.fileInputRef}
              capture
              accept={this.props.accept}
              className="FileInput"
              multiple={this.props.multiple}
              type="file"
              onChange={this.handleFilesAdded}
            />
          ) : (
            <input
              ref={this.fileInputRef}
              accept={this.props.accept}
              className="FileInput"
              multiple={this.props.multiple}
              type="file"
              onChange={this.handleFilesAdded}
            />
          )}
        </div>
      </>
    );
  }
}

const enhanced = withAppContext(Upload);
export {enhanced as Upload};
