import { AppContext } from "../AppContext";
import { IconButton } from "@material-ui/core";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import React, { Component } from "react";

const styles = {
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  root: {
    flexGrow: 1
  }
};

class MenuButton extends Component {
  render() {
    return (
      <AppContext.Consumer>
        {context =>
          context.state.isMobile ? (
            <IconButton
              aria-label="Menu"
              className={this.props.classes.menuButton}
              color="inherit"
              onClick={context.toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          ) : null
        }
      </AppContext.Consumer>
    );
  }
}

export default withStyles(styles)(withRouter(MenuButton));
