import { AutocompleteSelect } from "./autocomplete-select";
import { GET_CASE_TYPE_LIST } from "../graphql/case-types";
import { createWhereObject } from "../utils/form-helpers";
import { withApollo } from "@apollo/client/react/hoc";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export const formatCaseTypeLabel = caseType => caseType.name;

class CaseTypeSelect extends PureComponent {
  static propTypes = {
    client: PropTypes.object.isRequired
  };
  state = {
    defaultOptions: []
  };
  async componentDidMount() {
    const response = await this.caseTypeQuery();
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      defaultOptions: response.data.case_types_aggregate.nodes.map(
        caseType => ({
          ...caseType,
          label: formatCaseTypeLabel(caseType),
          value: caseType.id
        })
      )
    });
  }
  caseTypeQuery = where => {
    return this.props.client.query({
      query: GET_CASE_TYPE_LIST,
      variables: {
        limit: 50,
        orderBy: { name: "asc" },
        where
      }
    });
  };
  handleLoadCaseTypes = async (input, callback) => {
    const where = input ? createWhereObject(["name"], input) : undefined;
    const response = await this.caseTypeQuery(where);

    callback(
      response.data.case_types_aggregate.nodes.map(caseType => ({
        label: formatCaseTypeLabel(caseType),
        value: caseType.id,
        ...caseType
      }))
    );
  };

  render() {
    return (
      <AutocompleteSelect
        defaultOptions={this.state.defaultOptions}
        onLoadOptions={this.handleLoadCaseTypes}
        {...this.props}
      />
    );
  }
}

const withHOC = withApollo(CaseTypeSelect);

export { withHOC as CaseTypeSelect };
