import * as yup from "yup";
import {
  DELETE_STANDARD_PHOTO,
  GET_STANDARD_PHOTO,
  UPDATE_STANDARD_PHOTO
} from "../../../graphql/standard-photos";
import { Page } from "../../../components/layout/page";
import { Query } from "@apollo/client/react/components";
import { StandardPhotoForm } from "./form";
import { flowRight as compose } from "lodash";
import { defineMessages, injectIntl } from "react-intl";
import { graphql, withApollo } from "@apollo/client/react/hoc";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import AreYouSure from "../../../components/are-you-sure";
import Header from "../../../components/layout/header";
import PropTypes from "prop-types";
import React, { PureComponent, useState } from "react";

const messages = defineMessages({
  update: {
    defaultMessage: "Redigér foto",
    id: "photo-entry.update-photo"
  }
});

const schema = yup.object().shape({
  title: yup.string().required()
});

class PhotoEntry extends PureComponent {
  static propTypes = {
    client: PropTypes.object.isRequired,
    deletePhoto: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    photoId: PropTypes.string.isRequired,
    resetForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired
  };

  handleResetForm = () => {
    this.props.handleReset();
  };

  showNavigationWarning = () => {
    return !this.props.isSubmitting && this.props.dirty;
  };

  doDelete = async () => {
    const { deletePhoto, history, values } = this.props;
    await deletePhoto({ photoId: values.id });
    history.push("/settings/photos");
  };

  handleDeleteClick = () => {
    this.doDelete();
  };

  render() {
    const {
      dirty,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      intl: { formatMessage },
      isSubmitting,
      setFieldValue,
      touched,
      values
    } = this.props;

    const heading = formatMessage(messages.update);

    return (
      <Page
        appBar={
          <Header
            dirty={dirty}
            title={heading}
            onDeleteClick={this.handleDeleteClick}
            onResetButton={this.handleResetForm}
            onSaveButton={handleSubmit}
          />
        }
      >
        <div
          style={{
            backgroundColor: "#FFF",
            minHeight: "calc(100% - 64px)",
            padding: 20
          }}
        >
          <StandardPhotoForm
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            touched={touched}
            values={values}
          />
        </div>
        <AreYouSure when={this.showNavigationWarning} />
      </Page>
    );
  }
}

const PhotoEntryWithApollo = withApollo(PhotoEntry);

const handleSubmit = async (
  payload,
  { props, resetForm, setErrors, setSubmitting }
) => {
  const { category, id, title } = payload;
  const { photoId } = props.match.params;
  const variables = {
    id: photoId,
    standard_photo: {
      category,
      id,
      title
    }
  };

  try {
    const response = await props.updatePhoto({
      variables
    });
    //props.setVariables(response.data.update_standard_photos.returning[0]);
    resetForm();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    const errors = e.graphQLErrors.map(error => error.message);
    // eslint-disable-next-line no-console
    console.log(errors);
    setSubmitting(false);
    setErrors({ form: errors });
  }
};

const PhotoFormWithGraphQL = compose(
  graphql(UPDATE_STANDARD_PHOTO, { name: "updatePhoto" }),
  graphql(DELETE_STANDARD_PHOTO, { name: "deletePhoto" }),
  withFormik({
    displayName: "PhotoForm",
    handleSubmit,
    mapPropsToValues: ({ variables }) => ({
      ...variables,
      category: variables.category || "",
      rotate: 0
    }),
    validationSchema: schema
  })
)(PhotoEntryWithApollo);

const PhotoEntryWithIntl = withRouter(injectIntl(PhotoFormWithGraphQL));

const PhotoEntryWrapper = ({
  match: {
    params: { photoId }
  }
}) => {
  const [variables, setVariables] = useState(null);
  if (variables) {
    return (
      <PhotoEntryWithIntl
        photoId={photoId}
        setVariables={setVariables}
        variables={variables}
      />
    );
  }
  return (
    <Query query={GET_STANDARD_PHOTO} variables={{ photoId }}>
      {({ data, error, loading }) => {
        if (loading) {
          return <p>Loading...</p>;
        }
        if (error) {
          return <p>Error :(</p>;
        }

        return (
          <PhotoEntryWithIntl
            photoId={photoId}
            setVariables={setVariables}
            variables={data.standard_photos[0]}
          />
        );
      }}
    </Query>
  );
};

const PhotoEntryWrapperWithRouter = withRouter(PhotoEntryWrapper);
export { PhotoEntryWrapperWithRouter as StandardPhotosEntry };
