/* eslint-disable react/display-name */
import { ArrowDialog } from "./arrow-dialog";
import { CREATE_CASE_PHOTO } from "../graphql/cases";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Paper
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { MarkerDialog } from "./checklist-marker-dialog";
import { SelectCasePhotoDialog } from "./select-case-photo-dialog";
import { TextFieldAutosize } from "./resizing-text-area";
import { Upload } from "./upload";
import { fileInstanceToURL } from "../pages/case/photos/list";
import { useApolloClient } from "@apollo/client";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Bowser from "bowser";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import ImageIcon from "@material-ui/icons/Image";
import LocationIcon from "@material-ui/icons/LocationOn";
import PropTypes from "prop-types";
import React, { Component, useCallback, useState } from "react";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import uuid from "uuid/v4";
const browser = Bowser.getParser(window.navigator.userAgent);
const isMobile = browser.getPlatformType(true) !== "desktop";

const messages = defineMessages({
  description: {
    defaultMessage: "Beskrivelse",
    id: "request-list.description"
  },
  price: {
    defaultMessage: "Pris",
    id: "request-list.price"
  },
  title: {
    defaultMessage: "Titel",
    id: "request-list.title"
  }
});

const EMPTY_ITEM = {
  comment: "",
  completed: false,
  description: "",
  end_date: null,
  file_id: null,
  id: null,
  placement: "",
  price: "",
  start_date: null,
  ue: "",
  x: null,
  y: null
};

const Divider = React.memo(({ index, onClick }) => {
  const handleAddItem = useCallback(() => {
    onClick({ ...EMPTY_ITEM, order: index });
  }, [onClick, index]);
  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div style={{ flex: 1, paddingTop: 14 }}>
        <hr />
      </div>
      <div style={{ width: 48 }}>
        <IconButton aria-label="Save" onClick={handleAddItem}>
          <AddIcon />
        </IconButton>
      </div>
      <div style={{ flex: 1, paddingTop: 14 }}>
        <hr />
      </div>
    </div>
  );
});
Divider.propTypes = {
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

export const ChecklistMarker = React.memo(({ active, left, number, top }) => {
  return (
    <div
      style={{
        backgroundColor: active ? "rgb(33, 150, 243)" : "#fff",
        border: "3px solid #000",
        borderRadius: "18px",
        fontSize: "16px",
        left: left - 20 || "0",
        margin: "6px",
        minHeight: "18px",
        minWidth: "28px",
        padding: "2px",
        position: "absolute",
        textAlign: "center",
        top: top - 20 || "0",
        userSelect: "none",
        zIndex: "1"
        //margin: 0
      }}
    >
      {number}
    </div>
  );
});
ChecklistMarker.propTypes = {
  active: PropTypes.bool,
  left: PropTypes.number,
  number: PropTypes.number.isRequired,
  top: PropTypes.number
};

const itemComperator = (a, b) => a.order - b.order;
const ItemCards = React.memo(
  ({ caseId, dirty, floorplan, items, onDelete, onSave }) => {
    if (!items || items.length === 0) {
      return null;
    }
    return items.sort(itemComperator).map((row, index) => (
      <Draggable key={row.id} draggableId={row.id} index={index}>
        {provided => (
          <div
            ref={provided.innerRef}
            style={{ paddingBottom: 10 }}
            {...provided.draggableProps}
          >
            <ItemForm
              caseId={caseId}
              dirty={dirty}
              dragHandleProps={provided.dragHandleProps}
              floorplan={floorplan}
              initialValues={row}
              markers={items.map(t => {
                return { number: t.order + 1, x: t.x, y: t.y };
              })}
              number={row.order + 1}
              onDelete={onDelete}
              onSave={onSave}
            />
            <Divider index={index} onClick={onSave} />
          </div>
        )}
      </Draggable>
    ));
  }
);

ItemCards.propTypes = {
  items: PropTypes.array,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

const ItemForm = React.memo(
  ({
    caseId,
    dirty,
    dragHandleProps,
    floorplan,
    initialValues,
    markers,
    number,
    onDelete,
    onSave
  }) => {
    const [values, setValues] = useState(initialValues);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [caseDialogOpen, setCaseDialogOpen] = useState(false);
    const [arrowDialogOpen, setArrowDialogOpen] = useState(false);
    const client = useApolloClient();
    const { formatMessage } = useIntl();
    const handleAddItem = useCallback(() => {
      onSave(values);
      setValues(initialValues);
    }, [values, onSave, setValues, initialValues]);

    React.useEffect(() => {
      if (!values.id || dirty) {
        return;
      }
      setValues(initialValues);
    }, [dirty, initialValues, values.id]);

    const debouncedSave = useCallback(
      _.debounce(newValues => {
        onSave(newValues);
      }, 500),
      [onSave]
    );

    const handleRemoveItem = useCallback(() => {
      onDelete(values.id);
    }, [values, onDelete]);

    const handleChange = useCallback(
      event => {
        const newValues = { ...values };
        newValues[event.target.name] =
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value;
        setValues(newValues);
        if (newValues.id) {
          debouncedSave(newValues);
        }
      },
      [debouncedSave, values]
    );

    const handleOpenDialog = useCallback(() => {
      setDialogOpen(true);
    }, []);

    const handleCloseDialog = useCallback(() => {
      setDialogOpen(false);
    }, []);

    const handleOpenCaseDialog = useCallback(() => {
      setCaseDialogOpen(true);
    }, []);

    const handleCloseCaseDialog = useCallback(() => {
      setCaseDialogOpen(false);
    }, []);

    const handleOpenArrowDialog = useCallback(() => {
      setArrowDialogOpen(true);
    }, []);

    const handleCloseArrowDialog = useCallback(() => {
      setArrowDialogOpen(false);
    }, []);

    const handleUpload = useCallback(
      files => {
        const file = files[0];

        client.mutate({
          mutation: CREATE_CASE_PHOTO,
          variables: {
            case_photos: [
              {
                case_id: caseId,
                file_id: file.id,
                title: file.filename
              }
            ]
          }
        });

        const copy = {
          ...values,
          file: {
            filename: file.filename,
            id: file.id,
            share_key: file.share_key
          },
          file_id: file.id
        };
        setValues(copy);
        if (copy.id) {
          onSave(copy);
        }
      },
      [caseId, client, onSave, values]
    );

    const handleCasePhotoSelected = useCallback(
      files => {
        handleCloseDialog();
        handleCloseCaseDialog();
        let copy;
        if (!files) {
          copy = {
            ...values,
            file: null,
            file_id: null
          };
        } else {
          const file = files[0];
          copy = {
            ...values,
            file: {
              filename: file.filename,
              id: file.id,
              share_key: file.share_key
            },
            file_id: file.id
          };
        }
        setValues(copy);
        if (copy.id) {
          onSave(copy);
        }
      },
      [handleCloseCaseDialog, handleCloseDialog, onSave, values]
    );

    const handleArrowsDialogOk = useCallback(
      arrows => {
        handleCloseArrowDialog();
        handleChange({
          target: {
            name: "arrows",
            value: arrows
          }
        });
      },
      [handleChange, handleCloseArrowDialog]
    );

    const handleMarkersDialogOk = useCallback(
      marker => {
        handleCloseDialog();
        const copy = { ...values, x: marker.x, y: marker.y };
        setValues(copy);
        if (copy.id) {
          onSave(copy);
        }
      },
      [handleCloseDialog, onSave, values]
    );

    return (
      <Paper square style={{ marginTop: 20, padding: 10 }}>
        <div style={{ display: "flex", position: "relative", width: "100%" }}>
          {values.id ? (
            <>
              <ChecklistMarker number={number} />
              <div
                style={{
                  alignItems: "center",

                  display: "flex",
                  justifyContent: "center",
                  width: 48
                }}
              >
                <IconButton
                  aria-label="Save"
                  style={{ alignSelf: "flex-end" }}
                  onClick={handleRemoveItem}
                >
                  <DeleteIcon color="error" />
                </IconButton>
              </div>
            </>
          ) : null}
          <div style={{ flex: 1 }}>
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <Grid item>
                  <TextField
                    fullWidth
                    label={
                      <FormattedMessage
                        defaultMessage="Placering"
                        id="checklist-items.placement"
                      />
                    }
                    name="placement"
                    tabIndex="1"
                    type="text"
                    value={values.placement}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                  <TextFieldAutosize
                    fullWidth
                    multiline
                    label={formatMessage(messages.description)}
                    name="description"
                    tabIndex="2"
                    type="text"
                    value={values.description}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                  <TextFieldAutosize
                    fullWidth
                    multiline
                    label={
                      <FormattedMessage
                        defaultMessage="Kommentar"
                        id="checklist-items.comment"
                      />
                    }
                    name="comment"
                    tabIndex="3"
                    type="text"
                    value={values.comment}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid item sm={3} xs={12}>
                <TextField
                  fullWidth
                  label={
                    <FormattedMessage
                      defaultMessage="UE'er"
                      id="checklist-items.ue"
                    />
                  }
                  name="ue"
                  type="text"
                  value={values.ue}
                  onChange={handleChange}
                />

                <DatePicker
                  clearable
                  fullWidth
                  DialogProps={{ fullScreen: isMobile }}
                  format="Do MMMM YYYY"
                  label={
                    <FormattedMessage
                      defaultMessage="Startdato"
                      id="checklist-items.start-date"
                    />
                  }
                  name="start_date"
                  value={values.start_date}
                  onChange={selectedDate =>
                    handleChange({
                      target: {
                        name: "start_date",
                        value: selectedDate
                      }
                    })
                  }
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.completed}
                      name="completed"
                      onChange={handleChange}
                    />
                  }
                  label={
                    <FormattedMessage
                      defaultMessage="Udført"
                      id="checklist-items.completed"
                    />
                  }
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <TextField
                  fullWidth
                  label={
                    <FormattedMessage
                      defaultMessage="Pris"
                      id="checklist-items.price"
                    />
                  }
                  name="price"
                  type="number"
                  value={values.price}
                  onChange={handleChange}
                />

                <DatePicker
                  clearable
                  fullWidth
                  DialogProps={{ fullScreen: isMobile }}
                  format="Do MMMM YYYY"
                  label={
                    <FormattedMessage
                      defaultMessage="Slutdato"
                      id="checklist-items.end-date"
                    />
                  }
                  name="end_date"
                  value={values.end_date}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={selectedDate =>
                    handleChange({
                      target: {
                        name: "end_date",
                        value: selectedDate
                      }
                    })
                  }
                />
              </Grid>
            </Grid>
          </div>
          {values.file && values.file.id ? (
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                width: 200
              }}
            >
              <div style={{ position: "relative" }}>
                {values.arrows && values.arrows.length ? (
                  <ArrowBackIcon
                    style={{ color: "red", position: "absolute" }}
                  />
                ) : null}
                <img src={fileInstanceToURL(values, 552)} width="200" />
              </div>
            </div>
          ) : null}
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              height: 250,
              justifyContent: "space-evenly",
              width: 48
            }}
          >
            {!values.id ? (
              <IconButton aria-label="Save" onClick={handleAddItem}>
                <DoneIcon />
              </IconButton>
            ) : null}
            <IconButton
              aria-label="Open Case Dialog"
              onClick={handleOpenCaseDialog}
            >
              <ImageIcon />
            </IconButton>
            <Upload
              capture
              localSpinner
              accept="Image/*"
              floating={false}
              onFileUploaded={handleUpload}
            />
            <IconButton
              aria-label="Open Dialog"
              disabled={!values.file}
              onClick={handleOpenArrowDialog}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton
              aria-label="Open Dialog"
              disabled={!floorplan}
              onClick={handleOpenDialog}
            >
              <LocationIcon />
            </IconButton>
            {values.id ? (
              <div {...dragHandleProps}>
                <DragHandleIcon />
              </div>
            ) : null}
          </div>
        </div>
        <SelectCasePhotoDialog
          open={caseDialogOpen}
          onCancel={handleCloseCaseDialog}
          onFileSelected={handleCasePhotoSelected}
        />
        {dialogOpen && floorplan && floorplan.id ? (
          <MarkerDialog
            file={floorplan}
            markers={markers}
            number={number}
            open={dialogOpen}
            onCancel={handleCloseDialog}
            onOk={handleMarkersDialogOk}
          />
        ) : null}
        {values.file && values.file.id ? (
          <ArrowDialog
            arrows={values.arrows}
            file={values.file}
            open={arrowDialogOpen}
            relatedName="item_id"
            taskId={values.id}
            onCancel={handleCloseArrowDialog}
            onOk={handleArrowsDialogOk}
          />
        ) : null}
      </Paper>
    );
  },
  (prevProps, nextProps) => {
    return (
      _.isEqual(prevProps.initialValues, nextProps.initialValues) &&
      _.isEqual(nextProps.markers, prevProps.markers) &&
      prevProps.number === nextProps.number
    );
  }
);

ItemForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  onSave: PropTypes.func.isRequired
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export class Items extends Component {
  static propTypes = {
    caseId: PropTypes.string.isRequired,
    field: PropTypes.object.isRequired,
    file: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired
  };

  state = {
    selectedFilterIndex: 0
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState)) {
      return false;
    }
    return true;
  }

  handleSave = item => {
    const {
      field: { name, value },
      form: { setFieldValue }
    } = this.props;
    const oldItems = value || [];
    let newItems = [];
    if (item.price === "") {
      item.price = null;
    }
    if (item.id) {
      const index = oldItems.findIndex(t => t.id === item.id);
      newItems = [...oldItems];
      newItems[index] = item;
    } else {
      item.id = uuid();
      if (item.order === undefined) {
        item.order = oldItems.length;
        newItems = [...oldItems, item];
      } else {
        newItems = [...oldItems];
        newItems.splice(item.order + 1, 0, item);
        newItems = newItems.map((t, index) => {
          t.order = index;
          return t;
        });
      }
    }
    setFieldValue(name, newItems);
  };

  debouncedHandleSave = _.debounce(this.handleSave, 500);

  handleDelete = itemId => {
    const {
      field: { name, value },
      form: { setFieldValue }
    } = this.props;
    const itemsCopy = [...value]
      .filter(t => t.id !== itemId)
      .map((item, index) => {
        item.order = index;
        return item;
      });

    setFieldValue(name, itemsCopy);
  };

  handleDragEnd = result => {
    const {
      field: { name, value },
      form: { setFieldValue }
    } = this.props;
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    console.log(value);
    const items = reorder(value, result.source.index, result.destination.index);

    const newItems = items.map((t, index) => {
      t.order = index;
      return t;
    });
    setFieldValue(name, newItems);
  };

  handleFilterClickListItem = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuItemClick = (event, selectedFilterIndex) => {
    this.setState({ anchorEl: null, selectedFilterIndex });
  };

  searchFieldChanged = event => {
    this.setState({ search: event.target.value });
  };

  render() {
    const {
      caseId,
      field: { value },
      file
    } = this.props;

    const options = [
      <FormattedMessage
        key={1}
        defaultMessage="Alle"
        id="checklist-items.filter-options.all"
      />,
      <FormattedMessage
        key={2}
        defaultMessage="Åbne"
        id="checklist-items.filter-options.open"
      />,
      <FormattedMessage
        key={3}
        defaultMessage="Lukkede"
        id="checklist-items.filter-options.closed"
      />
    ];

    let filteredItems;
    switch (this.state.selectedFilterIndex) {
      case 1:
        filteredItems = value.filter(item => !item.completed);
        break;
      case 2:
        filteredItems = value.filter(item => item.completed);
        break;
      default:
        filteredItems = value;
    }

    if (this.state.search) {
      filteredItems = filteredItems.filter(item =>
        `${item.placement} ${item.ue} ${item.price} ${item.description} ${
          item.start_date ? item.start_date.toISOString() : ""
        } ${item.end_date ? item.end_date.toISOString() : ""} ${
          item.comment
        } ${item.order + 1}`.includes(this.state.search)
      );
    }

    return (
      <>
        <ItemForm
          caseId={caseId}
          floorplan={file}
          initialValues={{ ...EMPTY_ITEM }}
          markers={
            value
              ? value.map(t => {
                  return { number: t.order + 1, x: t.x, y: t.y };
                })
              : []
          }
          number={(value ? value.length : 0) + 1}
          onSave={this.handleSave}
        />
        <Grid container spacing={2}>
          <Grid item sm={2} xs={12}>
            <List dense aria-label="Filter">
              <ListItem button onClick={this.handleFilterClickListItem}>
                <ListItemText
                  primary={
                    <FormattedMessage
                      key={3}
                      defaultMessage="Filtrer"
                      id="checklist-items.filter-options.filter"
                    />
                  }
                  secondary={options[this.state.selectedFilterIndex]}
                />
              </ListItem>
            </List>
            <Menu
              keepMounted
              anchorEl={this.state.anchorEl}
              id="lock-menu"
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}
            >
              {options.map((option, index) => (
                <MenuItem
                  key={index}
                  selected={index === this.state.selectedFilterIndex}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={event => this.handleMenuItemClick(event, index)}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
          <Grid item sm={10} xs={12}>
            <TextField
              fullWidth
              id="outlined-search"
              label={
                <FormattedMessage
                  key={3}
                  defaultMessage="Søg"
                  id="checklist-items.filter-options.Search"
                />
              }
              margin="normal"
              value={this.state.search}
              variant="outlined"
              onChange={this.searchFieldChanged}
            />
          </Grid>
        </Grid>
        <br />
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable droppableId="list">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <ItemCards
                  caseId={caseId}
                  dirty={this.props.form.dirty}
                  floorplan={file}
                  items={filteredItems}
                  onDelete={this.handleDelete}
                  onSave={this.handleSave}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {filteredItems && filteredItems.length ? (
          <Paper>
            <div style={{ padding: 10, textAlign: "right" }}>
              <FormattedMessage
                defaultMessage="Total: {total}"
                id="total"
                values={{
                  total: filteredItems
                    .reduce((total, item) => {
                      if (item.price != null && item.price !== "") {
                        let { price } = item;
                        if (typeof item.price === "string") {
                          price = parseFloat(price.replace(",", "."));
                        }
                        return total + price;
                      }
                      return total;
                    }, 0.0)
                    .toFixed(2)
                }}
              />
            </div>
          </Paper>
        ) : null}
      </>
    );
  }
}
