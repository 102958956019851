import {
  FormattedDate,
  FormattedTime,
  defineMessages,
  injectIntl,
  
} from "react-intl";

import {MUTable} from "../mu-table";
import {REMINDERS} from "../../graphql/reminders";
import {withRouter} from "react-router";
import MuFab from "../fab";
import PropTypes from "prop-types";
import React, {PureComponent} from "react";

const messages = defineMessages({
  date: {
    defaultMessage: "Dato",
    id: "request-reminders-list.date"
  },
  initils: {
    defaultMessage: "Initialer",
    id: "request-reminders-list.initils"
  },
  message: {
    defaultMessage: "Besked",
    id: "request-reminders-list.message"
  },
  time: {
    defaultMessage: "Tid",
    id: "request-reminders-list.time"
  }
});

class ReminderList extends PureComponent {
  static propTypes = {
    baseURL: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    parentId: PropTypes.string.isRequired,
    parentName: PropTypes.string.isRequired
  };

  handleFabClick = () => {
    this.props.history.push(`${this.props.location.pathname}/create`);
  };

  handleRowClick = reminder => {
    const {
      baseURL
    } = this.props;
    this.props.history.push(`${baseURL}/${reminder.id}`);
  };

  render() {
    const {
      intl: {formatMessage},
      parentId,
      parentName
    } = this.props;

    const where = {[parentName]: {_eq: parentId}};
    return (
      <>
        <MUTable
          columns={[
            {
              field: "date",
              render: reminder => (
                <>
                  <FormattedDate value={reminder.date} />
                </>
              ),
              title: formatMessage(messages.date)
            },
            {
              field: "time",
              render: reminder => (
                <>
                  <FormattedTime value={reminder.date} />
                </>
              ),
              title: formatMessage(messages.time)
            },
            {
              field: "address",
              render: reminder => reminder.creator.acronym,
              title: formatMessage(messages.initils)
            },
            {
              field: "message",
              style: {width: "80%"},
              title: formatMessage(messages.message)
            }
          ]}
          dataSourceName="reminders"
          direction="desc"
          gql={REMINDERS}
          orderBy="date"
          where={where}
          onRowClick={this.handleRowClick}
        />
        <MuFab onClick={this.handleFabClick} />
      </>
    );
  }
}

const EnhancedReminderList = withRouter(injectIntl(ReminderList));
export {EnhancedReminderList as ReminderList};
