import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  Select
} from "@material-ui/core";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { UPDATE_FILE } from "../../../graphql/cases";
import { fileInstanceToURL } from "./list";
import { formikPropTypes } from "../../../utils/form-helpers";
import { withApollo } from "@apollo/client/react/hoc";
import React, { PureComponent } from "react";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import TextField from "@material-ui/core/TextField";

const messages = defineMessages({
  buildingInspection: {
    defaultMessage: "Byggetilsyn",
    id: "standard-photos-form.building-inspection"
  },
  insurance: {
    defaultMessage: "Forsikring",
    id: "standard-photos-form.insurance"
  },
  moistureTechnology: {
    defaultMessage: "Fugtteknik",
    id: "standard-photos-form.moisture-technology"
  },
  mold: {
    defaultMessage: "Skimmel",
    id: "standard-photos-form.mold"
  },
  other: {
    defaultMessage: "Andet",
    id: "standard-photos-form.other"
  },
  title: {
    defaultMessage: "Titel",
    id: "standard-photo-form.title"
  }
});

class StandardPhotoForm extends PureComponent {
  static propTypes = {
    ...formikPropTypes
  };
  state = {
    fullsizeUrl: fileInstanceToURL(this.props.values),
    imageURL: fileInstanceToURL(this.props.values, 800)
  };

  updateFile = async newFileInfo => {
    const response = await this.props.client.mutate({
      mutation: UPDATE_FILE,
      variables: {
        file: {
          file_path: newFileInfo.path,
          filename: newFileInfo.filename
        },
        id: this.props.values.file.id
      }
    });
    const newValues = { ...this.props.values };

    newValues.file = response.data.update_files.returning[0];
    this.setState({
      fullsizeUrl: fileInstanceToURL(newValues),
      imageURL: fileInstanceToURL(newValues, 800)
    });
  };

  handleRotateLeft = () => {
    fetch(`${this.state.fullsizeUrl}&rotate=-90`)
      .then(function(response) {
        return response.json();
      })
      .then(file => {
        return this.updateFile(file);
      });
  };
  handleRotateRight = () => {
    fetch(`${this.state.fullsizeUrl}&rotate=90`)
      .then(function(response) {
        return response.json();
      })
      .then(file => {
        return this.updateFile(file);
      });
  };
  render() {
    const {
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      intl,
      values
    } = this.props;

    return (
      <>
        <form onSubmit={handleSubmit}>
          <div>{errors.form}</div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                error={errors.title}
                label={intl.formatMessage(messages.title)}
                name="title"
                type="text"
                value={values.title}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel htmlFor="category-select">
                  <FormattedMessage
                    defaultMessage="Kategori"
                    id="standard-photos-form.category"
                  />
                </InputLabel>
                <Select
                  fullWidth
                  native
                  inputProps={{
                    id: "category-select",
                    name: "category"
                  }}
                  value={values.category}
                  onChange={handleChange}
                >
                  <option value="" />
                  <option value="building-inspection">
                    {intl.formatMessage(messages.buildingInspection)}
                  </option>
                  <option value="moisture-technology">
                    {intl.formatMessage(messages.moistureTechnology)}
                  </option>
                  <option value="mold">
                    {intl.formatMessage(messages.mold)}
                  </option>
                  <option value="insurance">
                    {intl.formatMessage(messages.insurance)}
                  </option>
                  <option value="other">
                    {intl.formatMessage(messages.other)}
                  </option>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button onClick={this.handleRotateLeft}>
                <RotateLeftIcon />
              </Button>
              <Button onClick={this.handleRotateRight}>
                <RotateRightIcon />
              </Button>
            </Grid>
          </Grid>
          <div style={{ minHeight: 800 }}>
            <img
              src={this.state.imageURL}
              style={{
                maxHeight: 400,
                position: "relative",
                top: values.rotate % 180 ? 100 : 0,
                transform: `rotate(${values.rotate}deg)`
              }}
            />
          </div>
        </form>
      </>
    );
  }
}

const StandardPhotoFormWithIntl = injectIntl(withApollo(StandardPhotoForm));

export { StandardPhotoFormWithIntl as StandardPhotoForm };
