import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  defineMessages,
  injectIntl
} from "react-intl";

import "react-image-lightbox/style.css";
import { BASE_FILE_URL } from "../../../utils/app";
import { CASE_DOCUMENTS } from "../../../graphql/cases";
import { IconButton } from "@material-ui/core";
import { MUTable } from "../../../components/mu-table";
import { NewDocumentDialog } from "./new-document-dialog";
import { SelectOrderConfirmationDialog } from "./select-order-confirmation-dialog";
import { SelectQualityControlDialog } from "./select-order-quality-control-dialog";
import { emailLink } from "../../../utils/form-helpers";
import { getCaseAddressFormatted } from "../case-list";
import { withApollo } from "@apollo/client/react/hoc";
import { withAppContext } from "../../../utils/with-app-context";
import { withRouter } from "react-router-dom";
import FileIcon from "@material-ui/icons/InsertDriveFile";
import MailIcon from "@material-ui/icons/Mail";
import MuFab from "../../../components/fab";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

const messages = defineMessages({
  changed: {
    defaultMessage: "Sidst ændret",
    id: "case-doc-list.changed"
  },
  initials: {
    defaultMessage: "Initialer",
    id: "case-doc-list.initials"
  },
  title: {
    defaultMessage: "Titel",
    id: "case-doc-list.title"
  },
  type: {
    defaultMessage: "Type",
    id: "case-doc-list.type"
  }
});

export const getFileMailLink = (caseInstance, title, url) => {
  const subject = `${getCaseAddressFormatted(caseInstance)} - ${title}`;
  const body = `
Download-link:
${url}
`;
  return emailLink(caseInstance.builder.mail, subject, body);
};

export const getFileURL = doc => {
  return `${BASE_FILE_URL}/pdf/${doc.type}/${doc.id}/document.pdf?shareKey=${doc.share_key}`;
};
const docCreatedComperator = (a, b) => {
  if (a.created < b.created) {
    return 1;
  }
  if (a.created > b.created) {
    return -1;
  }
  return 0;
};

export class DownloadFileIcon extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    doc: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
  };
  handleClick = event => {
    const { doc, onClick } = this.props;
    event.preventDefault();
    event.stopPropagation();
    onClick(doc);
  };
  render() {
    return (
      <IconButton
        color="inherit"
        disabled={this.props.disabled}
        size="small"
        style={{ marginRight: 5 }}
        onClick={this.handleClick}
      >
        <FileIcon />
      </IconButton>
    );
  }
}

const typeToTitle = {
  checklist: (
    <FormattedMessage
      defaultMessage="Mangelliste"
      id="case-doc-list.checklist"
    />
  ),
  economy: (
    <FormattedMessage defaultMessage="Økonomi" id="case-doc-list.economy" />
  ),
  note: <FormattedMessage defaultMessage="Note" id="case-doc-list.note" />,
  "order-confirmation": (
    <FormattedMessage
      defaultMessage="Ordrebekræftelse"
      id="case-doc-list.order-confirmation"
    />
  ),
  "quality-control": (
    <FormattedMessage
      defaultMessage="Tilsynsnotat"
      id="case-doc-list.quality-control"
    />
  )
};

class CaseDocsList extends PureComponent {
  static propTypes = {
    caseInstance: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };
  state = {
    newDocumentDialogOpen: false,
    selectOrderConfirmationDialogOpen: false,
    selectQualityControlDialogOpen: false
  };

  handleRowClick = doc => {
    this.props.history.push(
      `/cases/${this.props.match.params.caseId}/${doc.type}/${doc.id}`
    );
  };
  handleFabClick = () => {
    this.setState({ newDocumentDialogOpen: true });
  };

  handleDownloadClick = doc => {
    window.open(getFileURL(doc), "_blank");
  };
  handleRequestOrderConfirmationDialog = () => {
    this.setState({
      newDocumentDialogOpen: false,
      selectOrderConfirmationDialogOpen: true
    });
  };

  handleNewDocumentDialogCancel = () => {
    this.setState({
      newDocumentDialogOpen: false
    });
  };
  handleSelectOrderConfirmationDialogCancel = () => {
    this.setState({
      newDocumentDialogOpen: true,
      selectOrderConfirmationDialogOpen: false
    });
  };

  handleSelectQualityControlDialogCancel = () => {
    this.setState({
      newDocumentDialogOpen: true,
      selectQualityControlDialogOpen: false
    });
  };
  handleRequestQualityControlDialog = () => {
    this.setState({
      newDocumentDialogOpen: false,
      selectQualityControlDialogOpen: true
    });
  };

  render() {
    const {
      caseInstance,
      intl: { formatMessage },
      match: {
        params: { caseId }
      }
    } = this.props;

    return (
      <>
        <MUTable
          columns={[
            {
              field: "changed",
              render: doc => (
                <>
                  <FormattedDate value={doc.changed} />{" "}
                  <FormattedTime value={doc.changed} />
                </>
              ),
              style: { width: 170 },
              title: formatMessage(messages.changed)
            },
            {
              render: doc => (doc.user ? doc.user.acronym : null),
              style: { width: 170 },
              title: formatMessage(messages.initials)
            },
            {
              field: "type",
              render: doc => typeToTitle[doc.type],
              style: { width: 170 },
              title: formatMessage(messages.type)
            },
            {
              field: "title",
              render: doc => (
                <div
                  style={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                    wordBreak: "break-all"
                  }}
                >
                  {doc.title}
                </div>
              ),
              title: formatMessage(messages.title)
            },
            {
              field: "actions",
              noClick: true,
              render: doc => {
                return (
                  <>
                    <IconButton
                      component="a"
                      disabled={!caseInstance.builder}
                      href={
                        caseInstance.builder
                          ? getFileMailLink(
                              caseInstance,
                              doc.title,
                              getFileURL(doc)
                            )
                          : ""
                      }
                      size="small"
                      style={{ marginRight: 5 }}
                    >
                      <MailIcon />
                    </IconButton>
                    <DownloadFileIcon
                      doc={doc}
                      onClick={this.handleDownloadClick}
                    />
                  </>
                );
              },
              style: { width: 120 },
              title: ""
            }
          ]}
          customSort={docCreatedComperator}
          dataSourceName={[
            "case_doc_notes",
            "case_doc_economy",
            "case_doc_order_confirmations",
            "case_doc_quality_controls",
            "case_doc_checklists"
          ]}
          extraVariables={{ case_id: caseId }}
          gql={CASE_DOCUMENTS}
          onRowClick={this.handleRowClick}
        />
        <MuFab onClick={this.handleFabClick} />
        <NewDocumentDialog
          caseId={caseId}
          open={this.state.newDocumentDialogOpen}
          onCancel={this.handleNewDocumentDialogCancel}
          onRequestOrderConfirmationDialog={
            this.handleRequestOrderConfirmationDialog
          }
          onRequestQualityControlDialog={this.handleRequestQualityControlDialog}
        />
        <SelectOrderConfirmationDialog
          caseId={caseId}
          open={this.state.selectOrderConfirmationDialogOpen}
          onCancel={this.handleSelectOrderConfirmationDialogCancel}
        />
        <SelectQualityControlDialog
          caseId={caseId}
          open={this.state.selectQualityControlDialogOpen}
          onCancel={this.handleSelectQualityControlDialogCancel}
        />
      </>
    );
  }
}

const enhanced = withAppContext(
  withRouter(injectIntl(withApollo(CaseDocsList)))
);
export { enhanced as CaseDocsList };
