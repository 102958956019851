import { AppContext } from "../../components/AppContext";
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  Switch,
  Typography
} from "@material-ui/core";
import {
  CalendarButton,
  getCalLink,
  getReminderCaseInformation
} from "../../components/calendar-button";
import { DateTimePicker } from "@material-ui/pickers";
import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  defineMessages,
  useIntl
} from "react-intl";
import { MUTable } from "../../components/mu-table";
import { Page } from "../../components/layout/page";
import { REMINDERS } from "../../graphql/reminders";
import { ReminderCreateDialog } from "../../components/reminder-create-dialog";
import { ReminderMessageDialog } from "./reminder-message-dialog";
import { StringParam, useQueryParam } from "use-query-params";
import { createWhereObject } from "../../utils/form-helpers";
import { getCaseAddress } from "../case/case-list";
import {
  useCreateReminderMutation,
  useCreateReminderRecipientMutation,
  useUpdateReminderMutation,
  useUpdateRemindersContactedMutation
} from "../../generated/graphql";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Bowser from "bowser";
import Header from "../../components/layout/header";
import MuFab from "../../components/fab";
import React, { useCallback, useContext, useState } from "react";
import moment from "moment";

const browser = Bowser.getParser(window.navigator.userAgent);
const isMobile = browser.getPlatformType(true) !== "desktop";
const messages = defineMessages({
  address: {
    defaultMessage: "Sagsadresse",
    id: "reminder-list.address"
  },
  case: {
    defaultMessage: "Sag",
    id: "reminder-list.case"
  },
  contact: {
    defaultMessage: "Kontaktperson",
    id: "reminder-list.contact"
  },
  contacted: {
    defaultMessage: "Kontakt",
    id: "reminder-list.contacted"
  },
  date: {
    defaultMessage: "Dato",
    id: "reminder-list.date"
  },
  initials: {
    defaultMessage: "Initialer",
    id: "reminder-list.initials"
  },
  manager: {
    defaultMessage: "Byggeleder",
    id: "reminder-list.manager"
  },
  message: {
    defaultMessage: "Besked",
    id: "reminder-list.message"
  },
  noContact: {
    defaultMessage: "Ingen kontakt",
    id: "reminder-list.noContact"
  },
  reminders: {
    defaultMessage: "Mine påmindelser",
    id: "reminder-list.reminders"
  },
  state: {
    defaultMessage: "Kontakt",
    id: "reminder-list.state"
  },
  time: {
    defaultMessage: "Tid",
    id: "reminder-list.time"
  }
});

const CELL_STYLE = { padding: "0 1px 0 1px" };

const TimeColumn = (reminder): JSX.Element | null => {
  if (!reminder) {
    return null;
  }
  return (
    <>
      <FormattedTime value={reminder.date} />
      <CalendarButton caseInstance={reminder} showTitle={false} />
    </>
  );
};

const ContactColumn = React.memo(function ContactColumn({
  instance,
  onCheckedChanged
}: {
  instance: { contacted?: boolean };
  onCheckedChanged: (
    instance: { contacted?: boolean },
    checked: boolean,
    callback: (checked: boolean) => void
  ) => void;
}): JSX.Element {
  const [checked, setChecked] = useState<boolean | null>(null);
  const changeCheckedState = useCallback((value: boolean) => {
    setChecked(value);
  }, []);
  const handleCheckedChanged = useCallback(
    event => {
      onCheckedChanged(instance, event.target.checked, changeCheckedState);
    },
    [changeCheckedState, instance, onCheckedChanged]
  );

  return (
    <Checkbox
      checked={checked !== null ? checked : instance.contacted}
      color="primary"
      value="contacted"
      onChange={handleCheckedChanged}
    />
  );
});

const FakeComponent = (): JSX.Element => {
  return <></>;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
const handleFaker = (): void => {};

const DateEditColumn = React.memo(function DateEditColumn({
  instance,
  onClick
}: {
  instance?: any;
  onClick: (instance: any) => void;
}): JSX.Element {
  const handleClick = useCallback(() => {
    onClick(instance);
  }, [instance, onClick]);

  return (
    <table>
      <tbody>
        <tr>
          <td>
            <FormattedDate value={instance.date} />
          </td>
        </tr>
        <tr>
          <td>
            <Button color="primary" size="small" onClick={handleClick}>
              <FormattedMessage
                defaultMessage="Rediger"
                id="reminder-list.edit"
              />
            </Button>
          </td>
        </tr>
      </tbody>
    </table>
  );
});

const isNewRowGroup = (prev, current): boolean => {
  return (
    new Date(prev.date).getUTCDate() !== new Date(current.date).getUTCDate()
  );
};

export const ReminderList = React.memo(function ReminderList(): JSX.Element {
  const [q] = useQueryParam("q", StringParam);
  const [days14, setDays14] = useState(false);
  const [lookBack, setLookBack] = useState(false);
  const [noContact, setNoContact] = useState(false);
  const [reminderCreateDialogOpen, setReminderCreateDialogOpen] = useState(
    false
  );
  const [
    reminderCreateDialogOpenedFor,
    setReminderCreateDialogOpenedFor
  ] = useState(null);
  const [
    reminderCreateDialogOpenedInstance,
    setReminderCreateDialogOpenedInstance
  ] = useState(null);
  const [reminderMessage, setReminderMessage] = useState<any>();
  const [reminderMessageDialogOpen, setReminderMessageDialogOpen] = useState(
    false
  );
  const [reminderMoveOpenedFor, setReminderMoveOpenedFor] = useState(null);
  const [search, setSearch] = useState(q);
  const [sortDirection, setSortDirection] = useState("");
  const [sorting, setSorting] = useState<any>({ date: "asc" });
  const [updateCounter, setUpdateCounter] = useState(0);

  const handleDays14Changed = useCallback(event => {
    setDays14(event.target.checked);
  }, []);

  const handleNoContactChanged = useCallback(event => {
    setNoContact(event.target.checked);
  }, []);

  const [
    updateRemindersContactedMutation
  ] = useUpdateRemindersContactedMutation();

  const handleContactedCheckedChanged = useCallback(
    async (reminder, contacted, setChecked) => {
      const response = await updateRemindersContactedMutation({
        variables: {
          contacted,
          id: reminder.id
        }
      });
      setChecked(response.data.update_reminders.returning[0].contacted);
    },
    [updateRemindersContactedMutation]
  );

  const handleReminderCreateDialogCancel = useCallback(() => {
    setReminderCreateDialogOpen(false);
    setReminderCreateDialogOpenedFor(null);
    setReminderCreateDialogOpenedInstance(null);
  }, []);

  const handleReminderCreate = useCallback(caseInfo => {
    setReminderCreateDialogOpen(true);
    setReminderCreateDialogOpenedFor(caseInfo);
  }, []);

  const handleReminderCreated = useCallback(() => {
    handleReminderCreateDialogCancel();
    setUpdateCounter(updateCounter + 1);
  }, [handleReminderCreateDialogCancel, updateCounter]);

  const handleFabClick = useCallback(() => {
    handleReminderCreate(null);
  }, [handleReminderCreate]);

  const handleReminderEditButtonClicked = useCallback(instance => {
    setReminderMoveOpenedFor(instance);
  }, []);

  const [createReminder] = useCreateReminderMutation();
  const [updateReminder] = useUpdateReminderMutation();
  const [createReminderRecipient] = useCreateReminderRecipientMutation();
  const {
    state: { currentUser }
  } = useContext(AppContext);
  const moveReminder = useCallback(
    async (instance, date) => {
      const recipients = instance.recipients || [];
      delete instance.recipients;
      const reminder = {
        case_id: instance.case_id,
        creator_id: currentUser.id,
        date,
        message: instance.message,
        request_id: instance.request_id
      };
      try {
        const response = await createReminder({
          variables: {
            reminder
          }
        });
        const createdReminderId =
          response.data.insert_reminders.returning[0].id;
        const reminderRecipients = recipients.map(recipient => ({
          reminder_id: createdReminderId,
          user_id: recipient.user ? recipient.user.id : recipient.id
        }));

        await createReminderRecipient({
          variables: { reminder_recipients: reminderRecipients }
        });

        const updateVariables = {
          id: instance.id,
          reminder: {
            next_reminder_id: createdReminderId
          }
        };
        await updateReminder({ variables: updateVariables });

        handleReminderCreated();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        const errors = e.graphQLErrors.map(error => error.message);
        // eslint-disable-next-line no-console
        console.log(errors);
      }
    },
    [
      createReminder,
      createReminderRecipient,
      currentUser.id,
      handleReminderCreated,
      updateReminder
    ]
  );

  const handleMoveReminderDateAccepted = useCallback(
    date => {
      const instance = reminderMoveOpenedFor;
      setReminderMoveOpenedFor(null);
      moveReminder(instance, date);
    },
    [moveReminder, reminderMoveOpenedFor]
  );

  const handleMoveReminderDateCancel = useCallback(() => {
    setReminderMoveOpenedFor(null);
  }, []);

  const handleDirectionChanged = useCallback(() => {
    setLookBack(!lookBack);
  }, [lookBack]);

  const handleSortByCompanyClick = useCallback(() => {
    if (sortDirection === "desc") {
      setSortDirection("");
      setSorting({ date: "asc" });
    } else {
      const newSortDirection = sortDirection === "asc" ? "desc" : "asc";
      setSortDirection(newSortDirection);

      setSorting([
        { case: { company: { first_name: newSortDirection } } },
        { case: { company: { last_name: newSortDirection } } }
      ]);
    }
  }, [sortDirection]);

  const handleMessageClick = useCallback(instance => {
    setReminderMessage(instance);
    setReminderMessageDialogOpen(true);
  }, []);

  const handleReminderMessageDialogOk = useCallback(
    message => {
      setReminderMessageDialogOpen(false);
      moveReminder({ ...reminderMessage, message }, reminderMessage.date);
    },
    [moveReminder, reminderMessage]
  );

  const handleReminderMessageDialogCancel = useCallback(() => {
    setReminderMessageDialogOpen(false);
  }, []);

  const { formatMessage } = useIntl();
  const startDate = moment().startOf("day");
  const endDate = moment()
    .startOf("day")
    .add(days14 ? 14 : 7, "days");
  const daysFilterDisabled = !!search;
  const filter: any = [
    {
      recipients: {
        user_id: { _eq: currentUser.id }
      }
    },
    { next_reminder_id: { _is_null: true } }
  ];
  if (!daysFilterDisabled) {
    if (lookBack) {
      filter.push({
        date: {
          _gte: moment()
            .startOf("day")
            .subtract(days14 ? 14 : 7, "days")
            .toISOString()
        }
      });
      filter.push({ date: { _lte: startDate.toISOString() } });
    } else {
      filter.push({ date: { _lte: endDate.toISOString() } });
      filter.push({ date: { _gte: startDate.toISOString() } });
    }
  }
  if (noContact) {
    filter.push({ contacted: { _eq: false } });
  }
  const where = createWhereObject(
    [
      "recipients.user.acronym",
      "request.address",
      "request.zip_code",
      "request.city",
      "request.builder",
      "date"
    ],
    search,
    filter
  );
  const typoMobileStyle = isMobile
    ? { fontSize: 12, letterSpacing: "normal" }
    : {};

  const DateTimePickerWithoutType: any = DateTimePicker;
  const MUTableWithoutType: any = MUTable;

  return (
    <Page
      appBar={
        <Header
          title={formatMessage(messages.reminders)}
          onSearchChange={setSearch}
        />
      }
    >
      <Typography
        component="div"
        style={{
          backgroundColor: "#FFF",
          display: "flex",
          paddingLeft: 5,
          ...typoMobileStyle
        }}
      >
        <Grid container>
          <Grid item sm={3} xs={4}>
            <Grid container alignItems="center" component="label" spacing={0}>
              <Grid item>7</Grid>
              <Grid item>
                <Switch
                  checked={days14}
                  disabled={daysFilterDisabled}
                  onChange={handleDays14Changed}
                />
              </Grid>
              <Grid item>
                <FormattedMessage
                  defaultMessage="14 dage"
                  id="reminder-list.14days"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={3} xs={4}>
            <Grid container alignItems="center" component="label" spacing={0}>
              <Grid item>
                <FormattedMessage
                  defaultMessage="Frem"
                  id="reminder-list.forward"
                />
              </Grid>
              <Grid item>
                <Switch
                  checked={lookBack}
                  disabled={daysFilterDisabled}
                  onChange={handleDirectionChanged}
                />
              </Grid>
              <Grid item>
                <FormattedMessage
                  defaultMessage="Tilbage"
                  id="reminder-list.back"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={3} xs={4}>
            <Grid container alignItems="center" component="label" spacing={0}>
              <Grid item>
                <FormattedMessage
                  defaultMessage="Alle"
                  id="reminder-list.no-contact"
                />
              </Grid>
              <Grid item>
                <Switch checked={noContact} onChange={handleNoContactChanged} />
              </Grid>
              <Grid item>
                <FormattedMessage
                  defaultMessage="Ingen kontakt"
                  id="reminder-list.no-contact"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Typography>
      <MUTableWithoutType
        noPadding
        cellStyle={CELL_STYLE}
        columns={[
          {
            component: (
              <DateEditColumn onClick={handleReminderEditButtonClicked} />
            ),
            field: "date",
            style: { ...CELL_STYLE, width: 130 },
            title: formatMessage(messages.date)
          },
          {
            field: "time",
            render: TimeColumn,
            style: { ...CELL_STYLE, width: 130 },
            title: formatMessage(messages.time)
          },
          {
            field: "message",
            nextRow: isMobile,
            onClick: handleMessageClick,
            style: { ...CELL_STYLE, verticalAlign: "top" },
            title: formatMessage(messages.message)
          },
          {
            field: "contact",
            // eslint-disable-next-line react/display-name
            render: reminder => {
              const caseInfo = getReminderCaseInformation(reminder);
              if (!caseInfo.builder) {
                return <></>;
              }
              return (
                <table>
                  <tbody>
                    <tr>
                      <td>
                        {`${caseInfo.builder.first_name} ${caseInfo.builder.last_name}`.trim()}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href={`tel:${caseInfo.builder.mobile}`}>
                          {caseInfo.builder.mobile}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href={`mailto:${caseInfo.builder.mail}`}>
                          {caseInfo.builder.mail}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              );
            },
            style: { ...CELL_STYLE, width: 200 },
            title: formatMessage(messages.contact)
          },
          {
            field: "company",
            // eslint-disable-next-line react/display-name
            render: reminder => {
              if (!reminder.case || (reminder.case && !reminder.case.company)) {
                return null;
              }
              const { company } = reminder.case;
              return (
                <table>
                  <tbody>
                    <tr>
                      <td>{company.company}</td>
                    </tr>
                    <tr>
                      <td>
                        {company.first_name} {company.last_name}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href={`tel:${company.mobile}`}>{company.mobile}</a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a href={`mailto:${company.mail}`}>{company.mail}</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              );
            },
            sortBy: handleSortByCompanyClick,
            sortDirection,
            sortingActive: !!sortDirection,
            style: { ...CELL_STYLE, width: 130 },
            title: formatMessage(messages.manager)
          },
          {
            field: "address",
            // eslint-disable-next-line react/display-name
            render: reminder => {
              const adressInfo = getCaseAddress(
                getReminderCaseInformation(reminder)
              );
              return (
                <table>
                  <tbody>
                    <tr>
                      <td>{adressInfo?.address}</td>
                    </tr>
                    <tr>
                      <td>
                        {adressInfo?.zip_code} {adressInfo?.city}
                      </td>
                    </tr>
                  </tbody>
                </table>
              );
            },
            style: { ...CELL_STYLE, width: 200 },
            title: formatMessage(messages.address)
          },
          {
            component: (
              <ContactColumn
                instance={{}}
                onCheckedChanged={handleContactedCheckedChanged}
              />
            ),
            field: "contacted",
            style: { ...CELL_STYLE, width: 80 },
            title: formatMessage(messages.state)
          },
          {
            field: "case",
            // eslint-disable-next-line react/display-name
            render: reminder => (
              <IconButton
                component="a"
                href={getCalLink(reminder)}
                target="_BLANK"
              >
                <AssignmentIcon />
              </IconButton>
            ),
            style: { ...CELL_STYLE, width: 50 },
            title: formatMessage(messages.case)
          }
        ]}
        dataSourceName="reminders"
        gql={REMINDERS}
        limit={daysFilterDisabled ? 80 : null}
        newRowGroup={isNewRowGroup}
        orderBy={sorting}
        updateCounter={updateCounter}
        where={where}
      />
      {reminderCreateDialogOpen ? (
        <ReminderCreateDialog
          caseInfo={reminderCreateDialogOpenedFor}
          open={reminderCreateDialogOpen}
          variables={reminderCreateDialogOpenedInstance}
          onCancel={handleReminderCreateDialogCancel}
          onReminderCreated={handleReminderCreated}
        />
      ) : null}
      {reminderMoveOpenedFor ? (
        <DateTimePickerWithoutType
          open
          ampm={false}
          DialogProps={{ fullScreen: isMobile }}
          format="Do MMMM YYYY HH:mm"
          minutesStep={15}
          TextFieldComponent={FakeComponent}
          value={
            reminderMoveOpenedFor ? moment(reminderMoveOpenedFor.date) : null
          }
          onAccept={handleMoveReminderDateAccepted}
          onChange={handleFaker}
          onDismiss={handleMoveReminderDateCancel}
        />
      ) : null}
      <ReminderMessageDialog
        open={reminderMessageDialogOpen}
        variables={{ message: reminderMessage?.message || "" }}
        onCancel={handleReminderMessageDialogCancel}
        onOk={handleReminderMessageDialogOk}
      />
      <MuFab onClick={handleFabClick} />
    </Page>
  );
});
