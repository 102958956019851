import { BASE_URL } from "../../utils/app";
import { CaseTypeSelect } from "../../components/case-type-select";
import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import {
  ClosedCaseRemindersQuery,
  Order_By,
  useClosedCaseRemindersLazyQuery
} from "../../generated/graphql";
import { FormattedDate, FormattedMessage } from "react-intl";
import { User, UserSelect } from "../../components/user-select";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import gql from "graphql-tag";

export const GET_CLOSED_CASE_STATS = gql`
  query closedCaseReminders($orderBy: cases_order_by!, $where: cases_bool_exp) {
    cases(order_by: [$orderBy], where: $where) {
      id
      task
      description
      responsible_id
      responsible {
        id
        acronym
      }
      builder {
        id
        address
        zip_code
        city
      }
      address
      zip_code
      city
      case_type_id
      case_type {
        name
      }

      reminders {
        id
        date
      }
      date_of_inspection
      other_address
      changed
      closed
    }
  }
`;

type CaseInstance = ClosedCaseRemindersQuery["cases"][number];

const Row = React.memo(function Row({
  caseInstance
}: {
  caseInstance: CaseInstance;
}): JSX.Element {
  const handleCaseClik = useCallback(() => {
    window.open(`${BASE_URL}/cases/${caseInstance.id}`, "_BLANK");
  }, [caseInstance.id]);
  const fourteenDaysAfterClosing = useMemo(() => {
    if (!caseInstance.closed) {
      return null;
    }
    const closingDate = new Date(caseInstance.closed);
    closingDate.setDate(closingDate.getDate() + 14);
    return closingDate.toISOString();
  }, [caseInstance.closed]);

  const reminderOk = useMemo(() => {
    return caseInstance.reminders.some(reminder => {
      if (!fourteenDaysAfterClosing) {
        return true;
      }
      return reminder.date > fourteenDaysAfterClosing;
    });
  }, [caseInstance.reminders, fourteenDaysAfterClosing]);

  return (
    <TableRow key={caseInstance.id}>
      <TableCell>{caseInstance.builder?.address}</TableCell>
      <TableCell>{caseInstance.case_type?.name}</TableCell>
      <TableCell>{caseInstance.responsible.acronym}</TableCell>
      <TableCell>
        {caseInstance.date_of_inspection ? (
          <FormattedDate value={caseInstance.date_of_inspection} />
        ) : null}
      </TableCell>

      <TableCell style={!reminderOk ? { backgroundColor: "red" } : undefined}>
        {!reminderOk ? (
          <FormattedMessage
            defaultMessage="Nej"
            id="moisture-technology-table.no"
          />
        ) : null}
      </TableCell>

      <TableCell>
        <div style={{ cursor: "pointer" }} onClick={handleCaseClik}>
          Åbn sag
        </div>
      </TableCell>
    </TableRow>
  );
});

export function ClosedCaseReminders({
  userId
}: {
  userId?: string;
}): JSX.Element {
  const [responsible, setResponsible] = useState<User | null>(null);
  const onUserSelected = useCallback((option): void => {
    setResponsible(option);
  }, []);

  const [caseType, setCaseType] = useState<{ value: string } | null>(null);
  const onCaseTypeSelected = useCallback((option): void => {
    setCaseType(option);
  }, []);

  const [getData, { data, loading }] = useClosedCaseRemindersLazyQuery();

  useEffect(() => {
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 3);
    const caseTypeId = caseType?.value;
    const responsibleId = responsible?.id;
    const where = {
      _and: caseTypeId ? [{ case_type_id: { _eq: caseTypeId } }] : [],
      closed: { _is_null: false },
      date_of_inspection: { _gt: startDate.toISOString() },
      responsible: responsibleId ? { id: { _eq: responsibleId } } : {}
    };

    getData({
      variables: {
        orderBy: {
          date_of_inspection: Order_By.Desc
        },
        where
      }
    });
  }, [caseType?.value, getData, responsible?.id, userId]);

  return (
    <Paper>
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <Grid container>
          {!userId ? (
            <Grid item lg={6}>
              <UserSelect
                field={{ name: "responsible", value: responsible }}
                form={{ errors: {} }}
                onlyGroups={[1, 2, 3]}
                placeholder={
                  <FormattedMessage
                    defaultMessage="Sagsansvarlig"
                    id="building-inspectio.responsible"
                  />
                }
                onSelect={onUserSelected}
              />
            </Grid>
          ) : null}
          <Grid item lg={6}>
            <CaseTypeSelect
              field={{ name: "caseType", value: caseType }}
              form={{ errors: {} }}
              placeholder={
                <FormattedMessage
                  defaultMessage="Sagstype"
                  id="moisture-technology.case_type"
                />
              }
              onSelect={onCaseTypeSelected}
            />
          </Grid>
        </Grid>
      </div>
      {loading || !data?.cases ? (
        <div style={{ padding: 10, textAlign: "center" }}>
          <CircularProgress size={24} />
        </div>
      ) : (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Sagsadresse"
                  id="closed-case.header.case-address"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Sagstype"
                  id="closed-case.header.case-type"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Sagsansvarlig"
                  id="closed-case.header.responsible"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="Besigtigelsesdato"
                  id="closed-case.header.date-of-inspection"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  defaultMessage="1 og 5 års"
                  id="closed-case.header.notes"
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.cases.map(caseInstance => {
              return <Row key={caseInstance.id} caseInstance={caseInstance} />;
            })}
          </TableBody>
        </Table>
      )}
    </Paper>
  );
}
