import {AppContext} from "../../components/AppContext";
import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  defineMessages,
  injectIntl
} from "react-intl";
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import {TextFieldAutosize} from "../../components/resizing-text-area";
import DoneIcon from "@material-ui/icons/Done";
import PropTypes from "prop-types";
import React, {PureComponent, useCallback, useState} from "react";
import TextField from "@material-ui/core/TextField";

const messages = defineMessages({
  notes: {
    defaultMessage: "Note",
    id: "request-list.notes"
  }
});

const NotesTable = React.memo(({notes}) => {
  if (!notes || notes.length === 0) {
    return null;
  }
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <FormattedMessage
              defaultMessage="Beskrivelse"
              id="notes-table.description"
            />
          </TableCell>
          <TableCell style={{paddingLeft: 2, paddingRight: 2, width: 50}}>
            <FormattedMessage
              defaultMessage="Initialer"
              id="notes-table.description"
            />
          </TableCell>
          <TableCell style={{paddingLeft: 2, paddingRight: 2, width: 110}}>
            <FormattedMessage
              defaultMessage="Dato"
              id="notes-table.description"
            />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {notes.map(row => (
          <TableRow key={row.id}>
            <TableCell style={{whiteSpace: "pre-line"}}>{row.notes}</TableCell>
            <TableCell style={{paddingLeft: 2, paddingRight: 2, width: 50}}>
              {row.user.acronym}
            </TableCell>
            <TableCell style={{paddingLeft: 2, paddingRight: 2, width: 110}}>
              <FormattedDate value={row.added_date} />{" "}
              <FormattedTime value={row.added_date} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
});
NotesTable.displayName = "NotesTable";
NotesTable.propTypes = {
  notes: PropTypes.array
};

const AddNote = React.memo(
  injectIntl(({currentUser, intl, onAddNote}) => {
    const [notes, setNotes] = useState("");
    const handleAddNote = useCallback(() => {
      onAddNote(notes, currentUser);
      setNotes("");
    }, [notes, onAddNote, setNotes, currentUser]);
    return (
      <Paper style={{padding: 10}}>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <TextFieldAutosize
              fullWidth
              multiline
              label={intl.formatMessage(messages.notes)}
              // eslint-disable-next-line react/jsx-no-bind
              name="builder"
              value={notes}
              onChange={event => setNotes(event.target.value)}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton
              aria-label="Save"
              disabled={!notes}
              onClick={handleAddNote}
            >
              <DoneIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    );
  })
);
AddNote.displayName = "AddNote";
AddNote.propTypes = {
  currentUser: PropTypes.object.isRequired,
  onAddNote: PropTypes.func.isRequired
};

export class RequestNotes extends PureComponent {
  static propTypes = {
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired
  };

  handleAddNote = (notes, currentUser) => {
    const {
      field: {name, value},
      form: {setFieldValue}
    } = this.props;
    const newNotes = [
      {
        added_date: new Date().toISOString(),
        notes,
        user: {acronym: currentUser.acronym, id: currentUser.id}
      },
      ...value,
    ];
    setFieldValue(name, newNotes);
  };
  render() {
    const {
      field: {value}
    } = this.props;
    return (
      <>
        <AppContext.Consumer>
          {context => (
            <AddNote
              currentUser={context.state.currentUser}
              onAddNote={this.handleAddNote}
            />
          )}
        </AppContext.Consumer>
        <br />
        <NotesTable notes={value} />
      </>
    );
  }
}
