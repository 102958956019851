import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { GET_ABSENCE_TYPES } from "../graphql/absence";
import { useQuery } from "@apollo/client";
import React from "react";

export const formatAbsenceTypeLabel = (absenceType): string =>
  absenceType.label;

export const AbsenceTypeSelect = (props: any): JSX.Element => {
  const { data } = useQuery(GET_ABSENCE_TYPES, {
    variables: {
      limit: 20,
      orderBy: { label: "asc" },
      where: { active: { _eq: true } }
    }
  });
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="age-native-simple">{props.label}</InputLabel>
      <Select {...props.field}>
        {data?.absence_types?.map(absenceType => (
          <MenuItem key={absenceType.id} value={absenceType.id}>
            {formatAbsenceTypeLabel(absenceType)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
