import { withApollo } from "@apollo/client/react/hoc";
import { withRouter } from "react-router";
import MaterialTable from "material-table";
import React, { Component } from "react";

/*interface Props {
  columns: Array<any>;
  gql: string;
  dataSourceName: string;
  defaultOrderBy?: string;
  direction?: string;
  onRowClick?: (
    event?: React.MouseEvent<Element, MouseEvent>,
    rowData?: any
  ) => any;
  path?: string
}*/

class BSTable extends Component {
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();
  }

  componentDidMount() {
    this.props.path && this.props.history.listen(this.refreshData);
  }

  /*componentDidUpdate(prevProps) {
    console.log(JSON.stringify(prevProps.search) !== JSON.stringify(this.props.search))
    if (JSON.stringify(prevProps.search) !== JSON.stringify(this.props.search) ) {
      this.tableRef.current && this.tableRef.current.onQueryChange();
    }
  }*/

  refreshData = () => {
    if (this.props.history.location.pathname === this.props.path) {
      this.tableRef.current && this.tableRef.current.onQueryChange();
    }
  };

  render() {
    const {
      client,
      columns,
      dataSourceName,
      defaultOrderBy,
      direction,
      gql,
      limit,
      onRowClick,
      search
    } = this.props;
    return (
      <MaterialTable
        columns={columns}
        // eslint-disable-next-line react/jsx-no-bind
        data={query => {
          let orderBy;
          if (query.orderBy) {
            const orderByParts = query.orderBy.field.split("__");
            const partsLength = orderByParts.length;
            if (partsLength > 1) {
              orderByParts.reverse().forEach(part => {
                if (!orderBy) {
                  orderBy = { [part]: query.orderDirection };
                } else {
                  orderBy = { [part]: orderBy };
                }
              });
            } else {
              orderBy = {};
              orderBy[query.orderBy.field] = query.orderDirection;
            }
          } else if (defaultOrderBy) {
            orderBy = {};
            if (direction) {
              orderBy[defaultOrderBy] = direction;
            } else {
              orderBy[defaultOrderBy] = "asc";
            }
          }

          return client
            .query({
              query: gql,
              variables: {
                limit: limit || 30,
                //where: query.where, TODO: Set from props.search
                orderBy
              }
            })
            .then(response => {
              const value = {
                data: response.data[dataSourceName].nodes,
                page: 0,
                totalCount: response.data[dataSourceName].aggregate
                  ? response.data[dataSourceName].aggregate.count
                  : 1000
              };
              return value;
            });
        }}
        options={{
          maxbodyheight: "100vh",
          pageSizeOptions: null,
          paging: false,
          toolbar: false
        }}
        tableRef={this.tableRef}
        title="table"
        onRowClick={onRowClick}
      />
    );
  }
}

export default withRouter(withApollo(BSTable));
