import { getCaseAddressFormatted } from "../pages/case/case-list";
import moment from "moment";

export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000/#"
    : !window.TEST_SERVER
    ? "https://login.byggesagkyndig.nu/#"
    : "https://dev.multi-office.dk/#";

export const BASE_FILE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : !window.TEST_SERVER
    ? "https://login.byggesagkyndig.nu"
    : "https://dev.multi-office.dk";

export const replaceWithData = (str, caseInstance) => {
  if (!str || !caseInstance) {
    return "";
  }
  let parsedString = str;
  const o = {};
  if (caseInstance.date_of_inspection) {
    o.date = moment(caseInstance.date_of_inspection).format("D/M YYYY");
  } else {
    o.date = "DATO";
  }

  if (caseInstance.date_of_inspection) {
    o.time = moment(caseInstance.date_of_inspection).format("HH:mm");
  } else {
    o.time = "TIME";
  }

  if (caseInstance.responsible_id) {
    o.responsible = `${caseInstance.responsible.first_name} ${caseInstance.responsible.last_name}`;
    o.responsibleMobile = caseInstance.responsible.mobile;
    o.responsiblePhone = caseInstance.responsible.phone;
    o.responsibleEmail = caseInstance.responsible.mail;
    o.responsibleInitials = caseInstance.responsible.acronym;
  } else {
    o.responsible = "RESPONSIBLE";
    o.responsibleMobile = "PHONE";
    o.responsibleEmail = "EMAIL";
    o.responsibleInitials = "INITIALS";
  }

  if (caseInstance.builder_id) {
    const { builder } = caseInstance;
    o.firstName = builder.first_name;
    o.lastName = builder.last_name;
    o.phone = builder.phone;
    o.email = builder.mail;
    o.builderAddress = `${builder.address || ""}, ${builder.zip_code ||
      ""} ${builder.city || ""}`;
  } else {
    o.firstName = "FIRST NAME";
    o.lastName = "LAST NAME";
    o.phone = "PHONE";
    o.email = "EMAIL";
    o.builderAddress = "ADDRESS";
  }

  o.address = getCaseAddressFormatted(caseInstance);

  for (const [key, value] of Object.entries(o)) {
    parsedString = parsedString.replace(
      new RegExp(`\\{\\{\\s*${key}\\s*\\}\\}`, "g"),
      value
    );
  }
  return parsedString;
};
