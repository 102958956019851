import * as yup from "yup";
import {
  CONTACTS_AGGREGATED_QUERY,
  CREATE_CONTACT,
  GET_CONTACT,
  GET_CONTACT_WITH_NAME_MOBILE,
  UPDATE_CONTACT
} from "../../graphql/contacts";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { Page } from "../../components/layout/page";
import { Query } from "@apollo/client/react/components";
import { Typography } from "@material-ui/core";
import { flowRight as compose } from "lodash";
import { graphql, withApollo } from "@apollo/client/react/hoc";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import AreYouSure from "../../components/are-you-sure";
import ContactForm from "./contact-form";
import Header from "../../components/layout/header";
import PropTypes from "prop-types";
import React, { PureComponent, useState } from "react";

const messages = defineMessages({
  add: {
    defaultMessage: "Tilføj",
    id: "contact-entry.add"
  },
  contacts: {
    defaultMessage: "Kontakter",
    id: "contact-entry.contacts"
  },
  createNew: {
    defaultMessage: "Opret ny kotakt",
    id: "contact-entry.create-new-contact"
  },
  update: {
    defaultMessage: "Redigér Kontakt",
    id: "contact-entry.update-contact"
  }
});

const schema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  mail: yup.string().required(),
  mobile: yup.string().required(),
  type: yup.string({ min: 1 }).required()
});

class ContactEntry extends PureComponent {
  static propTypes = {
    client: PropTypes.object.isRequired,
    contactId: PropTypes.string.isRequired,
    dirty: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    resetForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired
  };
  state = {
    contactExists: false
  };
  handleSubmitForm = async () => {
    const {
      client,
      contactId,
      submitForm,
      values: { first_name, last_name, mobile }
    } = this.props;
    if (!!contactId || !(first_name && last_name && mobile)) {
      submitForm();
      return;
    }
    const existing = await client.query({
      fetchPolicy: "network-only",
      query: GET_CONTACT_WITH_NAME_MOBILE,
      variables: {
        first_name,
        last_name,
        mobile
      }
    });
    if (!existing.data.contacts.length) {
      submitForm();
      this.setState({ contactExists: false });
    } else {
      this.setState({ contactExists: true });
    }
  };

  handleResetForm = () => this.props.handleReset();

  showNavigationWarning = () => !this.props.isSubmitting && this.props.dirty;

  render() {
    const {
      contactId,
      dirty,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      intl: { formatMessage },
      isSubmitting,
      touched,
      values
    } = this.props;

    let heading;
    if (contactId) {
      heading = formatMessage(messages.update);
    } else {
      heading = formatMessage(messages.createNew);
    }

    return (
      <Page
        appBar={
          <Header
            dirty={dirty}
            title={heading}
            onResetButton={this.handleResetForm}
            onSaveButton={this.handleSubmitForm}
          />
        }
      >
        <div
          style={{
            backgroundColor: "#FFF",
            minHeight: "calc(100% - 64px)",
            padding: 20
          }}
        >
          {this.state.contactExists ? (
            <Typography color="error" variant="subtitle1">
              <FormattedMessage
                defaultMessage="Kontakten eksisterer i forvejen"
                id="contact-entry.create-new-contact"
              />
            </Typography>
          ) : null}
          <ContactForm
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            touched={touched}
            values={values}
          />
        </div>
        <AreYouSure when={this.showNavigationWarning} />
      </Page>
    );
  }
}

const ContactEntryWithApollo = withApollo(ContactEntry);

const handleSubmit = async (
  payload,
  { props, resetForm, setErrors, setSubmitting }
) => {
  const {
    address,
    city,
    company,
    first_name,
    id,
    last_name,
    mail,
    mobile,
    phone,
    type,
    zip_code
  } = payload;
  let action;
  const variables = {
    contact: {
      address,
      city,
      company,
      first_name,
      id,
      last_name,
      mail,
      mobile,
      phone,
      type,
      zip_code
    },
    id: undefined
  };
  const { contactId } = props.match.params;
  if (contactId) {
    action = props.update_contact;
    variables.id = contactId;
  } else {
    action = props.insert_contact;
  }
  try {
    const response = await action({
      variables
    });
    if (contactId) {
      //props.setVariables(response.data.update_contacts.returning[0]);
      resetForm();
    } else {
      const createdContactId = response.data.insert_contacts.returning[0].id;
      props.history.replace(`/contacts/${createdContactId}`);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    const errors = e.graphQLErrors.map(error => error.message);
    // eslint-disable-next-line no-console
    console.log(errors);
    setSubmitting(false);
    setErrors({ form: errors });
  }
};

const withGraphQL = compose(
  graphql(CREATE_CONTACT, {
    name: "insert_contact",
    options: {
      refetchQueries: [
        {
          query: CONTACTS_AGGREGATED_QUERY,
          variables: { orderBy: { first_name: "asc" } }
        }
      ]
    }
  }),
  graphql(UPDATE_CONTACT, { name: "update_contact" }),
  withFormik({
    displayName: "ContactEntry",
    handleSubmit,
    mapPropsToValues: ({ variables }) => {
      return {
        ...variables
      };
    },
    validationSchema: schema
  })
)(ContactEntryWithApollo);

const ContactEntryWithIntl = withRouter(injectIntl(withGraphQL));

const ContactEntryWrapper = ({
  match: {
    params: { contactId }
  }
}) => {
  const [variables, setVariables] = useState(null);
  if (variables) {
    return (
      <ContactEntryWithIntl
        contactId={contactId}
        setVariables={setVariables}
        variables={variables}
      />
    );
  }
  if (contactId) {
    return (
      <Query query={GET_CONTACT} variables={{ contactId }}>
        {({ data, error, loading }) => {
          if (loading) {
            return <p>Loading...</p>;
          }
          if (error) {
            return <p>Error :(</p>;
          }

          return (
            <ContactEntryWithIntl
              contactId={contactId}
              setVariables={setVariables}
              variables={data.contacts[0]}
            />
          );
        }}
      </Query>
    );
  } else {
    return <ContactEntryWithIntl variables={{}} />;
  }
};

const ContactEntryWrapperWithRouter = withRouter(ContactEntryWrapper);
export { ContactEntryWrapperWithRouter as ContactEntry };
