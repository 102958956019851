import { BuildingInspectionCard } from "./building-inspection";
import { FormattedMessage } from "react-intl";
import { Grid } from "@material-ui/core";
import { MoistureTechnologyCard } from "./moisture-technology";
import React from "react";

export function CaseControl(): JSX.Element {
  return (
    <div
      style={{
        minHeight: "calc(100% - 64px)",
        padding: 20
      }}
    >
      <FormattedMessage
        defaultMessage="Byggetilsyn"
        id="case-control.building-inspection"
        tagName="h2"
      />
      <BuildingInspectionCard />

      <FormattedMessage
        defaultMessage="Fugtteknik"
        id="case-control.moisture-technology"
        tagName="h2"
      />
      <MoistureTechnologyCard />
    </div>
  );
}
