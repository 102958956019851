import gql from "graphql-tag";

export const CREATE_CASE_DOC_NOTE = gql`
  mutation insert_case_doc_note($case_doc_note: case_doc_notes_insert_input!) {
    insert_case_doc_notes(objects: [$case_doc_note]) {
      returning {
        id
        type
        case_id
        title
        text
      }
    }
  }
`;

export const CREATE_CASE_DOC_NOTES = gql`
  mutation insert_case_doc_notes(
    $case_doc_notes: [case_doc_notes_insert_input!]!
  ) {
    insert_case_doc_notes(objects: $case_doc_notes) {
      returning {
        id
        type
        case_id
        title
        text
      }
    }
  }
`;

export const GET_CASE_DOC_NOTE = gql`
  query get_case_doc_notes($caseNoteId: uuid) {
    case_doc_notes(where: { id: { _eq: $caseNoteId } }) {
      id
      case_id
      title
      text
      type
      document_date
      revision_date
    }
  }
`;

export const UPDATE_CASE_DOC_NOTE = gql`
  mutation update_case_doc_note(
    $id: uuid
    $case_doc_note: case_doc_notes_set_input!
  ) {
    update_case_doc_notes(where: { id: { _eq: $id } }, _set: $case_doc_note) {
      returning {
        id
        case_id
        title
        type
        text
        document_date
        revision_date
      }
    }
  }
`;
