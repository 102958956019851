import {AppContext} from "../AppContext";
import Drawer from "@material-ui/core/Drawer";
import ListSideBar from "./ListSideBar";
import React from "react";

export default function SideBar({isSideBarOpen, toggleDrawer, variant}) {
  return (
    <Drawer
      open={isSideBarOpen}
      PaperProps={{
        style:
          variant === "permanent"
            ? {overflowX: "hidden", width: 256}
            : undefined,
      }}
      variant={variant}
      onClose={toggleDrawer}
    >
      <div role="button" tabIndex={0} onClick={toggleDrawer}>
        <AppContext.Consumer>
          {context => (
            <ListSideBar context={context} isMobile={context.state.isMobile} />
          )}
        </AppContext.Consumer>
      </div>
    </Drawer>
  );
}
