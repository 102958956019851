/* eslint-disable react/jsx-no-bind */
import { Absence } from "./pages/absence";
import { AbsenceEntry } from "./pages/absence/absence-entry";
import { AbsenceTypeEntry } from "./pages/settings/absence-types";
import { ArchiveFilesEntry } from "./pages/files/entry";
import { CaseEconomyEntry } from "./pages/case/docs/economy/entry";
import { CaseEntry } from "./pages/case/case-entry";
import { CaseNoteEntry } from "./pages/case/docs/notes/entry";
import { CaseTypesEntry } from "./pages/settings/case-types-entry";
import { ChecklistsEntry } from "./pages/case/docs/checklists/entry";
import { ConstructionPlanEntry } from "./pages/settings/construction-plans/construction-plans";
import { ConstructionPlanEntryEntry } from "./pages/settings/construction-plans/construction-plan-entries";
import { ContactEntry } from "./pages/contact/contact-entry";
import { FileList } from "./pages/files/list";
import { FilesEntry } from "./pages/case/files/entry";
import { Login } from "./pages/login";
import { MyCases } from "./pages/my-cases";
import { OrderConfirmationTemplatesEntry } from "./pages/settings/order-confirmation-templates-entry";
import { OrderConfirmationsEntry } from "./pages/case/docs/order-confirmations/order-confirmations-entry";
import { Overview } from "./pages/overview";
import { PhotosEntry } from "./pages/case/photos/entry";
import { QualityControlTemplatesEntry } from "./pages/settings/quality-control-templates-entry";
import { QualityControlsEntry } from "./pages/case/docs/quality-controls/entry";
import { ReminderEntry } from "./components/reminders/reminders-entry";
import { ReminderList } from "./pages/my-reminders/reminder-list";
import { RequestEntry } from "./pages/request/request-entry";
import { RequestList } from "./pages/request/request-list";
import { Route, HashRouter as Router, Switch } from "react-router-dom";
import { StandardPhotosEntry } from "./pages/settings/photos/entry";
import { Statistics } from "./pages/statistics";
import { TimeEntry } from "./pages/case/time/entry";
import { UserEntry } from "./pages/user/user-entry";
import CaseList from "./pages/case/case-list";
import ContactList from "./pages/contact/contact-list";
import NotFound from "./components/NotFound";
import NotImplemented from "./pages/not-implementet";
import React from "react";
import Settings from "./pages/settings";
import UserList from "./pages/user/user-list";
import UserProfile from "./pages/user-profile/";

export function AppRoutes(): JSX.Element {
  return (
    <Router>
      <Switch>
        <Route exact component={CaseList} path="/" />
        <Route
          component={ReminderEntry}
          path="/cases/requests/:requestId/reminders/create"
        />
        <Route
          component={ReminderEntry}
          path="/cases/requests/:requestId/reminders/:reminderId"
        />
        <Route component={RequestEntry} path="/cases/requests/new" />
        <Route component={RequestEntry} path="/cases/requests/:requestId" />
        <Route component={RequestList} path="/cases/requests" />
        <Route component={CaseEntry} path="/cases/create" />
        <Route component={CaseList} path="/cases/list/:state" />
        <Route
          component={ReminderEntry}
          path="/cases/:caseId/reminders/create"
        />
        <Route
          component={ReminderEntry}
          path="/cases/:caseId/reminders/:reminderId"
        />
        <Route
          exact
          component={CaseNoteEntry}
          path="/cases/:caseId/note/create"
        />
        <Route
          component={ReminderEntry}
          path="/cases/:caseId/note/:caseNoteId/reminders/create"
        />
        <Route
          component={ReminderEntry}
          path="/cases/:caseId/note/:caseNoteId/reminders/:reminderId"
        />
        <Route
          component={CaseNoteEntry}
          path="/cases/:caseId/note/:caseNoteId"
        />
        <Route
          exact
          component={CaseEconomyEntry}
          path="/cases/:caseId/economy/create"
        />
        <Route
          component={CaseEconomyEntry}
          path="/cases/:caseId/economy/:economyId"
        />

        <Route
          exact
          component={OrderConfirmationsEntry}
          path="/cases/:caseId/order-confirmation/create/:templateId"
        />
        <Route
          exact
          component={OrderConfirmationsEntry}
          path="/cases/:caseId/order-confirmation/create"
        />
        <Route
          component={ReminderEntry}
          path="/cases/:caseId/order-confirmation/:orderConfirmationId/reminders/create"
        />
        <Route
          component={ReminderEntry}
          path="/cases/:caseId/order-confirmation/:orderConfirmationId/reminders/:reminderId"
        />
        <Route
          component={OrderConfirmationsEntry}
          path="/cases/:caseId/order-confirmation/:orderConfirmationId"
        />

        <Route
          exact
          component={QualityControlsEntry}
          path="/cases/:caseId/quality-control/create/:templateId"
        />
        <Route
          exact
          component={QualityControlsEntry}
          path="/cases/:caseId/quality-control/create"
        />
        <Route
          component={ReminderEntry}
          path="/cases/:caseId/quality-control/:qualityControlId/reminders/create"
        />
        <Route
          component={ReminderEntry}
          path="/cases/:caseId/quality-control/:qualityControlId/reminders/:reminderId"
        />
        <Route
          component={QualityControlsEntry}
          path="/cases/:caseId/quality-control/:qualityControlId"
        />

        <Route
          exact
          component={ChecklistsEntry}
          path="/cases/:caseId/checklist/create"
        />
        <Route
          component={ReminderEntry}
          path="/cases/:caseId/checklist/:checklistId/reminders/create"
        />
        <Route
          component={ReminderEntry}
          path="/cases/:caseId/checklist/:checklistId/reminders/:reminderId"
        />
        <Route
          component={ChecklistsEntry}
          path="/cases/:caseId/checklist/:checklistId"
        />

        <Route
          exact
          component={PhotosEntry}
          path="/cases/:caseId/photos/:photoId"
        />
        <Route
          exact
          component={FilesEntry}
          path="/cases/:caseId/files/:fileId"
        />

        <Route exact component={TimeEntry} path="/cases/:caseId/time/create" />
        <Route component={TimeEntry} path="/cases/:caseId/time/:timeId" />

        <Route component={CaseEntry} path="/cases/:caseId" />
        {/* TODO: Handling tabs and additional pages. */}
        <Route
          component={props => (
            <NotImplemented {...props} title="Builderpedia" />
          )}
          path="/builderpedia"
        />
        <Route component={Overview} path="/overview" />
        <Route component={TimeEntry} path="/timeregistration" />
        <Route component={ReminderList} path="/reminders" />
        <Route component={ContactEntry} path="/contacts/create" />
        <Route component={ContactEntry} path="/contacts/:contactId" />
        <Route component={AbsenceEntry} path="/absence/create" />
        <Route component={AbsenceEntry} path="/absence/update/:id" />
        <Route component={Absence} path="/absence/:tab" />
        <Route component={Absence} path="/absence" />
        <Route component={MyCases} path="/my-cases" />
        <Route component={ContactList} path="/contacts" />
        <Route exact component={FileList} path="/files" />
        <Route component={ArchiveFilesEntry} path="/files/:fileId" />
        {/* As far as I can see its all .pdf TODO: Maybe better name */}
        <Route component={UserProfile} path="/profile" />
        {/* TODO: Decide what values they should be able to alter. Cascade in db. */}
        <Route component={Login} path="/login" />
        <Route
          component={props => <NotImplemented {...props} title="Firmaprofil" />}
          path="/company"
        />
        {/* Decide if it should be here or somewhere else e.g. settings page. Very rarely changed and only used in pdf-generator. Admin only */}
        <Route component={CaseTypesEntry} path="/settings/case-types/create" />
        <Route
          component={CaseTypesEntry}
          path="/settings/case-types/:caseTypeId"
        />
        <Route
          component={OrderConfirmationTemplatesEntry}
          path="/settings/order-confirmations/create"
        />
        <Route
          component={OrderConfirmationTemplatesEntry}
          path="/settings/order-confirmations/:id"
        />
        <Route
          component={QualityControlTemplatesEntry}
          path="/settings/quality-control/create"
        />
        <Route
          component={QualityControlTemplatesEntry}
          path="/settings/quality-control/:id"
        />
        <Route
          component={AbsenceTypeEntry}
          path="/settings/absence-types/create"
        />
        <Route
          component={AbsenceTypeEntry}
          path="/settings/absence-types/:id"
        />
        <Route
          component={ConstructionPlanEntryEntry}
          path="/settings/construction-plans/:planId/create-entry"
        />
        <Route
          component={ConstructionPlanEntryEntry}
          path="/settings/construction-plans/:planId/:id"
        />
        <Route
          component={ConstructionPlanEntry}
          path="/settings/construction-plans/create"
        />
        <Route
          component={ConstructionPlanEntry}
          path="/settings/construction-plans/:id"
        />
        <Route
          component={StandardPhotosEntry}
          path="/settings/photos/:photoId"
        />
        <Route component={Settings} path="/settings/:tab" />
        <Route component={Settings} path="/settings" />
        <Route component={Statistics} path="/statistics/:tab" />
        <Route component={Statistics} path="/statistics" />
        <Route component={UserEntry} path="/users/create" />
        {/* TODO: Handle password. Maybe hash from client-side. https://www.npmjs.com/package/hasha */}
        <Route component={UserEntry} path="/users/:userId" />
        {/* TODO: Handle password */}
        <Route component={UserList} path="/users" />
        <Route component={NotFound} />
        {/* TODO: Add better not found page */}
      </Switch>
    </Router>
  );
}
