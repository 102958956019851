import {DialogBase} from "../../../components/dialog";
import {
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import React from "react";

const NewDocumentDialog = ({
  caseId,
  onCancel,
  onRequestOrderConfirmationDialog,
  onRequestQualityControlDialog,
  open
}) => (
  <DialogBase
    open={open}
    title={
      <FormattedMessage
        defaultMessage="Vælg dokument"
        id="new-document-dialog.title"
      />
    }
    onCancel={onCancel}
  >
    <DialogContent>
      <List>
        <ListItem button component="a" href={`#/cases/${caseId}/note/create`}>
          <ListItemText
            primary={
              <FormattedMessage
                defaultMessage="Note"
                id="new-document-dialog.note"
              />
            }
          />
        </ListItem>
        <Divider />
        <ListItem button onClick={onRequestOrderConfirmationDialog}>
          <ListItemText
            primary={
              <FormattedMessage
                defaultMessage="Ordrebekræftelse"
                id="new-document-dialog.order-confirmation"
              />
            }
          />
        </ListItem>
        <Divider />
        <ListItem button onClick={onRequestQualityControlDialog}>
          <ListItemText
            primary={
              <FormattedMessage
                defaultMessage="Tilsynsnotat"
                id="new-document-dialog.quality-control"
              />
            }
          />
        </ListItem>
        <Divider />
        <ListItem button component="a" href={`#/cases/${caseId}/checklist/create`}>
          <ListItemText
            primary={
              <FormattedMessage
                defaultMessage="Mangelliste"
                id="new-document-dialog.checklist"
              />
            }
          />
        </ListItem>
      <Divider />
      <ListItem button component="a" href={`#/cases/${caseId}/economy/create`}>
          <ListItemText
            primary={
              <FormattedMessage
                defaultMessage="Økonomi"
                id="new-document-dialog.economy"
              />
            }
          />
        </ListItem>
      </List>
    </DialogContent>
  </DialogBase>
);

const memoed = React.memo(NewDocumentDialog);
export {memoed as NewDocumentDialog};
