import {DatePickerField} from "../../../../components/date-time-fields";
import {Field} from "formik";
import {FormattedMessage, injectIntl} from "react-intl";
import {Grid} from "@material-ui/core";
import {Tasks} from "../../../../components/quality-control-tasks";
import {formikPropTypes} from "../../../../utils/form-helpers";
import React from "react";
import TextField from "@material-ui/core/TextField";

const QualityControlForm =
  // eslint-disable-next-line react/prop-types
  ({caseId, errors, handleBlur, handleChange, handleSubmit, values}) => (
    <>
      <form onSubmit={handleSubmit}>
        <div>{errors.form}</div>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Field
              fullWidth
              component={DatePickerField}
              label={
                <FormattedMessage
                  defaultMessage="Dokument dato"
                  id="quality-controls-form.document-date"
                />
              }
              name="document_date"
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Field
              fullWidth
              component={DatePickerField}
              label={
                <FormattedMessage
                  defaultMessage="Rev. dato"
                  id="quality-controls-form.revision_date"
                />
              }
              name="revision_date"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={errors.title}
              label={
                <FormattedMessage
                  defaultMessage="Titel"
                  id="quality-controls-form.document-title"
                />
              }
              name="title"
              type="text"
              value={values.title}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field
              fullWidth
              component={DatePickerField}
              label={
                <FormattedMessage
                  defaultMessage="Inspektionsdato"
                  id="quality-controls-form.date_of_inspection"
                />
              }
              name="date_of_inspection"
            />
          </Grid>
        </Grid>

        <h4>
          <FormattedMessage
            defaultMessage="Punkter"
            id="quality-controls-form.tasks"
          />
        </h4>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field useArrows useCasePhotos caseId={caseId} component={Tasks} name="tasks" />
          </Grid>
        </Grid>
      </form>
    </>
  );
QualityControlForm.propTypes = {
  ...formikPropTypes
};

const QualityControlFormWithIntl = injectIntl(React.memo(QualityControlForm));

export {QualityControlFormWithIntl as QualityControlForm};
