import gql from "graphql-tag";

export const ARCHIVE_FILES = gql`
  query list_archive_files(
    $orderBy: archive_files_order_by!
    $where: archive_files_bool_exp
    $limit: Int
  ) {
    archive_files(order_by: [$orderBy], where: $where, limit: $limit) {
      id
      file_id
      title
      file {
        id
        share_key
        filename
        created
      }
    }
  }
`;

export const CREATE_ARCHIVE_FILE = gql`
  mutation insert_archive_file($archive_files: [archive_files_insert_input!]!) {
    insert_archive_files(objects: $archive_files) {
      returning {
        id
        file_id
        title
        file {
          id
          share_key
          filename
          created
        }
      }
    }
  }
`;

export const GET_ARCHIVE_FILE = gql`
  query get_archive_files($fileId: uuid) {
    archive_files(where: { id: { _eq: $fileId } }) {
      id
      file_id
      title
      file {
        id
        filename
        share_key
        created
      }
    }
  }
`;

export const UPDATE_ARCHIVE_FILE = gql`
  mutation update_archive_file(
    $id: uuid
    $archive_file: archive_files_set_input!
  ) {
    update_archive_files(where: { id: { _eq: $id } }, _set: $archive_file) {
      returning {
        id
        file_id
        title
        file {
          id
          share_key
          filename
          created
        }
      }
    }
  }
`;

export const DELETE_ARCHIVE_FILE = gql`
  mutation delete_archive_files($where: archive_files_bool_exp!) {
    delete_archive_files(where: $where) {
      affected_rows
    }
  }
`;
