/* eslint-disable react/jsx-no-bind */
import {RichUtils} from "draft-js";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";

/**
 * @TODO
 *
 * Refactor to make the chained loops more readable.
 * Relying on index position is not easily maintainable.
 */

 const labelToIcon={
    OL:<FormatListBulletedIcon />,
    UL:<FormatListNumberedIcon />,
    Bold:<FormatBoldIcon />,
    Italic:<FormatItalicIcon />,
    Underline:<FormatUnderlinedIcon />,
 }

const Tray = props => (
  <ButtonsList>
      <ButtonListItem>
    {[props.blockOptions, props.inlineOptions].map((arr, num) => (
        arr.map(({label, style}, i) => (
          <Button
            key={i}
            type="button"
            active={
              num === 0
                ? RichUtils.getCurrentBlockType(props.value) === style
                : props.value.getCurrentInlineStyle().has(style)
            }
            onMouseDown={(e) => {e.preventDefault();
              props.onChange(
                RichUtils[num === 0 ? "toggleBlockType" : "toggleInlineStyle"](
                  props.value,
                  style
                )
              )
            }
            }
            onMouseUp={() =>
              props.editor ? props.editor.current.focus() : null
            }
          >
            {labelToIcon[label]}
          </Button>
        ))
    ))}
      </ButtonListItem>
  </ButtonsList>
);

Tray.propTypes = {
  value: PropTypes.object.isRequired,
  blockOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      style: PropTypes.oneOf([
        "unstyled",
        "paragraph",
        "header-one",
        "header-two",
        "header-three",
        "header-four",
        "header-five",
        "header-six",
        "unordered-list-item",
        "ordered-list-item",
        "blockquote",
        "code-block",
        "atomic"
      ]).isRequired
    })
  ),
  focus: PropTypes.object,
  inlineOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      style: PropTypes.oneOf([
        "BOLD",
        "ITALIC",
        "UNDERLINE",
        "STRIKETRHOUGH",
        "fontFamily",
        "fontSize",
        "|",
        "inlineStyle",
        "paragraphFormat",
        "align",
        "undo",
        "redo",
        "html"
      ]).isRequired
    })
  )
};

Tray.defaultProps = {
  blockOptions: [
    // {label: "H1", style: "header-one"},
    // {label: "H2", style: "header-two"},
    // {label: "H3", style: "header-three"},
    // {label: "H4", style: "header-four"},
    // {label: "H5", style: "header-five"},
    // {label: "H6", style: "header-six"},
    {label: "OL", style: "unordered-list-item"},
    {label: "UL", style: "ordered-list-item"}

  ],
  inlineOptions: [
    {label: "Bold", style: "BOLD"},
    {label: "Italic", style: "ITALIC"},
    {label: "Underline", style: "UNDERLINE"}
  ]
};

export const ButtonsList = styled.ul`
  list-style: none;
  margin: 0 0 1rem;
  padding: 0.5rem;
`;

export const ButtonListItem = styled.li`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1em;

  &:not(:first-of-type) {
    font-size: small;
  }
`;

export const Button = styled.button`
  background: ${props =>
    props.active ? "rgba(221, 221, 221, 0.3)" : "transparent"};
  border: 0;
  border-radius: 5px;
  color: ${props => (props.active ? "#444" : "#000")};
  cursor: pointer;
  padding: 0.5rem;
  font-size: inherit;
  line-height: 1;
  outline: 0;
  transition-duration: 500ms;
  transition-property: background-color, color;

  &:focus,
  &:hover {
    color: #444;
  }
`;

export default Tray;
