import gql from "graphql-tag";

export const GET_TIME = gql`
  query get_time($timeId: uuid!) {
    case_time_entries_by_pk(id: $timeId) {
      date
      description
      id
      time
      user_id
      user {
        acronym
        first_name
        last_name
        mail
      }
    }
  }
`;

export const CREATE_CASE_TIME = gql`
  mutation insert_case_time($caseTime: case_time_entries_insert_input!) {
    insert_case_time_entries(objects: [$caseTime]) {
      returning {
        date
        description
        id
        time
        user_id
        user {
          acronym
          first_name
          last_name
          mail
        }
      }
    }
  }
`;

export const UPDATE_CASE_TIME = gql`
  mutation update_case_time(
    $id: uuid
    $caseTime: case_time_entries_set_input!
  ) {
    update_case_time_entries(where: { id: { _eq: $id } }, _set: $caseTime) {
      returning {
        date
        description
        id
        time
        user_id
        user {
          acronym
          first_name
          last_name
          mail
        }
      }
    }
  }
`;
