import gql from "graphql-tag";

export const STANDARD_PHOTOS = gql`
  query list_standard_photos(
    $orderBy: standard_photos_order_by!
    $where: standard_photos_bool_exp
    $limit: Int
  ) {
    standard_photos(order_by: [$orderBy], where: $where, limit: $limit) {
      id
      file_id
      category
      title
      file {
        id
        share_key
        filename
        created
      }
    }
  }
`;

export const CREATE_STANDARD_PHOTO = gql`
  mutation insert_standard_photo(
    $standard_photos: [standard_photos_insert_input!]!
  ) {
    insert_standard_photos(objects: $standard_photos) {
      returning {
        id
        file_id
        category
        title
        file {
          id
          share_key
          filename
          created
        }
      }
    }
  }
`;

export const GET_STANDARD_PHOTO = gql`
  query get_standard($photoId: uuid) {
    standard_photos(where: {id: {_eq: $photoId}}) {
      id
      file_id
      category
      title
      file {
        id
        filename
        share_key
        created
      }
    }
  }
`;

export const UPDATE_STANDARD_PHOTO = gql`
  mutation update_standard_photo(
    $id: uuid
    $standard_photo: standard_photos_set_input!
  ) {
    update_standard_photos(where: {id: {_eq: $id}}, _set: $standard_photo) {
      returning {
        id
        file_id
        category
        title
        file {
          id
          share_key
          filename
          created
        }
      }
    }
  }
`;

export const DELETE_STANDARD_PHOTO = gql`
  mutation delete_standard_photos($photoId: uuid) {
    delete_standard_photos(where: {id: {_eq: $photoId}}) {
      affected_rows
    }
  }
`;
