import styled from 'styled-components';

export default styled.div`
    box-sizing: border-box;
    position: relative;
    max-width: 100%;

    [contenteditable] {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    box-sizing: border-box;
        min-height: 100px;
        padding-bottom: 0.5rem;
    }
`;