import gql from "graphql-tag";

export const CREATE_CASE_DOC_ECONOMY = gql`
  mutation insert_case_doc_economy(
    $case_doc_economy: case_doc_economy_insert_input!
  ) {
    insert_case_doc_economy(objects: [$case_doc_economy]) {
      returning {
        id
        type
        case_id
        title
        text
      }
    }
  }
`;

export const CREATE_CASE_DOC_ECONOMYS = gql`
  mutation insert_case_doc_economys(
    $case_doc_economy: [case_doc_economy_insert_input!]!
  ) {
    insert_case_doc_economy(objects: $case_doc_economy) {
      returning {
        id
        type
        case_id
        title
        text
      }
    }
  }
`;

export const GET_CASE_DOC_ECONOMY = gql`
  query get_case_doc_economy($economyId: uuid) {
    case_doc_economy(where: { id: { _eq: $economyId } }) {
      id
      case_id
      title
      text
      type
      document_date
      revision_date
    }
  }
`;

export const UPDATE_CASE_DOC_ECONOMY = gql`
  mutation update_case_doc_economy(
    $id: uuid
    $case_doc_economy: case_doc_economy_set_input!
  ) {
    update_case_doc_economy(
      where: { id: { _eq: $id } }
      _set: $case_doc_economy
    ) {
      returning {
        id
        case_id
        title
        type
        text
        document_date
        revision_date
      }
    }
  }
`;
