import * as jwt_decode from "jwt-decode";
import { AppContext } from "../components/AppContext";
import { Container, CssBaseline } from "@material-ui/core";
import { RouterProps } from "react-router";
import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React, { Component, FormEvent, FunctionComponent } from "react";
import SnackBarCustom from "../components/layout/SnackBarCustom";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

const styles: any = {
  "@global": {
    body: {
      backgroundColor: "#FFF"
    }
  },
  avatar: {
    backgroundColor: "#000",
    margin: 10
  },
  form: {
    marginTop: 10, // Fix IE 11 issue.
    width: "100%"
  },
  paper: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: 80
  },
  submit: {
    marginTop: 10
  }
};

interface Props extends RouterProps {
  classes?: any;
  context: any;
  intl: any;
}

interface State {
  error?: string;
  mail: string;
  password: string;
}

class LoginBase extends Component<Props, State> {
  state = {
    error: null,
    mail: localStorage.getItem("email") || "",
    password: ""
  };

  child?: SnackBarCustom;

  handleLogin = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const { mail, password } = this.state;
    fetch("/login", {
      body: JSON.stringify({
        mail: mail.toLowerCase(),
        password
      }),
      headers: { "Content-Type": "application/json" },
      method: "post"
    })
      .then(async response => {
        if (!response.ok) {
          throw Error("Fejl ved login");
        }
        const data = await response.json();
        this.setState({ error: null });
        localStorage.setItem("token", data.token);
        localStorage.setItem("email", mail.toLowerCase());
        const userInformation = jwt_decode(data.token);
        this.props.context.setCurrentUser({
          acronym: userInformation.acronym,
          id: userInformation.sub,
          ...userInformation,
          mail: userInformation.name
        });
        window.location.href = "/";
        return;
      })
      .catch((error: string) => {
        this.setState({ error });
      });
  };

  render(): JSX.Element {
    const { classes } = this.props;
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Log Ind
          </Typography>
          {this.state.error ? (
            <Typography color="error" component="h1" variant="h5">
              Fejl i login
            </Typography>
          ) : null}
          <form noValidate className={classes.form} onSubmit={this.handleLogin}>
            <TextField
              autoFocus
              fullWidth
              required
              autoComplete="mail"
              id="mail"
              label="Email Address"
              margin="normal"
              name="mail"
              value={this.state.mail}
              variant="outlined"
              onChange={e => this.setState({ mail: e.target.value })}
            />
            <TextField
              fullWidth
              required
              autoComplete="current-password"
              id="password"
              label="Password"
              margin="normal"
              name="password"
              type="password"
              variant="outlined"
              onChange={e => this.setState({ password: e.target.value })}
            />
            <Button
              fullWidth
              className={classes.submit}
              color="primary"
              type="submit"
              variant="contained"
            >
              Login
            </Button>
          </form>
        </div>
      </Container>
    );
  }
}

interface WrapperProps extends RouterProps {
  intl: any;
}

const LoginWithContext: FunctionComponent<WrapperProps> = React.memo(
  function LoginWithContext(props) {
    return (
      <AppContext.Consumer>
        {context => <LoginBase context={context} {...props} />}
      </AppContext.Consumer>
    );
  }
);

const LoginWithIntl = withStyles(styles)(injectIntl(LoginWithContext));

export const Login = LoginWithIntl;
