import { FormattedDate, defineMessages, useIntl } from "react-intl";
import { MUTable } from "../../components/mu-table";
import { createWhereObject } from "../../utils/form-helpers";
import { useHistory } from "react-router";
import MuFab from "../../components/fab";
import React, { useCallback } from "react";
import _ from "lodash";
import gql from "graphql-tag";
const messages = defineMessages({
  count: {
    defaultMessage: "Antal",
    id: "absence-list.count"
  },
  end: {
    defaultMessage: "Slut",
    id: "absence-list.end"
  },
  start: {
    defaultMessage: "Start",
    id: "absence-list.start"
  },
  type: {
    defaultMessage: "Type",
    id: "absence-list.type"
  },
  user: {
    defaultMessage: "Initialer",
    id: "absence-list.initials"
  }
});

const GET_ABSENCE = gql`
  query getAbsence(
    $orderBy: absence_search_view_order_by!
    $where: absence_search_view_bool_exp
    $limit: Int
  ) {
    absence_search_view(order_by: [$orderBy], where: $where, limit: $limit) {
      id
      start
      end
      half_day
      employee {
        id
        mail
        acronym
        first_name
        last_name
      }
      absence_type {
        label
      }
    }
  }
`;

const DateFormatter = ({ date }: { date: Date }): JSX.Element => {
  return <FormattedDate value={date} />;
};

export function getBusinessDatesCount(
  startDate: Date,
  endDate: Date,
  half_day: boolean
): number {
  let count = 0;
  const curDate = startDate;
  // eslint-disable-next-line no-unmodified-loop-condition
  while (curDate <= endDate) {
    const dayOfWeek = curDate.getDay();
    if (!(dayOfWeek === 6 || dayOfWeek === 0)) {
      if (half_day) {
        count += 0.5;
      } else {
        count += 1;
      }
    }
    curDate.setDate(curDate.getDate() + 1);
  }
  return count;
}

export const AbsenceList = ({ search }: { search: string }): JSX.Element => {
  const history = useHistory();
  const handleRowClick = useCallback(
    absence => {
      history.push(`/absence/update/${absence.id}`);
    },
    [history]
  );
  const handleFabClick = useCallback(() => {
    history.push("/absence/create");
  }, [history]);
  const { formatMessage } = useIntl();

  const where = createWhereObject(
    [
      "text_start",
      "text_end",
      "employee.mail",
      "employee.acronym",
      "employee.first_name",
      "employee.last_name",
      "absence_type.label"
    ],
    search
  );

  const MUTableWithoutTypes: any = MUTable;
  return (
    <>
      <MUTableWithoutTypes
        columns={[
          {
            field: "employee__acronym",
            render: absence => absence.employee.acronym,
            title: formatMessage(messages.user)
          },
          {
            field: "absence_type__label",
            render: absence => absence.absence_type.label,
            title: formatMessage(messages.type)
          },
          {
            field: "start",
            render: absence =>
              getBusinessDatesCount(
                new Date(absence.start),
                new Date(absence.end),
                absence.half_day
              ),
            title: formatMessage(messages.count)
          },
          {
            field: "start",
            // eslint-disable-next-line react/display-name
            render: absence => <DateFormatter date={absence.start} />,
            title: formatMessage(messages.start)
          },
          {
            field: "end",
            // eslint-disable-next-line react/display-name
            render: absence => <DateFormatter date={absence.end} />,
            title: formatMessage(messages.end)
          }
        ]}
        dataSourceName="absence_search_view"
        gql={GET_ABSENCE}
        orderBy="start"
        where={where}
        onRowClick={handleRowClick}
      />
      <MuFab onClick={handleFabClick} />
    </>
  );
};
