import { AppContext } from "../AppContext";
import {
  CircularProgress,
  DialogContent,
  IconButton,
  Typography
} from "@material-ui/core";
import { DialogBase } from "../dialog";
import {
  DownloadFileIcon,
  getFileMailLink,
  getFileURL
} from "../../pages/case/docs/list";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { SearchField } from "../search-field";
import { StringParam, useQueryParam } from "use-query-params";
import { Theme, fade, makeStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/styles";
import { useHistory } from "react-router";
import AppBar from "@material-ui/core/AppBar";
import BackIcon from "@material-ui/icons/ArrowBack";
import Bowser from "bowser";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import DoneIcon from "@material-ui/icons/Done";
import FilterListIcon from "@material-ui/icons/FilterList";
import MailIcon from "@material-ui/icons/Mail";
import MenuButton from "./MenuButton";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import Toolbar from "@material-ui/core/Toolbar";
import WarningIcon from "@material-ui/icons/Warning";

const browser = Bowser.getParser(window.navigator.userAgent);

const isMobile =
  browser.getPlatformType(true) !== "desktop" ||
  (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

const messages = defineMessages({
  delete: {
    defaultMessage: "Slet",
    id: "header.delete"
  },
  reset: {
    defaultMessage: "Nulstil formular",
    id: "header.reset"
  }
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      "@media (min-width:1025px)": {
        width: isMobile ? "100%" : "calc(100% - 240px)"
      }
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        "&:focus": {
          width: 200
        },
        width: 120
      }
    },
    inputRoot: {
      color: "inherit"
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    root: {
      flexGrow: 1
    },
    search: {
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25)
      },
      backgroundColor: fade(theme.palette.common.white, 0.15),
      borderRadius: theme.shape.borderRadius,
      marginLeft: 0,
      position: "relative",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto"
      }
    },
    searchIcon: {
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      pointerEvents: "none",
      position: "absolute",
      width: theme.spacing(7)
    },
    title: {
      display: "none",
      flexGrow: 1,
      [theme.breakpoints.up("sm")]: {
        display: "block"
      }
    }
  })
);

interface HeaderProps {
  allowDelete?: boolean;
  backLocation?: string;
  children?: JSX.Element;
  dirty?: boolean;
  doc?: any;
  onDeleteClick?: () => void;
  onFilterClearClick?: () => void;
  onFilterClick?: () => void;
  onResetButton?: () => void;
  onSaveButton?: () => void;
  onSearchChange?: (searchString: string) => void;
  showMenuButton?: boolean;
  title: string | JSX.Element;
}

function Header(props: HeaderProps): JSX.Element {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const history = useHistory();

  const [searchString, setSearchString] = useQueryParam("q", StringParam);

  const handleBackClick = useCallback(() => {
    if (props.backLocation) {
      history.push(props.backLocation);
    } else {
      window.history.back();
    }
  }, [history, props.backLocation]);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchString(event.target.value);
      props.onSearchChange(event.target.value);
    },
    [props, setSearchString]
  );

  const handleResetButtonClick = useCallback(() => {
    setResetDialogOpen(true);
  }, []);
  const handleResetDialogCancel = useCallback(() => {
    setResetDialogOpen(false);
  }, []);
  const handleResetDialogOk = useCallback(() => {
    setResetDialogOpen(false);
    props.onResetButton();
  }, [props]);

  const handleClearSearch = useCallback(() => {
    setSearchString("");
    props.onSearchChange("");
  }, [props, setSearchString]);

  const handleDownloadClick = useCallback(doc => {
    window.open(getFileURL(doc), "_blank");
  }, []);

  const handleDeleteClick = useCallback(() => {
    setDeleteDialogOpen(true);
  }, []);

  const handleDeleteDialogOk = useCallback(() => {
    setDeleteDialogOpen(false);
    props.onDeleteClick();
  }, [props]);

  const handleDeleteDialogCancel = useCallback(() => {
    setDeleteDialogOpen(false);
  }, []);
  const intl = useIntl();
  const classes = useStyles();
  const {
    dirty,
    doc,
    onDeleteClick,
    onFilterClearClick,
    onFilterClick,
    onSaveButton,
    onSearchChange
  } = props;
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (inputRef.current && !isMobile) {
      inputRef.current.focus();
    }
  });
  let searchField;
  if (onSearchChange) {
    searchField = (
      <SearchField
        autoFocus={!isMobile}
        inputRef={inputRef}
        value={searchString || ""}
        onChange={handleSearchChange}
      />
    );
  }
  let warning;
  if (dirty) {
    warning = (
      <div style={{ color: "orange" }}>
        <IconButton
          aria-label="Reset"
          color="inherit"
          style={{ marginLeft: 5 }}
          onClick={handleResetButtonClick}
        >
          <WarningIcon color="inherit" />
        </IconButton>
      </div>
    );
  }
  const DialogBaseWithoutType = DialogBase as any;
  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar>
          {props.showMenuButton !== false ? <MenuButton /> : null}
          {onSaveButton ? (
            <>
              <IconButton
                aria-label="Tilbage"
                color="inherit"
                onClick={handleBackClick}
              >
                <BackIcon />
              </IconButton>
              <IconButton
                aria-label="Gem"
                color="inherit"
                style={{ marginLeft: 5 }}
                onClick={onSaveButton}
              >
                <DoneIcon />
              </IconButton>
              {warning}
            </>
          ) : null}
          {doc ? (
            <>
              <IconButton
                color="inherit"
                component="a"
                disabled={dirty || !doc.case || !doc.case.builder}
                href={
                  doc.case && doc.case.builder
                    ? getFileMailLink(doc.case, doc.title, getFileURL(doc))
                    : ""
                }
                size="small"
                style={{ marginLeft: 5, marginRight: 10 }}
              >
                <MailIcon />
              </IconButton>
              <DownloadFileIcon
                disabled={dirty || !doc.case}
                doc={doc}
                onClick={handleDownloadClick}
              />
            </>
          ) : null}
          <Typography className={classes.title} color="inherit" variant="h6">
            {props.title}
          </Typography>
          <AppContext.Consumer>
            {context =>
              context.state.loading ? (
                <>
                  {typeof context.state.loading == "string"
                    ? context.state.loading
                    : null}
                  <CircularProgress color="inherit" size={32} />
                </>
              ) : null
            }
          </AppContext.Consumer>
          {searchField ? (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              {searchField}

              {searchString ? (
                <div style={{ display: "inline", position: "relative" }}>
                  <IconButton
                    aria-label="Nulstil søgning"
                    color="inherit"
                    onClick={handleClearSearch}
                  >
                    <ClearIcon />
                  </IconButton>
                </div>
              ) : null}
            </div>
          ) : null}
          {onFilterClearClick ? (
            <IconButton
              aria-label="Fjern filter"
              color="inherit"
              onClick={onFilterClearClick}
            >
              <DeleteSweepIcon />
            </IconButton>
          ) : null}
          {onFilterClick ? (
            <IconButton
              aria-label="Filter"
              color="inherit"
              onClick={onFilterClick}
            >
              <FilterListIcon />
            </IconButton>
          ) : null}
          {props.children}
          <AppContext.Consumer>
            {context =>
              (props.allowDelete ||
                (context.state.currentUser &&
                  context.state.currentUser.role === "admin")) &&
              onDeleteClick ? (
                <IconButton
                  aria-label="Filter"
                  color="inherit"
                  onClick={handleDeleteClick}
                >
                  <DeleteIcon />
                </IconButton>
              ) : null
            }
          </AppContext.Consumer>
        </Toolbar>
        <DialogBaseWithoutType
          open={resetDialogOpen}
          title={intl.formatMessage(messages.reset)}
          onCancel={handleResetDialogCancel}
          onOk={handleResetDialogOk}
        >
          <DialogContent>
            <FormattedMessage
              defaultMessage="Er du sikker på du vil nulstille denne formular?"
              id="header.reset-message"
            />
          </DialogContent>
        </DialogBaseWithoutType>
        <DialogBaseWithoutType
          open={deleteDialogOpen}
          title={intl.formatMessage(messages.delete)}
          onCancel={handleDeleteDialogCancel}
          onOk={handleDeleteDialogOk}
        >
          <DialogContent>
            <FormattedMessage
              defaultMessage="Er du sikker på du vil slette?"
              id="header.delete-message"
            />
          </DialogContent>
        </DialogBaseWithoutType>
      </AppBar>
    </div>
  );
}

Header.defualtProps = {
  showMenuButton: true
};

export default Header;
