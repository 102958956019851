import {
  Button,
  Checkbox,
  DialogContent,
  FormControlLabel,
  Grid,
  TextField
} from "@material-ui/core";
import { CaseTypeSelect } from "../../components/case-type-select";
import { DialogBase } from "../../components/dialog";
import { Field, withFormik } from "formik";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { flowRight as compose } from "lodash";
import { withApollo } from "@apollo/client/react/hoc";
import { withAppContext } from "../../utils/with-app-context";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

const messages = defineMessages({
  editMessage: {
    defaultMessage: "Rediger besked",
    id: "reminder-message-dialog.edit-message"
  },
  message: {
    defaultMessage: "Besked",
    id: "reminder-message-dialog.message"
  }
});

class ReminderMessageDialog extends PureComponent {
  static propTypes = {
    context: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired
  };

  render() {
    const {
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      intl: { formatMessage },
      onCancel,
      open,
      values
    } = this.props;
    return (
      <DialogBase
        open={open}
        title={formatMessage(messages.editMessage)}
        onCancel={onCancel}
        onOk={handleSubmit}
      >
        <DialogContent>
          <div style={{ height: 400 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    error={errors.message}
                    label={formatMessage(messages.message)}
                    name="message"
                    type="text"
                    value={values.message}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </form>
          </div>
        </DialogContent>
      </DialogBase>
    );
  }
}

const handleSubmit = (payload, { props }) => {
  const { message } = payload;
  props.onOk(message);
};

const enhanced = compose(
  withFormik({
    displayName: "ReminderMessageDialog",
    enableReinitialize: true,
    handleSubmit,
    mapPropsToValues: ({ variables }) => {
      return {
        ...variables
      };
    }
  }),
  injectIntl,
  withApollo,
  withAppContext
)(ReminderMessageDialog);

export { enhanced as ReminderMessageDialog };
