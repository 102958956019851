import { Button, Grid } from "@material-ui/core";
import { UPDATE_FILE } from "../../../graphql/cases";
import { defineMessages, injectIntl } from "react-intl";
import { fileInstanceToURL } from "./list";
import { formikPropTypes } from "../../../utils/form-helpers";
import { withApollo } from "@apollo/client/react/hoc";
import React, { PureComponent } from "react";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import TextField from "@material-ui/core/TextField";

const messages = defineMessages({
  title: {
    defaultMessage: "Titel",
    id: "case-foto-form.title"
  }
});

class CasePhotoForm extends PureComponent {
  static propTypes = {
    ...formikPropTypes
  };
  state = {
    fullsizeUrl: fileInstanceToURL(this.props.values),
    imageURL: fileInstanceToURL(this.props.values, 800)
  };

  updateFile = async newFileInfo => {
    const response = await this.props.client.mutate({
      mutation: UPDATE_FILE,
      variables: {
        file: {
          file_path: newFileInfo.path,
          filename: newFileInfo.filename
        },
        id: this.props.values.file.id
      }
    });
    const newValues = { ...this.props.values };

    newValues.file = response.data.update_files.returning[0];
    this.setState({
      fullsizeUrl: fileInstanceToURL(newValues),
      imageURL: fileInstanceToURL(newValues, 800)
    });
  };

  handleRotateLeft = () => {
    fetch(`${this.state.fullsizeUrl}&rotate=-90`)
      .then(function(response) {
        return response.json();
      })
      .then(file => {
        return this.updateFile(file);
      });
  };
  handleRotateRight = () => {
    fetch(`${this.state.fullsizeUrl}&rotate=90`)
      .then(function(response) {
        return response.json();
      })
      .then(file => {
        return this.updateFile(file);
      });
  };
  render() {
    const {
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      intl,
      values
    } = this.props;

    return (
      <>
        <form onSubmit={handleSubmit}>
          <div>{errors.form}</div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                error={errors.title}
                label={intl.formatMessage(messages.title)}
                name="title"
                type="text"
                value={values.title}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button onClick={this.handleRotateLeft}>
                <RotateLeftIcon />
              </Button>
              <Button onClick={this.handleRotateRight}>
                <RotateRightIcon />
              </Button>
            </Grid>
          </Grid>
          <div style={{ minHeight: 800 }}>
            <img
              src={this.state.imageURL}
              style={{
                maxHeight: 400,
                position: "relative",
                top: values.rotate % 180 ? 100 : 0,
                transform: `rotate(${values.rotate}deg)`
              }}
            />
          </div>
        </form>
      </>
    );
  }
}

const CasePhotoFormWithIntl = injectIntl(withApollo(CasePhotoForm));

export { CasePhotoFormWithIntl as CasePhotoForm };
