/* eslint-disable react/no-unused-state */
import * as jwtDecode from "jwt-decode";
import { AppContext } from "./components/AppContext";
import { AppRoutes } from "./routes";
import { HashRouter as Router } from "react-router-dom";
import { useLocation } from "react-router";
import Bowser from "bowser";
import React, { useCallback, useEffect, useState } from "react";
import SideBar from "./components/layout/SideBar";

const browser = Bowser.getParser(window.navigator.userAgent);
const platform = browser.getPlatformType(true);
const isIpadPro =
  navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1;

export default function App(): JSX.Element {
  const location = useLocation();
  const [currentUser, setCurrentUser] = useState<
    | {
        id?: string;
        mail?: string;
        role?: string;
        employee_number?: number;
      }
    | {}
  >({});

  const [sideBarOpen, setSideBarOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [variant, setVariant] = useState<"permanent" | "persistent">(
    "permanent"
  );
  const [mobile, setMobile] = useState<boolean>(false);

  const resize = useCallback(() => {
    const smallScreen = window.innerWidth < 1025;
    const isMobile =
      platform === "mobile" ||
      platform === "tablet" ||
      isIpadPro ||
      smallScreen;
    setMobile(isMobile);
    setSideBarOpen(!isMobile && !smallScreen);
    setVariant(isMobile ? "persistent" : "permanent");
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const isLogin = location.pathname === "/login";
    let userInformation;
    if (!isLogin) {
      try {
        userInformation = jwtDecode(token);
        if (!userInformation.role) {
          window.location.href = "#/login";
        }
      } catch (error) {
        window.location.href = "#/login";
      }
    }
    const smallScreen = window.innerWidth < 1025;
    const isMobile =
      platform === "mobile" ||
      platform === "tablet" ||
      isIpadPro ||
      smallScreen;
    setMobile(isMobile);

    setSideBarOpen(!isMobile && !smallScreen);
    setVariant(isMobile ? "persistent" : "permanent");
    setCurrentUser(
      userInformation
        ? {
            id: userInformation.sub,
            ...userInformation,
            mail: userInformation.name
          }
        : null
    );
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [location.pathname, resize]);
  const toggleDrawer = useCallback(() => {
    setSideBarOpen(!sideBarOpen);
  }, [sideBarOpen]);

  const isLogin = location.pathname === "/login";
  return (
    <div>
      <AppContext.Provider
        value={{
          setCurrentUser,
          setLoading,
          state: {
            currentUser: currentUser || {},
            isMobile: mobile,
            loading
          },
          toggleDrawer
        }}
      >
        {!isLogin ? (
          <SideBar
            isSideBarOpen={sideBarOpen}
            toggleDrawer={toggleDrawer}
            variant={variant}
          />
        ) : null}
        <div
          className={!isLogin && !mobile ? "desktopMargin" : null}
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            minHeight: "100%"
          }}
        >
          <Router>
            <AppRoutes />
          </Router>
        </div>
      </AppContext.Provider>
    </div>
  );
}
