/* eslint-disable react/jsx-no-bind */
import * as yup from "yup";
import { APP_BAR_HEIGHT, Page } from "../../../../components/layout/page";
import { AppBar, Link, Tab, Tabs, withStyles } from "@material-ui/core";
import {
  CREATE_CHECKLISTS,
  CREATE_CHECKLISTS_TASK,
  CREATE_CHECKLIST_TASK_ARROWS,
  DELETE_CHECKLISTS_TASK,
  DELETE_CHECKLIST_TASK_ARROWS,
  GET_CHECKLIST,
  UPDATE_CHECKLISTS,
  UPDATE_CHECKLISTS_TASK,
  UPDATE_CHECKLIST_TASK_ARROWS
} from "../../../../graphql/checklists";
import { ChecklistForm } from "./form";
import { GET_CASE } from "../../../../graphql/cases";
import { Query } from "@apollo/client/react/components";
import { ReminderList } from "../../../../components/reminders/reminder-list";
import { Route, Switch, withRouter } from "react-router-dom";
import { flowRight as compose } from "lodash";
import { defineMessages, injectIntl } from "react-intl";
import { graphql } from "@apollo/client/react/hoc";
import { replaceWithData } from "../../../../utils/app";
import { withAppContext } from "../../../../utils/with-app-context";
import { withFormik } from "formik";
import AreYouSure from "../../../../components/are-you-sure";
import Header from "../../../../components/layout/header";
import PropTypes from "prop-types";
import React, { PureComponent, useState } from "react";
import _ from "lodash";

const INTRODUCTION = `<p>Deltagere mangelgennemgangen:</p>
<p><br></p>
<p>For entreprenør/byggefirma: xXXx</p>
<p><br></p>
<p>For bygherre: {{ firstName }} {{ lastName }}</p>
<p><br></p>
<p>For Byggesagkyndig.nu: {{ responsible }}</p>
<p><br></p>
<p><strong>Mangler</strong></p>
<p>Arbejdet er gennemgået d.d., konstaterede mangler er anført på nedenstående mangelliste som er udarbejdet af {{ responsible }}, Byggesagkyndig.nu.</p>
<p><br></p>
<p>Nedenfor nævnte fejl og mangler skal afhjælpes snarest, og senest 4 uger fra datoen for denne besigtigelse.</p>
<p><br></p>
<p>Mangelafhjælpning kontrolleres af bygherre selv ud fra denne mangelliste efter færdigmelding.</p>
`;

const CLOSING = `<p><strong>Tilbageholdelse</strong></p>
<p>Indtil det er konstateret at mangler er afhjulpet tilfredsstillende og mangelafhjælpningen er godkendt af bygherre, tilbageholdes beløbet som fremgår ovenfor, svarende til at de konstaterede mangler kan afhjælpes korrekt inkl. udgifter til eventuelle afledede aktiviteter.</p>
<p>Er der ikke angivet noget beløb, skyldes det, at tilbagehold ikke er muligt iht. entreprisekontrakten.</p>
<p><br></p>
<p><strong>Fejl og mangler konstateret efter overtagelsen</strong></p>
<p>Dersom bygherren indenfor 5 års garantiperioden konstaterer mangler ved det udførte, skal bygherren med det samme rette henvendelse til hovedentreprenøren, således at afhjælpning kan ske snarest muligt efter det konstaterede. Bygherren skal således ikke afvente 1 og 5 års gennemgangen hvis nye mangler kan konstateres i den mellemliggende periode.</p>
<p><br></p>
<p><strong>Rådgivning &amp; Ansvar</strong></p>
<p>Entreprenørens ansvar og tilhørende garanti regnes fra denne dato, medmindre der er tale om en førgennemgang inden overtagelse, i dette pågældende tilfælde (se overskriften på mangellisten).</p>
<p>Byggesagkyndig.nu kan efter nærmere aftale yde bistand ved kontrol af mangelafhjælpning samt afholdelse af 1 års og 5 års eftersyn.</p>
<p>Der gøres opmærksom på, at det kan forekomme, at det er bygherrens ansvar at indkalde de relevante parter til 1 års og 5 års eftersyn, jf. jeres kontraktbetingelser.</p>
<p>Byggesagkyndig.nu vil dog bestræbe sig på at informere herom når den tid kommer.</p>
<p><br></p>
<p><strong>Særlige aftaler og forhold</strong></p>
<p>xXXx</p>
<p><br></p>
<p><strong>Forbehold</strong></p>
<p>Der gøres opmærksom på, at vores gennemgang alene omhandler visuelle undersøgelser på besigtigelsestidspunktet, og der ifm. gennemgangen af ejendommen afsat op til 2 timer iht. aftale.</p>
<p><br></p>
<p>Fejl og mangler er prissat og vurderet ud fra bedste evne, og er et erfaringsmæssigt skøn vurderet uden prisindhentning fra håndværkere, hvorfor der tages forbehold for denne vurdering.Fremgår der ikke priser, skyldes dette manglende mulighed for tilbagehold, jf. ovenstående.</p>
<p><br></p>
<p>Der tages forbehold for skjulte fejl og mangler som ikke har kunne besigtiges ved gennemgangen.&nbsp;</p>
<p><br></p>
<p>Byggesagkyndig.nu yder udelukkende byggeteknisk rådgivning.&nbsp;</p>
<p>Juridiske forhold, skal derfor drøftes og vurderes af egen advokat.&nbsp;</p>
<p><br></p>
<p>De i notatet brugte billeder og evt. målinger er taget/udført dags dato.&nbsp;</p>
<p><br></p>
<p>Nærværende mangelliste er skrevet ifm. gennemgangen af ejendommen på en ipad, hvorfor der tages forbehold for mindre stave- eller tastefejl samt for underlige eller sjov pga. autokorrektur mv.</p>
<p><br></p>`;

const messages = defineMessages({
  add: {
    defaultMessage: "Tilføj",
    id: "checklist-entry.add"
  },
  createNew: {
    defaultMessage: "Opret ny mangelliste",
    id: "checklist-entry.create-new-checklist"
  },
  details: {
    defaultMessage: "Detaljer",
    id: "checklist-entry.details"
  },
  reminders: {
    defaultMessage: "Påmindelser",
    id: "checklist-entry.reminders"
  },
  update: {
    defaultMessage: "Redigér mangelliste",
    id: "checklist-entry.update-checklist"
  }
});

const schema = yup.object().shape({
  title: yup.string().required()
  //color: yup.string().required(),
  //icon: yup.string().required()
});

const styles = () => ({
  appBar: {
    "@media (min-width:1025px)": {
      width: "calc(100% - 240px)"
    },
    marginTop: APP_BAR_HEIGHT
  }
});

class ChecklistEntry extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    dirty: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    id: PropTypes.string,
    intl: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    resetForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired
  };

  handleResetForm = () => {
    this.props.handleReset();
  };

  showNavigationWarning = () => {
    return !this.props.isSubmitting && (!this.props.id || this.props.dirty);
  };

  render() {
    const {
      dirty,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      id,
      intl: { formatMessage },
      isSubmitting,
      match: {
        params: { caseId }
      },
      setFieldValue,
      touched,
      values
    } = this.props;

    let heading;
    if (id) {
      heading = formatMessage(messages.update);
    } else {
      heading = formatMessage(messages.createNew);
    }

    return (
      <Page
        appBar={
          <Header
            backLocation={`/cases/${caseId}/documents`}
            dirty={dirty || !this.props.id}
            doc={values}
            title={heading}
            onResetButton={this.handleResetForm}
            onSaveButton={handleSubmit}
          />
        }
      >
        <Switch>
          <Route
            path="/cases/:caseId/checklist/:checklistId/reminders"
            render={props => (
              <ReminderList
                {...props}
                baseURL={`/cases/${caseId}/checklist/${id}/reminders`}
                parentId={id}
                parentName="document_checklist_id"
              />
            )}
          />
          <Route
            path="/cases/:caseId/checklist/:checklistId"
            render={props => (
              <div
                style={{
                  backgroundColor: "#FFF",
                  minHeight: "calc(100% - 64px)",
                  padding: 20
                }}
              >
                <ChecklistForm
                  {...props}
                  caseId={caseId}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  values={values}
                />
              </div>
            )}
          />
        </Switch>
        <AreYouSure when={this.showNavigationWarning} />
      </Page>
    );
  }
}

const relatedDiff = (oldList, newList) => {
  const toDelete = oldList.reduce((removedInstances, oldInstance) => {
    if (!newList.find(instance => instance.id === oldInstance.id)) {
      removedInstances.push(oldInstance);
    }
    return removedInstances;
  }, []);

  const toAdd = newList.reduce((addedInstances, instance) => {
    if (!oldList.find(oldInstance => instance.id === oldInstance.id)) {
      addedInstances.push(instance);
    }
    return addedInstances;
  }, []);

  const toUpdate = newList.reduce((updatedInstances, instance) => {
    if (oldList.find(oldInstance => instance.id === oldInstance.id)) {
      delete instance.__typename;
      updatedInstances.push(instance);
    }
    return updatedInstances;
  }, []);

  return { toAdd, toDelete, toUpdate };
};

const handleSubmit = async (
  payload,
  { props, resetForm, setErrors, setSubmitting }
) => {
  const nowTimestamp = new Date().toISOString();
  const { caseId, checklistId } = props.match.params;
  const {
    closing,
    date_of_inspection,
    document_date,
    file_id,
    introduction,
    items,
    revision_date,
    title
  } = payload;
  const variables = {
    checklist: {
      case_id: caseId,
      changed: nowTimestamp,
      closing,
      date_of_inspection,
      document_date,
      file_id,
      introduction,
      revision_date,
      title
    },
    id: undefined
  };
  try {
    if (checklistId) {
      const oldTasks = props.variables.items;
      const newTasks = items || [];
      const { toAdd, toDelete, toUpdate } = relatedDiff(oldTasks, newTasks);
      if (toDelete.length) {
        await props.deleteChecklistTask({
          variables: {
            where: {
              _or: toDelete.map(instance => {
                return { id: { _eq: instance.id } };
              })
            }
          }
        });
      }
      if (toAdd.length) {
        await props.createChecklistTask({
          variables: {
            items: toAdd.map(instance => {
              const copy = { ...instance };
              delete copy.file;
              if (copy.arrows && copy.arrows.length) {
                copy.arrows = {
                  data: copy.arrows.map(arrow => {
                    delete arrow.item_id;
                    return arrow;
                  })
                };
              } else {
                delete copy.arrows;
              }
              copy.checklist_id = checklistId;
              return copy;
            })
          }
        });
      }
      let [totalArrowsToDelete, totalArrowsToAdd, totalArrowsToUpdate] = [
        [],
        [],
        []
      ];

      if (toUpdate.length) {
        Promise.all(
          toUpdate.map(async instance => {
            const copy = { ...instance };
            delete copy.file;
            const oldTask = oldTasks.find(t => instance.id === t.id);
            const arrowDiff = relatedDiff(oldTask.arrows, copy.arrows || []);
            totalArrowsToDelete = totalArrowsToDelete.concat(
              arrowDiff.toDelete
            );
            totalArrowsToAdd = totalArrowsToAdd.concat(arrowDiff.toAdd);
            totalArrowsToUpdate = totalArrowsToUpdate.concat(
              arrowDiff.toUpdate
            );
            delete copy.arrows;
            await props.updateChecklistTask({
              variables: {
                id: instance.id,
                item: copy
              }
            });
          })
        );
      }

      if (totalArrowsToDelete.length) {
        await props.deleteChecklistTaskArrow({
          variables: {
            where: {
              _or: totalArrowsToDelete.map(instance => {
                return { id: { _eq: instance.id } };
              })
            }
          }
        });
      }
      if (totalArrowsToAdd.length) {
        await props.createChecklistTaskArrow({
          variables: {
            itemArrows: totalArrowsToAdd.map(arrow => {
              return arrow;
            })
          }
        });
      }

      if (totalArrowsToUpdate.length) {
        Promise.all(
          totalArrowsToUpdate.map(async instance => {
            await props.updateChecklistTaskArrow({
              variables: {
                id: instance.id,
                itemArrow: instance
              }
            });
          })
        );
      }

      variables.id = checklistId;
      const response = await props.updateChecklist({
        variables
      });
      props.setVariables(response.data.update_case_doc_checklists.returning[0]);
      resetForm();
    } else {
      variables.checklist.items = {
        data: items
          ? items.map(t => {
              delete t.file;
              if (t.arrows) {
                if (t.arrows.length) {
                  t.arrows = {
                    data: t.arrows.map(arrow => {
                      delete arrow.item_id;
                      return arrow;
                    })
                  };
                } else {
                  delete t.arrows;
                }
              }
              return t;
            })
          : []
      };
      variables.checklist.created = nowTimestamp;
      variables.checklist.user_id = props.context.state.currentUser.id;
      const response = await props.createChecklist({
        variables
      });
      const createdId =
        response.data.insert_case_doc_checklists.returning[0].id;
      props.history.replace(`/cases/${caseId}/checklist/${createdId}`);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    const errors = e.graphQLErrors.map(error => error.message);
    // eslint-disable-next-line no-console
    console.log(errors);
    setSubmitting(false);
    setErrors({ form: errors });
  }
};

const ChecklistFormWithGraphQL = compose(
  withRouter,
  withAppContext,
  graphql(CREATE_CHECKLISTS, {
    name: "createChecklist"
  }),
  graphql(UPDATE_CHECKLISTS, {
    name: "updateChecklist"
  }),

  graphql(CREATE_CHECKLISTS_TASK, {
    name: "createChecklistTask"
  }),
  graphql(UPDATE_CHECKLISTS_TASK, {
    name: "updateChecklistTask"
  }),
  graphql(DELETE_CHECKLISTS_TASK, {
    name: "deleteChecklistTask"
  }),
  graphql(CREATE_CHECKLIST_TASK_ARROWS, {
    name: "createChecklistTaskArrow"
  }),
  graphql(UPDATE_CHECKLIST_TASK_ARROWS, {
    name: "updateChecklistTaskArrow"
  }),
  graphql(DELETE_CHECKLIST_TASK_ARROWS, {
    name: "deleteChecklistTaskArrow"
  }),
  withFormik({
    displayName: "ChecklistForm",
    handleSubmit,
    mapPropsToValues: ({ dateOfInspection, variables }) => {
      let {
        closing,
        date_of_inspection,
        document_date,
        introduction
      } = variables;
      document_date = document_date ? document_date : new Date().toISOString();
      date_of_inspection = date_of_inspection
        ? date_of_inspection
        : dateOfInspection;
      if (!introduction) {
        introduction = replaceWithData(INTRODUCTION, variables.case);
      }
      if (!closing) {
        closing = CLOSING;
      }
      return {
        ...variables,
        closing,
        date_of_inspection,
        document_date,
        introduction
      };
    },
    validationSchema: schema
  }),
  injectIntl,
  withStyles(styles)
)(ChecklistEntry);

const ChecklistEntryWrapper = ({
  match: {
    params: { caseId, checklistId }
  }
}) => {
  const id = checklistId;
  const [variables, setVariables] = useState(null);
  if (variables) {
    return (
      <ChecklistFormWithGraphQL
        id={id}
        setVariables={setVariables}
        variables={variables}
      />
    );
  }
  if (id) {
    return (
      <Query query={GET_CHECKLIST} variables={{ id }}>
        {({ data, error, loading }) => {
          if (loading) {
            return <p>Loading...</p>;
          }
          if (error) {
            return <p>Error :(</p>;
          }
          return (
            <ChecklistFormWithGraphQL
              id={id}
              setVariables={setVariables}
              variables={data.case_doc_checklists_by_pk}
            />
          );
        }}
      </Query>
    );
  } else {
    return (
      <Query query={GET_CASE} variables={{ caseId }}>
        {({ data, error, loading }) => {
          if (loading) {
            return <p>Loading...</p>;
          }
          if (error) {
            return <p>Error :(</p>;
          }
          return (
            <ChecklistFormWithGraphQL
              id={id}
              setVariables={setVariables}
              variables={{ case: data.cases[0] }}
            />
          );
        }}
      </Query>
    );
  }
};

const ChecklistEntryWrapperWithRouter = withRouter(ChecklistEntryWrapper);
export { ChecklistEntryWrapperWithRouter as ChecklistsEntry };
