import gql from "graphql-tag";

export const GET_USER_LIST = gql`
  query listUsers(
    $orderBy: users_order_by!
    $where: users_bool_exp
    $offset: Int
    $limit: Int
  ) {
    users_aggregate(
      offset: $offset
      order_by: [$orderBy]
      where: $where
      limit: $limit
    ) {
      aggregate {
        count
      }
      nodes {
        id
        mail
        user_type
        phone
        mobile
        first_name
        last_name
        acronym
        active
        employee_number
      }
    }
  }
`;

export const GET_USER = gql`
  query get_users($userId: uuid) {
    users(where: { id: { _eq: $userId } }) {
      id
      mail
      user_type
      phone
      mobile
      first_name
      last_name
      acronym
      active
      substitute_id
      substitute {
        id
        mail
        first_name
        last_name
        acronym
      }
      employee_number
    }
  }
`;

export const GET_USER_WITH_MAIL = gql`
  query get_users_with_mail($mail: String!) {
    users(where: { mail: { _eq: $mail } }) {
      id
    }
  }
`;

export const CREATE_USER = gql`
  mutation insert_user($user: users_insert_input!) {
    insert_users(objects: [$user]) {
      returning {
        id
        mail
        user_type
        phone
        mobile
        first_name
        last_name
        acronym
        active
        substitute_id
        substitute {
          id
          mail
          first_name
          last_name
          acronym
        }
        employee_number
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation update_user($id: uuid, $user: users_set_input!) {
    update_users(where: { id: { _eq: $id } }, _set: $user) {
      returning {
        id
        mail
        user_type
        phone
        mobile
        first_name
        last_name
        acronym
        active
        substitute_id
        substitute {
          id
          mail
          first_name
          last_name
          acronym
        }
        employee_number
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation update_user_password($mail: String!, $password: String!) {
    update_users(
      where: { mail: { _eq: $mail } }
      _set: { password: $password }
    ) {
      returning {
        id
      }
    }
  }
`;
