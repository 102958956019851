/* eslint-disable react/jsx-no-bind */
import { Editor, EditorState, RichUtils } from "draft-js";
import { FormLabel } from "@material-ui/core";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Tray from "./components/Tray.jsx";
import Wrapper from "./components/Wrapper.jsx";
import mockTarget from "./helpers/target";

export const RichTextEditor = props => {
  const EditorDOM = React.createRef();
  const contentState = stateFromHTML(props.value || "");
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState)
  );
  const [initialValue] = useState(props.value);
  React.useEffect(() => {
    if (props.value === initialValue) {
      const newContentState = stateFromHTML(props.value || "");
      setEditorState(EditorState.createWithContent(newContentState));
    }
  }, [props.value, props.initialValue]);
  const editorChanged = e => {
    setEditorState(e);
    props.onChange(
      mockTarget({
        name: props.name,
        value: stateToHTML(e.getCurrentContent())
      })
    );
  };
  return (
    <div style={{ marginTop: 10 }}>
      <FormLabel>{props.label}</FormLabel>
      <Wrapper>
        <Tray editor={EditorDOM} value={editorState} onChange={editorChanged} />
        <Editor
          ref={EditorDOM}
          spellCheck
          stripPastedStyles
          editorState={editorState}
          handleKeyCommand={(command, currentEditorState) => {
            const newState = RichUtils.handleKeyCommand(
              currentEditorState,
              command
            );
            if (!newState) {
              return "not-handled";
            }

            editorChanged(newState);

            return "handled";
          }}
          onChange={e => {
            editorChanged(e);
          }}
        />
      </Wrapper>
    </div>
  );
};
// update this
RichTextEditor.propTypes = {
  initialValue: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  value: PropTypes.string
};
