import {Field} from "formik";
import {UserSelect} from "../../components/user-select";
import {defineMessages, injectIntl} from "react-intl";
import React, {FunctionComponent} from "react";

const messages = defineMessages({
  substitute: {
    defaultMessage: "Afløser",
    id: "user-form.substitute",
  },
});

interface Props {
  errors: any;
  handleBlur: any;
  handleChange: any;
  handleSubmit: any;
  intl: any;
  isSubmitting: any;
  touched: any;
  values: any;
}

const UserForm: FunctionComponent<Props> = ({errors, handleSubmit, intl}) => (
  <>
    <form onSubmit={handleSubmit}>
      <div>{errors.form}</div>
      <Field
        component={UserSelect}
        label={intl.formatMessage(messages.substitute)}
        name="substitute"
      />
    </form>
  </>
);

const UserFormWithIntl: any = injectIntl(UserForm);

export {UserFormWithIntl as UserForm};
