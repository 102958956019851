import { InputBase } from "@material-ui/core";
import {
  Theme,
  createStyles,
  fade,
  makeStyles
} from "@material-ui/core/styles";
import { defineMessages, useIntl } from "react-intl";
import React from "react";

const messages = defineMessages({
  search: {
    defaultMessage: "Søg",
    id: "search-field.search"
  }
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        "&:focus": {
          width: 400
        },
        width: 120
      }
    },
    inputRoot: {
      color: "inherit"
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    root: {
      flexGrow: 1
    },
    search: {
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25)
      },
      backgroundColor: fade(theme.palette.common.white, 0.15),
      borderRadius: theme.shape.borderRadius,
      marginLeft: 0,
      position: "relative",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto"
      }
    },
    searchIcon: {
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      pointerEvents: "none",
      position: "absolute",
      width: theme.spacing(7)
    },
    title: {
      display: "none",
      flexGrow: 1,
      [theme.breakpoints.up("sm")]: {
        display: "block"
      }
    }
  })
);

export const SearchField = function SearchField({
  inputRef,
  onChange,
  value
}: {
  autoFocus: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
}): JSX.Element {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <InputBase
      classes={{
        input: classes.inputInput,
        root: classes.inputRoot
      }}
      inputRef={inputRef}
      placeholder={intl.formatMessage(messages.search)}
      value={value}
      onChange={onChange}
    />
  );
};
