/* eslint-disable react/display-name */
import {
  Button,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Radio,
  RadioGroup
} from "@material-ui/core";
import {DialogBase} from "./dialog";
import {FormattedMessage} from "react-intl";
import {fileInstanceToURL} from "../pages/case/photos/list";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import PropTypes from "prop-types";
import React, {useCallback, useState} from "react";
import _ from "lodash";
import uuid from "uuid/v4";

const arrowOrderToColor = ["red", "#00ff00", "yellow", "white", "blue", "cyan"];

const ArrowItem = React.memo(
  ({angle, color, dark, id, onAngleChange, onClick, selected}) => {
    const handleChange = useCallback(
      (_event, value) => {
        onAngleChange(id, value);
      },
      [onAngleChange, id]
    );

    const handleClick = useCallback(
      _event => {
        onClick(id);
      },
      [onClick, id]
    );

    return (
      <ListItem
        style={{backgroundColor: selected ? "#EEE" : "auto", color}}
        onClick={handleClick}
      >
        <FormControl>
          <RadioGroup row value={angle.toString()} onChange={handleChange}>
            <FormControlLabel
              control={<Radio />}
              label={
                <ArrowForwardIcon
                  style={{backgroundColor: dark ? "#777" : "auto"}}
                />
              }
              value="0"
            />
            <FormControlLabel
              control={<Radio />}
              label={
                <ArrowUpwardIcon
                  style={{backgroundColor: dark ? "#777" : "auto"}}
                />
              }
              value="90"
            />
            <FormControlLabel
              control={<Radio />}
              label={
                <ArrowBackIcon
                  style={{backgroundColor: dark ? "#777" : "auto"}}
                />
              }
              value="180"
            />
            <FormControlLabel
              control={<Radio />}
              label={
                <ArrowDownwardIcon
                  style={{backgroundColor: dark ? "#777" : "auto"}}
                />
              }
              value="270"
            />
          </RadioGroup>
        </FormControl>
      </ListItem>
    );
  }
);

const ArrowDialog = ({
  arrows,
  file,
  onCancel,
  onOk,
  open,
  relatedName,
  taskId
}) => {
  const [arrowList, setArrowList] = useState(
    arrows && arrows.length
      ? arrows
      : [{angle: "", id: uuid(), order: 0, [relatedName]: taskId}]
  );
  const [selectedArrow, setSelectedArrow] = useState();
  const [imageSize, setImageSize] = useState();
  const addArrow = useCallback(() => {
    setArrowList([
      ...arrowList,
      {angle: "", id: uuid(), order: arrowList.length, [relatedName]: taskId}
    ]);
  }, [setArrowList, arrowList]);

  const removeArrow = useCallback(() => {
    const copy = [...arrowList];
    if (copy.length === 1) {
      copy[0].angle = "";
    } else {
      const removed = copy.pop();
      if (selectedArrow === removed.id) {
        setSelectedArrow(null);
      }
    }
    setArrowList(copy);
  }, [setArrowList, arrowList, setSelectedArrow, selectedArrow]);

  const handleAngleChange = useCallback(
    (id, angle) => {
      const copy = [...arrowList];
      const changedArrow = copy.find(arrow => arrow.id === id);
      changedArrow.angle = angle;
      setArrowList(copy);
    },
    [arrowList, setArrowList]
  );

  const handleClick = useCallback(
    id => {
      setSelectedArrow(id);
    },
    [setSelectedArrow]
  );

  const handleImageClick = useCallback(
    event => {
      if (!selectedArrow) {
        setSelectedArrow(arrowList[0].id);
      }
      const copy = [...arrowList];
      const changedArrow =
        copy.find(arrow => arrow.id === selectedArrow) ||
        copy.find(arrow => arrow.id === arrowList[0].id);
      if (!changedArrow.angle) {
        changedArrow.angle = "0";
      }
      changedArrow.x = event.nativeEvent.offsetX / event.target.width;
      changedArrow.y = event.nativeEvent.offsetY / event.target.height;
      setArrowList(copy);
    },
    [selectedArrow, arrowList, setArrowList, setSelectedArrow]
  );

  const onImgLoaded = useCallback(
    ({target: img}) => {
      setImageSize({
        height: img.height,
        width: img.width
      });
    },
    [setImageSize]
  );

  const handleOK = useCallback(() => {
    onOk(arrowList.filter(a => a.x && a.y && a.angle));
  }, [arrowList, onOk]);

  return (
    <DialogBase
      open={open}
      title={
        <FormattedMessage
          defaultMessage="Indsæt pile"
          id="new-document-dialog.title"
        />
      }
      onCancel={onCancel}
      onOk={handleOK}
    >
      <DialogContent>
        <div style={{position: "relative"}}>
          {imageSize &&
            arrowList.map((arrow, index) => {
              if (!arrow.x || !arrow.y) {
                return null;
              }
              const realX = arrow.x * imageSize.width;
              const realY = arrow.y * imageSize.height;

              const style = {
                color: arrowOrderToColor[arrow.order],
                left: realX,
                position: "absolute",
                top: realY
              };
              switch (arrow.angle.toString()) {
                case "0":
                  style.top -= 12;
                  style.left -= 24;
                  return <ArrowForwardIcon key={index} style={style} />;
                case "90":
                  style.left -= 12;
                  return <ArrowUpwardIcon key={index} style={style} />;
                case "180":
                  style.top -= 12;
                  return <ArrowBackIcon key={index} style={style} />;
                case "270":
                  style.top -= 24;
                  style.left -= 12;
                  return <ArrowDownwardIcon key={index} style={style} />;
                default:
                  return null;
              }
            })}
          <img
            src={fileInstanceToURL({file})}
            width="100%"
            onClick={handleImageClick}
            onLoad={onImgLoaded}
          />
        </div>
        <Grid container>
          <Grid item xs={8}>
            <List>
              {_.sortBy(arrowList, "order").map((arrow, index) => {
                const isSelected = arrow.id === selectedArrow;
                return (
                  <ArrowItem
                    key={`${index}-${isSelected}`}
                    angle={arrow.angle}
                    color={arrowOrderToColor[arrow.order]}
                    dark={arrow.order === 2 || arrow.order === 3}
                    id={arrow.id}
                    selected={isSelected}
                    onAngleChange={handleAngleChange}
                    onClick={handleClick}
                  />
                );
              })}
            </List>
          </Grid>
          <Grid
            item
            style={{
              textAlign: "right"
            }}
            xs={4}
          >
            <Button onClick={removeArrow}>
              <FormattedMessage
                defaultMessage="Fjern"
                id="arrowDialog.remove"
              />
            </Button>
            <Button onClick={addArrow}>
              <FormattedMessage defaultMessage="Tilføj" id="arrowDialog.add" />
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </DialogBase>
  );
};
ArrowDialog.propTypes = {
  arrows: PropTypes.array,
  file: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  relatedName: PropTypes.string,
  taskId: PropTypes.string.isRequired
};
ArrowDialog.defaultProps = {
  relatedName: "task_id"
};

const memoed = React.memo(ArrowDialog);
export {memoed as ArrowDialog};
