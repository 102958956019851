import { CASES } from "../../graphql/cases";
import {
  CaseFilterDialog,
  STANDARD_CASE_FILTER,
  getFilters,
  setStandardFilter
} from "./case-filter-dialog";
import {
  FormattedDate,
  FormattedTime,
  defineMessages,
  injectIntl
} from "react-intl";
import { MUTable } from "../../components/mu-table";
import { Page } from "../../components/layout/page";
import {
  createWhereObject,
  filterToWhereClause
} from "../../utils/form-helpers";
import { getQueryString } from "../../utils";
import Header from "../../components/layout/header";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import _ from "lodash";

const transformFilter = filter => {
  const { caseType, responsible } = filter;
  return {
    case_type:
      caseType && caseType.length
        ? ["id", caseType.map(selected => selected.id)]
        : null,
    responsible: responsible ? ["acronym", responsible.toUpperCase()] : null
  };
};
const messages = defineMessages({
  add: {
    defaultMessage: "Tilføj sag",
    id: "case-list.add"
  },
  address: {
    defaultMessage: "Adresse",
    id: "case-list.address"
  },
  allCases: {
    defaultMessage: "Alle Sager",
    id: "case-list.cases"
  },
  builder: {
    defaultMessage: "Bygherrer",
    id: "case-list.builder"
  },
  cases: {
    defaultMessage: "Igangværende Sager",
    id: "case-list.cases"
  },
  closedCases: {
    defaultMessage: "Lukkede Sager",
    id: "case-list.closed-cases"
  },
  lastChange: {
    defaultMessage: "Sidste ændring",
    id: "case-list.lastChange"
  },
  responsible: {
    defaultMessage: "Ansvarlig",
    id: "case-list.responsible"
  },
  task: {
    defaultMessage: "Opgave",
    id: "case-list.task"
  }
});

export const getCaseAddress = caseInstance => {
  if (caseInstance.other_address) {
    const { address, city, zip_code } = caseInstance;
    return { address, city, zip_code };
  } else if (caseInstance.builder) {
    const { address, city, zip_code } = caseInstance.builder;
    return { address, city, zip_code };
  }
};

export const getCaseAddressFormatted = caseInstance => {
  const caseAddress = getCaseAddress(caseInstance);
  if (caseAddress) {
    const { address, city, zip_code } = caseAddress;
    return `${address || ""}, ${zip_code || ""} ${city || ""}`;
  }
};

const sqlSearchTerm = input => `%${input}%`;

export const getCaseWhere = (searchString, finalFilter) => {
  const fullAddressPattern = /([a-zæøåA-Zæøå]* \d{1,3}) (\d{1,4}) ([a-zæøåA-Zæøå]*)/i;
  const withPostalAddressPattern = /([a-zæøåA-Zæøå]* \d{1,3}) (\d{1,4})/i;
  const addressPattern = /[a-zæøåA-Zæøå ]* \d{1,3}/gi;

  let where;
  if (fullAddressPattern.test(searchString)) {
    const addressParts = searchString.match(fullAddressPattern);
    where = {
      _and: [
        {
          _or: [
            {
              address: { _ilike: sqlSearchTerm(addressParts[1]) },
              city: { _ilike: sqlSearchTerm(addressParts[3]) },
              zip_code: { _ilike: sqlSearchTerm(addressParts[2]) }
            },
            {
              builder: {
                address: { _ilike: sqlSearchTerm(addressParts[1]) },
                city: { _ilike: sqlSearchTerm(addressParts[3]) },
                zip_code: { _ilike: sqlSearchTerm(addressParts[2]) }
              }
            }
          ]
        },
        finalFilter
      ]
    };
  } else if (withPostalAddressPattern.test(searchString)) {
    const addressParts = searchString.match(withPostalAddressPattern);
    where = {
      _and: [
        {
          _or: [
            {
              address: { _ilike: sqlSearchTerm(addressParts[1]) },
              zip_code: { _ilike: sqlSearchTerm(addressParts[2]) }
            },
            {
              builder: {
                address: { _ilike: sqlSearchTerm(addressParts[1]) },
                zip_code: { _ilike: sqlSearchTerm(addressParts[2]) }
              }
            }
          ]
        },
        finalFilter
      ]
    };
  } else if (addressPattern.test(searchString)) {
    where = {
      _and: [
        {
          _or: [
            { address: { _ilike: sqlSearchTerm(searchString) } },
            { builder: { address: { _ilike: sqlSearchTerm(searchString) } } }
          ]
        },
        finalFilter
      ]
    };
  } else {
    where = createWhereObject(
      [
        "address",
        "zip_code",
        "city",
        "task",
        "builder.address",
        "builder.zip_code",
        "builder.city",
        "builder.first_name",
        "builder.last_name",
        "builder.phone",
        "builder.mail",
        "builder.mobile",
        "responsible.acronym"
      ],
      searchString,
      finalFilter
    );
  }
  return where;
};

class CaseList extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };
  state = {
    caseFilterDialogOpen: false,
    filter: filterToWhereClause(transformFilter(getFilters())),
    search: getQueryString("q") || ""
  };

  componentDidUpdate(prevProps) {
    if (this.props.match.params.state !== prevProps.match.params.state) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ search: getQueryString("q") || "" });
    }
  }

  debouncedSearchChange = _.debounce(value => {
    this.setState({ search: value });
  }, 300);

  handleSearchChange = value => {
    this.debouncedSearchChange(value);
  };

  handleRowClick = instance => {
    this.props.history.push(`/cases/${instance.id}`);
  };

  handleCaseFilterDialogCancel = () => {
    this.setState({ caseFilterDialogOpen: false });
  };
  handleFilterClick = () => {
    this.setState({ caseFilterDialogOpen: true });
  };
  handleFilterChange = filter => {
    this.setState({
      caseFilterDialogOpen: false,
      filter: filterToWhereClause(transformFilter(filter))
    });
  };
  handleFilterClearClick = () => {
    setStandardFilter();
    this.handleFilterChange(STANDARD_CASE_FILTER);
  };

  render() {
    const {
      intl: { formatMessage },
      match: {
        params: { state }
      }
    } = this.props;
    let filter = null;
    let title;
    switch (state) {
      case "current":
        filter = { closed: { _is_null: true }, deleted: { _eq: false } };
        title = formatMessage(messages.cases);
        break;
      case "archive":
        filter = { closed: { _is_null: false }, deleted: { _eq: false } };
        title = formatMessage(messages.closedCases);
        break;
      default:
        filter = { deleted: { _eq: false } };
        title = formatMessage(messages.allCases);
        break;
    }
    const filtered = !_.isEqual(getFilters(), STANDARD_CASE_FILTER);
    let finalFilter = filter;
    if (filtered) {
      finalFilter = [...this.state.filter];
      finalFilter.push(filter);
    }

    return (
      <Page
        appBar={
          <Header
            title={title}
            onFilterClearClick={filtered ? this.handleFilterClearClick : null}
            onFilterClick={this.handleFilterClick}
            onSearchChange={this.handleSearchChange}
          />
        }
      >
        <MUTable
          columns={[
            {
              field: "builder__address",
              render: caseInstance => getCaseAddressFormatted(caseInstance),
              title: formatMessage(messages.address)
            },
            {
              field: "builder__first_name",
              render: caseInstance =>
                caseInstance.builder
                  ? `${caseInstance.builder.first_name} ${caseInstance.builder.last_name}`
                  : null,
              title: formatMessage(messages.builder)
            },
            {
              field: "responsible__acronym",
              render: caseInstance =>
                caseInstance.responsible
                  ? caseInstance.responsible.acronym
                  : null,
              title: formatMessage(messages.responsible)
            },
            {
              field: "task",
              title: formatMessage(messages.task)
            },
            {
              field: "changed",
              render: caseInstance =>
                caseInstance.changed ? (
                  <>
                    <FormattedDate value={caseInstance.changed} />{" "}
                    <FormattedTime value={caseInstance.changed} />
                  </>
                ) : null,
              title: formatMessage(messages.lastChange)
            }
          ]}
          dataSourceName="cases_aggregate"
          direction="desc"
          gql={CASES}
          limit={
            state === "current" && (filtered || this.state.search) ? 5000 : 40
          }
          orderBy="changed"
          where={getCaseWhere(this.state.search.replace(",", ""), finalFilter)}
          onRowClick={this.handleRowClick}
        />
        <CaseFilterDialog
          filter={this.state.filter}
          open={this.state.caseFilterDialogOpen}
          onCancel={this.handleCaseFilterDialogCancel}
          onFilterChanged={this.handleFilterChange}
        />
      </Page>
    );
  }
}

export default injectIntl(CaseList);
