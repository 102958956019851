import { Fab } from "@material-ui/core";
import { defineMessages, useIntl } from "react-intl";
import AddIcon from "@material-ui/icons/Add";
import React, { memo } from "react";

const messages = defineMessages({
  add: {
    defaultMessage: "Opret",
    id: "mu-fab.add"
  }
});
const MuFab = memo(function Mufab({
  disabled,
  icon,
  onClick,
  row
}: {
  disabled?: boolean;
  icon?: JSX.Element;
  onClick: () => void;
  row?: number;
}): JSX.Element {
  const fabIcon = icon || <AddIcon />;
  const rowNumber = row ? row - 1 : 0;
  const { formatMessage } = useIntl();
  return (
    <Fab
      aria-label={formatMessage(messages.add)}
      color="primary"
      disabled={!!disabled}
      style={{
        bottom: 5 * 2 + 66 * rowNumber,
        position: "fixed",
        right: 5 * 2
      }}
      onClick={onClick}
    >
      {fabIcon}
    </Fab>
  );
});

export default MuFab;
