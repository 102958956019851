import { DateTimePickerField } from "./date-time-fields";
import { DialogBase } from "./dialog";
import { DialogContent, Grid, TextField, Typography } from "@material-ui/core";
import { Field, Formik } from "formik";
import { FormattedMessage } from "react-intl";
import { RichTextEditor } from "./draftjs-formik";
import { TextFieldAutosize } from "./resizing-text-area";
import React, { useCallback } from "react";
const DialogBaseWithOutTypes: any = DialogBase;

export const CalendarEntryDialog = function CalendarEntryDialog({
  address,
  city,
  description,
  error,
  onCancel,
  onOk,
  open,
  startDate,
  title,
  zipCode
}: {
  address: string;
  city: string;
  description: string;
  error?: boolean;
  onCancel: () => void;
  onOk: (values: {
    description: string;
    endDate: string;
    startDate: string;
    title: string;
  }) => void;
  open: boolean;
  startDate: string;
  title: string;
  zipCode: string;
}): JSX.Element {
  const endDate = new Date(startDate);
  endDate.setHours(endDate.getHours() + 2);
  const handleSubmit = useCallback(
    values => {
      const localStartDate = new Date(values.startDate);
      const startDstOffset = localStartDate.getTimezoneOffset() * -1;
      localStartDate.setMinutes(localStartDate.getMinutes() + startDstOffset);

      const localEndDate = new Date(values.endDate);
      const endDstOffset = localEndDate.getTimezoneOffset() * -1;
      localEndDate.setMinutes(localEndDate.getMinutes() + endDstOffset);

      onOk({
        ...values,
        endDate: localEndDate.toISOString(),
        startDate: localStartDate.toISOString()
      });
    },
    [onOk]
  );
  return (
    <Formik
      initialValues={{
        address,
        city,
        description,
        endDate: endDate.toISOString(),
        startDate,
        title,
        zipCode
      }}
      onSubmit={handleSubmit}
    >
      {formik => (
        <DialogBaseWithOutTypes
          open={open}
          title={
            <FormattedMessage
              defaultMessage="Overfør til kalender"
              id="calendar-entry-dialog.title"
            />
          }
          onCancel={onCancel}
          onOk={formik.handleSubmit}
        >
          <DialogContent>
            {error ? (
              <>
                <Typography color="error" variant="subtitle1">
                  <FormattedMessage
                    defaultMessage="Der opstod en fejl, prøv igen senere"
                    id="calendar-entry-dialog.error"
                  />
                </Typography>
              </>
            ) : null}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  error={!!formik.errors.description}
                  label={
                    <FormattedMessage
                      defaultMessage="Titel"
                      id="calendar-entry-dialog.title"
                    />
                  }
                  name="title"
                  type="text"
                  value={formik.values.title}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={6} xs={12}>
                <Field
                  fullWidth
                  component={DateTimePickerField}
                  label={
                    <FormattedMessage
                      defaultMessage="Start"
                      id="calendar-entry-dialog.start"
                    />
                  }
                  name="startDate"
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <Field
                  fullWidth
                  component={DateTimePickerField}
                  label={
                    <FormattedMessage
                      defaultMessage="Slut"
                      id="calendar-entry-dialog.end"
                    />
                  }
                  name="endDate"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RichTextEditor
                  label={
                    <FormattedMessage
                      defaultMessage="Beskrivelse"
                      id="calendar-entry-dialog.description"
                    />
                  }
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={5} xs={12}>
                <TextField
                  fullWidth
                  multiline
                  error={!!formik.errors.address}
                  label={
                    <FormattedMessage
                      defaultMessage="Adresse"
                      id="calendar-entry-dialog.address"
                    />
                  }
                  name="address"
                  type="text"
                  value={formik.values.address}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item lg={2} xs={12}>
                <TextField
                  fullWidth
                  multiline
                  error={!!formik.errors.zipCode}
                  label={
                    <FormattedMessage
                      defaultMessage="Postnr"
                      id="calendar-entry-dialog.zipCode"
                    />
                  }
                  name="zipCode"
                  type="text"
                  value={formik.values.zipCode}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item lg={5} xs={12}>
                <TextField
                  fullWidth
                  multiline
                  error={!!formik.errors.city}
                  label={
                    <FormattedMessage
                      defaultMessage="By"
                      id="calendar-entry-dialog.city"
                    />
                  }
                  name="city"
                  type="text"
                  value={formik.values.city}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </DialogBaseWithOutTypes>
      )}
    </Formik>
  );
};
