import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  defineMessages,
  injectIntl
} from "react-intl";

import "react-image-lightbox/style.css";
import {
  ARCHIVE_FILES,
  CREATE_ARCHIVE_FILE
} from "../../graphql/archive-files";
import { BASE_FILE_URL } from "../../utils/app";
import { IconButton, Typography } from "@material-ui/core";
import { MUTable } from "../../components/mu-table";
import { Page } from "../../components/layout/page";
import { Upload } from "../../components/upload";
import { createWhereObject, emailLink } from "../../utils/form-helpers";
import { fileInstanceToURL } from "../case/photos/list";
import { getQueryString } from "../../utils";
import { withApollo } from "@apollo/client/react/hoc";
import { withAppContext } from "../../utils/with-app-context";
import { withRouter } from "react-router";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EditIcon from "@material-ui/icons/Edit";
import Header from "../../components/layout/header";
import MailIcon from "@material-ui/icons/Mail";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

const messages = defineMessages({
  created: {
    defaultMessage: "Oprettet",
    id: "request-caseFiles-list.created"
  },
  title: {
    defaultMessage: "Titel",
    id: "request-caseFiles-list.time"
  }
});

const getFileMailLink = (address, caseFile) => {
  const subject = caseFile.file.filename;
  const body = `
Download filen her:
${BASE_FILE_URL}${fileInstanceToURL(caseFile)}
`;

  return emailLink(address, subject, body);
};

class FileList extends PureComponent {
  static propTypes = {
    client: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    files: [],
    search: getQueryString("q") || "",
    uploadError: false
  };
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.state !== prevProps.match.params.state) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ search: getQueryString("q") || "" });
    }
  }

  handleSearchChange = value => {
    this.setState({ search: value });
  };

  addFile = async files => {
    const file = files[0];
    const returning = await this.props.client.mutate({
      mutation: CREATE_ARCHIVE_FILE,
      variables: {
        archive_files: [
          {
            file_id: file.id,
            title: file.filename
          }
        ]
      }
    });
    const newFile = returning.data.insert_archive_files.returning[0];
    const currentFiles = [...this.state.files];
    currentFiles.push(newFile);

    this.setState({ files: currentFiles });
  };

  handleFileUploaded = file => {
    this.addFile(file);
  };

  handleUploadError = _error => {
    this.setState({ uploadError: true });
  };

  handleUploadStart = () => {
    this.setState({ uploadError: false });
  };

  render() {
    const {
      intl: { formatMessage }
    } = this.props;

    const where = createWhereObject(["title"], this.state.search);

    return (
      <Page
        appBar={
          <Header
            title={
              <FormattedMessage defaultMessage="Filarkiv" id="files.header" />
            }
            onSearchChange={this.handleSearchChange}
          />
        }
      >
        {this.state.uploadError ? (
          <Typography color="error" variant="h5">
            <FormattedMessage
              defaultMessage="Der skete en fejl. Ikke alle filer er blevet uploadet"
              id="photo-upload-list.error"
            />
          </Typography>
        ) : null}
        <MUTable
          columns={[
            {
              field: "title",
              title: formatMessage(messages.title)
            },
            {
              render: file => (
                <>
                  <IconButton
                    component="a"
                    href={fileInstanceToURL(file)}
                    size="small"
                    style={{ marginRight: 5 }}
                    target="_BLANK"
                  >
                    <CloudDownloadIcon />
                  </IconButton>
                  <IconButton
                    component="a"
                    href={getFileMailLink("", file)}
                    size="small"
                    style={{ marginRight: 5 }}
                  >
                    <MailIcon />
                  </IconButton>
                  <IconButton
                    component="a"
                    href={`#/files/${file.id}`}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                </>
              ),
              style: { width: 170 },
              title: ""
            },
            {
              field: "created",
              render: file => (
                <>
                  <FormattedDate value={file.file.created} />{" "}
                  <FormattedTime value={file.file.created} />
                </>
              ),
              style: { width: 170 },
              title: formatMessage(messages.created)
            }
          ]}
          dataSourceName="archive_files"
          direction={{ created: "desc" }}
          extraRows={this.state.files}
          gql={ARCHIVE_FILES}
          limit={500}
          orderBy="file"
          where={where}
        />
        <Upload
          accept="*"
          onError={this.handleUploadError}
          onFileUploaded={this.handleFileUploaded}
          onUploadStart={this.handleUploadStart}
        />
      </Page>
    );
  }
}

const EnhancedFileList = withAppContext(
  withRouter(injectIntl(withApollo(FileList)))
);
export { EnhancedFileList as FileList };
