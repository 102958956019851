import * as PropTypes from "prop-types";
import * as React from "react";
import {TextField} from "@material-ui/core";

const HIDDEN_TEXTAREA_STYLE = {
  height: "0",
  "max-height": "none",
  "min-height": "0",
  overflow: "hidden",
  position: "absolute",
  right: "0",
  top: "0",
  visibility: "hidden",
  "z-index": "-1000"
};

const SIZING_STYLE = [
  "letter-spacing",
  "line-height",
  "font-family",
  "font-weight",
  "font-size",
  "font-style",
  "tab-size",
  "text-rendering",
  "text-transform",
  "width",
  "text-indent",
  "padding-top",
  "padding-right",
  "padding-bottom",
  "padding-left",
  "border-top-width",
  "border-right-width",
  "border-bottom-width",
  "border-left-width",
  "box-sizing"
];

const computedStyleCache = {};
const hiddenTextarea = document.createElement("textarea");

const forceHiddenStyles = node => {
  Object.keys(HIDDEN_TEXTAREA_STYLE).forEach(key => {
    node.style.setProperty(key, HIDDEN_TEXTAREA_STYLE[key], "important");
  });
};

forceHiddenStyles(hiddenTextarea);

function calculateNodeStyling(node, uid, useCache = false) {
  if (useCache && computedStyleCache[uid]) {
    return computedStyleCache[uid];
  }

  const style = window.getComputedStyle(node);

  if (style === null) {
    return null;
  }

  const sizingStyle = SIZING_STYLE.reduce((obj, name) => {
    obj[name] = style.getPropertyValue(name);
    return obj;
  }, {});

  const boxSizing = sizingStyle["box-sizing"];

  // probably node is detached from DOM, can't read computed dimensions
  if (boxSizing === "") {
    return null;
  }

  const paddingSize =
    parseFloat(sizingStyle["padding-bottom"]) +
    parseFloat(sizingStyle["padding-top"]);

  const borderSize =
    parseFloat(sizingStyle["border-bottom-width"]) +
    parseFloat(sizingStyle["border-top-width"]);

  const nodeInfo = {
    borderSize,
    boxSizing,
    paddingSize,
    sizingStyle
  };

  if (useCache) {
    computedStyleCache[uid] = nodeInfo;
  }

  return nodeInfo;
}

export const purgeCache = uid => {
  delete computedStyleCache[uid];
};

function calculateNodeHeight(
  uiTextNode,
  uid,
  useCache = false,
  minRows = null,
  maxRows = null
) {
  if (hiddenTextarea.parentNode === null) {
    document.body.appendChild(hiddenTextarea);
  }

  // Copy all CSS properties that have an impact on the height of the content in
  // the textbox
  const nodeStyling = calculateNodeStyling(uiTextNode, uid, useCache);

  if (nodeStyling === null) {
    return null;
  }

  const {borderSize, boxSizing, paddingSize, sizingStyle} = nodeStyling;

  // Need to have the overflow attribute to hide the scrollbar otherwise
  // text-lines will not calculated properly as the shadow will technically be
  // narrower for content
  Object.keys(sizingStyle).forEach(key => {
    hiddenTextarea.style[key] = sizingStyle[key];
  });
  forceHiddenStyles(hiddenTextarea);
  hiddenTextarea.value = uiTextNode.value || uiTextNode.placeholder || "x";

  let minHeight = -Infinity;
  let maxHeight = Infinity;
  let height = hiddenTextarea.scrollHeight;

  if (boxSizing === "border-box") {
    // border-box: add border, since height = content + padding + border
    height = height + borderSize;
  } else if (boxSizing === "content-box") {
    // remove padding, since height = content
    height = height - paddingSize;
  }

  // measure height of a textarea with a single row
  hiddenTextarea.value = "x";
  const singleRowHeight = hiddenTextarea.scrollHeight - paddingSize;

  // Stores the value's rows count rendered in `hiddenTextarea`,
  // regardless if `maxRows` or `minRows` props are passed
  const valueRowCount = Math.floor(height / singleRowHeight);

  if (minRows !== null) {
    minHeight = singleRowHeight * minRows;
    if (boxSizing === "border-box") {
      minHeight = minHeight + paddingSize + borderSize;
    }
    height = Math.max(minHeight, height);
  }

  if (maxRows !== null) {
    maxHeight = singleRowHeight * maxRows;
    if (boxSizing === "border-box") {
      maxHeight = maxHeight + paddingSize + borderSize;
    }
    height = Math.min(maxHeight, height);
  }

  const rowCount = Math.floor(height / singleRowHeight);

  return {height, maxHeight, minHeight, rowCount, valueRowCount};
}

// eslint-disable-next-line no-empty-function
const noop = () => {};

let uid = 0;

export class TextFieldAutosize extends React.Component {
  static propTypes = {
    inputRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({
        current: PropTypes.any
      })
    ]),
    maxRows: PropTypes.number,
    minRows: PropTypes.number,
    onChange: PropTypes.func,
    onHeightChange: PropTypes.func,
    style: PropTypes.object,
    useCacheForDOMMeasurements: PropTypes.bool,
    value: PropTypes.string
  };

  static defaultProps = {
    inputRef: noop,
    onChange: noop,
    onHeightChange: noop,
    useCacheForDOMMeasurements: false
  };

  constructor(props) {
    super(props);
    this.state = {
      height: (props.style && props.style.height) || 0,
      maxHeight: Infinity,
      minHeight: -Infinity,
      rowCount: null,
    };

    this._uid = uid++;
    this._controlled = props.value !== undefined;
    this._resizeLock = false;
  }

  componentDidMount() {
    this._resizeComponent();
    // Working around Firefox bug which runs resize listeners even when other JS is running at the same moment
    // causing competing rerenders (due to setState in the listener) in React.
    // More can be found here - facebook/react#6324
    this._resizeListener = () => {
      if (this._resizeLock) {
        return;
      }
      this._resizeLock = true;
      this._resizeComponent(() => {
        this._resizeLock = false;
      });
    };
    window.addEventListener("resize", this._resizeListener);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this._resizeComponent();
    }

    if (this.state.height !== prevState.height) {
      this.props.onHeightChange(this.state.height, this);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._resizeListener);
    purgeCache(this._uid);
  }

  _onRef = node => {
    this._ref = node;
    const {inputRef} = this.props;

    if (typeof inputRef === "function") {
      inputRef(node);
      return;
    }

    inputRef.current = node;
  };

  handleChange = event => {
    if (!this._controlled) {
      this._resizeComponent();
    }
    this.props.onChange(event, this);
  };

  _resizeComponent = (callback = noop) => {
    if (!this._ref) {
      callback();
      return;
    }

    const nodeHeight = calculateNodeHeight(
      this._ref,
      this._uid,
      this.props.useCacheForDOMMeasurements,
      this.props.minRows,
      this.props.maxRows
    );
    if (nodeHeight === null) {
      callback();
      return;
    }

    const {height, maxHeight, minHeight, rowCount, valueRowCount} = nodeHeight;

    this.rowCount = rowCount;
    this.valueRowCount = valueRowCount;

    if (
      this.state.height !== height ||
      this.state.minHeight !== minHeight ||
      this.state.maxHeight !== maxHeight ||
      this.state.rowCount !== rowCount
    ) {
      this.setState({height, maxHeight, minHeight, rowCount}, callback);
      return;
    }

    callback();
  };

  render() {
    const {
      inputRef: _inputRef,
      maxRows: _maxRows,
      minRows: _minRows,
      onHeightChange: _onHeightChange,
      useCacheForDOMMeasurements: _useCacheForDOMMeasurements,
      ...props
    } = this.props;

    props.style = {...props.style};

    const maxHeight = Math.max(
      props.style.maxHeight || Infinity,
      this.state.maxHeight
    );

    if (maxHeight < this.state.height) {
      props.style.overflow = "hidden";
    }

    return (
      <TextField {...props} inputRef={this._onRef} rows={this.state.rowCount} onChange={this.handleChange} />
    );
  }
}
