import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  defineMessages,
  injectIntl
} from "react-intl";

import "react-image-lightbox/style.css";
import { BASE_FILE_URL } from "../../../utils/app";
import { CASE_FILES, CREATE_CASE_FILE } from "../../../graphql/cases";
import { IconButton, Typography } from "@material-ui/core";
import { MUTable } from "../../../components/mu-table";
import { Upload } from "../../../components/upload";
import { emailLink } from "../../../utils/form-helpers";
import { fileInstanceToURL } from "../photos/list";
import { withApollo } from "@apollo/client/react/hoc";
import { withAppContext } from "../../../utils/with-app-context";
import { withRouter } from "react-router";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EditIcon from "@material-ui/icons/Edit";
import MailIcon from "@material-ui/icons/Mail";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

const messages = defineMessages({
  created: {
    defaultMessage: "Oprettet",
    id: "request-caseFiles-list.created"
  },
  title: {
    defaultMessage: "Titel",
    id: "request-caseFiles-list.time"
  }
});

const getFileMailLink = (address, caseFile) => {
  const subject = encodeURI(caseFile.file.filename);
  const body = `
Download filen her:
${BASE_FILE_URL}${fileInstanceToURL(caseFile)}
`;

  return emailLink(address, subject, body);
};

class CaseFileList extends PureComponent {
  static propTypes = {
    caseInstance: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    files: [],
    uploadError: false
  };
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
  }

  addFile = async files => {
    const file = files[0];
    const returning = await this.props.client.mutate({
      mutation: CREATE_CASE_FILE,
      variables: {
        case_files: [
          {
            case_id: this.props.match.params.caseId,
            file_id: file.id,
            title: file.filename
          }
        ]
      }
    });
    const newFile = returning.data.insert_case_files.returning[0];
    const currentFiles = [...this.state.files];
    currentFiles.push(newFile);

    this.setState({ files: currentFiles });
  };

  handleFileUploaded = file => {
    this.addFile(file);
  };

  handleUploadError = _error => {
    this.setState({ uploadError: true });
  };

  handleUploadStart = () => {
    this.setState({ uploadError: false });
  };

  render() {
    const {
      caseInstance,
      intl: { formatMessage },
      match: {
        params: { caseId }
      }
    } = this.props;
    const where = { case_id: { _eq: caseId } };

    return (
      <>
        {this.state.uploadError ? (
          <Typography color="error" variant="h5">
            <FormattedMessage
              defaultMessage="Der skete en fejl. Ikke alle filer er blevet uploadet"
              id="photo-upload-list.error"
            />
          </Typography>
        ) : null}
        <MUTable
          columns={[
            {
              field: "title",
              title: formatMessage(messages.title)
            },
            {
              render: file => (
                <>
                  <IconButton
                    component="a"
                    href={fileInstanceToURL(file)}
                    size="small"
                    style={{ marginRight: 5 }}
                    target="_BLANK"
                  >
                    <CloudDownloadIcon />
                  </IconButton>
                  <IconButton
                    component="a"
                    disabled={!caseInstance.builder}
                    href={
                      caseInstance.builder
                        ? getFileMailLink(caseInstance.builder.mail, file)
                        : ""
                    }
                    size="small"
                    style={{ marginRight: 5 }}
                  >
                    <MailIcon />
                  </IconButton>
                  <IconButton
                    component="a"
                    href={`#/cases/${file.case_id}/files/${file.id}`}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                </>
              ),
              style: { width: 170 },
              title: ""
            },
            {
              field: "created",
              render: file => (
                <>
                  <FormattedDate value={file.file.created} />{" "}
                  <FormattedTime value={file.file.created} />
                </>
              ),
              style: { width: 170 },
              title: formatMessage(messages.created)
            }
          ]}
          dataSourceName="case_files"
          direction={{ created: "desc" }}
          extraRows={this.state.files}
          gql={CASE_FILES}
          orderBy="file"
          where={where}
          onRowClick={this.handleRowClick}
        />
        <Upload
          accept="*"
          onError={this.handleUploadError}
          onFileUploaded={this.handleFileUploaded}
          onUploadStart={this.handleUploadStart}
        />
      </>
    );
  }
}

const EnhancedCaseFileList = withAppContext(
  withRouter(injectIntl(withApollo(CaseFileList)))
);
export { EnhancedCaseFileList as CaseFileList };
