import { Alert } from "@material-ui/lab";
import { AppContext } from "./AppContext";
import { BASE_URL } from "../utils/app";
import { Button, Snackbar } from "@material-ui/core";
import { CalendarEntryDialog } from "./calendar-entry-dialog";
import { FormattedMessage } from "react-intl";
import {
  getCaseAddress,
  getCaseAddressFormatted
} from "../pages/case/case-list";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import React, { useCallback, useContext, useState } from "react";
import _ from "lodash";

const formatCalendarString = (info: string | null): string =>
  info ? `${info}<br />` : "";

export const getReminderCaseInformation = _.memoize(reminder => {
  if (reminder.request) {
    const {
      address,
      builder,
      city,
      mail,
      mobile,
      phone,
      zip_code
    } = reminder.request;
    return {
      builder: {
        address,
        city,
        first_name: builder,
        mail,
        mobile,
        phone,
        zip_code
      }
    };
  }
  if (reminder.case) {
    return reminder.case;
  }
  return {};
});

export const getCalLink = (instance: any): string => {
  if (instance.__typename === "reminders") {
    if (instance.case) {
      return `${BASE_URL}/cases/${instance.case_id}`;
    }
    if (instance.request) {
      return `${BASE_URL}/cases/requests/${instance.request_id}`;
    }
    return "";
  } else {
    return `${BASE_URL}/cases/${instance.id}`;
  }
};

export const CalendarButton = React.memo(function CalendarButton({
  caseInstance,
  showTitle = true
}: {
  caseInstance: any;
  showTitle?: boolean;
}) {
  const { state } = useContext(AppContext);

  const [calendarEntryDialogOpen, setCalendarEntryDialogOpen] = useState(false);
  const [calendarError, setCalendarError] = useState(false);
  const handleCloseSnackbar = useCallback(() => {
    setCalendarError(false);
  }, []);

  let formattedAddress = "";
  let builder = "";
  let company = "";
  let phone = "";
  let mail = "";
  let mobile = "";
  let caseAddress;
  let startDate = "";
  let title = "";
  let recipient = "";

  if (caseInstance.__typename === "reminders") {
    const caseInfo = getReminderCaseInformation(caseInstance);
    if (caseInfo.builder) {
      builder = `${caseInfo.builder.first_name} ${caseInfo.builder.last_name}`;
      ({ company, mail, mobile, phone } = caseInfo.builder);
    }
    caseAddress = getCaseAddress(caseInfo);
    startDate = caseInstance.date;
    title = caseInstance.message;
    formattedAddress = getCaseAddressFormatted(caseInfo);
    recipient = caseInstance.recipients?.length
      ? caseInstance.recipients[0].user.mail
      : null;
  } else {
    if (caseInstance.builder) {
      builder = `${caseInstance.builder.first_name} ${caseInstance.builder.last_name}`;
      ({ company, mail, mobile, phone } = caseInstance.builder);
    }
    caseAddress = getCaseAddress(caseInstance);
    startDate = caseInstance.date_of_inspection;
    title = `${caseInstance.task} /${state.currentUser.acronym}`;
    formattedAddress = getCaseAddressFormatted(caseInstance);
    recipient = caseInstance.responsible?.mail;
  }

  const handleDialogOk = useCallback(
    values => {
      const token = localStorage.getItem("token");
      const caseLink = getCalLink(caseInstance);

      const descriptionWithLinks = `<a href="${caseLink}">Gå til sagen</a><br/><br/>${
        values.description
      }<br /><a href="https://www.google.com/maps/search/?api=1&query=${encodeURI(
        formattedAddress
      )}">Kør til sagen</a>`;
      fetch("/calendar", {
        body: JSON.stringify({
          ...values,
          description: descriptionWithLinks,
          mail: recipient
        }),
        headers: {
          Accept: "application/json",
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        method: "POST"
      })
        .then(response => {
          if (response.ok) {
            setCalendarEntryDialogOpen(false);
          } else {
            throw Error;
          }
          return;
        })
        .catch(() => {
          setCalendarEntryDialogOpen(false);
          setCalendarError(true);
        });
    },
    [caseInstance, formattedAddress, recipient]
  );
  const handleClick = useCallback(() => {
    setCalendarEntryDialogOpen(true);
  }, []);
  const handleDialogCancel = useCallback(() => {
    setCalendarEntryDialogOpen(false);
  }, []);
  if (!recipient) {
    return (
      <>
        <Button disabled component="span">
          <CalendarTodayIcon />
          {showTitle ? (
            <FormattedMessage
              defaultMessage="Tilføj til kalender"
              id="case-form.add-to-calendar"
            />
          ) : null}
        </Button>
      </>
    );
  }

  return (
    <>
      <Button color="primary" component="span" onClick={handleClick}>
        <CalendarTodayIcon />
        {showTitle ? (
          <FormattedMessage
            defaultMessage="Tilføj til kalender"
            id="case-form.add-to-calendar"
          />
        ) : null}
      </Button>
      <CalendarEntryDialog
        address={caseAddress?.address}
        city={caseAddress?.city}
        description={`
Kontaktperson:<br/>
${builder}<br/>
${formatCalendarString(company)}${formatCalendarString(
          mobile
        )}${formatCalendarString(phone)}${formatCalendarString(mail)}`}
        open={calendarEntryDialogOpen}
        startDate={startDate}
        title={title}
        zipCode={caseAddress?.zip_code}
        onCancel={handleDialogCancel}
        onOk={handleDialogOk}
      />
      <Snackbar
        autoHideDuration={6000}
        open={calendarError}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="error" variant="filled">
          <FormattedMessage
            defaultMessage="Der skete en fejl under oprettelse i kalenderen. Prøv igen senere"
            id="case-form.error"
          />
        </Alert>
      </Snackbar>
    </>
  );
});
