import { DatePicker } from "@material-ui/pickers";
import { FormattedMessage } from "react-intl";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { isMobile } from "../../utils";
import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import gql from "graphql-tag";
import moment from "moment";

const QUERY = gql`
  query requestStats($startDate: timestamptz, $endDate: timestamptz) {
    cases(
      where: {
        created: { _gte: $startDate, _lte: $endDate }
        deleted: { _eq: false }
      }
    ) {
      responsible_id
      case_type {
        id
        name
      }
      created
      responsible {
        acronym
        employee_number
      }
    }
  }
`;

export const CasesCreatedResponsibleCard = React.memo(
  function ExternalCaseCard(): JSX.Element {
    const [getData, { data, loading }]: [
      any,
      {
        data: {
          cases:
            | {
                case_type: {
                  id: string;
                  name: string;
                } | null;
                created: string | null;
                responsible: {
                  acronym: string;
                  employee_number: number;
                } | null;
                responsible_id: string;
              }[]
            | null;
        };
        loading: boolean;
      }
    ] = useLazyQuery(QUERY);

    const [startDate, setStartDate] = useState<moment.Moment | null>(null);
    const [endDate, setEndDate] = useState<moment.Moment | null>(null);

    useEffect(() => {
      if (startDate) {
        const startIsoDate = startDate.startOf("day").toISOString();

        const endIsoDate = endDate.endOf("day").toISOString();

        getData({
          variables: {
            endDate: endIsoDate,
            startDate: startIsoDate
          }
        });
      } else if (!startDate) {
        const initialStartDate = moment();
        setStartDate(initialStartDate);
        setEndDate(initialStartDate);
      }
    }, [endDate, getData, startDate]);

    const [employees, sumData, total] = useMemo(() => {
      let employeeList = [];
      const calculatedData = new Map<string, number[]>();
      if (loading || !data?.cases) {
        return [employeeList, calculatedData, []];
      }
      const tempEmployeeSet = new Set<string>();
      data.cases.forEach(instance => {
        tempEmployeeSet.add(instance.responsible?.acronym);
      });
      employeeList = [...tempEmployeeSet].sort();
      const calcTotal = employeeList.map(() => 0);
      data.cases.forEach(instance => {
        let existing = calculatedData.get(instance.case_type?.name);
        if (!existing) {
          existing = employeeList.map(() => 0);
        }
        const employeeIndex = employeeList.indexOf(
          instance.responsible?.acronym
        );
        existing[employeeIndex] += 1;
        calcTotal[employeeIndex] += 1;

        calculatedData.set(instance.case_type?.name, existing);
      });

      return [employeeList, calculatedData, calcTotal];
    }, [data, data?.cases, loading]);

    return (
      <Paper>
        <div style={{ padding: 10 }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <DatePicker
                clearable
                fullWidth
                DialogProps={{ fullScreen: isMobile }}
                format="Do MMMM YYYY"
                label={
                  <FormattedMessage
                    defaultMessage="Start dato"
                    id="checklist-items.start"
                  />
                }
                value={startDate}
                onChange={setStartDate}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                clearable
                fullWidth
                DialogProps={{ fullScreen: isMobile }}
                format="Do MMMM YYYY"
                label={
                  <FormattedMessage
                    defaultMessage="Slut dato"
                    id="checklist-items.end"
                  />
                }
                value={endDate}
                onChange={setEndDate}
              />
            </Grid>
          </Grid>
        </div>
        {loading ? (
          "Loader"
        ) : (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage
                    defaultMessage="Sagstype"
                    id="cases-created-card.case-type"
                  />
                </TableCell>
                {employees.map(employee => (
                  <TableCell key={employee}>{employee}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from(sumData.entries()).map(([caseType, values]) => {
                return (
                  <TableRow key={caseType}>
                    <TableCell>{caseType}</TableCell>
                    {values.map((value, index) => (
                      <TableCell key={index}>{value}</TableCell>
                    ))}
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell>
                  <FormattedMessage
                    defaultMessage="Total"
                    id="cases-created-card.total"
                  />
                </TableCell>
                {total.map((entry, index) => (
                  <TableCell key={index}>{entry}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        )}
      </Paper>
    );
  }
);
