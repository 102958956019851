import * as yup from "yup";
import { AppContext } from "../../components/AppContext";
import { Button, CircularProgress } from "@material-ui/core";
import { CHANGE_PASSWORD, GET_USER, UPDATE_USER } from "../../graphql/users";
import { ChangePasswordDialog } from "../../components/change-password-dialog";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { Page } from "../../components/layout/page";
import { Query } from "@apollo/client/react/components";
import { UserForm } from "./user-form";
import { flowRight as compose } from "lodash";
import { formatUserLabel } from "../../components/user-select";
import { generateSelectValue } from "../../utils/form-helpers";
import { graphql, withApollo } from "@apollo/client/react/hoc";
import { withFormik } from "formik";
import { withRouter } from "react-router-dom";
import AreYouSure from "../../components/are-you-sure";
import Header from "../../components/layout/header";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import bcrypt from "bcryptjs";

const messages = defineMessages({
  title: {
    defaultMessage: "Brugerprofil",
    id: "user-profile.title"
  }
});

const userTypes = ["user", "admin", "superuser"];
const schema = yup.object().shape({
  acronym: yup.string().required(),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  mail: yup
    .string()
    .email()
    .required(), // TODO: Decide if it should be unique in db
  mobile: yup
    .number()
    .positive()
    .required(),
  password: yup.string(),
  phone: yup
    .number()
    .positive()
    .required(),
  user_type: yup.string().oneOf(userTypes)
});

class UserEntry extends PureComponent {
  static propTypes = {
    client: PropTypes.object.isRequired,
    currentUserMail: PropTypes.string.isRequired,
    dirty: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    resetForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired
  };

  state = {
    changePasswordDialogOpen: false,
    error: false,
    updatingPassword: false
  };

  handleResetForm = () => this.props.handleReset();

  showNavigationWarning = () => !this.props.isSubmitting && this.props.dirty;

  handlePasswordChangeClick = () => {
    this.setState({ changePasswordDialogOpen: true });
  };
  handlePasswordChangeOk = async password => {
    this.setState({ changePasswordDialogOpen: false });
    const salt = bcrypt.genSaltSync();
    const hash = await bcrypt.hash(password, salt);
    this.setState({ error: false, updatingPassword: true });
    try {
      await this.props.client.mutate({
        mutation: CHANGE_PASSWORD,
        variables: {
          mail: this.props.currentUserMail,
          password: hash
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      this.setState({ error: true });
    }
    this.setState({ updatingPassword: false });
  };
  handlePasswordChangeCancel = () => {
    this.setState({ changePasswordDialogOpen: false });
  };

  render() {
    const {
      dirty,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      intl: { formatMessage },
      isSubmitting,
      touched,
      values
    } = this.props;

    const heading = formatMessage(messages.title);
    const { error, updatingPassword } = this.state;
    return (
      <Page
        appBar={
          <Header
            dirty={dirty}
            title={heading}
            onResetButton={this.handleResetForm}
            onSaveButton={this.props.submitForm}
          />
        }
      >
        <div
          style={{
            backgroundColor: "#FFF",
            minHeight: "calc(100% - 64px)",
            padding: 20
          }}
        >
          <Button color="primary" onClick={this.handlePasswordChangeClick}>
            <FormattedMessage
              defaultMessage="Skift password"
              id="user-profile.change-password"
            />
          </Button>
          {updatingPassword ? (
            <div>
              <CircularProgress />{" "}
              <FormattedMessage
                defaultMessage="Gemmer nyt password."
                id="user-profile.saving-password"
              />
            </div>
          ) : null}
          {error ? (
            <div style={{ color: "red" }}>
              <FormattedMessage
                defaultMessage="Der skete en fejl. Dit nye password er ikke gemt"
                id="user-profile.error-saving-password"
              />
            </div>
          ) : null}

          <UserForm
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            touched={touched}
            values={values}
          />
        </div>
        <AreYouSure when={this.showNavigationWarning} />
        <ChangePasswordDialog
          open={this.state.changePasswordDialogOpen}
          onCancel={this.handlePasswordChangeCancel}
          onOk={this.handlePasswordChangeOk}
        />
      </Page>
    );
  }
}

const handleSubmit = async (
  payload,
  { props, resetForm, setErrors, setSubmitting }
) => {
  const { substitute } = payload;
  const { userId } = props.match.params;

  const variables = {
    id: userId,
    user: {
      substitute_id: substitute ? substitute.id : null
    }
  };
  try {
    await props.update_user({
      variables
    });
    resetForm();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    const errors = e.graphQLErrors.map(error => error.message);
    // eslint-disable-next-line no-console
    console.log(errors);
    setSubmitting(false);
    setErrors({ form: errors });
  }
};

const UserFormWithGraphQL = compose(
  graphql(UPDATE_USER, { name: "update_user" }),
  withApollo,
  withFormik({
    displayName: "UserForm",
    handleSubmit,
    mapPropsToValues: ({ variables }) => {
      const substitute = variables.id
        ? generateSelectValue(variables.substitute, formatUserLabel)
        : null;
      return {
        ...variables,
        substitute
      };
    },
    validationSchema: schema
  })
)(UserEntry);

const UserEntryWithIntl = withRouter(injectIntl(UserFormWithGraphQL));

const UserEntryWrapper = () => {
  return (
    <AppContext.Consumer>
      {context => (
        <Query
          query={GET_USER}
          variables={{ userId: context.state.currentUser.id }}
        >
          {({ data, error, loading }) => {
            if (loading) {
              return <p>Loading...</p>;
            }
            if (error) {
              return <p>Error :(</p>;
            }

            return (
              <UserEntryWithIntl
                currentUserMail={context.state.currentUser.name}
                userId={context.state.currentUser.id}
                variables={data.users[0]}
              />
            );
          }}
        </Query>
      )}
    </AppContext.Consumer>
  );
};

const UserEntryWrapperWithRouter = withRouter(UserEntryWrapper);
export default UserEntryWrapperWithRouter;
