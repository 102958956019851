import PropTypes from "prop-types";
import React, { useCallback } from "react";

const PinIcon = React.memo(({ color }) => (
  <svg
    height="100%"
    viewBox="0 0 24 24"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12,2C8.1,2,5,5.1,5,9c0,5.2,7,13,7,13s7-7.8,7-13C19,5.1,15.9,2,12,2z"
      fill={color || "none"}
    />
    <path d="M0,0h24v24H0V0z" fill="none" />
  </svg>
));

PinIcon.displayName = "PinIcon";
PinIcon.propTypes = {
  color: PropTypes.string.isRequired
};

export const PIN_SHAPES = {
  bell:
    "M10,21c0,1.1,0.9,2,2,2s2-0.9,2-2H10z M18.9,16.8V11c0-3.2-2.2-6-5.3-6.7V3.6C13.6,2.7,12.9,2,12,2s-1.6,0.7-1.6,1.6v0.7C7.4,5,5.1,7.8,5.1,11v5.8L3,18.9V20h18v-1.1L18.9,16.8z",
  circle: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z",
  drop:
    "M12,2c0,0-7,7.8-7,13c0,3.9,3.1,7,7,7s7-3.1,7-7C19,9.8,12,2,12,2z M8.8,19.5c-0.9,0-1.7-1.4-1.7-3.2c0-1.7,0.8-3.2,1.7-3.2s1.7,1.4,1.7,3.2C10.5,18.1,9.7,19.5,8.8,19.5z",
  eye:
    "M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z",
  square:
    "M21,19V5c0-1.1-0.9-2-2-2H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14C20.1,21,21,20.1,21,19z",
  star:
    "M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z",
  sun:
    "M6.76 4.84l-1.8-1.79-1.41 1.41 1.79 1.79 1.42-1.41zM4 10.5H1v2h3v-2zm9-9.95h-2V3.5h2V.55zm7.45 3.91l-1.41-1.41-1.79 1.79 1.41 1.41 1.79-1.79zm-3.21 13.7l1.79 1.8 1.41-1.41-1.8-1.79-1.4 1.4zM20 10.5v2h3v-2h-3zm-8-5c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm-1 16.95h2V19.5h-2v2.95zm-7.45-3.91l1.41 1.41 1.79-1.8-1.41-1.41-1.79 1.8z"
};

export const PinInsideIcon = React.memo(({ color, shape }) => (
  <svg
    height="100%"
    viewBox="0 0 24 24"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d={PIN_SHAPES[shape]} fill={color || "black"} />
    <path d="M0,0h24v24H0V0z" fill="none" />
  </svg>
));
PinInsideIcon.displayName = "PinInsideIcon";
PinInsideIcon.propTypes = {
  color: PropTypes.string.isRequired,
  shape: PropTypes.string.isRequired
};

export const Pin = React.memo(function Pin({
  caseId,
  color,
  icon,
  lat,
  lng,
  onClick
}) {
  const outerStyle =
    lat && lng
      ? {
          cursor: onClick ? "pointer" : "auto",
          height: 41,
          left: -38 / 2,
          position: "absolute",
          top: -41,
          width: 38
        }
      : null;
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(caseId);
    }
  }, [onClick, caseId]);
  return (
    <div style={outerStyle} onClick={handleClick}>
      <div style={{ position: "relative" }}>
        <div style={{ width: 38 }}>
          <PinIcon color={color} />
        </div>
        {icon ? (
          <div style={{ left: 13, position: "absolute", top: 9, width: 12 }}>
            <PinInsideIcon color="#FFF" shape={icon} />
          </div>
        ) : null}
      </div>
    </div>
  );
});
Pin.propTypes = {
  caseId: PropTypes.string,
  color: PropTypes.string.isRequired,
  icon: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
  onClick: PropTypes.func
};
